import { Observable } from 'rxjs/Observable';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import { makeFlagWithCommentRequest } from '../../../rest/card/flag-with-comment';
import type { IssueFlagWithCommentResponse } from '../../../rest/card/flag-with-comment/types';
import type { ServiceContext } from '../../service-context';

/**
 * Flag and comment on a card using the REST API.
 */
export default function flagWithCommentService(
	ctx: ServiceContext,
	{
		issueKeys,
		flag,
		comment,
		internal,
	}: { issueKeys: string[]; flag: boolean; comment: JSONDocNode; internal?: boolean },
): Observable<IssueFlagWithCommentResponse> {
	return Observable.fromPromise(makeFlagWithCommentRequest(issueKeys, flag, comment, internal));
}
