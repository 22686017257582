import get from 'lodash/get';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import type { SwimlaneModeId } from '@atlassian/jira-platform-board-kit/src/ui/swimlane/types.tsx';
import type { SwimlaneId } from '../../model/swimlane/swimlane-types';

// app provides it's unique prefix
const localStorage = createLocalStorageProvider('software');

const COLLAPSED_SWIMLANES = 'collapsed-swimlanes';

const DETAIL_VIEW_WIDTH_RATIO = 'gh.detailViewWidth';
const GROUP_BY_SELECTION = 'gh.groupBySelection.';

export const getDetailViewWidthRatio = (): number => {
	const value = localStorage.get(DETAIL_VIEW_WIDTH_RATIO);
	return value ? Number(value) : 0;
};
export const setDetailViewWidthRatio = (ratio: number) =>
	localStorage.set(DETAIL_VIEW_WIDTH_RATIO, ratio);

export const getCollapsedSwimlanes = (boardId: number | string): SwimlaneId[] =>
	get(localStorage.get(COLLAPSED_SWIMLANES), [String(boardId)], []);
export const setCollapsedSwimlanes = (boardId: number | string, swimlanesId: SwimlaneId[]) =>
	localStorage.set(COLLAPSED_SWIMLANES, {
		[boardId]: swimlanesId,
	});

export const setSwimlaneModeSetting = (
	boardId: string,
	groupBy: SwimlaneModeId | null | undefined,
) => {
	localStorage.set(`${GROUP_BY_SELECTION}${boardId}`, groupBy);
};

export const getSwimlaneModeSetting = (boardId: string): SwimlaneModeId | null | undefined => {
	const data = localStorage.get(`${GROUP_BY_SELECTION}${boardId}`);

	if (!data) {
		return null;
	}

	return data;
};
