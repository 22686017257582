import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { CardLabels } from '../../../labels';
import type { TextCustomField } from '../types';

type Props = {
	field: TextCustomField;
};

export const CUSTOM_FIELD_LABELS = 'labels';

export const TextLabelCustomFieldContent = ({ field }: Props) => {
	if (!field?.text) {
		return null;
	}
	const labels = field.text.split(',');

	return (
		<Box xcss={overflowOuterStyles} data-testId="text-label-custom-field-content-card-labels">
			<Box xcss={overflowInnerStyles}>
				{/* TMP displays labels outside of custom fields */}
				<CardLabels isCMPBoard labels={labels} />
			</Box>
		</Box>
	);
};

const overflowOuterStyles = xcss({
	display: 'flex',
	flexDirection: 'row',
	overflow: 'hidden',
});

const overflowInnerStyles = xcss({
	boxAlign: 'center',
	flexFlow: 'row wrap',
	alignItems: 'center',
	maxWidth: '100%',
	gap: 'space.100',
});
