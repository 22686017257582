import type { IssueDeleteModalOpenAction } from '../../../actions/issue/delete-modal';
import type { State } from '../../../reducers/types';

export const issueDeleteModalOpen =
	(eventPrefix: string) => (preState: State, state: State, action: IssueDeleteModalOpenAction) => ({
		name: `${eventPrefix}.issue.delete.modal.open`,
		data: {
			issueId: action.payload.issueId,
			columnIndex: action.payload.columnIndex,
		},
	});

export const issueDeleteModalClose = (eventPrefix: string) => () => ({
	name: `${eventPrefix}.issue.delete.modal.close`,
});
