import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';
import { isBoardEmptySelector } from '../board/board-selectors';
import { hasInlineCardCreateConfig } from '../inline-create/inline-create-selectors';
import { getBoardType, getIsCMPBoard } from '../software/software-selectors';
import { hasNoActiveSprintStateSelector } from '../sprint/sprint-selectors';

export type PlaceHolderType = 'NONE' | 'NO_ACTIVE_SPRINT' | 'CMP_EMPTY_KANBAN';

export const NO_ACTIVE_SPRINT_PLACEHOLDER = 'NO_ACTIVE_SPRINT';
export const CMP_EMPTY_KANBAN_PLACEHOLDER = 'CMP_EMPTY_KANBAN';
export const NO_PLACEHOLDER = 'NONE';

export const makeShowPlaceHolderSelector = createSelector(
	[
		getIsCMPBoard,
		getBoardType,
		isBoardEmptySelector,
		hasInlineCardCreateConfig,
		hasNoActiveSprintStateSelector,
	],
	(isCMPBoard, boardType, isBoardEmpty, hasInlineCardCreate, hasNoActiveSprint) => {
		const makeShowPlaceholder = (columnIndex: number): PlaceHolderType => {
			const isCMPKanbanBoard = isCMPBoard && boardType === 'KANBAN';
			const isFirstColumn = columnIndex === 0;

			if (!isFirstColumn) return NO_PLACEHOLDER;

			if (isCMPKanbanBoard) {
				return isBoardEmpty && !hasInlineCardCreate ? CMP_EMPTY_KANBAN_PLACEHOLDER : NO_PLACEHOLDER;
			}

			return hasNoActiveSprint ? NO_ACTIVE_SPRINT_PLACEHOLDER : NO_PLACEHOLDER;
		};

		return memoize(makeShowPlaceholder);
	},
);
