import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	externalLozengeShort: {
		id: 'portfolio-3-plan-increment-common.external-lozenge-short',
		defaultMessage: 'EXT',
		description: 'Shorter lozenge for the external team',
	},
	externalLozenge: {
		id: 'portfolio-3-plan-increment-common.external-lozenge',
		defaultMessage: 'EXTERNAL',
		description: 'Lozenge for the external team',
	},
	externalTeamTooltipTitle: {
		id: 'portfolio-3-plan-increment-common.external-team-tooltip-title',
		defaultMessage: 'Team currently not in this plan',
		description: 'Tooltip title for the external team',
	},
	externalTeamTooltipContent: {
		id: 'portfolio-3-plan-increment-common.external-team-tooltip-content',
		defaultMessage: "This team isn't included in your plan, but has issues assigned to it.",
		description: 'Tooltip title for the external team',
	},
	planOnlyLozenge: {
		id: 'portfolio-3-plan-increment-common.plan-only-lozenge',
		defaultMessage: 'PLAN-ONLY',
		description: 'Lozenge for the plan-only team',
	},
	planOnlyTeamTooltipTitle: {
		id: 'portfolio-3-plan-increment-common.plan-only-team-tooltip-title',
		defaultMessage: 'Plan-only team',
		description: 'Tooltip title for the plan-only team',
	},
	planOnlyTeamTooltipContent: {
		id: 'portfolio-3-plan-increment-common.plan-only-team-tooltip-content',
		defaultMessage:
			"A plan-only team can only be used within this plan. It won't appear in JQL filters or on your issue screens.",
		description: 'Tooltip title for the plan-only team',
	},
	kanbanLozenge: {
		id: 'portfolio-3-plan-increment-common.kanban-lozenge',
		defaultMessage: 'KANBAN',
		description: 'Lozenge for the kanban team',
	},
	weeksOption: {
		id: 'portfolio-3-plan-increment-common.weeks-option',
		defaultMessage: '{count} {count, plural, one {week} other {weeks}}',
		description: 'Labels for the iteration duration',
	},
	externalTeamTooltipContentIssueTermRefresh: {
		id: 'portfolio-3-plan-increment-common.external-team-tooltip-content-issue-term-refresh',
		defaultMessage: "This team isn't included in your plan, but has issues assigned to it.",
		description: 'Tooltip title for the external team',
	},
	planOnlyTeamTooltipContentIssueTermRefresh: {
		id: 'portfolio-3-plan-increment-common.plan-only-team-tooltip-content-issue-term-refresh',
		defaultMessage:
			"A plan-only team can only be used within this plan. It won't appear in JQL filters or on your issue screens.",
		description: 'Tooltip title for the plan-only team',
	},
});
