import { getNormalisedPerformanceFFs } from '@atlassian/jira-common-long-task-metrics/src/common/util/collectors';
import { issueViewOpenInteraction } from '../utils/performance-analytics';
import type { BoardCardCount } from './board-data-collector';

export const setIssueViewOpenFeedbackMark = () => {
	issueViewOpenInteraction.mark('feedback');
};

export const setIssueViewOpenFinishMark = (
	isSideBar: boolean,
	boardCardCount: BoardCardCount,
	isCMPBoard: boolean,
) =>
	issueViewOpenInteraction.stop({
		customData: {
			...getNormalisedPerformanceFFs(),
			...boardCardCount,
			isSideBar,
			isClassic: isCMPBoard,
		},
	});
