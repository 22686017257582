import { connect } from '@atlassian/jira-react-redux';
import { getColumnStatusMappingUrl } from '../../../../common/urls';
import {
	getIsCMPBoard,
	projectKeySelector,
	rapidViewIdSelector,
} from '../../../../state/selectors/software/software-selectors';
import { isSprintsEnabled } from '../../../../state/selectors/sprint/sprint-selectors';
import type { State } from '../../../../state/types';
import ColumnConfiguration from './view';

type StateProps = {
	isKanbanBoard: boolean;
	isCMPBoard: boolean;
	url: string;
};

const mapStateToProps = (state: State): StateProps => ({
	url: getColumnStatusMappingUrl(
		projectKeySelector(state),
		rapidViewIdSelector(state),
		getIsCMPBoard(state),
	),
	isKanbanBoard: !isSprintsEnabled(state),
	isCMPBoard: getIsCMPBoard(state),
});

const ConnectedColumnConfiguration = connect(mapStateToProps, {})(ColumnConfiguration);

export default ConnectedColumnConfiguration;
