import { type RefObject, useCallback, useEffect } from 'react';
import { useDependencyLinks } from '@atlassian/jira-portfolio-3-plan-increment-common/src/hooks/use-dependency-links';
import { useResizeObserver } from '@atlassian/jira-react-use-resize-observer';

const useMeasureHeaderForDependencies = ({
	columnId,
	isUnscheduledWorkColumnPanel,
	headerRef,
}: {
	columnId: number;
	isUnscheduledWorkColumnPanel?: boolean;
	headerRef: RefObject<HTMLDivElement>;
}) => {
	const [_, { updateColumnPositionMap }] = useDependencyLinks();
	const measureAndUpdateHeaderPosition = useCallback(() => {
		if (
			!isUnscheduledWorkColumnPanel &&
			headerRef &&
			typeof headerRef !== 'function' &&
			headerRef.current
		) {
			const { x, width, height } = headerRef.current.getBoundingClientRect();
			updateColumnPositionMap({ x, width, height }, columnId);
		}
	}, [isUnscheduledWorkColumnPanel, columnId, headerRef, updateColumnPositionMap]);

	useEffect(() => {
		measureAndUpdateHeaderPosition();
	}, [measureAndUpdateHeaderPosition]);

	useResizeObserver({
		ref: headerRef,
		onResize: measureAndUpdateHeaderPosition,
	});
};

// Component for measuring the swimlane column header's position for use in the calculation of positions for
// dependency line visualisation. This has no actual representation on the DOM - we conditionally render
// so that we can conditionally call the hook if the ref is actually defined, since resizeObserver takes only defined refs
export const HeaderMeasurement = ({
	columnId,
	isUnscheduledWorkColumnPanel,
	headerRef,
}: {
	columnId: number;
	isUnscheduledWorkColumnPanel?: boolean;
	headerRef: RefObject<HTMLDivElement>;
}) => {
	useMeasureHeaderForDependencies({ columnId, isUnscheduledWorkColumnPanel, headerRef });
	return null;
};
