import type { MessageDescriptorV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { FlagData, UndoFlagData } from '../../../model/flags/flag-types';
import type { Issue, IssueKey } from '../../../model/issue/issue-types';
import type { Sprint, SprintArray } from '../../../model/sprint/sprint-types';
import flagMessages from '../../../view/flags/messages';
import { undoFlagMeta, warningFlagMeta, type FlagMeta } from '../meta/flag-meta';

// deprecated - will be replaced with ISSUE_CREATE_FILTERED_V2
export const FLAG_DISMISSED = 'state.actions.flags.FLAG_DISMISSED' as const;

export type DismissFlagAction = {
	type: typeof FLAG_DISMISSED;
	payload: FlagData;
};

export const dismissFlag = (flagData: FlagData): DismissFlagAction => ({
	type: FLAG_DISMISSED,
	payload: flagData,
});

export const SHOW_UNDO_FLAG = 'state.actions.flags.SHOW_UNDO_FLAG' as const;

export type ShowUndoFlag = {
	type: typeof SHOW_UNDO_FLAG;
	meta: FlagMeta;
};

type ShowUndoFlagParams = {
	id: string;
	titleMessage: MessageDescriptorV2;
	descriptionMessage: MessageDescriptorV2;
	links: UndoFlagData['links'];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	context: any;
};

export const showUndoFlag = ({
	id,
	titleMessage,
	descriptionMessage,
	links,
	context,
}: ShowUndoFlagParams): ShowUndoFlag => ({
	type: SHOW_UNDO_FLAG,
	meta: undoFlagMeta({
		id: `${SHOW_UNDO_FLAG}::${id}`,
		titleMessage,
		descriptionMessage,
		context,
		links,
	}),
});

export const UNDO_FLAG_PERFORM_UNDO = 'state.actions.flags.UNDO_FLAG_PERFORM_UNDO';

export type UndoFlagPerformUndoAction = {
	type: typeof UNDO_FLAG_PERFORM_UNDO;
	payload: {
		flagId: string;
	};
};

export const undoFlagPerformUndo = (flagId: string): UndoFlagPerformUndoAction => ({
	type: UNDO_FLAG_PERFORM_UNDO,
	payload: { flagId },
});

export const UNDO_FLAG_PERFORM_COMMIT = 'state.actions.flags.UNDO_FLAG_PERFORM_COMMIT';

export type UndoFlagPerformCommitAction = {
	type: typeof UNDO_FLAG_PERFORM_COMMIT;
	payload: {
		flagId: string;
	};
};

export const undoFlagPerformCommit = (flagId: string): UndoFlagPerformCommitAction => ({
	type: UNDO_FLAG_PERFORM_COMMIT,
	payload: { flagId },
});

// deprecated - will be replaced with ISSUE_CREATE_FILTERED_V2
export const FLAG_CREATED_ISSUE_IS_FILTERED =
	'state.actions.flags.FLAG_CREATED_ISSUE_IS_FILTERED' as const;

export type ShowCreatedIssueIsFilteredFlagAction = {
	type: typeof FLAG_CREATED_ISSUE_IS_FILTERED;
	payload: Issue;
	meta: FlagMeta;
};

export const showCreatedIssueIsFilteredFlag = (
	issue: Issue,
): ShowCreatedIssueIsFilteredFlagAction => ({
	type: FLAG_CREATED_ISSUE_IS_FILTERED,
	payload: issue,
	meta: warningFlagMeta({
		id: FLAG_CREATED_ISSUE_IS_FILTERED,
		titleMessage: flagMessages.createdIssueIsFilteredTitle, // Overridden
		descriptionMessage: flagMessages.createdIssueIsFilteredDescription, // Overridden
		context: {
			issueKey: issue.key,
			issueId: issue.id,
		},
	}),
});

export const ISSUE_CREATE_FILTERED_V2 = 'state.actions.flags.ISSUE_CREATE_FILTERED_V2' as const;

export type IssueCreateFilteredV2Action = {
	type: typeof ISSUE_CREATE_FILTERED_V2;
	payload: { cardKeys: IssueKey[]; sprints?: Sprint[] | null; filteredOnServer?: boolean };
};

export const issueCreateFilteredV2 = (
	cardKeys: IssueKey[],
	sprints?: SprintArray | null,
	filteredOnServer?: boolean,
): IssueCreateFilteredV2Action => ({
	type: ISSUE_CREATE_FILTERED_V2,
	payload: { cardKeys, sprints, filteredOnServer },
});

export const ISSUE_CREATE_MOVED_BETWEEN_SWIMLANES =
	'state.actions.flags.ISSUE_CREATE_MOVED_BETWEEN_SWIMLANES' as const;

export type IssueCreateMovedBetweenJqlSwimlanesAction = {
	type: typeof ISSUE_CREATE_MOVED_BETWEEN_SWIMLANES;
	payload: { cardKeys: IssueKey[] };
};

export const issueCreateMovedBetweenJqlSwimlanes = (
	cardKeys: IssueKey[],
): IssueCreateMovedBetweenJqlSwimlanesAction => ({
	type: ISSUE_CREATE_MOVED_BETWEEN_SWIMLANES,
	payload: { cardKeys },
});

export const CARD_CREATE_FILTERED_OUT_IN_IP_BOARD =
	'state.actions.flags.CARD_CREATE_FILTERED_OUT_IN_IP_BOARD';

export type CardCreateFilteredOutInIPBoardAction = {
	type: typeof CARD_CREATE_FILTERED_OUT_IN_IP_BOARD;
};

export const cardCreateFilteredOutInIPBoard = (): CardCreateFilteredOutInIPBoardAction => ({
	type: CARD_CREATE_FILTERED_OUT_IN_IP_BOARD,
});

export type Action =
	| DismissFlagAction
	| ShowCreatedIssueIsFilteredFlagAction
	| IssueCreateFilteredV2Action
	| IssueCreateMovedBetweenJqlSwimlanesAction
	| ShowUndoFlag
	| UndoFlagPerformCommitAction
	| UndoFlagPerformUndoAction
	| CardCreateFilteredOutInIPBoardAction;
