import React, { useCallback } from 'react';
import JiraFullscreenButton from '@atlassian/jira-fullscreen-button';
import {
	useViewSettingsActions,
	useIsFullscreen,
} from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import { Capability } from '../../../../common/capability';
import { useCapability } from '../../../../state/state-hooks/capabilities';

interface Props {
	boardId: number;
	shouldRenderContent?: boolean;
}

const getFullscreenRefs = (): (HTMLElement | null)[] =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, jira/jira-ssr/no-unchecked-globals-usage
	Array.from(document.querySelectorAll('[data-fullscreen-id]')) as (HTMLElement | null)[];

export const FullscreenButton = ({ boardId, shouldRenderContent }: Props) => {
	const { setIsFullscreen } = useViewSettingsActions();
	const isFullscreen = useIsFullscreen();
	const isTabNavigation = useCapability(Capability.IS_TAB_NAVIGATION);

	const handleFullscreenToggle = useCallback(
		(wasFullscreen: boolean) => {
			setIsFullscreen({ boardId, isFullscreen: !wasFullscreen });
		},
		[boardId, setIsFullscreen],
	);

	return (
		<JiraFullscreenButton
			testId="software-board.header.controls-bar.fullscreen-button.fullscreen-button"
			getCustomRefs={getFullscreenRefs}
			withContainerStyles={false}
			onToggle={handleFullscreenToggle}
			isFullscreen={isFullscreen}
			renderAsResponsiveButton={isTabNavigation}
			shouldRenderContent={shouldRenderContent}
		/>
	);
};
