import type { EstimateGQL } from '@atlassian/jira-software-board-fetch-scope-critical/src/types';
import { ORIGINAL_ESTIMATE_FIELD_ID } from '../../../model/constants';
import {
	type Estimate,
	TMP_STORY_POINTS,
	TMP_ORIGINAL_TIME_ESTIMATE,
} from '../../../model/issue/issue-types';
import type { EstimationStatisticFieldId } from '../../../model/work/work-types';
import messages from './messages';

export const calculateEstimateValue = (
	estimate: EstimateGQL | null | undefined,
	estimationStatisticFieldId: EstimationStatisticFieldId,
): Estimate => {
	if (!estimationStatisticFieldId) {
		return null;
	}

	if (estimationStatisticFieldId === ORIGINAL_ESTIMATE_FIELD_ID) {
		return {
			type: TMP_ORIGINAL_TIME_ESTIMATE,
			displayName: messages.originalEstimateDisplayLabel,
			fieldId: estimationStatisticFieldId,
			value: estimate?.originalEstimate?.value,
			valueAsText: estimate?.originalEstimate?.valueAsText,
		};
	}
	return {
		type: TMP_STORY_POINTS,
		fieldId: estimationStatisticFieldId,
		displayName: messages.storyPointsDisplayLabel,
		value: estimate?.storyPoints ?? undefined,
	};
};
