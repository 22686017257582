import { connect } from '@atlassian/jira-react-redux';
import {
	type CardFilteredFlagProps,
	CardFilteredFlag,
} from '@atlassian/jira-software-flags/src/ui/card-filtered/index.tsx';
import { openIssueModal } from '../../state/actions/issue/modal';
import { addToSprintRequest } from '../../state/actions/sprints/add-to-sprint';
import {
	addToSprintSuccessSelector,
	addToSprintLoadingSelector,
} from '../../state/selectors/board/board-selectors';
import type { State } from '../../state/types';

type DispatchProps = Pick<CardFilteredFlagProps, 'onViewIssue' | 'onAddToSprint'>;
type StateProps = Pick<CardFilteredFlagProps, 'isAddedToSprintSuccess' | 'isAddedToSprintLoading'>;
type OwnProps = Omit<
	CardFilteredFlagProps,
	'onViewIssue' | 'onAddToSprint' | 'isAddedToSprintSuccess' | 'isAddedToSprintLoading'
>;

const mapStateToProps = (state: State): StateProps => ({
	isAddedToSprintSuccess: addToSprintSuccessSelector(state),
	isAddedToSprintLoading: addToSprintLoadingSelector(state),
});

const mapDispatchToProps = {
	onViewIssue: openIssueModal,
	onAddToSprint: addToSprintRequest,
} as const;

export default connect<StateProps, DispatchProps, OwnProps, State>(
	mapStateToProps,
	mapDispatchToProps,
)(CardFilteredFlag);
