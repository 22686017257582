import isNil from 'lodash/isNil';
import { fg } from '@atlassian/jira-feature-gating';
import {
	type Hash,
	toIssueId,
	toIssueKey,
	toIssueTypeId,
	toIssueStatusId,
	toProjectId,
	toAccountId,
	toDisplayName,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { Filters } from '@atlassian/jira-software-filters/src/common/types';
import {
	createDefaultMatcherCreators,
	createFilterFunction,
} from '@atlassian/jira-software-filters/src/utils';
import type { FilterableIssue, Matcher } from '@atlassian/jira-software-filters/src/utils/types';
import { isIncrementPlanningBoardM1Enabled } from '../../../feature-flags';
import type { Issue, IssueEntities } from '../../../model/issue/issue-types';
import type { PeopleEntities } from '../../../model/people/people-types';

export const isIssueIdOptimistic: Matcher<FilterableIssue> = (issue) => Number(issue.id) < 0;

export const filter = (
	filterValues: Filters,
	issues: Issue[],
	issueHash: IssueEntities,
	people: PeopleEntities,
	filteredIssueIds?: Set<string>,
): Issue[] => {
	const toFilterableIssue = ({
		id,
		key,
		summary,
		labels,
		typeId,
		typeName,
		parentId,
		assigneeAccountId,
		requestTypeId,
		statusId,
		sprintId,
		projectId,
		issueLinks,
	}: Issue): FilterableIssue => {
		const assignee =
			!isNil(assigneeAccountId) && assigneeAccountId in people
				? {
						id: toAccountId(assigneeAccountId),
						displayName: toDisplayName(people[assigneeAccountId].displayName),
					}
				: undefined;
		return {
			id: toIssueId(id.toString()),
			key: toIssueKey(key),
			summary,
			labels,
			type: {
				id: toIssueTypeId(typeId.toString()),
				name: typeName,
			},
			parentId: !isNil(parentId) ? toIssueId(parentId.toString()) : undefined,
			requestTypeId,
			statusId: statusId != null ? toIssueStatusId(String(statusId)) : undefined,
			assignee,
			sprintId,
			projectId: isIncrementPlanningBoardM1Enabled() ? toProjectId(projectId.toString()) : '',
			hasOfftrackDependency: fg('dependency_visualisation_program_board_fe_and_be')
				? issueLinks?.some((link) => link.isOfftrack)
				: false,
			hasOntrackDependency: fg('dependency_visualisation_program_board_fe_and_be')
				? issueLinks?.some((link) => !link.isOfftrack)
				: false,
		};
	};

	const filterableIssues = Object.keys(issueHash).map((id) => toFilterableIssue(issueHash[id]));
	const filterableIssuesHash = filterableIssues.reduce((acc, filterableIssue) => {
		if (isNil(filterableIssue.id)) return acc;
		acc[`${filterableIssue.id}`] = filterableIssue;
		return acc;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	}, {} as Hash<FilterableIssue>);

	const filterFunction = createFilterFunction(
		filterValues,
		{ issues: { list: filterableIssues, hash: filterableIssuesHash }, filteredIssueIds },
		createDefaultMatcherCreators<FilterableIssue>(),
		[isIssueIdOptimistic],
	);

	return issues.filter((issue) => {
		const filterableIssue = filterableIssuesHash[issue.id] ?? toFilterableIssue(issue);
		return filterFunction(filterableIssue);
	});
};
