import type { Entity } from '../../model/software/software-types';

export const entityArrayToMap = <T extends Entity>(
	entities: T[],
): {
	[key: string]: T;
} => {
	const result: {
		[key: string]: T;
	} = {};
	entities.forEach((entity) => {
		result[String(entity.id)] = entity;
	});
	return result;
};
