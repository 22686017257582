import React from 'react';
import { useBoardSelector } from '../../../state';
import {
	activeSprintsSelector,
	getSelectedOrAllSprintsSelector,
} from '../../../state/selectors/sprint/sprint-selectors.tsx';
import SprintControls from './view';

// Still in progress https://jplat.atlassian.net/browse/BLU-3256
// TODO BLU-4236 async loading of the content
// TODO BLU-4236 aria dialog
// TODO BLU-4236 error boundary for content (portal)
export const SprintPopup = () => {
	const selectedSprints = useBoardSelector(getSelectedOrAllSprintsSelector);
	const activeSprints = useBoardSelector(activeSprintsSelector);

	if (!activeSprints?.length) {
		// can happen because our parent uses "hasActiveSprints" selector
		// which doesn't check if the array is empty (as of 2024/09)
		return null;
	}

	return <SprintControls activeSprints={activeSprints} selectedSprints={selectedSprints} />;
};
