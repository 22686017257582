import React from 'react';
import noop from 'lodash/noop';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types';
import { useIntl } from '@atlassian/jira-intl';
import { getNextgenSettingsWorkflowUrl } from '@atlassian/jira-project-settings-apps-common/src/urls';
import { RouterActions } from '@atlassian/react-resource-router';
import messages from './messages';

export type OwnProps = {
	isDisabled: boolean;
	issueTypeId: string;
	projectKey: string;
};

export type Props = OwnProps;

const ManageWorkflowMenuItem = (props: Props) => {
	const { formatMessage } = useIntl();
	const { isDisabled, projectKey, issueTypeId } = props;

	return (
		<RouterActions>
			{({ push }) => {
				const onClick = issueTypeId
					? () => push(getNextgenSettingsWorkflowUrl(SOFTWARE_PROJECT, projectKey, issueTypeId))
					: noop;

				return (
					<DropdownItem isDisabled={isDisabled || !issueTypeId} onClick={onClick}>
						{formatMessage(messages.displayText)}
					</DropdownItem>
				);
			}}
		</RouterActions>
	);
};

export default ManageWorkflowMenuItem;
