import { ff } from '@atlassian/jira-feature-flagging';
import type { JiraPlanDataRestResponse } from '@atlassian/jira-software-backlog-uif-types';
import { extractStatusCode } from '@atlassian/jira-software-sla-tracker/src/services/status-code/index.tsx';
import type {
	UIFBoardCachedDataResult,
	UIFBacklogCachedDataResult,
} from '@atlassian/jira-software-uif-early-script';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { type RouteResource, useResource } from '@atlassian/react-resource-router';

export const useShouldShowAdminInaccessibleBoard = (
	resource: RouteResource<
		UIFBoardCachedDataResult | UIFBacklogCachedDataResult | JiraPlanDataRestResponse | null
	>,
) => {
	const isAdmin = useIsAdmin();
	const data = useResource(resource);
	const statusCode = data.error ? extractStatusCode(data.error) : null;

	return (
		ff('show-board-filter-permission-message-to-admins_iqh25') && isAdmin && statusCode === 404
	);
};
