import { useCallback, useEffect, useState, useMemo } from 'react';
import type { DismissalState, UseDismissalState } from './types';
import { getDismissalLocalStorageState, setDismissalLocalStorageState } from './utils';

export const useDismissal = (): UseDismissalState => {
	const [dismissalState, setDismissalState] = useState<DismissalState | undefined>();

	const isDismissed = useMemo(
		() => !dismissalState || dismissalState.count > 2 || dismissalState.dismissUntil >= Date.now(),
		[dismissalState],
	);

	useEffect(() => {
		// reading the data from local storage in useEffect so we won't try to read it for SSR
		setDismissalState(getDismissalLocalStorageState());
	}, []);

	useEffect(() => {
		if (dismissalState?.dismissUntil) {
			setDismissalLocalStorageState(dismissalState);
		}
	}, [dismissalState]);

	const dismiss = useCallback(() => {
		setDismissalState((prevState) => ({
			count: (prevState?.count ?? 0) + 1,
			dismissUntil: new Date().setHours(23, 59, 59, 999),
		}));
	}, []);

	return {
		isDismissed,
		dismiss,
		dismissCount: dismissalState?.count ?? 0,
	};
};
