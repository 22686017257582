import React, { useCallback, useEffect, useMemo } from 'react';
import { sub, startOfDay, formatISO } from 'date-fns';
import { useShowCreateRetroOrCrossSellModal } from '@atlassian/jira-confluence-sprint-retros/src/controllers/show-create-retro-or-cross-sell-modal/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { createAri } from '@atlassian/jira-platform-ari';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { completeSprintModalEntryPoint } from '@atlassian/jira-software-complete-sprint-modal-relay/entrypoint';
import { ModalTriggerButton } from '@atlassian/jira-software-modal-dialog/src/ui/modal-trigger-button/index.tsx';
import { BOARD } from '@atlassian/jira-software-resource-invalidator/src/common/types.tsx';
import { triggerResourceCacheInvalidation } from '@atlassian/jira-software-resource-invalidator/src/controllers/resources-cache-invalidation-subject/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { completeSprintModalLoad } from '../../../common/graphql/metrics';
import { useBacklogUrl } from '../../../common/hooks/use-backlog-url';
import { DisabledButtonHelper } from '../../../common/sprint-actions/disabled-button-helper/index.tsx';
import type { RapidViewId } from '../../../model/software/software-types';
import type { Sprint } from '../../../model/sprint/sprint-types';
import messages from './messages';

export interface StateProps {
	activeSprints: Sprint[] | null;
	appearance: 'default' | 'primary';
	boardId: RapidViewId;
	selectedSprintIds: string[];
	sourceIssueNo: number;
	isCMPBoard: boolean;
}

export interface OwnProps {
	isDisabled?: boolean;
}

export type Props = StateProps & OwnProps;

const A11Y_HELPER_TEXT_ID = 'software-board.header.complete-sprint-button.a11y-helper-text';

export const CompleteSprintButton = ({
	activeSprints,
	appearance,
	boardId,
	selectedSprintIds,
	sourceIssueNo,
	isCMPBoard,
	isDisabled,
}: Props) => {
	const cloudId = useCloudId();
	const { data: project } = useProjectContext();
	const jiraProjectAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'project',
		resourceId: String(project?.projectId),
	});
	const { formatMessage } = useIntl();
	const { push } = useRouterActions();

	const boardIdAri = createAri({
		resourceOwner: 'jira-software',
		cloudId,
		resourceType: 'board',
		resourceId: String(boardId),
	});

	const showRetrosModal = useShowCreateRetroOrCrossSellModal();

	const backlogUrl = useBacklogUrl(['sprintCompleted']) || '';

	const onCompleted = useCallback(() => {
		push(backlogUrl);

		// Set the flag to load the retros modal when we get to the backlog if the checkbox was ticked
		showRetrosModal();

		// Since the sprint modals don't go through redux we need to fire their invalidations manually
		triggerResourceCacheInvalidation(BOARD, 'COMPLETE_SPRINT_MODAL_COMPLETE');
	}, [backlogUrl, push, showRetrosModal]);

	const entryPointParams = useMemo(
		() => ({
			jiraProjectAri,
			// Date of 12 weeks ago as we want to fetch deployments date updated at most 12 weeks ago for deployments report nudge on sprint completion
			updatedFromDeploymentsDate: formatISO(startOfDay(sub(Date.now(), { weeks: 12 }))),
			boardId: boardIdAri,
			activeSprintIds:
				activeSprints?.map((sprint) =>
					createAri({
						cloudId,
						resourceId: String(sprint.id),
						resourceOwner: 'jira-software',
						resourceType: 'sprint',
					}),
				) ?? [],
			isCompanyManaged: isCMPBoard,
		}),
		[activeSprints, boardIdAri, cloudId, isCMPBoard, jiraProjectAri],
	);

	const { entryPointActions, entryPointReferenceSubject, stopMetric } = useEntryPoint(
		completeSprintModalEntryPoint,
		entryPointParams,
		completeSprintModalLoad,
	);

	const triggerRef = useEntryPointButtonTrigger(entryPointActions);

	let sprintId = selectedSprintIds?.[0];

	if (!sprintId && activeSprints) {
		sprintId = activeSprints?.[0]?.id.toString();
	}

	const props = useMemo(
		() => ({
			analyticsSource: 'simple-software-board',
			boardId: boardIdAri,
			onCompleted,
			sourceIssueNo,
			sprintId,
			selectedSprintIds,
			onReady: stopMetric,
			isCompanyManaged: isCMPBoard,
		}),
		[boardIdAri, onCompleted, sourceIssueNo, sprintId, selectedSprintIds, stopMetric, isCMPBoard],
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	useEffect(() => {
		if (isCMPBoard) {
			const analyticsEvent = createAnalyticsEvent({});
			fireOperationalAnalytics(analyticsEvent, 'completeSprintButton mounted');
		}
	}, [isCMPBoard, createAnalyticsEvent]);

	return (
		<>
			{fg('jsw-jet-yamato-fe-permissions-seq-sprint-actions') ? (
				<DisabledButtonHelper
					isDisabled={isDisabled}
					describedById={A11Y_HELPER_TEXT_ID}
					helpText={formatMessage(messages.completeSprintButtonDisabledInfoTextNonFinal)}
				>
					<ModalTriggerButton
						testId="software-board.header.complete-sprint-button"
						appearance={appearance}
						ref={triggerRef}
						aria-describedby={A11Y_HELPER_TEXT_ID}
						isDisabled={isDisabled}
					>
						{formatMessage(messages.completeSprintButton)}
					</ModalTriggerButton>
				</DisabledButtonHelper>
			) : (
				<ModalTriggerButton
					testId="software-board.header.complete-sprint-button"
					appearance={appearance}
					ref={triggerRef}
				>
					{formatMessage(messages.completeSprintButton)}
				</ModalTriggerButton>
			)}
			<ModalEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="complete-sprint-modal"
				teamName="a4t-pandora"
				packageName="jiraSoftwareCompleteSprintModalRelay"
				entryPointActions={entryPointActions}
				runtimeProps={props}
			/>
		</>
	);
};
