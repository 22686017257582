import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	copyIssueKey: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-items.copy-issue-key.copy-issue-key',
		defaultMessage: 'Copy issue key',
		description: 'Label for context menu action to copy the selected issue key',
	},
	copySuccessTitle: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-items.copy-issue-key.copy-success-title',
		defaultMessage: 'Issue key copied',
		description: 'Title for flag shown when issue key is copied to clipboard',
	},
	copySuccessMessage: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-items.copy-issue-key.copy-success-message',
		defaultMessage: 'You’ve copied the issue key to your clipboard.',
		description: 'Message body for flag shown when issue key is copied to clipboard',
	},
	copyFailureTitle: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-items.copy-issue-key.copy-failure-title',
		defaultMessage: 'We couldn’t copy the issue key',
		description: 'Title for flag shown when unable to copy the issue key to clipboard',
	},
	copyFailureMessage: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-items.copy-issue-key.copy-failure-message',
		defaultMessage: 'Refresh the page or check your browser settings, then try again.',
		description: 'Message body for flag shown when unable to copy the issue key to clipboard',
	},
});
