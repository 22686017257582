import { useEffect } from 'react';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { PollingMemoryReporter, type GetCustomData } from '../services/polling-memory-reporter';

type Props = { getCustomData?: GetCustomData };

const MemoryObserver = ({ getCustomData }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	useEffect(() => {
		const memoryReporter = new PollingMemoryReporter(createAnalyticsEvent, getCustomData);
		memoryReporter.start();
		memoryReporter.setBucketTimeouts();

		return () => {
			memoryReporter.stop();
			memoryReporter.clearBucketTimeouts();
		};
	}, [createAnalyticsEvent, getCustomData]);

	return null;
};

export default MemoryObserver;
