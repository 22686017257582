import type { FetchOptions } from '@atlassian/jira-insights-common-constants/src/common/types/fetch.tsx';
import { useFetch } from '@atlassian/jira-insights-fetch/src/controllers/use-fetch/main.tsx';
import { performPutRequest } from '@atlassian/jira-insights-fetch/src/services/fetch/perform-put-request/index.tsx';
import { NBT_MY_PREFERENCES_URL, NBT_OPT_IN_PREF_ANALYTICS_ID, PACKAGE_NAME } from './constants';

type NextBestTaskMyPreferencesDTO = {
	hasUserOptedIn: boolean;
};

type UseFetchOptions = Omit<FetchOptions, 'body'>;

const updateNbtOptInPreference = async (
	fetchOptions: UseFetchOptions,
	payload: NextBestTaskMyPreferencesDTO,
): Promise<null> => {
	const response = await performPutRequest(NBT_MY_PREFERENCES_URL, {
		...fetchOptions,
		body: JSON.stringify(payload),
	});
	return response;
};

export const useNextBestTaskUserPreference = () =>
	useFetch(
		{
			onFetch: (fetchOptions: UseFetchOptions, payload: NextBestTaskMyPreferencesDTO) =>
				updateNbtOptInPreference(fetchOptions, payload),
			analyticsTracking: {
				actionSubject: `useFetch.${NBT_OPT_IN_PREF_ANALYTICS_ID}`,
				packageName: PACKAGE_NAME,
				action: 'dataUpdate',
			},
			loggingKey: `${PACKAGE_NAME}.${NBT_OPT_IN_PREF_ANALYTICS_ID}`,
			isAutoFetchable: false,
		},
		[],
	);
