import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { executeBacklogEnable } from '../../rest/board/backlog-enable-server';
import { getNextGenBacklogUrl } from '../../rest/software/software-api-urls';
import {
	backlogEnableExecute,
	backlogEnableExecuteSuccess,
	backlogEnableExecuteFailure,
	type BacklogEnableExecuteAction,
	type BacklogEnableConfirmAction,
	type BacklogEnableExecuteSuccessAction,
	BACKLOG_ENABLE_EXECUTE,
	BACKLOG_ENABLE_CONFIRM,
	BACKLOG_ENABLE_EXECUTE_SUCCESS,
} from '../../state/actions/board/backlog-enable';
import { getFirstColumnIssueIds } from '../../state/selectors/board/backlog-issue-move-selectors';
import {
	contextPathSelector,
	projectKeySelector,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors';
import type { Action, State, ActionsObservable, MiddlewareAPI } from '../../state/types';

const confirmEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(BACKLOG_ENABLE_CONFIRM)
		.switchMap((action: BacklogEnableConfirmAction) =>
			Observable.of(
				backlogEnableExecute(
					getFirstColumnIssueIds(store.getState()),
					action.payload.push,
					action.payload.refresh,
				),
			),
		);

const executeEnableBacklogEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(BACKLOG_ENABLE_EXECUTE).switchMap((action: BacklogEnableExecuteAction) =>
		executeBacklogEnable(store.getState())
			.switchMap(() =>
				Observable.of(backlogEnableExecuteSuccess(action.payload.push, action.payload.refresh)),
			)
			.catch((error: FetchError) => {
				log.safeErrorWithoutCustomerData(
					'backlog.enable.failure',
					'Failed to enable the backlog',
					error,
				);
				return Observable.of(backlogEnableExecuteFailure());
			}),
	);

const executeEnableBacklogEpicSuccess = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(BACKLOG_ENABLE_EXECUTE_SUCCESS)
		.mergeMap((action: BacklogEnableExecuteSuccessAction) => {
			const contextPath = contextPathSelector(store.getState());
			const rapidViewId = rapidViewIdSelector(store.getState());
			const projectKey = projectKeySelector(store.getState());
			const { push, refresh } = action.payload;

			const url = getNextGenBacklogUrl(contextPath, projectKey, rapidViewId);
			push(url);
			refresh();
			return Observable.empty<never>();
		});

export default combineEpics<Action, State>(
	confirmEpic,
	executeEnableBacklogEpic,
	executeEnableBacklogEpicSuccess,
);
