import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { colors, fontSizeSmall } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { layout } from '@atlassian/jira-common-styles/src/main.tsx';
import type { CustomOptionLabel, CustomOptionLabelProps } from '../types';

type Props = {
	label: string;
	subLabel?: string;
	iconUrl: string;
	value: string;
	maxSelectionReachedTooltip: string | undefined;
	isDisabled: boolean;
	CustomOptionLabel: CustomOptionLabel;
};

export const DefaultOptionLabel = ({ label, iconUrl }: CustomOptionLabelProps) => (
	<>
		{iconUrl !== undefined && <Icon src={iconUrl} role="presentation" />}
		<SelectOptionLabel>{label}</SelectOptionLabel>
	</>
);

const ListFilterOption = ({
	label,
	subLabel,
	iconUrl,
	value,
	maxSelectionReachedTooltip = undefined,
	isDisabled,
	CustomOptionLabel,
}: Props) => {
	const content = isDisabled && maxSelectionReachedTooltip ? maxSelectionReachedTooltip : label;

	return (
		<Tooltip content={content}>
			<SelectOptionWrapper data-testid="filters.common.ui.list.option">
				<CustomOptionLabel label={label} value={value} iconUrl={iconUrl} />
			</SelectOptionWrapper>
			{subLabel !== undefined && <SelectOptionSubLabel>{subLabel}</SelectOptionSubLabel>}
		</Tooltip>
	);
};

ListFilterOption.defaultProps = {
	iconUrl: undefined,
	isDisabled: false,
};

export default memo(ListFilterOption);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SelectOptionWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SelectOptionLabel = styled.div({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SelectOptionSubLabel = styled.div({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N100),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSizeSmall()}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Icon = styled.img({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${layout.issueIconSize}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${layout.issueIconSize}px`,
	paddingRight: token('space.100', '8px'),
});
