import { type Observable as ObservableType, Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import { fg } from '@atlassian/jira-feature-gating';
import {
	getBoardScopeGraphqlQuery,
	softwareBoardScopeCriticalDataOperationName,
} from '@atlassian/jira-software-board-fetch-scope-critical-query';
import { query$ } from '@atlassian/jira-software-swag/src/services/query/index.tsx';
import { boardScopeDataCriticalAnalyticsOptions } from './constants';
import type { BoardScopeGQL } from './types';

type Response = {
	boardScope: BoardScopeGQL;
};

export const parseResponse = (result: Response): ObservableType<BoardScopeGQL> =>
	Observable.of(result.boardScope);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (contextPath: string, boardId: string): ObservableType<BoardScopeGQL> =>
	query$<Response>(
		contextPath,
		softwareBoardScopeCriticalDataOperationName,
		getBoardScopeGraphqlQuery(),
		{ boardId, useBackendIccConfig: fg('use_backend_tmp_icc_config_') },
		boardScopeDataCriticalAnalyticsOptions,
	).flatMap(parseResponse);
