import { type MigrationStatus, Status } from '../types';

export const X2C_SERVICE_URL_PREFIX = '/gateway/api/jira-x2c-service/api/v1';

export const APP_IDENTIFIER = 'jira-business.project-create-importer-common';
export const APP_TEAM_NAME = 'x2c-wolverine';

export const INITIAL_MIGRATION_STATUS: MigrationStatus = {
	overallProgress: 0.05,
	overallStatus: Status.READY,
	createdIssues: 0,
	totalIssues: 0,
	detailedProgress: {
		dataTransformStatus: Status.READY,
		userImportStatus: Status.READY,
		projectCreateStatus: Status.READY,
	},
};

export const FEEDBACK_GROUP_ID = 'jwm-feedback-importer';

export const IMPORT_TYPE_PARAM_KEY = 'importType';
export const WORKSPACE_ID_PARAM_KEY = 'workspaceId';
export const ENTITY_ID_PARAM_KEY = 'entityId';
