import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	ISSUE_BULK_UPDATE_REQUEST,
	issueBulkUpdateSuccess,
	issueBulkUpdateFailed,
} from '../../../state/actions/issue/bulk-update';
import type { ActionsObservable, MiddlewareAPI, BoardDependencies } from '../../../state/types';

/**
 * This epic is used in the IP board for
 * * D&D the cards
 * * assigning the parent in parent modal
 */
export function issueBulkUpdateEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
	{ customRequestHandlers }: BoardDependencies,
) {
	return action$.ofType(ISSUE_BULK_UPDATE_REQUEST).mergeMap((action) => {
		const success = (response: unknown) => {
			if (action.promise) {
				action.promise.resolve(response);
			}
			return Observable.of(issueBulkUpdateSuccess());
		};

		const error = (err?: Error) => {
			log.safeErrorWithoutCustomerData(
				'issue.issue-bulk-update.failure',
				'Failed to bulk update on IP board',
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				err as Error,
			);
			if (action.promise) {
				action.promise.reject(err);
			}
			return Observable.of(issueBulkUpdateFailed());
		};

		if (!customRequestHandlers || !customRequestHandlers.bulkUpdateIssues) {
			return error(new Error('no request handler found for bulk update'));
		}

		return Observable.from(customRequestHandlers.bulkUpdateIssues(action.payload))
			.flatMap((response) => success(response))
			.catch((err) => error(err));
	});
}
