import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import CardIcon from '../icon/main.tsx';
import { CHECK_ICON } from '../icon/types.tsx';
import messages from './messages';

export const CardTick = () => {
	const { formatMessage } = useIntl();
	const message = formatMessage(messages.done);

	return (
		<Container>
			<Tooltip content={message}>
				<CardIcon
					type={CHECK_ICON}
					label={message}
					primaryColor={token('color.icon.success', colors.G400)}
				/>
			</Tooltip>
		</Container>
	);
};

export default CardTick;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	lineHeight: 1,
});
