export const integrationTypes = {
	BENTO_ISSUE: 'bentoIssue',
	CLASSIC_BOARD: 'classicBoard',
	NEXTGEN_BOARD: 'nextGenBoard',
	ROADMAP: 'roadmap',
	TIMELINE: 'timeline',
	LIST: 'list',
	CALENDAR: 'calendar',
	BOARD: 'board',
	FORM: 'form',
	SUMMARY: 'summary',
	ATTACHMENTS: 'attachments',
} as const;
