import type { PubSub, Subscriber } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class PubSubHub<Events extends string, Payloads extends { [Event in Events]: any }>
	implements PubSub<Events, Payloads>
{
	private subscribers: {
		[Event in Events]?: Array<Subscriber<Payloads[Event]>>;
	} = {};

	subscribe = <Event extends Events>(event: Event, subscriber: Subscriber<Payloads[Event]>) => {
		let subs = this.subscribers[event];
		if (!subs) {
			subs = [];
			this.subscribers[event] = subs;
		}
		subs?.push(subscriber);
		return () => this.unsubscribe(event, subscriber);
	};

	unsubscribe = <Event extends Events>(event: Event, subscriber: Subscriber<Payloads[Event]>) => {
		const subs = this.subscribers[event];
		if (subs) {
			this.subscribers[event] = subs.filter((subscription) => subscription !== subscriber);
		}
	};

	publish = <Event extends Events>(event: Event, data: Payloads[Event]) => {
		const subs = this.subscribers[event];
		if (subs?.length) {
			subs?.forEach((subscriber) => subscriber(data));
		}
	};

	clearAll = () => {
		this.subscribers = {};
	};
}
