import { filter } from 'icepick';
import type { FlagData } from '../../../../model/flags/flag-types';
import { FLAG_DISMISSED } from '../../../actions/flags';
import type { FlagsState } from './types';

// https://jdog.jira-dev.com/browse/OC-401
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ActionWithMeta = any;

export const isFlagAlreadyInState = (state: FlagsState, flag: FlagData) =>
	!!state.find((stateFlag) => stateFlag.id === flag.id);

export const flagsReducer = (state: FlagsState = [], action: ActionWithMeta): FlagsState => {
	if (action.type === FLAG_DISMISSED) {
		if (action.payload) {
			const { id } = action.payload;
			return filter((flag: FlagData) => flag.id !== id, state);
		}
	}

	// Add a flag to state if it is passed in the action's meta
	if (action.meta && action.meta.flag) {
		if (!isFlagAlreadyInState(state, action.meta.flag)) {
			return state.concat(action.meta.flag);
		}
	}

	return state;
};
