import React, { memo, useMemo } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import ToolTip from '@atlaskit/tooltip';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { Person } from '../../../model/people/people-types';
import messages from './messages';

type Props = {
	value: Person | null;
};

export const AssigneeFieldStatic = ({ value }: Props) => {
	const { formatMessage } = useIntl();

	const name = useMemo(
		() =>
			value?.id
				? formatMessage(messages.assigneeTooltip, { name: value.displayName })
				: formatMessage(messages.assigneeTooltipUnassigned),
		[value, formatMessage],
	);

	return (
		<AvatarWrapper data-testid="software-board.common.fields.assignee-field-static.avatar-wrapper">
			<ToolTip content={name}>
				<Avatar src={value?.avatarUrl} name={name} size="small" borderColor="transparent" />
			</ToolTip>
		</AvatarWrapper>
	);
};

export default memo<Props>(AssigneeFieldStatic);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: gridSize * 3.5,
	cursor: 'default',
});
