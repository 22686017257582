import { push, splice } from 'icepick';
import {
	type SwimlaneToggleAction,
	SWIMLANE_TOGGLE,
	type SwimlanesToggleAction,
	SWIMLANES_TOGGLE,
	SWIMLANES_COLLAPSE,
	type SwimlanesCollapseAction,
	SWIMLANES_EXPAND,
} from '../../../../actions/swimlane';
import type { Action } from '../../../../types';
import type { CollapsedState } from './types';

export const swimlanesCollapsedReducer = (
	state: CollapsedState = [],
	action: Action,
): CollapsedState => {
	if (action.type === SWIMLANE_TOGGLE) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { payload } = action as SwimlaneToggleAction;
		const index = state.indexOf(payload);
		if (index === -1) {
			return push(state, payload);
		}
		return splice(state, index, 1);
	}

	if (action.type === SWIMLANES_TOGGLE) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { payload } = action as SwimlanesToggleAction;

		if (state.length === payload.length) {
			return [];
		}
		return payload;
	}

	if (action.type === SWIMLANES_EXPAND) {
		return [];
	}

	if (action.type === SWIMLANES_COLLAPSE) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { payload } = action as SwimlanesCollapseAction;
		return payload;
	}

	return state;
};
