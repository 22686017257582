import React from 'react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import type { ApolloError } from 'apollo-client';
import {
	ExperienceFailureTracker as ViewExperienceFailureTracker,
	ExperienceAbortTracker as ViewExperienceAbortTracker,
	ExperienceSuccessTracker as ViewExperienceSuccessTracker,
} from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/result-declared/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { FetchError as EarlyScriptFetchError } from '@atlassian/jira-early-script-utils/src/types.tsx';
import { getErrorHash } from '@atlassian/jira-errors-handling/src/utils/error-hash.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { AGGError } from '@atlassian/jira-relay-errors';
import type { SwagError } from '@atlassian/jira-software-swag/src/common/utils/error/index.tsx';
import { Redirect } from '@atlassian/react-resource-router';
import { isBacklogWithRelayEnabled } from '../../feature-flags';
import { extractTraceId } from '../extract-trace-id';
import { extractStatusCode } from '../status-code';
import { createFailureReason, createFailureReasonWithStatusCode } from '../util';
import {
	REASON_CRITICAL_DATA_FAILED,
	REASON_CRITICAL_DATA_FAILED_WITH_STATUS_CODE,
	REASON_ABORT_429_ERROR,
	REASON_ABORT_USER_NETWORK_ERROR,
	REASON_ABORT_CHUNK_LOAD_ERROR,
	REASON_ABORT_CLIENT_FETCH_ERROR,
	REASON_ABORT_RESPONSE_NULL_ERROR,
	VALUE_UNKNOWN,
	REASON_ABORT_CLIENT_THIRD_PARTY_APP_ERROR,
} from './constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PartialError = Partial<Error> & Record<PropertyKey, any>;

type ExperienceFailureTrackerAttributes = {
	location: string;
	statusCode: number | null;
	traceId: string;
	hash: string;
	buildKey: string;
};

const renderExperienceFailureWithStatus = ({
	location,
	statusCode,
	traceId,
	hash,
	buildKey,
}: ExperienceFailureTrackerAttributes) => (
	<ViewExperienceFailureTracker
		location={location}
		failureEventAttributes={{
			...createFailureReasonWithStatusCode(
				REASON_CRITICAL_DATA_FAILED_WITH_STATUS_CODE,
				statusCode,
			),
			buildKey,
			traceId,
			hash,
		}}
	/>
);

export const isUserNetworkError = (error: PartialError): boolean => {
	const networkErrorName = get(error, ['networkError', 'name'], null);
	const networkErrorStatusCode = get(error, ['networkError', 'statusCode'], null);

	if (networkErrorName && !networkErrorStatusCode) {
		log.safeErrorWithoutCustomerData(
			'nextgen.sla.network.error.unknown.error.name',
			networkErrorName,
		);
	}

	// A fetch() promise will reject with a TypeError when a network error is encountered or CORS is misconfigured on the server-side,
	// although this usually means permission issues or similar — a 404 does not constitute a network error
	// more https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Checking_that_the_fetch_was_successful
	return networkErrorName === 'TypeError' || networkErrorName === 'AbortError';
};

export const isChunkLoadError = (error: PartialError): boolean => {
	if (error?.name === 'ChunkLoadError') {
		log.safeErrorWithoutCustomerData('nextgen.sla.chunk.load.error', error?.name, {
			message: error.message,
		});

		return true;
	}

	return false;
};

export const isResponseNullError = (error: PartialError): boolean => {
	if (error?.name === 'ResponseNullError') {
		log.safeErrorWithoutCustomerData('nextgen.sla.response.null.error', error?.name, {
			message: error.message,
		});
		return true;
	}

	return false;
};

const KNOWN_CLIENT_SIDE_THIRD_PARTY_APP_ERRORS = ['FPClassifier is not defined'];

export const isClientSideThirdPartyAppError = (error: PartialError): boolean =>
	!isNil(error) &&
	!isNil(error.message) &&
	!!KNOWN_CLIENT_SIDE_THIRD_PARTY_APP_ERRORS.find((errorMessage) =>
		error?.message?.includes(errorMessage),
	);

export const isFailureStatusCode = (statusCode: number | null): boolean => {
	if (statusCode == null || statusCode === 429 || statusCode >= 500) {
		// jira is too busy, unknown error or server error
		return true;
	}
	if (statusCode >= 400 && statusCode < 500) {
		return false;
	}
	return true;
};

const detectLocalNetworkErrors = (
	errorResponse: Partial<AGGError | SwagError | ApolloError | EarlyScriptFetchError | TypeError>,
): string | null => {
	if (isUserNetworkError(errorResponse)) return REASON_ABORT_USER_NETWORK_ERROR;
	if (isChunkLoadError(errorResponse)) return REASON_ABORT_CHUNK_LOAD_ERROR;
	if (isClientFetchError(errorResponse)) return REASON_ABORT_CLIENT_FETCH_ERROR;
	if (ff('jsw.cmp.response-null-abort') && isResponseNullError(errorResponse)) {
		return REASON_ABORT_RESPONSE_NULL_ERROR;
	}
	if (isClientSideThirdPartyAppError(errorResponse)) {
		return REASON_ABORT_CLIENT_THIRD_PARTY_APP_ERROR;
	}

	return null;
};

const responseToSla = (
	errorResponse: Partial<AGGError | SwagError | ApolloError | EarlyScriptFetchError | TypeError>,
	location: string,
) => {
	const statusCode = extractStatusCode(errorResponse);
	const abortFailureReason = detectLocalNetworkErrors(errorResponse);
	const traceId = extractTraceId(errorResponse) || VALUE_UNKNOWN;
	const hash = getErrorHash(errorResponse) || VALUE_UNKNOWN;
	const buildKey = get(window, ['BUILD_KEY']) || VALUE_UNKNOWN;
	const errorResponseMessage = errorResponse?.message || VALUE_UNKNOWN;

	if (abortFailureReason) {
		// Fire SLA Abort event to monitor the volume of client-side issues without impacting SLO

		return (
			<ViewExperienceAbortTracker
				location={location}
				failureEventAttributes={{
					...createFailureReason(abortFailureReason),
					error: errorResponseMessage,
					statusCode: statusCode || '',
					buildKey,
					traceId,
					hash,
				}}
			/>
		);
	}

	if (statusCode === null) {
		log.safeErrorWithoutCustomerData('nextgen.sla.unknown.failure', REASON_CRITICAL_DATA_FAILED, {
			networkErrorName: get(errorResponse, ['networkError', 'name'], ''),
			networkStatusCode: get(errorResponse, ['networkError', 'statusCode'], ''),
			graphqlStatusCode: get(errorResponse, ['graphQLErrors', 0, 'extensions', 'statusCode'], ''),
			location,
			error: JSON.stringify(errorResponse, Object.getOwnPropertyNames(errorResponse)),
			hash,
			isBacklogWithRelayEnabled: isBacklogWithRelayEnabled(),
		});

		return (
			<ViewExperienceFailureTracker
				location={location}
				failureEventAttributes={{
					...createFailureReason(REASON_CRITICAL_DATA_FAILED),
					traceId,
					buildKey,
					error: JSON.stringify({
						message: errorResponseMessage,
					}),
					hash,
				}}
			/>
		);
	}

	// based on https://hello.atlassian.net/wiki/spaces/agile/pages/512782292/SWAG+response+handling+on+the+client

	// 40x missing permission, experience does not exist, not logged in, etc
	if (statusCode === 400 || statusCode === 401 || statusCode === 403) {
		return <ViewExperienceSuccessTracker location={location} />;
	}
	if (statusCode === 429) {
		// jira is too busy
		return (
			<ViewExperienceAbortTracker
				location={location}
				failureEventAttributes={{
					...createFailureReason(REASON_ABORT_429_ERROR),
					error: errorResponseMessage,
					statusCode: '429',
					buildKey,
					traceId,
					hash,
				}}
			/>
		);
	}
	if (statusCode === 404) {
		// redirect to "all-projects" page, when page is not found
		return <Redirect to="/jira/projects?showFlag=jsw.project-not-found" />;
	}
	if (statusCode > 400 && statusCode < 500) {
		// the rest of 40X
		return <ViewExperienceSuccessTracker location={location} />;
	}
	if (statusCode >= 500) {
		return renderExperienceFailureWithStatus({
			location,
			statusCode,
			traceId,
			hash,
			buildKey,
		});
	}

	return renderExperienceFailureWithStatus({ location, statusCode, traceId, hash, buildKey });
};

export default responseToSla;
