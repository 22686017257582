import { expVal } from '@atlassian/jira-feature-experiments';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { FlagConfiguration } from '@atlassian/jira-flags';
import messages from './messages';

const UndoSuccessFlag: FlagConfiguration = {
	title: messages.title,
	type: 'success',
	isAutoDismiss: true,
};

const UndoSuccessFlagIssueTermRefresh: FlagConfiguration = {
	title: messages.titleIssueTermRefresh,
	type: 'success',
	isAutoDismiss: true,
};

const selector = functionWithCondition(
	() => expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false),
	() => UndoSuccessFlagIssueTermRefresh,
	() => UndoSuccessFlag,
);

const UndoSuccessFlagConfigurations = selector();
export default UndoSuccessFlagConfigurations;
