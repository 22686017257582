import { type Observable as ObservableType, Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import type { IssueId } from '@atlassian/jira-software-board-common';
import { query$ } from '@atlassian/jira-software-swag/src/services/query/index.tsx';
import type { FilteredCardIdsLoadGql } from '../../model/work/work-types';

const filteredCardsQuery = 'FilteredCardIds';

export const getQuery = () => `
    query ${filteredCardsQuery} ($boardId: ID!, $issueIds: [ID]!, $customFilterIds: [ID]!) {
        boardScope(boardId: $boardId) {
            filteredCardIds(
                issueIds: $issueIds,
                customFilterIds: $customFilterIds
            )
        }
    }
`;

interface Params {
	boardId: string;
	issueIds: IssueId[];
	customFilterIds: string[];
}

export function fetchTMPFilteredCardIds({
	boardId,
	issueIds,
	customFilterIds,
}: Params): ObservableType<string[]> {
	return query$<FilteredCardIdsLoadGql>('', filteredCardsQuery, getQuery(), {
		boardId,
		issueIds,
		customFilterIds,
	}).flatMap(({ boardScope }) => Observable.of(boardScope.filteredCardIds));
}
