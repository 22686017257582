import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-board-clearing.common.undo-success-flag.title',
		defaultMessage: 'We have restored your cleared issues',
		description: 'Message when the undo operation of a manual board clearing succeeds',
	},
	titleIssueTermRefresh: {
		id: 'software-board-clearing.common.undo-success-flag.title-issue-term-refresh',
		defaultMessage: 'We have restored your cleared issues',
		description: 'Message when the undo operation of a manual board clearing succeeds',
	},
});
