import { useIntl } from '@atlassian/jira-intl';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { CAN_DELETE_ISSUE } from '../../../../../../../../model/permission/permission-types';
import { useBoardActionCreator, useBoardSelector } from '../../../../../../../../state';
import { issueDeleteModalOpen } from '../../../../../../../../state/actions/issue/delete-modal';
import { getPermissionsSelector } from '../../../../../../../../state/selectors/board/board-permissions-selectors';
import { getColumnIndex } from '../../../../../../../../state/selectors/column/column-selectors';
import { getIssueById } from '../../../../../../../../state/selectors/issue/issue-selectors';
import { DELETE_ISSUE_MENU_ID } from '../../constants';
import messages from '../../messages';
import type { ContentProps, Props } from './types';

export const useDeleteIssueContent = ({
	isDeleteEnabled,
	onDeleteIssue,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	if (!isDeleteEnabled) return [];

	return [
		{
			type: MenuNodeType.Item,
			label: formatMessage(messages.delete),
			id: DELETE_ISSUE_MENU_ID,
			onClick: onDeleteIssue,
		},
	];
};

export const useDeleteIssue = ({ issueId, isMultiSelectMode }: Props): MenuNode[] => {
	const canDelete = useBoardSelector(getPermissionsSelector)[CAN_DELETE_ISSUE];
	const issue = useBoardSelector((state) => getIssueById(state, issueId));
	const columnIndex = useBoardSelector((state) => getColumnIndex(state, issue?.columnId));

	const onDeleteIssue = useBoardActionCreator(() => issueDeleteModalOpen(issueId, columnIndex));

	return useDeleteIssueContent({
		isDeleteEnabled: canDelete && !isMultiSelectMode,
		onDeleteIssue,
	});
};
