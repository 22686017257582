import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { SwimlaneModeId } from '@atlassian/jira-platform-board-kit/src/ui/swimlane/types.tsx';
import type { SwimlaneStrategyGQL } from '@atlassian/jira-software-board-fetch-scope-critical/src/types';
import mutateUserSwimlaneStrategyRest from '../../../rest/user-swimlane-strategy-mutate-graphql';
import type { SwimlaneStrategyMutationGQLData } from '../../../rest/user-swimlane-strategy-mutate-graphql/types';
import { transformSwimlaneModeToApi } from '../../swimlane/swimlane-data-transformer';

export const transform = (data: SwimlaneStrategyMutationGQLData): SwimlaneStrategyGQL =>
	data.strategy;

export default function mutateUserSwimlaneStrategyService(
	contextPath: string,
	boardId: string,
	swimlaneStrategy: SwimlaneModeId,
) {
	return mutateUserSwimlaneStrategyRest(
		contextPath,
		boardId,
		transformSwimlaneModeToApi(swimlaneStrategy),
	).catch((error) => {
		log.safeErrorWithoutCustomerData(
			'board.user.swimlane.strategy.mutate.graphql.failure',
			'Failed to mutate user swimlane strategy via GraphQL',
		);
		return Observable.throw(error);
	});
}
