import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-board-clearing.common.undo-flag.title',
		defaultMessage:
			'{numDoneCards, plural, one {Issue cleared from board} other {{numDoneCards} done issues cleared}}',
		description: 'Message title when the manual board clearing is triggered',
	},
	description: {
		id: 'software-board-clearing.common.undo-flag.description',
		defaultMessage:
			'{numDoneCards, plural, one {It is still in your project, and you can search for it later if you need to.} other {They’re still in your project, and you can search for them later if you need to.}}',
		description: 'Message description when the manual board clearing is triggered',
	},
	undo: {
		id: 'software-board-clearing.common.undo-flag.undo',
		defaultMessage: 'Undo',
		description: 'Link to undo change in the manual board clearing flag',
	},
	viewIssues: {
		id: 'software-board-clearing.common.undo-flag.view-issues',
		defaultMessage: 'View done issues',
		description: 'Link to view done issues in the manual board clearing flag',
	},
	titleIssueTermRefresh: {
		id: 'software-board-clearing.common.undo-flag.title-issue-term-refresh',
		defaultMessage:
			'{numDoneCards, plural, one {Issue cleared from board} other {{numDoneCards} done issues cleared}}',
		description: 'Message title when the manual board clearing is triggered',
	},
	viewIssuesIssueTermRefresh: {
		id: 'software-board-clearing.common.undo-flag.view-issues-issue-term-refresh',
		defaultMessage: 'View done issues',
		description: 'Link to view done issues in the manual board clearing flag',
	},
});
