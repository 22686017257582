import { useState, useEffect, useCallback } from 'react';
import isNil from 'lodash/isNil';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { ERROR_REPORTING_TEAM, PACKAGE_NAME } from '../../common/constants';
import type { Value } from './types';

export const useFetchCustomFieldValues = ({
	planId,
	scenarioId,
	fieldId,
}: {
	planId: string | undefined;
	scenarioId: string | undefined;
	fieldId: string | undefined;
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [values, setValues] = useState<Value[] | null>(null);
	const fetchCustomFieldValues = useCallback(
		async (query = '') => {
			try {
				if (isNil(planId)) {
					throw new Error('planId is empty in the creation IP board');
				}
				if (isNil(scenarioId)) {
					throw new Error('scenarioId is empty in the creation IP board');
				}
				if (isNil(fieldId)) {
					throw new Error('fieldId is empty in the creation IP board');
				}
				setIsLoading(true);
				const url = ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1')
					? `/rest/jpo/2.0/plans/${planId}/increments/scenarios/${scenarioId}/fieldId/${fieldId}/scopeFilterValues?query=${query}`
					: `/rest/jpo/2.0/plans/${planId}/increments/scenarios/${scenarioId}/fieldId/${fieldId}/scopeFilterValues`;
				// eslint-disable-next-line @typescript-eslint/no-shadow
				const values = await performGetRequest(url);
				setValues(values || []);
				setError(null);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow
			} catch (error: any) {
				fireErrorAnalytics({
					meta: {
						id: 'useFetchCustomFieldValues',
						packageName: PACKAGE_NAME,
						teamName: ERROR_REPORTING_TEAM,
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
				setError(error);
				setValues(null);
			} finally {
				setIsLoading(false);
			}
		},
		[planId, scenarioId, fieldId],
	);

	useEffect(() => {
		if (!ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1')) {
			if (!isNil(fieldId)) {
				fetchCustomFieldValues();
			} else {
				setValues(null);
			}
		} else if (isNil(fieldId)) {
			setValues(null);
		}
	}, [fieldId, fetchCustomFieldValues]);

	return {
		isLoading,
		error,
		values,
		fetchCustomFieldValues,
	};
};
