import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import uniqBy from 'lodash/uniqBy';
import {
	CHILD_HIERARCHY_TYPE,
	PARENT_HIERARCHY_TYPE,
} from '@atlassian/jira-issue-type-hierarchies';
import type { CardType, CardTypeKey } from '../../../model/software/software-types';
import {
	getIssueTypes,
	getProjectIssueTypes,
	getIssueTypesByProject,
} from '../software/software-selectors';

export const issueTypeIdsSelector = createSelector(getIssueTypes, (issueTypes) =>
	Object.keys(issueTypes),
);

export const issueBaseTypesSelector = createSelector(getIssueTypes, (issueTypes) =>
	Object.keys(issueTypes).map((id) => issueTypes[id]),
);

export const firstIssueTypeIdSelector = createSelector(issueTypeIdsSelector, (issueTypeIds) =>
	head(issueTypeIds),
);

export const issueParentTypesSelector = createSelector(getProjectIssueTypes, (projectIssueTypes) =>
	Object.keys(projectIssueTypes)
		.map((key) => projectIssueTypes[key])
		.filter((issueType) => issueType.hierarchyLevelType === PARENT_HIERARCHY_TYPE),
);

export const issueChildrenTypesSelector = createSelector(
	getProjectIssueTypes,
	(projectIssueTypes) =>
		Object.keys(projectIssueTypes)
			.map((key) => projectIssueTypes[key])
			.filter((issueType) => issueType.hierarchyLevelType === CHILD_HIERARCHY_TYPE),
);

export const issueChildrenTypeIdsSelector = createSelector(
	issueChildrenTypesSelector,
	(issueTypes) => issueTypes.map((issueType) => issueType.id),
);

export const hasRequiredFieldsForIssueTypeSelector = createSelector(
	getProjectIssueTypes,
	(projectIssueTypes) => (issueTypeId: CardTypeKey) =>
		!!projectIssueTypes[issueTypeId]?.hasRequiredFields,
);

const getDefaultIssueTypeIdPure = (issueTypes: CardType[]) => {
	const defaultIssueType = issueTypes.find((issueType) => issueType.default);

	return defaultIssueType ? Number(defaultIssueType.id) : undefined;
};

export const defaultIssueTypeIdSelector = createSelector([issueBaseTypesSelector], (issueTypes) =>
	getDefaultIssueTypeIdPure(issueTypes),
);

export const getIssueTypesGroupedByNameLevel = createSelector(
	[getIssueTypesByProject],
	(issueTypesByProject) => {
		const allIssueTypes = uniqBy(flatten(Object.values(issueTypesByProject)), 'id').filter(Boolean);
		const grouped = groupBy(
			allIssueTypes,
			({ name, hierarchyLevelType }) => `${hierarchyLevelType}/${name}`,
		);
		const issueTypeGroups = Object.entries(grouped).map(([key, issueTypes]) => ({
			issueTypes: issueTypes.map(({ id }) => id),
			name: issueTypes[0].name,
			iconUrl: issueTypes[0].iconUrl,
			key,
		}));
		return issueTypeGroups;
	},
);
