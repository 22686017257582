import type { IssueId } from '@atlassian/jira-software-board-common';
import type { CardTransition } from '../../../../model/card-transition/card-transition-types';

export const SHOW_UPDATE_ISSUE_PARENT_MODAL =
	'state.actions.issue.update-issue-parent-modal.SHOW_UPDATE_ISSUE_PARENT_MODAL' as const;

export type ShowUpdateIssueParentModalAction = {
	type: typeof SHOW_UPDATE_ISSUE_PARENT_MODAL;
	payload: {
		issueParentId: IssueId;
		transitions: Pick<CardTransition, 'name' | 'id'>[];
	};
};

export const showUpdateIssueParentModal = (
	issueParentId: IssueId,
	transitions: Pick<CardTransition, 'name' | 'id'>[],
): ShowUpdateIssueParentModalAction => ({
	type: SHOW_UPDATE_ISSUE_PARENT_MODAL,
	payload: {
		issueParentId,
		transitions,
	},
});

export const HIDE_UPDATE_ISSUE_PARENT_MODAL =
	'state.actions.issue.update-issue-parent-modal.HIDE_UPDATE_ISSUE_PARENT_MODAL' as const;

export type HideUpdateIssueParentModalAction = {
	type: typeof HIDE_UPDATE_ISSUE_PARENT_MODAL;
};

export const hideUpdateIssueParentModal = (): HideUpdateIssueParentModalAction => ({
	type: HIDE_UPDATE_ISSUE_PARENT_MODAL,
});

export type Action = ShowUpdateIssueParentModalAction | HideUpdateIssueParentModalAction;
