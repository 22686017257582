import React, { Component, type ReactNode } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import throttle from 'lodash/throttle';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { gridSize, layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	getDetailViewWidthRatio,
	setDetailViewWidthRatio,
} from '../../../services/software/software-storage';

type Props = {
	parentWidth: number;
	children: ReactNode;
	useFixedMinWidth?: boolean;
	onResize: ((arg1: number) => void) | undefined;
};

export const MIN_DETAILS_WIDTH_ISSUE = 440;
export const MIN_DETAILS_WIDTH_INSIGHTS = 440;
export const WINDOW_RESIZE_THROTTLE = 350;

const IS_DRAGGING_CLASS_NAME = 'ak-dnd-is-dragging';

type State = {
	width: number;
	parentWidth: number;
};

// eslint-disable-next-line jira/react/no-class-components
export default class Resizer extends Component<Props, State> {
	static defaultProps = {
		onResize: undefined,
	};

	constructor(props: Props) {
		super(props);
		const initValue = getDetailViewWidthRatio();
		this.state = {
			width: Math.max(props.parentWidth * initValue, MIN_DETAILS_WIDTH_ISSUE),
			parentWidth: props.parentWidth,
		};
	}

	static getDerivedStateFromProps(nextProps: Props, state: State): State {
		const { parentWidth } = nextProps;
		if (parentWidth !== state.parentWidth) {
			const initValue = getDetailViewWidthRatio();
			return {
				width: Math.max(parentWidth * initValue, MIN_DETAILS_WIDTH_ISSUE),
				parentWidth,
			};
		}
		return state;
	}

	componentDidMount() {
		const { useFixedMinWidth, onResize } = this.props;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('resize', this.onWindowResize);

		onResize?.(useFixedMinWidth ? MIN_DETAILS_WIDTH_INSIGHTS : this.state.width);
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		const { onResize, useFixedMinWidth } = this.props;

		if (prevProps.useFixedMinWidth !== useFixedMinWidth || prevState.width !== this.state.width) {
			onResize?.(useFixedMinWidth ? MIN_DETAILS_WIDTH_INSIGHTS : this.state.width);
		}
	}

	componentWillUnmount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('resize', this.onWindowResize);
	}

	onWindowResize = throttle(() => {
		const initValue = getDetailViewWidthRatio();
		const newValue = Math.max(this.props.parentWidth * initValue, MIN_DETAILS_WIDTH_ISSUE);

		if (newValue === this.state.width) {
			return;
		}

		this.setState({
			width: newValue,
		});
	}, WINDOW_RESIZE_THROTTLE);

	onMouseDown = (e: React.MouseEvent) => {
		this.startX = e.pageX + (this.state.width - MIN_DETAILS_WIDTH_ISSUE);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('mousemove', this.onMouseMove);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('mouseup', this.onMouseUp);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (document.body) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.body.classList.add(IS_DRAGGING_CLASS_NAME);
		}
	};

	onMouseMove = (e: MouseEvent) => {
		this.extraWidth = Math.max(this.startX - e.pageX, 0);
		const newValue = Math.min(
			MIN_DETAILS_WIDTH_ISSUE + this.extraWidth,
			this.props.parentWidth / 2,
		);

		if (newValue === this.state.width) {
			return;
		}

		this.setState({
			width: newValue,
		});
	};

	onMouseUp = () => {
		this.startX = 0;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('mousemove', this.onMouseMove);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('mouseup', this.onMouseUp);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (document.body) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.body.classList.remove(IS_DRAGGING_CLASS_NAME);
		}

		const widthRatio =
			this.state.width === MIN_DETAILS_WIDTH_ISSUE ? 0 : this.state.width / this.props.parentWidth;
		setDetailViewWidthRatio(widthRatio);
	};

	extraWidth = 0;

	startX = 0;

	render() {
		const { children, useFixedMinWidth } = this.props;

		return (
			// dynamic change of values
			<Container minWidth={useFixedMinWidth ? MIN_DETAILS_WIDTH_INSIGHTS : this.state.width}>
				{}
				<ResizeHandle
					onMouseDown={this.onMouseDown}
					data-testid="software-board.detail-view.resizer.resize-handler"
				/>
				{children}
			</Container>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const ContainerControl = styled.div<{ minWidth: string | number }>((props) => ({
	width: '400px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${props.minWidth || 400}px`,
	minHeight: '100%',
	maxHeight: '100%',
	overflowY: 'auto',
	overflowX: 'hidden',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('elevation.surface', colors.N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.card,
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerExperiment = styled2.div<{ minWidth: string | number }>({
	width: '400px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minWidth: (props) => `${props.minWidth || 400}px`,
	minHeight: '100%',
	maxHeight: '100%',
	overflowY: 'auto',
	overflowX: 'hidden',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('elevation.surface', colors.N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.card,
});

const Container = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	ContainerExperiment,
	ContainerControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResizeHandleControl = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize}px`,
	cursor: 'ew-resize',
	position: 'relative',
	alignSelf: 'stretch',
	'&::after': {
		content: "''",
		width: '2px',
		height: '20px',
		top: '50%',
		transform: 'translateY(-50%)',
		left: token('space.050', '4px'),
		transition: 'background 0.2s',
		position: 'absolute',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		background: token('color.border', colors.N30),
	},
	'&:hover::after, &:active::after': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		background: token('color.background.brand.bold', colors.B200),
		height: '100%',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResizeHandleExperiment = styled2.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize}px`,
	cursor: 'ew-resize',
	position: 'relative',
	alignSelf: 'stretch',
	'&::after': {
		content: "''",
		width: '2px',
		height: '20px',
		top: '50%',
		transform: 'translateY(-50%)',
		left: token('space.050', '4px'),
		transition: 'background 0.2s',
		position: 'absolute',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		background: token('color.border', colors.N30),
	},
	'&:hover::after, &:active::after': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		background: token('color.background.brand.bold', colors.B200),
		height: '100%',
	},
});

const ResizeHandle = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	ResizeHandleExperiment,
	ResizeHandleControl,
);
