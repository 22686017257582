/** @jsx jsx */
import React, { useCallback, type SyntheticEvent } from 'react';
import { css, jsx } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { DropdownItem as DropdownItemWithoutAnalytics } from '@atlaskit/dropdown-menu';
import { token } from '@atlaskit/tokens';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useRouterActions } from '@atlassian/react-resource-router';

const DropdownItem = ComponentWithAnalytics('dropdownItem', {
	onClick: 'clicked',
})(DropdownItemWithoutAnalytics);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OwnProps = Record<any, any>;

export type StateProps = {
	url: string;
	linkTitle: string;
	onClick?: () => void;
	useHref?: boolean;
};

export type Props = OwnProps & StateProps;

const linkWrapperStyles = css({
	display: 'inline-flex',
	alignItems: 'center',
	width: '100%',
	paddingBottom: token('space.050', '4px'),
	/* Higher specificity to override DropdownItem's styles */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&& span': {
		width: '100%',
	},
	/* Higher specificity to override DropdownItem's styles for first span */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&& > span': {
		padding: `${token('space.100', '8px')} ${token('space.150', '12px')} 7px
        ${token('space.150', '12px')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&& > a': {
		width: '100%',
		padding: `${token('space.100', '8px')} ${token('space.150', '12px')} 7px
        ${token('space.250', '20px')}`,
	},
});

export const LinkItem = ({ url, linkTitle, onClick, useHref = false }: Props) => {
	const { push } = useRouterActions();
	const onDropdownClick = useCallback(
		(event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'clickLinkFromFilters');
			onClick && onClick();
			if (!useHref) {
				push(url);
			}
		},
		[useHref, onClick, push, url],
	);

	return (
		<div css={linkWrapperStyles}>
			{useHref ? (
				/* @ts-expect-error - Type '(event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void' is not assignable to type '(e: MouseEvent<Element, MouseEvent> | KeyboardEvent<Element>, args_1: UIAnalyticsEvent) => any'. */
				<DropdownItem href={url} onClick={onDropdownClick}>
					{linkTitle}
				</DropdownItem>
			) : (
				/* @ts-expect-error - Type '(event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void' is not assignable to type '(e: MouseEvent<Element, MouseEvent> | KeyboardEvent<Element>, args_1: UIAnalyticsEvent) => any'. */
				<DropdownItem onClick={onDropdownClick}>{linkTitle}</DropdownItem>
			)}
		</div>
	);
};

export default LinkItem;
