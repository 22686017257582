import isEmpty from 'lodash/isEmpty';
import type {
	NonEmptyArray,
	HideState,
	OverflowState,
	OverflowConfigItem,
	SelectedItems,
} from './types';

interface EvalResult {
	usedSpace: number;
	hideState: HideState;
}

export const evalElements = (
	items: OverflowConfigItem[],
	itemElements: { [id: string]: HTMLElement },
	spaceLimit?: number,
): EvalResult => {
	const hideState: HideState = {};
	let usedSpace = 0;
	let isOverflowingAssumed = false;

	items.forEach(({ id }: OverflowConfigItem) => {
		const element = itemElements[id];

		if (element) {
			const { offsetWidth: space } = element;

			if ((spaceLimit === undefined || usedSpace + space < spaceLimit) && !isOverflowingAssumed) {
				usedSpace += space;
			} else {
				if (!isOverflowingAssumed) {
					isOverflowingAssumed = true;
				}

				hideState[id] = true;
			}
		}
	});

	return { usedSpace, hideState };
};

export const calcOverflow = (
	{ offsetWidth: availableSpace }: HTMLElement,
	{ offsetWidth: moreFilterSpace }: HTMLElement,
	renderedOverflowableItems: NonEmptyArray<OverflowConfigItem>,
	staticItems: OverflowConfigItem[],
	itemElements: { [id: string]: HTMLElement },
): OverflowState => {
	const { usedSpace: staticSpace } = evalElements(staticItems, itemElements);
	const maxOverflowableSpace = renderedOverflowableItems.reduce(
		(maxSpace: number, { id }: OverflowConfigItem) =>
			maxSpace + (itemElements[id]?.offsetWidth ?? 0),
		0,
	);
	const isOverflowing = maxOverflowableSpace + staticSpace > availableSpace;

	let overflowableHideState: HideState = {};

	if (isOverflowing) {
		const spaceLimit = Math.max(availableSpace - moreFilterSpace - staticSpace, 0);
		const { hideState } = evalElements(renderedOverflowableItems, itemElements, spaceLimit);

		overflowableHideState = hideState;
	}

	return { overflowableHideState, isMoreDropdownHidden: !isOverflowing };
};

const BADGE_WIDTH_THRESHOLD = 25; // Width of count badge
/**
 * Should NOT re-calculate overflow
 *      if the container width did not change AND the items width in the container did not change
 */
export const getShouldRecomputeOverflow = (
	prevContainerWidth: number,
	currContainerWidth: number,
	prevElementsWidthRef: { [id: string]: number },
	currElementsWidthRef: { [id: string]: number },
): boolean => {
	const currItemIds = Object.keys(currElementsWidthRef);
	const prevItemIds = Object.keys(prevElementsWidthRef);

	if (prevItemIds.length !== currItemIds.length || prevContainerWidth !== currContainerWidth) {
		return true;
	}

	const hasItemsWidthChange = currItemIds.some(
		(id: string) =>
			prevElementsWidthRef[id] === undefined ||
			Math.abs(prevElementsWidthRef[id] - currElementsWidthRef[id]) > BADGE_WIDTH_THRESHOLD,
	);

	return hasItemsWidthChange;
};

/**
 * Return the sum of selected items if the item is hidden
 */
export const getSelectedItemsCount = (
	itemsHiddenState: HideState,
	selectedItems: SelectedItems,
): number => {
	if (isEmpty(selectedItems) || isEmpty(itemsHiddenState)) {
		return 0;
	}

	return Object.entries(selectedItems).reduce((acc, [key, value]) => {
		if (value === undefined || itemsHiddenState[key] !== true) {
			return acc;
		}
		// selectedItem could be "any" value, if the value is not an Array, it will be counted as 1
		return Array.isArray(value) ? acc + value.length : acc + 1;
	}, 0);
};

interface Dimensions {
	width: number;
	height: number;
}

export interface RefObject {
	[key: string]: Dimensions;
}
