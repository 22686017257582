import type { PanelType } from '../../../model/panels/types';

export const TOGGLE_PANEL = 'state.actions.panels.TOGGLE_PANEL' as const;

export type TogglePanelAction = {
	type: typeof TOGGLE_PANEL;
	payload: {
		panel: PanelType;
	};
};

export const togglePanel = (panel: PanelType): TogglePanelAction => ({
	type: TOGGLE_PANEL,
	payload: {
		panel,
	},
});

export const OPEN_INSIGHTS_PANEL = 'state.actions.panels.OPEN_INSIGHTS_PANEL' as const;

export type OpenInsightsPanelAction = {
	type: typeof OPEN_INSIGHTS_PANEL;
};

export const openInsightsPanel = (): OpenInsightsPanelAction => ({
	type: OPEN_INSIGHTS_PANEL,
});

export const CLOSE_INSIGHTS_PANEL = 'state.actions.panels.CLOSE_INSIGHTS_PANEL' as const;

export type CloseInsightsPanelAction = {
	type: typeof CLOSE_INSIGHTS_PANEL;
};

export const closeInsightsPanel = (): CloseInsightsPanelAction => ({
	type: CLOSE_INSIGHTS_PANEL,
});

export const CLOSE_VIEW_SETTINGS_PANEL = 'state.actions.panels.CLOSE_VIEW_SETTINGS_PANEL' as const;

export type CloseViewSettingsPanelAction = {
	type: typeof CLOSE_VIEW_SETTINGS_PANEL;
};

export const closeViewSettingsPanel = (): CloseViewSettingsPanelAction => ({
	type: CLOSE_VIEW_SETTINGS_PANEL,
});

export type Action =
	| TogglePanelAction
	| OpenInsightsPanelAction
	| CloseInsightsPanelAction
	| CloseViewSettingsPanelAction;
