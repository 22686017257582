import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	moveTo: {
		id: 'software.main.board-container.board.card-container.card.context-menu.move-to',
		defaultMessage: 'Move to',
		description: 'Label for context menu action to move a card to another list',
	},
	column: {
		id: 'software.main.board-container.board.card-container.card.context-menu.column',
		defaultMessage: 'Column {columnName}',
		description: 'Label for the move to column options',
	},
	topOfTheColumn: {
		id: 'software.main.board-container.board.card-container.card.context-menu.top-of-the-column',
		defaultMessage: 'Top of column',
		description: 'Label for context menu action to move a card to the top of the column',
	},
	bottomOfTheColumn: {
		id: 'software.main.board-container.board.card-container.card.context-menu.bottom-of-the-column',
		defaultMessage: 'Bottom of column',
		description: 'Label for context menu action to move a card to the bottom of the column',
	},
	backlog: {
		id: 'software.main.board-container.board.card-container.card.context-menu.backlog',
		defaultMessage: 'Backlog',
		description: 'Label for context menu action to move a card to the backlog',
	},
	viewInIssueSearch: {
		id: 'software.main.board-container.board.card-container.card.context-menu.view-in-issue-search',
		defaultMessage: 'View in issue search',
		description: 'Label for context menu action to view selected issues in issue search.',
	},
	label: {
		id: 'software.main.board-container.board.card-container.card.context-menu.label',
		defaultMessage: 'Add label',
		description: 'Label for context menu action to add label to the selected issue',
	},
	flag: {
		id: 'software.main.board-container.board.card-container.card.context-menu.flag',
		defaultMessage: 'Flag',
		description: 'Label for context menu action to flag or remove flag from a card',
	},
	addFlag: {
		id: 'software.main.board-container.board.card-container.card.context-menu.add-flag',
		defaultMessage: 'Add flag',
		description: 'Label for context menu action to add a flag',
	},
	removeFlag: {
		id: 'software.main.board-container.board.card-container.card.context-menu.remove-flag',
		defaultMessage: 'Remove flag',
		description: 'Label for context menu action to remove a flag',
	},
	addIssueParent: {
		id: 'software.main.board-container.board.card-container.card.context-menu.add-issue-parent',
		defaultMessage: 'Add parent',
		description: 'Label for context menu action to add a parent to the selected issue(s).',
	},
	changeIssueParent: {
		id: 'software.main.board-container.board.card-container.card.context-menu.change-issue-parent',
		defaultMessage: 'Change parent',
		description: 'Label for context menu action to change the parent of the selected issue(s).',
	},
	bulkChange: {
		id: 'software.main.board-container.board.card-container.card.context-menu.bulk-change',
		defaultMessage: 'Bulk change',
		description: 'Label for context menu action to go to bulk editor screen.',
	},
	bulkChangeIconLabel: {
		id: 'software.main.board-container.board.card-container.card.context-menu.bulk-change-icon-label',
		defaultMessage: 'Open in new window',
		description:
			'Label for the icon displayed in the context menu action to go to bulk editor screen with the selected issues(s). The icon indicates that the action will open a new browser window.',
	},
	removeFromSprint: {
		id: 'software.main.board-container.board.card-container.card.context-menu.remove-from-sprint',
		defaultMessage: 'Remove from sprint',
		description:
			'Option in the card context menu on each issue in board to remove from the current sprint',
	},
	delete: {
		id: 'software.main.board-container.board.card-container.card.context-menu.delete',
		defaultMessage: 'Delete',
		description: 'Label for context menu action to delete a card',
	},
	archive: {
		id: 'software.main.board-container.board.card-container.card.context-menu.archive',
		defaultMessage: 'Archive',
		description: 'Label for context menu action to archive a card',
	},
	menuTriggerOneCard: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-trigger-one-card',
		defaultMessage: 'More actions for {issueKey} {summary}',
		description:
			'Label for meatball menu button which open list with issue actions (one card selected)',
	},
	menuTriggerMoreCards: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-trigger-more-cards',
		defaultMessage: 'More actions for {issueKey} {summary} and other selected issues',
		description:
			'Label for meatball menu button which open list with issue actions(more that one card selected)',
	},
	unscheduleIncrement: {
		id: 'software.main.board-container.board.card-container.card.context-menu.unschedule-increment',
		defaultMessage: 'Unschedule',
		description: 'Label for context menu action to unschedule an issue from a planned increment',
	},
	changeTeam: {
		id: 'software.main.board-container.board.card-container.card.context-menu.change-team',
		defaultMessage: 'Change team',
		description: 'Label for context menu action to change the team',
	},
	unassign: {
		id: 'software.main.board-container.board.card-container.card.context-menu.unassign',
		defaultMessage: 'Unassign',
		description: 'Label for context menu action to unassign the team',
	},
});
