import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Box, media, xcss } from '@atlaskit/primitives';
import { ACTION_SUBJECT_ID as WORK_SUGGESTION_TOGGLE_ACTION_SUBJECT_ID } from '@atlassian/jira-insights-next-best-task/src/services/use-next-best-task-user-preference/constants';
import { useNextBestTaskUserPreference } from '@atlassian/jira-insights-next-best-task/src/services/use-next-best-task-user-preference/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { nextBestTaskFeatureResource } from '@atlassian/jira-router-resources-next-best-task/src/services/index.tsx';
import { View, ViewAs } from '@atlassian/jira-software-view-settings/src/common/types/constant.tsx';
import ViewSettings from '@atlassian/jira-software-view-settings/src/ui/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import { NO_SWIMLANE } from '../../../model/swimlane/swimlane-modes';
import { useBoardActionCreator, useBoardSelector } from '../../../state';
import { fetchCardCovers } from '../../../state/actions/card-covers';
import { fetchIssueLinksStats } from '../../../state/actions/issue-links-stats';
import { swimlanesCollapse, swimlanesExpand } from '../../../state/actions/swimlane';
import { getIssueLinksPermissions } from '../../../state/selectors/issue-links-permissions/issue-links-permissions-selectors';
import { boardOrderedIssueIdsSelector } from '../../../state/selectors/issue/board-issue-selectors';
import { getFirstSelectableIssueAscending } from '../../../state/selectors/issue/issue-navigation-selectors';
import {
	getCardExtraFields,
	getHierarchyNomenclature,
	getIsDaysInColumnEnabled,
	getIsDueDateEnabled,
	getIsEstimateEnabled,
	getIsIssueLabelsEnabled,
	getIsPriorityEnabled,
	getIsDevelopmentEnabled,
	rapidViewIdSelector,
} from '../../../state/selectors/software/software-selectors';
import { getSwimlaneMode } from '../../../state/selectors/swimlane/swimlane-mode-selectors';
import {
	getCustomFilters,
	platformSwimlaneIdsSelector,
} from '../../../state/selectors/work/work-selectors';
import { useIsCMPBoard, useIsJSWBoard } from '../../../state/state-hooks/capabilities';

type Props = {
	isPanelOpen: boolean;
	onPanelClose: () => void;
};

export const ViewSettingsPanel = ({ isPanelOpen, onPanelClose }: Props) => {
	const rapidViewId = useBoardSelector(rapidViewIdSelector);
	const epicCustomName = useBoardSelector(getHierarchyNomenclature)?.firstLevelName;
	const hasSwimlanes = useBoardSelector(getSwimlaneMode) !== NO_SWIMLANE.id;
	const swimlaneIds = useBoardSelector(platformSwimlaneIdsSelector);

	const onSwimlaneExpandAll = useBoardActionCreator(() => swimlanesExpand());
	const onSwimlaneCollapseAll = useBoardActionCreator(() => swimlanesCollapse(swimlaneIds));

	const isCompanyManaged = useIsCMPBoard();
	const isQuickFilterAvailable = useBoardSelector(getCustomFilters).length > 0;
	const showFilterBarToggle = isCompanyManaged && isQuickFilterAvailable;

	const isDaysInColumnEnabled = useBoardSelector(getIsDaysInColumnEnabled);
	const isEstimateEnabled = useBoardSelector(getIsEstimateEnabled);
	const isDueDateEnabled = useBoardSelector(getIsDueDateEnabled);
	const isLabelsEnabled = useBoardSelector(getIsIssueLabelsEnabled);
	const isPriorityEnabled = useBoardSelector(getIsPriorityEnabled);
	const isDevelopmentEnabled = useBoardSelector(getIsDevelopmentEnabled);
	const isJSWBoard = useIsJSWBoard();

	const cardExtraFields = useBoardSelector(getCardExtraFields);
	const firstSelectableIssueId = useBoardSelector(getFirstSelectableIssueAscending);
	const { canUserLinkIssue, issueLinkTypes } = useBoardSelector(getIssueLinksPermissions);

	const isIssueLinkingEnabledOnInstance = canUserLinkIssue && issueLinkTypes?.length > 0;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const boardIssueIds = useBoardSelector(boardOrderedIssueIdsSelector);
	const onLinkedIssuesStatsToggledOn = useBoardActionCreator(() =>
		fetchIssueLinksStats(boardIssueIds, { analyticsEvent: createAnalyticsEvent({}) }),
	);
	const onShowCardCoversToggledOn = useBoardActionCreator(() =>
		fetchCardCovers(boardIssueIds, { analyticsEvent: createAnalyticsEvent({}) }),
	);

	const {
		data: isWorkSuggestionsEnabledData,
		update: updateWorkSuggestionsResource,
		loading: loadingWorkSuggestionsEnabled,
	} = useResource(nextBestTaskFeatureResource);

	const [, { doFetch: updateWorkSuggestionsEnabled }] = useNextBestTaskUserPreference();

	const onToggleWorkSuggestions = (analyticsEvent: UIAnalyticsEvent) => {
		const newOptInValue = !isWorkSuggestionsEnabledData?.hasUserOptedIn;
		updateWorkSuggestionsResource(() => ({
			hasUserOptedIn: newOptInValue,
		}));
		updateWorkSuggestionsEnabled({ hasUserOptedIn: newOptInValue });
		fireUIAnalytics(analyticsEvent, WORK_SUGGESTION_TOGGLE_ACTION_SUBJECT_ID, {
			hasUserOptedIn: newOptInValue,
		});
	};

	return (
		<Box xcss={ViewSettingWrapperStyles}>
			<ViewSettings
				isViewPanelOpen={isPanelOpen}
				onViewPanelClose={onPanelClose}
				boardId={Number(rapidViewId)}
				isClassic={isCompanyManaged}
				view={View.BOARD}
				viewAs={ViewAs.PANEL}
				epicCustomName={epicCustomName}
				// showdetails is true for JSW board
				showCardDetails
				isSwimlanesEnabled={hasSwimlanes}
				onSwimlaneExpandAll={onSwimlaneExpandAll}
				onSwimlaneCollapseAll={onSwimlaneCollapseAll}
				showFilterBarToggle={showFilterBarToggle}
				isDaysInColumnEnabled={isDaysInColumnEnabled}
				isEstimateEnabled={isEstimateEnabled}
				onToggleWorkSuggestions={onToggleWorkSuggestions}
				isDueDateEnabled={isDueDateEnabled}
				isPriorityEnabled={isPriorityEnabled}
				isDevelopmentEnabled={isDevelopmentEnabled}
				isLabelsEnabled={!isCompanyManaged && isLabelsEnabled}
				isWorkSuggestionsEnabled={!!isWorkSuggestionsEnabledData?.hasUserOptedIn}
				showWorkSuggestions={
					isWorkSuggestionsEnabledData != null && !loadingWorkSuggestionsEnabled && isJSWBoard
				}
				showLinkedIssueStatsToggle={
					isIssueLinkingEnabledOnInstance && Boolean(firstSelectableIssueId)
				}
				onLinkedIssuesStatsToggledOn={onLinkedIssuesStatsToggledOn}
				cardExtraFields={cardExtraFields}
				onShowCardCoversToggledOn={onShowCardCoversToggledOn}
			/>
		</Box>
	);
};

const ViewSettingWrapperStyles = xcss({
	overflowX: 'hidden',
	overflowY: 'auto',
	paddingRight: 'space.500',
	paddingBottom: 'space.300',
	minWidth: '330px',
	[media.above.lg]: {
		minWidth: '360px',
	},
});
