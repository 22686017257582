import type { Observable as ObservableType } from 'rxjs/Observable';
import type {
	IssuesLinksResponse,
	IssueIds,
} from '../../model/issue-links-stats/issue-links-stats-types';
import { getIssueLinksStats } from '../../rest/issue-links-stats';
/**
 * Get all issues links stats on board cards using the REST API.
 */
export const fetchIssueLinksStatsService = (
	issueIds: IssueIds,
): ObservableType<IssuesLinksResponse> => getIssueLinksStats(issueIds);
