import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';
import type { CardTransitionEntities } from '../../../../model/card-transition/card-transition-types';
import type { Column, ColumnId } from '../../../../model/column/column-types';
import type { OptimisticId } from '../../../../model/software/software-types';
import type { FlagMeta } from '../../meta/flag-meta';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiWithFlagMeta,
	type OptimisticUiMeta,
} from '../../meta/optimistic-ui-meta';

export const COLUMN_CREATE_REQUEST = 'state.actions.column.create.COLUMN_CREATE_REQUEST' as const;

export type ColumnCreateRequestAction = {
	type: typeof COLUMN_CREATE_REQUEST;
	payload: {
		temporaryColumnId: ColumnId;
		name: string;
	};
	meta: {
		analyticsEvent: AnalyticsEvent;
		isCMPBoard: boolean;
	} & OptimisticUiMeta;
};

export const columnCreateRequest = (
	temporaryColumnId: ColumnId,
	name: string,
	analyticsEvent: AnalyticsEvent,
	isCMPBoard: boolean,
): ColumnCreateRequestAction => ({
	type: COLUMN_CREATE_REQUEST,
	payload: {
		temporaryColumnId,
		name,
	},
	meta: {
		...beginOptimisticUiMeta(),
		analyticsEvent,
		isCMPBoard,
	},
});

export const COLUMN_CREATE_SUCCESS = 'state.actions.column.create.COLUMN_CREATE_SUCCESS' as const;

export type ColumnCreateSuccessAction = {
	type: typeof COLUMN_CREATE_SUCCESS;
	payload: {
		temporaryColumnId: ColumnId;
		column: Column;
		columns: Column[];
		cardTransitions: CardTransitionEntities | null;
	};
	meta: {
		analyticsEvent: AnalyticsEvent;
	} & OptimisticUiMeta;
};

export const columnCreateSuccess = (
	optimisticId: OptimisticId,
	temporaryColumnId: ColumnId,
	column: Column,
	columns: Column[],
	cardTransitions: CardTransitionEntities | null,
	analyticsEvent: AnalyticsEvent,
): ColumnCreateSuccessAction => ({
	type: COLUMN_CREATE_SUCCESS,
	payload: {
		temporaryColumnId,
		column,
		columns,
		cardTransitions,
	},
	meta: {
		...commitOptimisticUiMeta(optimisticId),
		analyticsEvent,
	},
});

export const COLUMN_CREATE_FAILURE = 'state.actions.column.create.COLUMN_CREATE_FAILURE' as const;

export type ColumnCreateFailureAction = {
	type: typeof COLUMN_CREATE_FAILURE;
	payload: {
		temporaryColumnId: ColumnId;
	};
	error: string;
	meta: {
		analyticsEvent: AnalyticsEvent;
	} & OptimisticUiMeta &
		FlagMeta;
};

export const columnCreateFailure = (
	optimisticId: OptimisticId,
	temporaryColumnId: ColumnId,
	error: string,
	analyticsEvent: AnalyticsEvent,
): ColumnCreateFailureAction => ({
	type: COLUMN_CREATE_FAILURE,
	payload: {
		temporaryColumnId,
	},
	error,
	meta: {
		...revertOptimisticUiWithFlagMeta(optimisticId),
		analyticsEvent,
	},
});

export type Action =
	| ColumnCreateRequestAction
	| ColumnCreateSuccessAction
	| ColumnCreateFailureAction;
