/** @jsx jsx */
import { useCallback, useEffect, useState } from 'react';
import { css, jsx } from '@compiled/react';
import OriginTracer from '@atlassiansox/origin-tracing';
import { graphql, useLazyLoadQuery } from 'react-relay';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import CloseIcon from '@atlaskit/icon/core/close';
import SuccessIcon from '@atlaskit/icon/core/migration/success--editor-success';
import Link from '@atlaskit/link';
import { Box, Inline, Stack, xcss, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { FormattedMessage } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { issueCreatedFlagGetTrialLinkQuery } from '@atlassian/jira-relay/src/__generated__/issueCreatedFlagGetTrialLinkQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import NowAddYourTeamIllustration from '../../../common/assets/now-add-your-team.svg';
import { setHasSeenFlag } from '../../common/utils/local-storage';
import { ReloadPageModal } from './reload-page-modal';

export interface IssueCreatedFlagProps {
	flagId: string;
	onDismiss?: () => void;
	issueKeys: string[];
}

export function IssueCreatedFlag(props: IssueCreatedFlagProps) {
	return (
		<JSErrorBoundary
			id="rtfIssueCreatedFlag"
			packageName="jiraRecommendTrialToFree"
			teamName="luna-growth"
			fallback="unmount"
		>
			<IssueCreatedFlagContent {...props} />
		</JSErrorBoundary>
	);
}

export function IssueCreatedFlagContent({ flagId, onDismiss, issueKeys }: IssueCreatedFlagProps) {
	const [showReloadPageModal, setShowReloadPageModal] = useState(false);
	const openModal = useCallback(() => setShowReloadPageModal(true), []);
	const closeModal = useCallback(() => setShowReloadPageModal(false), []);
	const { dismissFlag } = useFlagsService();
	const cloudId = useCloudId();
	const linkToTrial = useLazyLoadQuery<issueCreatedFlagGetTrialLinkQuery>(
		graphql`
			query issueCreatedFlagGetTrialLinkQuery(
				$cloudId: ID!
				$productKey: String!
				$offeringKey: ID
			) {
				tenantContexts(cloudIds: [$cloudId]) {
					entitlementInfo(hamsProductKey: $productKey) {
						entitlement {
							experienceCapabilities {
								changeOffering(offeringKey: $offeringKey) {
									experienceUrl
								}
							}
						}
					}
				}
			}
		`,
		{
			cloudId,
			productKey: 'jira-software.ondemand',
			offeringKey: 'a70b5cbb-1ae1-4003-8f4a-9001a4a50526',
		},
	)?.tenantContexts?.[0]?.entitlementInfo?.entitlement?.experienceCapabilities?.changeOffering
		?.experienceUrl;

	useEffect(() => {
		setHasSeenFlag(flagId);
	}, [flagId]);

	const origin = new OriginTracer({ product: 'jira' });
	const issueCount = issueKeys.length;

	if (showReloadPageModal) {
		return <ReloadPageModal closeModal={closeModal} />;
	}

	return (
		<ContextualAnalyticsData sourceType={SCREEN} sourceName="recommendTrialToFreeFlag">
			<FireScreenAnalytics />
			<Box xcss={cardStyles}>
				<Stack alignInline="start" spread="space-between">
					<Inline
						alignInline="stretch"
						alignBlock="center"
						grow="fill"
						spread="space-between"
						xcss={inlineStyles}
					>
						<Inline alignBlock="center" space="space.200">
							<SuccessIcon
								label="success"
								color={token('color.icon.success')}
								LEGACY_size="large"
								spacing="spacious"
							/>
							<Box xcss={titleStyles}>
								<Text>
									{issueCount > 1 ? (
										<FormattedMessage
											id="jira-recommend-trial-to-free.issue-created.card-title-multiple"
											defaultMessage="You've created {issueCount, plural, one {# issue} other {# issues}}"
											description="Title for flag that appears when a user creates more than one issue at a time."
											values={{ issueCount }}
										/>
									) : (
										<FormattedMessage
											id="jira-recommend-trial-to-free.issue-created.card-title-single"
											defaultMessage={'You\'ve created "{issueKey}" issue'}
											description="Title for flag that appears when a user creates one single issue."
											values={{ issueKey: issueKeys[0] }}
										/>
									)}
								</Text>
							</Box>
						</Inline>
						<Button
							appearance="subtle-link"
							onClick={(_, analyticsEvent) => {
								fireUIAnalytics(analyticsEvent, 'rtfDismissFlag');
								if (onDismiss) {
									onDismiss();
									return;
								}
								dismissFlag(flagId);
							}}
							aria-label="Close"
						>
							<CloseIcon label="close" color={token('color.icon')} />
						</Button>
					</Inline>
					<Inline
						space="space.200"
						alignBlock="center"
						spread="space-between"
						grow="fill"
						xcss={contentContainerStyles}
					>
						<Stack
							alignBlock="center"
							alignInline="start"
							space="space.100"
							xcss={mainContentStyles}
						>
							<Heading size="small">
								<FormattedMessage
									id="jira-recommend-trial-to-free.issue-created.content-title"
									defaultMessage="Now add your team"
									description="Content title for flag that appears when a user creates an issue. It encourages the user to try more features."
								/>
							</Heading>
							<Box xcss={bodyStyles}>
								<Text>
									<FormattedMessage
										id="jira-recommend-trial-to-free.issue-created.content"
										defaultMessage="Upgrade to get unlimited users and add everyone you work with."
										description="Content for flag that appears when a user creates an issue. It encourages the user to try more features."
									/>
								</Text>
							</Box>
							{linkToTrial && (
								<Link
									href={origin.addToUrl(linkToTrial)}
									onClick={(_, analyticsEvent) => {
										fireUIAnalytics(analyticsEvent, 'rtfTryItFree');
										openModal();
									}}
									target="_blank"
								>
									<Text color="color.link">
										<FormattedMessage
											id="jira-recommend-trial-to-free.issue-created.try-it-now-link"
											defaultMessage="Try it free"
											description="Link in flag that sends user to sign up for a trial"
										/>
									</Text>
								</Link>
							)}
						</Stack>
						<Stack alignBlock="center" alignInline="center" xcss={illustrationStackStyles}>
							<img src={NowAddYourTeamIllustration} alt="" css={illustrationStyles} />
						</Stack>
					</Inline>
				</Stack>
			</Box>
		</ContextualAnalyticsData>
	);
}

const cardStyles = xcss({
	borderColor: 'color.border',
	borderRadius: '3px',
	boxShadow: 'elevation.shadow.overlay',
	display: 'flex',
	flexDirection: 'column',
	backgroundColor: 'elevation.surface.overlay',
});

const inlineStyles = xcss({
	borderBottom: '1px solid',
	borderColor: 'color.border',
	padding: 'space.200',
});

const titleStyles = xcss({
	fontSize: 'font.body',
});

const contentContainerStyles = xcss({
	background: 'color.neutral.light',
	paddingInline: 'space.250',
	paddingBlock: 'space.400',
	width: '100%',
});

const mainContentStyles = xcss({
	maxWidth: '240px',
});

const bodyStyles = xcss({
	fontSize: 'font.body',
	lineHeight: '20px',
	fontWeight: '400',
});

const illustrationStyles = css({
	maxHeight: '149px',
	maxWidth: '149px',
});

const illustrationStackStyles = xcss({
	paddingTop: 'space.200',
});
