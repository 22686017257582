import { freeze } from 'icepick';
import { OPEN_PLAN_ISSUE_MODAL, CLOSE_PLAN_ISSUE_MODAL } from '../../../actions/issue/modal';
import type { Action } from '../../../types';
import type { PlanIssueModalState } from './types';

const initialState: PlanIssueModalState = freeze({
	selectedIssueId: null,
});

export const planIssueModalReducer = (
	state: PlanIssueModalState = initialState,
	action: Action,
) => {
	if (action.type === OPEN_PLAN_ISSUE_MODAL) {
		if (state.selectedIssueId !== action.payload.issueId) {
			return {
				...state,
				selectedIssueId: action.payload.issueId,
			};
		}
		return state;
	}

	if (action.type === CLOSE_PLAN_ISSUE_MODAL) {
		return initialState;
	}

	return state;
};
