import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import SearchNoResults from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import commonMessages from '../../../../common/messages';
import { usePlanPathParams } from '../../../../hooks/use-increments-link';
import BoardSwitcher from '../../../board-switcher';
import messages from './messages';
import type { Props } from './types';

const ProgramBoardCustomFieldOptionNotFound = ({ customFieldName, isReadOnly }: Props) => {
	const { formatMessage } = useIntl();
	const { incrementId } = usePlanPathParams();

	const renderPrimaryAction = () => (
		<BoardSwitcher
			boardId={Number(incrementId)}
			triggerLabel={formatMessage(commonMessages.backToBoards)}
			isReadOnly={isReadOnly}
		/>
	);

	return (
		<EmptyState
			renderImage={getAkEmptyStateRenderImageFn(SearchNoResults)}
			header={formatMessage(messages.title, {
				fieldName: customFieldName,
			})}
			description={
				<p>
					{formatMessage(messages.description, {
						fieldName: customFieldName,
					})}
				</p>
			}
			primaryAction={renderPrimaryAction()}
		/>
	);
};

export default ProgramBoardCustomFieldOptionNotFound;
