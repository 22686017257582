import type { Subject } from 'rxjs/Subject';
import type { CacheInvalidationType } from '../../common/types';
import CacheInvalidationSubject, {
	addInvalidationReason,
} from '../resources-cache-invalidation-subject';
import type { Action } from './types';

export const generateResourcesCacheMiddleware =
	(subject: Subject<CacheInvalidationType>) =>
	(origin: CacheInvalidationType, invalidationFunction: (arg1: Action) => boolean) =>
	() =>
	(next: (action?: Action) => Action) =>
	(action?: Action) => {
		// There is a potential that the action gets passed as undefined
		if (action && invalidationFunction(action)) {
			// Trigger cache invalidation. Let the listeners handle everything else
			subject.next(origin);
			addInvalidationReason(origin, action.type ?? 'UNKNOWN');
		}

		return next(action);
	};

export default generateResourcesCacheMiddleware(CacheInvalidationSubject);
