import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { IssueId, IssueLinkType, IssueLink } from '@atlassian/jira-software-board-common';
import { isUnscheduledColumn } from '../../../common/utils/column';
import type { ColumnEntities } from '../../../model/column/column-types';
import {
	ISSUE_LINK_CREATE_REQUEST,
	issueLinkCreateSuccess,
	issueLinkCreateFailed,
	ISSUE_LINKS_CREATE,
	issueLinksAddAndUpdateSuccess,
	issueLinksAddAndUpdateFailed,
	type IssueLinkCreateAction,
} from '../../../state/actions/issue/issue-link';
import {
	ISSUE_RANK_TEAM_DATE_REQUEST,
	type IssueRankTeamDateRequestAction,
} from '../../../state/actions/issue/rank-team-date';
import { getColumns } from '../../../state/selectors/column/column-selectors';
import { getIssueById } from '../../../state/selectors/issue/issue-selectors';
import {
	getIsIncrementPlanningBoard,
	getIncrementConfig,
	getIssueLinkTypeById,
} from '../../../state/selectors/software/software-selectors';
import type {
	ActionsObservable,
	MiddlewareAPI,
	BoardDependencies,
	Action,
	State,
} from '../../../state/types';

export function issueLinkEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
	{ customRequestHandlers }: BoardDependencies,
) {
	return action$.ofType(ISSUE_LINK_CREATE_REQUEST).mergeMap((action) => {
		const { sourceItemKey, targetItemKey, type } = action.payload;

		const success = (response: unknown) => {
			if (action.promise) {
				action.promise.resolve(response);
			}
			return Observable.of(issueLinkCreateSuccess());
		};

		const error = (err?: Error) => {
			log.safeErrorWithoutCustomerData(
				'issue.issue-link-epic.failure',
				'Failed to link IP board issue',
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				err as Error,
			);
			if (action.promise) {
				action.promise.reject(err);
			}
			return Observable.of(issueLinkCreateFailed());
		};

		if (!customRequestHandlers || !customRequestHandlers.addIssueLink) {
			return error(new Error('no request handler found for adding dependency'));
		}

		return Observable.from(
			customRequestHandlers.addIssueLink({
				sourceItemKey,
				targetItemKey,
				type,
			}),
		)
			.flatMap((response) => success(response))
			.catch((err) => error(err));
	});
}

export const isOffTrack = ({
	issueLinkType,
	sourceIssueColumnId,
	destinationIssueColumnId,
	isConcurrentScheduling,
	columns,
}: {
	issueLinkType: IssueLinkType;
	sourceIssueColumnId: number;
	destinationIssueColumnId: number;
	isConcurrentScheduling: boolean;
	columns: ColumnEntities;
}): boolean => {
	const sourceColumn: number = issueLinkType.isOutward
		? sourceIssueColumnId
		: destinationIssueColumnId;
	const destinationColumn: number = issueLinkType.isOutward
		? destinationIssueColumnId
		: sourceIssueColumnId;

	if (
		isUnscheduledColumn(columns[sourceColumn]) ||
		isUnscheduledColumn(columns[destinationColumn])
	) {
		return false;
	}

	if (isConcurrentScheduling) {
		return sourceColumn > destinationColumn;
	}
	return sourceColumn >= destinationColumn;
};

export const updateIssueLinksForAddingLinks = (
	originalIssueLinks: Record<IssueId, IssueLink[]>,
	state: State,
) => {
	const columns = getColumns(state);
	// when clean up the fg dependency_visualisation_program_board_fe_and_be ,
	// the isConcurrentScheduling would always be filled and will not be undefined
	// change this line to `const isConcurrentScheduling = getIncrementConfig(state).isConcurrentScheduling`
	const isConcurrentScheduling = !!getIncrementConfig(state)?.isConcurrentScheduling;
	const newIssueLinks: Record<IssueId, IssueLink[]> = {};

	Object.entries(originalIssueLinks).forEach(([issueId, issueLinks]) => {
		const existingIssueLinks = getIssueById(state, issueId)?.issueLinks || [];
		const newAddedIssueLinksWithOfftrack: IssueLink[] = issueLinks.map((link) => {
			const issueLinkType = getIssueLinkTypeById(state)(link.linkTypeId);
			if (isNil(issueLinkType)) {
				throw new Error('the issue link type is not existing');
			}
			return {
				...link,
				isOfftrack: isOffTrack({
					issueLinkType,
					sourceIssueColumnId: getIssueById(state, link.sourceId).columnId,
					destinationIssueColumnId: getIssueById(state, link.destinationId).columnId,
					columns,
					isConcurrentScheduling,
				}),
			};
		});
		newIssueLinks[issueId] = existingIssueLinks.concat(newAddedIssueLinksWithOfftrack);
	});
	return newIssueLinks;
};

const updateIssueLinkOfftrack = (
	issueLinks: IssueLink[],
	issueLinkId: string,
	offtrackValue: boolean,
) => {
	return issueLinks.map((link) =>
		link.id === issueLinkId ? { ...link, isOfftrack: offtrackValue } : link,
	);
};

/**
 * the issueLinks is a prop of the Issue, if issueA blocks issueB, the issueLink
 * issueA: { ..., columnId: 12, issueLinks: [{id: '1000', sourceId: issueAId, destinationId:issueBId, isOfftrack: false, linkTypeId: 20001}]}
 * issueB: {..., columnId: 13, issueLinks: [{id: '1000', sourceId: issueAId, destinationId:issueBId, isOfftrack: false, linkTypeId: 20001}]}
 * when changing the issueA's column by dragging and dropping, need to recalculate the isOfftrack of the issue links and update the issueA.issueLinks
 * also need to update issueB.issueLinks
 * @param issueIds the dragged and dropped cards
 * @param destinationColumnId the destination column id
 * @param state
 * @returns
 */
export const updateIssueLinksForDragAndDrop = (
	issueIds: IssueId[],
	destinationColumnId: number,
	state: State,
) => {
	const columns = getColumns(state);
	const isConcurrentScheduling = !!getIncrementConfig(state)?.isConcurrentScheduling;
	const newIssueLinks: Record<IssueId, IssueLink[]> = {};

	issueIds.forEach((issueId: IssueId) => {
		const issueLinks = getIssueById(state, issueId)?.issueLinks || [];
		const issueLinksWithUpdatedOfftrack = issueLinks.map((link) => {
			const issueLinkType = getIssueLinkTypeById(state)(link.linkTypeId);
			const isSourceIssue = link.sourceId === issueId;
			const sourceIssue = isSourceIssue
				? { ...getIssueById(state, link.sourceId), columnId: destinationColumnId }
				: getIssueById(state, link.sourceId);
			const destinationIssue = isSourceIssue
				? getIssueById(state, link.destinationId)
				: {
						...getIssueById(state, link.destinationId),
						columnId: destinationColumnId,
					};

			if (isNil(issueLinkType)) {
				throw new Error('the issue link type is not existing');
			}

			const isOffTrackValue = isOffTrack({
				issueLinkType,
				sourceIssueColumnId: sourceIssue.columnId,
				destinationIssueColumnId: destinationIssue.columnId,
				columns,
				isConcurrentScheduling,
			});

			// if the dragged issue is the source issue of the link, need to update issue links of the destination issue of the link,
			// if the dragged issue is the target issue of the link, need to update issue links of the source issue of the link.
			const issueUpdated = isSourceIssue ? destinationIssue : sourceIssue;

			const issueLinksWithOfftrack = updateIssueLinkOfftrack(
				newIssueLinks[issueUpdated.id] || issueUpdated?.issueLinks || [],
				link.id,
				isOffTrackValue,
			);

			if (!isEmpty(issueLinksWithOfftrack)) {
				newIssueLinks[issueUpdated.id] = issueLinksWithOfftrack;
			}

			return {
				...link,
				isOfftrack: isOffTrackValue,
			};
		});
		newIssueLinks[issueId] = issueLinksWithUpdatedOfftrack;
	});
	return newIssueLinks;
};

const handleIssueLinkUpdate = (
	action: IssueLinkCreateAction | IssueRankTeamDateRequestAction,
	state: State,
): Record<IssueId, IssueLink[]> => {
	let newIssueLinks: Record<IssueId, IssueLink[]> = {};

	if (action.type === ISSUE_LINKS_CREATE) {
		newIssueLinks = updateIssueLinksForAddingLinks(action.payload.newIssueLinks, state);
	}
	if (action.type === ISSUE_RANK_TEAM_DATE_REQUEST) {
		newIssueLinks = updateIssueLinksForDragAndDrop(
			action.payload.issueIds,
			action.payload.destinationColumnId,
			state,
		);
	}
	return newIssueLinks;
};

/**
 * This epic is called in the IP board
 * 1. after successfully adding a issue link to BE
 * 2. when user drag and drop the cards we optimistically update the card columnId in the FE.
 * @param action$
 * @param store
 * @returns
 */
export function issueLinkUpdateEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> {
	return action$
		.ofType(ISSUE_LINKS_CREATE, ISSUE_RANK_TEAM_DATE_REQUEST)
		.mergeMap((action: IssueLinkCreateAction | IssueRankTeamDateRequestAction) => {
			const state = store.getState();
			const isIncrementPlanningBoard = getIsIncrementPlanningBoard(state);

			if (isIncrementPlanningBoard && fg('dependency_visualisation_program_board_fe_and_be')) {
				return Observable.of(handleIssueLinkUpdate(action, state))
					.flatMap((newIssueLinks: Record<IssueId, IssueLink[]>) => {
						return Observable.of(
							issueLinksAddAndUpdateSuccess({
								newIssueLinks,
							}),
						);
					})
					.catch((err) => {
						log.safeErrorWithoutCustomerData(
							'issue.issue-link-add-update-success-epic.failure',
							'The issue links failed to update',
							err,
						);
						return Observable.of(issueLinksAddAndUpdateFailed());
					});
			}
			return Observable.empty<never>();
		});
}
