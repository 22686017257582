import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	backlogLimitApproachingFlagTitle: {
		id: 'software-issue-limit-flags.limit-approaching-flag.backlog-limit-approaching-flag-title',
		defaultMessage: 'Your backlog is almost full',
		description: 'Title for flag when issue count is approaching the limit on backlog',
	},
	backlogLimitApproachingAdminFlagBody: {
		id: 'software-issue-limit-flags.limit-approaching-flag.backlog-limit-approaching-admin-flag-body',
		defaultMessage:
			"The backlog can display up to 5000 issues. Edit your board filter to refine what's included and excluded.",
		description:
			'Description for flag when issue count is approaching the limit on backlog for board admins',
	},
	backlogLimitApproachingEndUserFlagBody: {
		id: 'software-issue-limit-flags.limit-approaching-flag.backlog-limit-approaching-end-user-flag-body',
		defaultMessage:
			"The backlog can display up to 5000 issues. The admin who created the board filter can refine what's included and excluded.",
		description:
			'Description for flag when issue count is approaching the limit on backlog for end users',
	},
	boardLimitApproachingFlagTitle: {
		id: 'software-issue-limit-flags.limit-approaching-flag.board-limit-approaching-flag-title',
		defaultMessage: 'Your board is almost full',
		description: 'Title for flag when issue count is approaching the limit on board',
	},
	boardLimitApproachingAdminFlagBody: {
		id: 'software-issue-limit-flags.limit-approaching-flag.board-limit-approaching-admin-flag-body',
		defaultMessage:
			"The board can display up to 5000 issues. Edit your board filter to refine what's included and excluded.",
		description:
			'Description for flag when issue count is approaching the limit on board for board admins',
	},
	boardLimitApproachingEndUserFlagBody: {
		id: 'software-issue-limit-flags.limit-approaching-flag.board-limit-approaching-end-user-flag-body',
		defaultMessage:
			"The board can display up to 5000 issues. The admin who created the board filter can refine what's included and excluded.",
		description:
			'Description for flag when issue count is approaching the limit on board for end users',
	},
	tmpBacklogLimitApproachingFlagTitle: {
		id: 'software-issue-limit-flags.limit-approaching-flag.tmp-backlog-limit-approaching-flag-title',
		defaultMessage: 'Your backlog is almost full',
		description:
			'Title for flag when issue count is approaching the limit in backlog section of a TMP backlog',
	},
	tmpSprintsLimitApproachingFlagTitle: {
		id: 'software-issue-limit-flags.limit-approaching-flag.tmp-sprints-limit-approaching-flag-title',
		defaultMessage: 'Your sprint section is almost full',
		description:
			'Title for flag when issue count is approaching the limit in sprint section of a TMP backlog',
	},
	tmpBacklogSprintsLimitApproachingFlagTitle: {
		id: 'software-issue-limit-flags.limit-approaching-flag.tmp-backlog-sprints-limit-approaching-flag-title',
		defaultMessage: 'Your backlog and sprint sections are almost full',
		description:
			'Title for flag when issue count is approaching the limit in both the backlog and sprint sections of a TMP backlog',
	},
	tmpBacklogBoardLimitApproachingFlagTitle: {
		id: 'software-issue-limit-flags.limit-approaching-flag.tmp-backlog-board-limit-approaching-flag-title',
		defaultMessage: 'Your backlog and board sections are almost full',
		description:
			'Title for flag when issue count is approaching the limit in both the backlog and board sections of a TMP backlog',
	},
	tmpBoardLimitApproachingFlagBody: {
		id: 'software-issue-limit-flags.limit-approaching-flag.tmp-board-limit-approaching-flag-body',
		defaultMessage:
			'The board can only display up to 5000 issues. It might be time to move some issues.',
		description: 'Description for flag when issue count is approaching the limit on a TMP board',
	},
	tmpBacklogLimitApproachingFlagBody: {
		id: 'software-issue-limit-flags.limit-approaching-flag.tmp-backlog-limit-approaching-flag-body',
		defaultMessage:
			'The backlog can display up to 5000 issues. It might be time to move some issues.',
		description:
			'Description for flag when issue count is approaching the limit in backlog section of a TMP backlog',
	},
	tmpSprintsLimitApproachingFlagBody: {
		id: 'software-issue-limit-flags.limit-approaching-flag.tmp-sprints-limit-approaching-flag-body',
		defaultMessage:
			'We can only display up to 5000 issues across all your sprints. It might be time to move some issues.',
		description:
			'Description for flag when issue count is approaching the limit in sprint section of a TMP backlog',
	},
	tmpBacklogSprintsLimitApproachingFlagBody: {
		id: 'software-issue-limit-flags.limit-approaching-flag.tmp-backlog-sprints-limit-approaching-flag-body',
		defaultMessage:
			'Your backlog and sprint sections can each only display up to 5000 issues. It might be time to move some issues.',
		description:
			'Description for flag when issue count is approaching the limit in both the backlog and sprint sections of a TMP backlog',
	},
	tmpBacklogBoardLimitApproachingFlagBody: {
		id: 'software-issue-limit-flags.limit-approaching-flag.tmp-backlog-board-limit-approaching-flag-body',
		defaultMessage:
			'Your backlog and board sections can each only display up to 5000 issues. It might be time to move some issues.',
		description:
			'Description for flag when issue count is approaching the limit in both the backlog and board sections of a TMP backlog',
	},
	backlogLimitApproachingAdminFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-approaching-flag.backlog-limit-approaching-admin-flag-body-issue-term-refresh',
		defaultMessage:
			"The backlog can display up to 5000 issues. Edit your board filter to refine what's included and excluded.",
		description:
			'Description for flag when issue count is approaching the limit on backlog for board admins',
	},
	backlogLimitApproachingEndUserFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-approaching-flag.backlog-limit-approaching-end-user-flag-body-issue-term-refresh',
		defaultMessage:
			"The backlog can display up to 5000 issues. The admin who created the board filter can refine what's included and excluded.",
		description:
			'Description for flag when issue count is approaching the limit on backlog for end users',
	},
	boardLimitApproachingAdminFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-approaching-flag.board-limit-approaching-admin-flag-body-issue-term-refresh',
		defaultMessage:
			"The board can display up to 5000 issues. Edit your board filter to refine what's included and excluded.",
		description:
			'Description for flag when issue count is approaching the limit on board for board admins',
	},
	boardLimitApproachingEndUserFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-approaching-flag.board-limit-approaching-end-user-flag-body-issue-term-refresh',
		defaultMessage:
			"The board can display up to 5000 issues. The admin who created the board filter can refine what's included and excluded.",
		description:
			'Description for flag when issue count is approaching the limit on board for end users',
	},
	tmpBoardLimitApproachingFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-approaching-flag.tmp-board-limit-approaching-flag-body-issue-term-refresh',
		defaultMessage:
			'The board can only display up to 5000 issues. It might be time to move some issues.',
		description: 'Description for flag when issue count is approaching the limit on a TMP board',
	},
	tmpBacklogLimitApproachingFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-approaching-flag.tmp-backlog-limit-approaching-flag-body-issue-term-refresh',
		defaultMessage:
			'The backlog can display up to 5000 issues. It might be time to move some issues.',
		description:
			'Description for flag when issue count is approaching the limit in backlog section of a TMP backlog',
	},
	tmpSprintsLimitApproachingFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-approaching-flag.tmp-sprints-limit-approaching-flag-body-issue-term-refresh',
		defaultMessage:
			'We can only display up to 5000 issues across all your sprints. It might be time to move some issues.',
		description:
			'Description for flag when issue count is approaching the limit in sprint section of a TMP backlog',
	},
	tmpBacklogSprintsLimitApproachingFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-approaching-flag.tmp-backlog-sprints-limit-approaching-flag-body-issue-term-refresh',
		defaultMessage:
			'Your backlog and sprint sections can each only display up to 5000 issues. It might be time to move some issues.',
		description:
			'Description for flag when issue count is approaching the limit in both the backlog and sprint sections of a TMP backlog',
	},
	tmpBacklogBoardLimitApproachingFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-approaching-flag.tmp-backlog-board-limit-approaching-flag-body-issue-term-refresh',
		defaultMessage:
			'Your backlog and board sections can each only display up to 5000 issues. It might be time to move some issues.',
		description:
			'Description for flag when issue count is approaching the limit in both the backlog and board sections of a TMP backlog',
	},
});
