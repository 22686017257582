import type { State } from './types';

export const initialState: State = {
	isArchiveIssueModalOpen: false,
	issueKey: null,
	issueId: null,
	onIssueArchiveSuccess: null,
	onCloseIssueViewModal: null,
	triggerPointKey: null,
};
