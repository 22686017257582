import { entityArrayToMap } from '../../../../services/software/software-data-transformer';
import { WORK_DATA_SET } from '../../../actions/work';
import type { Action } from '../../../types';
import type { RequestTypesState } from './types';

export const requestTypesReducer = (
	state: RequestTypesState = {},
	action: Action,
): RequestTypesState => {
	if (action.type === WORK_DATA_SET) {
		const {
			payload: { requestTypes },
		} = action;

		return entityArrayToMap(requestTypes);
	}

	return state;
};
