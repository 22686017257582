import { type Reducer, combineReducers } from 'redux';
import type { OptimisticState } from 'redux-optimistic-ui';
import type { Action } from '../types';
import { configurationReducer } from './configuration/reducer';
import entitiesReducers from './entities/reducer';
import type { EntitiesState } from './entities/types';
import type { State } from './types';
import uiReducers from './ui/reducer';

export default function rootBoardReducer(): Reducer<State, Action> {
	return combineReducers<State, Action>({
		configuration: configurationReducer,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		entities: entitiesReducers as Reducer<EntitiesState | OptimisticState<EntitiesState>, Action>,
		ui: uiReducers(),
	});
}
