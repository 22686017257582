import React, { Component } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import AppIcon from '@atlaskit/icon/core/app';
import AddonIcon from '@atlaskit/icon/glyph/addon';
import { ButtonItem } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import type { Operation } from '../types';

export type Props = {
	operation: Operation;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onModalClose: () => any;
};
const iconSize = gridSize * 3;

/**
 * Serves as an interactive UI element designed to execute specific operations. This component
 * is uniquely structured to incorporate visual cues such as icons and tooltips, facilitating
 * user engagement with the application's functionalities. It includes an innovative solution
 * for activating additional UI layers, exemplified by a hidden modal link, which circumvents
 * limitations in standard UI components. This approach ensures seamless integration of
 * complex UI behaviors, enhancing the user experience.
 */
// eslint-disable-next-line jira/react/no-class-components
export default class OperationButton extends Component<Props> {
	// go/jfe-eslint
	// eslint-disable-next-line react/sort-comp
	static defaultProps = {
		operation: {},
		onModalClose: noop,
	};

	static getFallbackIcon = (name: string) =>
		getWillShowNav4() && fg('move-connect-addons-board-backlog-nav4') ? (
			<AppIcon label={name} color={token('color.icon')} />
		) : (
			<AddonIcon size="medium" label={name} />
		);

	/* not used yet TOTEM-818 */
	onModalClose = () => this.props.onModalClose();

	setEcosystemModalLinkRef = (element?: HTMLElement | null) => {
		this.ecosystemModalLinkRef = element;
	};

	ecosystemModalLinkRef: HTMLElement | null | undefined;

	clickEcosystemModalLinkRef = () => {
		this.ecosystemModalLinkRef && this.ecosystemModalLinkRef.click();
	};

	/*
	 * ABE-22: The reason for using this 'invisible' anchor with an href is needed, instead of simply
	 * passing the href to the component which would then have an underlying anchor, is that the
	 * styleClasses list of classes here are needed to trigger the ecosystem addon modal to come
	 * up on click. This is in contrast to the component behaviour of a vanilla anchor that
	 * currently does not support custom classes, which would redirect to a Jira 404 error page.
	 */
	renderEcosystemModalLink() {
		const { url, styleClass } = this.props.operation;
		return (
			// go/jfe-eslint
			// eslint-disable-next-line jsx-a11y/anchor-has-content
			<a
				href={url}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styleClass}
				ref={this.setEcosystemModalLinkRef}
				// eslint-disable-next-line jira/react/no-style-attribute
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					display: 'none',
				}}
			/>
		);
	}

	renderIcon() {
		const { icon, name } = this.props.operation;
		return icon && icon.url ? (
			<AsyncIcon
				title={name}
				url={icon.url}
				width={iconSize}
				height={iconSize}
				fallback={() => OperationButton.getFallbackIcon(name || '')}
			/>
		) : (
			OperationButton.getFallbackIcon(name || '')
		);
	}

	renderButton() {
		const { name } = this.props.operation;
		const icon = this.renderIcon();
		return (
			<Button
				key={name}
				onClick={this.clickEcosystemModalLinkRef}
				// @ts-expect-error - TS2322 - Type 'Element | null' is not assignable to type 'ReactChild | undefined'.
				iconBefore={name ? icon : null}
			>
				{name || <OnlyIconWrapper>{icon}</OnlyIconWrapper>}
				{this.renderEcosystemModalLink()}
			</Button>
		);
	}

	renderMenuItem() {
		const { name } = this.props.operation;
		const icon = this.renderIcon();
		return (
			<ButtonItem
				key={name}
				onClick={this.clickEcosystemModalLinkRef}
				iconBefore={name ? icon : null}
			>
				{name || <OnlyIconWrapper>{icon}</OnlyIconWrapper>}
				{this.renderEcosystemModalLink()}
			</ButtonItem>
		);
	}

	render() {
		const { tooltip } = this.props.operation;
		const content =
			getWillShowNav4() && fg('move-connect-addons-board-backlog-nav4')
				? this.renderMenuItem()
				: this.renderButton();
		if (tooltip) {
			return <Tooltip content={tooltip}>{content}</Tooltip>;
		}
		return content;
	}
}
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OnlyIconWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
});
