import type { GlobalIssueCreateProps } from '../../../model/check-global-issue-create/check-global-issue-create-types';

export const CHECK_GLOBAL_ISSUE_CREATE =
	'state.actions.check-global-issue-create.CHECK_GLOBAL_ISSUE_CREATE';

export type CheckGlobalIssueCreateAction = {
	type: typeof CHECK_GLOBAL_ISSUE_CREATE;
	payload: {
		issues: GlobalIssueCreateProps[];
	};
};

export const checkGlobalIssueCreate = (
	issues: GlobalIssueCreateProps[],
): CheckGlobalIssueCreateAction => ({
	type: CHECK_GLOBAL_ISSUE_CREATE,
	payload: {
		issues,
	},
});

export type Action = CheckGlobalIssueCreateAction;
