import React, { useMemo } from 'react';
import { IconButton } from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import CrossIconNew from '@atlaskit/icon/core/close';
import CrossIconOld from '@atlaskit/icon/glyph/cross';
import { Text, Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { SwimlaneColumnHeaderContainer } from '@atlassian/jira-platform-board-kit/src/ui/swimlane-column-header/index.tsx';
import SwimlaneWrapper from '@atlassian/jira-platform-board-kit/src/ui/swimlane/wrapper/index.tsx';
import {
	type MeasurementCacheEntry,
	useVirtual,
} from '@atlassian/jira-software-fast-virtual/src/services/use-virtual/index.tsx';
import { useViewSettingsActions } from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import { useBoardSelector } from '../../../../state';
import { rapidViewIdSelector } from '../../../../state/selectors/software/software-selectors';
import {
	platformSwimlaneIdsSelector,
	swimlaneHeightsSelector,
} from '../../../../state/selectors/work/work-selectors';
import { useAutoScrollSwimlane } from '../swimlanes-container/use-auto-scroll-swimlane';
import VirtualizedSwimlane from '../virtualized-swimlane';
import messages from './messages';

const UnscheduledWorkSwimlanesContainer = () => {
	const { formatMessage } = useIntl();
	const swimlaneIds = useBoardSelector(platformSwimlaneIdsSelector);
	const swimlaneHeights = useBoardSelector(swimlaneHeightsSelector);
	const boardId = useBoardSelector(rapidViewIdSelector);
	const { setShowUnscheduledColumn } = useViewSettingsActions();
	const cacheOptions = useMemo(
		() => ({
			cache: new Map<string, MeasurementCacheEntry>(),
			getCacheKey: (index: number) => swimlaneIds[index],
		}),
		[swimlaneIds],
	);

	const { rows, totalSize, scrollTo } = useVirtual({
		rowCount: swimlaneIds.length,
		getDefaultRowSize: (index) => swimlaneHeights[swimlaneIds[index]],
		offsetTop: 0,
		cacheOptions,
	});

	useAutoScrollSwimlane(swimlaneIds, scrollTo);

	const renderSwimlanes = () => (
		<>
			{rows.map(({ index, top, forceRemeasure, measure }) => (
				<VirtualizedSwimlane
					key={swimlaneIds[index]}
					id={swimlaneIds[index]}
					index={index}
					top={top}
					measure={measure}
					forceRemeasure={forceRemeasure}
					isLastSwimlane={index === swimlaneIds.length - 1}
					isUnscheduledWorkColumnPanel
				/>
			))}
		</>
	);

	const renderButtonIcon = () =>
		fg('tnk-1613-jira-visual-refresh-jsw-board-icons-2') ? (
			<CrossIconNew
				LEGACY_fallbackIcon={CrossIconOld}
				LEGACY_size="small"
				label={formatMessage(messages.closeColumnLabel)}
				color={token('color.icon')}
			/>
		) : (
			<CrossIconOld size="small" label={formatMessage(messages.closeColumnLabel)} />
		);

	const closeUnscheduledWork = () => setShowUnscheduledColumn(Number(boardId), false);

	return (
		<Box xcss={UnscheduledWorkSwimlanesContainerWrapperStyles}>
			<SwimlaneColumnHeaderContainer>
				<Flex
					alignItems="center"
					justifyContent="space-between"
					xcss={UnscheduledColumnHeaderContentStyles}
				>
					{fg('increment_board_a11y_fix') ? (
						<Heading size="small" as="h2">
							{formatMessage(messages.columnHeader)}
						</Heading>
					) : (
						<Text size="large" weight="semibold">
							{formatMessage(messages.columnHeader)}
						</Text>
					)}
					<Box testId="software-board.board-container.board.unscheduled-work-swimlanes-container.close-button">
						<IconButton
							icon={renderButtonIcon}
							label={formatMessage(messages.closeColumnLabel)}
							appearance="subtle"
							onClick={closeUnscheduledWork}
						/>
					</Box>
				</Flex>
			</SwimlaneColumnHeaderContainer>
			<SwimlaneWrapper minHeight={totalSize} isVirtualised hideTickMask>
				{renderSwimlanes()}
			</SwimlaneWrapper>
		</Box>
	);
};

export default UnscheduledWorkSwimlanesContainer;

const UnscheduledWorkSwimlanesContainerWrapperStyles = xcss({
	height: '100%',
	width: '100%',
	paddingInline: 'space.050',
});

const UnscheduledColumnHeaderContentStyles = xcss({
	width: '100%',
	paddingBlock: 'space.200',
	paddingLeft: 'space.150',
	paddingRight: 'space.100',
	backgroundColor: 'elevation.surface',
});
