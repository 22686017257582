import { useEffect, useReducer, useRef, useCallback } from 'react';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { HookEffects, UseFetchProps, UseFetchReturn } from './types';
import { buildActionDispatchers } from './utils/action-dispatchers.tsx';
import { UseFetchHandledError } from './utils/error.tsx';
import { reducer } from './utils/reducer.tsx';
import { isShallowDifferent } from './utils/shallow-difference.tsx';
import { initialState } from './utils/state.tsx';
import { thunks } from './utils/thunks.tsx';

export const useFetch = <TData,>(
	{
		onFetch,
		isAutoFetchable = true,
		analyticsTracking,
		browserMetricsKey,
		loggingKey,
		initialData,
		isAbortable = true,
	}: UseFetchProps<TData>,
	fetchDependencies: HookEffects,
): UseFetchReturn<TData> => {
	const [state, dispatch] = useReducer(reducer, { ...initialState, data: initialData });
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { initialized, fetched, loading } = state;

	const abortControllerRef = useRef<unknown>();
	const fetchDependenciesRef = useRef(fetchDependencies);
	const isMounted = useRef(true);

	const getAbortController = useCallback(() => {
		const controller = abortControllerRef.current;
		// @ts-expect-error - TS2571 - Object is of type 'unknown'.
		if (!abortControllerRef.current || controller?.signal.aborted) {
			abortControllerRef.current = new AbortController();
		}
		return abortControllerRef.current;
	}, []);

	const callFetch = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(...args: any[]) => {
			// @ts-expect-error - TS2339 - Property 'signal' does not exist on type 'unknown'.
			const { signal } = getAbortController();
			thunks.fetch({
				onFetch: async () => {
					try {
						return await onFetch({ signal }, ...args);
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					} catch (e: any) {
						throw new UseFetchHandledError(isMounted.current, e);
					}
				},
				analyticsTracking,
				createAnalyticsEvent,
				browserMetricsKey,
				loggingKey,
				signal,
			})({
				actions: buildActionDispatchers(dispatch),
			});
		},
		[
			onFetch,
			analyticsTracking,
			browserMetricsKey,
			createAnalyticsEvent,
			loggingKey,
			getAbortController,
		],
	);

	const doAbort = useCallback(() => {
		if (isAbortable) {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			getAbortController().abort();
		}
	}, [getAbortController, isAbortable]);

	const doFetch = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(...args: any[]) => {
			if (loading) {
				doAbort();
			}

			callFetch(...args);
		},
		[loading, callFetch, doAbort],
	);

	useEffect(() => {
		const hasEffectsChanged = isShallowDifferent(fetchDependenciesRef.current, fetchDependencies);

		if ((!initialized && !fetched) || hasEffectsChanged) {
			if (hasEffectsChanged) {
				fetchDependenciesRef.current = fetchDependencies;
			}

			if (hasEffectsChanged) {
				doAbort();
			}

			if (isAutoFetchable) {
				callFetch();
			} else if (initialized && hasEffectsChanged) {
				dispatch({ type: 'resetInitialState', payload: null });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialized, doAbort, fetched, callFetch, isAutoFetchable, ...fetchDependencies]);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
			doAbort();
		};
	}, [doAbort]);

	return [state, { doFetch, doAbort }];
};
