import type { Ari } from '@atlassian/jira-platform-ari';
import parameters from '@atlassian/jira-relay/src/__generated__/mainCompleteSprintMetadataQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const completeSprintModalEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-complete-sprint-modal" */ './src'),
	),
	getPreloadProps: ({
		jiraProjectAri,
		updatedFromDeploymentsDate,
		boardId,
		activeSprintIds,
	}: {
		jiraProjectAri: Ari;
		updatedFromDeploymentsDate: string;
		boardId: Ari;
		activeSprintIds: Ari[];
	}) => ({
		queries: {
			completeSprintMetadata: {
				options: {
					fetchPolicy: 'network-only' as const,
				},
				parameters,
				variables: {
					jiraProjectAri,
					updatedFromDeploymentsDate,
					boardId,
					activeSprintIds,
				},
			},
		},
	}),
});
