import React, { useEffect, useRef } from 'react';
import { useQueryLoader } from 'react-relay';
import { isFedRamp } from '@atlassian/atl-context';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	isClientFetchError,
	isHttpClientErrorResponse,
} from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { JIRA_INSIGHTS } from '@atlassian/jira-insights-common-constants/src/common/constants/analytics';
import { INSIGHTS_PACKAGE } from '@atlassian/jira-insights-common-constants/src/common/constants/package.tsx';
import { UserPropertiesContainer } from '@atlassian/jira-insights-common/src/services/user-properties';
import {
	NEXT_BEST_TASK_ANALYTICS,
	NEXT_BEST_TASK_USER_PROPERTIES_SCOPE,
} from '@atlassian/jira-insights-next-best-task/src/common/constants';
import { useNextBestTaskActions } from '@atlassian/jira-insights-next-best-task/src/services/next-best-task-container/index.tsx';
import { useContextAri } from '@atlassian/jira-insights-next-best-task/src/services/use-context-ari';
import { useNextBestTaskPanel } from '@atlassian/jira-insights-next-best-task/src/services/use-next-best-task-panel/index.tsx';
import { SafeNBTDataLoaderForOnlyCurrentUserSelected } from '@atlassian/jira-insights-next-best-task/src/ui/nbt-data-loader/index.tsx';
import { AsyncNBTPanel as NewAsyncNBTPanel } from '@atlassian/jira-insights-next-best-task/src/ui/panel/async.tsx';
import { AsyncNBTPanel } from '@atlassian/jira-insights-next-best-task/src/ui/task-panel/async.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import NBTgithubOnboardingQuery, {
	type footer_NBTgithubOnboardingDetails_Query,
} from '@atlassian/jira-relay/src/__generated__/footer_NBTgithubOnboardingDetails_Query.graphql';
import { nextBestTaskFeatureResource } from '@atlassian/jira-router-resources-next-best-task/src/services/index.tsx';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import { isClientSideThirdPartyAppError } from '@atlassian/jira-software-sla-tracker/src/services/response-to-sla';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import { useBoardSelector } from '../../../state';
import { rapidViewIdSelector } from '../../../state/selectors/software/software-selectors';
import { isFilterOnCurrentUserOnly } from '../../../state/selectors/work/work-selectors';
import { useIsCMPBoard } from '../../../state/state-hooks/capabilities';

export const ConnectedNextBestTaskPanel = () => {
	const isCMPBoard = useIsCMPBoard();
	const boardId = useBoardSelector(rapidViewIdSelector);

	const onStart = useExperienceStart({
		experience: NEXT_BEST_TASK_ANALYTICS,
		analyticsSource: INSIGHTS_PACKAGE,
	});

	useEffect(() => {
		onStart();
	}, [onStart]);

	return fg('jira-work-suggestions-board-extra-pr-tasks-type') ? (
		<NewAsyncNBTPanel isSimplifiedProject={!isCMPBoard} boardId={boardId} />
	) : (
		<AsyncNBTPanel isSimplifiedProject={!isCMPBoard} boardId={boardId} />
	);
};

export const NextBestTaskPanelInner = () => {
	const accountId = useAccountId();
	if (!accountId) throw new Error('accountId could not be found');

	return (
		<UserPropertiesContainer scope={NEXT_BEST_TASK_USER_PROPERTIES_SCOPE} accountId={accountId}>
			<ConnectedNextBestTaskPanel />
		</UserPropertiesContainer>
	);
};

export const NextBestTaskPanel = () => {
	const { data, loading } = useResource(nextBestTaskFeatureResource);
	const isJSMBoard = useIsJSMBoard();
	const isOnlyCurrentUserSelected = useBoardSelector(isFilterOnCurrentUserOnly) || false;
	const cloudId = useCloudId();
	const { projectAri } = useContextAri();
	const mountedNBTRef = useRef<boolean>(false);

	const [queryRef, loadGitHubOnboardingQuery] =
		useQueryLoader<footer_NBTgithubOnboardingDetails_Query>(NBTgithubOnboardingQuery);

	const { setQueryGithubOnboardingRef } = useNextBestTaskActions();

	const isOptedIn = !!data?.hasUserOptedIn;

	const isNBTEnabled = !loading && data != null && isOptedIn && !isJSMBoard;

	const { isOpen } = useNextBestTaskPanel();

	if (isNBTEnabled && isOnlyCurrentUserSelected) {
		mountedNBTRef.current = true;
	}

	useEffect(() => {
		if (isOpen && queryRef != null) {
			setQueryGithubOnboardingRef(queryRef);
		}
	}, [queryRef, setQueryGithubOnboardingRef, isOpen]);

	useEffect(() => {
		if (isNBTEnabled && isOpen && projectAri && queryRef == null) {
			loadGitHubOnboardingQuery({
				cloudId,
				projectAri,
			});
		}
	}, [isNBTEnabled, loadGitHubOnboardingQuery, cloudId, isOpen, queryRef, projectAri]);

	const addAnalyticAttributes = (error: Error) => ({
		isClientFetchError:
			isClientFetchError(error) ||
			isClientSideThirdPartyAppError(error) ||
			isHttpClientErrorResponse(error),
	});

	return isNBTEnabled ? (
		<JSErrorBoundary
			id="jira.insights.next-best-task-panel"
			packageName={JIRA_INSIGHTS}
			fallback="flag"
			attributes={addAnalyticAttributes}
		>
			<SafeNBTDataLoaderForOnlyCurrentUserSelected
				isOnlyCurrentUserSelected={isOnlyCurrentUserSelected}
			/>
			{mountedNBTRef.current && (
				<Placeholder fallback={null} name="next-best-task-panel">
					{!isFedRamp() && <NextBestTaskPanelInner />}
				</Placeholder>
			)}
		</JSErrorBoundary>
	) : null;
};
