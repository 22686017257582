import React from 'react';
import { isSafeUrl } from '@atlaskit/adf-schema';
import { LazySingleLineSmartLink } from '@atlassian/jira-issue-field-smart-link/src/ui/single-line-smart-link/async';
import { CustomFieldTooltip } from '../custom-field-tooltip';
import { TextCardCustomFieldContent } from '../text-card-custom-field-content';
import type { UrlCustomField } from '../types';

type Props = { field: UrlCustomField };

export const TextSmartLinkCustomFieldContent = ({ field }: Props) =>
	field.text && isSafeUrl(field.text) ? (
		<LazySingleLineSmartLink anchorTarget="_blank" url={field.text} />
	) : (
		<CustomFieldTooltip field={field}>
			<TextCardCustomFieldContent field={field} />
		</CustomFieldTooltip>
	);
