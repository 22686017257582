import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { fetchIssueLinksPermissionsService } from '../../services/issue-link-permissions';
import {
	FETCH_ISSUE_LINKS_PERMISSIONS,
	setIssueLinksPermissions,
} from '../../state/actions/issue-links-permissions';
import {
	cloudIdSelector,
	getIsCMPBoard,
	getIsIncrementPlanningBoard,
	getIsJSWBoard,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types';

export const getIssueLinksPermissionsEpic = (
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> => {
	const state = store.getState();
	const boardId = rapidViewIdSelector(state);
	const cloudId = cloudIdSelector(state);
	const isCMP = getIsCMPBoard(state);
	const isJSWBoard = getIsJSWBoard(state);
	const isIPBoard = getIsIncrementPlanningBoard(state);
	return action$.ofType(FETCH_ISSUE_LINKS_PERMISSIONS).switchMap(() => {
		if (!isJSWBoard || isIPBoard) return Observable.of();

		return fetchIssueLinksPermissionsService({ boardId, cloudId, isCMP })
			.mergeMap((linkedIssuesPermissionsResponse) =>
				Observable.of(setIssueLinksPermissions(linkedIssuesPermissionsResponse)),
			)
			.catch((error) => {
				if (!isClientFetchError(error)) {
					fireErrorAnalytics({
						error,
						meta: {
							id: 'board.issueLinksPermissions.load.failure',
							packageName: 'jiraSoftwareBoard',
							teamName: 'a4t-tanuki',
						},
					});
				}
				return Observable.of();
			});
	});
};
