import { styled } from '@compiled/react';
import { FlexContainer } from './ui/styled';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Draggable = styled(FlexContainer)({
	cursor: 'grab',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RestrictedDraggable = styled(FlexContainer)({
	cursor: 'not-allowed',
	'&:hover': {
		opacity: 0.5,
	},
});

// Intermediate div(TypeIconContainer) for AKTooltip to bind to
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TypeIconContainer = styled.div<{ isActionVisible: boolean; isRestricted: boolean }>(
	{
		width: 16,
		display: 'grid',
		gridTemplateAreas: 'slot',
		placeItems: 'center',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		'--action-opacity': ({ isActionVisible }) => (isActionVisible ? '1' : '0'),
		'@supports not selector(:has(*))': {
			'&:focus-within': {
				'--action-opacity': '1',
			},
		},
		'@supports selector(:has(*))': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'&:has(:focus-visible)': {
				'--action-opacity': '1',
			},
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isRestricted }) =>
		isRestricted
			? {
					cursor: 'not-allowed',
					'&:hover': {
						opacity: 0.5,
					},
				}
			: undefined,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InnerIconContainer = styled.div({
	gridArea: 'slot',
	display: 'inline-flex',
	opacity: 'calc(1 - var(--action-opacity,1))',
	pointerEvents: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InnerActionContainer = styled.div({
	gridArea: 'slot',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	opacity: 'var(--action-opacity,1)',
});
