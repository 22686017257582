import partition from 'lodash/partition';
import { UNSCHEDULED_COLUMN_ID } from '@atlassian/jira-common-constants/src/column-types';
import { useIntl } from '@atlassian/jira-intl';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import type { Column } from '../../../../../../../../model/column/column-types';
import {
	UNSCHEDULE_DATE_RANGE_MENU_ID,
	SCHEDULE_DATE_RANGE_MENU_ID,
	CHANGE_DATE_RANGE_MENU_ID,
} from '../../constants';
import messages from '../../messages';
import { createSeparator } from '../separator';
import type { ContentProps } from './types';

export const useChangeDateRangeContent = ({
	onDateRangeChange,
	columns,
	teamSprintsForIterations,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();
	const [unplanned, planned] = partition(columns, (column) => column.id === UNSCHEDULED_COLUMN_ID);

	const getLabelForDestinationColumn = (column: Column) => {
		const sprintForIterationName = teamSprintsForIterations?.[Number(column.id)]?.name;
		if (!sprintForIterationName) {
			return formatMessage(messages.column, { columnName: column.name });
		}

		return sprintForIterationName;
	};

	const scheduleActions: MenuNode[] = planned.map((column) => ({
		type: MenuNodeType.Item,
		id: `${SCHEDULE_DATE_RANGE_MENU_ID}/${column.id}`,
		label: getLabelForDestinationColumn(column),
		onClick: () => onDateRangeChange(column),
	}));

	let unscheduleAction: MenuNode[] = [];
	if (unplanned.length > 0) {
		unscheduleAction = [
			createSeparator('unschedule-action'),
			{
				type: MenuNodeType.Item,
				id: UNSCHEDULE_DATE_RANGE_MENU_ID,
				label: formatMessage(messages.unscheduleIncrement),
				onClick: () => onDateRangeChange(unplanned[0]),
			},
		];
	}

	return [
		{
			type: MenuNodeType.Item,
			label: formatMessage(messages.moveTo),
			id: CHANGE_DATE_RANGE_MENU_ID,
			children: [...scheduleActions, ...unscheduleAction],
		},
	];
};
