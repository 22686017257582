import { useEffect } from 'react';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { SUMMARY_TYPE } from '@atlassian/jira-platform-field-config';
import type { IssueId } from '@atlassian/jira-software-board-common';
import { useDiffCheck } from '../../../../common/hooks/use-diff-check/index.tsx';
import { useBoardActionCreator } from '../../../../state';
import { setSummary } from '../../../../state/actions/issue/summary';
import { useIsIncrementPlanningBoard } from '../../../../state/state-hooks/capabilities';
import type { FieldSyncSummaryProps } from './types';

export const FieldSyncSummary = ({
	issue: { id, key, summary: summaryFromBoard },
	projectKey,
}: FieldSyncSummaryProps) => {
	const setSummaryInBoard = useBoardActionCreator((issueId: IssueId, summary: string) =>
		setSummary(issueId, summary),
	);
	const isIPBoard = useIsIncrementPlanningBoard();
	// In the increment planning board, scenario issue keys do not yet have a numeric identifier as
	// they have not been created in Jira yet. In this case, the key is equal to the projectKey,
	// and thus not unique. Instead, we pass the issue id to the field config service (as it
	// requires a unique identifier for each issue).
	const uniqueKey = isIPBoard && key === projectKey ? `${id}` : key;
	const [summaryFromIssueField, { setFieldValue: setSummaryInExtractedFields }] = useFieldValue({
		issueKey: uniqueKey,
		fieldKey: SUMMARY_TYPE,
	});

	const summaryFromBoardChanged = useDiffCheck([summaryFromBoard]);
	const summaryFromIssueFieldChanged = useDiffCheck([summaryFromIssueField]);

	const storesNeedToSync = summaryFromBoard !== summaryFromIssueField;

	// SUMMARY SYNC: BOARD -> EXTRACTED FIELDS
	useEffect(() => {
		if (summaryFromBoardChanged && storesNeedToSync) {
			setSummaryInExtractedFields(uniqueKey, SUMMARY_TYPE, summaryFromBoard);
		}
	}, [
		uniqueKey,
		setSummaryInExtractedFields,
		storesNeedToSync,
		summaryFromBoard,
		summaryFromBoardChanged,
	]);

	// SUMMARY SYNC: EXTRACTED FIELDS -> BOARD
	useEffect(() => {
		if (!summaryFromBoardChanged && summaryFromIssueFieldChanged && storesNeedToSync) {
			setSummaryInBoard(id, summaryFromIssueField);
		}
	}, [
		id,
		setSummaryInBoard,
		storesNeedToSync,
		summaryFromBoardChanged,
		summaryFromIssueField,
		summaryFromIssueFieldChanged,
	]);

	return null;
};
