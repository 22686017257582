import { chain } from 'icepick';
import unsetIn from '@atlassian/jira-common-icepick/src/utils/unset-in/index.tsx';
import { entityArrayToMap } from '../../../../services/software/software-data-transformer';
import { UPDATE_PLANNED_CAPACITY_SUCCESS } from '../../../actions/sprints/update-planned-capacity';
import { UPDATE_ITERATION_CAPACITY_SUCCESS } from '../../../actions/team';
import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../actions/work';
import type { Action } from '../../../types';
import type { TeamState } from './types';

export const teamsReducer = (state: TeamState = {}, action: Action): TeamState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		return entityArrayToMap(action.payload.teams || []);
	}

	if (action.type === UPDATE_PLANNED_CAPACITY_SUCCESS) {
		const { teamId, iterationId, capacity } = action.payload;

		if (capacity === null) {
			// if the capacity has been removed, we remove the entry from state so that we fall back to the default capacity
			return unsetIn(state, [teamId, 'capacityBySprintId', iterationId]);
		}

		const newState = chain(state)
			.setIn([teamId, 'capacityBySprintId', iterationId, 'capacity'], capacity)
			.value();

		return newState;
	}

	if (action.type === UPDATE_ITERATION_CAPACITY_SUCCESS) {
		const { teamId, iterationId, capacity } = action.payload;

		if (capacity === null) {
			// if the capacity has been removed, we remove the entry from state so that we fall back to the default capacity
			return unsetIn(state, [teamId, 'capacityByIterationId', iterationId]);
		}

		const newState = chain(state)
			.setIn([teamId, 'capacityByIterationId', iterationId, 'capacity'], capacity)
			.value();

		return newState;
	}

	return state;
};
