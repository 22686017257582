import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { setReturnToProjectUrl } from '@atlassian/jira-navigation-apps-sidebar-project-utils';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import messages from './messages';

type Props = Intl & {
	url: string;
};
export const ConfigureBoardItem = ({ intl, url }: Props) => {
	const { push } = useRouterActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ actionSubject: 'dropdownItem', action: 'clicked' }),
			'clickConfigureBoardItem',
		);
		setReturnToProjectUrl();
		push(url);
	}, [push, url, createAnalyticsEvent]);

	return <DropdownItem onClick={onClick}>{intl.formatMessage(messages.displayText)}</DropdownItem>;
};

export default injectIntl(ConfigureBoardItem);
