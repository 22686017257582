import type { Dispatch } from 'redux';
import Extension from '@atlassian/jira-common-components-ecosystem-operations';
import { connect } from '@atlassian/jira-react-redux';
import { REFRESH_SOURCE_MODAL_CLOSED } from '../../../model/constants';
import { workRefreshData } from '../../../state/actions/work';
import { operationsSelector } from '../../../state/selectors/board/board-selectors';
import type { State } from '../../../state/types';

export const mapStateToProps = (state: State) => ({
	operations: operationsSelector(state),
});

type DispatchProps = {
	onModalClose: () => void;
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onModalClose: () => {
		dispatch(workRefreshData(REFRESH_SOURCE_MODAL_CLOSED));
	},
});

const ConnectedExtension = connect(mapStateToProps, mapDispatchToProps)(Extension);

export default ConnectedExtension;
