import { useCallback, useRef } from 'react';
import { useIsAnyLevelOfAdmin } from '../is-any-level-of-admin';
import { useInsightsStoreContainer } from '../sweet-state';
import type { State } from '../sweet-state/types';
import { useIsProjectType } from '../use-is-project-type';
import type { ExtendedActions } from './types';

export const useInsightsContainer = <TState extends State>(): readonly [
	TState,
	ExtendedActions<TState>,
] => {
	const [state, actions] = useInsightsStoreContainer();
	const memoCallbacksRef = useRef({});

	const toggleAction = actions.toggleProp;

	useIsAnyLevelOfAdmin({
		onIsAnyLevelOfAdminUpdated: actions.setInfo,
	});

	useIsProjectType({ onIsProjectTypeUpdated: actions.setInfo });

	/*
      This will create a memoized function callback scoped to the current scope + propName so we can easily
      call this function from the onClick of any of the composed components.
      ex:
        <InsightsContainer>
          {({ data: { isPanelOpen } }, { onToggleCallback }) => {
            <HidePanelButton onClick={onToggleCallback('isPanelOpen')}
            <Panel isPanelOpen={isPanelOpen} />
          }}
        <InsightsContainer>
    */
	const onToggleCallback = useCallback(
		(propName: keyof TState['data']) => {
			if (!memoCallbacksRef.current) {
				memoCallbacksRef.current = {};
			}

			const callbacksMap = memoCallbacksRef.current;

			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
			if (propName && !callbacksMap[propName]) {
				// this will prevent recreate the callback on each render
				// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
				callbacksMap[propName] = () => toggleAction(propName);
			}

			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
			return callbacksMap[propName];
		},
		[toggleAction],
	);

	const updateContainerAction = actions.updateContainer;

	/*
      This will create a memoized function callback scoped to the current scope + propName so we can easily
      call this function from the onClick of any of the composed components.
      ex:
        <InsightsContainer>
          {({ data: { settings } }, { onDataUpdatedCallback }) => {
            <LoadPanelSettings onLoad={onDataUpdatedCallback('settings')}
            <Panel settings={settings} />
          }}
        <InsightsContainer>
    */
	const onDataUpdatedCallback = useCallback(
		(propName: keyof TState['data']) => {
			if (!memoCallbacksRef.current) {
				memoCallbacksRef.current = {};
			}

			const callbacksMap = memoCallbacksRef.current;

			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
			if (!callbacksMap[propName]) {
				// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
				callbacksMap[propName] = (data: TState['data'][propName]) =>
					// this will prevent recreate the callback on each render
					updateContainerAction({
						[propName]: data,
					});
			}

			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
			return callbacksMap[propName];
		},
		[updateContainerAction],
	);

	return [
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		state as TState,
		{
			// preferred usage is onDataUpdatedCallback and onToggleCallback
			onDataUpdatedCallback,
			onToggleCallback,
			// when we need a big customization and onDataUpdatedCallback and onToggleCallback do not fit
			// to solve the problem, we can use the original implementation onUpdate and onToggle
			doUpdate: actions.updateContainer,
			doToggle: actions.toggleProp,
			doClean: actions.cleanState,
			// pass through actions (from insights container)
			refreshUniqueId: actions.refreshUniqueId,
			updateIsDeepLinking: actions.updateIsDeepLinking,
		},
	] as const;
};
