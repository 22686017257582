/**
 * Feature flags convention: go/frontend-feature-flags
 */

import { ff } from '@atlassian/jira-feature-flagging';

// <qfeyaerts>
export const INCREMENT_PLANNING_BOARD_M1 =
	'com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1';
export const isIncrementPlanningBoardM1Enabled = () => ff(INCREMENT_PLANNING_BOARD_M1);
// </qfeyaerts>
