import { useState, useCallback } from 'react';
import isNil from 'lodash/isNil';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { ERROR_REPORTING_TEAM, PACKAGE_NAME } from '../../common/constants';
import type { CustomField } from './types';

export const useFetchCustomFields = ({ planId }: { planId: string | undefined }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [customFields, setCustomFields] = useState<CustomField[] | null>(null);

	const fetchCustomFields = useCallback(async () => {
		try {
			if (isNil(planId)) {
				throw new Error('planId is empty in the fetch custom fields request');
			}
			setIsLoading(true);
			const rawCustomFields = await performGetRequest(
				`/rest/jpo/2.0/plans/${planId}/increments/scopeFilterFields`,
			);
			setCustomFields(rawCustomFields || []);
			setError(null);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow
		} catch (error: any) {
			fireErrorAnalytics({
				meta: {
					id: 'useFetchCustomFields',
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
			setError(error);
			setCustomFields(null);
		} finally {
			setIsLoading(false);
		}
	}, [planId]);
	return {
		isLoading,
		error,
		customFields,
		fetchCustomFields,
	};
};
