import {
	type OptimisticUiMeta,
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
} from '../../meta/optimistic-ui-meta';

// request
export const ADD_TO_SPRINT_REQUEST = 'state.actions.sprints.add-to-sprint.ADD_TO_SPRINT_REQUEST';

export interface AddToSprintRequestAction {
	type: typeof ADD_TO_SPRINT_REQUEST;
	payload: {
		issueId: string;
		sprintId?: number;
	};
	meta?: OptimisticUiMeta;
}

export function addToSprintRequest(
	issueId: string,
	sprintId?: number | undefined,
): AddToSprintRequestAction {
	return {
		type: ADD_TO_SPRINT_REQUEST,
		payload: { issueId, sprintId },
		meta: beginOptimisticUiMeta(),
	};
}

// success
export const ADD_TO_SPRINT_SUCCESS = 'state.actions.sprints.add-to-sprint.ADD_TO_SPRINT_SUCCESS';

export interface AddToSprintSuccessAction {
	type: typeof ADD_TO_SPRINT_SUCCESS;
	payload: {
		issueKey: string;
		sprintId: number;
	};
	meta: OptimisticUiMeta;
}

export function addToSprintSuccess(
	issueKey: string,
	sprintId: number,
	optimisticId: string,
): AddToSprintSuccessAction {
	return {
		type: ADD_TO_SPRINT_SUCCESS,
		payload: { issueKey, sprintId },
		meta: commitOptimisticUiMeta(optimisticId),
	};
}

// failure
export const ADD_TO_SPRINT_FAILURE = 'state.actions.sprints.add-to-sprint.ADD_TO_SPRINT_FAILURE';

export interface AddToSprintFailureAction {
	type: typeof ADD_TO_SPRINT_FAILURE;
	payload: {
		issueKey: string;
		sprintId: number;
	};
	meta: OptimisticUiMeta;
}

export function addToSprintFailure(
	issueKey: string,
	sprintId: number,
	optimisticId: string,
): AddToSprintFailureAction {
	return {
		type: ADD_TO_SPRINT_FAILURE,
		payload: { issueKey, sprintId },
		meta: commitOptimisticUiMeta(optimisticId),
	};
}

export type Action = AddToSprintRequestAction | AddToSprintSuccessAction | AddToSprintFailureAction;
