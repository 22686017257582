import { merge, setIn } from 'icepick';
import { CARD_MEDIA_DATA_SET, type CardMediaDataSetAction } from '../../../actions/card';
import { ISSUE_MEDIA_VISIBILITY_CHANGE_REQUEST } from '../../../actions/issue/media';
import { type WorkDataSetAction, WORK_DATA_SET } from '../../../actions/work';
import type { Action } from '../../../types';
import type { IssueMediaState } from './types';

export const issueMediaReducer = (state: IssueMediaState = {}, action: Action): IssueMediaState => {
	if (action.type === ISSUE_MEDIA_VISIBILITY_CHANGE_REQUEST) {
		const { issueId, isVisible } = action.payload;
		return setIn(state, [`${issueId}`, 'isHiddenByUser'], !isVisible);
	}

	if (action.type === WORK_DATA_SET) {
		const {
			payload: { issueMedia },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as WorkDataSetAction;

		return merge(state, issueMedia);
	}

	if (action.type === CARD_MEDIA_DATA_SET) {
		const {
			payload: { issueMedia },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as CardMediaDataSetAction;

		return merge(state, issueMedia);
	}

	return state;
};
