import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/concatMap';
import {
	fireOperationalAnalytics,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { REFRESH_SOURCE_COLUMN_DELETE, REFRESH_SOURCE_FAILURE } from '../../model/constants';
import columnDeleteService, { type ColumnDeleteData } from '../../services/column-graphql/delete';
import {
	columnDeleteFailure,
	columnDeleteSuccess,
	COLUMN_DELETE_REQUEST,
} from '../../state/actions/column/delete';
import { workRefreshData } from '../../state/actions/work';
import {
	contextPathSelector,
	projectKeySelector,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(COLUMN_DELETE_REQUEST).concatMap((action) => {
		const state = store.getState();
		const contextPath = contextPathSelector(state);
		const boardId = rapidViewIdSelector(state);
		const projectKey = projectKeySelector(state);

		const {
			payload: { columnId, closestColumn },
			meta: { optimistic, analyticsEvent },
		} = action;

		return columnDeleteService(
			contextPath,
			boardId,
			columnId,
			closestColumn && closestColumn.id,
			projectKey,
		)
			.flatMap((columnsDeleteData: ColumnDeleteData) => {
				const { columns, cardTransitions } = columnsDeleteData;

				if (analyticsEvent) {
					fireTrackAnalytics(analyticsEvent, 'column deleted');
				}

				const actions: Action[] = [
					columnDeleteSuccess(optimistic.id, closestColumn, columns, cardTransitions),
				];

				if (!closestColumn) {
					actions.push(workRefreshData(REFRESH_SOURCE_COLUMN_DELETE));
				}

				return Observable.from(actions);
			})
			.catch((error) => {
				if (analyticsEvent) {
					fireOperationalAnalytics(analyticsEvent, 'column deleteFailed', {
						error,
					});
				}

				return Observable.of(
					columnDeleteFailure(optimistic.id, columnId),
					// @ts-expect-error - TS2769 - No overload matches this call.
					workRefreshData(REFRESH_SOURCE_FAILURE),
				);
			});
	});
