import type { IssueMediaVisibilityChangeRequestAction } from '../../../actions/issue/media';
import type { State } from '../../../reducers/types';

export const issueMediaVisibilityChange =
	(eventPrefix: string) =>
	(preState: State, state: State, action: IssueMediaVisibilityChangeRequestAction) => {
		const { issueId, isVisible } = action.payload;
		return {
			name: `${eventPrefix}.card.media.toggled.card`,
			data: {
				issueId,
				enabled: isVisible,
			},
		};
	};
