import 'rxjs/add/observable/from';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/mergeMap';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import {
	CARD_NEXT_COLUMN,
	CARD_PREV_COLUMN,
	cardSingleSelect,
	cardFocus,
	type CardColumnNavigationAction,
} from '../../state/actions/card';
import { cardAutoScroll } from '../../state/actions/card/card-auto-scroll';
import { openIssueModalDebounced } from '../../state/actions/issue/modal';
import { getCardActive } from '../../state/selectors/card/card-selectors';
import { isIssueModalShowingSelector } from '../../state/selectors/issue/issue-modal-selectors';
import {
	getFirstSelectableIssueInFirstColumn,
	getFirstSelectableIssueInLastColumn,
	makeGetNeighbouringIssueInNextColumn,
	makeGetNeighbouringIssueInPrevColumn,
	isActiveCardVisible as isActiveCardVisibleSelector,
} from '../../state/selectors/issue/issue-navigation-selectors';
import { getIssueById } from '../../state/selectors/issue/issue-selectors';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types';

const cardColumnNavigationEpic = (
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> =>
	action$
		.ofType(CARD_NEXT_COLUMN, CARD_PREV_COLUMN)
		.throttleTime(200)
		.mergeMap(({ type, payload }: CardColumnNavigationAction): Observable<Action> => {
			const state = store.getState();
			const activeCardId = getCardActive(state);
			// Applying a filter or collapsing a swimlane does not clear the active card,
			// So we need to check manually if it is actually visible.
			const isActiveCardVisible = isActiveCardVisibleSelector(state);

			const isCardNextColumn = type === CARD_NEXT_COLUMN;

			// The function to call when there's no card currently active
			const getFirstSelectableIssueFn = isCardNextColumn
				? getFirstSelectableIssueInFirstColumn
				: getFirstSelectableIssueInLastColumn;

			// The function to call when there's an active card
			const getNextOrPrevIssueFn = isCardNextColumn
				? makeGetNeighbouringIssueInNextColumn
				: makeGetNeighbouringIssueInPrevColumn;

			const newActiveIssueId =
				isNil(activeCardId) || !isActiveCardVisible
					? getFirstSelectableIssueFn(state)
					: getNextOrPrevIssueFn(state)(activeCardId);

			const attributes = isCardNextColumn
				? { id: 'nextColumnKeyboardShortcut', key: 'n' }
				: { id: 'previousColumnKeyboardShortcut', key: 'p' };

			payload.fireShortcutAnalyticsFn(attributes);

			if (isNil(newActiveIssueId)) {
				return Observable.empty<never>();
			}

			const actions: Action[] = [
				cardSingleSelect(newActiveIssueId),
				cardFocus(newActiveIssueId),
				cardAutoScroll(newActiveIssueId),
			];

			if (isIssueModalShowingSelector(state)) {
				const issueKey = getIssueById(state, newActiveIssueId).key;
				actions.push(openIssueModalDebounced(issueKey));
			}

			return Observable.from(actions);
		});

export default cardColumnNavigationEpic;
