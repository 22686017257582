import React, { memo, useCallback, type MouseEvent } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { HighlightText, shouldHighlightText } from '../highlight-text';
import messages from './messages';
import type { Props } from './types';

export const CardKey = memo(
	({
		text,
		highlight = [],
		onClick,
		isDone = false,
		stopEventPropagation = true,
		openInNewTab = false,
	}: Props) => {
		const { formatMessage } = useIntl();
		const highlightedText =
			highlight && shouldHighlightText(text, highlight) ? (
				<HighlightText phrases={highlight} content={text} />
			) : (
				text
			);

		const onClickKey = useCallback(
			(event: React.MouseEvent<HTMLAnchorElement>) => {
				const isModifierClick = event.ctrlKey || event.altKey || event.metaKey || event.shiftKey;
				stopEventPropagation && event.stopPropagation();

				if (!isModifierClick && !openInNewTab) {
					event.preventDefault();
					onClick?.(event);
				}
			},
			[onClick, openInNewTab, stopEventPropagation],
		);

		// prevent the context menu and Bento from opening
		const onContextMenu = useCallback((event: MouseEvent) => event.stopPropagation(), []);

		return (
			<Container data-testid="platform-card.common.ui.key.key">
				<Tooltip content={text}>
					<StyledLink
						isLineThrough={Boolean(isDone)}
						href={`/browse/${text}`}
						target="_blank"
						onContextMenu={onContextMenu}
						onClick={onClickKey}
						{...(Boolean(isDone) && {
							'aria-label': formatMessage(messages.label, {
								issueKey: text,
							}),
						})}
					>
						<Key>{highlightedText}</Key>
					</StyledLink>
				</Tooltip>
			</Container>
		);
	},
);

export default CardKey;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const StyledLinkControl = styled.a<{ isLineThrough: boolean }>((props) => ({
	userSelect: 'initial',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N100),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	textDecoration: props.isLineThrough ? 'line-through' : 'none',
	'&:focus, &:hover, &:visited, &:link, &:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		textDecoration: props.isLineThrough ? 'line-through' : 'none',
	},
}));

type StyledLinkProps = { isLineThrough: boolean };
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledLinkExperiment = styled2.a<StyledLinkProps>({
	userSelect: 'initial',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N100),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	textDecoration: (props) => (props.isLineThrough ? 'line-through' : 'none'),
	'&:focus, &:hover, &:visited, &:link, &:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		textDecoration: (props) => (props.isLineThrough ? 'line-through' : 'none'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledLink = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	StyledLinkExperiment,
	StyledLinkControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled2.div({
	flex: '0 1 auto',
	direction: 'rtl',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	'&:focus-within': {
		outline: `${token('border.width.outline', '2px')} auto
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
            ${token('color.border.focused', colors.B200)}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Key = styled2.span({
	display: 'block',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	fontWeight: 600,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N100),
});
