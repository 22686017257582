import { useCallback } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log';
import { INSIGHTS_PACKAGE } from '@atlassian/jira-insights-common-constants/src/common/constants/package';
import type { UFOExperience } from '@atlassian/ufo';
import { experiences } from '../common/constants';

// Replace with lodash/noop
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export const useUfoErrorLogger = ({
	eventFrom,
	ufoExperience,
}: {
	eventFrom: string;
	ufoExperience?: UFOExperience;
}) => {
	const ufoErrorLogger = useCallback(
		(error: Error) =>
			log.safeErrorWithoutCustomerData(
				`${INSIGHTS_PACKAGE}.${eventFrom}.${ufoExperience?.id || 'unknown'}.${experiences.RENDER}`,
				'Insights experience failed',
				error,
			),
		[eventFrom, ufoExperience],
	);

	return ufoExperience ? ufoErrorLogger : noop;
};
