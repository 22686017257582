import React, { type ComponentType } from 'react';
import { styled } from '@compiled/react';
import { lazyForPaint } from 'react-loosely-lazy';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props } from './types';

const LazyBoardInsightsPanel: ComponentType<Props> = lazyForPaint(
	() => import(/* webpackChunkName: "async-board-insights-panel" */ './index'),
);

export const AsyncBoardInsightsPanel = (props: Props) => (
	<Placeholder
		name="board-insights-panel"
		fallback={
			<Container>
				<Spinner size="large" />
			</Container>
		}
	>
		<LazyBoardInsightsPanel {...props} />
	</Placeholder>
);

const maxWidth = gridSize * 47;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	paddingBottom: token('space.025', '2px'),
	margin: `0 ${token('space.500', '40px')} ${token('space.200', '16px')} ${token(
		'space.150',
		'12px',
	)}`,
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${maxWidth}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${maxWidth}px`,
});
