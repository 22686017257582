import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import intersection from 'lodash/intersection';
import memoize from 'lodash/memoize';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import type { IssueId } from '@atlassian/jira-software-board-common';
import type { IssueEntities, IssueKey } from '../../../model/issue/issue-types';
import { SWIMLANE_BY_SUBTASK } from '../../../model/swimlane/swimlane-modes';
import { getIssueChildren, issueChildrenSelector } from '../issue-children';
import { issueChildrenTypeIdsSelector } from '../issue-type/issue-type-selectors';
import {
	getIsCMPBoard,
	getIssues,
	getMissingParents,
	getOrderedIssueChildrenIds,
	getOrderedIssueIds,
	isBoardRankable,
} from '../software/software-selectors';
import { getSwimlaneMode } from '../swimlane/swimlane-mode-selectors';

export const issueIdsWithSubtasksSelector = createSelector(
	[issueChildrenSelector],
	(issueChildren) =>
		uniq(
			issueChildren
				.map((issueChild) => issueChild.parentId || 0)
				.filter((parentId) => parentId !== 0),
		),
);

export const issuesWithoutSubtasksSelector = createSelector(
	[getIssues, issueIdsWithSubtasksSelector],
	(issues: IssueEntities, issueIdsWithSubtasks) =>
		omit(
			issues,
			issueIdsWithSubtasks.map((issueId) => String(issueId)),
		),
);

export const orderedIssuesWithSubtasksSelector = createSelector(
	[getOrderedIssueIds, getIssues, issueIdsWithSubtasksSelector, getIsCMPBoard, getMissingParents],
	(orderedIssueIds, issues, issueIdsWithSubtasks, isCMPBoard, missingParents) => {
		const allIssueIds: IssueId[] = isCMPBoard
			? [
					...orderedIssueIds,
					...Object.keys(missingParents).map((missingParentId) => Number(missingParentId)),
				]
			: orderedIssueIds;

		const allIssues = (() => {
			if (!isCMPBoard) {
				return { ...issues };
			}

			return {
				...missingParents,
				...issues,
			};
		})();

		return intersection(allIssueIds, issueIdsWithSubtasks)
			.map((issueId) => allIssues[String(issueId)])
			.filter((issue) => issue);
	},
);

export const boardIssuesSelector = createSelector(
	[getSwimlaneMode, getIssues, issuesWithoutSubtasksSelector, getIssueChildren, getIsCMPBoard],
	(swimlaneMode, allIssues, issuesWithoutSubtasks, children, isCMPBoard) => {
		if (swimlaneMode === SWIMLANE_BY_SUBTASK.id) {
			return isCMPBoard
				? {
						...allIssues,
						...children,
					}
				: {
						...issuesWithoutSubtasks,
						...children,
					};
		}

		if (isCMPBoard) {
			return { ...allIssues, ...children };
		}

		return allIssues;
	},
);

export const boardIssuesAndChildrenSelector = createSelector(
	[getIssues, getIssueChildren],
	(allIssues, children) => ({
		...allIssues,
		...children,
	}),
);

export const boardOrderedIssueIdsSelector = createSelector(
	[
		getSwimlaneMode,
		getIssues,
		issuesWithoutSubtasksSelector,
		getOrderedIssueIds,
		getOrderedIssueChildrenIds,
		getIsCMPBoard,
		getIssueChildren,
		isBoardRankable,
	],
	(
		swimlaneMode,
		allIssues,
		issuesWithoutSubtasks,
		orderedIssueIds,
		orderedChildrenIds,
		isCMPBoard,
		children,
		isRankable,
	) => {
		if (swimlaneMode === SWIMLANE_BY_SUBTASK.id) {
			if (isCMPBoard) {
				// ordered swimlane header issue ids. It does not include children issue id.
				const orderedSwimlaneHeaderIssueIds = orderedIssueIds.filter((id) => id in allIssues);
				return [...orderedSwimlaneHeaderIssueIds, ...orderedChildrenIds];
			}

			const issueIdsWithoutSubtasks = Object.keys(issuesWithoutSubtasks);
			const orderedIssueWithoutSubtasksIds = orderedIssueIds.filter((id) =>
				issueIdsWithoutSubtasks.includes(String(id)),
			);
			return [...orderedIssueWithoutSubtasksIds, ...orderedChildrenIds];
		}

		if (isCMPBoard && isRankable) {
			const childrenIdsByParentId = groupBy(
				orderedChildrenIds,
				(childId) => children[childId].parentId,
			);

			return orderedIssueIds.flatMap((issueId) => [
				issueId,
				...(childrenIdsByParentId[issueId] ?? []),
			]);
		}

		return orderedIssueIds;
	},
);

export const isBoardIssueAChild = createSelector(
	[issueChildrenTypeIdsSelector, boardIssuesSelector],
	(issueChildrenTypeIds, boardIssues) => {
		const isBoardIssueAChildCheck = (issueId: IssueId) => {
			const issue = boardIssues[String(issueId)];
			if (!issue) return false;
			return issueChildrenTypeIds.includes(String(issue.typeId));
		};
		return isBoardIssueAChildCheck;
	},
);

export const makeGetIssueKeyFromIssueId = createSelector(getIssues, (issues) =>
	memoize((issueId: IssueId): IssueKey | undefined => issues[issueId]?.key ?? undefined),
);
