import type { IssueId } from '@atlassian/jira-software-board-common';

export const SHOW_ISSUE_FLAG_WITH_COMMENT_MODAL =
	'state.actions.issue.flag-with-comment-modal.SHOW_ISSUE_FLAG_WITH_COMMENT_MODAL' as const;

export type ShowIssueFlagWithCommentModalAction = {
	type: typeof SHOW_ISSUE_FLAG_WITH_COMMENT_MODAL;
	payload: { issueId: IssueId };
};

export const showIssueFlagWithCommentModal = (
	issueId: IssueId,
): ShowIssueFlagWithCommentModalAction => ({
	type: SHOW_ISSUE_FLAG_WITH_COMMENT_MODAL,
	payload: { issueId },
});

export const HIDE_ISSUE_FLAG_WITH_COMMENT_MODAL =
	'state.actions.issue.flag-with-comment-modal.HIDE_ISSUE_FLAG_WITH_COMMENT_MODAL' as const;

export type HideIssueFlagWithCommentModalAction = {
	type: typeof HIDE_ISSUE_FLAG_WITH_COMMENT_MODAL;
};

export const hideIssueFlagWithCommentModal = (): HideIssueFlagWithCommentModalAction => ({
	type: HIDE_ISSUE_FLAG_WITH_COMMENT_MODAL,
});

export type Action = ShowIssueFlagWithCommentModalAction | HideIssueFlagWithCommentModalAction;
