import {
	SHOW_ISSUE_ADD_LABEL_MODAL,
	HIDE_ISSUE_ADD_LABEL_MODAL,
} from '../../../actions/issue/add-label-modal';
import type { Action } from '../../../types';
import type { AddLabelModalState } from './types';

const defaultState = { isOpen: false } as const;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: AddLabelModalState = defaultState, action: Action): AddLabelModalState => {
	if (action.type === SHOW_ISSUE_ADD_LABEL_MODAL) {
		return {
			isOpen: true,
			issueIds: action.payload,
		};
	}
	if (action.type === HIDE_ISSUE_ADD_LABEL_MODAL) {
		return { isOpen: false };
	}

	return state;
};
