import ExtensibleError from '@atlassian/jira-extensible-error';
import type { AGGError } from '@atlassian/jira-relay-errors';

export const RELAY_REQUIRED_ERROR_PREFIX = 'Relay: Missing @required value at path';

export const isRelayValidationError = (error: Error) =>
	error.message.includes(RELAY_REQUIRED_ERROR_PREFIX);

export class AGGErrors extends ExtensibleError {
	name = 'AGGError';

	graphQLErrors: AGGError[];

	traceId?: string;

	statusCode?: number;

	constructor(errors: AGGError[], traceId?: string) {
		// this is used to generate same 'hash' for error flag and sla event attribute
		super(errors[0].message, { cause: errors[0] });

		this.graphQLErrors = errors;
		this.traceId = traceId;
		this.statusCode = errors[0].extensions?.statusCode ?? undefined;
	}
}
