/** @jsx jsx */
import React, { Component, createRef, type ChangeEvent, type KeyboardEvent } from 'react';
import { css, jsx } from '@compiled/react';
import noop from 'lodash/noop';
import { N0, N800, N200 } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import { FULL, MAX_SUMMARY_LENGTH } from '../../../common/constants';
import {
	type CardAppearance,
	type ValidationError,
	emptyValidationError,
} from '../../../common/types';
import messages from './messages';

const textAreaStyles = css({
	border: 'none',
	outline: 'none',
	margin: 0,
	padding: 0,
	height: token('space.500', '40px'),
	width: '100%',
	resize: 'none',
	backgroundColor: token('elevation.surface', N0),
	color: token('color.text', N800),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	'&::placeholder': {
		color: token('color.text.subtlest', N200),
		opacity: 1,
	},
});

const appearanceStyles = css({
	height: token('space.250', '20px'),
	whiteSpace: 'pre',
	scrollbarWidth: 'none',
	'-ms-overflow-style': 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&::-webkit-scrollbar': {
		display: 'none',
	},
});

export const stripConsecutiveWhitespacesAndTrim = (value: string): string =>
	value.replace(/\s/g, ' ').replace(/  +/g, ' ').trim();

export const splitSummary = (summary: string): string[] =>
	summary.split('\n').reduce((summaries: string[], item: string) => {
		const trimmedItem = stripConsecutiveWhitespacesAndTrim(item);
		// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
		return [...summaries, ...(trimmedItem ? [trimmedItem] : [])];
	}, []);

export const validateSummary = (summary: string, { formatMessage }: IntlShape): ValidationError => {
	if (!fg('remove_unused_multi_create__f39014bf')) {
		const sanitisedSummaries = splitSummary(summary);
		const isSummaryMultiline = sanitisedSummaries.length > 1;
		const invalidSummary = sanitisedSummaries.some((item) => item.length > MAX_SUMMARY_LENGTH);

		if (isSummaryMultiline) {
			const invalidSummaryConcat = sanitisedSummaries.join(' ').length > MAX_SUMMARY_LENGTH;

			return {
				...emptyValidationError,
				summary: invalidSummary ? formatMessage(messages.maxLengthMultiple) : '',
				summaryConcat: invalidSummaryConcat ? formatMessage(messages.maxLength) : '',
			};
		}

		// show a warning when summary has reached max length
		if (summary.length === MAX_SUMMARY_LENGTH) {
			return {
				...emptyValidationError,
				summary: formatMessage(messages.maxLength),
			};
		}

		return {
			...emptyValidationError,
			summary: invalidSummary ? formatMessage(messages.maxLength) : '',
		};
	}

	const trimmedSummary = stripConsecutiveWhitespacesAndTrim(summary);
	// show a warning when summary has reached max length
	if (trimmedSummary.length >= MAX_SUMMARY_LENGTH) {
		return {
			...emptyValidationError,
			summary: formatMessage(messages.maxLength),
		};
	}

	return emptyValidationError;
};

export const hasValidationError = (validationError: ValidationError): boolean =>
	Object.values(validationError).filter((error) => error).length > 0;

export type Props = {
	autoFocus: boolean;
	shouldScrollIntoView: boolean;
	initialSummary: string;
	appearance: CardAppearance;
	placeholderText: string;
	submitCounter: number;
	onChangeSummary: (arg1: { summary: string; validationError: ValidationError }) => void;
	onSubmit: () => void;
	onCancel: () => void;
	// Set to required on FG cleanup jsw_roadmaps_timeline-issue-creation-ally
	setFocusOnTrigger?: () => void;
	intl: IntlShape;
	selectedIssueType?: string;
};

type State = {
	summary: string;
	validationError: ValidationError;
	ariaLive: string;
};

// eslint-disable-next-line jira/react/no-class-components
export class Summary extends Component<Props, State> {
	static defaultProps = {
		autoFocus: true,
		shouldScrollIntoView: false,
		initialSummary: '',
		placeholderText: '',
		appearance: FULL,
		submitCounter: 0,
		selectedIssueType: undefined,
		onChangeSummary: noop,
		onSubmit: noop,
		onCancel: noop,
		setFocusOnTrigger: noop,
	};

	state = {
		summary: this.props.initialSummary,
		validationError: validateSummary(this.props.initialSummary, this.props.intl),
		ariaLive: '',
	};

	// For the cursor to be move to the end when the component remount, the textarea has to have focus first,
	// then setting the setSelectionRange in the textarea will send the cursor to the end of the textarea value.
	// And setting the textarea scrollHeight to scrollTop will send the cursor to the last line when add multiples.
	componentDidMount() {
		const { shouldScrollIntoView, autoFocus } = this.props;
		const textArea = this.textAreaRef.current;
		if (textArea) {
			const { length } = textArea.value;
			// preventScroll used to prevent unwanted scrolling with virtualization mounting / re-mounting the component
			textArea.focus({
				preventScroll: !shouldScrollIntoView,
			});
			textArea.setSelectionRange(length, length);
			textArea.scrollTop = textArea.scrollHeight;
			if (autoFocus) {
				textArea.focus();
				textArea.setSelectionRange(length, length);
				textArea.scrollTop = textArea.scrollHeight;
			}
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.submitCounter > prevProps.submitCounter) {
			this.reset();
		}
	}

	textAreaRef = createRef<HTMLTextAreaElement>();

	onChangeSummary = (event: ChangeEvent<HTMLTextAreaElement>) => {
		const { onChangeSummary, intl } = this.props;
		const summary = stripConsecutiveWhitespacesAndTrim(event.target.value);
		const displayedSummary = event.target.value.replace(/[\n\r]+/g, ' ');
		const validationError = validateSummary(summary, intl);

		onChangeSummary({ summary, validationError });
		this.setState({
			summary: displayedSummary,
			validationError,
			ariaLive: '',
		});
	};

	shouldSummarySubmit(): boolean {
		const { summary, validationError } = this.state;
		const sanitisedSummaries = splitSummary(summary);

		// the warning when summary has reached max length, should not block submit
		return (
			(!hasValidationError(validationError) || summary.length === MAX_SUMMARY_LENGTH) &&
			sanitisedSummaries.length === 1
		);
	}

	onKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
		event.stopPropagation();
		const { onSubmit, onCancel } = this.props;

		// RTL tests cannot send event ASCII code but only the actual string `Enter` or `Escape`
		// ref: https://github.com/testing-library/user-event/blob/main/src/keyboard/keyMap.ts#L60C24-L60C35
		if (event.key === 'Enter' && !event.nativeEvent.isComposing) {
			event.preventDefault();
			if (this.shouldSummarySubmit()) {
				traceUFOPress('inline-card-create-submit', event.timeStamp);
				onSubmit();
				this.setState({
					ariaLive: this.state.summary,
				});
			}
		} else if (
			!fg('jsw_roadmaps_timeline-issue-creation-ally') &&
			event.key === 'Escape' &&
			!event.nativeEvent.isComposing
		) {
			return onCancel();
		}
	};

	onKeyUp = (event: KeyboardEvent<HTMLTextAreaElement>) => {
		event.stopPropagation();
		const { onCancel, setFocusOnTrigger } = this.props;

		// nativeEvent isComposing check is here to prevent ICC from closing when IMEs are open
		// When an IME is open, pressing Escape should close the IME, but ICC should remain open
		if (event.key === 'Escape' && !event.nativeEvent.isComposing) {
			// Set to required on FG cleanup jsw_roadmaps_timeline-issue-creation-ally
			setFocusOnTrigger?.();
			return onCancel();
		}
	};

	reset() {
		this.setState({
			summary: '',
			validationError: emptyValidationError,
		});
	}

	isValid = (): boolean => {
		const { summary, summaryConcat } = this.state.validationError;
		return summary === '' && summaryConcat === '';
	};

	render() {
		const {
			placeholderText,
			appearance,
			selectedIssueType,
			intl: { formatMessage },
		} = this.props;

		const ariaLabel = selectedIssueType
			? formatMessage(messages.ariaLabelSelectedType, { value: selectedIssueType })
			: formatMessage(messages.ariaLabel);

		const ariaLabelOld = formatMessage(messages.ariaLabelOld);

		const accessibilityProps = {
			'aria-label': fg('jsw_roadmaps_timeline-issue-creation-ally') ? ariaLabel : ariaLabelOld,
			'aria-required': true,
			'aria-invalid': !this.isValid(),
		};
		const accessibilityMessage = formatMessage(messages.issueWithNameCreatedSuccessfully, {
			name: this.state.ariaLive,
		});
		return (
			<>
				<ScreenReaderText
					aria-live="assertive"
					id="inline-card-create.form.summary.a11y.aria-live"
					data-testid="platform-inline-card-create.ui.form.summary.aria-live"
				>
					{this.state.ariaLive && accessibilityMessage}
				</ScreenReaderText>
				<textarea
					css={[textAreaStyles, appearance !== FULL && appearanceStyles]}
					ref={this.textAreaRef}
					/**
					 * "data-test-id" is used in JBUS pollinator tests
					 */

					data-testid="platform-inline-card-create.ui.form.summary.styled-text-area"
					placeholder={placeholderText || formatMessage(messages.prompt)}
					value={this.state.summary}
					onChange={this.onChangeSummary}
					onKeyDown={this.onKeyDown}
					{...(fg('jsw_roadmaps_timeline-issue-creation-ally') ? { onKeyUp: this.onKeyUp } : {})}
					rows={appearance === FULL ? 2 : 1}
					maxLength={MAX_SUMMARY_LENGTH}
					tabIndex={0}
					aria-controls="inline-card-create.form.summary.a11y.aria-live"
					{...accessibilityProps}
				/>
			</>
		);
	}
}

export default AnalyticsEventToProps('ICC-summary', {
	onSubmit: 'onSubmitSummary',
	onCancel: 'onCancelSummary',
})(injectIntl(Summary));
