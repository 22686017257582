import type { IssueId } from '@atlassian/jira-software-board-common';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiMeta,
	type OptimisticUiMeta,
} from '../../meta/optimistic-ui-meta';

export const REMOVE_FROM_SPRINT_REQUEST =
	'state.actions.sprints.remove-from-sprint.REMOVE_FROM_SPRINT_REQUEST';

export interface RemoveFromSprintRequestAction {
	type: typeof REMOVE_FROM_SPRINT_REQUEST;
	payload: {
		issueId: IssueId;
	};
	meta: OptimisticUiMeta;
}

export function removeFromSprintRequest(issueId: IssueId): RemoveFromSprintRequestAction {
	return {
		type: REMOVE_FROM_SPRINT_REQUEST,
		payload: { issueId },
		meta: beginOptimisticUiMeta(),
	};
}

export const REMOVE_FROM_SPRINT_SUCCESS =
	'state.actions.sprints.remove-from-sprint.REMOVE_FROM_SPRINT_SUCCESS';

export interface RemoveFromSprintSuccessAction {
	type: typeof REMOVE_FROM_SPRINT_SUCCESS;
	payload: {
		issueKey: string;
	};
	meta: OptimisticUiMeta;
}

export function removeFromSprintSuccess(
	issueId: IssueId,
	issueKey: string,
	optimisticId: string,
): RemoveFromSprintSuccessAction {
	return {
		type: REMOVE_FROM_SPRINT_SUCCESS,
		payload: { issueKey },
		meta: {
			...commitOptimisticUiMeta(optimisticId),
		},
	};
}

export const REMOVE_FROM_SPRINT_FAILURE =
	'state.actions.sprints.remove-from-sprint.REMOVE_FROM_SPRINT_FAILURE';

export interface RemoveFromSprintFailureAction {
	type: typeof REMOVE_FROM_SPRINT_FAILURE;
	payload: {
		issueKey: string;
	};
	meta: OptimisticUiMeta;
}

export function removeFromSprintFailure(
	issueKey: string,
	optimisticId: string,
): RemoveFromSprintFailureAction {
	return {
		type: REMOVE_FROM_SPRINT_FAILURE,
		payload: { issueKey },
		meta: revertOptimisticUiMeta(optimisticId),
	};
}

export type Action =
	| RemoveFromSprintRequestAction
	| RemoveFromSprintSuccessAction
	| RemoveFromSprintFailureAction;
