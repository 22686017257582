import type { RowState } from '../../common/types';
import type { ScrollOptions } from './types';

const VIEWPORT_BUFFER = 50;

export const hasScrolledIntoView = (
	scrollElementEl: HTMLElement | null,
	rowState: RowState | undefined,
) => {
	if (!scrollElementEl || !rowState) return false;

	const topOfRow = rowState.top;
	const bottomOfRow = rowState.top + rowState.height;

	// Make the viewport a bit smaller so the card won't stick to the edge of the screen
	const topOfView = scrollElementEl.scrollTop + VIEWPORT_BUFFER;
	const bottomOfView = scrollElementEl.scrollTop + scrollElementEl.clientHeight - VIEWPORT_BUFFER;

	return (
		(topOfRow > topOfView && topOfRow < bottomOfView) ||
		(bottomOfRow > topOfView && bottomOfRow < bottomOfView) ||
		(topOfRow < topOfView && bottomOfRow > bottomOfView)
	);
};

/*
 * If the list has virtualised children, e.g. virtualised swimlanes, we will scroll the target to the bottom of the screen.
 * If the list does not have virtualised children, e.g. virtualised cards, we will scroll the target to the middle of the screen.
 */
export const getScrollToPosition = (
	scrollElementEl: HTMLElement | null,
	rowState: RowState | undefined,
	options?: ScrollOptions,
) => {
	if (!scrollElementEl || !rowState) return 0;

	if (options?.hasVirtualisedChildren) {
		return rowState.top - scrollElementEl.clientHeight;
	}

	return rowState.top + rowState.height / 2 - scrollElementEl.clientHeight / 3;
};
