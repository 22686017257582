import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { getFlags, setFlag } from '@atlassian/jira-common-have-i-seen-it';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';

const changeboardingFlags = {
	board: 'jsw.filters.board.search-field-changeboarding.shown',
	backlog: 'jsw.filters.backlog.search-field-changeboarding.shown',
} as const;
const CHANGEBOARDING_TTL_SEEN = 1000 * 60 * 60 * 24 * 365; // ~ 365 days
export const hasUserSeenSearchFieldChangeboarding: (
	analyticsEvent: UIAnalyticsEvent,
	screenName: string,
) => Promise<boolean> = (analyticsEvent, screenName) =>
	getFlags({
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly board: "jsw.filters.board.search-field-changeboarding.shown"; readonly backlog: "jsw.filters.backlog.search-field-changeboarding.shown"; }'.
		[changeboardingFlags[screenName]]: {},
	})
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly board: "jsw.filters.board.search-field-changeboarding.shown"; readonly backlog: "jsw.filters.backlog.search-field-changeboarding.shown"; }'.
		.then((res) => res[changeboardingFlags[screenName]].status)
		.catch(() => {
			fireTrackAnalytics(analyticsEvent, 'searchFilterChangeboarding checkSeenFailed');
			return false;
		});

export const setUserSeenSearchFieldChangeboarding: (screenName: string) => Promise<void> = (
	screenName,
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly board: "jsw.filters.board.search-field-changeboarding.shown"; readonly backlog: "jsw.filters.backlog.search-field-changeboarding.shown"; }'.
) => setFlag(changeboardingFlags[screenName], CHANGEBOARDING_TTL_SEEN);
