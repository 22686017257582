import { gqlTagSwag } from '@atlassian/jira-software-swag';
import { mutation$ } from '@atlassian/jira-software-swag/src/services/mutation/index.tsx';
import { columnRankAnalyticsOptions } from '../../common/graphql/metrics';

export const softwareColumnRankOperationName = 'SoftwareColumnRank';

export const columnRankMutation = gqlTagSwag`mutation SoftwareColumnRank($boardId: ID!, $columnId: ID!, $position: Int!){
        rankColumn(input: {boardId: $boardId, columnId: $columnId, position: $position}) {
            success
        }
    }
`;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (contextPath: string, boardId: string, columnId: number, position: number) =>
	mutation$(
		contextPath,
		softwareColumnRankOperationName,
		columnRankMutation,
		{
			boardId,
			columnId,
			position,
		},
		columnRankAnalyticsOptions,
	);
