import {
	type IncrementPlanningSettings,
	type Panels,
	type View,
	SHOW_EPICS,
	SHOW_STATUSES,
	SHOW_VERSIONS,
	SHOW_ISSUE_LINKS_STATS,
	SHOW_ISSUE_KEY,
	SHOW_ISSUE_TYPE,
	SHOW_ASSIGNEE,
	SHOW_PRIORITY,
	SHOW_DEVELOPMENT,
	SHOW_ESTIMATE,
	SHOW_DAYS_IN_COLUMN,
	SHOW_DUE_DATE,
	SHOW_LABELS,
	SHOW_CARD_COVERS,
} from './constant';

export type IssueId = string | number;

export type Issue = {
	id: IssueId;
	key: string;
	summary: string;
	typeUrl?: string;
};

export type State = {
	view: (typeof View)[keyof typeof View] | null;
	isFullscreen: boolean;
	showEpicPanel: boolean;
	showVersionPanel: boolean;
	showQuickFilters: boolean;
	showCompactMode: boolean;
	showEpics: boolean;
	showStatuses: boolean;
	showVersions: boolean;
	showUnscheduledColumn: boolean;
	showIssueLinksStats: boolean;
	showIssueType: boolean;
	showIssueKey: boolean;
	showPriority: boolean;
	showDevelopment: boolean;
	showAssignee: boolean;
	showEstimate: boolean;
	showDaysInColumn: boolean;
	showDueDate: boolean;
	showLabels: boolean;
	showCardCovers: boolean;
	showOfftrackDependencyLines: boolean;
	issueIdsToShowDependencies: IssueId[];
	cardExtraFields: { [key: string]: boolean };
};

export type PanelState = Record<(typeof Panels)[keyof typeof Panels], boolean>;

export const CardDetails = {
	SHOW_ISSUE_KEY,
	SHOW_ISSUE_TYPE,
	SHOW_EPICS,
	SHOW_STATUSES,
	SHOW_VERSIONS,
	SHOW_ISSUE_LINKS_STATS,
	SHOW_PRIORITY,
	SHOW_DEVELOPMENT,
	SHOW_ASSIGNEE,
	SHOW_ESTIMATE,
	SHOW_DAYS_IN_COLUMN,
	SHOW_DUE_DATE,
	SHOW_LABELS,
	SHOW_CARD_COVERS,
} as const;

export type CardDetailsState = Record<(typeof CardDetails)[keyof typeof CardDetails], boolean>;

export type CardExtraFieldsState = Record<string, boolean>;

export type IncrementPlanningSettingsState = {
	[IncrementPlanningSettings.SHOW_UNSCHEDULED_COLUMN]: boolean;
	[IncrementPlanningSettings.SHOW_OFFTRACK_DEPENDENCY_LINES]: boolean;
	[IncrementPlanningSettings.ISSUE_IDS_TO_SHOW_DEPENDENCIES]: IssueId[];
};
