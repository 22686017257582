import { useIntl } from '@atlassian/jira-intl';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { useBoardActionCreator, useBoardSelector } from '../../../../../../../../state';
import { showIssueAddLabelModal } from '../../../../../../../../state/actions/issue/add-label-modal';
import { isCardSelected } from '../../../../../../../../state/selectors/card/card-selectors';
import { ADD_LABEL_ID } from '../../constants';
import messages from '../../messages';
import type { Props } from './types';

export const useAddLabel = ({ issueId, selectedCardIds }: Props): MenuNode[] => {
	const { formatMessage } = useIntl();
	const isSelected = useBoardSelector((state) => isCardSelected(state)(issueId));

	const onAddLabel = useBoardActionCreator(() => {
		const issueIds = isSelected ? selectedCardIds : [issueId];
		return showIssueAddLabelModal(issueIds);
	});

	return [
		{
			type: MenuNodeType.Item,
			label: formatMessage(messages.label),
			id: ADD_LABEL_ID,
			onClick: onAddLabel,
		},
	];
};
