import { useIntl } from '@atlassian/jira-intl';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import {
	MOVE_TO_BACKLOG_MENU_ID,
	MOVE_TO_BOTTOM_OF_COLUMN_MENU_ID,
	MOVE_TO_MENU_ID,
	MOVE_TO_TOP_OF_COLUMN_MENU_ID,
} from '../../constants';
import messages from '../../messages';
import type { ContentProps } from './types';

export const useMoveToContent = ({
	onMoveToBacklog,
	onMoveToTop,
	onMoveToBottom,
	isMoveToBacklogEnabled,
	isMoveToTopActionVisible,
	isMoveToBottomActionVisible,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	const moveToActions: MenuNode[] = [];

	if (isMoveToBacklogEnabled) {
		moveToActions.push({
			type: MenuNodeType.Item,
			id: MOVE_TO_BACKLOG_MENU_ID,
			label: formatMessage(messages.backlog),
			onClick: onMoveToBacklog,
		});
	}

	if (isMoveToTopActionVisible) {
		moveToActions.push({
			type: MenuNodeType.Item,
			id: MOVE_TO_TOP_OF_COLUMN_MENU_ID,
			label: formatMessage(messages.topOfTheColumn),
			onClick: onMoveToTop,
		});
	}
	if (isMoveToBottomActionVisible) {
		moveToActions.push({
			type: MenuNodeType.Item,
			id: MOVE_TO_BOTTOM_OF_COLUMN_MENU_ID,
			label: formatMessage(messages.bottomOfTheColumn),
			onClick: onMoveToBottom,
		});
	}

	return moveToActions.length > 0
		? [
				{
					type: MenuNodeType.Item,
					label: formatMessage(messages.moveTo),
					id: MOVE_TO_MENU_ID,
					children: moveToActions,
				},
			]
		: [];
};
