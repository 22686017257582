import React, { Component, type ReactNode, type MouseEvent as MouseEventType } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import DropdownMenu, { type OnOpenChangeArgs } from '@atlaskit/dropdown-menu';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	fireUIAnalytics,
	ContextualAnalyticsData,
	AnalyticsEventToProps,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';

type Props = {
	items: ReactNode;
	children: ReactNode;
	onContextMenuChange: (isContextMenuOpen: boolean) => void;
};

type State = {
	isContextMenuOpen: boolean;
	contextMenuPositionX: number;
	contextMenuPositionY: number;
};

type WrapperProps = {
	children: ReactNode;
	onSetRef: (ref: HTMLDivElement) => void;
	onClick: (e: React.MouseEvent) => void;
	onContextMenu: (e: React.MouseEvent, analyticsEvent?: UIAnalyticsEvent) => void;
};

const ContextMenuWrapper = ({ children, onClick, onSetRef, onContextMenu }: WrapperProps) => (
	// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
	<div
		ref={onSetRef}
		onClick={onClick}
		onContextMenu={onContextMenu}
		data-testid="platform-card.common.ui.context-menu.wrapper"
	>
		{children}
	</div>
);

const WrapperWithAnalytics = AnalyticsEventToProps('contextMenu', {
	onContextMenu: 'open',
})(ContextMenuWrapper);

// eslint-disable-next-line jira/react/no-class-components
export class CardContextMenu extends Component<Props, State> {
	state = {
		isContextMenuOpen: false,
		contextMenuPositionX: 0,
		contextMenuPositionY: 0,
	};

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (prevState.isContextMenuOpen === false && this.state.isContextMenuOpen === true) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.addEventListener('mousedown', this.onMouseDownOutside);
		}

		if (prevState.isContextMenuOpen === true && this.state.isContextMenuOpen === false) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('mousedown', this.onMouseDownOutside);
		}
	}

	componentWillUnmount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.removeEventListener('mousedown', this.onMouseDownOutside);
	}

	isClickOutside = (e: MouseEvent) => {
		if (!(e.target instanceof Node)) {
			return true;
		}

		const isClickInside =
			(this.refWrapper && this.refWrapper.contains(e.target)) ||
			(this.refDropDownMenu && this.refDropDownMenu.contains(e.target));

		return !isClickInside;
	};

	onMouseDownOutside = (e: MouseEvent) => {
		if (this.isClickOutside(e)) {
			this.onContextMenuClose();
		}
	};

	onMouseDownInside = (e: MouseEventType<HTMLDivElement>) => {
		// prevents click on the context menu item to bubble up and fire card click
		e.preventDefault();
		e.stopPropagation();
	};

	onContextMenu = (e: MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
		if (this.isClickOutside(e)) {
			return;
		}

		e.stopPropagation();
		e.preventDefault();

		if (this.props.items === null) {
			return;
		}

		if (this.refWrapper) {
			const { onContextMenuChange } = this.props;
			const { top, left } = this.refWrapper.getBoundingClientRect();
			this.setState({
				isContextMenuOpen: true,
				contextMenuPositionX: e.clientX - left,
				contextMenuPositionY: e.clientY - top - 15,
			});
			fireUIAnalytics(analyticsEvent, 'openedContextMenu');
			onContextMenuChange(true);
		}

		if (this.refDropDownMenu) {
			this.refDropDownMenu.focus();
		}
	};

	onContextMenuClose = () => {
		const { onContextMenuChange } = this.props;
		this.setState({
			isContextMenuOpen: false,
		});
		onContextMenuChange(false);
	};

	onOpenChange = ({ isOpen }: OnOpenChangeArgs) => {
		if (!isOpen) {
			this.onContextMenuClose();
		}
	};

	onWrapperRefSet = (ref: HTMLElement | null) => {
		this.refWrapper = ref;
	};

	onDropDownRefSet = (ref: HTMLElement | null) => {
		this.refDropDownMenu = ref;
	};

	refWrapper: HTMLElement | null = null;

	refDropDownMenu: HTMLElement | null = null;

	render() {
		const { children, items } = this.props;
		const { isContextMenuOpen, contextMenuPositionX, contextMenuPositionY } = this.state;

		/*
                            * First we don't have the ref anymore, so we can't detect clicks outside of the
                              menu (this is made worse by the fact that the menu is in a portal, because
                              previously the menu was inside of the card / container)
                            * Second, because the menu is in a portal, it does't update its position when
                              the parent is moved
        */
		return (
			<ContextualAnalyticsData sourceType={SCREEN}>
				<WrapperWithAnalytics onSetRef={this.onWrapperRefSet} onContextMenu={this.onContextMenu}>
					{items === null ? null : (
						<ActionsSection
							x={contextMenuPositionX}
							y={contextMenuPositionY}
							onClick={this.onMouseDownInside}
						>
							<DropdownMenu
								key={`${contextMenuPositionX}:${contextMenuPositionY}`}
								spacing="compact"
								isOpen={isContextMenuOpen}
								trigger={({ triggerRef, ...props }) => (
									<TriggerWrapper>
										<Button {...props} ref={triggerRef} appearance="subtle" />
									</TriggerWrapper>
								)}
								onOpenChange={this.onOpenChange}
							>
								<div ref={this.onDropDownRefSet}>{items}</div>
							</DropdownMenu>
						</ActionsSection>
					)}
					{children}
				</WrapperWithAnalytics>
			</ContextualAnalyticsData>
		);
	}
}

export default CardContextMenu;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TriggerWrapper = styled2.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		height: 0,
	},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const ActionsSectionControl = styled.div<{ y: any; x: any }>((props) => ({
	position: 'absolute',
	zIndex: 400,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `${props.y}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	left: `${props.x}px`,
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionsSectionExperiment = styled2.div<{ y: number; x: number }>({
	position: 'absolute',
	zIndex: 400,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: (props) => `${props.y}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	left: (props) => `${props.x}px`,
});

const ActionsSection = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	ActionsSectionExperiment,
	ActionsSectionControl,
);
