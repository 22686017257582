import { useEffect, useState } from 'react';
import { EXPERIMENT } from '@atlassian/jira-common-util-get-experiment-group';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { useQueryParam } from '@atlassian/react-resource-router';
import { useOperationalExposureEvent } from '../analytics';
import { useCheckExperimentEligibility } from '../check-experiment-eligibility';
import { useAutoOpenTimestampActions, useAutoOpenTimestampState } from './store';

export function useIsEligibleAutoOpenInsightsPanel() {
	const [selectedIssue] = useQueryParam('selectedIssue');
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [_, fireExposure] = UNSAFE_noExposureExp('jsw_presentation_toolkit_experiment');
	const { isEligible, cohort } = useCheckExperimentEligibility(false);
	const [isEligibleToOpen, setIsEligibleToOpen] = useState(false);
	const { hasBeenFourteenDays } = useAutoOpenTimestampState();
	const { updateAutoOpenTimestamp } = useAutoOpenTimestampActions();
	const { fireOperationalExposureEvent } = useOperationalExposureEvent();

	useEffect(() => {
		if (!isEligible || selectedIssue || !hasBeenFourteenDays) {
			setIsEligibleToOpen(false);
			return;
		}

		fireExposure();
		fireOperationalExposureEvent();

		if (cohort !== EXPERIMENT) {
			// update timestamp for control. The "experiment" cohort will be handled when the insights panel is auto-toggled
			updateAutoOpenTimestamp();
			setIsEligibleToOpen(false);
			return;
		}

		setIsEligibleToOpen(true);
	}, [
		isEligible,
		isEligibleToOpen,
		hasBeenFourteenDays,
		setIsEligibleToOpen,
		cohort,
		fireExposure,
		selectedIssue,
		updateAutoOpenTimestamp,
		fireOperationalExposureEvent,
	]);

	return isEligibleToOpen;
}
