import 'rxjs/add/operator/map';
import qs from 'query-string';
import type { Observable as ObservableType } from 'rxjs/Observable';
import type { ProjectType } from '@atlassian/jira-common-constants';
import {
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { MobileRestApiResponse } from '@atlassian/jira-software-board-uif-types';
import { refreshUIFBoardData } from '@atlassian/jira-software-uif-early-script';
import type { WorkData } from '../../../../model/work/work-types';
import { transformMobileResponse } from './transformer';

interface FetchCMPBoardScopeDataParams {
	activeQuickFilters?: string[];
	activeSprints?: string[];
	projectType?: ProjectType;
	includeHidden?: boolean;
	skipExtraFields?: boolean;
}

export function fetchCMPBoardScopeData(
	boardId: string,
	{
		activeQuickFilters,
		activeSprints,
		includeHidden,
		projectType = SOFTWARE_PROJECT,
		skipExtraFields,
	}: FetchCMPBoardScopeDataParams = {},
): ObservableType<WorkData> {
	const query: {
		moduleKey: string;
		hideCardExtraFields: boolean;
		onlyUseEpicsFromIssues: boolean;
		skipEtag: boolean;
		activeQuickFilters?: string;
		activeSprints?: string[];
		includeHidden?: boolean;
		skipExtraFields?: boolean;
		fields?: string[];
		operation?: string;
	} = {
		moduleKey: fg('cmp_board_use_agile-web-board-service_module')
			? 'agile-web-board-service'
			: 'agile-mobile-board-service',
		hideCardExtraFields: true,
		onlyUseEpicsFromIssues: true,
		includeHidden,
		skipExtraFields,
		skipEtag: true,
		fields: projectType === SERVICE_DESK_PROJECT ? ['request_type', 'status'] : undefined,
		operation: 'fetchBoardData',
	};

	if (activeQuickFilters && activeQuickFilters.length) {
		// When you pass an array, we convert the query string into:
		// ? activeQuickFilters=1&activeQuickFilters=21
		// But unfortunately the monolith endpoint only supports CSV for this query parameter.
		query.activeQuickFilters = activeQuickFilters.join(',');
	}
	if (activeSprints && activeSprints.length) {
		query.activeSprints = activeSprints;
	}

	const queryStr = qs.stringify(query);

	return fetchJson$(`/rest/boards/latest/board/${boardId}?${queryStr}`).map((json) => {
		// Refresh the board cache if we are fetching all issues (eg, no filters applied)
		if (includeHidden || !(query.activeQuickFilters || query.activeSprints)) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			refreshUIFBoardData(boardId, json as MobileRestApiResponse);
		}

		return transformMobileResponse(json);
	});
}
