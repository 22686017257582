import React from 'react';
import { styled } from '@compiled/react';
import LinkExternalIcon from '@atlaskit/icon/core/link-external';
import ShortcutIconOld from '@atlaskit/icon/glyph/shortcut';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { useBoardSelector } from '../../../../../../../../state';
import { selectedIssueKeysSelector } from '../../../../../../../../state/selectors/card/card-selectors';
import { BULK_CHANGE_MENU_ID } from '../../constants';
import messages from '../../messages';
import type { ContentProps, Props } from './types';

export const useBulkChangeContent = ({
	onBulkChange,
	isBulkChangeVisible,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	if (!isBulkChangeVisible) return [];
	return [
		{
			type: MenuNodeType.Item,
			label: () => (
				<LabelWrapper>
					{formatMessage(messages.bulkChange)}
					<IconWrapper>
						{fg('enghealth-12479-jsw-board-visual-refresh') || true ? (
							<LinkExternalIcon
								LEGACY_size="small"
								LEGACY_fallbackIcon={ShortcutIconOld}
								LEGACY_primaryColor={token('color.icon', colors.N800)}
								label={formatMessage(messages.bulkChangeIconLabel)}
								color={token('color.icon', colors.N800)}
							/>
						) : (
							<ShortcutIconOld
								size="small"
								label={formatMessage(messages.bulkChangeIconLabel)}
								primaryColor={token('color.icon', colors.N800)}
							/>
						)}
					</IconWrapper>
				</LabelWrapper>
			),
			id: BULK_CHANGE_MENU_ID,
			onClick: onBulkChange,
		},
	];
};

export const useBulkChange = ({ isMultiSelectMode }: Props) => {
	const selectedIssueKeys = useBoardSelector(selectedIssueKeysSelector);

	const onBulkChange = () => {
		const url = `/secure/GHBulkChange.jspa?issueKeys=${encodeURIComponent(
			selectedIssueKeys.join(','),
		)}`;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(url, '_blank');
	};

	return useBulkChangeContent({
		onBulkChange,
		isBulkChangeVisible: isMultiSelectMode,
	});
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.span({
	position: 'relative',
	top: token('space.025', '2px'),
});
