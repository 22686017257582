import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';
import type { ColumnId } from '../../../../model/column/column-types';
import type { OptimisticId } from '../../../../model/software/software-types';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiWithFlagMeta,
	type OptimisticUiMeta,
} from '../../meta/optimistic-ui-meta';

export const COLUMN_OPEN_LIMITS_MODAL =
	'state.actions.column.limits.COLUMN_OPEN_LIMITS_MODAL' as const;

export type ColumnOpenLimitsModalAction = {
	type: typeof COLUMN_OPEN_LIMITS_MODAL;
	payload: {
		columnId: ColumnId;
	};
};

export const columnOpenLimitsModal = (columnId: ColumnId): ColumnOpenLimitsModalAction => ({
	type: COLUMN_OPEN_LIMITS_MODAL,
	payload: {
		columnId,
	},
});

export const COLUMN_CLOSE_LIMITS_MODAL =
	'state.actions.column.limits.COLUMN_CLOSE_LIMITS_MODAL' as const;

export type ColumnCloseLimitsModalAction = {
	type: typeof COLUMN_CLOSE_LIMITS_MODAL;
	payload: {
		columnId: ColumnId;
	};
};

export const columnCloseLimitsModal = (columnId: ColumnId): ColumnCloseLimitsModalAction => ({
	type: COLUMN_CLOSE_LIMITS_MODAL,
	payload: {
		columnId,
	},
});

export const COLUMN_SET_LIMITS_REQUEST =
	'state.actions.column.limits.COLUMN_SET_LIMITS_REQUEST' as const;

export type ColumnSetLimitsRequestAction = {
	type: typeof COLUMN_SET_LIMITS_REQUEST;
	payload: {
		columnId: number;
		maxIssueCount: number | null;
		minIssueCount: number | null;
	};
	meta: {
		analyticsEvent?: AnalyticsEvent;
	} & OptimisticUiMeta;
};

export const columnSetLimitsRequest = (
	columnId: number,
	maxIssueCount: number | null,
	minIssueCount: number | null,
	analyticsEvent?: AnalyticsEvent,
): ColumnSetLimitsRequestAction => ({
	type: COLUMN_SET_LIMITS_REQUEST,
	payload: {
		columnId,
		maxIssueCount,
		minIssueCount,
	},
	meta: {
		...beginOptimisticUiMeta(),
		analyticsEvent,
	},
});

export const COLUMN_SET_LIMITS_SUCCESS =
	'state.actions.column.limits.COLUMN_SET_LIMITS_SUCCESS' as const;

export type ColumnSetLimitsSuccessAction = {
	type: typeof COLUMN_SET_LIMITS_SUCCESS;
	payload: {
		columnId: ColumnId;
	};
	meta: OptimisticUiMeta;
};

export const columnSetLimitsSuccess = (
	optimisticId: OptimisticId,
	columnId: ColumnId,
): ColumnSetLimitsSuccessAction => ({
	type: COLUMN_SET_LIMITS_SUCCESS,
	payload: {
		columnId,
	},
	meta: commitOptimisticUiMeta(optimisticId),
});

export const COLUMN_SET_LIMITS_FAILURE =
	'state.actions.column.limits.COLUMN_SET_LIMITS_FAILURE' as const;

export type ColumnSetLimitsFailureAction = {
	type: typeof COLUMN_SET_LIMITS_FAILURE;
	payload: {
		columnId: ColumnId;
	};
	meta: OptimisticUiMeta;
};

export const columnSetLimitsFailure = (
	optimisticId: OptimisticId,
	columnId: ColumnId,
): ColumnSetLimitsFailureAction => ({
	type: COLUMN_SET_LIMITS_FAILURE,
	payload: {
		columnId,
	},
	meta: revertOptimisticUiWithFlagMeta(optimisticId),
});

export type Action =
	| ColumnOpenLimitsModalAction
	| ColumnCloseLimitsModalAction
	| ColumnSetLimitsRequestAction
	| ColumnSetLimitsSuccessAction
	| ColumnSetLimitsFailureAction;
