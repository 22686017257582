import type { IssueId } from '@atlassian/jira-software-board-common';
import type { RouterActionsType } from '@atlassian/react-resource-router';

export const BACKLOG_ENABLE_CANCEL =
	'state.actions.board.backlog-enable.BACKLOG_ENABLE_CANCEL' as const;

export type BacklogEnableCancelAction = {
	type: typeof BACKLOG_ENABLE_CANCEL;
};

export const backlogEnableCancel = (): BacklogEnableCancelAction => ({
	type: BACKLOG_ENABLE_CANCEL,
});

export const BACKLOG_ENABLE_CONFIRM =
	'state.actions.board.backlog-enable.BACKLOG_ENABLE_CONFIRM' as const;

export type BacklogEnableConfirmAction = {
	type: typeof BACKLOG_ENABLE_CONFIRM;
	payload: {
		push: RouterActionsType['push'];
		refresh: () => void;
	};
};

export const backlogEnableConfirm = (
	push: RouterActionsType['push'],
	refresh: () => void,
): BacklogEnableConfirmAction => ({
	type: BACKLOG_ENABLE_CONFIRM,
	payload: {
		push,
		refresh,
	},
});

export const BACKLOG_ENABLE_EXECUTE =
	'state.actions.board.backlog-enable.BACKLOG_ENABLE_EXECUTE' as const;

export type BacklogEnableExecuteAction = {
	type: typeof BACKLOG_ENABLE_EXECUTE;
	payload: {
		issueIds: IssueId[];
		push: RouterActionsType['push'];
		refresh: () => void;
	};
};

export const backlogEnableExecute = (
	issueIds: IssueId[],
	push: RouterActionsType['push'],
	refresh: () => void,
): BacklogEnableExecuteAction => ({
	type: BACKLOG_ENABLE_EXECUTE,
	payload: {
		issueIds,
		push,
		refresh,
	},
});

export const BACKLOG_ENABLE_EXECUTE_SUCCESS =
	'state.actions.board.backlog-enable.BACKLOG_ENABLE_EXECUTE_SUCCESS' as const;

export type BacklogEnableExecuteSuccessAction = {
	type: typeof BACKLOG_ENABLE_EXECUTE_SUCCESS;
	payload: {
		push: RouterActionsType['push'];
		refresh: () => void;
	};
};

export const backlogEnableExecuteSuccess = (
	push: RouterActionsType['push'],
	refresh: () => void,
): BacklogEnableExecuteSuccessAction => ({
	type: BACKLOG_ENABLE_EXECUTE_SUCCESS,
	payload: {
		push,
		refresh,
	},
});

export const BACKLOG_ENABLE_EXECUTE_FAILURE =
	'state.actions.board.backlog-enable.BACKLOG_ENABLE_EXECUTE_FAILURE' as const;

export type BacklogEnableExecuteFailureAction = {
	type: typeof BACKLOG_ENABLE_EXECUTE_FAILURE;
};

export const backlogEnableExecuteFailure = (): BacklogEnableExecuteFailureAction => ({
	type: BACKLOG_ENABLE_EXECUTE_FAILURE,
});

export type Action =
	| BacklogEnableCancelAction
	| BacklogEnableConfirmAction
	| BacklogEnableExecuteAction
	| BacklogEnableExecuteSuccessAction
	| BacklogEnableExecuteFailureAction;
