import React, { useMemo } from 'react';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container';
import type { EntryPointActions } from '@atlassian/jira-entry-point/src/controllers/utils/types.tsx';
import type { EntryPointReferenceSubject } from '@atlassian/jira-entry-point/src/controllers/utils/use-entry-point-load-manager/index.tsx';
import { createAri } from '@atlassian/jira-platform-ari';
import type { editSprintModalEntryPoint } from '@atlassian/jira-software-edit-sprint-modal-relay/entrypoint';
import type { OnSubmit } from '@atlassian/jira-software-edit-sprint-modal-relay/src/common/types.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsCMPBoard } from '../../../../state/state-hooks/capabilities';

export interface EditSprintModalEntryPointProps {
	boardId: number;
	onClose: () => void;
	onSubmit: OnSubmit;
	entryPointActions: EntryPointActions;
	entryPointReferenceSubject: EntryPointReferenceSubject<typeof editSprintModalEntryPoint>;
	onReady?: () => void;
}

export const EditSprintModalEntryPoint = ({
	boardId,
	onClose,
	onSubmit,
	entryPointActions,
	entryPointReferenceSubject,
	onReady,
}: EditSprintModalEntryPointProps) => {
	const cloudId = useCloudId();
	const isCompanyManaged = useIsCMPBoard();

	const boardAri = createAri({
		resourceOwner: 'jira-software',
		cloudId,
		resourceType: 'board',
		resourceId: String(boardId),
	});

	const props = useMemo(
		() => ({
			boardAri,
			onClose,
			onSubmit,
			fetchSprints: true,
			isCompanyManaged,
			...(onReady
				? {
						onReady,
					}
				: undefined),
		}),
		[boardAri, onClose, onSubmit, onReady, isCompanyManaged],
	);

	return (
		<ModalEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="edit-sprint-modal"
			teamName="a4t-pandora"
			packageName="jiraSoftwareEditSprintModalRelay"
			entryPointActions={entryPointActions}
			runtimeProps={props}
		/>
	);
};
