import memoizeOne from 'memoize-one';
import { connect } from '@atlassian/jira-react-redux';
import { CAN_EDIT_BOARD } from '../../../../../../../model/permission/permission-types';
import { columnOpenLimitsModal } from '../../../../../../../state/actions/column/limits';
import { getPermissionsSelector } from '../../../../../../../state/selectors/board/board-permissions-selectors';
import { getIsCMPBoard } from '../../../../../../../state/selectors/software/software-selectors';
import {
	boardColumnConstraintTypeSelector,
	isInlineColumnEditEnabled,
} from '../../../../../../../state/selectors/work/work-selectors';
import type { Dispatch, State } from '../../../../../../../state/types';
import ItemLimits from './view';

type OwnProps = {
	isDisabled: boolean;
	columnId: number;
	onClick: () => void;
};

export const mapStateToProps = (state: State, props: OwnProps) => ({
	isDisabled:
		props.isDisabled ||
		!getPermissionsSelector(state)[CAN_EDIT_BOARD] ||
		!isInlineColumnEditEnabled(state) ||
		(getIsCMPBoard(state) && !boardColumnConstraintTypeSelector(state)),
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
	dispatch,
});

const onClickFactory = memoizeOne(
	(dispatch: Dispatch, columnId: number, onClick: () => void) => () => {
		dispatch(columnOpenLimitsModal(columnId));
		onClick();
	},
);

export const mergeProps = (
	stateProps: ReturnType<typeof mapStateToProps>,
	dispatchProps: ReturnType<typeof mapDispatchToProps>,
	ownProps: OwnProps,
) => {
	const { columnId, onClick } = ownProps;
	const { dispatch } = dispatchProps;
	const { isDisabled } = stateProps;

	return {
		isDisabled,
		onClick: onClickFactory(dispatch, columnId, onClick),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export default connector(ItemLimits);
