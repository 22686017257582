import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	issueLinksButton: {
		id: 'issue-links-stats.issue-links-stats-button.issue-links-details-popup-entrypoint-container.issue-links-button',
		defaultMessage: '{issueLinkCount} linked {issueLinkCount, plural, one {issue} other {issues}}',
		description: 'Label for linked issues button',
	},
	issueLinksPopup: {
		id: 'issue-links-stats.issue-links-stats-button.issue-links-details-popup-entrypoint-container.issue-links-popup',
		defaultMessage: 'Linked issues pop up',
		description: 'Label for linked issues popup',
	},
});
