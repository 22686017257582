import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { transformSprintActionsResponse } from '../common/utils/transformer';
import {
	fetchCMPExtensionData,
	fetchCMPSprintActionsData,
	type FetchCMPSprintActionsParams,
} from '../services/load-extension-data';
import type { State } from './types';

export type FetchExtensionDataParams = {
	boardId: string;
	projectKey: string;
	mode?: 'work' | 'plan';
};

export const fetchExtensionData =
	({ boardId, projectKey, mode = 'work' }: FetchExtensionDataParams): Action<State> =>
	async ({ getState, setState }) => {
		const key = JSON.stringify([projectKey, boardId, mode]);
		const state = getState();

		if (state.key === key && state.isLoading) {
			return;
		}

		if (state.key !== key) {
			setState({ toolSections: null, isLoading: true, key });
		} else {
			setState({ isLoading: true });
		}
		try {
			const response = await fetchCMPExtensionData({ boardId, projectKey, mode });
			setState({ toolSections: response.sections, key, isLoading: false });
		} catch (error) {
			log.safeErrorWithoutCustomerData(
				'software.extension.board-tools',
				error instanceof Error ? error.message : String(error),
				error instanceof Error ? error : undefined,
			);
			setState({ isLoading: false });
		}
	};

export const fetchSprintActionsData =
	({ sprintId }: FetchCMPSprintActionsParams): Action<State> =>
	async ({ getState, setState }) => {
		const { sprintActions } = getState();

		const findActions = sprintActions?.[sprintId] || { isLoading: false, hasLoaded: false };
		if (findActions.isLoading || findActions.hasLoaded) {
			return;
		}
		// set loading to true to prevent multiple requests
		setState({
			sprintActions: {
				...sprintActions,
				[sprintId]: { isLoading: true, hasLoaded: false },
			},
		});

		try {
			const response = await fetchCMPSprintActionsData({ sprintId });
			const actionsForSprint = transformSprintActionsResponse(response);
			setState({
				sprintActions: {
					...getState().sprintActions,
					[sprintId]: { ...actionsForSprint, isLoading: false, hasLoaded: true },
				},
			});
		} catch (error) {
			log.safeErrorWithoutCustomerData(
				'software.extension.sprint-actions',
				error instanceof Error ? error.message : String(error),
				error instanceof Error ? error : undefined,
			);
			setState({
				sprintActions: {
					...getState().sprintActions,
					[sprintId]: { isLoading: false, hasLoaded: false },
				},
			});
		}
	};
