import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import { Box, xcss } from '@atlaskit/primitives';
import errorImageUrl from '@atlassian/jira-common-assets/src/assets/error/something-wrong.svg';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';

export type Props = Intl;

export const BoardSizeLimitErrorWithNoCTA = ({ intl }: Props) => (
	<Box xcss={containerStyles}>
		<EmptyState
			imageUrl={errorImageUrl}
			header={intl.formatMessage(messages.header)}
			description={intl.formatMessage(messages.description)}
		/>
	</Box>
);

const containerStyles = xcss({
	width: '100%',
});
