import type { FilteredCardClearAction } from '../../card/filtered-cards';
import type { SetAllFiltersAction, SetFiltersAction } from '../../filter';

export const SELECT_SPRINTS = 'state.actions.sprints.select.SELECT_SPRINTS' as const;

export type SelectSprintAction = {
	type: typeof SELECT_SPRINTS;
	payload: {
		sprintFilterIds: string[];
	};
};

export const selectSprint = ({
	sprintFilterIds,
}: {
	sprintFilterIds: string[];
}): SelectSprintAction => ({
	type: SELECT_SPRINTS,
	payload: { sprintFilterIds },
});

export const CLEAR_SELECTED_SPRINTS =
	'state.actions.sprints.select.CLEAR_SELECTED_SPRINTS' as const;

export type ClearSelectedSprintAction = {
	type: typeof CLEAR_SELECTED_SPRINTS;
};

export type Action =
	| SelectSprintAction
	| ClearSelectedSprintAction
	| SetFiltersAction
	| SetAllFiltersAction
	| FilteredCardClearAction;
