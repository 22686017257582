// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, Children } from 'react';
import { styled as compiledStyled } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { css } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import Tooltip from '@atlaskit/tooltip';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import styles from '@atlassian/jira-software-board-css-module/src/card-compact.module.css';
import { CUSTOM_FIELD_LABELS } from '../../../common/constants';
import { CardAvatar } from '../../../common/ui/avatar';
import { CardColor } from '../../../common/ui/color';
import { CardCover } from '../../../common/ui/cover';
import { CardCustomFields } from '../../../common/ui/custom-fields';
import { CardFlag } from '../../../common/ui/flag';
import { CardIcons } from '../../../common/ui/icons';
import { CardKey } from '../../../common/ui/key';
import { CardSpinner } from '../../../common/ui/spinner';
import { CardSummary } from '../../../common/ui/summary';
import { CardTick } from '../../../common/ui/tick';
import { CardType } from '../../../common/ui/type';
import messages from '../messages';
import type { Props } from './types';

const CONTENT_SECTION_SELECTOR = 'platform-card.ui.card.card-content.content-section';

// eslint-disable-next-line jira/react/no-class-components
export class CardContent extends PureComponent<Props> {
	static defaultProps = {
		isDone: false,
		isFlagged: false,
		isLoading: false,
		shouldMenuRender: false,
		typeName: null,
		typeUri: null,
		cardKey: null,
		coverUri: null,
		cover: null,
		avatarName: null,
		avatarUri: null,
		icons: null,
		highlightText: [],
		customFields: null,
		children: null,
		onImageLoad: noop,
		isCMPBoard: false,
		isIPBoard: false,
		status: null,
		hasCompactIssueType: false,
		color: null,
		hasScenarioChanges: false,
	};

	shouldRenderFooter() {
		const {
			isFlagged,
			isDone,
			typeName,
			typeUri,
			cardKey,
			icons,
			avatarName,
			avatarUri,
			isLoading,
			renderAssignee,
		} = this.props;

		if (fg('jira_view_settings_additional_fields')) {
			return [
				isFlagged,
				typeName,
				typeUri,
				cardKey,
				avatarName,
				avatarUri,
				isLoading,
				renderAssignee,
				!isEmpty(icons) && icons.some((icon) => !!icon),
			].some((component) => !!component);
		}

		return [
			isFlagged,
			isDone,
			typeName,
			typeUri,
			cardKey,
			!isEmpty(icons),
			avatarName,
			avatarUri,
			isLoading,
		].some((component) => !!component);
	}

	renderCover() {
		const { coverUri, cover, onImageLoad } = this.props;
		if (coverUri) {
			return coverUri && <CardCover onLoad={onImageLoad} uri={coverUri} />;
		}
		return cover;
	}

	renderSummary() {
		if (this.props.renderSummary) {
			const {
				summary: text,
				highlightText: highlight,
				shouldMenuRender,
				renderSummary,
			} = this.props;
			if (fg('fix_jira_board_card_nested_interactive_elements')) {
				return (
					<Box xcss={contentSectionStyles} data-component-selector={CONTENT_SECTION_SELECTOR}>
						{renderSummary({ text, highlight, shouldMenuRender })}
					</Box>
				);
			}

			return (
				<ContentSection>{renderSummary({ text, highlight, shouldMenuRender })}</ContentSection>
			);
		}
		const { summary, highlightText, shouldMenuRender, isCMPBoard } = this.props;

		if (fg('fix_jira_board_card_nested_interactive_elements')) {
			return (
				<Box xcss={contentSectionStyles} data-component-selector={CONTENT_SECTION_SELECTOR}>
					<CardSummary
						text={summary}
						highlight={highlightText}
						shouldMenuRender={shouldMenuRender}
						shouldTruncate={Boolean(isCMPBoard)}
					/>
				</Box>
			);
		}

		return (
			<ContentSection>
				<CardSummary
					text={summary}
					highlight={highlightText}
					shouldMenuRender={shouldMenuRender}
					shouldTruncate={Boolean(isCMPBoard)}
				/>
			</ContentSection>
		);
	}

	renderFields() {
		const { children } = this.props;

		if (
			!isEmpty(Children.toArray(children)) &&
			fg('fix_jira_board_card_nested_interactive_elements')
		) {
			return (
				<Box xcss={contentSectionStyles} data-component-selector={CONTENT_SECTION_SELECTOR}>
					<FieldsSection>{children}</FieldsSection>
				</Box>
			);
		}

		return (
			!isEmpty(Children.toArray(children)) && (
				<ContentSection>
					<FieldsSection>{children}</FieldsSection>
				</ContentSection>
			)
		);
	}

	renderCustomFields() {
		const { customFields, isCMPBoard, fixVersions } = this.props;
		// TMP labels are rendred directly from issues.lables in a fixed card position
		// CMP labels are reorderable in user settings and therefore need to come from custom fields

		const customFieldsToDisplay = isCMPBoard
			? customFields
			: customFields?.filter((cf) => cf.id !== CUSTOM_FIELD_LABELS);

		if (fg('fix_jira_board_card_nested_interactive_elements')) {
			return (
				<>
					{!isEmpty(customFieldsToDisplay) && (
						<Box xcss={contentSectionStyles} data-component-selector={CONTENT_SECTION_SELECTOR}>
							<CardCustomFields
								fields={customFieldsToDisplay}
								isCMPBoard={isCMPBoard}
								fixVersions={fixVersions}
							/>
						</Box>
					)}
				</>
			);
		}

		return (
			<>
				{!isEmpty(customFieldsToDisplay) && (
					<ContentSection>
						<CardCustomFields
							fields={customFieldsToDisplay}
							isCMPBoard={isCMPBoard}
							fixVersions={fixVersions}
						/>
					</ContentSection>
				)}
			</>
		);
	}

	renderType() {
		const { typeName, typeUri } = this.props;
		return typeName && typeUri && <CardType text={typeName} uri={typeUri} />;
	}

	renderKey() {
		const { cardKey, highlightText, isDone, isIPBoard, onClick } = this.props;
		return (
			cardKey && (
				<CardKey
					text={cardKey}
					highlight={highlightText}
					isDone={Boolean(isDone)}
					onClick={onClick}
					openInNewTab={isIPBoard}
				/>
			)
		);
	}

	renderIcons() {
		const { isDone, isFlagged, icons, isLoading, isCMPBoard, isIPBoard } = this.props;
		const hasIcons = icons?.some((icon) => !!icon);
		return (
			!isLoading &&
			(isDone || isFlagged || hasIcons) && (
				<CardIcons>
					{!isCMPBoard && !isIPBoard && !isFlagged && isDone && <CardTick key="card-ticked" />}
					{isFlagged && <CardFlag key="card-flagged" />}
					{icons}
				</CardIcons>
			)
		);
	}

	renderAvatar() {
		if (this.props.renderAssignee) {
			return this.props.renderAssignee();
		}

		const { avatarName, avatarUri, highlightText } = this.props;
		return (
			avatarName &&
			avatarUri && <CardAvatar name={avatarName} uri={avatarUri} highlight={highlightText} />
		);
	}

	renderLoading() {
		const { isLoading } = this.props;
		return isLoading && <CardSpinner />;
	}

	renderIssueLinksStats() {
		return this.props.renderIssueLinksStats?.();
	}

	renderCardColor() {
		const { color, hasScenarioChanges, isIPBoard } = this.props;
		/**
		 * In increment planning boards, we render the card color only if the issue:
		 * * is a new issue created in Plans that doesn't yet exist in Jira
		 * * has scenario changes not yet saved to Jira
		 *
		 * The card color is wrapped with a tooltip to inform the user about the fact that
		 * changes only exist in Plans and need to be saved to Jira to be reflected there.
		 */
		if (!isIPBoard) {
			return <CardColor color={color} />;
		}

		if (hasScenarioChanges) {
			return (
				<Tooltip content={<FormattedMessage {...messages.scenarioChangeTooltip} />}>
					<CardColor
						testId="platform-card.ui.card.card-content.program-board-scenario-indicator"
						color={color}
					/>
				</Tooltip>
			);
		}
		return null;
	}

	renderFooter() {
		// When adding new items into the footer, remember to update the `shouldRenderFooter()`
		if (!this.shouldRenderFooter()) return null;

		const { hasCompactIssueType, isIPBoard } = this.props;

		if (fg('fix_jira_board_card_nested_interactive_elements')) {
			return (
				<Box
					testId="platform-card.ui.card.card-content.footer"
					data-component-selector={CONTENT_SECTION_SELECTOR}
					xcss={contentSectionStyles}
				>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<FooterSection className={styles.footer}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<FooterPrimarySection className={styles.footerChildSection}>
							{!hasCompactIssueType && this.renderType()}
							{this.renderKey()}
						</FooterPrimarySection>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<FooterTertiarySection className={styles.footerChildSection}>
							{this.renderIssueLinksStats()}
							{Boolean(hasCompactIssueType) && this.renderType()}
							{!isIPBoard && this.renderIcons()}
						</FooterTertiarySection>
						<FooterSecondarySection>
							{!isIPBoard && this.renderAvatar()}
							{isIPBoard && this.renderIcons()}
							{this.renderLoading()}
						</FooterSecondarySection>
					</FooterSection>
				</Box>
			);
		}

		return (
			<ContentSection data-testid="platform-card.ui.card.card-content.footer">
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<FooterSection className={styles.footer}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<FooterPrimarySection className={styles.footerChildSection}>
						{!hasCompactIssueType && this.renderType()}
						{this.renderKey()}
					</FooterPrimarySection>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<FooterTertiarySection className={styles.footerChildSection}>
						{this.renderIssueLinksStats()}
						{Boolean(hasCompactIssueType) && this.renderType()}
						{!isIPBoard && this.renderIcons()}
					</FooterTertiarySection>
					<FooterSecondarySection>
						{!isIPBoard && this.renderAvatar()}
						{isIPBoard && this.renderIcons()}
						{this.renderLoading()}
					</FooterSecondarySection>
				</FooterSection>
			</ContentSection>
		);
	}

	render() {
		const isCMPBoard = Boolean(this.props.isCMPBoard);

		const cardContent = fg('fix_jira_board_card_nested_interactive_elements') ? (
			<Box xcss={containerStyles}>
				{this.renderCover()}
				<Content
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.content}
					isCMPBoard={isCMPBoard}
					hasStatus={this.props.status !== null}
				>
					{this.renderSummary()}
					{this.renderFields()}
					{this.renderCustomFields()}
					{this.renderCardColor()}
					{this.renderFooter()}
				</Content>
			</Box>
		) : (
			<>
				{this.renderCover()}
				<Content
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.content}
					isCMPBoard={isCMPBoard}
					hasStatus={this.props.status !== null}
				>
					{this.renderSummary()}
					{this.renderFields()}
					{this.renderCustomFields()}
					{this.renderCardColor()}
					{this.renderFooter()}
				</Content>
			</>
		);

		return isCMPBoard ? <Box xcss={contentContainerStyles}>{cardContent}</Box> : cardContent;
	}
}

export default CardContent;

// eslint-disable-next-line jira/styled/styled-component-order
const contentContainerStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	container: `${styles['card-content-container']} / inline-size`,
});

const gutterSize = gridSize / 2;
const footerMinHeight = 28;

const gridareas = () => {
	if (styles.primary && styles.tertiary && styles.secondary)
		return `"${styles.primary} ${styles.tertiary} ${styles.secondary}"`;
	return '"primary tertiary secondary"';
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentSection = styled.div({
	display: 'flex',
	flexDirection: 'row',
	width: '100%',
});

const contentSectionStyles = xcss({
	display: 'flex',
	flexDirection: 'row',
	width: '100%',
	position: 'relative',
	pointerEvents: 'auto',
});

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentControl = styled.div<{ isCMPBoard: boolean; hasStatus?: boolean }>`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding: ${token('space.150', '12px')};
	${/* sc-selector */ ContentSection} {
		margin-bottom: ${token('space.100', '8px')};
		&:last-child {
			margin-bottom: 0;
		}
		${(props) =>
			!props.isCMPBoard &&
			`&:nth-of-type(2):last-of-type {
            margin-top: ${token('space.050', '4px')};
        }`}
		${(props) =>
			props.hasStatus &&
			`&:nth-child(2) {
                margin-bottom: ${token('space.050', '4px')};
            }
            &:nth-last-child(2) {
                margin-bottom: ${token('space.100', '8px')};
            }
        `}
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ContentExperiment = compiledStyled.div<{ isCMPBoard: boolean; hasStatus?: boolean }>({
	boxSizing: 'border-box',
	display: 'flex',
	flexDirection: 'column',
	padding: token('space.150', '12px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values
	[`[data-component-selector="${CONTENT_SECTION_SELECTOR}"]`]: {
		marginBottom: token('space.100', '8px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&:last-child': {
			marginBottom: 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&:nth-of-type(2):last-of-type': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
			marginTop: ({ isCMPBoard }) => (!isCMPBoard ? token('space.050', '4px') : null),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&:nth-child(2)': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
			marginBottom: ({ hasStatus }) => (hasStatus ? token('space.050', '4px') : null),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&:nth-last-child(2)': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
			marginBottom: ({ hasStatus }) => (hasStatus ? token('space.100', '8px') : null),
		},
	},
});

const Content = styledComponentWithCondition(
	() => fg('fix_jira_board_card_nested_interactive_elements'),
	ContentExperiment,
	ContentControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldsSection = styled.div({
	display: 'flex',
	flexFlow: 'row wrap',
	alignItems: 'center',
	gap: token('space.100', '8px'),
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterSection = compiledStyled.div({
	display: 'grid',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gap: `${gutterSize * 2}px ${gutterSize}px`,
	minHeight: `${footerMinHeight}px`,
	gridTemplateRows: '1fr',
	gridTemplateColumns: 'minmax(0, 1fr) auto auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gridTemplateAreas: gridareas(),
});

const footerSectionMixinStyles = css({
	boxSizing: 'border-box',
	display: 'grid',
	gridAutoFlow: 'column',
	alignItems: 'center',
	justifyContent: 'start',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterPrimarySection = compiledStyled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gridArea: styles.primary ? styles.primary : 'primary',
	maxWidth: 'fit-content',
	boxSizing: 'border-box',
	display: 'grid',
	gridAutoFlow: 'column',
	alignItems: 'center',
	justifyContent: 'start',
	gap: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterSecondarySection = styled.div(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		gridArea: styles.secondary ? styles.secondary : 'secondary',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	() => footerSectionMixinStyles,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterTertiarySection = styled.div(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		gridArea: styles.tertiary ? styles.tertiary : 'tertiary',
		minHeight: '24px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	() => footerSectionMixinStyles,
);

const containerStyles = xcss({
	pointerEvents: 'none',
});
