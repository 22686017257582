import 'rxjs/add/observable/of';
import 'rxjs/add/observable/zip';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import isNil from 'lodash/isNil';
import partition from 'lodash/partition';
import { Observable, type ObservableInput } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { PARENT_HIERARCHY_TYPE } from '@atlassian/jira-issue-type-hierarchies';
import { SWIMLANE_TEAMLESS } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import { isUnscheduledColumn, getDateRangeColumn } from '../../../common/utils/column';
import {
	transformDateValue,
	transformDateRangeUpdateValues,
} from '../../../common/utils/increment-planning';
import type {
	UpdateValue,
	UpdateValues,
} from '../../../model/issue/issue-increment-planning-types';
import { issueRankJPOService } from '../../../services/issue/issue-update-service';
import { issueBulkUpdateRequest } from '../../../state/actions/issue/bulk-update';
import {
	ISSUE_RANK_TEAM_DATE_REQUEST,
	type IssueRankTeamDateRequestAction,
	issueTeamDateRankFailure,
	issueTeamDateRankSuccess,
} from '../../../state/actions/issue/rank-team-date';
import { getColumnById } from '../../../state/selectors/column/column-selectors';
import {
	getIncrementConfig,
	getIssues,
	getIssueTypeInIPboard,
} from '../../../state/selectors/software/software-selectors';
import { getTeamSprintsForIterations } from '../../../state/selectors/team/team-selectors';
import type {
	Action,
	ActionsObservable,
	MiddlewareAPI,
	State,
	BoardDependencies,
} from '../../../state/types';

const error = (err?: Error) => {
	log.safeErrorWithoutCustomerData(
		'team.date.and.rank.failure',
		'Failed to update IP board issue',
		err,
	);
	return Observable.of(issueTeamDateRankFailure());
};

const success = () => Observable.of(issueTeamDateRankSuccess());

function doRank(
	state: State,
	{ payload: { issueIds, rankBeforeIssueId, rankAfterIssueId } }: IssueRankTeamDateRequestAction,
	{ customRequestHandlers }: BoardDependencies,
): Observable<Action> {
	if (!customRequestHandlers || !customRequestHandlers.rankIssue) {
		return error(new Error('no request handler found for ranking issues'));
	}

	return issueRankJPOService({
		issueIds,
		rankBeforeIssueId,
		rankAfterIssueId,
		requestHandler: customRequestHandlers.rankIssue,
	})
		.flatMap(() => success())
		.catch((err) => error(err));
}

function doSwimlaneAndColumnMove(
	state: State,
	action: IssueRankTeamDateRequestAction,
	dependencies: BoardDependencies,
): Observable<unknown> {
	const incrementConfig = getIncrementConfig(state);
	const { issueIds, destinationColumnId, destinationSwimlaneId } = action.payload;
	const teamSprintsForIterations = getTeamSprintsForIterations(state, destinationSwimlaneId);

	if (incrementConfig === null) {
		return error(new Error('incrementConfig cannot be null'));
	}
	const destinationColumn =
		!isNil(destinationColumnId) &&
		getDateRangeColumn(getColumnById(state, Number(destinationColumnId)));

	let sprint;

	// if we move the issue to a teamless swimlane (i.e. no sprints associated), we remove the sprint OR
	// if we move the issue to the unscheduled column, we clear the sprint OR
	// if the destination swimlane doesn't have sprints associated, we clear the sprint value
	if (
		destinationSwimlaneId === SWIMLANE_TEAMLESS ||
		(destinationColumn && isUnscheduledColumn(destinationColumn)) ||
		!teamSprintsForIterations
	) {
		sprint = null;
	}

	if (teamSprintsForIterations) {
		sprint = teamSprintsForIterations[destinationColumnId];
	}

	let dateRange = destinationColumn ? destinationColumn.dateRange : null;

	// If the destination column has a sprint associated and the sprint has dates set,
	// we use these dates for the dateRange instead of the iteration dates (for Epic level issues)
	if (
		teamSprintsForIterations !== null &&
		dateRange !== null &&
		sprint &&
		sprint.startDate !== null &&
		sprint.endDate !== null
	) {
		dateRange = {
			start: sprint.startDate,
			end: sprint.endDate,
		};
	}

	// Split issues into epics and stories
	const [epicIds, storyIds] = partition(issueIds, (issueId) => {
		const issue = getIssues(state)[issueId];
		const issueType = getIssueTypeInIPboard(state)(issue.projectId, `${issue.typeId}`);
		return !isNil(issueType) && issueType.hierarchyLevelType === PARENT_HIERARCHY_TYPE;
	});

	// Split epic that has existing start date and it's after the end of iteration (or sprint) end dates
	const [epicsWithClearStartDate, otherEpics] = partition(epicIds, (epicId) => {
		const epic = getIssues(state)[epicId];
		return (
			epic &&
			epic.startDate &&
			dateRange &&
			dateRange.end !== 0 &&
			new Date(epic.startDate) > new Date(dateRange.end)
		);
	});

	const requests$: Observable<unknown>[] = [];
	// Clear start date for epics that has existing start date and it's after the end of iteration (or sprint) end dates
	if (epicsWithClearStartDate.length > 0) {
		requests$.push(
			doSwimlaneAndColumnMoveWithStartAndEndDate(
				state,
				{
					type: action.type,
					payload: {
						...action.payload,
						issueIds: epicsWithClearStartDate,
					},
				},
				dependencies,
				transformDateValue(dateRange?.end),
				null,
			),
		);
	}

	// Keep start date for epics that doesn't have existing start date or if it's before the end of iteration dates
	if (otherEpics.length > 0) {
		requests$.push(
			doSwimlaneAndColumnMoveWithStartAndEndDate(
				state,
				{
					type: action.type,
					payload: {
						...action.payload,
						issueIds: otherEpics,
					},
				},
				dependencies,
				transformDateValue(dateRange?.end),
				undefined,
			),
		);
	}

	// Set iteration start date or sprint for stories
	if (storyIds.length > 0) {
		if (sprint) {
			requests$.push(
				doSwimlaneAndColumnMoveWithSprint(
					state,
					{
						type: action.type,
						payload: {
							...action.payload,
							issueIds: storyIds,
						},
					},
					dependencies,
					sprint.id,
				),
			);
		} else {
			requests$.push(
				doSwimlaneAndColumnMoveWithStartAndEndDate(
					state,
					{
						type: action.type,
						payload: {
							...action.payload,
							issueIds: storyIds,
						},
					},
					dependencies,
					transformDateValue(dateRange?.end),
					transformDateValue(dateRange?.start),
					sprint === null,
				),
			);
		}
	}

	return Observable.forkJoin(...requests$);
}

/* This function is called for Epic level issues, or Story level issues where the destination team column does not have a sprint association */
function doSwimlaneAndColumnMoveWithStartAndEndDate(
	state: State,
	{
		payload: {
			issueIds,
			sourceSwimlaneId,
			destinationSwimlaneId,
			sourceColumnId,
			destinationColumnId,
		},
	}: IssueRankTeamDateRequestAction,
	{ customRequestHandlers }: BoardDependencies,
	newEndDate: UpdateValue | null,
	newStartDate?: UpdateValue | null,
	shouldClearSprint?: boolean,
): Observable<unknown> {
	if (!customRequestHandlers || !customRequestHandlers.bulkUpdateIssues) {
		return error(new Error('no request handler found for swimlane and column move'));
	}

	const incrementConfig = getIncrementConfig(state);

	if (incrementConfig === null) {
		return error(new Error('incrementConfig cannot be null'));
	}

	const destinationColumn =
		!isNil(destinationColumnId) &&
		getDateRangeColumn(getColumnById(state, Number(destinationColumnId)));

	const destinationSwimlaneHasSprints =
		getTeamSprintsForIterations(state, destinationSwimlaneId) !== null;
	const sourceSwimlaneHasSprints = getTeamSprintsForIterations(state, sourceSwimlaneId) !== null;

	const values = (() => {
		let innerValues: UpdateValues = {};
		const startEndValues = transformDateRangeUpdateValues(
			incrementConfig,
			newStartDate,
			newEndDate,
		);
		const isColumnChange = destinationColumnId !== sourceColumnId;
		const isSwimlaneChange = destinationSwimlaneId && sourceSwimlaneId !== destinationSwimlaneId;
		const sprintSwimlaneToDateSwimlane = sourceSwimlaneHasSprints && !destinationSwimlaneHasSprints;

		// Regardless of column change, update start / end date when epics are moved to swimlanes with linked sprints
		// or when both epic and story-level issues are moved from sprint linked swimlanes to date range swimlanes
		if (sprintSwimlaneToDateSwimlane || destinationSwimlaneHasSprints || isColumnChange) {
			// Update start and end dates
			innerValues = startEndValues;

			// Clear sprints when moving to unscheduled column
			// or when epics moving to a column linked to sprints
			if (
				destinationColumn &&
				(isUnscheduledColumn(destinationColumn) || destinationSwimlaneHasSprints)
			) {
				innerValues.sprint = null;
			}
		}

		if (isSwimlaneChange) {
			// if moving an issue from sprint associated team/column to teamless swimlane or team w/out sprint assoc we assign iteration dates
			if (sprintSwimlaneToDateSwimlane) {
				// Update start and end dates
				innerValues = { ...innerValues, ...startEndValues };
			}

			innerValues.team = destinationSwimlaneId === SWIMLANE_TEAMLESS ? null : destinationSwimlaneId;
		}

		if (shouldClearSprint) {
			innerValues.sprint = null;
		}
		return innerValues;
	})();

	return ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1')
		? Observable.of(issueBulkUpdateRequest({ issueIds, ...values }))
		: Observable.of(
				customRequestHandlers.bulkUpdateIssues({
					issueIds,
					...values,
				}),
			);
}

/* This function is called for Story level issues where the destination team column has a sprint association */
function doSwimlaneAndColumnMoveWithSprint(
	state: State,
	{
		payload: {
			issueIds,
			sourceSwimlaneId,
			destinationSwimlaneId,
			sourceColumnId,
			destinationColumnId,
		},
	}: IssueRankTeamDateRequestAction,
	{ customRequestHandlers }: BoardDependencies,
	sprintId: number,
): Observable<unknown> {
	if (!customRequestHandlers || !customRequestHandlers.bulkUpdateIssues) {
		return error(new Error('no request handler found for swimlane and column move'));
	}

	const incrementConfig = getIncrementConfig(state);

	if (incrementConfig === null) {
		return error(new Error('incrementConfig cannot be null'));
	}

	const values = (() => {
		const innerValues: UpdateValues = {};
		// assign sprint value when an issue moves from a different swimlane or column
		if (sourceSwimlaneId !== destinationSwimlaneId || destinationColumnId !== sourceColumnId) {
			innerValues.sprint = `${sprintId}`;
		}

		// assign team value when an issue moves from a different swimlane
		if (destinationSwimlaneId && sourceSwimlaneId !== destinationSwimlaneId) {
			innerValues.team = destinationSwimlaneId;
		}
		return innerValues;
	})();

	return ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1')
		? Observable.of(issueBulkUpdateRequest({ issueIds, ...values }))
		: Observable.of(
				customRequestHandlers.bulkUpdateIssues({
					issueIds,
					...values,
				}),
			);
}

export function handleIssueRankTeamDateChange(
	store: MiddlewareAPI,
	action: IssueRankTeamDateRequestAction,
	dependencies: BoardDependencies,
) {
	const state = store.getState();
	const { rankBeforeIssueId, rankAfterIssueId } = action.payload;
	const hasRankChanged = !(isNil(rankBeforeIssueId) && isNil(rankAfterIssueId));

	return doSwimlaneAndColumnMove(state, action, dependencies)
		.flatMap((followUpAction) => {
			if (ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1') && followUpAction) {
				return Observable.merge(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					followUpAction as ObservableInput<Action>,
					hasRankChanged ? doRank(state, action, dependencies) : success(),
				);
			}
			return hasRankChanged ? doRank(state, action, dependencies) : success();
		})
		.catch((err) => error(err));
}

export function issueRankTeamDateEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
	dependencies: BoardDependencies,
) {
	return action$
		.ofType(ISSUE_RANK_TEAM_DATE_REQUEST)
		.mergeMap((action: IssueRankTeamDateRequestAction) =>
			handleIssueRankTeamDateChange(store, action, dependencies),
		);
}
