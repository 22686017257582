/* eslint-disable jira/react/no-style-attribute */
import React, { useLayoutEffect, useRef, type ReactNode } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { CardDropIndicator } from '@atlassian/jira-platform-board-kit/src/ui/card/main';
import { useIsCardOverColumn } from '@atlassian/jira-platform-board-kit/src/ui/column/column-edge-provider';
import { useResizeObserver } from '@atlassian/jira-react-use-resize-observer';
import type { IssueEntry } from '@atlassian/jira-software-board-common';
import { useEvent } from '@atlassian/jira-software-react-use-event';
import type { RenderCardCallback } from '../../types';
import { ElementSizeMeasurer } from '../element-size-measurer';

type Props = {
	isScrolling: boolean;
	index: number;
	footerIndex: number;
	cardEntries: IssueEntry[];
	footer: ReactNode;
	renderCard: RenderCardCallback;
	// eslint-disable-next-line jira/react/handler-naming
	measure: (el: Element | null) => void;
	// eslint-disable-next-line jira/react/handler-naming
	forceRemeasure: (el?: Element | null) => void;
	rowStyle: React.CSSProperties;
	placeholder: ReactNode | null;
	showFooter: boolean;
	offsetTop: number;
};

const RowRenderer = ({
	index,
	footerIndex,
	cardEntries,
	rowStyle,
	forceRemeasure,
	measure,
	footer,
	renderCard,
	isScrolling,
	placeholder,
	showFooter,
	offsetTop,
}: Props) => {
	const onResize = useEvent(() => {
		forceRemeasure(rowRef.current);
	});
	const rowRef = useRef(null);

	useLayoutEffect(() => {
		if (rowRef.current) {
			measure(rowRef.current);
		}
	}, [rowRef, measure]);

	useResizeObserver({
		ref: rowRef,
		onResize,
	});

	const { isOverColumn } = useIsCardOverColumn();

	// If we have nothing to show except the placeholder
	if (cardEntries.length === 0 && !!placeholder && !showFooter) {
		// Just measure the DIV once we have the ref which is good enough to determine its proper height
		return ff('backlog-active-sprints-list-markup-accessibility-fix_imavd') ? (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			<li style={rowStyle} ref={rowRef}>
				{placeholder}
			</li>
		) : (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			<div style={rowStyle} ref={rowRef}>
				{placeholder}
			</div>
		);
	}

	// Handle the footer specific use case
	if (index === footerIndex) {
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			<ElementSizeMeasurer style={rowStyle} onResize={measure} showFooter={showFooter}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
				{isOverColumn && <CardDropIndicator edge="top" style={{ top: '-1px' }} />}
				{showFooter && footer}
			</ElementSizeMeasurer>
		);
	}

	const cardEntry = cardEntries[index];

	// If there is no cardEntry
	// This will happen if we are attempting to render a footer but showFooter is false.
	if (cardEntry == null) return null;

	// Finally render a normal card
	return ff('backlog-active-sprints-list-markup-accessibility-fix_imavd') ? (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<li style={rowStyle} ref={rowRef}>
			{renderCard(cardEntry, index, isScrolling, onResize, offsetTop)}
		</li>
	) : (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<div style={rowStyle} ref={rowRef}>
			{renderCard(cardEntry, index, isScrolling, onResize, offsetTop)}
		</div>
	);
};

export default RowRenderer;
