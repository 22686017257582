import React, { Component } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import noop from 'lodash/noop';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
// eslint-disable-next-line import/order
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { layout } from '../../../common/constants/styles';
import type { ValidateColumnCallback, RenderCustomButton, ColumnId } from '../../../common/types';
import { isStickySupported } from '../../../common/utils/is-sticky-supported';
import { ColumnCreateButtonWithEngagement } from './button';
import ColumnCreateForm, { type ColumnCreateCallback } from './form';

const columnMarginRight = layout.columnMargin * 2;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledDivControl = styled.div({
	alignItems: 'stretch',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${layout.cardListRadius}px`,
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
	flexWrap: 'nowrap',
	justifyContent: 'flex-start',
	'&::before': {
		position: 'absolute',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `${layout.columnMargin}px`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledDivExperiment = styled2.div({
	alignItems: 'stretch',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${layout.cardListRadius}px`,
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
	flexWrap: 'nowrap',
	justifyContent: 'flex-start',
	'&::before': {
		position: 'absolute',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `${layout.columnMargin}px`,
	},
});

const StyledDiv = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	StyledDivExperiment,
	StyledDivControl,
);

export interface Props {
	isDisabled: boolean;
	validate: ValidateColumnCallback;
	spotlightTargetId?: string;
	renderCustomButton?: RenderCustomButton;
	onSubmit: ColumnCreateCallback;
	onCreateButtonClick?: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onEditStart?: () => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onEditCancel?: (columnId: ColumnId, type: string) => any;
}

interface State {
	isFormVisible: boolean;
}

// eslint-disable-next-line jira/react/no-class-components
export default class ColumnCreate extends Component<Props, State> {
	static defaultProps = {
		isDisabled: false,
		spotlightTargetId: '',
		onSubmit: noop,
	};

	state = {
		isFormVisible: false,
	};

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (this.state.isFormVisible !== prevState.isFormVisible) {
			this.scrollIntoView();
		}
	}

	onSubmit = (temporaryId: ColumnId, name: string, analyticsEvent: AnalyticsEvent) => {
		this.setState({
			isFormVisible: false,
		});
		this.props.onSubmit(temporaryId, name, analyticsEvent);
	};

	onCancel = (columnId: ColumnId, type: string) => {
		this.setState({
			isFormVisible: false,
		});

		this.props.onEditCancel && this.props.onEditCancel(columnId, type);
	};

	setContainer = (ref: HTMLElement) => {
		this.container = ref;
	};

	// @ts-expect-error - TS2564 - Property 'container' has no initializer and is not definitely assigned in the constructor.
	container: HTMLElement;

	scrollIntoView() {
		if (!isStickySupported) {
			// Scroll the column into view with a method which works with IE11
			this.container.scrollIntoView();
		} else {
			// padding required due to BoardScroll (32px wide + column margin(12px))
			this.container.style.paddingRight = `${gridSize * 4 + columnMarginRight}px`;
			scrollIntoViewIfNeeded(this.container);
			this.container.style.paddingRight = '0px';
		}
	}

	showForm = () => {
		this.setState({
			isFormVisible: true,
		});
	};

	render() {
		const {
			onEditStart,
			validate,
			isDisabled,
			spotlightTargetId,
			renderCustomButton,
			onCreateButtonClick,
		} = this.props;
		const { isFormVisible } = this.state;
		return (
			<StyledDiv innerRef={this.setContainer}>
				{isFormVisible ? (
					<ColumnCreateForm
						onEditStart={onEditStart}
						onCancel={this.onCancel}
						onSubmit={this.onSubmit}
						validate={validate}
					/>
				) : (
					<ColumnCreateButtonWithEngagement
						targetId={spotlightTargetId}
						onClick={onCreateButtonClick || this.showForm}
						isDisabled={isDisabled}
						renderCustomButton={renderCustomButton}
					/>
				)}
			</StyledDiv>
		);
	}
}
