import type { Observable as ObservableType } from 'rxjs/Observable';
import type { CardRefreshBoardScopeResponse } from '../../model/card/card-types';
import { fetchCardRefreshBoardScopeData } from '../../rest/card-refresh-board-scope';

export const cardRefreshBoardScopeService = (
	contextPath: string,
	boardId: string,
	cardIds: string[],
	shouldLoadChildren = true,
): ObservableType<CardRefreshBoardScopeResponse> =>
	fetchCardRefreshBoardScopeData(contextPath, boardId, cardIds, shouldLoadChildren);
