import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError, { type ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ProjectRoleResponse, RoleData } from './types';

const MEMBER_ROLE_NAME = 'Member';
const VIEWER_ROLE_NAME = 'Viewer';

const userProjectRoleNames = (data: ProjectRoleResponse): string[] =>
	data.map((projectRole) => projectRole.name);

export const userIsMember = (data: ProjectRoleResponse) =>
	data.some((role) => role.name === MEMBER_ROLE_NAME);
export const userIsJiraAdmin = (data: ProjectRoleResponse) => data.some((role) => role.admin);
export const userHasCustomRole = (data: ProjectRoleResponse) =>
	data.some(
		(role) => role.name !== VIEWER_ROLE_NAME && role.name !== MEMBER_ROLE_NAME && !role.admin,
	);

export const url = (baseUrl: string, projectId: number) =>
	`${baseUrl}/rest/api/3/project/${projectId}/roledetails?excludeConnectAddons=true&currentMember=true`;

export const getUserProjectRoles = (
	baseUrl: string,
	projectId: number,
): Promise<RoleData | null> => {
	const request = () => performGetRequest(url(baseUrl, projectId));

	return request()
		.then((data) => ({
			isMember: userIsMember(data),
			isJiraAdmin: userIsJiraAdmin(data),
			hasCustomRole: userHasCustomRole(data),
			projectRoleNames: userProjectRoleNames(data),
		}))
		.catch((error: FetchError | ValidationError) => {
			const attributes =
				error instanceof FetchError
					? {
							statusCode: error.statusCode,
							traceId: error.traceId,
						}
					: { errorMessage: error.message };
			fireErrorAnalytics({
				meta: {
					id: 'fetchUserProjectRoles',
					packageName: 'jiraFilters',
					teamName: 'a4t-tanuki',
				},
				error,
				attributes,
				sendToPrivacyUnsafeSplunk: true,
			});
			return Promise.resolve(null);
		});
};
