import { freeze } from 'icepick';
import get from 'lodash/get';
import memoizeOne from 'memoize-one';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { getLongTasksMetrics } from '@atlassian/jira-common-long-task-metrics';
import type { ExternalAction } from '@atlassian/jira-issue-view-store/src/actions/external-actions';
import type { DraggableCardData } from '@atlassian/jira-platform-board-kit/src/common/types.tsx';
import type { SwimlaneModeId } from '@atlassian/jira-platform-board-kit/src/ui/swimlane/types.tsx';
import { connect } from '@atlassian/jira-react-redux';
import type { CardId } from '../../../../../model/card/card-types';
import { BOARD_LONG_TASKS_DND_MARK_NAME } from '../../../../../model/constants';
import type { IssueEntities } from '../../../../../model/issue/issue-types';
import type { IssueProjectEntities } from '../../../../../model/software/software-types';
import {
	SWIMLANE_BY_SUBTASK,
	SWIMLANE_CHILDLESS,
} from '../../../../../model/swimlane/swimlane-modes';
import {
	cardClick,
	cardFocus,
	cardBlur,
	cardRemoveShowRipple,
} from '../../../../../state/actions/card';
import { openIssueModal } from '../../../../../state/actions/issue/modal';
import { closeInsightsPanel, closeViewSettingsPanel } from '../../../../../state/actions/panels';
import type { IssueChildrenState } from '../../../../../state/reducers/entities/issue-children/types';
import type { SelectedState } from '../../../../../state/reducers/ui/cards/selected/types';
import {
	isCardSelected,
	isCardFocused,
	shouldCardShowRipple,
	getShouldShowParent,
	getCardSelection,
} from '../../../../../state/selectors/card/card-selectors';
import { getIssueChildren } from '../../../../../state/selectors/issue-children';
import {
	issueParentsSelector,
	getIssueParents,
	makeIssueParentByIdSelector,
	availableIssueParentsForIssueProjectSelector,
} from '../../../../../state/selectors/issue-parent';
import { isIssueModalShowingSelector } from '../../../../../state/selectors/issue/issue-modal-selectors';
import { isDoneIssueSelector } from '../../../../../state/selectors/issue/issue-selectors';
import { getPerson } from '../../../../../state/selectors/people/people-selectors';
import {
	getIsCMPBoard,
	getIssueProjectIds,
	isBoardRankable,
	projectTypeSelector,
	getIsIncrementPlanningBoard,
	getIssueProjects,
} from '../../../../../state/selectors/software/software-selectors';
import { getSwimlaneMode } from '../../../../../state/selectors/swimlane/swimlane-mode-selectors';
import {
	shouldRenderCardTypesSelector,
	platformHighlightScopeSelector,
	isCardDragDisabled,
	getEstimateExtendedByIssueId,
	getStatusByIssueIdSelector,
	platformIssueSelector,
} from '../../../../../state/selectors/work/work-selectors';
import type { Dispatch, State } from '../../../../../state/types';
import type { StateProps, OwnProps } from './types';
import Card, { NewCardWithNullableIssue as NewCardView } from './view';

const defaultHighlightLabelsOnCard = freeze([]);

type CanDropOnCardParams = {
	draggingCardData: DraggableCardData;
	droppingCardData: DraggableCardData;
	isCMPBoard: boolean;
	isRankable: boolean;
	swimlaneMode: SwimlaneModeId | null | undefined;
	issueChildren: IssueChildrenState;
	selectedCards: CardId[] | null | undefined;
	isIncrementPlanningBoard?: boolean;
};

export const canDropOnCard = ({
	draggingCardData,
	droppingCardData,
	isCMPBoard,
	isRankable,
	swimlaneMode,
	issueChildren,
	selectedCards,
	isIncrementPlanningBoard,
}: CanDropOnCardParams) => {
	// Childless cards cannot be moved to a subtask type swimlane and vice-versa
	const sourceSwimlaneId = draggingCardData.swimlaneId;
	const targetSwimlaneId = droppingCardData.swimlaneId;
	if (
		(targetSwimlaneId === SWIMLANE_CHILDLESS && sourceSwimlaneId !== SWIMLANE_CHILDLESS) ||
		(targetSwimlaneId !== SWIMLANE_CHILDLESS && sourceSwimlaneId === SWIMLANE_CHILDLESS)
	) {
		return false;
	}
	// In Increment planning board, allow the user to rank issues by drop on card
	if (isIncrementPlanningBoard) return true;
	if (!isCMPBoard) return true;

	if (!isRankable) return false;

	if (draggingCardData.swimlaneId !== droppingCardData.swimlaneId) return false;

	if (swimlaneMode !== SWIMLANE_BY_SUBTASK.id) {
		const draggingIssueChild = issueChildren[draggingCardData.cardId];
		const droppingIssueChild = issueChildren[droppingCardData.cardId];

		if (draggingIssueChild || droppingIssueChild) {
			return draggingIssueChild?.parentId === droppingIssueChild?.parentId;
		}
	}

	if (selectedCards?.includes(droppingCardData.cardId)) {
		return false;
	}

	return true;
};

export const mapStateToProps = () => {
	const isDroppableOnCardFactory = memoizeOne(
		(
			isCMPBoard: boolean,
			isRankable: boolean,
			swimlaneMode: SwimlaneModeId | null | undefined,
			issueChildren: IssueEntities,
			selectedCards: SelectedState,
			isIncrementPlanningBoard: boolean,
		) =>
			(draggingCardData: DraggableCardData, droppingCardData: DraggableCardData) =>
				canDropOnCard({
					draggingCardData,
					droppingCardData,
					isCMPBoard,
					isRankable,
					swimlaneMode,
					issueChildren,
					selectedCards,
					isIncrementPlanningBoard,
				}),
	);
	return (state: State, ownProps: OwnProps): StateProps => {
		const { id } = ownProps;
		const issue = platformIssueSelector(state)(id);
		const highlight = platformHighlightScopeSelector(state);
		const isCMPBoard = getIsCMPBoard(state);
		const isIncrementPlanningBoard = getIsIncrementPlanningBoard(state);
		const issueParents = issueParentsSelector(state);
		const availableIssueParentsForIssueProject = availableIssueParentsForIssueProjectSelector(
			state,
		)(issue?.projectId);
		const issueParentsObj = getIssueParents(state);
		const isParent = id in issueParentsObj;
		const parentId = (() => {
			if (!issue) {
				return null;
			}

			if (isParent) {
				return id;
			}
			return issue.parentId;
		})();
		const parent = parentId !== null ? makeIssueParentByIdSelector(state)(parentId) : null;
		const isJSMBoard = projectTypeSelector(state) === SERVICE_DESK_PROJECT;
		const isRankable = isBoardRankable(state);
		const swimlaneMode = getSwimlaneMode(state);
		const issueChildren = getIssueChildren(state);
		const selectedCards = getCardSelection(state);
		const projects: IssueProjectEntities = getIssueProjects(state);
		const projectKey =
			issue && Object.values(projects).find((project) => project.id === issue.projectId)?.key;
		const isDroppableOnCard = isDroppableOnCardFactory(
			isCMPBoard,
			isRankable,
			swimlaneMode,
			issueChildren,
			selectedCards,
			isIncrementPlanningBoard,
		);
		return {
			issue,
			assignee:
				issue && issue?.assigneeAccountId !== null
					? getPerson(state, issue.assigneeAccountId)
					: null,
			parent,
			issueParents: isIncrementPlanningBoard ? availableIssueParentsForIssueProject : issueParents,
			estimate: getEstimateExtendedByIssueId(state, { id }),
			shouldShowParent: getShouldShowParent(state),
			shouldShowRipple: shouldCardShowRipple(state)(id),
			isDone: isDoneIssueSelector(state)(id),
			isSelected: isCardSelected(state)(id),
			isFocused: isCardFocused(state)(id),
			isFlagged: issue?.isFlagged || false,
			isIssueViewOpen: isIssueModalShowingSelector(state),
			shouldRenderCardType: shouldRenderCardTypesSelector(state),
			highlightTextOnCard: get(highlight, 'text', '') || '',
			// defaultHighlightLabelsOnCard is used to avoid unnecessary re-renderings.
			// If you use [] instead "Card" component would be re-rendered every time when connect function is executed
			highlightLabelsOnCard:
				get(highlight, 'labels', defaultHighlightLabelsOnCard) || defaultHighlightLabelsOnCard,
			isDragDisabled: isCardDragDisabled(state)(id),
			hideEpic: isCMPBoard && state.ui.board.displayOptions.areEpicLabelsHidden,
			projectIds: getIssueProjectIds(state),
			projectKey,
			isCMPBoard,
			status: isJSMBoard ? getStatusByIssueIdSelector(state, { id }) ?? null : null,
			isDroppableOnCard,
		};
	};
};
export const mapDispatchToProps = (dispatch: Dispatch) => ({
	onClick: (id: CardId, withCmd: boolean, withShift: boolean, externalAction?: ExternalAction) => {
		if (!withCmd && !withShift) {
			getLongTasksMetrics('bento').start('board');
		}
		dispatch(cardClick(id, withCmd, withShift, externalAction));
	},
	onEnterPress: (issueKey: string) => {
		dispatch(openIssueModal(issueKey));
	},
	onMouseDown: () => {
		getLongTasksMetrics('dnd').start('board', BOARD_LONG_TASKS_DND_MARK_NAME);
	},
	onMouseUp: () => {
		getLongTasksMetrics('dnd').stopIfNoMark(BOARD_LONG_TASKS_DND_MARK_NAME);
	},
	onRippleEnd: (id: CardId) => {
		dispatch(cardRemoveShowRipple(id));
	},
	onFocus: (id: CardId) => {
		dispatch(cardFocus(id));
	},
	onBlur: () => {
		dispatch(cardBlur());
	},
	onCloseOtherPanels: () => {
		dispatch(closeInsightsPanel());
		dispatch(closeViewSettingsPanel());
	},
});

/**
 * Non editable card - Use NewCard if possible
 * */
const CardConnected = connect(mapStateToProps, mapDispatchToProps)(AnalyticsSubject('issue')(Card));
/**
 * Inline editable card
 * */
export const NewCard = connect(
	mapStateToProps,
	mapDispatchToProps,
)(AnalyticsSubject('issue')(NewCardView));

export default CardConnected;
