import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../../actions/work';
import type { Action } from '../../../../types';
import type { HasFilteredIssueState } from './types';

export const hasFilteredIssuesReducer = (
	state: HasFilteredIssueState = false,
	action: Action,
): HasFilteredIssueState => {
	switch (action.type) {
		case WORK_DATA_SET:
		case WORK_DATA_CRITICAL_SET:
			return action.payload.hasFilteredIssues;
		default:
			return state;
	}
};
