import { useRouter } from '@atlassian/react-resource-router';
import { getMode, getModeV2, toCamelCaseChartView } from './utils';

export type UrlState = {
	accountId?: string | null;
	mode: string;
	boardId?: string | null;
	chartView: string;
	projectKey?: string | null;
	routeName: string;
	config: string;
	isBoardLocationless: boolean;
	isReportsView: boolean;
};

export const useUrlState = (): UrlState => {
	const [{ query, route, match }] = useRouter();

	const { rapidView, chart, view, config, projectKey: queryProjectKey, locationless } = query;
	const { accountId } = match.params;

	const isBoardLocationless = !!locationless;
	const shouldUsePrettyUrlState = !isBoardLocationless;

	const mode = shouldUsePrettyUrlState ? getModeV2(route) : getMode(view);
	const boardId = shouldUsePrettyUrlState ? match.params.boardId : rapidView;
	const projectKey = shouldUsePrettyUrlState ? match.params.projectKey : queryProjectKey;
	const chartView = shouldUsePrettyUrlState
		? toCamelCaseChartView(match.params.chartView || '')
		: chart;
	const isReportsView = query && query.view === 'reporting';

	return {
		accountId,
		mode,
		boardId,
		chartView,
		projectKey,
		routeName: route.name,
		config,
		isBoardLocationless,
		isReportsView,
	};
};
