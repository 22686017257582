import type {
	BacklogIssueMoveExecuteAction,
	BacklogIssueMoveExecuteFailureAction,
	BacklogIssueMoveExecuteSuccessAction,
} from '../../../actions/board/backlog-issue-move';
import type { State } from '../../../reducers/types';
import { getFirstColumnIssueIds } from '../../../selectors/board/backlog-issue-move-selectors';

export const backlogIssueMoveShow = (eventPrefix: string) => (preState: State, state: State) => ({
	name: `${eventPrefix}.backlog-issue-move.show`,
	data: {
		numIssues: getFirstColumnIssueIds(state).length,
	},
});

export const backlogIssueMoveCancel = (eventPrefix: string) => (preState: State, state: State) => ({
	name: `${eventPrefix}.backlog-issue-move.cancel`,
	data: {
		numIssues: getFirstColumnIssueIds(state).length,
	},
});

export const backlogIssueMoveExecute =
	(eventPrefix: string) =>
	(preState: State, state: State, action: BacklogIssueMoveExecuteAction) => ({
		name: `${eventPrefix}.backlog-issue-move.execute`,
		data: {
			numIssues: action.payload.issueIds.length,
			transactionId: action.meta.optimistic.id,
		},
	});

export const backlogIssueMoveExecuteSuccess =
	(eventPrefix: string) =>
	(preState: State, state: State, action: BacklogIssueMoveExecuteSuccessAction) => ({
		name: `${eventPrefix}.backlog-issue-move.execute.success`,
		data: {
			transactionId: action.meta.optimistic.id,
		},
	});

export const backlogIssueMoveExecuteFailure =
	(eventPrefix: string) =>
	(preState: State, state: State, action: BacklogIssueMoveExecuteFailureAction) => ({
		name: `${eventPrefix}.backlog-issue-move.execute.failure`,
		data: {
			transactionId: action.meta.optimistic.id,
		},
	});
