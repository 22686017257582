import { useIntl } from '@atlassian/jira-intl';
import { BASE_HIERARCHY_TYPE } from '@atlassian/jira-issue-type-hierarchies';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { Capability } from '../../../../../../../../common/capability';
import { useBoardActionCreator, useBoardSelector } from '../../../../../../../../state';
import { removeFromSprintRequest } from '../../../../../../../../state/actions/sprints/remove-from-sprint';
import { getCapability } from '../../../../../../../../state/selectors/capability/capability-selectors';
import { getIssueById } from '../../../../../../../../state/selectors/issue/issue-selectors';
import { getProjectIssueTypes } from '../../../../../../../../state/selectors/software/software-selectors';
import { REMOVE_FROM_SPRINT_MENU_ID } from '../../constants';
import messages from '../../messages';
import type { ContentProps, Props } from './types';

export const useRemoveFromSprintContent = ({
	isRemoveFromSprintEnabled,
	onRemoveFromSprint,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	if (!isRemoveFromSprintEnabled) return [];
	return [
		{
			type: MenuNodeType.Item,
			label: formatMessage(messages.removeFromSprint),
			id: REMOVE_FROM_SPRINT_MENU_ID,
			onClick: onRemoveFromSprint,
		},
	];
};

export const useRemoveFromSprint = ({ issueId }: Props): MenuNode[] => {
	const canRemoveFromSprint = useBoardSelector((state) =>
		getCapability(state)(Capability.REMOVE_FROM_SPRINT),
	);
	const issue = useBoardSelector((state) => getIssueById(state, issueId));
	const isBaseType =
		useBoardSelector(getProjectIssueTypes)[issue?.typeId]?.hierarchyLevelType ===
		BASE_HIERARCHY_TYPE;

	// TODO: add bulk remove from sprint once feature is added in UIF
	const onRemoveFromSprint = useBoardActionCreator(() => removeFromSprintRequest(issueId));

	return useRemoveFromSprintContent({
		isRemoveFromSprintEnabled: canRemoveFromSprint && isBaseType,
		onRemoveFromSprint,
	});
};
