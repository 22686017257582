import escapeRegExp from 'lodash/escapeRegExp';
import memoize from 'lodash/memoize';
import type { FilterableIssue, MatcherCreator } from '../types';

export const removeAccents = (text: string): string =>
	text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const doesSummaryContainText = (summary: string, lowercaseText: string) =>
	summary.toLowerCase().includes(lowercaseText) ||
	removeAccents(summary).toLowerCase().includes(lowercaseText);

const doesIssueKeyMatchLoosely = (key: string | null | undefined, lowercaseText: string) =>
	key !== undefined && key?.toLowerCase().includes(lowercaseText);

type DisplayNameChecker = (displayName: string) => boolean;

const createDisplayNameChecker = (text: string): DisplayNameChecker => {
	// It transforms a text to a regular expression.
	// eg: text='mi ze', the transformed regexp would be 'mi.*\s+ze'
	// which searches for 'mi' followed by anything then 'ze'.
	const pattern = text.split(/\s+/).map(escapeRegExp).join('.*\\s+');
	const regExp = new RegExp(pattern, 'i');

	return memoize((displayName: string) => regExp.test(displayName));
};

export const textMatcherCreator: MatcherCreator<string, FilterableIssue> = (textFilter) => {
	const lowercaseText = textFilter.toLowerCase();
	const doesDisplayNameMatchRegExp = createDisplayNameChecker(textFilter);

	return ({ summary, key, assignee }) =>
		(summary && doesSummaryContainText(summary, lowercaseText)) ||
		doesIssueKeyMatchLoosely(key, lowercaseText) ||
		(assignee !== undefined && doesDisplayNameMatchRegExp(assignee.displayName));
};
