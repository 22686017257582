import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import type { DeleteCardInput } from '@atlassian/jira-relay/src/__generated__/deleteCardMutation.graphql';
import type { IssueId } from '@atlassian/jira-software-board-common';
import type { DeleteCardExtraInput } from '../../rest/card/delete';
import { deleteCardService } from '../../services/card/delete';
import { makeServiceContext } from '../../services/service-context';
import {
	ISSUE_DELETE_REQUEST,
	issueDeleteFailure,
	type IssueDeleteRequestAction,
	issueDeleteSuccess,
} from '../../state/actions/issue/delete';
import {
	activationIdSelector,
	cloudIdSelector,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors';
import type { Action, ActionsObservable, MiddlewareAPI, State } from '../../state/types';

export const deleteCardInputBuilder = (
	state: State,
	action: IssueDeleteRequestAction,
): DeleteCardInput => {
	const siteId = cloudIdSelector(state);
	const activationId = activationIdSelector(state);
	const boardId = rapidViewIdSelector(state);
	const cardId = action.payload.issue.id.toString();

	// TODO: Update this to use ARI library @atlassian/ari when dependency is accepted https://jdog.jira-dev.com/browse/TOTEM-3113
	const cardAri = `ari:cloud:jira-software:${siteId}:card/activation/${activationId}/${boardId}/${cardId}`;

	return {
		cardId: cardAri,
	};
};

export const extraInputBuilder = (
	state: State,
	action: IssueDeleteRequestAction,
): DeleteCardExtraInput => ({
	cloudId: cloudIdSelector(state),
	issueId: action.payload.issue.id,
});

export const handleError = ({
	error,
	issueId,
	optimisticId,
}: {
	error: Error;
	issueId: IssueId;
	optimisticId: string;
}): Action => {
	try {
		const response: unknown = JSON.parse(error.message);
		if (
			response &&
			typeof response === 'object' &&
			'errorMessages' in response &&
			response.errorMessages &&
			Array.isArray(response.errorMessages)
		) {
			const errors = response.errorMessages.map((message) => String(message));
			return issueDeleteFailure(optimisticId, issueId, errors);
		}
	} catch (_parseErr) {
		/* empty */
	}
	return issueDeleteFailure(optimisticId, issueId);
};

const cardDeleteEpic = (action$: ActionsObservable, store: MiddlewareAPI): Observable<Action> =>
	action$.ofType(ISSUE_DELETE_REQUEST).mergeMap((action: IssueDeleteRequestAction) => {
		const {
			payload: { issue },
			meta: {
				optimistic: { id: optimisticId },
			},
		} = action;

		const state = store.getState();
		const ctx = makeServiceContext(state);
		const deleteCardInput = deleteCardInputBuilder(state, action);
		const extraInput = extraInputBuilder(state, action);

		return deleteCardService(ctx, { deleteCardInput, extraInput })
			.flatMap(() => Observable.of(issueDeleteSuccess(optimisticId, issue.id)))
			.catch((err) => Observable.of(handleError({ error: err, optimisticId, issueId: issue.id })));
	});

export default cardDeleteEpic;
