import type {
	DispatchAction,
	ActionPayload,
	FetchData,
	BuildActionDispatchersProps,
	StoreApi,
	BuildSweetStateActionDispatchersProps,
	HydrateSweetStateActionsDispatchersProps,
} from '../types';
import { strategies } from './reducer-strategies';

export const buildActionDispatchers = (dispatch: DispatchAction): BuildActionDispatchersProps =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Object.keys(strategies).reduce<Record<string, any>>((accumulator, strategy) => {
		accumulator[strategy] = (payload: ActionPayload) => dispatch({ type: strategy, payload });
		return accumulator;
	}, {});

export const buildSweetStateActionDispatchers = (): BuildSweetStateActionDispatchersProps =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Object.keys(strategies).reduce<Record<string, any>>((accumulator, strategy) => {
		accumulator[strategy] =
			(key: string, initialData: FetchData, payload: ActionPayload) =>
			({ setState, getState }: StoreApi) => {
				const state = getState();

				const sliceState = state.fetchables[key] || initialData;
				// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly resetInitialState: () => { readonly initialized: false; readonly loading: false; readonly fetched: false; readonly hasError: false; readonly aborted: false; readonly error: undefined; readonly data: undefined; }; ... 4 more ...; readonly default: (state: FetchState) => FetchState; }'.
				const nextSliceState = strategies[strategy](sliceState, {
					type: strategy,
					payload,
				});

				const nextState = {
					...state,
					fetchables: {
						...state.fetchables,
						[key]: nextSliceState,
					},
				};

				setState(nextState);
			};
		return accumulator;
	}, {});

export const hydrateSweetStateActionsDispatchers = ({
	actions,
	key,
	initialData,
}: HydrateSweetStateActionsDispatchersProps): BuildActionDispatchersProps =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Object.keys(actions).reduce<Record<string, any>>((accumulator, strategy) => {
		const actionExecutor = actions[strategy];
		accumulator[strategy] = (payload: ActionPayload) => {
			actionExecutor(key, initialData, payload);
		};
		return accumulator;
	}, {});
