import { type Observable as ObservableType, Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import { gqlTagSwag } from '@atlassian/jira-software-swag';
import { mutation$ } from '@atlassian/jira-software-swag/src/services/mutation/index.tsx';
import { ColumnStatusFragment } from '../../common/graphql/fragments/column-status';
import { columnDeleteAnalyticsOptions } from '../../common/graphql/metrics';
import type { ColumnsGQLData } from '../../model/column/column-types';

export const softwareColumnDeleteOperationName = 'SoftwareColumnDelete';

export const columnDeleteMutation = gqlTagSwag`mutation SoftwareColumnDelete($boardId:ID!, $columnId: ID!, $replacementColumnId: ID) {
        deleteColumn(input: {boardId: $boardId, columnId: $columnId, replacementColumnId: $replacementColumnId}) {
            columns {
                id
                name
                maxIssueCount
                status {
                    id
                    name
                }
                columnStatus {
                    ...ColumnStatusFragment
                }
                isInitial
                isDone
                transitionId
            }
        }
    }

    ${ColumnStatusFragment}
`;

type Response = {
	deleteColumn: ColumnsGQLData;
};

export const parseResponse = (data: Response): ObservableType<ColumnsGQLData> =>
	Observable.of(data.deleteColumn);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	contextPath: string,
	boardId: string,
	columnId: number,
	replacementColumnId: number | null,
): ObservableType<ColumnsGQLData> =>
	mutation$<Response>(
		contextPath,
		softwareColumnDeleteOperationName,
		columnDeleteMutation,
		{
			boardId,
			columnId,
			replacementColumnId,
		},
		columnDeleteAnalyticsOptions,
	).flatMap(parseResponse);
