import { createHook, createStore, type StoreActionApi } from '@atlassian/react-sweet-state';
import type { BoardScrollPosition, BoardScrollState, OnHorizontalScroll } from './types';

const actions = {
	getBoardScroll:
		() =>
		({ getState }: StoreActionApi<BoardScrollState>) => {
			const { onHorizontalScroll, ...state } = getState();
			return state;
		},
	onBoardScroll:
		(boardScrollPosition: BoardScrollPosition) =>
		({ setState }: StoreActionApi<BoardScrollState>) => {
			setState(boardScrollPosition);
		},
	onBoardScrollInit:
		(boardScrollPosition: BoardScrollPosition, onHorizontalScroll: OnHorizontalScroll) =>
		({ setState }: StoreActionApi<BoardScrollState>) => {
			setState({
				...boardScrollPosition,
				onHorizontalScroll,
			});
		},
	onHorizontalScroll:
		(scrollLeft: number) =>
		({ getState }: StoreActionApi<BoardScrollState>) => {
			const { onHorizontalScroll } = getState();
			onHorizontalScroll(scrollLeft);
		},
};

const boardScrollStore = createStore<BoardScrollState, typeof actions>({
	name: 'board-scroll',
	initialState: {
		scrollLeft: 0,
		scrollTop: 0,
		scrollWidth: 0,
		clientWidth: 0,
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onHorizontalScroll: () => {},
	},
	actions,
});

export const useBoardScroll = createHook(boardScrollStore);

export const useBoardScrollLeft = createHook(boardScrollStore, {
	selector: ({ scrollLeft }) => scrollLeft,
});

export const useBoardHasScrollLeft = createHook(boardScrollStore, {
	selector: ({ scrollLeft }) => !!scrollLeft,
});

export const useBoardScrollTop = createHook(boardScrollStore, {
	selector: ({ scrollTop }) => scrollTop,
});

export const useBoardHasScrollTop = createHook(boardScrollStore, {
	selector: ({ scrollTop }) => !!scrollTop,
});

export const useBoardScrollIsAtRightEdge = createHook(boardScrollStore, {
	selector: ({ clientWidth, scrollLeft, scrollWidth }) => scrollLeft === scrollWidth - clientWidth,
});

export const useBoardScrollApi = createHook(boardScrollStore, {
	selector: null,
});
