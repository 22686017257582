import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { TriggerPointKeyType } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/types.tsx';
import type { GlobalIssueCreateProps } from '../../../model/check-global-issue-create/check-global-issue-create-types';
import type { WorkData, WorkDataCritical, RefreshSource } from '../../../model/work/work-types';
import flagMessages from '../../../view/flags/messages';
import { type FlagMeta, warningFlagMeta } from '../meta/flag-meta';

export const BOARD_LOAD_FAILURE = 'state.actions.work.BOARD_LOAD_FAILURE';

export type LoadBoardFailureAction = {
	type: typeof BOARD_LOAD_FAILURE;
	meta: FlagMeta | null;
};

export const loadBoardFailure = (error = '', showErrorFlag = true): LoadBoardFailureAction => ({
	type: BOARD_LOAD_FAILURE,
	meta: showErrorFlag
		? warningFlagMeta({
				id: BOARD_LOAD_FAILURE,
				titleMessage: flagMessages.boardLoadedFailureTitle,
				descriptionMessage: flagMessages.boardLoadedFailureDescription,
				context: { error: error || '' },
			})
		: null,
});

export const BOARD_LOAD_FAILURE_NO_COLUMN = 'state.actions.work.BOARD_LOAD_FAILURE_NO_COLUMN';

export type LoadBoardFailureNoColumnAction = {
	type: typeof BOARD_LOAD_FAILURE_NO_COLUMN;
	meta: FlagMeta | null;
};

export const loadBoardFailureNoColumn = (
	showErrorFlag = true,
	canConfigureBoard: boolean,
): LoadBoardFailureNoColumnAction => ({
	type: BOARD_LOAD_FAILURE_NO_COLUMN,
	meta: showErrorFlag
		? warningFlagMeta({
				id: BOARD_LOAD_FAILURE_NO_COLUMN,
				titleMessage: flagMessages.noColumnFailureTitle,
				descriptionMessage: canConfigureBoard
					? flagMessages.noColumnFailureAdminDescription
					: flagMessages.noColumnFailureNonAdminDescription,
				context: { canConfigureBoard },
			})
		: null,
});

export const BOARD_VIEW = 'state.actions.work.BOARD_VIEW';

export type BoardViewAction = {
	type: typeof BOARD_VIEW;
	payload: {
		hasHorizontalScrollbar?: boolean;
		isUnscheduledWorkColumnPanel?: boolean;
	};
	meta: {
		analytics: UIAnalyticsEvent;
	};
};

export const boardView = (
	boardInfo: {
		hasHorizontalScrollbar?: boolean;
		isUnscheduledWorkColumnPanel?: boolean;
	},
	analyticsEvent: UIAnalyticsEvent,
): BoardViewAction => ({
	type: BOARD_VIEW,
	payload: boardInfo,
	meta: {
		analytics: analyticsEvent,
	},
});

export const BOARD_NON_CRITICAL_VIEW = 'state.actions.work.BOARD_NON_CRITICAL_VIEW';

export type BoardNonCriticalViewAction = {
	type: typeof BOARD_NON_CRITICAL_VIEW;
	meta: {
		analytics: UIAnalyticsEvent;
	};
};

export const boardNonCriticalView = (
	analyticsEvent: UIAnalyticsEvent,
): BoardNonCriticalViewAction => ({
	type: BOARD_NON_CRITICAL_VIEW,
	meta: {
		analytics: analyticsEvent,
	},
});

export const IP_BOARD_404_VIEW = 'state.actions.work.IP_BOARD_404_VIEW';

export type ShowIPBoard404ViewAction = {
	type: typeof IP_BOARD_404_VIEW;
	meta: null;
};

export const showIPBoard404View = (): ShowIPBoard404ViewAction => ({
	type: IP_BOARD_404_VIEW,
	meta: null,
});

export const LOG_BACK_IN_SHOW = 'state.actions.work.LOG_BACK_IN_SHOW';

export type ShowLogBackInAction = {
	type: typeof LOG_BACK_IN_SHOW;
	payload: string;
	meta: FlagMeta;
};

export const showLogBackInMessage = (logBackInUrl: string): ShowLogBackInAction => ({
	type: LOG_BACK_IN_SHOW,
	payload: logBackInUrl,
	meta: warningFlagMeta({
		id: LOG_BACK_IN_SHOW,
		titleMessage: flagMessages.logBackInTitle,
		descriptionMessage: flagMessages.logBackInDescription,
		context: {
			url: logBackInUrl,
		},
	}),
});

export const SHOW_MISSING_ISSUE_TYPE_MESSAGE = 'state.actions.work.SHOW_MISSING_ISSUE_TYPE_MESSAGE';

export type ShowMissingIssueTypeMessageAction = {
	type: typeof SHOW_MISSING_ISSUE_TYPE_MESSAGE;
	payload: string;
};

export const showMissingIssueTypeMessage = (
	missingIssueTypeName: string,
): ShowMissingIssueTypeMessageAction => ({
	type: SHOW_MISSING_ISSUE_TYPE_MESSAGE,
	payload: missingIssueTypeName,
});

export const WORK_REFRESH_DATA = 'state.actions.work.WORK_REFRESH_DATA';

export type WorkRefreshDataAction = {
	type: typeof WORK_REFRESH_DATA;
	payload: {
		source: RefreshSource;
		issues?: GlobalIssueCreateProps[]; // Populated via props on the top level Board component,
		triggerSource?: TriggerPointKeyType;
		hasShownFlag?: boolean;
	};
};

export const workRefreshData = (
	source: RefreshSource,
	issues?: GlobalIssueCreateProps[],
	triggerSource?: TriggerPointKeyType,
	hasShownFlag?: boolean,
): WorkRefreshDataAction => ({
	type: WORK_REFRESH_DATA,
	payload: {
		source,
		issues,
		triggerSource,
		hasShownFlag,
	},
});

export const WORK_DATA_SET = 'state.actions.work.WORK_DATA_SET';

export type WorkDataSetAction = {
	type: typeof WORK_DATA_SET;
	payload: WorkData;
};

export const workDataSet = (workData: WorkData): WorkDataSetAction => ({
	type: WORK_DATA_SET,
	payload: workData,
});

export const WORK_DATA_CRITICAL_SET = 'state.actions.work.WORK_DATA_CRITICAL_SET';

export type WorkDataCriticalSetAction = {
	type: typeof WORK_DATA_CRITICAL_SET;
	payload: WorkDataCritical;
};

export const workDataCriticalSet = ({
	issues,
	people,
	projects,
	config,
	hasFilteredIssues,
	columns,
	swimlaneModeId,
	sprints,
	isSprintsEnabled,
	boardName,
	capabilities,
	incrementConfig,
	issueParents,
	issueTypes,
	projectIssueTypes,
	boardPermissions,
	issueChildren,
	cardTransitions,
	allFeatures,
	inlineCardCreate,
	isInlineColumnEditEnabled,
	estimationStatistic,
	teams,
}: WorkDataCritical): WorkDataCriticalSetAction => ({
	type: WORK_DATA_CRITICAL_SET,
	payload: {
		issues,
		people,
		projects,
		config,
		hasFilteredIssues,
		columns,
		swimlaneModeId,
		sprints,
		isSprintsEnabled,
		boardName,
		capabilities,
		incrementConfig,
		issueParents,
		issueTypes,
		projectIssueTypes,
		boardPermissions,
		issueChildren,
		cardTransitions,
		allFeatures,
		inlineCardCreate,
		isInlineColumnEditEnabled,
		estimationStatistic,
		teams,
	},
});

export const BEFORE_DISPOSE = 'state.actions.work.BEFORE_DISPOSE';

export type BeforeDisposeAction = {
	type: typeof BEFORE_DISPOSE;
};

export const beforeDispose = { type: BEFORE_DISPOSE };

export type Action =
	| LoadBoardFailureAction
	| BoardViewAction
	| ShowLogBackInAction
	| ShowMissingIssueTypeMessageAction
	| ShowIPBoard404ViewAction
	| WorkRefreshDataAction
	| WorkDataSetAction
	| WorkDataCriticalSetAction
	| LoadBoardFailureNoColumnAction
	| BeforeDisposeAction;
