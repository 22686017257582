import { freeze } from 'icepick';
import { createSelector } from 'reselect';
import {
	IS_SPRINTS_ENABLED,
	IS_POLLING_ENABLED,
	IS_CARD_MEDIA_SWITCH_ENABLED,
} from '../../../model/work/work-features-types';
import { isSprintsEnabled } from '../sprint/sprint-selectors';
import { isCardMediaAvailable } from './work-selectors';

export const getPlatformFeatures = createSelector(
	[isSprintsEnabled, isCardMediaAvailable],
	(isSprintsFeatureEnabled, isAnyCardMediaAvailable) =>
		freeze({
			[IS_SPRINTS_ENABLED]: isSprintsFeatureEnabled,
			[IS_POLLING_ENABLED]: true,
			[IS_CARD_MEDIA_SWITCH_ENABLED]: isAnyCardMediaAvailable,
		}),
);
