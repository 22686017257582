import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { REFRESH_SOURCE_FAILURE } from '../../model/constants';
import {
	setParentFailure,
	setParentSuccess,
	SET_PARENT_OPTIMISTIC,
	type SetParentOptimisticAction,
} from '../../state/actions/issue/parent';
import { workRefreshData } from '../../state/actions/work';
import type { Action, ActionsObservable } from '../../state/types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable) =>
	action$.ofType(SET_PARENT_OPTIMISTIC).mergeMap((action: SetParentOptimisticAction) => {
		const {
			payload: { issues, parentId },
			meta: { optimistic },
		} = action;

		const changedIssueIds = issues
			.filter((issue) => issue.parentId !== parentId)
			.map((issue) => issue.id);

		if (changedIssueIds.length === 0)
			return Observable.of(setParentSuccess(optimistic.id, changedIssueIds));

		const cardsParentAction = Observable.fromPromise(action.payload.requestPromise);

		return cardsParentAction
			.flatMap(() => Observable.of(setParentSuccess(optimistic.id, changedIssueIds)))
			.catch((error: FetchError) => {
				log.safeErrorWithoutCustomerData(
					'issue.set-parent.failure',
					'Failed to set issue parent',
					error,
				);
				return Observable.of(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					setParentFailure(optimistic.id) as Action,
					workRefreshData(REFRESH_SOURCE_FAILURE),
				);
			});
	});
