import { SET_INLINE_EDITING } from '../../../../actions/card';
import type { Action } from '../../../../types';
import type { InlineEditing } from './types';

export const initialState: InlineEditing = {};

export const cardInlineEditingReducer = (
	state: InlineEditing = initialState,
	action: Action,
): InlineEditing => {
	switch (action.type) {
		case SET_INLINE_EDITING: {
			return {
				...state,
				[action.payload.issueId]: {
					// Setting all fields to false except the one we are setting
					assignee: false,
					summary: false,
					[action.payload.field]: action.payload.value,
				},
			};
		}

		default: {
			return state;
		}
	}
};
