/** @jsx jsx */
import React from 'react';
import { jsx } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { Text } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { TemplateRecommendationId } from '../../../common/types';
import { descriptionsMessagesMapping, headingMessagesMapping } from './constants';

export const ExplainerSection = ({ templateId }: { templateId: TemplateRecommendationId }) => {
	const { formatMessage } = useIntl();

	const heading = headingMessagesMapping[templateId];
	const description = descriptionsMessagesMapping[templateId];
	if (!heading || !description) return null;

	return (
		<>
			<Heading size="xsmall" as="div">
				{formatMessage(heading)}
			</Heading>
			<Text as="p">{formatMessage(description)}</Text>
		</>
	);
};
