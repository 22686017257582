import React, { useCallback } from 'react';
import { layers as akLayers } from '@atlaskit/theme/constants';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { PortalledNudge as PortalledNudgeCommon } from '../do-not-use-directly-portalled-nudge';
import type { PortalledNudgeProps } from './types';

export const PortalledNudge = ({
	onRender,
	nudgeTargetId,
	nudgeIdForAnalytics,
	analyticsAttributes = {},
	...props
}: PortalledNudgeProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onRenderNudge = useCallback(() => {
		const event = createAnalyticsEvent({});

		fireUIAnalytics(event, 'nudgeSpotlightCard viewed', nudgeIdForAnalytics, analyticsAttributes);
		if (onRender) {
			onRender();
		}
	}, [nudgeIdForAnalytics, analyticsAttributes, createAnalyticsEvent, onRender]);

	return (
		<PortalledNudgeCommon
			onRender={onRenderNudge}
			nudgeTargetId={nudgeTargetId}
			zIndex={akLayers.dialog()}
			{...props}
		/>
	);
};
