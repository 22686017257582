import React from 'react';
import { styled } from '@compiled/react';
import { BOARD } from '@atlassian/jira-development-board-common/src/common/constants.tsx';
import DevInfoIcon from '@atlassian/jira-development-board-dev-info-icon/src/main.tsx';
import { SUPPORTED_DEV_STATUSES } from './constants';
import type { Props } from './types';

const CardDevStatus = (props: Props) => {
	const { type, issueId, deploymentDetails } = props;

	const hasSupportedType = SUPPORTED_DEV_STATUSES.includes(type);

	if (!hasSupportedType) {
		return null;
	}

	return (
		<Container>
			{issueId && (
				<DevInfoIcon
					issueId={issueId}
					devInfoType={type}
					allowNativeContextMenu
					deploymentDetails={deploymentDetails}
					location={BOARD}
				/>
			)}
		</Container>
	);
};

export default CardDevStatus;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
});
