import { getPerformanceFFsString } from '../common/util/collectors';
import { isExpandedObservationData, type ObservationData } from '../types';
import { generateLongTaskAnalyticsSummary, triggerAnalytics } from './common';

export const filterReporter = (
	observationData: ObservationData,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	featureFlags: Record<any, any> = {},
) => {
	if (isExpandedObservationData(observationData)) {
		const triggerSoftwareAnalytics = triggerAnalytics(`software.${observationData.appName}`);
		const startTime = observationData.events.map((event) => event.timestamp);
		const analyticsData = {
			...generateLongTaskAnalyticsSummary('filter', observationData.events),
			measureTimespan: performance.now() - Math.min(observationData.startTimestamp, ...startTime),
			...featureFlags,
			performanceFFs: getPerformanceFFsString(),
		};
		triggerSoftwareAnalytics('long-tasks-filter', analyticsData);

		try {
			performance.measure('filterBarResponsiveness', 'filterBarStart', 'filterBarStop');
			triggerSoftwareAnalytics(
				'performance-filter-bar',
				performance.getEntriesByName('filterBarResponsiveness')[0],
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			// do nothing
		}
		try {
			performance.measure('oldFilterBarResponsiveness', 'oldFilterBarStart', 'oldFilterBarStop');
			triggerSoftwareAnalytics(
				'performance-old-filter-bar',
				performance.getEntriesByName('oldFilterBarResponsiveness')[0],
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			// do nothing
		}
		performance.clearMeasures();
	}
};
