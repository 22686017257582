import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	issueCount: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.issue-count.issue-count',
		defaultMessage: 'Issue count:',
		description: 'Label for the issue count, i.e Issue count: 10',
	},
});

export default messages;
