import { freeze, merge } from 'icepick';
import { entityArrayToMap } from '../../../../services/software/software-data-transformer';
import { STATUS_CATEGORY_LOAD_SUCCESS } from '../../../actions/status-category';
import type { Action } from '../../../types';
import type { StatusCategoriesState } from './types';

export const statusCategoriesReducer = (
	state: StatusCategoriesState = freeze({}),
	action: Action,
): StatusCategoriesState => {
	if (action.type === STATUS_CATEGORY_LOAD_SUCCESS) {
		const { statusCategories } = action.payload;
		return merge(state, entityArrayToMap(statusCategories));
	}

	return state;
};
