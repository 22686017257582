import React from 'react';
import { lazy } from 'react-loosely-lazy';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props } from './main';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyHtmlCardCustomFieldContent = lazy(() =>
	import(/* webpackChunkName: "async-html-card-custom-field-content" */ './main').then(
		({ HtmlCardCustomFieldContent }) => HtmlCardCustomFieldContent,
	),
);

export const AsyncHtmlCardCustomFieldContent = ({ field }: Props) => (
	<ErrorBoundary id="async-board-card-html-field" packageName="board-kit-card">
		<Placeholder name="async-html-card-custom-field-content" fallback={null}>
			<LazyHtmlCardCustomFieldContent field={field} />
		</Placeholder>
	</ErrorBoundary>
);
