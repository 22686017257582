import { chain } from 'icepick';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import type { Action } from '../../../actions';
import {
	type MissingParentResolveOptimisticAction,
	MISSING_PARENT_RESOLVE_OPTIMISTIC,
} from '../../../actions/issue/missing-parent-resolve';
import { WORK_DATA_SET } from '../../../actions/work';
import type { MissingParentsState } from './types';

export const missingParentsReducer = (
	state: MissingParentsState = {},
	action: Action,
): MissingParentsState => {
	if (action.type === WORK_DATA_SET && action.payload.missingParents) {
		return keyBy(action.payload.missingParents, 'id');
	}

	if (action.type === MISSING_PARENT_RESOLVE_OPTIMISTIC) {
		const {
			payload: { issueId, doneStatusName },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as MissingParentResolveOptimisticAction;
		if (!isEmpty(state) && !isNil(state[issueId] && isNil(doneStatusName))) {
			return (
				chain(state)
					.setIn([issueId, 'status', 'name'], doneStatusName)
					// update this for displaying correct status color and strikethrough on issue key
					.setIn([issueId, 'status', 'category'], 'DONE')
					.value()
			);
		}
	}

	return state;
};
