import React from 'react';
import Badge from '@atlaskit/badge';
import { Box } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { EPIC_STORY_POINT } from './constants';
import messages from './messages';

const EpicStoryPoint = ({ value }: { value: string | number | undefined }) => {
	const { formatMessage, formatNumber } = useIntl();
	if (value === undefined) {
		return null;
	}

	return (
		<Box paddingInlineEnd="space.025" testId={EPIC_STORY_POINT}>
			<Tooltip
				content={formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.tooltipIssueTermRefresh
						: messages.tooltip,
				)}
				position="auto"
			>
				{/* Supported prop in Atlaskit: https://atlassian.design/components/badge/code#style */}
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, jira/react/no-style-attribute */}
				<Badge style={badgeStyles}>{formatNumber(Number(value))}</Badge>
			</Tooltip>
		</Box>
	);
};

export default EpicStoryPoint;

const badgeStyles = {
	backgroundColor: token('color.background.disabled', colors.N20A),
	color: token('color.text.disabled', colors.N100),
};
