import { Subject } from 'rxjs/Subject';
import { type CacheInvalidationType, UNKNOWN } from '../../common/types';
import { CacheInvalidationTracker } from './invalidation-tracker';

const invalidationTracker = new CacheInvalidationTracker();

// Standard observer pattern, the middleware in board/backlog will invoke this
// when it comes across a matching redux mutation that requires the cache
// to be purged
const ResourcesCacheInvalidationSubject = new Subject<CacheInvalidationType>();
export default ResourcesCacheInvalidationSubject;

export const triggerResourceCacheInvalidation = (
	origin: CacheInvalidationType,
	reason: string = UNKNOWN,
) => {
	ResourcesCacheInvalidationSubject.next(origin);

	invalidationTracker.addReason(origin, reason);
};

export const addInvalidationReason = (origin: CacheInvalidationType, reason?: string) => {
	invalidationTracker.addReason(origin, reason);
};

export const getAndClearInvalidationReasons = (origin: CacheInvalidationType): string[] =>
	invalidationTracker.getAndClearReasons(origin);
