import React from 'react';
import { lazy } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { CollabContainerProps } from './controllers/collab';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyCollabContainer = lazy(() =>
	import(
		/* webpackChunkName: "async-software-new-version-release-modal" */ './controllers/collab'
	).then(({ CollabContainer }) => CollabContainer),
);

export const CollabContainerAsync = (props: CollabContainerProps) => (
	<JSErrorBoundary
		id="async-jira-software-real-time-presence"
		packageName="jiraSoftwareRealtimePresence"
		teamName="jira-werewolves"
		fallback="flag"
	>
		<Placeholder name="software-real-time-presence" fallback={<></>}>
			<LazyCollabContainer {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
