import { metrics } from '@atlassian/browser-metrics';
import { APP_IDENTIFIER } from '../index';

export const EXPERIENCES = {
	POLLING: 'projectCreateImporterPolling',
} as const;

export type EXPERIENCES = (typeof EXPERIENCES)[keyof typeof EXPERIENCES];

const POLLING_ITERACTION_METRIC_KEY = `${APP_IDENTIFIER}.polling`;
export const pollingInteractionMetric = metrics.interaction({
	key: POLLING_ITERACTION_METRIC_KEY,
	histogram: {
		result: '200_500_1000_2000_3000_4000_5000_10000',
		response: '200_500_1000_2000_3000_4000_5000_10000',
	},
});
