import { trackBrowserMetricsForJira } from '@atlassian/jira-insights-experience-tracker/src/common/utils/metrics.tsx';
import type { BuildActionDispatchersProps, FetchThunkProps } from '../types';
import { trackDataFetchAnalytics } from './analytics';
import { loggingHandler } from './logger';

export const thunks = {
	fetch: ({
		onFetch,
		analyticsTracking,
		browserMetricsKey,
		loggingKey,
		signal,
		createAnalyticsEvent,
	}: FetchThunkProps) => {
		const metricsTracker = trackBrowserMetricsForJira({
			browserMetricsKey,
		});

		const logger = loggingHandler({ loggingKey });

		return async ({ actions }: { actions: BuildActionDispatchersProps }) => {
			const analyticsTracker = trackDataFetchAnalytics({
				...analyticsTracking,
				createAnalyticsEvent,
			});

			metricsTracker.start();
			analyticsTracker.start();
			// @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
			actions.setFetchStart();

			try {
				const data = await onFetch({ signal });
				actions.setFetchEnd(data);
				metricsTracker.end();
				analyticsTracker.succeeded();
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				metricsTracker.cancel();

				if (e.componentMounted) {
					if (signal.aborted) {
						// @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
						actions.setAborted();
						analyticsTracker.aborted();
					} else {
						const thrownError = e.originalError;
						actions.setError(thrownError);
						analyticsTracker.failed(thrownError);
						logger.error(thrownError);
					}
				}
			}
		};
	},
} as const;
