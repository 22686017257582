import type { IssueId } from '@atlassian/jira-software-board-common';
import type { OptimisticId } from '../../../../model/software/software-types';
import type { FlagMeta } from '../../meta/flag-meta';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiWithFlagMeta,
	type OptimisticUiMeta,
} from '../../meta/optimistic-ui-meta';

export const BACKLOG_ISSUE_MOVE_SHOW =
	'state.actions.board.backlog-issue-move.BACKLOG_ISSUE_MOVE_SHOW' as const;

export type BacklogIssueMoveShowAction = {
	type: typeof BACKLOG_ISSUE_MOVE_SHOW;
};

export const backlogIssueMoveShow = (): BacklogIssueMoveShowAction => ({
	type: BACKLOG_ISSUE_MOVE_SHOW,
});

export const BACKLOG_ISSUE_MOVE_CANCEL =
	'state.actions.board.backlog-issue-move.BACKLOG_ISSUE_MOVE_CANCEL' as const;

export type BacklogIssueMoveCancelAction = {
	type: typeof BACKLOG_ISSUE_MOVE_CANCEL;
};

export const backlogIssueMoveCancel = (): BacklogIssueMoveCancelAction => ({
	type: BACKLOG_ISSUE_MOVE_CANCEL,
});

export const BACKLOG_ISSUE_MOVE_CONFIRM =
	'state.actions.board.backlog-issue-move.BACKLOG_ISSUE_MOVE_CONFIRM' as const;

export type BacklogIssueMoveConfirmAction = {
	type: typeof BACKLOG_ISSUE_MOVE_CONFIRM;
};

export const backlogIssueMoveConfirm = (): BacklogIssueMoveConfirmAction => ({
	type: BACKLOG_ISSUE_MOVE_CONFIRM,
});

export const BACKLOG_ISSUE_MOVE_EXECUTE =
	'state.actions.board.backlog-issue-move.BACKLOG_ISSUE_MOVE_EXECUTE' as const;

export type BacklogIssueMoveExecuteAction = {
	type: typeof BACKLOG_ISSUE_MOVE_EXECUTE;
	payload: {
		issueIds: IssueId[];
	};
	meta: OptimisticUiMeta;
};

export const backlogIssueMoveExecute = (issueIds: IssueId[]): BacklogIssueMoveExecuteAction => ({
	type: BACKLOG_ISSUE_MOVE_EXECUTE,
	payload: {
		issueIds,
	},
	meta: beginOptimisticUiMeta(),
});

export const BACKLOG_ISSUE_MOVE_EXECUTE_SUCCESS =
	'state.actions.board.backlog-issue-move.BACKLOG_ISSUE_MOVE_EXECUTE_SUCCESS' as const;

export type BacklogIssueMoveExecuteSuccessAction = {
	type: typeof BACKLOG_ISSUE_MOVE_EXECUTE_SUCCESS;
	meta: OptimisticUiMeta;
};

export const backlogIssueMoveExecuteSuccess = (
	optimisticId: OptimisticId,
): BacklogIssueMoveExecuteSuccessAction => ({
	type: BACKLOG_ISSUE_MOVE_EXECUTE_SUCCESS,
	meta: commitOptimisticUiMeta(optimisticId),
});

export const BACKLOG_ISSUE_MOVE_EXECUTE_FAILURE =
	'state.actions.board.backlog-issue-move.BACKLOG_ISSUE_MOVE_EXECUTE_FAILURE' as const;

export type BacklogIssueMoveExecuteFailureAction = {
	type: typeof BACKLOG_ISSUE_MOVE_EXECUTE_FAILURE;
	meta: OptimisticUiMeta & FlagMeta;
};

export const backlogIssueMoveExecuteFailure = (
	optimisticId: OptimisticId,
): BacklogIssueMoveExecuteFailureAction => ({
	type: BACKLOG_ISSUE_MOVE_EXECUTE_FAILURE,
	meta: revertOptimisticUiWithFlagMeta(optimisticId),
});

export type Action =
	| BacklogIssueMoveCancelAction
	| BacklogIssueMoveConfirmAction
	| BacklogIssueMoveShowAction
	| BacklogIssueMoveExecuteAction
	| BacklogIssueMoveExecuteFailureAction
	| BacklogIssueMoveExecuteSuccessAction;
