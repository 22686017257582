import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FREE_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useCheckIfUserCanUpgrade } from '../../../services/eligibility';

export const EligibilityChecker = ({ children }: { children: JSX.Element }): JSX.Element => {
	const cohort = expVal('jira_recommended_trial_to_free_ab', 'cohort', 'not_enrolled');
	const { userCanUpgrade } = useCheckIfUserCanUpgrade(cohort === 'not_enrolled');
	const { software: jswEdition } = useAppEditions();
	const eligibleForTrial =
		jswEdition === FREE_EDITION && userCanUpgrade && cohort && cohort === 'test';

	if (eligibleForTrial) {
		return children;
	}

	return <></>;
};
