import type { Action } from '../../../actions';
import { EXTENSION_FETCH_SUCCESS } from '../../../actions/extension';
import type { ExtensionStateType } from './types';

const initialState: ExtensionStateType = {
	operations: [],
};

export default function extensionReducer(
	state: ExtensionStateType = initialState,
	action: Action,
): ExtensionStateType {
	if (action.type === EXTENSION_FETCH_SUCCESS) {
		return { ...state, ...action.payload.extension };
	}
	return state;
}
