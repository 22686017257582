import memoize from 'lodash/memoize';
import { createStorageProvider, withAutoReconnect } from './utils';

type StorageProviderOptions = {
	/**
	 * Enables 1 retry when an error occurs for get, set, remove, clearAll
	 * This is generally helpful to recover from indexed-db connection being closed after the computer wakes up from sleep
	 */
	shouldAutoReconnect?: boolean;
};

export const createIndexedDBStorageProvider = memoize(
	(appPrefix: string, options?: StorageProviderOptions) => {
		if (!appPrefix) {
			throw Error("appPrefix can not be empty. It's used to separate apps");
		}
		const provider = createStorageProvider(appPrefix);
		if (options?.shouldAutoReconnect) {
			return withAutoReconnect(provider);
		}
		return provider;
	},
);
