import { useCallback, useState, useEffect, useRef } from 'react';
import type {
	UseFocusOnIssueLinksStatsEffectOptions,
	UseFocusOnIssueLinksStatsEffectResult,
	UseFocusOnIssueLinksStatsResult,
} from './types';

export const useFocusOnIssueLinksStats = (): UseFocusOnIssueLinksStatsResult => {
	const [isFocusOnIssueLinksStats, setIsFocusOnIssueLinksStats] = useState<boolean>(false);

	return { isFocusOnIssueLinksStats, focusOnIssueLinks: setIsFocusOnIssueLinksStats };
};

export const useFocusOnIssueLinksStatsEffect = ({
	isFocusOnTrigger,
	onFocusToTrigger,
}: UseFocusOnIssueLinksStatsEffectOptions): UseFocusOnIssueLinksStatsEffectResult => {
	const openTriggerRef = useRef<HTMLElement | null>(null);

	const updateRef = useCallback(
		(element: HTMLElement | null) => {
			openTriggerRef.current = element;
		},
		[openTriggerRef],
	);

	useEffect(() => {
		if (!isFocusOnTrigger) {
			return;
		}

		openTriggerRef.current?.focus();
		onFocusToTrigger(false);
	}, [isFocusOnTrigger, onFocusToTrigger, openTriggerRef]);

	return updateRef;
};
