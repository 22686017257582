import type { Dispatch, Middleware } from 'redux';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import {
	jswReduxSoftwareAppLoadedStartMark,
	jswReduxSoftwareAppLoadedEndMark,
} from '@atlassian/jira-providers-spa-apdex-analytics/src/marks';
import type { Action } from '../../actions';
import { SOFTWARE_APP_LOADED } from '../../actions/software';

// @ts-expect-error - Type 'T' is not assignable to type 'S'.
export const actionMetricsMiddleware: Middleware = () => (next: Dispatch) => (action: Action) => {
	let result;
	if (action.type === SOFTWARE_APP_LOADED) {
		// Measure how long it takes to execute the action when the software app is loaded
		// to get better insight into performance of large boards.
		setMark(jswReduxSoftwareAppLoadedStartMark);
		result = next(action);
		setMark(jswReduxSoftwareAppLoadedEndMark);
	} else {
		result = next(action);
	}
	return result;
};
