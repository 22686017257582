import React from 'react';
import { SuccessAutoDismissFlag } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import flagMessages from './messages';
import type { Props } from './types';

const CardCreateUnmappedFlagInIpBoard = ({ onDismissed, id, onClearAllButtonClicked }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<SuccessAutoDismissFlag
			id={id}
			onDismissed={onDismissed}
			title={formatMessage(flagMessages.updatedIssueHiddenTitle)}
			description={formatMessage(flagMessages.updatedIssueHiddenDescription)}
			actions={[
				{
					content: formatMessage(flagMessages.clearFiltersActionText),
					onClick: () => {
						onClearAllButtonClicked();
						onDismissed && onDismissed(id);
					},
				},
			]}
		/>
	);
};

export default CardCreateUnmappedFlagInIpBoard;
