import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { createAri } from '@atlassian/jira-platform-ari';
import ShareButton from '@atlassian/jira-share-button';
import { integrationTypes } from '@atlassian/jira-share-button/src/constants';
import { useShareIntegrations } from '@atlassian/jira-share-integrations';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import messages from './messages';

type Props = {
	projectId: string;
	shareContentType: string;
	onTriggerButtonClick: () => void;
};

export const JswShareButton = ({ projectId, shareContentType, onTriggerButtonClick }: Props) => {
	const cloudId = useCloudId();
	const { formatMessage } = useIntl();
	const { shareIntegrations } = useShareIntegrations('board');

	return (
		<ShareButton
			integrationType={integrationTypes.CLASSIC_BOARD}
			triggerButtonStyle="icon-only"
			productId="jira"
			subProductId="jira-software"
			shareTitle={formatMessage(messages.shareTitle)}
			shareFormTitle={formatMessage(messages.shareFormTitle)}
			shareContentType={shareContentType}
			objectAri={createAri({
				resourceOwner: 'jira',
				cloudId,
				resourceType: 'project',
				resourceId: projectId,
			})}
			copyTooltipText={formatMessage(messages.shareTooltipText)}
			shareIntegrations={shareIntegrations}
			shareFormHelperMessage={formatMessage(messages.shareHelperMessage)}
			onTriggerButtonClick={onTriggerButtonClick}
		/>
	);
};
