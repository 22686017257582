import React, { type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import SettingsIcon from '@atlaskit/icon/core/settings';
import SettingsIconOld from '@atlaskit/icon/glyph/settings';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	onClick: (arg1: SyntheticEvent<HTMLElement>, arg2: UIAnalyticsEvent) => void;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ onClick }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<Container>
			<Sticky>
				<Tooltip content={formatMessage(messages.description)}>
					<Button
						testId="platform-board-kit.ui.column.column-configuration.button"
						aria-label={formatMessage(messages.description)}
						iconBefore={
							fg('tnk-1614-visual-refresh-boardkit-icons') ? (
								<SettingsIcon
									label={formatMessage(messages.description)}
									spacing="spacious"
									LEGACY_fallbackIcon={SettingsIconOld}
									LEGACY_size="medium"
									color={token('color.icon')}
								/>
							) : (
								<SettingsIconOld label={formatMessage(messages.description)} size="medium" />
							)
						}
						onClick={onClick}
					/>
				</Tooltip>
			</Sticky>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	position: 'relative',
	padding: `0 ${token('space.100', '8px')} 0 ${token('space.100', '8px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Sticky = styled.div({
	position: 'sticky',
	top: 0,
});
