import { createSelector } from 'reselect';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';
import { UNSCHEDULED_COLUMN_ID } from '@atlassian/jira-common-constants/src/column-types';
import type { IssueId } from '@atlassian/jira-software-board-common';
import type { CardTransition } from '../../../model/card-transition/card-transition-types';
import type { ColumnId } from '../../../model/column/column-types';
import type { IssueEntities, IssueKey, IssueTypeId } from '../../../model/issue/issue-types';
import type { StatusId } from '../../../model/software/software-types';
import { SWIMLANE_BY_PARENT_ISSUE } from '../../../model/swimlane/swimlane-modes';
import type { SwimlaneId } from '../../../model/swimlane/swimlane-types';
import { getSwimlaneId } from '../../../services/issue/issue-data-transformer';
import type { State } from '../../reducers/types';
import type { AutoScrollState } from '../../reducers/ui/cards/auto-scroll/types';
import type { CardDragState } from '../../reducers/ui/cards/drag/types';
import type { FocusedState } from '../../reducers/ui/cards/focused/types';
import type { SelectedState } from '../../reducers/ui/cards/selected/types';
import type { TransactionId } from '../../reducers/ui/cards/transactions/types';
import {
	boardIssuesSelector,
	isBoardIssueAChild as isBoardIssueAChildSelector,
} from '../issue/board-issue-selectors';
import { getIssueById } from '../issue/issue-selectors';
import { getCardTransitions, getIsCMPBoard, getUi } from '../software/software-selectors';
import { getSwimlaneMode, hasSwimlanes } from '../swimlane/swimlane-mode-selectors';
import { getSwimlanes } from '../swimlane/swimlane-selectors';

export const getCardSelection = (state: State): SelectedState => state.ui.cards.selected;
const getCardUi = (state: State) => getUi(state).cards;
export const getCardDragState = (state: State): CardDragState => getCardUi(state).drag;
export const getSelectedTransition = (state: State) => getCardDragState(state).selectedTransition;
export const isHoverIntended = (state: State): boolean =>
	getCardDragState(state).hoverIntended || false;
export const getCardFocused = (state: State): FocusedState => getCardUi(state).focused;
export const getCardAutoScroll = (state: State): AutoScrollState => getCardUi(state).autoScroll;
// The active card is the card currently focused or the most recently selected card
export const getCardActive = (state: State): IssueId | null | undefined => {
	const focusedCard = getCardFocused(state);
	if (focusedCard !== null) {
		return focusedCard;
	}
	const selectedCards = getCardSelection(state);
	return selectedCards[selectedCards.length - 1];
};
export const getCardActiveKey = (state: State): IssueKey | null | undefined => {
	const activeIssueId = getCardActive(state);
	const issue = activeIssueId ? getIssueById(state, activeIssueId) : null;
	return issue ? issue.key : null;
};

export const getCardActiveAssignee = (state: State): string | null | undefined => {
	const activeIssueId = getCardActive(state);
	const issue = activeIssueId ? getIssueById(state, activeIssueId) : null;
	return issue ? issue.assigneeAccountId : null;
};

export const getDraggingCardId = (state: State) => getCardDragState(state).cardId;

export const isDragInSnapMode = (state: State) => getCardDragState(state).snapMode;
const getCardDragShowRipple = (state: State) => getCardDragState(state).rippleIdsToShow;
export const hasNonGlobalTransitions = createSelector(
	[getCardTransitions],
	(cardTransitions) =>
		!!cardTransitions &&
		flatMap(cardTransitions.columnIssueTypeTransitions, (issueTypeTransitions) =>
			flatMap(issueTypeTransitions),
		).some((t) => !(t.isGlobal || t.isInitial)),
);

/**
 * This selector builds a function that, given an issue ID being dragged, will
 * use it and all selected cards to determine if any of their issue types have
 * transitions with conditions.
 *
 * This is important to: trigger requests for per issue transitions
 * conditions/permissions (only really required if any of the transitions for
 * this issue type have conditions)
 */
export const makeGetHasConditions = createSelector(
	[getCardTransitions, getCardSelection, boardIssuesSelector],
	(cardTransitions, cardSelection, boardIssues) => (issueId: IssueId) => {
		const getIssueTypeHasConditions = (issueTypeId: IssueTypeId) => {
			if (!cardTransitions) {
				return false;
			}

			return Object.keys(cardTransitions.columnIssueTypeTransitions).some((columnId) => {
				const issueTypeTransitions = cardTransitions.columnIssueTypeTransitions[columnId];
				const transitions = issueTypeTransitions[issueTypeId];

				return Boolean(
					transitions?.some((transition: CardTransition) => Boolean(transition.hasConditions)),
				);
			});
		};

		const selectedIssueTypes = uniq(
			[...cardSelection, issueId].map((id) => boardIssues[id]?.typeId),
		);
		return selectedIssueTypes.some(getIssueTypeHasConditions);
	},
);

export const hasConditionsOnTransitions = createSelector(
	[getCardTransitions],
	(cardTransitions): boolean =>
		!!cardTransitions &&
		flatMap(cardTransitions.columnIssueTypeTransitions, (issueTypeTransitions) =>
			flatMap(issueTypeTransitions),
		).some((t) => t.hasConditions === true),
);

export const hasNoTransitionSelector = createSelector(
	[getCardTransitions],
	(cardTransitions) =>
		!cardTransitions || Object.values(cardTransitions.columnIssueTypeTransitions).every(isEmpty),
);

export const isTransactionValid = (
	state: State,
	issueId: IssueId,
	transactionId: TransactionId,
): boolean => state.ui.cards.transactions[String(issueId)] === transactionId;

export const isCardSelected = createSelector([getCardSelection], (cardSelection) =>
	memoize((issueId) => cardSelection.includes(issueId)),
);

export const isCardFocused = createSelector(
	[getCardFocused],
	(focusedCardId) => (issueId: IssueId) => focusedCardId === issueId,
);

export const getFocusedIssue = createSelector(
	[getCardFocused, boardIssuesSelector],
	(focusedCardId, issues) => issues[String(focusedCardId)],
);

export const shouldCardShowRipple = createSelector([getCardDragShowRipple], (rippleIdsToShow) =>
	memoize((issueId) => rippleIdsToShow.includes(issueId)),
);

export const getSelectedCount = createSelector(
	[getCardSelection],
	(cardSelection) => cardSelection.length,
);

export const getLastSelectedCard = createSelector([getCardSelection], (cardSelection) =>
	cardSelection.length > 0 ? cardSelection[cardSelection.length - 1] : null,
);

export const areAnySelectedCardsIssueChildren = createSelector(
	[getCardSelection, isBoardIssueAChildSelector],
	(cardSelection, isBoardIssueAChild) => cardSelection.some((cardId) => isBoardIssueAChild(cardId)),
);

export const draggingCardSourceColumnIdSelector = createSelector(
	[getCardDragState, boardIssuesSelector],
	(cardDrag, issues) => issues[String(cardDrag.cardId)] && issues[String(cardDrag.cardId)].columnId,
);

export const draggingCardSourceSwimlaneIdSelector = createSelector(
	[getCardDragState, boardIssuesSelector, getSwimlaneMode, getSwimlanes, getIsCMPBoard],
	(cardDrag, issues, swimlaneMode, availableSwimlanes, isCMPBoard) => {
		const issue = issues[String(cardDrag.cardId)];
		if (!issue) return null;

		return getSwimlaneId(
			swimlaneMode,
			availableSwimlanes,
			issue,
			isCMPBoard,
			(issueId: string) => issues[issueId],
		);
	},
);

export const draggingCardIssueTypeId = createSelector(
	[getDraggingCardId, boardIssuesSelector],
	(issueId: IssueId | null | undefined, boardIssues: IssueEntities): IssueTypeId | undefined =>
		issueId != null && boardIssues[String(issueId)] != null
			? boardIssues[String(issueId)].typeId
			: undefined,
);

export const draggingCardIssueStatusId = createSelector(
	[getDraggingCardId, boardIssuesSelector],
	(issueId: IssueId | null | undefined, boardIssues: IssueEntities): StatusId | undefined =>
		issueId != null && boardIssues[String(issueId)]
			? boardIssues[String(issueId)].statusId
			: undefined,
);

export const getIssueIdWithAboveICCOpen = (state: State): IssueId | null =>
	state.ui.cards.cardWithIcc.cardIdWithAboveIccOpen ?? null;

export const hasICCOpen = (state: State, cardId: IssueId | undefined): boolean =>
	cardId === getIssueIdWithAboveICCOpen(state);

export const getColumnIdToKeepICCOpen = (state: State): ColumnId | null =>
	state.ui.cards.cardWithIcc.columnIdToKeepIccOpen ?? null;

export const getLastOpenedColumnId = (state: State): ColumnId | null =>
	state.ui.cards.cardWithIcc.lastColumnIdOpen ?? null;

export const keepICCOpenInColumn = (state: State, columnId: ColumnId): boolean =>
	getIssueIdWithAboveICCOpen(state) === null &&
	!hasSwimlanes(state) &&
	getColumnIdToKeepICCOpen(state) === columnId;

export const getSwimlaneIdToKeepICCOpen = (state: State): SwimlaneId | null =>
	state.ui.cards.cardWithIcc.swimlaneIdToKeepIccOpen ?? null;

export const isUnscheduledColumnICCOpen = (
	state: State,
	swimlaneId: SwimlaneId | undefined | null,
	columnId: ColumnId,
): boolean =>
	columnId === UNSCHEDULED_COLUMN_ID &&
	!!swimlaneId &&
	getSwimlaneIdToKeepICCOpen(state) === swimlaneId;

export const draggingCardSourceStatusSelector = createSelector(
	[getCardTransitions, draggingCardIssueStatusId, draggingCardIssueTypeId],
	(cardTransitions, statusId, issueTypeId) => {
		if (isNil(statusId) || isNil(issueTypeId)) {
			return undefined;
		}

		const statusesForIssueType = cardTransitions.issueTypeStatus[issueTypeId];
		return statusesForIssueType && statusesForIssueType[statusId];
	},
);

export const draggingCardTargetColumnIdSelector = createSelector(
	[getCardDragState],
	(cardDrag) => cardDrag.overTransition && cardDrag.overTransition.toColumnId,
);

export const draggingCardHoverIntentSelector = createSelector(
	[getCardDragState],
	(cardDrag) => !!(cardDrag.overTransition && cardDrag.overTransition.hoverIntent),
);

export const selectedIssueKeysSelector = createSelector(
	[getCardSelection, boardIssuesSelector],
	(selectedIssueIds, issuesById) =>
		selectedIssueIds
			.map((issueId) => issuesById[String(issueId)])
			.filter((issue) => !!issue)
			.map((issue) => issue.key),
);

export const selectedIssueIdsSelector = createSelector(
	[getCardSelection],
	(selectedIssueIds) => selectedIssueIds,
);

// This will exclude any selected issues that have children
// when the swimlane mode is SWIMLANE_BY_SUBTASK
export const getVisibleCardSelection = createSelector(
	[getCardSelection, boardIssuesSelector],
	(selectedIssueIds, issuesById) =>
		selectedIssueIds.filter((issueId) => !!issuesById[String(issueId)]),
);

/**
 * On TMP boards, epic lozenges are only shown if the board is not grouped by
 * epic.
 *
 * On CMP boards, epic lozenges are always shown. There's a separate
 * displayOptions setting to hide them.
 */
export const getShouldShowParent = createSelector(
	[getSwimlaneMode, getIsCMPBoard],
	(swimlaneMode, isCMPBoard) => isCMPBoard || swimlaneMode !== SWIMLANE_BY_PARENT_ISSUE.id,
);

export const getCardEditSummary = (state: State) => (cardId: IssueId) =>
	state.ui.cards.inlineEditing[cardId]?.summary;

export const getAnyCardEditingSummary = (state: State) =>
	Object.values(state.ui.cards.inlineEditing).some((editing) => editing.summary);

export const getCardEditAssignee = (state: State) => (cardId: IssueId) =>
	state.ui.cards.inlineEditing[cardId]?.assignee;
