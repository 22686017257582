import type { IssueId } from '@atlassian/jira-software-board-common';

export const SHOW_ASSIGN_ISSUE_PARENT_MODAL =
	'state.actions.issue.assign-issue-parent-modal.SHOW_ASSIGN_ISSUE_PARENT_MODAL' as const;

export type ShowAssignIssueParentModalAction = {
	type: typeof SHOW_ASSIGN_ISSUE_PARENT_MODAL;
	payload: {
		issueIds: IssueId[];
	};
};

export const showAssignIssueParentModal = (
	issueIds: IssueId[],
): ShowAssignIssueParentModalAction => ({
	type: SHOW_ASSIGN_ISSUE_PARENT_MODAL,
	payload: {
		issueIds,
	},
});

export const HIDE_ASSIGN_ISSUE_PARENT_MODAL =
	'state.actions.issue.assign-issue-parent-modal.HIDE_ASSIGN_ISSUE_PARENT_MODAL' as const;

export type HideAssignIssueParentModalAction = {
	type: typeof HIDE_ASSIGN_ISSUE_PARENT_MODAL;
};

export const hideAssignIssueParentModal = (): HideAssignIssueParentModalAction => ({
	type: HIDE_ASSIGN_ISSUE_PARENT_MODAL,
});

export type Action = ShowAssignIssueParentModalAction | HideAssignIssueParentModalAction;
