import type { JiraUserGQL } from '@atlassian/jira-software-board-fetch-scope-critical/src/types';
import type { Person } from '../../model/people/people-types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformPerson = (user: any): Person => {
	const { displayName, accountId } = user;
	return {
		id: accountId,
		displayName,
		avatarUrl: user.avatarUrls && user.avatarUrls['48x48'],
	};
};

export const cardRefreshPersonTransformer = (cardAssignee: JiraUserGQL): Person => {
	const { displayName, avatarUrl, accountId } = cardAssignee;
	return {
		id: accountId,
		displayName,
		avatarUrl,
	};
};
