import type { ReactNode, SyntheticEvent } from 'react';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';
import type { statusCategories } from '@atlassian/jira-common-constants';
import type { DeploymentDetails } from '@atlassian/jira-development-board-dev-info-icon/src/types.tsx';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import type { IssueTypeHierarchyLevelType } from '@atlassian/jira-issue-type-hierarchies';
import type { CustomField } from '@atlassian/jira-platform-card/src/common/ui/custom-fields/card-custom-field/types';
import type { BoardView } from './constants';
import type {
	CARD_DND_TYPE,
	COLUMN_DND_TYPE,
	TRANSITION_ZONE_DND_TYPE,
} from './constants/drag-drop/index.tsx';

export type ISODateTime = string;
export type BoardId = number;
export type ColumnId = number | string;
export type SwimlaneId = string;
export type CardId = number | string;
export type IssueId = number | string;
export type PersonId = string;
export type StatusId = number;
export type TransitionId = number;
export type ProjectId = number;

export type CardKey = string;
export type TransitionKey = string;
export type ProjectKey = string;
export type CardTypeKey = string;

export const ColumnTheme = {
	Default: 'default',
	Warning: 'warning',
	Danger: 'danger',
} as const;

export type ColumnTheme = (typeof ColumnTheme)[keyof typeof ColumnTheme];

// Copied below types from '@atlassian/jira-software-backlog/src/model/types'  Kindly update both place places incase of type updates
export type Mentions = {
	readonly latestUnreadCommentId: number | null;
	readonly hasUnreadIssueDescriptionMention: boolean;
};

export type ColumnThemeProps = {
	background: string;
	headerHover: string;
	btnHover: string;
	btnActive: string;
	btnIconHover: string;
	btnIconActive: string;
	titleEditHover: string;
};

export type ColumnLozengeValues = {
	descriptor: MessageDescriptor;
	values: {
		count: string;
	};
	tooltip: MessageDescriptor;
	type?: 'maximum' | 'minimum';
	busted?: boolean;
};

// Entities
export type Column = {
	id: ColumnId;
	name: string;
	isDone: boolean;
	clearedOutCardsUrl: string | null | undefined;
	// is used to show total number of cards in column header, when filters applied
	totalCardsCount: number;
	isInitial: boolean;
	isUpdating?: boolean;
	lozenges?: (string | ColumnLozengeValues)[];
	theme?: ColumnTheme;
};

export type DevStatusActivity =
	| 'branch'
	| 'commit'
	| 'pullrequest'
	| 'declined'
	| 'merged'
	| 'deployment'
	| 'design';

export type DevStatus = {
	activity: DevStatusActivity;
	count: number;
	deploymentDetails?: DeploymentDetails;
};

export type CardType = {
	description: string;
	iconUrl: string;
	id: CardTypeKey;
	name: string;
	subtask: boolean;
};

export type Priority = {
	name: string;
	iconUrl: string;
};

export type IssueLink = {
	id: string;
	linkTypeId: number;
	sourceId: IssueId;
	destinationId: IssueId;
};

export type Issue = {
	id: IssueId;
	url: string;
	key: string;
	summary: string;
	assigneeAccountId: string | null;
	statusId: StatusId;
	columnId: ColumnId;
	projectId?: number;
	swimlaneId: string | null;
	typeId?: string;
	typeName?: string;
	typeUrl?: string;
	daysInColumn?: number;
	devStatusField?: DevStatus;
	isFlagged?: boolean;
	labels?: string[];
	parentId: IssueId | null;
	estimate?: number | string;

	/**
	 * If defined this will be rendered as the estimation unit on tooltips.
	 *
	 * On JSW CMP this may be an arbitrary string, since custom fields can be
	 * used as the estimation field.
	 */
	estimateUnit?: string | MessageDescriptor;
	priority: Priority | null;
	dueDate: ISODateTime | null;
	numCompleteChildren: number;
	numTotalChildren: number;
	cardColor?: string | null;
	customFields?: CustomField[] | null;
	// Increment Planning board only fields
	hasScenarioChanges?: boolean | null;
	issueLinks?: IssueLink[] | null;
	fixVersions?: number[];
};

export type Person = {
	id: PersonId;
	displayName: string;
	avatarUrl?: string;
};

export type Transition = {
	id: TransitionId;
	sourceColumnId: StatusId;
	destinationColumnId: StatusId;
};

export type Project = {
	id: ProjectId;
	key: string;
	name: string;
};

export type TransitionEntities = Record<TransitionKey, Transition>;

export type ColumnValidation =
	| {
			isValid: true;
			message: null;
	  }
	| {
			isValid: false;
			message: {
				title: string | MessageDescriptor;
				description: string | MessageDescriptor;
			};
	  };

export type ValidateColumnCallback = (columnId: ColumnId, name: string) => ColumnValidation;

export type IssueStatus = {
	id: number;
	name: string;
	category?: statusCategories.StatusCategory;
	isDone?: boolean;
	isInitial?: boolean;
};

export type JiraIssue = {
	status: IssueStatus | null;
};

export type IssueParent = {
	id: IssueId;
	key: string;
	summary: string;
	issueType: {
		iconUrl: string;
		id: CardTypeKey;
		name: string;
		hierarchyLevelType?: IssueTypeHierarchyLevelType;
		description?: string;
	};
	color: Color | undefined;
	status?: IssueStatus;
};

export type RenderCustomButton = (args: {
	isDisabled?: boolean;
	onClick: (event: SyntheticEvent, analyticsEvent: AnalyticsEvent) => void;
	ariaLabel: string;
	testId: string;
}) => ReactNode;

export type DndType = typeof COLUMN_DND_TYPE | typeof CARD_DND_TYPE;

export type DraggableColumnData = {
	type: typeof COLUMN_DND_TYPE;
	columnId: ColumnId;
	columnIndex: number;
	swimlaneId?: SwimlaneId | null;
};

export type DraggableCardData = {
	type: typeof CARD_DND_TYPE;
	cardId: CardId;
	cardIndex: number;
	columnId: ColumnId;
	swimlaneId?: SwimlaneId | null;
};

export type DraggableData = DraggableColumnData | DraggableCardData;

export type DroppableColumnData = DraggableColumnData;

export type DroppableCardData = DraggableCardData;

export type DroppableTransitionZoneData = {
	type: typeof TRANSITION_ZONE_DND_TYPE;
	columnId: ColumnId;
	swimlaneId?: SwimlaneId | null;
	transitionId: number;
};

export type DroppableData = DroppableColumnData | DroppableCardData | DroppableTransitionZoneData;

export type IssueLinkStat = {
	issueLinkCount: number;
};

export type BoardType = (typeof BoardView)[keyof typeof BoardView];
