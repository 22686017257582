import { freeze } from 'icepick';
import {
	BACKLOG_ISSUE_MOVE_SHOW,
	BACKLOG_ISSUE_MOVE_CANCEL,
	BACKLOG_ISSUE_MOVE_CONFIRM,
} from '../../../actions/board/backlog-issue-move';
import type { Action } from '../../../types';
import type { BacklogIssueMoveDialogState } from './types';

const closedState: BacklogIssueMoveDialogState = freeze({ isOpen: false });
const openState: BacklogIssueMoveDialogState = freeze({ isOpen: true });

export const backlogIssueMoveDialogReducer = (
	state: BacklogIssueMoveDialogState = closedState,
	action: Action,
): BacklogIssueMoveDialogState => {
	switch (action.type) {
		case BACKLOG_ISSUE_MOVE_SHOW:
			return openState;
		case BACKLOG_ISSUE_MOVE_CANCEL:
			return closedState;
		case BACKLOG_ISSUE_MOVE_CONFIRM:
			return closedState;
		default:
			return state;
	}
};
