import React from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
// eslint-disable-next-line import/order
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import type { CardLabelProps, ContainerProps } from './types';

export const CardLabel = (props: CardLabelProps) => {
	const { text, highlight } = props;
	const isSelected = !!highlight && highlight.includes(text);

	return (
		<Container isSelected={isSelected}>
			<Content>{text}</Content>
		</Container>
	);
};

CardLabel.defaultProps = {
	highlight: [],
};

export default CardLabel;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const ContainerControl = styled.span<ContainerProps>((props) => ({
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2.5}px`,
	margin: token('space.050', '4px'),
	padding: 0,
	position: 'relative',
	cursor: 'default',
	lineHeight: 1,
	overflow: 'hidden',
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backgroundColor: props.isSelected
		? token('color.background.accent.blue.subtler', '#B3D4FF')
		: token('color.background.neutral', '#F4F5F7'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: props.isSelected
		? token('color.text.accent.blue', '#0747A6')
		: token('color.text', '#172B4D'),
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerExperiment = styled2.span({
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2.5}px`,
	margin: token('space.050', '4px'),
	padding: 0,
	position: 'relative',
	cursor: 'default',
	lineHeight: 1,
	overflow: 'hidden',
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props: ContainerProps) =>
		props.isSelected
			? token('color.background.accent.blue.subtler', '#B3D4FF')
			: token('color.background.neutral', '#F4F5F7'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) =>
		props.isSelected ? token('color.text.accent.blue', '#0747A6') : token('color.text', '#172B4D'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	ContainerExperiment,
	ContainerControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled2.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 22.5}px`,
	padding: `${token('space.025', '2px')} ${token('space.050', '4px')}`,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
