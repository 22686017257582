import { useEffect } from 'react';
import omit from 'lodash/omit';
import { useRouter } from '@atlassian/react-resource-router';
import {
	swimlaneStrategiesToMode,
	type SwimlaneTypeName,
} from '../../services/swimlane/swimlane-data-transformer';
import { useBoardDispatch, useBoardSelector } from '../../state';
import { swimlaneModeChangeRequest } from '../../state/actions/swimlane';
import { getSwimlaneModes } from '../../state/selectors/swimlane/swimlane-mode-selectors';

export const SWIMLANE_MODE_QUERY_KEY = 'groupBy';

export const SwimlaneModeFromUrl = () => {
	const [{ location, query }, { replace }] = useRouter();
	const dispatch = useBoardDispatch();
	const availableSwimlanes = useBoardSelector(getSwimlaneModes);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const queryValue = query[SWIMLANE_MODE_QUERY_KEY] as SwimlaneTypeName;

		if (!queryValue) {
			return;
		}

		const swimlaneModeId = swimlaneStrategiesToMode[queryValue] || null;

		if (availableSwimlanes.find((swimlane) => swimlane.id === swimlaneModeId)) {
			dispatch(swimlaneModeChangeRequest(swimlaneModeId));
		}

		// Remove groupBy from search params
		replace({
			...location,
			search: new URLSearchParams(omit(query, SWIMLANE_MODE_QUERY_KEY)).toString(),
		});
	}, [availableSwimlanes, dispatch, location, query, replace]);

	return null;
};
