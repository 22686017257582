import { connect } from '@atlassian/jira-react-redux';
import { getManageCustomFiltersUrl } from '../../common/urls';
import { CAN_EDIT_BOARD } from '../../model/permission/permission-types';
import { getPermissionsSelector } from '../../state/selectors/board/board-permissions-selectors';
import {
	getIsCMPBoard,
	projectKeySelector,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors';
import type { State } from '../../state/types';
import CustomFilterFailed from './view';

type StateProps = {
	canAccessCustomFilterSettings: boolean;
	customFiltersSettingsUrl: string;
};

const mapStateToProps = (state: State): StateProps => {
	const permissions = getPermissionsSelector(state);
	return {
		canAccessCustomFilterSettings: permissions[CAN_EDIT_BOARD] === true && !getIsCMPBoard(state),
		customFiltersSettingsUrl: getManageCustomFiltersUrl(
			projectKeySelector(state),
			rapidViewIdSelector(state),
		),
	};
};

export default connect(mapStateToProps, {})(CustomFilterFailed);
