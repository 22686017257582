import { boardOrderedIssueIdsSelector } from '../../state/selectors/issue/board-issue-selectors';
import {
	getIsCMPBoard,
	getOrderedColumnIds,
} from '../../state/selectors/software/software-selectors';
import { workFilteredIssuesSelector } from '../../state/selectors/work/work-selectors';
import type { State } from '../../state/types';

export const getBoardExtraData = (state: State) => ({
	issuesCount: boardOrderedIssueIdsSelector(state).length,
	filteredIssuesCount: workFilteredIssuesSelector(state).length,
	columnsCount: getOrderedColumnIds(state).length,
	isCompanyManaged: getIsCMPBoard(state),
});
