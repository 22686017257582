import { chain } from 'icepick';
import isEmpty from 'lodash/isEmpty';
import { fg } from '@atlassian/jira-feature-gating';
import {
	TEXT,
	LABEL,
	ISSUE_PARENT,
	ASSIGNEE,
	ISSUE_PROJECT,
	ISSUE_TYPE,
	REQUEST_TYPE,
	STATUS,
	VERSION,
	STATUS_CATEGORY,
	CUSTOM_FILTER,
	SPRINT,
	DEPENDENCIES,
} from '../common/constants';
import type { Filters, FilterType } from '../common/types';
import { isIncrementPlanningBoardM1Enabled } from '../feature-flags';

export const getNormalizedFilters = (filters: Filters): Filters => {
	const chainedFilters = chain({})
		.setIn([TEXT], filters[TEXT] ? filters[TEXT] : undefined)
		.setIn([LABEL], !isEmpty(filters[LABEL]) ? filters[LABEL] : undefined)
		.setIn([ISSUE_PARENT], !isEmpty(filters[ISSUE_PARENT]) ? filters[ISSUE_PARENT] : undefined)
		.setIn([ASSIGNEE], !isEmpty(filters[ASSIGNEE]) ? filters[ASSIGNEE] : undefined)
		.setIn([ISSUE_TYPE], !isEmpty(filters[ISSUE_TYPE]) ? filters[ISSUE_TYPE] : undefined)
		.setIn([VERSION], !isEmpty(filters[VERSION]) ? filters[VERSION] : undefined)
		.setIn([SPRINT], !isEmpty(filters[SPRINT]) ? filters[SPRINT] : undefined)
		.setIn([REQUEST_TYPE], !isEmpty(filters[REQUEST_TYPE]) ? filters[REQUEST_TYPE] : undefined)
		.setIn([STATUS], !isEmpty(filters[STATUS]) ? filters[STATUS] : undefined)
		.setIn([CUSTOM_FILTER], !isEmpty(filters[CUSTOM_FILTER]) ? filters[CUSTOM_FILTER] : undefined)
		.setIn(
			[STATUS_CATEGORY],
			!isEmpty(filters[STATUS_CATEGORY]) ? filters[STATUS_CATEGORY] : undefined,
		);

	/**
	 * Delete the condition and merge the content in the chain({}) above when removing the isIncrementPlanningBoardM1Enabled FF i.e.
	 * getNormalizedFilters = () => chain({}).setIn()...setIn([ISSUE_PROJECT])...setIn().value();
	 */
	if (isIncrementPlanningBoardM1Enabled()) {
		chainedFilters.setIn(
			[ISSUE_PROJECT],
			!isEmpty(filters[ISSUE_PROJECT]) ? filters[ISSUE_PROJECT] : undefined,
		);
	}

	if (fg('dependency_visualisation_program_board_fe_and_be')) {
		chainedFilters.setIn(
			[DEPENDENCIES],
			!isEmpty(filters[DEPENDENCIES]) ? filters[DEPENDENCIES] : undefined,
		);
	}

	return chainedFilters.value();
};

export const onFilterChangeStartInteraction = (
	filterType: FilterType,
	onChangeInteractionStart: () => void,
	onCustomFilterChangeInteractionStart: () => void,
) => {
	if (filterType === CUSTOM_FILTER) {
		onCustomFilterChangeInteractionStart();
	} else {
		onChangeInteractionStart();
	}
};
