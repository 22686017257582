import {
	type UseStore,
	get as idbGet,
	set as idbSet,
	update,
	del,
	delMany,
	createStore,
} from 'idb-keyval';
import type { AsyncStorage } from '../../types';

export const prefixKey = (prefix: string, key?: string) =>
	`${prefix.replace(/_*$/, '')}__${key || ''}`;
const createCustomStore = () => createStore('keyval-store', 'keyval');
let customStore: UseStore | undefined;
const getStore = () => {
	let store: UseStore | undefined = customStore;
	if (!store) {
		customStore = createCustomStore();
		store = customStore;
	}
	return store;
};
/**
 * Provides a robust storage solution with optimized data retrieval and management capabilities.
 * It simplifies interactions with AsyncStorage by introducing a namespaced key system for better organization and efficiency.
 * Notably, it innovates on the 'clearAll' operation by maintaining a registry of keys, significantly reducing performance overhead.
 */
export const createStorageProvider = (appPrefix: string): AsyncStorage => {
	const KEYS_STORE_PREFIX = prefixKey(appPrefix);
	const prefix = (key: string) => prefixKey(appPrefix, key);

	// Runtime check to ensure a key is provided as the prefix without a key
	// holds an array of all the keys that are prefixed by this store
	const assertKey = (key?: string) => {
		if (!key) {
			throw Error('You must provide a key');
		}
	};

	// Calling `clearAll` would require us to iterate over every key in the
	// indexed-db, filtering by this instance's prefix and subsequently deleting
	// all the filtered keys. As indexed-db gains traction within Jira, this
	// will quickly become an expensive operation. To counteract this, we
	// instead store all the keys that this instance has created so that when it
	// comes time to clear them all, we simply just have to clear every key in
	// this store. This removes the need for iterating and filtering all
	// together.
	const updateStoreKeys = async (key: string, remove = false) =>
		update<string[]>(
			KEYS_STORE_PREFIX,
			(currentKeys) => {
				const storeKeys = new Set(currentKeys);
				if (remove) {
					storeKeys.delete(key);
					return Array.from(storeKeys.values());
				}
				storeKeys.add(key);
				return Array.from(storeKeys.values());
			},
			getStore(),
		);
	return {
		async get<T>(key: string): Promise<T | undefined> {
			assertKey(key);
			return idbGet(prefix(key), getStore());
		},
		async remove(key: string): Promise<void> {
			assertKey(key);
			const prefixedKey = prefix(key);
			await del(prefixedKey, getStore());
			return updateStoreKeys(prefixedKey, true);
		},
		async clearAll(): Promise<void> {
			const prefixedKeys = await idbGet<string[]>(KEYS_STORE_PREFIX, getStore());
			if (!prefixedKeys || prefixedKeys.length === 0) {
				return undefined;
			}
			return delMany(prefixedKeys.concat(KEYS_STORE_PREFIX));
		},
		async set<T>(key: string, value?: T | ((oldValue: T | undefined) => T)): Promise<void> {
			assertKey(key);
			const prefixedKey = prefix(key);
			if (typeof value === 'function') {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				update(prefixedKey, value as (oldValue: any) => any);
			} else {
				idbSet(prefixedKey, value, getStore());
			}
			return updateStoreKeys(prefixedKey);
		},
		async reconnect(): Promise<void> {
			customStore = createCustomStore();
		},
	};
};
/**
 * Enables 1 retry when an error occurs for get, set, remove, clearAll
 * This is generally helpful to recover from indexed-db connection being closed after the computer wakes up from sleep
 * @param storageProvider
 * @returns
 */
export const withAutoReconnect = (storageProvider: AsyncStorage): AsyncStorage => ({
	async get(...args) {
		try {
			return await storageProvider.get(...args);
		} catch (e) {
			if (e instanceof Error) {
				await storageProvider.reconnect?.();
				return storageProvider.get(...args);
			}
			throw e;
		}
	},
	async set(...args) {
		try {
			return await storageProvider.set(...args);
		} catch (e) {
			if (e instanceof Error) {
				await storageProvider.reconnect?.();
				return storageProvider.set(...args);
			}
			throw e;
		}
	},
	async remove(...args) {
		try {
			return await storageProvider.remove(...args);
		} catch (e) {
			if (e instanceof Error) {
				await storageProvider.reconnect?.();
				return storageProvider.remove(...args);
			}
			throw e;
		}
	},
	async clearAll(...args) {
		try {
			return await storageProvider.clearAll(...args);
		} catch (e) {
			if (e instanceof Error) {
				await storageProvider.reconnect?.();
				return storageProvider.clearAll(...args);
			}
			throw e;
		}
	},
	async reconnect() {
		await storageProvider.reconnect?.();
	},
});
