import { splice, push } from 'icepick';
import uniq from 'lodash/uniq';
import {
	CARD_TOGGLE_SELECTION,
	CARD_SINGLE_SELECT,
	CARD_CANCEL_SELECTION,
	CARD_MULTI_SELECT,
} from '../../../../actions/card';
import { SWIMLANE_MODE_CHANGE_REQUEST } from '../../../../actions/swimlane';
import type { Action } from '../../../../types';
import type { SelectedState } from './types';

const initialState: Array<number> = [];

export const cardSelectionReducer = (
	state: SelectedState = initialState,
	action: Action,
): SelectedState => {
	if (action.type === CARD_TOGGLE_SELECTION) {
		const { issueId } = action.payload;
		const selectedCardIndex = state.indexOf(action.payload.issueId);

		return selectedCardIndex === -1 ? push(state, issueId) : splice(state, selectedCardIndex, 1);
	}

	if (action.type === CARD_MULTI_SELECT) {
		const { issueIds } = action.payload;
		return uniq(state.concat(issueIds));
	}

	if (action.type === CARD_SINGLE_SELECT) {
		const { issueId } = action.payload;
		return [issueId];
	}

	if (action.type === SWIMLANE_MODE_CHANGE_REQUEST) {
		return initialState;
	}

	if (action.type === CARD_CANCEL_SELECTION) {
		return initialState;
	}

	return state;
};
