import type { BoardPermissions as PermissionsState } from '../../../../../model/board/board-types.tsx';
import { BOARD_DEFERRED_DATA_LOAD_SUCCESS } from '../../../../actions/board/board-deferred-data';
import { WORK_DATA_CRITICAL_SET, WORK_DATA_SET } from '../../../../actions/work';
import type { Action } from '../../../../types';

export const initialState: PermissionsState = {
	createIssue: false,
	deleteIssue: false,
	editIssue: false,
	manageSprint: false,
	editBoardConfig: false,
	manageAutomation: false,
	canRelease: false,
	createSprint: false,
	archiveIssue: false,
};

export const permissionsReducer = (
	state: PermissionsState = initialState,
	action: Action,
): PermissionsState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		return action.payload.boardPermissions;
	}

	if (action.type === BOARD_DEFERRED_DATA_LOAD_SUCCESS && action.payload.sprints) {
		return {
			...state,
			manageSprint: action.payload.sprints.some((sprint) => sprint.canUpdateSprint),
		};
	}

	return state;
};
