import { type SetIssuesNotOnBoardAction, SET_ISSUES_NOT_ON_BOARD } from '../../../../actions/issue';
import type { Action } from '../../../../types';
import type { IssuesNotOnBoardState } from './types';

export const issuesNotOnBoardReducer = (
	state: IssuesNotOnBoardState = {},
	action: Action,
): IssuesNotOnBoardState => {
	if (action.type === SET_ISSUES_NOT_ON_BOARD) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { payload } = action as SetIssuesNotOnBoardAction;
		return payload;
	}
	return state;
};
