import type { SprintId } from '../../../../model/sprint/sprint-types';
import {
	type OptimisticUiMeta,
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiWithFlagMeta,
} from '../../meta/optimistic-ui-meta';

export const EDIT_SPRINT_REQUEST = 'state.actions.sprints.edit.EDIT_SPRINT_REQUEST' as const;

export type EditSprintRequestAction = {
	type: typeof EDIT_SPRINT_REQUEST;
	payload: {
		id: SprintId;
		name: string;
		goal: string;
		startDate: string | null;
		endDate: string | null;
	};
	meta: OptimisticUiMeta;
};

export const editSprintRequest = (
	id: SprintId,
	name: string,
	goal: string,
	startDate: string | null,
	endDate: string | null,
): EditSprintRequestAction => ({
	type: EDIT_SPRINT_REQUEST,
	payload: {
		id,
		name,
		goal,
		startDate,
		endDate,
	},
	meta: beginOptimisticUiMeta(),
});

export const EDIT_SPRINT_SUCCESS = 'state.actions.sprints.edit.EDIT_SPRINT_SUCCESS' as const;

export type EditSprintSuccessAction = {
	type: typeof EDIT_SPRINT_SUCCESS;
	payload: {
		id: number;
		daysRemaining: number;
	};
	meta: OptimisticUiMeta;
};

export const editSprintSuccess = (
	txId: string,
	id: number,
	daysRemaining: number,
): EditSprintSuccessAction => ({
	type: EDIT_SPRINT_SUCCESS,
	payload: { id, daysRemaining },
	meta: commitOptimisticUiMeta(txId),
});

export const EDIT_SPRINT_FAILURE = 'state.actions.sprints.edit.EDIT_SPRINT_FAILURE' as const;

export type EditSprintFailureAction = {
	type: typeof EDIT_SPRINT_FAILURE;
	meta: OptimisticUiMeta;
};

export const editSprintFailure = (txId: string): EditSprintFailureAction => ({
	type: EDIT_SPRINT_FAILURE,
	meta: revertOptimisticUiWithFlagMeta(txId),
});

export type Action = EditSprintRequestAction | EditSprintSuccessAction | EditSprintFailureAction;
