import get from 'lodash/get';
import { graphql, commitMutation } from 'react-relay';
import { Observable as ObservableType } from 'rxjs/Observable';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment';
import type {
	boardCardMoveGraphqlMutation,
	boardCardMoveGraphqlMutation$data,
	BoardCardMoveInput,
} from '@atlassian/jira-relay/src/__generated__/boardCardMoveGraphqlMutation.graphql';
import { getStatusCodeFromGraphQLErrors } from '@atlassian/jira-software-sla-tracker/src/services/status-code/index.tsx';

const commitMutationPromise = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	environment: any,
	config: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		mutation: any;
		variables: {
			input: BoardCardMoveInput;
		};
	},
): Promise<boardCardMoveGraphqlMutation$data> =>
	new Promise(
		(
			resolve: (result: boardCardMoveGraphqlMutation$data) => void,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			reject: (error?: any) => void,
		) => {
			commitMutation<boardCardMoveGraphqlMutation>(environment, {
				...config,
				onCompleted: (payload, errors) => {
					if (errors && errors.length) {
						const statusCode = getStatusCodeFromGraphQLErrors(errors);
						if (statusCode == null) {
							reject(errors);
							return;
						}

						reject(new FetchError(statusCode, ''));
						return;
					}

					if (!get(payload, 'boardCardMove.issuesWereTransitioned')) {
						/**
						 * reponse may return 200 with issuesWereTransitioned value of false or null.
						 * It indicates a card move failure due to permission limit, not an server error.
						 * an example would be a board viewer (no transit/rank permission) is trying to move a card to an empty column in a private/limited board.
						 */
						reject(new FetchError(400, 'Either transition or rank operation has failed'));
					}

					resolve(payload);
				},
				onError: (error: Error) => {
					reject(error);
				},
			});
		},
	);

export const fetchBoardCardMoveData = (
	boardCardMoveInput: BoardCardMoveInput,
): ObservableType<boardCardMoveGraphqlMutation$data> =>
	ObservableType.fromPromise(
		commitMutationPromise(getRelayEnvironment(), {
			mutation: graphql`
				mutation boardCardMoveGraphqlMutation($input: BoardCardMoveInput) {
					boardCardMove(input: $input) {
						clientMutationId
						statusCode
						issuesWereTransitioned
						success
						message
					}
				}
			`,
			variables: { input: boardCardMoveInput },
		}),
	);
