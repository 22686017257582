import { useCallback, useEffect, useState } from 'react';
import { useBoardDispatch } from '../../../state';
import {
	fieldUpdateFailure,
	fieldUpdateOptimistic,
	fieldUpdateSuccess,
} from '../../../state/actions/issue/field-update';
import type { EditableFieldProps } from './types';
import { getUpdateFieldExperience } from './utils';

export function useEditableField({
	isExperienceAvailable,
}: {
	isExperienceAvailable: boolean;
}): EditableFieldProps {
	const [experience] = useState(getUpdateFieldExperience());

	useEffect(() => {
		if (isExperienceAvailable) {
			experience.start();
		}
	}, [experience, isExperienceAvailable]);

	const dispatch = useBoardDispatch();
	const onSubmit = useCallback(() => {
		dispatch(fieldUpdateOptimistic());
	}, [dispatch]);

	const onUpdate = useCallback(() => {
		dispatch(fieldUpdateSuccess());
		experience.success();
	}, [dispatch, experience]);

	const onFailure = useCallback(() => {
		dispatch(fieldUpdateFailure());
		experience.failure();
	}, [dispatch, experience]);

	/**
	 * onFailure will be due to an error from backend
	 * onError will be due to an error in frontend, so we dont want to rollback
	 * an update that succeeded in the backend (which we would do in the onFailure)
	 */
	const onError = useCallback(() => {
		experience.failure();
	}, [experience]);

	return {
		onSubmit,
		onUpdate,
		onFailure,
		onError,
	};
}
