import React, { useCallback } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { BoardInsightsButton } from '@atlassian/jira-insights-board-button/src/ui/index.tsx';
import { KanbanBoardInsightsButton } from '@atlassian/jira-insights-board-button/src/ui/kanban-button/index.tsx';
import { useIsCurrentBrowserSupported } from '@atlassian/jira-insights-common/src/controllers/use-is-current-browser-supported/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { ServicedeskBoardInsightsButton } from '@atlassian/jira-servicedesk-board-button/src/ui/index.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import { Capability } from '../../../common/capability';
import { INSIGHTS_PANEL } from '../../../model/panels/constants';
import type { PanelType } from '../../../model/panels/types';
import { useBoardActionCreator } from '../../../state';
import { closeViewSettingsPanel, type TogglePanelAction } from '../../../state/actions/panels';
import { useIsCMPBoard, useCapability } from '../../../state/state-hooks/capabilities';

type Props = {
	isInsightsPanelOpen: boolean;
	boardId: number;
	edition: ApplicationEdition | null;
	onToggleInsightsPanel: (arg1: PanelType) => TogglePanelAction;
	isSprintsEnabled: boolean;
	isHeaderCompactMode?: boolean;
};

const InsightsButtonSofwareOnly = (props: Props) => {
	const isSupported = useIsCurrentBrowserSupported();
	const isVisible = useCapability(Capability.RENDER_BOARD_INSIGHT_BUTTON);

	const closeViewSettingsPanelAction = useBoardActionCreator(() => closeViewSettingsPanel());

	const {
		isInsightsPanelOpen,
		onToggleInsightsPanel,
		boardId,
		edition,
		isSprintsEnabled,
		isHeaderCompactMode,
	} = props;

	const isCMPBoard = useIsCMPBoard();
	const onToggleInsightsPanelHandler = useCallback(() => {
		onToggleInsightsPanel(INSIGHTS_PANEL);
		closeViewSettingsPanelAction();
	}, [closeViewSettingsPanelAction, onToggleInsightsPanel]);

	if (!isSupported || !isVisible) {
		return null;
	}

	// this is only because of flow, it will never happen
	if (!edition) {
		return null;
	}

	if (!isSprintsEnabled) {
		return (
			<KanbanBoardInsightsButton
				isSimplifiedProject={!isCMPBoard}
				isOpen={isInsightsPanelOpen}
				isCompact={isHeaderCompactMode}
				boardId={Number(boardId)}
				edition={edition}
				onToggle={onToggleInsightsPanelHandler}
			/>
		);
	}

	if (isSprintsEnabled) {
		return (
			<BoardInsightsButton
				isSimplifiedProject={!isCMPBoard}
				isOpen={isInsightsPanelOpen}
				isCompact={isHeaderCompactMode}
				boardId={Number(boardId)}
				edition={edition}
				onToggle={onToggleInsightsPanelHandler}
			/>
		);
	}

	return null;
};

const InsightsButtonCommon = (props: Props) => {
	const isSupported = useIsCurrentBrowserSupported();
	const isVisibleForSoftware = useCapability(Capability.RENDER_BOARD_INSIGHT_BUTTON);
	const isVisibleForServiceDesk = useCapability(Capability.RENDER_JSM_BOARD_INSIGHT_BUTTON);
	const { data: project } = useProjectContext();

	const closeViewSettingsPanelAction = useBoardActionCreator(() => closeViewSettingsPanel());

	const {
		isInsightsPanelOpen,
		onToggleInsightsPanel,
		boardId,
		edition,
		isSprintsEnabled,
		isHeaderCompactMode,
	} = props;

	const isCMPBoard = useIsCMPBoard();
	const onToggleInsightsPanelHandler = useCallback(() => {
		onToggleInsightsPanel(INSIGHTS_PANEL);
		closeViewSettingsPanelAction();
	}, [closeViewSettingsPanelAction, onToggleInsightsPanel]);

	if (!isSupported) {
		return null;
	}

	// this is only because of flow, it will never happen
	if (!edition) {
		return null;
	}

	if (isVisibleForServiceDesk) {
		return (
			<ServicedeskBoardInsightsButton
				isSimplifiedProject={!isCMPBoard}
				isOpen={isInsightsPanelOpen}
				boardId={Number(boardId)}
				edition={edition}
				projectId={project?.projectId}
				onToggle={onToggleInsightsPanelHandler}
			/>
		);
	}

	if (isVisibleForSoftware) {
		if (isSprintsEnabled) {
			return (
				<BoardInsightsButton
					isSimplifiedProject={!isCMPBoard}
					isOpen={isInsightsPanelOpen}
					isCompact={isHeaderCompactMode}
					boardId={Number(boardId)}
					edition={edition}
					onToggle={onToggleInsightsPanelHandler}
				/>
			);
		}
		return (
			<KanbanBoardInsightsButton
				isSimplifiedProject={!isCMPBoard}
				isOpen={isInsightsPanelOpen}
				isCompact={isHeaderCompactMode}
				boardId={Number(boardId)}
				edition={edition}
				onToggle={onToggleInsightsPanelHandler}
			/>
		);
	}

	return null;
};

export const InsightsButton = (props: Props) =>
	ff('jsm-board-insights-issues-for-attention_erwbg') ? (
		<InsightsButtonCommon {...props} />
	) : (
		<InsightsButtonSofwareOnly {...props} />
	);
