import { createSelector } from 'reselect';
import { UNASSIGNED_USER_ID } from '@atlassian/jira-software-filters/src/common/constants.tsx';
import { ASSIGNEE } from '../../../model/filter/filter-types';
import { SWIMLANE_BY_SUBTASK } from '../../../model/swimlane/swimlane-modes';
import { getIssueChildren } from '../issue-children';
import { filter } from '../issue/card-filter';
import { getCurrentUserAccountId, getPeople } from '../people/people-selectors';
import { getIssues } from '../software/software-selectors';
import { getSwimlaneMode } from '../swimlane/swimlane-mode-selectors';
import { getWorkFilters, workIssuesSelector, getFilteredCardIds } from '../work/work-selectors';

export const unassignedWorkFilterSelector = createSelector(
	[getWorkFilters, getCurrentUserAccountId],
	(workFilters, accountId) => ({
		...workFilters.values,
		[ASSIGNEE]: [UNASSIGNED_USER_ID, accountId],
	}),
);

export const unassignedWorkFilterIssuesSelector = createSelector(
	[unassignedWorkFilterSelector, workIssuesSelector, getIssues, getPeople, getFilteredCardIds],
	(unassignedFilter, issues, issueHash, people, filteredCardIds) =>
		filter(unassignedFilter, issues, issueHash, people, filteredCardIds || undefined),
);

/**
 * Returns true if the user has any in-progress work
 */
export const userHasIncompleteAssignedIssuesSelector = createSelector(
	[workIssuesSelector, getIssueChildren, getCurrentUserAccountId, getSwimlaneMode],
	(issues, childrenIssue, accountId, swimlaneMode) => {
		if (swimlaneMode !== SWIMLANE_BY_SUBTASK.id) {
			return issues.some((issue) => !issue.isDone && issue.assigneeAccountId === accountId);
		}

		return [...issues, ...Object.values(childrenIssue)].some(
			(issue) => !issue.isDone && issue.assigneeAccountId === accountId,
		);
	},
);

/**
 * Returns true if the board has available work once the CTA filter is applied
 */
export const boardHasIncompleteUnassignedWorkSelector = createSelector(
	[unassignedWorkFilterIssuesSelector],
	(issues) =>
		issues.some(
			(issue) =>
				!issue.isDone &&
				(issue.assigneeAccountId === undefined || issue.assigneeAccountId === null),
		),
);

export const getIsAssigneeFilterUnsetOrSetToCurrentUser = createSelector(
	[getWorkFilters, getCurrentUserAccountId],
	(workFilters, accountId) =>
		!workFilters.values[ASSIGNEE] ||
		(workFilters.values[ASSIGNEE].length === 1 && workFilters.values[ASSIGNEE][0] === accountId),
);

export const shouldShowUnassignedFilterCTASelector = createSelector(
	[
		userHasIncompleteAssignedIssuesSelector,
		boardHasIncompleteUnassignedWorkSelector,
		getIsAssigneeFilterUnsetOrSetToCurrentUser,
	],
	(userHasIncompleteAssignedIssues, boardHasIncompleteUnassignedWork, userFilterAppliedSelector) =>
		!userHasIncompleteAssignedIssues &&
		boardHasIncompleteUnassignedWork &&
		userFilterAppliedSelector,
);
