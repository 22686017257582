import { useCallback } from 'react';
import { useService } from '@atlassian/jira-common-services/src/use-service';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { usePathParam } from '@atlassian/react-resource-router';
import type { CreationIncrementPayload } from './types';

export const useIncrementCreationService = () => {
	const [planId] = usePathParam('planId');

	const request = useCallback(
		(requestData: CreationIncrementPayload) =>
			performPostRequest(`/rest/jpo/2.0/plans/${planId}/increments`, {
				body: JSON.stringify(requestData),
			}),
		[planId],
	);

	const { loading, error, data, fetch } = useService(request);

	return {
		saving: loading,
		error,
		data,
		save: fetch,
	};
};
