import { PERSON_SET } from '../../../../actions/people';
import type { Action } from '../../../../types';
import type { IsCurrentUserLoadedState } from './types';

export const isCurrentUserLoadedReducer = (
	state: IsCurrentUserLoadedState = false,
	action: Action,
): IsCurrentUserLoadedState => {
	switch (action.type) {
		case PERSON_SET:
			return true;
		default: {
			// @ts-expect-error - TS2322: Type 'ShowErrorFlagAction | AddToSprintRequestAction | AddToSprintSuccessAction | AddToSprintFailureAction | ... 256 more ... | UnsupportedAction' is not assignable to type 'never'.
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
