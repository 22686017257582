import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	addIncrementTitle: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.add-increment-title',
		defaultMessage: 'Create a program board',
		description: 'Title of the create increment modal.',
	},
	addIncrementDescription: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.add-increment-description',
		defaultMessage:
			'Schedule work for the next quarter into six iterations, each spanning two weeks. ',
		description: 'Description of the create increment modal.',
	},
	addIncrementDescriptionNew: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.add-increment-description-new',
		defaultMessage: 'Schedule work across multiple teams and iterations for the next quarter. ',
		description: 'Description of the create increment modal.',
	},
	doclink: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.doclink',
		defaultMessage: 'See how to configure your program board',
		description: 'Label for the learn more link',
	},
	name: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.name',
		defaultMessage: 'Name of your board (required)',
		description: 'Label for the required increment name field',
	},
	nameShort: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.name-short',
		defaultMessage: 'Board name (required)',
		description: 'Label for the required increment name field',
	},
	startDate: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.start-date',
		defaultMessage: 'Start date (required)',
		description: 'Label for the required increment start date field',
	},
	startDateShort: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.start-date-short',
		defaultMessage: 'Start date',
		description: 'Label for the required increment start date field',
	},
	namePlaceholder: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.name-placeholder',
		defaultMessage: 'e.g.Q1FY24',
		description: 'Placeholder for the name field',
	},
	submit: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.submit',
		defaultMessage: 'Create',
		description: 'Label for the submit button',
	},
	cancel: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.cancel',
		defaultMessage: 'Cancel',
		description: 'Label for the cancel button',
	},
	errorLabel: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.error-label',
		defaultMessage: 'We couldn’t create your board. Refresh the page and try again. ',
		description: 'ARIA label for an error icon.',
	},
	closeModal: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.close-modal',
		defaultMessage: 'Close modal',
		description: 'Accessible label for the close modal button',
	},
	customFieldSettingHeading: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.custom-field-setting-heading',
		defaultMessage: 'What issues do you want to include?',
		description: 'Heading for the custom field setting',
	},
	customFieldSettingDescription: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.custom-field-setting-description',
		defaultMessage:
			'Select a field to filter which issues are added to your program board. If you choose none, all issues with statuses in the <bold>To do </bold> or <bold>In progress</bold> categories will show on your board. ',
		description: 'Description for the custom field setting',
	},
	customFieldSettingFieldLabel: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.custom-field-setting-field-label',
		defaultMessage: 'Field',
		description: 'Label for the custom field',
	},
	customFieldSettingFieldPlaceholder: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.custom-field-setting-field-placeholder',
		defaultMessage: 'Select field',
		description: 'Placeholder for the custom field',
	},
	scopeFilterHeading: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.scope-filter-heading',
		defaultMessage: 'What issues do you want to include?',
		description: 'Heading for the program board scope filter setting',
	},
	scopeFilterHeadingIssueTermRefresh: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.scope-filter-heading-issue-term-refresh',
		defaultMessage: 'What issues do you want to include?',
		description: 'Heading for the program board scope filter setting',
	},
	scopeFilterDescription: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.scope-filter-description',
		defaultMessage:
			'Select a field to filter which issues are added to your program board. If you choose none, all issues with statuses in the <bold>To do </bold> or <bold>In progress</bold> categories will show on your board. ',
		description: 'Description for the program board scope filter setting',
	},
	scopeFilterDescriptionIssueTermRefresh: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.scope-filter-description-issue-term-refresh',
		defaultMessage:
			'Select a field to filter which issues are added to your program board. If you choose none, all issues with statuses in the <bold>To do </bold> or <bold>In progress</bold> categories will show on your board. ',
		description: 'Description for the program board scope filter setting',
	},
	scopeFilterFieldLabel: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.scope-filter-field-label',
		defaultMessage: 'Field',
		description: 'Label for the program board scope filter field',
	},
	scopeFilterFieldPlaceholder: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.scope-filter-field-placeholder',
		defaultMessage: 'Select field',
		description: 'Placeholder for the program board scope filter field',
	},
	nameEmptyError: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.name-empty-error',
		defaultMessage: 'Name is required',
		description: 'Error for the empty name',
	},
	startDateEmptyError: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.start-date-empty-error',
		defaultMessage: 'Start date is required',
		description: 'Error for the empty start Date',
	},
	fieldValueEmptyError: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.field-value-empty-error',
		defaultMessage: 'Field value is required when you select Field',
		description: 'Error for the empty field value',
	},
	iterationLength: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.iteration-length',
		defaultMessage: 'Column duration',
		description: 'Label for the iteration length',
	},
	iterationsCount: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.iterations-count',
		defaultMessage: 'Number of columns',
		description: 'Label for the iterations count',
	},
	customFieldSettingHeadingIssueTermRefresh: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.custom-field-setting-heading-issue-term-refresh',
		defaultMessage: 'What issues do you want to include?',
		description: 'Heading for the custom field setting',
	},
	customFieldSettingDescriptionIssueTermRefresh: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.custom-field-setting-description-issue-term-refresh',
		defaultMessage:
			'Select a field to filter which issues are added to your program board. If you choose none, all issues with statuses in the <bold>To do </bold> or <bold>In progress</bold> categories will show on your board. ',
		description: 'Description for the custom field setting',
	},
});
export default messages;
