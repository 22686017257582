import { useCallback } from 'react';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useGlobalSearch } from '@atlassian/jira-software-global-search-tracker';

type Props = {
	isAnyModalOpen?: boolean;
	onFocusSearch: () => void;
};

const HijackBrowserDefaultSearch = ({ onFocusSearch, isAnyModalOpen = false }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onGlobalSearch = useCallback(
		// @ts-expect-error - TS7031 - Binding element 'event' implicitly has an 'any' type.
		({ event }) => {
			// Do not hijack browser default search when any modal is open
			if (isAnyModalOpen) {
				return;
			}
			// Hijacks default browser search and directs user to search in filter bar
			event.preventDefault();
			onFocusSearch();

			fireTrackAnalytics(createAnalyticsEvent({}), 'hijackBrowserDefaultSearch triggered');
		},
		[createAnalyticsEvent, isAnyModalOpen, onFocusSearch],
	);

	useGlobalSearch(onGlobalSearch);

	return null;
};

export default HijackBrowserDefaultSearch;
