import type { IssueId } from '@atlassian/jira-software-board-common';
import type { ColumnId } from '../../../../model/column/column-types';
import type { SwimlaneId } from '../../../../model/swimlane/swimlane-types';
import flagMessages from '../../../../view/flags/messages';
import { errorFlagMeta, type FlagMeta } from '../../meta/flag-meta';

export const ISSUE_RANK_TEAM_DATE_REQUEST =
	'state.actions.issue.rank-team-date.ISSUE_RANK_TEAM_DATE_REQUEST' as const;

type IssueRankTeamDateRequestPayload = {
	issueIds: IssueId[];
	sourceColumnId: ColumnId;
	destinationColumnId: ColumnId;
	rankBeforeIssueId: IssueId | null | undefined;
	rankAfterIssueId: IssueId | null | undefined;
	sourceSwimlaneId: SwimlaneId | null | undefined;
	destinationSwimlaneId: SwimlaneId | null | undefined;
};

export type IssueRankTeamDateRequestAction = {
	type: typeof ISSUE_RANK_TEAM_DATE_REQUEST;
	payload: IssueRankTeamDateRequestPayload;
};

export const issueRankTeamDateRequest = ({
	issueIds,
	sourceColumnId,
	destinationColumnId,
	rankBeforeIssueId,
	rankAfterIssueId,
	sourceSwimlaneId,
	destinationSwimlaneId,
}: IssueRankTeamDateRequestPayload): IssueRankTeamDateRequestAction => ({
	type: ISSUE_RANK_TEAM_DATE_REQUEST,
	payload: {
		issueIds,
		sourceColumnId,
		destinationColumnId,
		rankBeforeIssueId,
		rankAfterIssueId,
		sourceSwimlaneId,
		destinationSwimlaneId,
	},
});

export const ISSUE_TEAM_DATE_RANK_SUCCESS =
	'state.actions.issue.rank-team-date.ISSUE_TEAM_DATE_RANK_SUCCESS' as const;

export type IssueTeamDateRankSuccessAction = {
	type: typeof ISSUE_TEAM_DATE_RANK_SUCCESS;
};

export const issueTeamDateRankSuccess = (): IssueTeamDateRankSuccessAction => ({
	type: ISSUE_TEAM_DATE_RANK_SUCCESS,
});

export const ISSUE_TEAM_DATE_RANK_FAILURE =
	'state.actions.issue.rank-team-date.ISSUE_TEAM_DATE_RANK_FAILURE' as const;

export type IssueTeamDateRankFailureAction = {
	type: typeof ISSUE_TEAM_DATE_RANK_FAILURE;
	meta: FlagMeta;
};

export const issueTeamDateRankFailure = (): IssueTeamDateRankFailureAction => ({
	type: ISSUE_TEAM_DATE_RANK_FAILURE,
	meta: errorFlagMeta({
		titleMessage: flagMessages.cardMoveFailureInIpBoardTitleNoTranslate,
		descriptionMessage: flagMessages.cardMoveFailureInIpBoardMessageNoTranslate,
	}),
});

export type Action =
	| IssueRankTeamDateRequestAction
	| IssueTeamDateRankFailureAction
	| IssueTeamDateRankSuccessAction;
