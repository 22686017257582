import React, { useEffect, useCallback } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import { getLongTasksMetrics } from '@atlassian/jira-common-long-task-metrics';
import { bentoReporter } from '@atlassian/jira-common-long-task-metrics/src/reporters/software-bento';
import { simpleSoftwareBoardConfiguration } from '@atlassian/jira-issue-view-configurations';
import type { ChangeEvent } from '@atlassian/jira-issue-view-model/src/change-type';
import type { ExternalAction } from '@atlassian/jira-issue-view-store/src/actions/external-actions';
import { IssueBoundary } from '@atlassian/jira-issue-view/src/async.tsx';
import type IssueAppType from '@atlassian/jira-issue-view/src/views/issue-details/issue-app';
import { SCREEN } from '@atlassian/jira-product-analytics-bridge';
import type { IssueId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { EstimationStatisticFieldId } from '../../../model/work/work-types';
import type { BoardCardCount } from '../../../services/performance/board-data-collector';
import {
	setIssueViewOpenFeedbackMark,
	setIssueViewOpenFinishMark,
} from '../../../services/performance/issue-view-metrics';
import { PreIssueViewMountFeedbackMark } from '../../metrics/issue-view';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const IssueApp = lazyForPaint<typeof IssueAppType>(
	() =>
		import(
			/* webpackChunkName: "async-issue-app" */ '@atlassian/jira-issue-view/src/views/issue-details/issue-app'
		),
	{ ssr: false },
);

const NEXT_GEN_BOARD_ANALYTICS_SOURCE = 'simple-software-board';

export type OwnProps = {
	issueKey: IssueKey;
	rapidViewId: number;
	externalAction: ExternalAction | undefined;
	boardSessionId: string;
	estimationStatistic: EstimationStatisticFieldId;
	onSwitchToModal: () => void;
	onIssueKeyChange: (arg1: {
		fromIssueKey: IssueKey;
		toIssueKey: IssueKey;
		meta: {
			location: string;
		};
	}) => void;
	onIssueDeleteSuccess: (arg1: { issueId: IssueId; issueKey: string }) => void;
	onChange: (payload: ChangeEvent) => void;
	onClose: () => void;
	onResetExternalAction: () => void;
};

export type StateProps = {
	boardCardCount: BoardCardCount;
	isCMPBoard: boolean;
};

export type Props = OwnProps & StateProps;

const IssueWrapper = ({
	issueKey,
	externalAction,
	rapidViewId,
	boardSessionId,
	boardCardCount,
	estimationStatistic,
	onResetExternalAction,
	onChange,
	onClose,
	onSwitchToModal,
	onIssueDeleteSuccess,
	onIssueKeyChange,
	isCMPBoard,
}: Props) => {
	useEffect(() => {
		setIssueViewOpenFeedbackMark();
	}, [issueKey]);

	const configWithAppKey = simpleSoftwareBoardConfiguration();
	const { applicationKey, ...configWithoutAppKey } = configWithAppKey;

	// If there's an external action to be fired, dispatch it as soon as possible
	const onSetDispatchExternalActionRef = useCallback(
		(dispatchExternalActionRef: ((arg1: ExternalAction) => void) | null) => {
			if (dispatchExternalActionRef && externalAction) {
				// If a keyboard shortcut was the trigger for this external action,
				// the key may propagate into a field that is focused in the
				// issue view. setTimeout prevents this.
				setTimeout(() => {
					externalAction && dispatchExternalActionRef(externalAction);
					// remove optional checking when cleaning up DEE-930
					onResetExternalAction && onResetExternalAction();
				});
			}
		},
		[externalAction, onResetExternalAction],
	);

	return (
		<AnalyticsContext
			data={{
				source: `boardIssueView${SCREEN}`,
				attributes: {
					viewMode: 'SIDEBAR',
				},
			}}
		>
			<Box xcss={issueAppContainerStyles}>
				<PreIssueViewMountFeedbackMark issueKey={issueKey} />
				<IssueBoundary packageName="software-board">
					<IssueApp
						{...configWithoutAppKey}
						analyticsSource={NEXT_GEN_BOARD_ANALYTICS_SOURCE}
						isLoadedWithPage
						issueKey={issueKey}
						onChange={onChange}
						onClose={onClose}
						rapidViewId={rapidViewId}
						viewModeOptions={{
							viewMode: 'SIDEBAR',
							viewModeSwitchEnabled: true,
							onSwitchToModal,
						}}
						onIssueDeleteSuccess={onIssueDeleteSuccess}
						shouldSetInitialFocus
						shouldShowCloseButton
						metrics={{
							onReadyForUser: () => {
								getLongTasksMetrics('bento').stop(bentoReporter, {
									type: 'sidebar',
									boardSessionId,
								});
							},
							onInteractive: () => {
								setIssueViewOpenFinishMark(true, boardCardCount, isCMPBoard);
							},
						}}
						onIssueKeyChange={onIssueKeyChange}
						history={null}
						dispatchExternalActionRef={onSetDispatchExternalActionRef}
						estimationStatistic={estimationStatistic ?? undefined}
					/>
				</IssueBoundary>
			</Box>
		</AnalyticsContext>
	);
};

export default IssueWrapper;

const issueAppContainerStyles = xcss({
	height: '100%',
});
