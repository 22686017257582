import forbidden from './forbidden';
import genericError from './generic-error';
import serverError from './server-error';
import unauthorized from './unauthorized';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	forbidden,
	genericError,
	serverError,
	unauthorized,
};
