import type { IssueId } from '@atlassian/jira-software-board-common';
import type { DevStatusMap } from '../../../../model/issue/issue-types';
import flagMessages from '../../../../view/flags/messages';
import { warningFlagMeta, type FlagMeta } from '../../meta/flag-meta';

export const DEV_STATUS_LOAD = 'state.actions.issue.dev-status.DEV_STATUS_LOAD' as const;

export type DevStatusLoadAction = {
	type: typeof DEV_STATUS_LOAD;
	payload: IssueId[];
};

export const devStatusLoad = (issueIds: IssueId[]): DevStatusLoadAction => ({
	type: DEV_STATUS_LOAD,
	payload: issueIds,
});

export const DEV_STATUS_LOAD_REQUEST =
	'state.actions.issue.dev-status.DEV_STATUS_LOAD_REQUEST' as const;

export type DevStatusLoadRequestAction = {
	type: typeof DEV_STATUS_LOAD_REQUEST;
	payload: {
		concurrentId: string;
	};
};

export const devStatusLoadRequest = (concurrentId: string): DevStatusLoadRequestAction => ({
	type: DEV_STATUS_LOAD_REQUEST,
	payload: {
		concurrentId,
	},
});

export const DEV_STATUS_LOAD_SUCCESS =
	'state.actions.issue.dev-status.DEV_STATUS_LOAD_SUCCESS' as const;

export type DevStatusLoadSuccessAction = {
	type: typeof DEV_STATUS_LOAD_SUCCESS;
	payload: DevStatusMap;
};

export const devStatusLoadSuccess = (activity: DevStatusMap): DevStatusLoadSuccessAction => ({
	type: DEV_STATUS_LOAD_SUCCESS,
	payload: activity,
});

export const DEV_STATUS_LOAD_FAILURE =
	'state.actions.issue.dev-status.DEV_STATUS_LOAD_FAILURE' as const;

export type DevStatusLoadFailureAction = {
	type: typeof DEV_STATUS_LOAD_FAILURE;
	meta: FlagMeta;
};

export const devStatusLoadFailure = (): DevStatusLoadFailureAction => ({
	type: DEV_STATUS_LOAD_FAILURE,
	meta: warningFlagMeta({
		id: DEV_STATUS_LOAD_FAILURE,
		titleMessage: flagMessages.devStatusFetchFailedTitle,
		descriptionMessage: flagMessages.devStatusFetchFailedDescription,
	}),
});

export type Action = DevStatusLoadAction | DevStatusLoadSuccessAction | DevStatusLoadFailureAction;
