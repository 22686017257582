import { connect } from '@atlassian/jira-react-redux';
import { workIssuesSelector } from '../../state/selectors/work/work-selectors';
import type { State } from '../../state/types';
import GlobalSearchTracker, { type StateProps } from './view';

export default connect(
	(state: State): StateProps => ({
		numIssues: workIssuesSelector(state).length,
	}),
	{},
)(GlobalSearchTracker);
