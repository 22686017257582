import { useEffect } from 'react';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import type { IsAnyLevelOfAdminProps } from './types';

export const useIsAnyLevelOfAdmin = ({ onIsAnyLevelOfAdminUpdated }: IsAnyLevelOfAdminProps) => {
	const { data: projectContext } = useProjectContext();
	const isSiteAdmin = useIsSiteAdmin();
	const isAdmin = useIsAdmin();
	const isProjectAdmin = !!projectContext?.isProjectAdmin;

	useEffect(() => {
		onIsAnyLevelOfAdminUpdated({
			isAnyLevelOfAdmin: !!(isSiteAdmin || isAdmin || isProjectAdmin),
		});
	}, [isProjectAdmin, isSiteAdmin, isAdmin, onIsAnyLevelOfAdminUpdated]);
};
