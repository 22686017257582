import type { IssueId } from '@atlassian/jira-software-board-common';
import type { Estimate } from '../../../../model/issue/issue-types';

export const SET_ESTIMATE = 'state.actions.issue.SET_ESTIMATE' as const;

export type SetEstimateAction = {
	type: typeof SET_ESTIMATE;
	payload: {
		issueId: IssueId;
		estimate: Estimate;
	};
};

export const setEstimate = (issueId: IssueId, estimate: Estimate): SetEstimateAction => ({
	type: SET_ESTIMATE,
	payload: {
		issueId,
		estimate,
	},
});

export type Action = SetEstimateAction;
