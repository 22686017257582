import { connect } from '@atlassian/jira-react-redux';
import { getLocale } from '../../../state/selectors/software/software-selectors';
import { remainingDaysSelector } from '../../../state/selectors/sprint/sprint-selectors';
import type { State } from '../../../state/types';
import SprintRemainingDays, { type Props } from './view';

export default connect((state: State): Props => {
	const { remainingDays, startDate, endDate } = remainingDaysSelector(state);
	const locale = getLocale(state);

	return {
		remainingDays,
		startDate,
		endDate,
		locale,
	};
}, {})(SprintRemainingDays);
