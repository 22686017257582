import type { AnyAction } from 'redux';
import { combineEpics } from 'redux-observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import isEmpty from 'lodash/isEmpty';
import { Observable } from 'rxjs/Observable';
import { ColumnType } from '@atlassian/jira-common-constants/src/column-types';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { values } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common';
import type { CardRefreshBoardScopeResponse } from '../../model/card/card-types';
import type { StatusColumn } from '../../model/column/column-types';
import { OTHER, TRANSITION } from '../../model/issue/issue-update-origin';
import { getIssueChildren } from '../../services/board-scope-graphql/transformer';
import { cardRefreshBoardScopeService } from '../../services/card-refresh-board-scope';
import {
	cardRefreshIssueTransformer,
	cardRefreshMediaTransformer,
} from '../../services/card/card-data-transformer';
import { cardRefreshPersonTransformer } from '../../services/people/people-data-transformer';
import {
	CARD_REFRESH_REQUEST,
	cardDataSet,
	type CardRefreshRequestAction,
	cardMediaDataSet,
	cardRefreshRequestWith,
} from '../../state/actions/card';
import { setIssueChildren } from '../../state/actions/issue-children';
import {
	ISSUE_MEDIA_VISIBILITY_CHANGE_SUCCESS,
	type IssueMediaVisibilityChangeSuccessAction,
} from '../../state/actions/issue/media';
import {
	ISSUE_UPDATE_REQUEST,
	issueUpdateFailure,
	type IssueUpdateRequestAction,
} from '../../state/actions/issue/update';
import { isTransactionValid } from '../../state/selectors/card/card-selectors';
import {
	columnByStatusIdSelector,
	getColumns,
} from '../../state/selectors/column/column-selectors';
import { hasIssueOrIssueChild } from '../../state/selectors/issue/issue-selectors';
import {
	contextPathSelector,
	getIsCMPBoard,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors';
import { getEstimationStatistic } from '../../state/selectors/work/work-selectors';
import type { State, Action, ActionsObservable, MiddlewareAPI } from '../../state/types';

export const cardRefreshEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(CARD_REFRESH_REQUEST).switchMap((action: CardRefreshRequestAction) => {
		const state = store.getState();
		const contextPath = contextPathSelector(state);
		const boardId = rapidViewIdSelector(state);
		const estimateFieldId = getEstimationStatistic(state);

		const cardIds = action.payload.issueIds
			? action.payload.issueIds.map((issueId) => String(issueId))
			: [];

		if (getIsCMPBoard(state)) {
			return Observable.empty<never>();
		}

		// eslint-disable-next-line @atlassian/eng-health/code-evolution/ts-migration-4.9-5.4
		return cardRefreshBoardScopeService(contextPath, boardId, cardIds) // @ts-expect-error([Part of upgrade 4.9-5.4]) - Argument of type '(response: CardRefreshBoardScopeResponse) => Observable<IssueUpdateFailureAction> | Observable<AnyAction>' is not assignable to parameter of type '(value: CardRefreshBoardScopeResponse, index: number) => ObservableInput<AnyAction>'.
			.flatMap((response: CardRefreshBoardScopeResponse) => {
				const {
					projectLocation: { id: projectId },
					cards,
					board,
				} = response.data.boardScope;

				if (cards === null) {
					return Observable.of(issueUpdateFailure(`failed to get cards ${String(cardIds)}`));
				}

				const stateAfterRefresh = store.getState();

				return Observable.from(
					cards.reduce<AnyAction[]>((actions, card) => {
						const cardPerson =
							card.issue.assignee !== null
								? cardRefreshPersonTransformer(card.issue.assignee)
								: null;

						const {
							issue: { status },
						} = card;
						const column = columnByStatusIdSelector(
							stateAfterRefresh,
							// @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
							Number(status.id),
						);

						const transactionIsValid = isTransactionValid(
							stateAfterRefresh,
							+card.id,
							action.payload.transactionId,
						);

						if (transactionIsValid) {
							actions.push(
								cardDataSet(
									cardRefreshIssueTransformer(
										projectId,
										// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
										column!,
										estimateFieldId,
										card,
									),
									cardPerson,
									action.payload.origin,
								),
								cardMediaDataSet(cardRefreshMediaTransformer(card.id, card)),
							);
						}

						if (board && board.issueChildren) {
							actions.push(
								setIssueChildren(
									getIssueChildren(
										board.issueChildren,
										Number(projectId),
										values(getColumns(state)).filter(
											(c): c is StatusColumn => c.type === ColumnType.STATUS,
										),
										estimateFieldId,
									),
								),
							);
						}

						return actions;
					}, []),
				);
			})
			.catch((error: FetchError) => {
				log.safeErrorWithoutCustomerData('card-refresh.failure', 'Failed to refresh card', error);
				return Observable.empty<never>();
			});
	});

// listens to issue-updates (happen when Bento is closed) and triggers image refresh
const issueUpdateListenerEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(ISSUE_UPDATE_REQUEST).flatMap((action: IssueUpdateRequestAction) => {
		const state = store.getState();
		const filterExistingIssues = (issueId: IssueId) => hasIssueOrIssueChild(state, issueId);
		const issueIds = action.payload.issueIds.filter(filterExistingIssues);

		if (issueIds && !isEmpty(issueIds)) {
			const skipTransactionUpdate = action.payload.origin === TRANSITION;
			return Observable.of(
				cardRefreshRequestWith({
					issueIds,
					origin: action.payload.origin,
					transactionId: action.payload.transactionId,
					skipTransactionUpdate,
				}),
			);
		}

		return Observable.empty<never>();
	});

// listens to card menu show/hide image changes
const cardMenuClicksListenerEpic = (action$: ActionsObservable) =>
	action$
		.ofType(ISSUE_MEDIA_VISIBILITY_CHANGE_SUCCESS)
		.filter((action: IssueMediaVisibilityChangeSuccessAction) => action.payload.isVisible)
		.map((action: IssueMediaVisibilityChangeSuccessAction) =>
			cardRefreshRequestWith({
				issueIds: [action.payload.issueId],
				origin: OTHER,
				transactionId: action.meta.optimistic.id,
				skipTransactionUpdate: false,
			}),
		);

export default combineEpics<Action, State>(
	cardRefreshEpic,
	issueUpdateListenerEpic,
	cardMenuClicksListenerEpic,
);
