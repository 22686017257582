import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	backToPlan: {
		id: 'portfolio-3-plan-increment-common.common.back-to-plan',
		defaultMessage: 'Back to plan',
		description: 'Call to action button text',
	},
	backToBoards: {
		id: 'portfolio-3-plan-increment-common.common.back-to-boards',
		defaultMessage: 'Choose another board',
		description: 'Trigger label for the dropdown of different boards',
	},
	dayUnit: {
		id: 'portfolio-3-plan-increment-common.common.day-unit',
		defaultMessage: '{days, plural, one {day} other {days}}',
		description: 'Label for the day',
	},
	hourUnit: {
		id: 'portfolio-3-plan-increment-common.common.hour-unit',
		defaultMessage: '{hours, plural, one {hour} other {hours}}',
		description: 'Label for the hour',
	},
	storyPointUnit: {
		id: 'portfolio-3-plan-increment-common.common.story-point-unit',
		defaultMessage: '{points, plural, one {point} other {points}}',
		description: 'Label for the story points',
	},
	activeSprint: {
		id: 'portfolio-3-plan-increment-common.common.active-sprint',
		defaultMessage: 'ACTIVE',
		description: 'Label for the active sprints',
	},
	closedSprint: {
		id: 'portfolio-3-plan-increment-common.common.closed-sprint',
		defaultMessage: 'COMPLETED',
		description: 'Label for the completed sprints',
	},
	futureSprint: {
		id: 'portfolio-3-plan-increment-common.common.future-sprint',
		defaultMessage: 'FUTURE',
		description: 'Label for the future sprints',
	},
	apiErrorFlagTitle: {
		id: 'portfolio-3-plan-increment-common.common.api-error-flag-title',
		defaultMessage: 'Something went wrong.',
		description: 'Title for an api error flag',
	},
	apiErrorFlagDescription: {
		id: 'portfolio-3-plan-increment-common.common.api-error-flag-description',
		defaultMessage: 'Refresh the page and try that again. ',
		description: 'Description for an api error flag',
	},
	editCapacityLabel: {
		id: 'portfolio-3-plan-increment-common.common.edit-capacity-label',
		defaultMessage: 'Edit capacity',
		description: 'Accessibility label for button, which is used to enter editView for capacity',
	},
	confirmButtonLabel: {
		id: 'portfolio-3-plan-increment-common.common.confirm-button-label',
		defaultMessage: 'Confirm',
		description: 'Accessibility label for confirm button when editing capacity',
	},
	cancelButtonLabel: {
		id: 'portfolio-3-plan-increment-common.common.cancel-button-label',
		defaultMessage: 'Cancel',
		description: 'Accessibility label for cancel button when editing capacity',
	},
});
