import type { IssueId } from '@atlassian/jira-software-board-common';
import type { ColumnId } from '../../../../model/column/column-types';
import type { IssueKey } from '../../../../model/issue/issue-types';
import type { FlagMeta } from '../../meta/flag-meta';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiWithFlagMeta,
	type OptimisticUiMeta,
} from '../../meta/optimistic-ui-meta';

export const ISSUE_MOVE_TO_BACKLOG_REQUEST =
	'state.actions.issue.move-to-backlog.ISSUE_MOVE_TO_BACKLOG_REQUEST' as const;

export type IssueMoveToBacklogRequestAction = {
	type: typeof ISSUE_MOVE_TO_BACKLOG_REQUEST;
	payload: {
		issueIds: IssueId[];
		issueKeys: IssueKey[];
		columnId: ColumnId;
	};
	meta: OptimisticUiMeta;
};

export const issueMoveToBacklogRequest = (
	issueIds: IssueId[],
	issueKeys: IssueKey[],
	columnId: ColumnId,
): IssueMoveToBacklogRequestAction => ({
	type: ISSUE_MOVE_TO_BACKLOG_REQUEST,
	payload: {
		issueIds,
		issueKeys,
		columnId,
	},
	meta: beginOptimisticUiMeta(),
});

export const ISSUE_MOVE_TO_BACKLOG_SUCCESS =
	'state.actions.issue.move-to-backlog.ISSUE_MOVE_TO_BACKLOG_SUCCESS' as const;

export type IssueMoveToBacklogSuccessAction = {
	type: typeof ISSUE_MOVE_TO_BACKLOG_SUCCESS;
	payload: {
		issueIds: IssueId[];
		columnId: ColumnId;
	};
	meta: OptimisticUiMeta;
};

export const issueMoveToBacklogSuccess = (
	optimisticId: string,
	issueIds: IssueId[],
	columnId: ColumnId,
): IssueMoveToBacklogSuccessAction => ({
	type: ISSUE_MOVE_TO_BACKLOG_SUCCESS,
	payload: {
		issueIds,
		columnId,
	},
	meta: commitOptimisticUiMeta(optimisticId),
});

export const ISSUE_MOVE_TO_BACKLOG_FAILURE =
	'state.actions.issue.move-to-backlog.ISSUE_MOVE_TO_BACKLOG_FAILURE' as const;

export type IssueMoveToBacklogFailureAction = {
	type: typeof ISSUE_MOVE_TO_BACKLOG_FAILURE;
	payload: {
		issueIds: IssueId[];
	};
	meta: OptimisticUiMeta & FlagMeta;
};

export const issueMoveToBacklogFailure = (
	optimisticId: string,
	issueIds: IssueId[],
): IssueMoveToBacklogFailureAction => ({
	type: ISSUE_MOVE_TO_BACKLOG_FAILURE,
	payload: {
		issueIds,
	},
	meta: revertOptimisticUiWithFlagMeta(optimisticId),
});

export type Action =
	| IssueMoveToBacklogRequestAction
	| IssueMoveToBacklogSuccessAction
	| IssueMoveToBacklogFailureAction;
