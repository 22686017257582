import React from 'react';
import { styled } from '@compiled/react';
import isNil from 'lodash/isNil';
import Avatar from '@atlaskit/avatar';
import TeamsIcon from '@atlaskit/icon/core/teams';
import PeopleIconOld from '@atlaskit/icon/glyph/people';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { SWIMLANE_TEAMLESS } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import {
	type MenuItem,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { useTextFilter } from '@atlassian/jira-software-context-menu/src/common/utils/use-text-filter/index.tsx';
import type { Team } from '../../../../../../../../model/team/team-types';
import { getSwimlaneId } from '../../../../../../../../services/issue/issue-data-transformer';
import { useBoardSelector, useBoardActionCreator } from '../../../../../../../../state';
import { issueChangeTeamRequest } from '../../../../../../../../state/actions/issue/change-team';
import { boardIssuesSelector } from '../../../../../../../../state/selectors/issue/board-issue-selectors';
import { getTeams } from '../../../../../../../../state/selectors/software/software-selectors';
import { getSwimlaneMode } from '../../../../../../../../state/selectors/swimlane/swimlane-mode-selectors';
import { getSwimlanes } from '../../../../../../../../state/selectors/swimlane/swimlane-selectors';
import { CHANGE_TEAM_MENU_ID, CHANGE_TEAM_UNASSIGN_MENU_ID } from '../../constants';
import messages from '../../messages';
import type { Props } from './types';
import { useChangeTeamContent } from './view';

/**
 * Get team icon for increment planning board
 * @param imageUrl team avatar URL
 * @param name team name
 * @returns
 */
const getTeamIcon = (imageUrl?: string | null, name?: string | null) => {
	if (imageUrl) {
		return (
			<Avatar
				size="small"
				src={imageUrl}
				name={fg('ip_remove_aria_labels_for_decorative_images') ? undefined : name || ''}
			/>
		);
	}
	return (
		<PeopleIconWrapper>
			{fg('enghealth-12479-jsw-board-visual-refresh') ? (
				<TeamsIcon
					label={fg('ip_remove_aria_labels_for_decorative_images') ? '' : name || ''}
					LEGACY_fallbackIcon={PeopleIconOld}
					LEGACY_size="small"
					color={token('color.icon')}
				/>
			) : (
				<PeopleIconOld label={name || ''} size="small" />
			)}
		</PeopleIconWrapper>
	);
};

export const useChangeTeam = ({ issueId, selectedCardIds }: Props) => {
	const { formatMessage } = useIntl();
	const allIssues = useBoardSelector(boardIssuesSelector);
	const triggerIssue = allIssues[String(issueId)];
	const swimlaneMode = useBoardSelector(getSwimlaneMode);
	const swimlaneId = useBoardSelector((state) =>
		!isNil(triggerIssue) ? getSwimlaneId(swimlaneMode, getSwimlanes(state), triggerIssue) : null,
	);
	const teams = Object.values(useBoardSelector(getTeams)).filter(
		({ isPlanTeam, id }) => id !== swimlaneId && isPlanTeam,
	);

	const options: MenuItem[] = teams.map((team) => ({
		type: MenuNodeType.Item,
		label: team.name,
		id: `${CHANGE_TEAM_MENU_ID}/${team.id}`,
		onClick: () => onChangeTeam(team),
		icon: getTeamIcon(team.avatarUrl, team.name),
	}));

	const unassignOption: MenuItem = {
		type: MenuNodeType.Item,
		label: formatMessage(messages.unassign),
		id: CHANGE_TEAM_UNASSIGN_MENU_ID,
		onClick: () => onChangeTeam(),
	};

	if (!isNil(triggerIssue.teamId)) {
		options.unshift(unassignOption);
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const transformOptionToString = ({ label }: MenuItem) => label as string;

	const [filteredOptions, updateFilter] = useTextFilter<MenuItem>(options, transformOptionToString);

	const onChangeTeam = useBoardActionCreator((destinationTeam?: Team) =>
		issueChangeTeamRequest({
			issueIds: selectedCardIds,
			sourceColumnId: triggerIssue.columnId,
			destinationColumnId: triggerIssue.columnId,
			rankBeforeIssueId: null,
			rankAfterIssueId: null,
			sourceSwimlaneId: swimlaneId,
			destinationSwimlaneId: destinationTeam === undefined ? SWIMLANE_TEAMLESS : destinationTeam.id,
		}),
	);

	return useChangeTeamContent({
		setQuery: updateFilter,
		options: filteredOptions,
	});
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PeopleIconWrapper = styled.div({
	borderRadius: '50%',
	padding: `${token('space.025', '2px')} ${token('space.050', '4px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral', colors.N40),
	margin: `0 ${token('space.025', '2px')}`,
});
