import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_NEXTGEN_BOARD } from '../../common/constants';
import getData from '../get-data';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const boardResource = createResource<any>({
	type: RESOURCE_TYPE_NEXTGEN_BOARD,
	getKey: ({ match }) => match.params.boardId || '',
	getDataLoader: () => Promise.resolve({ default: getData }),
	// SSR doesn't care about this and we can't check FFs here because its not in the render path
	maxAge: 1000 * 60 * 60, // 1 hour max age
});
