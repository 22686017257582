import { connect } from '@atlassian/jira-react-redux';
import {
	backlogIssueMoveCancel,
	backlogIssueMoveConfirm,
} from '../../../../state/actions/board/backlog-issue-move';
import { isDialogVisibleSelector } from '../../../../state/selectors/board/backlog-issue-move-selectors';
import type { State } from '../../../../state/types';
import BacklogIssueMoveDialog from './view';

export default connect(
	(state: State) => ({
		isOpen: isDialogVisibleSelector(state),
	}),
	{
		onCancel: backlogIssueMoveCancel,
		onConfirm: backlogIssueMoveConfirm,
	},
)(BacklogIssueMoveDialog);
