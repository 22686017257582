import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editSprintLabelNonFinal: {
		id: 'software.main.header.sprint-goal-panel.edit-sprint-label-non-final',
		defaultMessage: 'Edit {activeSprints, plural, one {sprint} other {sprints}} ',
		description:
			'Tooltip content and label for the button that can edit active sprints. It displays as a tooltip for EditIcon.',
	},
	collapseSprintGoalPanelNonFinal: {
		id: 'software.main.header.sprint-goal-panel.collapse-sprint-goal-panel-non-final',
		defaultMessage: 'Click to collapse',
		description:
			'Tooltip content and label for the sprint goal panel when panel is in full display mode. Users click the panel to collapse panel view.',
	},
	expandSprintGoalPanelNonFinal: {
		id: 'software.main.header.sprint-goal-panel.expand-sprint-goal-panel-non-final',
		defaultMessage: 'Click to expand',
		description:
			'Tooltip content and label for the sprint goal panel when panel is in truncated view. Users click the panel to expand to full display mode.',
	},
	multipleActiveSprintsNonFinal: {
		id: 'software.main.header.sprint-goal-panel.multiple-active-sprints-non-final',
		defaultMessage: 'All sprints',
		description:
			'Sprint panel title displaying number of active sprints in a company-managed scrum board.',
	},
	remainingDaysLabelNonFinal: {
		id: 'software.main.header.sprint-goal-panel.remaining-days-label-non-final',
		defaultMessage: '{remainingDays} {remainingDays, plural, one {day} other {days}} remaining',
		description: 'Text to show remaining days in a sprint',
	},
});
