import type { IssueId } from '@atlassian/jira-software-board-common';
import type { IssueKey } from '../../model/issue/issue-types';
import type { RapidViewId } from '../../model/software/software-types';

export const STATUS_CATEGORY_URL = '/rest/api/2/statuscategory';

// GraphQL URLs

export const getJSWGraphQlEndpointUrl = (contextPath: string) => `${contextPath}/jsw2/graphql`;

export const getSoftwareColumnCreateUrl = (contextPath: string) =>
	`${getJSWGraphQlEndpointUrl(contextPath)}?operation=SoftwareColumnCreate`;

export const getSoftwareColumnDeleteUrl = (contextPath: string) =>
	`${getJSWGraphQlEndpointUrl(contextPath)}?operation=SoftwareColumnDelete`;

export const getSoftwareColumnRenameUrl = (contextPath: string) =>
	`${getJSWGraphQlEndpointUrl(contextPath)}?operation=SoftwareColumnRename`;

export const getSoftwareColumnRankUrl = (contextPath: string) =>
	`${getJSWGraphQlEndpointUrl(contextPath)}?operation=SoftwareColumnRank`;

export const getSoftwareColumnSetLimitUrl = (contextPath: string) =>
	`${getJSWGraphQlEndpointUrl(contextPath)}?operation=SoftwareColumnSetLimit`;

export const getInviteUserUrl = (projectId: string, contextPath: string) =>
	`${contextPath}/rest/internal/2/invite-user/project/${projectId}/invitation-settings`;

export const getCompleteSprintUrl = (contextPath: string) =>
	`${getJSWGraphQlEndpointUrl(contextPath)}?operation=CompleteSprintMetadata`;

export const getClearIssuesUrl = (contextPath: string) =>
	`${getJSWGraphQlEndpointUrl(contextPath)}?operation=SoftwareBoardClearCards`;

export const getFilteredCardIdsUrl = (contextPath: string) =>
	`${getJSWGraphQlEndpointUrl(contextPath)}?operation=FilteredCardIds`;

export const getBoardCardCreateUrl = (contextPath: string) =>
	`${getJSWGraphQlEndpointUrl(contextPath)}?operation=BoardCardCreate`;

// Rest URLs

export const getCreateIssueUrl = (contextPath: string): string => `${contextPath}/rest/api/2/issue`;

export const getIssueEditUrl = (contextPath: string, issueKey: IssueKey): string =>
	`${contextPath}/rest/api/2/issue/${issueKey}`;

export const getIssueTransitionWithoutColumnUrl = (contextPath: string) =>
	`${contextPath}/rest/greenhopper/1.0/board/issues/transition`;

export const getIssueTransitionUrl = () => '/rest/greenhopper/1.0/xboard/transitionAndRank';

export const getBulkIssueRankUrl = () => '/rest/greenhopper/1.0/rank';

export const getCurrentUserDataFromAccountIdUrl = (contextPath: string, accountId: string) =>
	`${contextPath}/rest/api/3/user?accountId=${encodeURIComponent(accountId)}`;

export const getBoardUrl = (contextPath: string, rapidViewId: RapidViewId) =>
	`${contextPath}/secure/RapidBoard.jspa?rapidView=${rapidViewId}`;

export const getBacklogUrl = (
	contextPath: string,
	rapidViewId: RapidViewId,
	additionalParams: string[] = [],
) => {
	const paramString = additionalParams.map((param) => `&${param}`).join('');
	return `${contextPath}/secure/RapidBoard.jspa?rapidView=${rapidViewId}&view=planning${paramString}`;
};

export const getCardMediaUrl = (
	contextPath: string,
	rapidViewId: RapidViewId,
	display: boolean,
): string =>
	`${contextPath}/rest/greenhopper/1.0/xboard/work/media?rapidViewId=${rapidViewId}&display=${String(
		display,
	)}`;

export const getIssueMediaVisibilityChangeUrl = (contextPath: string, issueId: IssueId) =>
	`${contextPath}/rest/greenhopper/1.0/xboard/work/media/${issueId}`;

export const getBoardPropertyKeysUrl = (contextPath: string, rapidViewId: RapidViewId) =>
	`${contextPath}/rest/agile/1.0/board/${rapidViewId}/properties`;

export const getBoardPropertyUrl = (
	contextPath: string,
	rapidViewId: RapidViewId,
	propertyKey: string,
) => `${contextPath}/rest/agile/1.0/board/${rapidViewId}/properties/${propertyKey}`;

export const getBacklogIssueMoveUrl = (contextPath: string) =>
	`${contextPath}/rest/greenhopper/1.0/xboard/migrateFirstColumnToBacklog`;

export const getIssuesMoveToBacklogUrl = (contextPath: string) =>
	`${contextPath}/rest/greenhopper/1.0/xboard/assignToListAndRank`;

export const getRealtimeSubscription = () => '/gateway/api/pubsub/subscribe';

export const getFeaturesUrl = (contextPath: string, rapidViewId: string) =>
	`${contextPath}/rest/agile/latest/board/${rapidViewId}/features`;

export const getNextGenBacklogUrl = (
	contextPath: string,
	projectKey: string,
	rapidViewId: RapidViewId,
) => `${contextPath}/jira/software/projects/${projectKey}/boards/${rapidViewId}/backlog`;

export const getVersionUrl = (rapidViewId: string) =>
	`/rest/greenhopper/1.0/xboard/work/versions?rapidViewId=${rapidViewId}`;

export const getIssueTypes = (contextPath: string, issueTypeId: string) =>
	`${contextPath}/rest/api/3/issuetype/${issueTypeId}`;

// Used in mocks only

export const getAddToHistoryUrl = (contextPath: string) =>
	`${contextPath}/rest/greenhopper/1.0/rapidview/addtohistory`;

export const getProjectRoleDetailsUrl = (projectKey: string, contextPath: string) =>
	`${contextPath}/rest/api/3/project/${projectKey}/roledetails?excludeConnectAddons=true`;

export const getSuggestedAssigneeUrl = () => '/rest/api/2/user/assignable/search';
export const getSuggestedParentsUrl = () => '/rest/api/3/search';
export const getIssueFieldBaseUrl = () => '/rest/graphql/1';
export const saveIssueFieldUrl = () => '/rest/api/3/issue/:issueKey';
export const saveAssigneeUrl = () => '/rest/api/3/issue/:issueKey/assignee';
export const addParentUrl = () =>
	'/rest/internal/simplified/1.0/projects/:projectId/issues/:issueId/children';
export const getGiraUrl = () => '/rest/gira/1/';
