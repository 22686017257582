import flow from 'lodash/flow';
import memoizeOne from 'memoize-one';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireUIAnalytics, AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux';
import type { RapidViewId } from '../../../../model/software/software-types';
import { rapidViewIdSelector } from '../../../../state/selectors/software/software-selectors';
import { columnsWithResolutionSelector } from '../../../../state/selectors/work/work-selectors';
import type { State, Dispatch } from '../../../../state/types';
import Minimap from './view';

type OwnProps = {
	onScrollAttempted: (scrollChanged: boolean, analyticsEvent: UIAnalyticsEvent) => void;
	onShow: (analyticsEvent: UIAnalyticsEvent) => void;
};

type StateProps = {
	externalId: string;
	rapidViewId: RapidViewId;
	columnCount: number;
};

type DispatchProps = {
	dispatch: Dispatch;
};

type Props = OwnProps & StateProps & DispatchProps;

const onScrollAttemptedFactory = memoizeOne(
	(columnCount: number, rapidViewId: string) =>
		(scrollChanged: boolean, analyticsEvent: UIAnalyticsEvent) => {
			analyticsEvent.update({ action: scrollChanged ? 'scrolled' : 'clicked' });
			fireUIAnalytics(analyticsEvent, { columnCount, rapidViewId });
		},
);

const onShowFactory = memoizeOne(
	(columnCount: number, rapidViewId: string) => (analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, { columnCount, rapidViewId });
	},
);

const mergeProps = (
	stateProps: StateProps,
	dispatchProps: DispatchProps,
	ownProps: OwnProps,
): Props => {
	const { rapidViewId, columnCount } = stateProps;
	return {
		...stateProps,
		...dispatchProps,
		...ownProps,
		onScrollAttempted: onScrollAttemptedFactory(columnCount, rapidViewId),
		onShow: onShowFactory(columnCount, rapidViewId),
	};
};

export default flow(
	AnalyticsEventToProps('minimap', {
		onScrollAttempted: 'scrollAttempted',
		onShow: 'showed',
	}),
	connect(
		(state: State): StateProps => ({
			externalId: 'software.board.minimap',
			rapidViewId: rapidViewIdSelector(state),
			columnCount: columnsWithResolutionSelector(state).length,
		}),
		null,
		mergeProps,
	),
)(Minimap);
