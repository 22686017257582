import { type Observable as ObservableType, Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import { fg } from '@atlassian/jira-feature-gating';
import { columnStatus } from '@atlassian/jira-software-board-fetch-scope-critical-query';
import { query$ } from '@atlassian/jira-software-swag/src/services/query/index.tsx';
import { boardScopeDataNonCriticalAnalyticsOptions } from '../../../common/graphql/metrics';
import type { NonCriticalDataResponse } from '../../../model/work/work-types';

export const softwareBoardScopeDataOperationName = 'SoftwareBoardScopeData';

export const getBoardScopeGraphqlQuery = () => {
	const editConfig = `
        editConfig {
            inlineColumnEdit {
                enabled
            }
            inlineIssueCreate @skip(if: $useBackendIccConfig){
                enabled
            }
        }
    `;

	const issueChildren = `issueChildren {
                id
                flagged
                done
                parentId
                estimate {
                    storyPoints
                    originalEstimate {
                        value
                        valueAsText
                    }
                }
                issue {
                    id
                    key
                    summary
                    labels
                    assignee {
                        accountId
                        displayName
                        avatarUrl
                    }
                    type { id, name, iconUrl }
                    status { id }
                    activeSprint { id }
                }
                coverMedia {
                    attachmentId
                    endpointUrl
                    clientId
                    token
                    attachmentMediaApiId
                    hiddenByUser
                }
                priority {
                    name
                    iconUrl
                }
                dueDate
            }`;

	const timeTrackingConfig = `
    jira {
        timeTrackingOptions {
            workingHoursPerDay
            workingDaysPerWeek
            defaultUnit
            timeFormat
        }
    }`;

	const globalConfig = `
        globalConfig @skip(if: $useBackendIccConfig){
            applicationProperties {
                createStatusIsEnabled
            }
        }
    `;

	return `query ${softwareBoardScopeDataOperationName} ($boardId: ID!, $useBackendIccConfig: Boolean!) {
            boardScope(boardId: $boardId) {
                userSwimlaneStrategy
                board {
                    name
                    swimlaneStrategy
                    hasClearedIssues
                    rankCustomFieldId
                    assignees {
                        accountId
                        displayName
                        avatarUrl
                    }
                    columns {
                        id
                        name
                        maxIssueCount
                        status {
                            id
                            name
                        }
                        ${columnStatus}
                        isDone
                        isInitial
                        transitionId
                        cards {
                            id
                            flagged
                            done
                            parentId
                            estimate {
                                storyPoints
                                originalEstimate {
                                    value
                                    valueAsText
                                }
                            }
                            issue {
                                id
                                key
                                summary
                                labels
                                assignee {
                                    accountId
                                    displayName
                                    avatarUrl
                                }
                                type { id, name, iconUrl }
                                status { id }
                                activeSprint { id }
                            }
                            coverMedia {
                                attachmentId
                                endpointUrl
                                clientId
                                token
                                attachmentMediaApiId
                                hiddenByUser
                            }
                            priority {
                                name
                                iconUrl
                            }
                            dueDate
                            childIssuesMetadata { complete, total }
                        }
                    }
                    issueTypes {
                        id
                        name
                        iconUrl
                        hierarchyLevelType
                        hasRequiredFields @skip(if: $useBackendIccConfig)
                    }
                    inlineIssueCreate @skip(if: $useBackendIccConfig) { enabled }
                    cardMedia { enabled }
                    ${issueChildren}
                    cards { id }
                    ${editConfig}
                    inlineCardCreateConfig @include(if: $useBackendIccConfig)
                }
                backlog {
                    boardIssueListKey
                    requestColumnMigration
                }
                sprints(state: [ACTIVE]) {
                    id
                    name
                    goal
                    startDate
                    endDate
                    daysRemaining
                }
                features { key, status, toggle, category }
                projectLocation {
                    id
                    key
                    name
                    isSimplifiedProject
                    issueTypes {
                        id
                        name
                        iconUrl
                        hierarchyLevelType
                        hasRequiredFields
                    }
                }
                issueParents {
                    id
                    key
                    summary
                    issue {
                        status {
                            id
                            name
                            category
                        }
                    }
                    issueType {
                        id
                        name
                        iconUrl
                    }
                    color
                }
                currentUser { permissions }
                estimation {
                    current {
                        customFieldId
                    }
                }
            }
            ${timeTrackingConfig}
            ${globalConfig}
        }`;
};

export const parseResponse = (
	data: NonCriticalDataResponse,
): ObservableType<NonCriticalDataResponse> => Observable.of(data);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (contextPath: string, boardId: string): ObservableType<NonCriticalDataResponse> =>
	query$<NonCriticalDataResponse>(
		contextPath,
		softwareBoardScopeDataOperationName,
		getBoardScopeGraphqlQuery(),
		{
			boardId,
			useBackendIccConfig: fg('use_backend_tmp_icc_config_'),
		},
		boardScopeDataNonCriticalAnalyticsOptions,
	).flatMap(parseResponse);
