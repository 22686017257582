import { entityArrayToMap } from '../../../../services/software/software-data-transformer';
import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../actions/work';
import type { Action } from '../../../types';
import type { IssueTypesState } from './types';

export const issueTypesReducer = (state: IssueTypesState = {}, action: Action): IssueTypesState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		const {
			payload: { issueTypes },
		} = action;

		return entityArrayToMap(issueTypes);
	}

	return state;
};
