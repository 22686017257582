import React, { useCallback, useMemo } from 'react';
import { styled, css } from '@compiled/react';
import { useShouldApplyInviteAndAssign } from '@atlassian/jira-invite-and-assign/src/controllers/use-should-apply-invite-and-assign/index.tsx';
import {
	IssueAssigneeFieldBoundary,
	AsyncLazyIssueAssigneeField,
} from '@atlassian/jira-issue-field-assignee/src/ui/popover/async.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import { ASSIGNEE_TYPE } from '@atlassian/jira-platform-field-config';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { AssigneeFieldStatic } from '../../../../../../common/fields/assignee-field-static';
import { useEditableField } from '../../../../../../common/fields/use-editable-field';
import type { EditableFieldProps } from '../../../../../../common/fields/use-editable-field/types';
import { PACKAGE_NAME } from '../../../../../../model/constants';
import { useBoardDispatch, useBoardSelector } from '../../../../../../state';
import { setInlineEditing } from '../../../../../../state/actions/card';
import { getPreventInlineEditing } from '../../../../../../state/selectors/board/board-selectors';
import { getActiveIssueKey } from '../../../../../../state/selectors/work/work-selectors';
import { INLINE_EDITING_FIELD_ZINDEX } from '../constants';
import { AsyncLazyIssueAssigneeFieldWithInviteAssign } from './assignee-field-with-invite-assign/async';
import { ASSIGNEE_BUTTON_TEST_ID } from './constants';
import type { AssigneeFieldProps } from './types';

const stopClickPropagation = (e: React.MouseEvent | React.KeyboardEvent) => {
	e.stopPropagation();
};

const fieldKey = 'assignee';

const AssigneeFieldInner = ({
	issueKey,
	onSubmit,
	onUpdate,
	onFailure,
	onOpen,
	onClose,
}: { issueKey: IssueKey } & Partial<EditableFieldProps>) => {
	const preventInlineEditing = useBoardSelector((state) => getPreventInlineEditing(state));

	const shouldApplyInviteAndAssign = useShouldApplyInviteAndAssign();

	return (
		<ContextualAnalyticsData
			attributes={{
				isInlineEditing: true,
				fieldKey,
				fieldType: 'userpicker',
			}}
		>
			<AssigneeFieldWrapper
				role="button"
				onClick={stopClickPropagation}
				onKeyDown={stopClickPropagation}
				data-testid={ASSIGNEE_BUTTON_TEST_ID}
				disableClick={preventInlineEditing}
			>
				{shouldApplyInviteAndAssign ? (
					<AsyncLazyIssueAssigneeFieldWithInviteAssign
						actionSubject="inlineEdit"
						issueKey={issueKey}
						shouldPreloadAssignToMe
						popupPlacement="bottom-end"
						onSubmit={onSubmit}
						onUpdate={onUpdate}
						onFailure={onFailure}
						onOpen={onOpen}
						onClose={onClose}
					/>
				) : (
					<AsyncLazyIssueAssigneeField
						actionSubject="inlineEdit"
						issueKey={issueKey}
						shouldPreloadAssignToMe
						popupPlacement="bottom-end"
						onSubmit={onSubmit}
						onUpdate={onUpdate}
						onFailure={onFailure}
						onOpen={onOpen}
						onClose={onClose}
					/>
				)}
			</AssigneeFieldWrapper>
		</ContextualAnalyticsData>
	);
};

export const AssigneeField = ({
	shouldRenderRichField,
	assignee,
	isCMPBoard,
	issueKey,
	...props
}: AssigneeFieldProps) => {
	const activeIssueKey = useBoardSelector(getActiveIssueKey);

	const [{ value: assigneeFieldConfig }] = useFieldConfig(issueKey, ASSIGNEE_TYPE, {
		skipRefresh: !isCMPBoard || activeIssueKey !== issueKey,
	});

	const shouldRenderRich = Boolean(shouldRenderRichField && assigneeFieldConfig);

	const editableField = useEditableField({
		isExperienceAvailable: shouldRenderRich,
	});
	const fallback = useMemo(() => <AssigneeFieldStatic value={assignee} />, [assignee]);
	const dispatch = useBoardDispatch();

	const onOpen = useCallback(() => {
		dispatch(setInlineEditing('assignee', props.issueId, true));
	}, [dispatch, props.issueId]);

	const onClose = useCallback(() => {
		dispatch(setInlineEditing('assignee', props.issueId, false));
	}, [dispatch, props.issueId]);

	if (shouldRenderRich) {
		return (
			<IssueAssigneeFieldBoundary
				packageName={PACKAGE_NAME}
				fallback={fallback}
				onError={editableField.onError}
			>
				<UFOSegment name="ng-board.inline-edit.assignee-field">
					<Placeholder name="assignee-field-inner" fallback={fallback}>
						<AssigneeFieldInner
							{...props}
							{...editableField}
							issueKey={issueKey}
							onOpen={onOpen}
							onClose={onClose}
						/>
					</Placeholder>
				</UFOSegment>
			</IssueAssigneeFieldBoundary>
		);
	}

	return fallback;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AssigneeFieldWrapper = styled.div<{
	disableClick?: boolean;
}>(
	{
		cursor: 'pointer',

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > [id$="popup-select"]': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			position: 'absolute !important',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			zIndex: INLINE_EDITING_FIELD_ZINDEX,
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ disableClick }) =>
		disableClick &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			pointerEvents: 'none',
		}),
);
