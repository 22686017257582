import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { ERROR_REPORTING_TEAM, PACKAGE_NAME } from '../../common/constants';
import { syncBoardSwitcher } from './entrypoint';
import type { Props as SyncSprintProps } from './types';

const AsyncBoardSwitcher = (props: SyncSprintProps) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(syncBoardSwitcher, {});

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
		return () => {
			entryPointActions.unload();
		};
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id="plan-increment-board-switcher"
			packageName={PACKAGE_NAME}
			teamName={ERROR_REPORTING_TEAM}
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={props}
		/>
	);
};

export default AsyncBoardSwitcher;
