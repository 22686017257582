import { ff } from '@atlassian/jira-feature-flagging';
import type { BoardPermissions } from '../../../model/board/board-types';
import {
	CREATE_ISSUE,
	MANAGE_SPRINT,
	DELETE_ISSUE,
	EDIT_BOARD_CONFIG,
	EDIT_ISSUE,
	MANAGE_AUTOMATION,
	ARCHIVE_ISSUE,
} from '../../../model/board/constants';

export const getBoardPermissions = (
	boardPermissions: ReadonlyArray<AGG$SoftwareBoardPermission>,
): BoardPermissions => ({
	createIssue: boardPermissions.includes(CREATE_ISSUE),
	deleteIssue: boardPermissions.includes(DELETE_ISSUE),
	manageSprint: boardPermissions.includes(MANAGE_SPRINT),
	editBoardConfig: boardPermissions.includes(EDIT_BOARD_CONFIG),
	editIssue: boardPermissions.includes(EDIT_ISSUE),
	manageAutomation: boardPermissions.includes(MANAGE_AUTOMATION),
	...(ff('issue-unarchival_wdpb9')
		? { archiveIssue: boardPermissions.includes(ARCHIVE_ISSUE) }
		: {}),
});
