import React, { useEffect, useRef } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import SvgOverlay from '@atlassian/jira-aais-dependency-lines-overlay/src/common/ui';
import {
	useDependencyLinks,
	useIPDependencyLines,
	type DependencyLinesProps,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/hooks/use-dependency-links';
import { useResizeObserver } from '@atlassian/jira-react-use-resize-observer';

export const DependencyLines = ({ issuesWithLinksById }: DependencyLinesProps) => {
	const [dependencyLinesData] = useIPDependencyLines({ issuesWithLinksById });

	const [_, { updateDependencyLinesContainerPosition }] = useDependencyLinks();

	// Hold the ref to the container of the dependency lines so we can account for panels pushing in from the left
	// E.g unscheduled work panel, nav sidebar being present/collapsed.
	const dependencyLinesContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (dependencyLinesContainerRef.current) {
			const { x } = dependencyLinesContainerRef.current.getBoundingClientRect();
			updateDependencyLinesContainerPosition({ x });
		}
	}, [updateDependencyLinesContainerPosition, dependencyLinesContainerRef]);

	useResizeObserver({
		ref: dependencyLinesContainerRef,
		onResize: () => {
			if (dependencyLinesContainerRef.current) {
				const { x } = dependencyLinesContainerRef.current.getBoundingClientRect();
				updateDependencyLinesContainerPosition({ x });
			}
		},
	});

	return (
		<>
			<SvgOverlay dependencies={dependencyLinesData} />
			<Box ref={dependencyLinesContainerRef} xcss={measureDependencyLinesContainerStyles} />
		</>
	);
};

const measureDependencyLinesContainerStyles = xcss({
	height: '100%',
	width: '100%',
	position: 'absolute',
	top: '0',
	left: '0',
	pointerEvents: 'none',
});
