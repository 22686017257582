import React, { useEffect, useCallback, useMemo, useState } from 'react';
import isNil from 'lodash/isNil';
import { IconButton } from '@atlaskit/button/new';
import InfoIcon from '@atlaskit/icon/utility/migration/information--editor-panel';
import Image from '@atlaskit/image';
import Link from '@atlaskit/link';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import type { FormatOptionLabelMeta, InputActionMeta } from '@atlaskit/select';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import HelpPanelButton from '@atlassian/jira-help-panel-button';
import { useIntl } from '@atlassian/jira-intl';
import { useActiveRightSidebarState } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller';
import {
	DOC_LINKS,
	IPH_IDS,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants';
import commonMessages from '../../../../common/messages';
import {
	Action,
	ActionSubject,
	ToggleKey,
	IncrementPlanningAnalyticsKey,
} from '../../../../common/types/analytics.tsx';
import { ExpandableState } from '../../../../common/types/constant.tsx';
import type { IncrementPlanningBoardViewProps } from '../../../../common/types/menu.tsx';
import { ViewSettingsSelect, type IssueOption } from '../../../../common/ui/view-settings-select';
import { ViewSettingsToggle } from '../../../../common/ui/view-settings-toggle';
import { useViewSettings } from '../../../../controllers';
import messages from './messages';

const MAX_OPTIONS_LENGTH = 20;

const InfoIconWithTooltip = ({ tooltipContent }: { tooltipContent: string }) => (
	<Box xcss={infoIconWrapperStyles}>
		<IconButton
			icon={InfoIcon}
			label={tooltipContent}
			appearance="subtle"
			isTooltipDisabled={false}
			spacing="compact"
		/>
	</Box>
);

const MAX_SELECTED_ISSUES = 10;

export const IncrementPlanningBoardMenu = ({
	viewAs,
	boardId,
	isSwimlanesEnabled = false,
	onHandleChange,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSwimlaneExpandAll = () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSwimlaneCollapseAll = () => {},
	dependencyIssueOptions = {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onUpdate = () => {},
}: IncrementPlanningBoardViewProps) => {
	const { formatMessage } = useIntl();
	const [state, actions] = useViewSettings();
	const rightPanelState = useActiveRightSidebarState();
	const issueOptions: IssueOption[] = useMemo(() => {
		if (fg('dependency_visualisation_program_board_fe_and_be')) {
			return Object.values(dependencyIssueOptions).map((issue) => ({
				value: issue.id,
				label: `${issue.key} ${issue.summary}`,
				iconUrl: issue.typeUrl,
			}));
		}
		return [];
	}, [dependencyIssueOptions]);
	const [slicedOptions, setSlicedOptions] = useState<IssueOption[]>(
		issueOptions.slice(0, MAX_OPTIONS_LENGTH),
	);

	useEffect(() => {
		if (
			!isNil(rightPanelState?.panelId) &&
			fg('dependency_visualisation_program_board_fe_and_be')
		) {
			onUpdate();
		}
	}, [rightPanelState?.panelId, onUpdate]);

	const {
		setShowUnscheduledColumn,
		setShowOfftrackDependencyLines,
		setIssueIdsToShowDependencies,
	} = actions;

	const onFormatOptionLabel = (
		option: IssueOption,
		formatOptionLabelMeta: FormatOptionLabelMeta<IssueOption>,
	) => {
		return (
			<Inline space="space.050" alignBlock="start">
				{option.iconUrl && (
					<Box xcss={formatOptionLabelMeta.context === 'menu' ? menuIconStyles : valueIconStyles}>
						<Image src={option.iconUrl} alt="" />
					</Box>
				)}
				<Box>{option.label}</Box>
			</Inline>
		);
	};

	const selectedIssueOptions: IssueOption[] = useMemo(() => {
		if (
			Array.isArray(state.issueIdsToShowDependencies) &&
			fg('dependency_visualisation_program_board_fe_and_be')
		) {
			const tempDefaultIssueOptions: IssueOption[] = [];
			state.issueIdsToShowDependencies.forEach((issueId) => {
				const issue = dependencyIssueOptions[issueId];
				if (issue) {
					tempDefaultIssueOptions.push({
						value: issue.id,
						label: `${issue.key} ${issue.summary}`,
						iconUrl: issue.typeUrl,
					});
				}
			});
			return tempDefaultIssueOptions;
		}
		return [];
	}, [state.issueIdsToShowDependencies, dependencyIssueOptions]);

	const onInputChange = useCallback(
		(newValue: string, actionMeta: InputActionMeta) => {
			if (actionMeta.action === 'input-change') {
				if (newValue.trim().length === 0) {
					setSlicedOptions(issueOptions.slice(0, MAX_OPTIONS_LENGTH));
				} else {
					const filteredInOptions = issueOptions.filter((o) => (o?.label || '').includes(newValue));
					setSlicedOptions(filteredInOptions.slice(0, MAX_OPTIONS_LENGTH));
				}
			}
		},
		[issueOptions, setSlicedOptions],
	);

	const getViewSettingsValidationMessage = () => {
		if (state.issueIdsToShowDependencies.length > MAX_SELECTED_ISSUES) {
			return formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? commonMessages.maxDependenciesErrorIssueTermRefresh
					: commonMessages.maxDependenciesError,
				{ maxDeps: MAX_SELECTED_ISSUES },
			);
		}

		return undefined;
	};

	return (
		<MenuGroup data-testId="software-view-settings.ui.view-settings-menu.menu-increment-planning-board">
			<Section>
				<ViewSettingsToggle
					testId="software-view-settings.ui.view-settings-menu.views.increment-planning-board.show-unscheduled-column-toggle"
					title={formatMessage(commonMessages.showUnscheduledWork)}
					onChange={() => {
						setShowUnscheduledColumn(boardId, !state.showUnscheduledColumn);
						onHandleChange(
							ActionSubject.TOGGLE,
							{
								key: IncrementPlanningAnalyticsKey.UNSCHEDULED_COLUMN_TOGGLE,
								state: !state.showUnscheduledColumn,
							},
							Action.CLICKED,
						);
					}}
					isChecked={state.showUnscheduledColumn}
					viewAs={viewAs}
				/>
			</Section>
			{isSwimlanesEnabled && (
				<Section title={formatMessage(commonMessages.swimlanes)} hasSeparator>
					<ButtonItem
						data-testid="software-view-settings.ui.view-settings-menu.views.increment-planning-board.expand-all"
						onClick={() => {
							onSwimlaneExpandAll();
							onHandleChange(
								ActionSubject.BUTTON,
								{
									key: ToggleKey.SWIMLANE_EXPAND_ALL,
									state: ExpandableState.EXPANDED,
								},
								Action.CLICKED,
							);
						}}
					>
						{formatMessage(commonMessages.expandAll)}
					</ButtonItem>
					<ButtonItem
						data-testid="software-view-settings.ui.view-settings-menu.views.increment-planning-board.collapse-all"
						onClick={() => {
							onSwimlaneCollapseAll();
							onHandleChange(
								ActionSubject.BUTTON,
								{
									key: ToggleKey.SWIMLANE_COLLAPSE_ALL,
									state: ExpandableState.COLLAPSED,
								},
								Action.CLICKED,
							);
						}}
					>
						{formatMessage(commonMessages.collapseAll)}
					</ButtonItem>
				</Section>
			)}
			{fg('dependency_visualisation_program_board_fe_and_be') && (
				<Section title={formatMessage(messages.dependencyLinesSection)} hasSeparator>
					<ViewSettingsToggle
						testId="software-view-settings.ui.view-settings-menu.views.increment-planning-board.offtrack-dependencies-toggle"
						title={formatMessage(messages.showOfftrackDependencyLines)}
						onChange={() => {
							setShowOfftrackDependencyLines(boardId, !state.showOfftrackDependencyLines);
							onHandleChange(
								ActionSubject.TOGGLE,
								{
									key: IncrementPlanningAnalyticsKey.SHOW_OFFTRACK_DEPENDENCY_LINES,
									state: !state.showOfftrackDependencyLines,
								},
								Action.CLICKED,
							);
						}}
						isChecked={state.showOfftrackDependencyLines}
						viewAs={viewAs}
						footer={
							<HelpPanelButton
								articleId={IPH_IDS.OFFTRACK_DEPENDENCIES}
								appearance="link"
								spacing="none"
								fallbackComponent={
									<Link href={DOC_LINKS.OFFTRACK_DEPENDENCIES} target="_blank">
										{formatMessage(messages.offtrackDependenciesHelpLink)}
									</Link>
								}
							>
								<Text color="color.text.accent.blue">
									{formatMessage(messages.offtrackDependenciesHelpLink)}
								</Text>
							</HelpPanelButton>
						}
					/>
					<Box xcss={selectWrapper}>
						<ViewSettingsSelect
							title={formatMessage(messages.showDependenciesOnIssues)}
							titleIcon={
								<InfoIconWithTooltip
									tooltipContent={formatMessage(
										messages.onlyShowScheduledIssueDependencyLinesTooltip,
									)}
								/>
							}
							testId="software-view-settings.ui.view-settings-menu.views.increment-planning-board.dependency-lines-select"
							isMulti
							isSearchable
							options={slicedOptions}
							formatOptionLabel={onFormatOptionLabel}
							viewAs={viewAs}
							{...(fg('dependency_visualisation_program_board_fe_and_be')
								? { value: selectedIssueOptions }
								: { defaultValue: selectedIssueOptions })}
							placeholder={formatMessage(messages.chooseIssue)}
							isInvalid={state.issueIdsToShowDependencies.length > MAX_SELECTED_ISSUES}
							validationErrorMessage={getViewSettingsValidationMessage()}
							menuIsOpen={
								// undefined is default value for menuIsOpen prop where it's controlled by the click. Setting it to false will prevent the menu from opening
								state.issueIdsToShowDependencies.length <= MAX_SELECTED_ISSUES ? undefined : false
							}
							onInputChange={onInputChange}
							onChange={(selectedOptions) => {
								let selectedIssueIds = [];
								if (Array.isArray(selectedOptions)) {
									selectedIssueIds = selectedOptions.map((option) => option.value);
								} else if (selectedOptions && 'value' in selectedOptions) {
									selectedIssueIds = selectedOptions ? [selectedOptions.value] : [];
								}
								setIssueIdsToShowDependencies(boardId, selectedIssueIds);
								onHandleChange(
									ActionSubject.SELECT,
									{
										key: IncrementPlanningAnalyticsKey.ISSUE_IDS_TO_SHOW_DEPENDENCIES,
										count: selectedIssueIds.length,
									},
									Action.CHANGED,
								);
							}}
						/>
					</Box>
				</Section>
			)}
		</MenuGroup>
	);
};

const menuIconStyles = xcss({
	marginTop: 'space.025',
	width: '16px',
	minWidth: '16px',
	height: '16px',
	minHeight: '16px',
});

const valueIconStyles = xcss({
	width: '16px',
	minWidth: '16px',
	height: '16px',
	minHeight: '16px',
});

const selectWrapper = xcss({
	width: '450px',
});

const infoIconWrapperStyles = xcss({
	height: '24px',
	display: 'flex',
	alignItems: 'center',
});
