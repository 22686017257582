import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	updatedIssueHiddenTitle: {
		id: 'software.main.flags.card-create-unmapped-in-ip-board.updated-issue-hidden-title',
		defaultMessage: "Your issue has been created, but it's hidden by a filter",
		description: 'Title of the flag when the issue is filtered out by filters on IP board',
	},
	updatedIssueHiddenDescription: {
		id: 'software.main.flags.card-create-unmapped-in-ip-board.updated-issue-hidden-description',
		defaultMessage: 'Remove your filters to see it on your board.',
		description: 'Description of the flag when the issue is filtered out by filters on IP board',
	},
	clearFiltersActionText: {
		id: 'software.main.flags.card-create-unmapped-in-ip-board.clear-filters-action-text',
		defaultMessage: 'Clear filters',
		description: 'Text for the action button to clear filters',
	},
});
