import React, { useCallback } from 'react';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { usePopupController } from '@atlassian/jira-servicedesk-common/src/use-popup-controller';
import type { Sprint } from '../../../model/sprint/sprint-types.tsx';
import PopupContent from './popup-content/index.tsx';
import PopupTrigger from './trigger-button/index.tsx';

// Still in progress https://jplat.atlassian.net/browse/BLU-3256
interface Props {
	activeSprints: Array<Sprint>; // = started but not completed
	selectedSprints: Array<Sprint>; // = explicitly selected by the user (or all if no selection)
}
function SprintPopup(props: Props) {
	const { activeSprints, selectedSprints } = props;
	const [isOpen, togglePopup, closePopup] = usePopupController();

	const renderPopupTrigger = useCallback(
		(triggerProps: TriggerProps) => {
			function onClick() {
				// TODO BLU-4236 analytics
				togglePopup();
			}

			// the function is coming from useCallback() so it's not less stable than the caller
			// eslint-disable-next-line react/jsx-no-bind
			return <PopupTrigger {...triggerProps} isSelected={isOpen} onClick={onClick} />;
		},
		[isOpen, togglePopup],
	);

	const renderPopupContent = useCallback(() => {
		return (
			<PopupContent selectedSprints={selectedSprints} activeSprintCount={activeSprints.length} />
		);
	}, [selectedSprints, activeSprints]);

	return (
		<Popup
			isOpen={isOpen}
			onClose={closePopup}
			placement="bottom-end"
			content={renderPopupContent}
			trigger={renderPopupTrigger}
		/>
	);
}

export default SprintPopup;
