import { Observable, type Observable as ObservableType } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import { fg } from '@atlassian/jira-feature-gating';
import { Capability } from '../../../common/capability';
import type { SprintArray } from '../../../model/sprint/sprint-types';
import type { JQLSwimlaneData } from '../../../model/work/work-types';
import {
	fetchBoardDeferredData,
	type BoardDeferredDataParams,
	type BoardDeferredDataResponse,
} from '../../../rest/board/board-deferred-data';
import type { State } from '../../../state/reducers/types';
import { getCapability } from '../../../state/selectors/capability/capability-selectors';
import {
	getEntities,
	rapidViewIdSelector,
} from '../../../state/selectors/software/software-selectors';
import {
	getSprintEntities,
	isSprintsEnabled as isSprintsEnabledSelector,
} from '../../../state/selectors/sprint/sprint-selectors';
import { isSwimlanesByJqlAvailable } from '../../../state/selectors/swimlane/swimlane-mode-selectors';

export interface BoardDeferredData {
	sprints?: SprintArray;
	jqlSwimlanes?: JQLSwimlaneData[];
	isUsingSimplifiedWorkflow?: boolean;
}

function getParamsFromState(state: State): BoardDeferredDataParams {
	const rapidViewId = rapidViewIdSelector(state);
	const isSprintsEnabled = isSprintsEnabledSelector(state);
	const isJqlSwimlanesEnabled = isSwimlanesByJqlAvailable(state);
	const sprintIds = isSprintsEnabled
		? Object.values(getSprintEntities(state)).map((sprint) => sprint.id)
		: undefined;
	const isInlineColumnEditEnabled = getCapability(state)(Capability.INLINE_COLUMN_EDIT);

	const fields = [
		...(isSprintsEnabled && !fg('simplified_and_update_sprint_when_skip_extra')
			? ['sprints_permission']
			: []),
		...(isJqlSwimlanesEnabled ? ['swimlanes_query'] : []),
		...(isInlineColumnEditEnabled && !fg('simplified_and_update_sprint_when_skip_extra')
			? ['simplified_workflow']
			: []),
	];

	return {
		rapidViewId,
		fields,
		sprintIds,
	};
}

function transformResponse(
	state: State,
	{ sprintsData, swimlanesData, workflowData }: BoardDeferredDataResponse,
): BoardDeferredData {
	// The sprint data returned from deferred endpoint does not include everything, so fill out the sprint type with value from state, overriding updated values (canUpdateSprint).
	const existingSprints = getSprintEntities(state);
	const sprints =
		sprintsData?.sprints.map(({ id, canUpdateSprint }) => ({
			...existingSprints[id],
			canUpdateSprint,
		})) ?? undefined;

	// The swimlane data returned from the deferred endpoint is not complete, so fill out the swimlanes with value from state, while overriding updated values (query).
	const existingSwimlanes = getEntities(state).jqlSwimlanes.swimlanes;
	const jqlSwimlanes = swimlanesData
		? existingSwimlanes.map((swimlane) => ({
				...swimlane,
				query:
					swimlanesData.customSwimlanesData.swimlanes.find((s) => String(s.id) === swimlane.id)
						?.query ?? swimlane.query,
			}))
		: undefined;

	const isUsingSimplifiedWorkflow = workflowData?.isUsingSimplifiedWorkflow;

	return { sprints, jqlSwimlanes, isUsingSimplifiedWorkflow };
}

export function boardDeferredDataService(state: State): ObservableType<BoardDeferredData> {
	const params = getParamsFromState(state);
	return params.fields.length
		? fetchBoardDeferredData(params).map((response) => transformResponse(state, response))
		: Observable.of({});
}
