import React, { useState, useMemo, useRef } from 'react';
import groupBy from 'lodash/groupBy';
import type { EntryPointProps } from 'react-relay';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { LoadingButton } from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { Stack, xcss, Box, media } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { usePathParam } from '@atlassian/react-resource-router';
import { MAX_ACTIVE_BOARDS } from '../../common/constants';
import { getBoardUrl, getManageBoardSettingsUrl } from '../../common/utils';
import { useFetchIncrements } from '../../services/fetch-increments';
import type { Increment } from '../../services/fetch-increments/types.tsx';
import IncrementCreation from '../increment-creation/main.tsx';
import messages from './messages';
import type { Props, IncrementItemProps, BoardSwitcherDropdownProps } from './types';

const BoardItem = ({ board: { id, name }, currentId, analyticsEvent }: IncrementItemProps) => (
	<DropdownItem
		isSelected={id === currentId}
		key={`active-board-${id}`}
		href={getBoardUrl(id)}
		title={name}
		onClick={() => {
			fireUIAnalytics(analyticsEvent, 'switchProgramBoard');
		}}
		UNSAFE_shouldDisableRouterLink
	>
		{name}
	</DropdownItem>
);

export const BoardSwitcherDropdown = ({
	boardId,
	defaultOpen,
	onOpenCreateModal,
	triggerLabel,
	isReadOnly,
	returnFocusRef,
}: BoardSwitcherDropdownProps) => {
	const [planId] = usePathParam('planId');
	const [scenarioId] = usePathParam('scenarioId');
	const { increments, isLoading } = useFetchIncrements({ planId });
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const analyticsEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'dropdownItem',
	});

	const currentBoard = useMemo(
		() => increments?.find(({ id }) => id === boardId),
		[increments, boardId],
	);

	const groupedOptions = useMemo(() => groupBy(increments, 'status'), [increments]);

	const activeOptions = groupedOptions.ACTIVE;
	const pastOptions = groupedOptions.PAST;

	const triggerContent = triggerLabel ?? currentBoard?.name;

	return (
		<DropdownMenu
			trigger={({ triggerRef, ...props }) => (
				<Box ref={triggerRef} xcss={triggerStyles}>
					{fg('increment_board_a11y_fix') ? (
						<Button
							{...props}
							ref={returnFocusRef}
							isLoading={isLoading}
							iconAfter={ChevronDownIcon}
						>
							{/* The LoadingButton takes on the width on the content while loading, so we need a placeholder to give it some width before the currentBoard is loaded */}
							{triggerContent ?? <Box xcss={placeholderStyles} />}
						</Button>
					) : (
						<LoadingButton
							{...props}
							isLoading={isLoading}
							iconAfter={<ChevronDownIcon label="" />}
						>
							{/* The LoadingButton takes on the width on the content while loading, so we need a placeholder to give it some width before the currentBoard is loaded */}
							{triggerContent ?? <Box xcss={placeholderStyles} />}
						</LoadingButton>
					)}
				</Box>
			)}
			returnFocusRef={returnFocusRef}
			shouldRenderToParent
			isLoading={isLoading}
			placement="bottom-start"
			defaultOpen={defaultOpen}
		>
			<Stack xcss={boardGroupStyles}>
				{activeOptions && activeOptions.length > 0 && (
					<DropdownItemGroup
						title={`${formatMessage(messages.activeGroupLabel)} (${activeOptions.length}/${MAX_ACTIVE_BOARDS})`}
					>
						{activeOptions.map((board) => (
							<BoardItem
								key={`active-board-${board.id}`}
								board={board}
								currentId={currentBoard?.id}
								analyticsEvent={analyticsEvent}
							/>
						))}
					</DropdownItemGroup>
				)}
				{pastOptions && pastOptions.length > 0 && (
					<DropdownItemGroup title={formatMessage(messages.pastGroupLabel)}>
						{pastOptions.map((board) => (
							<BoardItem
								key={`past-board-${board.id}`}
								board={board}
								currentId={currentBoard?.id}
								analyticsEvent={analyticsEvent}
							/>
						))}
					</DropdownItemGroup>
				)}
			</Stack>
			{!isReadOnly && (
				<DropdownItemGroup hasSeparator>
					<DropdownItem
						testId="portfolio-3-plan-increment-common.ui.board-switcher.create-button"
						isDisabled={activeOptions && activeOptions.length >= MAX_ACTIVE_BOARDS}
						onClick={() => {
							fireUIAnalytics(analyticsEvent, 'createProgramBoard');
							onOpenCreateModal();
						}}
					>
						{formatMessage(messages.createProgramBoard)}
					</DropdownItem>
					<DropdownItem
						href={getManageBoardSettingsUrl(planId, scenarioId)}
						title={formatMessage(messages.manageProgramBoardsTitle)}
						onClick={() => {
							fireUIAnalytics(analyticsEvent, 'manageProgramBoards');
						}}
						UNSAFE_shouldDisableRouterLink
					>
						{formatMessage(messages.manageProgramBoards)}
					</DropdownItem>
				</DropdownItemGroup>
			)}
		</DropdownMenu>
	);
};

const BoardSwitcher = ({ boardId, defaultOpen, triggerLabel, isReadOnly }: Props) => {
	const [isCreateBoardModalOpen, setIsCreateBoardModalOpen] = useState(false);

	const openCreateBoardModal = () => {
		setIsCreateBoardModalOpen(true);
	};

	const closeCreateBoardModal = () => {
		setIsCreateBoardModalOpen(false);
	};

	const onIncrementCreated = (newBoard: Increment) => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.location.replace(getBoardUrl(newBoard.id));
	};
	const returnFocusRef = useRef<HTMLButtonElement | null>(null);

	return (
		<>
			<IncrementCreation
				onIncrementCreated={onIncrementCreated}
				isOpen={isCreateBoardModalOpen}
				onClose={closeCreateBoardModal}
				returnFocusRef={fg('increment_board_a11y_fix') ? returnFocusRef : undefined}
			/>
			<BoardSwitcherDropdown
				boardId={boardId}
				defaultOpen={defaultOpen}
				onOpenCreateModal={openCreateBoardModal}
				triggerLabel={triggerLabel}
				isReadOnly={isReadOnly}
				returnFocusRef={fg('increment_board_a11y_fix') ? returnFocusRef : undefined}
			/>
		</>
	);
};

export default BoardSwitcher;

export const AsyncBoardSwitcher = ({ props }: EntryPointProps<{}, {}, Props, {}>) => (
	<BoardSwitcher {...props} />
);

const boardGroupStyles = xcss({
	maxHeight: '600px',
	overflow: 'auto',
	textAlign: 'left',
});

const triggerStyles = xcss({
	marginLeft: 'space.200',
	maxWidth: '175px',
	[media.above.md]: {
		maxWidth: '240px',
	},
	[media.above.lg]: {
		maxWidth: '280px',
	},
});

const placeholderStyles = xcss({
	width: '80px',
});
