import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';
import type { CardTransitionEntities } from '../../../../model/card-transition/card-transition-types';
import type { Column, ColumnId } from '../../../../model/column/column-types';
import type { Issue } from '../../../../model/issue/issue-types';
import type { OptimisticId } from '../../../../model/software/software-types';
import flagMessages from '../../../../view/flags/messages';
import { warningFlagMeta, type FlagMeta } from '../../meta/flag-meta';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiMeta,
	type OptimisticUiMeta,
} from '../../meta/optimistic-ui-meta';

export const COLUMN_DELETE_REQUEST = 'state.actions.column.delete.COLUMN_DELETE_REQUEST' as const;

export type ColumnDeleteRequestAction = {
	type: typeof COLUMN_DELETE_REQUEST;
	payload: {
		columnId: number;
		closestColumn: Column | null;
		issuesInColumn: Issue[];
	};
	meta: {
		analyticsEvent?: AnalyticsEvent;
	} & OptimisticUiMeta;
};

export const columnDeleteRequest = (
	columnId: number,
	closestColumn: Column | null,
	issuesInColumn: Issue[] = [],
	analyticsEvent?: AnalyticsEvent,
): ColumnDeleteRequestAction => ({
	type: COLUMN_DELETE_REQUEST,
	payload: {
		columnId,
		closestColumn,
		issuesInColumn,
	},
	meta: {
		...beginOptimisticUiMeta(),
		analyticsEvent,
	},
});

export const COLUMN_DELETE_SUCCESS = 'state.actions.column.delete.COLUMN_DELETE_SUCCESS' as const;

export type ColumnDeleteSuccessAction = {
	type: typeof COLUMN_DELETE_SUCCESS;
	payload: {
		closestColumn: Column | null;
		columns: Column[];
		cardTransitions: CardTransitionEntities | null;
	};
	meta: OptimisticUiMeta;
};

export const columnDeleteSuccess = (
	optimisticId: OptimisticId,
	closestColumn: Column | null,
	columns: Column[],
	cardTransitions: CardTransitionEntities | null,
): ColumnDeleteSuccessAction => ({
	type: COLUMN_DELETE_SUCCESS,
	payload: { closestColumn, columns, cardTransitions },
	meta: commitOptimisticUiMeta(optimisticId),
});

export const COLUMN_DELETE_FAILURE = 'state.actions.column.delete.COLUMN_DELETE_FAILURE' as const;

export type ColumnDeleteFailureAction = {
	type: typeof COLUMN_DELETE_FAILURE;
	payload: {
		columnId: ColumnId;
	};
	meta: OptimisticUiMeta & FlagMeta;
};

export const columnDeleteFailure = (
	optimisticId: OptimisticId,
	columnId: ColumnId,
	error = '',
): ColumnDeleteFailureAction => ({
	type: COLUMN_DELETE_FAILURE,
	payload: {
		columnId,
	},
	meta: {
		...revertOptimisticUiMeta(optimisticId),
		...warningFlagMeta({
			titleMessage: flagMessages.genericTitle,
			descriptionMessage: error ? flagMessages.failureDescription : flagMessages.genericDescription,
			context: { error },
		}),
	},
});

export const COLUMN_DELETE = 'state.actions.column.delete.COLUMN_DELETE' as const;

export type ColumnDeleteAction = {
	type: typeof COLUMN_DELETE;
	payload: {
		columnId: ColumnId;
		hasIssues: boolean;
		hasIssuesNotOnBoard: boolean;
	};
};

export const columnDelete = (
	columnId: ColumnId,
	hasIssues: boolean,
	hasIssuesNotOnBoard: boolean,
): ColumnDeleteAction => ({
	type: COLUMN_DELETE,
	payload: {
		columnId,
		hasIssues,
		hasIssuesNotOnBoard,
	},
});

export const COLUMN_DELETE_MODAL_CLOSE =
	'state.actions.column.delete.COLUMN_DELETE_MODAL_CLOSE' as const;

export type ColumnDeleteModalCloseAction = {
	type: typeof COLUMN_DELETE_MODAL_CLOSE;
};

export const columnDeleteModalClose = (): ColumnDeleteModalCloseAction => ({
	type: COLUMN_DELETE_MODAL_CLOSE,
});

export type Action =
	| ColumnDeleteRequestAction
	| ColumnDeleteSuccessAction
	| ColumnDeleteFailureAction
	| ColumnDeleteAction
	| ColumnDeleteModalCloseAction;
