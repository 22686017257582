import camelCase from 'lodash/camelCase';
import { expVal } from '@atlassian/jira-feature-experiments';
import { NO_BOARD_ID } from '@atlassian/jira-insights-common-constants/src/common/constants/storage-keys';
import { EstimationUnit } from '@atlassian/jira-insights-common-constants/src/common/utils/constants';
import { messages as commonMessages } from '../messages';
import type {
	EstimationUnitType,
	EstimationUnitMessageType,
	Estimation,
	PermissionStatusType,
} from '../types';

type GetMessageFromEstimationUnitProps = {
	estimationUnit: EstimationUnitType;
	messages: EstimationUnitMessageType;
};

export const getMessageFromEstimationUnit = ({
	estimationUnit,
	messages,
}: GetMessageFromEstimationUnitProps) => {
	if (!messages[estimationUnit]) {
		throw new Error(
			`Unsupported estimationUnit: ${estimationUnit} for ${JSON.stringify(messages)}`,
		);
	}

	return messages[estimationUnit];
};

export const getCommonMessageDescriptorFromEstimationUnit = (
	estimationUnit: EstimationUnitType,
) => {
	let issueTermRefreshSuffix = '';
	if (
		estimationUnit === 'ISSUE_COUNT' &&
		expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
	)
		issueTermRefreshSuffix = 'IssueTermRefresh';
	const estimationUnitCamelCase = camelCase(estimationUnit);
	const labelDescriptorName = `${estimationUnitCamelCase}Label${issueTermRefreshSuffix}`;
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ issueCountLabel: MessageDescriptorV2; storyPointsLabel: MessageDescriptorV2; timeOriginalEstimateLabel: MessageDescriptorV2; sprints: MessageDescriptorV2; issueCount: MessageDescriptorV2; storyPoints: MessageDescriptorV2; }'.
	if (!commonMessages[labelDescriptorName]) {
		throw new Error(
			`Unsupported estimationUnit: ${estimationUnit}, label not found for ${labelDescriptorName}} in ${JSON.stringify(
				Object.keys(commonMessages),
			)}`,
		);
	}

	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ issueCountLabel: MessageDescriptorV2; storyPointsLabel: MessageDescriptorV2; timeOriginalEstimateLabel: MessageDescriptorV2; sprints: MessageDescriptorV2; issueCount: MessageDescriptorV2; storyPoints: MessageDescriptorV2; }'.
	return commonMessages[labelDescriptorName];
};

export const getEstimatedUnit = (
	estimationUnit: EstimationUnitType,
	estimation: Estimation,
): number => {
	// these endpoints returning null shouldn't happen, so let's degrade gracefully
	switch (estimationUnit) {
		case EstimationUnit.ISSUE_COUNT:
			return estimation.issueCount || 0;
		case EstimationUnit.STORY_POINTS:
			return estimation.storyPoints != null ? parseFloat(estimation.storyPoints.toFixed(1)) : 0;
		case EstimationUnit.TIME_TRACKING:
			return estimation.time || 0;
		default:
			throw new Error(`Unexpected estimation type: ${estimationUnit}`);
	}
};

export const getUpdatedEstimation = (
	estimationUnit: EstimationUnitType,
	estimation: Estimation,
	value: number,
): Estimation => {
	switch (estimationUnit) {
		case EstimationUnit.ISSUE_COUNT:
			return {
				...estimation,
				issueCount: value,
			};
		case EstimationUnit.STORY_POINTS:
			return {
				...estimation,
				storyPoints: value,
			};
		case EstimationUnit.TIME_TRACKING:
			return {
				...estimation,
				time: value,
			};
		default:
			throw new Error(`Unexpected estimation type: ${estimationUnit}`);
	}
};

export const getPermissionEnum = (
	value: PermissionStatusType | null | null | undefined,
	defaultValue: PermissionStatusType,
): PermissionStatusType => (value === null || value === undefined ? defaultValue : value);

export const buildNotificationsDismissalNamespace = (boardId?: string | number | null) =>
	`print-halfway-alert-${boardId ?? NO_BOARD_ID}`;

export const buildNotificationsAutoExpandNamespace = (boardId?: string | number | null) =>
	`auto-expand-halfway-alert-${boardId ?? NO_BOARD_ID}`;

type IsValidBoardIdProps = {
	boardId: string | number | undefined | null;
};

export const isValidBoardId = ({ boardId }: IsValidBoardIdProps): boolean => {
	// BoardId must be a positive integer
	if (
		boardId === undefined ||
		boardId === null ||
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		!(Number.isInteger(boardId) && (boardId as number) > 0)
	) {
		return false;
	}
	return true;
};

export const isValidBoardIdString = ({ boardId }: IsValidBoardIdProps): boolean => {
	if (typeof boardId !== 'string') return false;
	return /^[0-9]+$/.test(boardId) && Number.parseInt(boardId, 10) > 0;
};
