import React, { useCallback, type ComponentType } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import AkTooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

const DATA_TEST_ID =
	'software-board.board-container.board.column.header.menu.item-delete.dropdown-item';

export type Props = {
	isLastRemainingColumn: boolean;
	isDisabled: boolean;
	onClick: () => void;
};

export const ColumnDeleteMenuItem = ({ isLastRemainingColumn, isDisabled, onClick }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOnClick = useCallback(() => {
		onClick();
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'columnSettingsDeleteMenuItem',
				action: 'clicked',
			}),
		);
	}, [onClick, createAnalyticsEvent]);

	const renderDropdownItem = useCallback(
		(disabled: boolean) => (
			<DropdownItem data-testid={DATA_TEST_ID} isDisabled={disabled} onClick={handleOnClick}>
				{formatMessage(messages.delete)}
			</DropdownItem>
		),
		[formatMessage, handleOnClick],
	);

	return isLastRemainingColumn ? (
		<AkTooltip content={formatMessage(messages.lastColumn)}>{renderDropdownItem(true)}</AkTooltip>
	) : (
		renderDropdownItem(isDisabled)
	);
};

const hideableMenuItem =
	(MenuItem: ComponentType<Props>): ComponentType<Props> =>
	(props: Props) =>
		!props.isDisabled ? <MenuItem {...props} /> : null;

export default hideableMenuItem(ColumnDeleteMenuItem);
