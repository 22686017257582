import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issueHiddenTitle: {
		id: 'software-flags.card-create-unmapped.issue-hidden-title',
		defaultMessage:
			'Changes saved, but {numIssues, plural, one {issue} other {issues}} not visible',
		description: "Unmapped Issue's Status Title",
	},
	issueHiddenDescription: {
		id: 'software-flags.card-create-unmapped.issue-hidden-description',
		defaultMessage:
			'Issue {linkStart}{linkEnd} has a status that’s not visible on the board and backlog.',
		description: 'Unmapped Issues Status Description',
	},
	multipleIssuesHiddenDescription: {
		id: 'software-flags.card-create-unmapped.multiple-issues-hidden-description',
		defaultMessage:
			"{numIssues, plural, one {{numIssues} issue has} other {{numIssues} issues have}} been created, but one or more have a status that's not visible on the board and backlog",
		description: 'Unmapped Issues Status Description',
	},
	issueLink: {
		id: 'software-flags.card-create-unmapped.issue-link',
		defaultMessage: 'View {numIssues, plural, one {issue} other {issues}}',
		description: 'Link to the issue',
	},
	updatedIssueHiddenTitle: {
		id: 'software-flags.card-create-unmapped.updated-issue-hidden-title',
		defaultMessage:
			'Changes saved, but {numIssues, plural, one {Issue} other {Issues}} not visible',
		description: 'Title when the issue is not visible on board and backlog',
	},
	updatedIssueHiddenDescription: {
		id: 'software-flags.card-create-unmapped.updated-issue-hidden-description',
		defaultMessage:
			'For an issue to be visible, it must match your project and filters, and have a status that’s assigned to the board.',
		description: 'Description when the issue is not visible on board and backlog',
	},
});
