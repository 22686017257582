import type { GIC_LIFECYCLE_EVENTS, GIC_LIFECYCLE_EVENTS_PAYLOADS } from './constants';
import { PubSubHub } from './pub-sub-hub';
import type { PubSub } from './pub-sub-hub/types';

const GICLifeCycleEventsService: PubSub<GIC_LIFECYCLE_EVENTS, GIC_LIFECYCLE_EVENTS_PAYLOADS> =
	new PubSubHub<GIC_LIFECYCLE_EVENTS, GIC_LIFECYCLE_EVENTS_PAYLOADS>();

export const useGICLifeCycleEvents = () => ({
	...GICLifeCycleEventsService,
});
