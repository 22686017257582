import { useState } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { ERROR_REPORTING_TEAM, PACKAGE_NAME } from '../../common/constants';

export const useRestoreCustomField = ({
	planId,
	customFieldId,
}: {
	planId: string | undefined;
	customFieldId: string | undefined;
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<undefined | Error>(undefined);
	const [isSuccessful, setIsSuccessful] = useState(false);

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const handleError = (error: Error | undefined) => {
		setIsSuccessful(false);
		setError(error);
		fireErrorAnalytics({
			meta: {
				id: 'restoreCustomField',
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});
	};
	const restoreCustomField = async () => {
		if (isLoading) return;
		if (!planId) {
			handleError(new Error('planId is empty in the restore custom field request'));
			return;
		}
		if (!customFieldId) {
			handleError(new Error('customFieldId is empty in the restore custom field request'));
			return;
		}

		setIsLoading(true);

		try {
			await performPostRequest('/rest/jpo/1.0/customfield/add', {
				body: JSON.stringify({
					planId,
					planCustomFields: [
						{
							customFieldId,
							filteringAllowed: true,
						},
					],
				}),
			});
			setIsSuccessful(true);
			// eslint-disable-next-line @typescript-eslint/no-shadow
		} catch (error) {
			handleError(new Error('Failed to restore custom field in Program board', { cause: error }));
		} finally {
			setIsLoading(false);
		}
	};

	return {
		restoreCustomField,
		isLoading,
		isSuccessful,
		error,
	};
};
