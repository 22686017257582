import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { IssueLinksStats as IssueLinksStatsPopup } from './ui';
import type { IssueLinksStatsProps } from './ui/types';

export const IssueLinksStats = (props: IssueLinksStatsProps) => (
	<JSErrorBoundary
		id="jira-issue-links-stats"
		packageName="jiraIssueLinksStats"
		teamName="jira-werewolves"
		fallback="unmount"
	>
		<IssueLinksStatsPopup {...props} />
	</JSErrorBoundary>
);
