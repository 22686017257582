import React, { useEffect, useRef, type ReactNode } from 'react';
import { AkFlag } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useEvent } from '@atlassian/jira-software-react-use-event';
import type { UndoFlagData } from '../../../model/flags/flag-types';
import { useBoardDispatch } from '../../../state';
import { undoFlagPerformCommit, undoFlagPerformUndo } from '../../../state/actions/flags';
import { messages } from './messages';

const DEFAULT_TIME_TO_COMPLETE = 8000;

type Props = {
	flag: UndoFlagData;
	icon: ReactNode;
	onDismissFlag: (flagId: string | number) => void;
};

export function useUndoFlagHandlers({
	flag,
	onDismissFlag,
}: {
	flag: { id: string };
	onDismissFlag: Props['onDismissFlag'];
}): {
	onClickUndo: () => void;
	onDismissed: () => void;
} {
	const hasDismissed = useRef(false);
	const dispatch = useBoardDispatch();
	const onClickUndo = useEvent(() => {
		if (hasDismissed.current) return;

		hasDismissed.current = true;
		dispatch(undoFlagPerformUndo(flag.id));
		onDismissFlag(flag.id);
	});

	const onDismissed = useEvent(() => {
		if (hasDismissed.current) return;

		hasDismissed.current = true;
		onDismissFlag(flag.id);
		dispatch(undoFlagPerformCommit(flag.id));
	});

	return { onClickUndo, onDismissed };
}

export function useAutoDismiss(onDismissed: () => void) {
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			onDismissed();
		}, DEFAULT_TIME_TO_COMPLETE);

		return () => {
			clearTimeout(timeoutId);
			onDismissed();
		};
	}, [onDismissed]);
}

export function UndoFlag({ flag, icon, onDismissFlag }: Props) {
	const { formatMessage } = useIntl();
	const { onClickUndo, onDismissed } = useUndoFlagHandlers({ flag, onDismissFlag });
	useAutoDismiss(onDismissed);

	return (
		<AkFlag
			id={flag.id}
			icon={icon}
			testId="software-board.flags.undo-flag.ak-flag"
			title={formatMessage(flag.titleMessage, flag.context)}
			description={formatMessage(flag.descriptionMessage, flag.context)}
			actions={[
				...flag.links.map((action) => ({
					...action,
					content: formatMessage(action.content, flag.context),
					target: '_blank',
				})),
				{
					testId: 'software-board.flags.undo-flag.ak-flag-undo-button',
					content: formatMessage(messages.revertChanges),
					onClick: onClickUndo,
				},
			]}
			onDismissed={onDismissed} // It is expected that if a user presses X they are accepting the change
		/>
	);
}
