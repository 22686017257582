import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/concatMap';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import {
	fireTrackAnalytics,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import rankColumnsService from '../../services/column-graphql/rank';
import {
	columnRankFailure,
	COLUMN_RANK_REQUEST,
	columnRankSuccess,
} from '../../state/actions/column/rank';
import {
	getColumnIndex,
	hasDoneColumnInLastPositionSelector,
} from '../../state/selectors/column/column-selectors';
import {
	contextPathSelector,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors';
import { isSprintsEnabled as isSprintsEnabledSelector } from '../../state/selectors/sprint/sprint-selectors';
import type { ActionsObservable, MiddlewareAPI } from '../../state/types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(COLUMN_RANK_REQUEST).concatMap((action) => {
		const state = store.getState();
		const contextPath = contextPathSelector(state);
		const boardId = rapidViewIdSelector(state);
		const hasDoneColumnInLastPosition = hasDoneColumnInLastPositionSelector(state);
		const isSprintsEnabled = isSprintsEnabledSelector(state);

		const {
			payload: { columnId, sourceColumnIndex },
			meta: { optimistic, analyticsEvent },
		} = action;

		// The redux state contains optimistic order, hence we can use columnID instead of targetID
		const position = getColumnIndex(state, columnId);

		return rankColumnsService(contextPath, boardId, columnId, position)
			.flatMap(() => {
				if (analyticsEvent && !isNil(sourceColumnIndex)) {
					fireTrackAnalytics(analyticsEvent, 'column moved', {
						initialPosition: sourceColumnIndex + 1,
						destinationPosition: position + 1,
						hasDoneColumnInLastPosition,
						isSprintsEnabled,
					});
				}
				return Observable.of(columnRankSuccess(optimistic.id, columnId));
			})
			.catch((error) => {
				if (analyticsEvent && !isNil(sourceColumnIndex)) {
					fireOperationalAnalytics(analyticsEvent, 'column moveFailed', {
						error,
						initialColumn: sourceColumnIndex + 1,
						destinationColumn: position + 1,
					});
				}
				return Observable.of(columnRankFailure(optimistic.id, columnId, error));
			});
	});
