import React from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { token } from '@atlaskit/tokens';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import type { Props } from './types';

export const CardIcons = (props: Props) => {
	const { children } = props;

	return <Container>{children}</Container>;
};

export default CardIcons;

// eslint-disable-next-line  @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContainerControl = styled.div({
	boxSizing: 'border-box',
	display: 'flex',
	justifyContent: 'space-around',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > *': {
		margin: `0 ${token('space.050', '4px')} 0 0`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > :last-child': {
		marginRight: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerExperiment = styled2.div({
	boxSizing: 'border-box',
	display: 'flex',
	justifyContent: 'space-around',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > *': {
		margin: `0 ${token('space.050', '4px')} 0 0`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > :last-child': {
		marginRight: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	ContainerExperiment,
	ContainerControl,
);
