import { useEffect } from 'react';
import { useRouter, useRouterActions } from '@atlassian/react-resource-router';
import { View } from '../../../common/types/constant.tsx';
import type { PanelState } from '../../../common/types/state.tsx';
import { parseSearchQuery, setPanels } from '../../../common/utils';
import { getViewSettingsPanels as getViewSettingsPanelsFromLocalStorage } from '../../../services/local-storage';
import { useShowPanels, useViewSettingsActions } from '../../index';
// This hook is used to sync the react-sweet-state, URL, and localStorage stores.
// This is used exclusively for the Version and Epic panels state.
export const useOnBacklog = (
	boardId: number,
	view: (typeof View)[keyof typeof View],
	showEpicAsPanel = false,
	isVersionsEnabled = false,
) => {
	const [{ location }] = useRouter();
	const showPanels = useShowPanels();
	const { setViewSettingsPanels } = useViewSettingsActions();
	const { replace } = useRouterActions();

	// Sync react-sweet-state store with URL or localStorage state on mount.
	useEffect(() => {
		if (view !== View.BACKLOG) {
			return;
		}

		const panelsFromQuery = parseSearchQuery(location.search);
		const panelsFromLocalStorage = getViewSettingsPanelsFromLocalStorage({ boardId, view });

		// Search query state takes precedence over localStorage state, which supersedes react-sweet-state state.
		// If there are panels from the search query, update localStorage and react-sweet-state.
		// If not, update react-sweet-state using localStorage instead.
		let panelState = panelsFromLocalStorage;
		if (Object.values(panelsFromQuery).filter(Boolean).length !== 0) {
			const queryPanelState: PanelState = {
				EPIC_PANEL: panelsFromQuery.EPIC_PANEL ?? false,
				VERSION_PANEL: panelsFromQuery.VERSION_PANEL ?? false,
			};
			panelState = queryPanelState;
		}
		setViewSettingsPanels({ boardId, view, panelState });
		// Removed `location.search` from the dependency array since we only want this hook to fire once (on mount).
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [boardId, isVersionsEnabled, setViewSettingsPanels, showEpicAsPanel, view]);

	// Sync URL with react-sweet-state store on mount and when react-sweet-state store changes.
	useEffect(() => {
		if (view !== View.BACKLOG) {
			return;
		}

		const url = setPanels(showPanels, location);
		if (location.search !== `?${url.search}`) {
			replace(url);
		}
		// `Location` has a `hash` attribute which causes unnecessary calls, so we are only using `search` in the dependency array.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.search, showPanels, replace, view]);
};
