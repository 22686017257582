import type { Observable } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { UpdateValues } from '../../model/issue/issue-increment-planning-types';
import type { IssueKey } from '../../model/issue/issue-types';
import type { getIssueCreateOrUpdateFields } from '../../services/issue/issue-data-transformer';
import { getIssueEditUrl } from '../software/software-api-urls';

/**
 * Perform update through edit issue REST API
 */
export function issueUpdateRest(
	issueKey: IssueKey,
	values: ReturnType<typeof getIssueCreateOrUpdateFields>,
): Observable<unknown> {
	return fetchJson$(getIssueEditUrl('', issueKey), {
		method: 'PUT',
		body: JSON.stringify({
			fields: values,
		}),
	});
}

/**
 * Perform update through JPO issue update REST API
 */

export function issueUpdateJPORest({
	issueIds,
	planId,
	scenarioId,
	values,
}: {
	issueIds: string[];
	planId: string;
	scenarioId: string;
	values: UpdateValues;
}): Observable<unknown> {
	return fetchJson$('/rest/jpo/1.0/scenarios/updateIssues', {
		method: 'POST',
		body: JSON.stringify({
			planId,
			scenarioId,
			itemKeys: issueIds,
			description: values,
		}),
	});
}
