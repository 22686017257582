import React from 'react';
import Icon from '@atlaskit/icon';
import type { Size } from '@atlaskit/icon/types';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

type Props = {
	size: Size;
	label: string;
};

export const BitbucketPullrequestMerged = ({ size, label }: Props) => (
	<Icon
		size={size}
		label={label}
		glyph={() => (
			<svg
				fill="none"
				height="24"
				viewBox="0 0 24 24"
				width="24"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="m0 0h24v24h-24z" fill={token('color.icon.inverse', colors.N0)} fillOpacity=".01" />
				<g clipRule="evenodd" fill={token('color.icon.success', colors.G300)} fillRule="evenodd">
					<path d="m7 5c-.55228 0-1 .44772-1 1s.44772 1 1 1 1-.44772 1-1-.44772-1-1-1zm-3 1c0-1.65685 1.34315-3 3-3s3 1.34315 3 3c0 1.30622-.83481 2.41746-2 2.82929v11.17071c0 .5523-.44772 1-1 1s-1-.4477-1-1v-11.17071c-1.16519-.41183-2-1.52307-2-2.82929z" />
					<path d="m7 7c.55228 0 1 .44772 1 1v2c0 3.5912 2.7043 6.5508 6.1876 6.9534.4247-1.1408 1.5237-1.9534 2.8124-1.9534 1.6569 0 3 1.3431 3 3s-1.3431 3-3 3c-1.3207 0-2.4421-.8534-2.8428-2.0389-4.57535-.4249-8.1572-4.2747-8.1572-8.9611v-2c0-.55228.44772-1 1-1zm10 10c-.5523 0-1 .4477-1 1s.4477 1 1 1 1-.4477 1-1-.4477-1-1-1z" />
					<path d="m20.7071 4.29289c.3905.39053.3905 1.02369 0 1.41422l-4 4c-.3905.39049-1.0237.39049-1.4142 0l-2-2c-.3905-.39053-.3905-1.02369 0-1.41422.3905-.39052 1.0237-.39052 1.4142 0l1.2929 1.2929 3.2929-3.2929c.3905-.39052 1.0237-.39052 1.4142 0z" />
				</g>
			</svg>
		)}
	/>
);
export const BitbucketPullrequestDeclined = ({ size, label }: Props) => (
	<Icon
		size={size}
		label={label}
		glyph={() => (
			<svg
				fill="none"
				height="24"
				viewBox="0 0 24 24"
				width="24"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="m0 0h24v24h-24z" fill={token('color.icon.inverse', colors.N0)} fillOpacity=".01" />
				<g clipRule="evenodd" fill={token('color.icon.danger', colors.R300)} fillRule="evenodd">
					<path d="m7 5c-.55228 0-1 .44772-1 1s.44772 1 1 1 1-.44772 1-1-.44772-1-1-1zm-3 1c0-1.65685 1.34315-3 3-3s3 1.34315 3 3c0 1.30622-.83481 2.41746-2 2.82929v11.17071c0 .5523-.44772 1-1 1s-1-.4477-1-1v-11.17071c-1.16519-.41183-2-1.52307-2-2.82929z" />
					<path d="m17 11c.5523 0 1 .4477 1 1v3.1707c1.1652.4118 2 1.5231 2 2.8293 0 1.6569-1.3431 3-3 3s-3-1.3431-3-3c0-1.3062.8348-2.4175 2-2.8293v-3.1707c0-.5523.4477-1 1-1zm0 6c-.5523 0-1 .4477-1 1s.4477 1 1 1 1-.4477 1-1-.4477-1-1-1z" />
					<path d="m13.7929 2.79289c.3905-.39052 1.0237-.39052 1.4142 0l1.7929 1.7929 1.7929-1.7929c.3905-.39052 1.0237-.39052 1.4142 0 .3905.39053.3905 1.02369 0 1.41422l-1.7929 1.79289 1.7929 1.79289c.3905.39053.3905 1.02369 0 1.41422-.3905.39052-1.0237.39052-1.4142 0l-1.7929-1.7929-1.7929 1.7929c-.3905.39052-1.0237.39052-1.4142 0-.3905-.39053-.3905-1.02369 0-1.41422l1.7929-1.79289-1.7929-1.79289c-.3905-.39053-.3905-1.02369 0-1.41422z" />
				</g>
			</svg>
		)}
	/>
);

export const DeploymentProdSuccess = ({ size, label }: Props) => (
	<Icon
		size={size}
		label={label}
		glyph={() => (
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					width="16"
					height="16"
					fill={token('color.icon.inverse', colors.N0)}
					fillOpacity="0.01"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M1 9.74998C1 11.3714 2.22503 12.7067 3.80003 12.8808V12.9H12.2V12.8807C13.775 12.7066 15 11.3713 15 9.74998C15 8.31668 14.0427 7.10697 12.7326 6.72531C12.1716 4.63715 10.2653 3.09998 8.00006 3.09998C5.73479 3.09998 3.82855 4.63713 3.2675 6.72527C1.95735 7.10688 1 8.31663 1 9.74998ZM6.84995 7.94994C6.78825 7.88606 6.71445 7.83511 6.63285 7.80005C6.55124 7.765 6.46348 7.74655 6.37467 7.74578C6.28586 7.745 6.19778 7.76193 6.11558 7.79556C6.03338 7.82919 5.9587 7.87885 5.8959 7.94165C5.8331 8.00445 5.78344 8.07913 5.74981 8.16133C5.71618 8.24353 5.69925 8.33161 5.70003 8.42042C5.7008 8.50923 5.71925 8.59699 5.7543 8.6786C5.78936 8.7602 5.84031 8.834 5.90419 8.8957L7.0542 10.0457C7.17963 10.171 7.34973 10.2415 7.52708 10.2415C7.70444 10.2415 7.87453 10.171 7.99996 10.0457L10.0958 7.94996C10.1597 7.88826 10.2106 7.81446 10.2457 7.73286C10.2808 7.65125 10.2992 7.56349 10.3 7.47467C10.3007 7.38586 10.2838 7.29779 10.2502 7.21559C10.2166 7.13339 10.1669 7.05871 10.1041 6.99591C10.0413 6.93311 9.96662 6.88345 9.88442 6.84982C9.80222 6.81619 9.71414 6.79926 9.62533 6.80003C9.53652 6.80081 9.44876 6.81926 9.36715 6.85431C9.28555 6.88936 9.21175 6.94032 9.15005 7.0042L7.52708 8.62702L6.84995 7.94994Z"
					fill={token('color.icon.success', colors.G300)}
				/>
			</svg>
		)}
	/>
);

export const DeploymentNonProdSuccess = ({ size, label }: Props) => (
	<Icon
		size={size}
		label={label}
		glyph={() => (
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					width="16"
					height="16"
					fill={token('color.icon.inverse', colors.N0)}
					fillOpacity="0.01"
				/>
				<mask id="path-1-inside-1_81_24007" fill={token('color.icon.inverse', colors.N0)}>
					<path
						fill={token('color.icon.success', colors.G300)}
						fillRule="evenodd"
						clipRule="evenodd"
						d="M3.80003 12.8808C2.22503 12.7067 1 11.3714 1 9.74998C1 8.31663 1.95735 7.10688 3.2675 6.72527C3.82855 4.63713 5.73479 3.09998 8.00006 3.09998C10.2653 3.09998 12.1716 4.63715 12.7326 6.72531C14.0427 7.10697 15 8.31668 15 9.74998C15 11.3713 13.775 12.7066 12.2 12.8807V12.9H3.80003V12.8808Z"
					/>
				</mask>
				<path
					d="M3.80003 12.8808H5.30003V11.5374L3.96483 11.3898L3.80003 12.8808ZM3.2675 6.72527L3.68698 8.16542L4.49716 7.92944L4.71613 7.11449L3.2675 6.72527ZM12.7326 6.72531L11.284 7.11452L11.5029 7.92943L12.3131 8.16544L12.7326 6.72531ZM12.2 12.8807L12.0352 11.3898L10.7 11.5374V12.8807H12.2ZM12.2 12.9V14.4H13.7V12.9H12.2ZM3.80003 12.9H2.30003V14.4H3.80003V12.9ZM3.96483 11.3898C3.14142 11.2988 2.5 10.5983 2.5 9.74998H-0.5C-0.5 12.1445 1.30864 14.1145 3.63523 14.3717L3.96483 11.3898ZM2.5 9.74998C2.5 9.00137 2.99976 8.36559 3.68698 8.16542L2.84803 5.28511C0.914937 5.84817 -0.5 7.63188 -0.5 9.74998H2.5ZM4.71613 7.11449C5.10556 5.66505 6.43035 4.59998 8.00006 4.59998V1.59998C5.03923 1.59998 2.55153 3.60922 1.81888 6.33605L4.71613 7.11449ZM8.00006 4.59998C9.56978 4.59998 10.8946 5.66506 11.284 7.11452L14.1812 6.33611C13.4486 3.60925 10.9609 1.59998 8.00006 1.59998V4.59998ZM12.3131 8.16544C13.0003 8.36564 13.5 9.0014 13.5 9.74998H16.5C16.5 7.63196 15.0851 5.8483 13.1522 5.28518L12.3131 8.16544ZM13.5 9.74998C13.5 10.5983 12.8586 11.2988 12.0352 11.3898L12.3649 14.3717C14.6914 14.1144 16.5 12.1444 16.5 9.74998H13.5ZM10.7 12.8807V12.9H13.7V12.8807H10.7ZM12.2 11.4H3.80003V14.4H12.2V11.4ZM5.30003 12.9V12.8808H2.30003V12.9H5.30003Z"
					fill={token('color.icon.success', colors.G300)}
					mask="url(#path-1-inside-1_81_24007)"
				/>
				<path
					d="M6.81117 7.86961C6.88213 7.90009 6.94631 7.9444 6.99996 7.99995L7.58877 8.58871L9.00004 7.17757C9.05369 7.12202 9.11787 7.07771 9.18883 7.04723C9.25979 7.01674 9.33611 7.0007 9.41333 7.00003C9.49056 6.99936 9.56715 7.01407 9.63862 7.04332C9.7101 7.07256 9.77504 7.11575 9.82965 7.17036C9.88426 7.22497 9.92745 7.28991 9.95669 7.36138C9.98593 7.43286 10.0006 7.50945 9.99998 7.58667C9.99931 7.6639 9.98326 7.74022 9.95278 7.81118C9.9223 7.88214 9.87799 7.94632 9.82244 7.99997L7.99997 9.82231C7.8909 9.93135 7.74299 9.9926 7.58877 9.9926C7.43454 9.9926 7.28663 9.93135 7.17757 9.82231L6.17756 8.82235C6.12201 8.7687 6.0777 8.70452 6.04722 8.63356C6.01674 8.5626 6.00069 8.48628 6.00002 8.40906C5.99935 8.33183 6.01407 8.25525 6.04331 8.18377C6.07255 8.11229 6.11574 8.04735 6.17035 7.99274C6.22496 7.93813 6.2899 7.89495 6.36138 7.8657C6.43285 7.83646 6.50944 7.82174 6.58667 7.82241C6.66389 7.82308 6.74021 7.83913 6.81117 7.86961Z"
					fill={token('color.icon.success', colors.G300)}
				/>
			</svg>
		)}
	/>
);

export const DeploymentProdFailed = ({ size, label }: Props) => (
	<Icon
		size={size}
		label={label}
		glyph={() => (
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					width="16"
					height="16"
					fill={token('color.icon.inverse', colors.N0)}
					fillOpacity="0.01"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M3.80003 12.8808C2.22503 12.7067 1 11.3714 1 9.74998C1 8.31663 1.95735 7.10688 3.2675 6.72527C3.82855 4.63713 5.73479 3.09998 8.00006 3.09998C10.2653 3.09998 12.1716 4.63715 12.7326 6.72531C14.0427 7.10697 15 8.31668 15 9.74998C15 11.3713 13.775 12.7066 12.2 12.8807V12.9H3.80003V12.8808ZM7.35 6.25C7.35 5.83579 7.68579 5.5 8.1 5.5C8.51421 5.5 8.85 5.83579 8.85 6.25V8.25C8.85 8.66421 8.51421 9 8.1 9C7.68579 9 7.35 8.66421 7.35 8.25V6.25ZM8.1 9.5C7.68579 9.5 7.35 9.83579 7.35 10.25C7.35 10.6642 7.68579 11 8.1 11C8.51421 11 8.85 10.6642 8.85 10.25C8.85 9.83579 8.51421 9.5 8.1 9.5Z"
					fill={token('color.icon.danger', colors.R300)}
				/>
			</svg>
		)}
	/>
);

export const DeploymentNonProdFailed = ({ size, label }: Props) => (
	<Icon
		size={size}
		label={label}
		glyph={() => (
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					width="16"
					height="16"
					fill={token('color.icon.inverse', colors.N0)}
					fillOpacity="0.01"
				/>
				<mask id="path-1-inside-1_91_14760" fill={token('color.icon.inverse', colors.N0)}>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						fill={token('color.icon.danger', colors.R300)}
						d="M3.80003 12.8808C2.22503 12.7067 1 11.3714 1 9.74998C1 8.31663 1.95735 7.10688 3.2675 6.72527C3.82855 4.63713 5.73479 3.09998 8.00006 3.09998C10.2653 3.09998 12.1716 4.63715 12.7326 6.72531C14.0427 7.10697 15 8.31668 15 9.74998C15 11.3713 13.775 12.7066 12.2 12.8807V12.9H3.80003V12.8808Z"
					/>
				</mask>
				<path
					d="M3.80003 12.8808H5.30003V11.5374L3.96483 11.3898L3.80003 12.8808ZM3.2675 6.72527L3.68698 8.16542L4.49716 7.92944L4.71613 7.11449L3.2675 6.72527ZM12.7326 6.72531L11.284 7.11452L11.5029 7.92943L12.3131 8.16544L12.7326 6.72531ZM12.2 12.8807L12.0352 11.3898L10.7 11.5374V12.8807H12.2ZM12.2 12.9V14.4H13.7V12.9H12.2ZM3.80003 12.9H2.30003V14.4H3.80003V12.9ZM3.96483 11.3898C3.14142 11.2988 2.5 10.5983 2.5 9.74998H-0.5C-0.5 12.1445 1.30864 14.1145 3.63523 14.3717L3.96483 11.3898ZM2.5 9.74998C2.5 9.00137 2.99976 8.36559 3.68698 8.16542L2.84803 5.28511C0.914937 5.84817 -0.5 7.63188 -0.5 9.74998H2.5ZM4.71613 7.11449C5.10556 5.66505 6.43035 4.59998 8.00006 4.59998V1.59998C5.03923 1.59998 2.55153 3.60922 1.81888 6.33605L4.71613 7.11449ZM8.00006 4.59998C9.56978 4.59998 10.8946 5.66506 11.284 7.11452L14.1812 6.33611C13.4486 3.60925 10.9609 1.59998 8.00006 1.59998V4.59998ZM12.3131 8.16544C13.0003 8.36564 13.5 9.0014 13.5 9.74998H16.5C16.5 7.63196 15.0851 5.8483 13.1522 5.28518L12.3131 8.16544ZM13.5 9.74998C13.5 10.5983 12.8586 11.2988 12.0352 11.3898L12.3649 14.3717C14.6914 14.1144 16.5 12.1444 16.5 9.74998H13.5ZM10.7 12.8807V12.9H13.7V12.8807H10.7ZM12.2 11.4H3.80003V14.4H12.2V11.4ZM5.30003 12.9V12.8808H2.30003V12.9H5.30003Z"
					fill={token('color.icon.danger', colors.R300)}
					mask="url(#path-1-inside-1_91_14760)"
				/>
				<rect
					x="7.3501"
					y="5.5"
					width="1.5"
					height="3.5"
					rx="0.75"
					fill={token('color.icon.danger', colors.R300)}
				/>
				<rect
					x="7.3501"
					y="9.5"
					width="1.5"
					height="1.5"
					rx="0.75"
					fill={token('color.icon.danger', colors.R300)}
				/>
			</svg>
		)}
	/>
);
