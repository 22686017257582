import { fg } from '@atlassian/jira-feature-gating';
import { CAN_MANAGE_SPRINT } from '../../model/permission/permission-types';
import { getPermissionsSelector } from '../../state/selectors/board/board-permissions-selectors';
import { hasActiveSprintsSelector } from '../../state/selectors/sprint/sprint-selectors';
import type { State } from '../../state/types';

export const shouldDisableStandup = ({
	boardType,
	isSprintsEnabled,
	hasActiveSprint,
}: {
	boardType: string | undefined;
	isSprintsEnabled: boolean;
	hasActiveSprint: boolean;
}) => {
	if (boardType === 'Kanban') {
		return false;
	}

	return isSprintsEnabled && !hasActiveSprint;
};

type GetIsCompleteSprintButtonVisible = (state: State, isTabNavigation: boolean) => boolean;
export const getIsCompleteSprintButtonVisible: GetIsCompleteSprintButtonVisible = (
	state,
	isTabNavigation,
) => {
	if (fg('jsw-jet-yamato-fe-permissions-seq-sprint-actions')) {
		return (
			hasActiveSprintsSelector(state) &&
			// allow the render of a disabled button if no permissions to manage the sprint
			(!isTabNavigation || fg('jsw_nav4_new_sprint_controls_in_boards_blu-3256'))
		);
	}
	return (
		hasActiveSprintsSelector(state) &&
		!!getPermissionsSelector(state)[CAN_MANAGE_SPRINT] &&
		(!isTabNavigation || fg('jsw_nav4_new_sprint_controls_in_boards_blu-3256'))
	);
};
