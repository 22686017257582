import { fg } from '@atlassian/jira-feature-gating';
import { DependencyFilterOption } from '@atlassian/jira-filters/src/ui/filters/dependencies/types';
import type { FilterableIssue, MatcherCreator } from '../types';

export const dependenciesMatcherCreator: MatcherCreator<string[], FilterableIssue> = (
	dependenciesFilter,
) => {
	// General rule of dependencies filter is that it should include issues that has at least 1 dependency matches the selected filter.
	// So if off track is selected, it should include issues that has at least 1 off track dependency and vice versa,
	// if on track is selected, it should include issues that has at least 1 on track dependency.
	// If both off track and on track are selected, it should include issues that has at least 1 off track or on track dependency.
	if (dependenciesFilter && fg('dependency_visualisation_program_board_fe_and_be')) {
		const includeOffTrack = dependenciesFilter.includes(DependencyFilterOption.OFF_TRACK);
		const includeOnTrack = dependenciesFilter.includes(DependencyFilterOption.ON_TRACK);
		return (issue) =>
			(includeOffTrack && !!issue.hasOfftrackDependency) ||
			(includeOnTrack && !!issue.hasOntrackDependency);
	}

	return () => true;
};
