/* eslint-disable jira/i18n/id-named-by-package
    --------
    Temporary disable while refactoring the flags package
    so that we don't trigger retranslation of strings */

import { defineMessages } from '@atlassian/jira-intl';
import type { FlagConfiguration } from '../../../../services/types';

const messages = defineMessages({
	title: {
		id: 'platform.ui.flags.flags.errors.forbidden.title',
		defaultMessage: "You don't have permission to do that",
		description: '',
	},
	description: {
		id: 'platform.ui.flags.flags.errors.forbidden.description',
		defaultMessage: 'Ask your Jira admin for help.',
		description: '',
	},
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (): FlagConfiguration => ({
	type: 'error',
	title: messages.title,
	description: messages.description,
});
