import { getNormalisedPerformanceFFs } from '@atlassian/jira-common-long-task-metrics/src/common/util/collectors';
import { scheduleAfterRender } from '@atlassian/jira-software-react-scheduler';
import { getBoardExtraData } from '../../../../services/performance/performance-board-data-reporter';
import {
	flagToggleInteraction,
	issueCreateInteraction,
	issueDeleteInteraction,
	issueRankAndTransitionInteraction,
} from '../../../../services/utils/performance-analytics';
import type {
	IssueCreateRequestAction,
	IssueCreateSuccessAction,
} from '../../../actions/issue/create';
import type { IssueDeleteRequestAction } from '../../../actions/issue/delete';
import type { IssueFlagWithCommentRequestAction } from '../../../actions/issue/flag-with-comment';
import type {
	IssueRankTransitionRequestAction,
	IssueRankTransitionUpdateOptimisticAction,
	IssueRankTransitionSuccessAction,
} from '../../../actions/issue/rank-transition';
import type { State } from '../../../reducers/types';

export const issueCreateRequestPerformance =
	() => (preState: State, state: State, action: IssueCreateRequestAction) => {
		const concurrentId = String(action.payload.temporaryIssueIds[0]);
		issueCreateInteraction(concurrentId).start();
		scheduleAfterRender(() => {
			issueCreateInteraction(concurrentId).mark('feedback');
		});
		return null;
	};

export const issueCreateSuccessPerformance =
	() => (preState: State, state: State, action: IssueCreateSuccessAction) => {
		const concurrentId = String(action.payload.temporaryIssueIds[0]);
		issueCreateInteraction(concurrentId).stop({
			customData: { ...getNormalisedPerformanceFFs(), ...getBoardExtraData(state) },
		});
		return null;
	};

export const issueRankTransitionRequestPerformance =
	() =>
	(
		preState: State,
		state: State,
		action: IssueRankTransitionRequestAction | IssueRankTransitionUpdateOptimisticAction,
	) => {
		const concurrentId = String(action.meta.optimistic?.id);
		issueRankAndTransitionInteraction(concurrentId).start();
		scheduleAfterRender(() => {
			issueRankAndTransitionInteraction(concurrentId).mark('feedback');
		});
		return null;
	};

export const issueRankTransitionSuccessPerformance =
	() => (preState: State, state: State, action: IssueRankTransitionSuccessAction) => {
		const concurrentId = String(action.meta.optimistic.id);
		issueRankAndTransitionInteraction(concurrentId).stop({
			customData: { ...getNormalisedPerformanceFFs(), ...getBoardExtraData(state) },
		});
		return null;
	};

export const issueDeleteRequestPerformance =
	() => (preState: State, state: State, action: IssueDeleteRequestAction) => {
		const concurrentId = String(action.meta.optimistic.id);
		issueDeleteInteraction(concurrentId).start();
		scheduleAfterRender(() => {
			issueDeleteInteraction(concurrentId).mark('feedback');
		});
		return null;
	};

export const issueFlagToggleRequestPerformance =
	() => (preState: State, state: State, action: IssueFlagWithCommentRequestAction) => {
		const concurrentId = String(action.meta.optimistic.id);
		flagToggleInteraction(concurrentId).start();

		scheduleAfterRender(() => {
			flagToggleInteraction(concurrentId).mark('feedback');
		});
		return null;
	};
