import { combineReducers } from 'redux';
import type { Action } from '../../../types';
import cardAutoScrollReducer from './auto-scroll/reducer';
import { cardWithIccReducer } from './card-with-icc/reducer';
import { cardDragReducer } from './drag/reducer';
import { filteredCardsReducer } from './filtered-cards/reducer';
import cardFocusReducer from './focused/reducer';
import { cardInlineEditingReducer } from './inline-editing/reducer';
import { cardSelectionReducer } from './selected/reducer';
import { cardTransactionsReducer } from './transactions/reducer';
import type { CardsState } from './types';

export default combineReducers<CardsState, Action>({
	selected: cardSelectionReducer,
	transactions: cardTransactionsReducer,
	drag: cardDragReducer,
	focused: cardFocusReducer,
	cardWithIcc: cardWithIccReducer,
	filteredCards: filteredCardsReducer,
	autoScroll: cardAutoScrollReducer,
	inlineEditing: cardInlineEditingReducer,
});
