import meanBy from 'lodash/meanBy';
import { isExpandedObservationData, type ObservationData } from '../types';
import {
	triggerAnalytics,
	generateLongTaskAnalyticsContinuousActionSummary,
	getAverageResultsFromContinuousActions,
} from './common';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const aggregatedDataTimeout: Record<string, any> = {};

export const boardScrollReporter = (observationData: ObservationData) => {
	if (isExpandedObservationData(observationData)) {
		const startTime = observationData.events.map((event) => event.timestamp);
		const analyticsData = {
			...generateLongTaskAnalyticsContinuousActionSummary('boardScroll', observationData.events),
			measureTimespan: performance.now() - Math.min(observationData.startTimestamp, ...startTime),
		};

		const { appName } = observationData;
		if (aggregatedDataTimeout[`software.${appName}.boardScroll`]) {
			aggregatedDataTimeout[`software.${appName}.boardScroll`].data.push(analyticsData);
		} else {
			aggregatedDataTimeout[`software.${appName}.boardScroll`] = {
				data: [analyticsData],
				extra: observationData.extra,
				timeout: setTimeout(() => {
					const { data, extra: extraData } =
						aggregatedDataTimeout[`software.${appName}.boardScroll`];
					const triggerSoftwareAnalytics = triggerAnalytics(`software.${appName}`);
					triggerSoftwareAnalytics('long-tasks-board-scroll', {
						...getAverageResultsFromContinuousActions('boardScroll', data),
						...extraData,
						avgScrollTimespan: meanBy(data, 'measureTimespan'),
					});
					delete aggregatedDataTimeout[`software.${appName}.boardScroll`];
				}, 7000),
			};
		}
	}
};
