import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fetchText$ from '@atlassian/jira-fetch/src/utils/as-text-stream.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { getIssuesMoveToBacklogUrl } from '../../rest/software/software-api-urls';
import {
	issueMoveToBacklogFailure,
	issueMoveToBacklogSuccess,
	type IssueMoveToBacklogRequestAction,
	ISSUE_MOVE_TO_BACKLOG_REQUEST,
} from '../../state/actions/issue/move-to-backlog';
import { getRankConfig } from '../../state/selectors/issue/issue-selectors';
import {
	contextPathSelector,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors';
import type { MiddlewareAPI, ActionsObservable } from '../../state/types';

export const issueMoveToBacklogEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(ISSUE_MOVE_TO_BACKLOG_REQUEST)
		.mergeMap((action: IssueMoveToBacklogRequestAction) => {
			const state = store.getState();
			const contextPath = contextPathSelector(state);
			const rapidViewId = rapidViewIdSelector(state);
			const { rankCustomFieldId } = getRankConfig(state);

			return fetchText$(getIssuesMoveToBacklogUrl(contextPath), {
				method: 'PUT',
				body: JSON.stringify({
					calculateNewIssuesOrder: false,
					issueKeys: action.payload.issueKeys,
					rankBeforeIssueIdOrKey: null,
					rankCustomFieldId,
					rapidViewId,
					target: 'BACKLOG',
				}),
			})
				.flatMap(() =>
					Observable.of(
						issueMoveToBacklogSuccess(
							action.meta.optimistic.id,
							action.payload.issueIds,
							action.payload.columnId,
						),
					),
				)
				.catch((error: FetchError) => {
					log.safeErrorWithoutCustomerData(
						'issue.move.to.backlog.failure',
						'Failed to move issue to backlog',
						error,
					);
					return Observable.of(
						issueMoveToBacklogFailure(action.meta.optimistic.id, action.payload.issueIds),
					);
				});
		});
