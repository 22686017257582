import { createSelector } from 'reselect';
import { fg } from '@atlassian/jira-feature-gating';
import { SPRINT } from '../../../model/filter/filter-types';
import { type Permissions, CAN_EDIT_BOARD } from '../../../model/permission/permission-types';
import type { Sprint, SprintKey } from '../../../model/sprint/sprint-types';
import type { ConfigState } from '../../reducers/entities/board/config/types';
import type { SprintsState } from '../../reducers/entities/sprints/types';
import { getBoardConfig, getIsCMPBoard } from '../software/software-selectors';
import {
	activeSprintsSelector,
	getSelectedSprintsKeys,
	getSprintEntities,
} from '../sprint/sprint-selectors';
import { getWorkFilters } from '../work/work-selectors';
import { getPermissionsSelector } from './board-permissions-selectors';

export const boardNameSelector = createSelector(
	[getBoardConfig],
	(boardConfig) => boardConfig.name,
);

export const boardTitleSelector = createSelector(
	[getSprintEntities, getBoardConfig, getSelectedSprintsKeys, getWorkFilters],
	(
		activeSprints: SprintsState,
		boardConfig: ConfigState,
		selectedSprintsFilter: SprintKey[],
		workFilters,
	): string => {
		const numOfActiveSprints = Object.keys(activeSprints).length;

		if (boardConfig.isSprintsEnabled === false || numOfActiveSprints === 0) {
			return boardConfig.name;
		}

		const selectedSprints = fg('jsw_board_sprint_name_title_fix')
			? workFilters.values[SPRINT] ?? []
			: selectedSprintsFilter;
		const numOfSelectedSprints = selectedSprints?.length ?? 0;

		if (numOfActiveSprints === 1) {
			return activeSprints[Object.keys(activeSprints)[0]].name;
		}

		if (numOfSelectedSprints === 1 && activeSprints[selectedSprints[0]]) {
			return activeSprints[selectedSprints[0]].name;
		}

		if (numOfSelectedSprints > 1 && numOfSelectedSprints < numOfActiveSprints) {
			return `${numOfSelectedSprints} sprints`;
		}

		return 'All sprints';
	},
);

export const isEditableSelector = createSelector(
	[activeSprintsSelector, getPermissionsSelector, getIsCMPBoard],
	(activeSprints: Sprint[] | null, permissions: Permissions, isCMPBoard: boolean): boolean =>
		(activeSprints === null || activeSprints.length === 0) &&
		(permissions[CAN_EDIT_BOARD] || false) &&
		!isCMPBoard,
);
