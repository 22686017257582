import { combineReducers } from 'redux';
import type { Action } from '../../../types';
import { swimlanesCollapsedReducer } from './collapsed/reducer';
import { swimlaneModeReducer } from './mode/reducer';
import type { SwimlaneState } from './types';

export default combineReducers<SwimlaneState, Action>({
	collapsed: swimlanesCollapsedReducer,
	mode: swimlaneModeReducer,
});
