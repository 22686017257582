import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software-filters.filters.assignee.stateless.label',
		defaultMessage: 'Filter by assignee',
		description:
			'The legend of a fieldset used to display an avatar of each team members to filter issues by.',
	},
});
