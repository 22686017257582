import uuid from 'uuid';
import type { ChangeEvent } from '@atlassian/jira-issue-view-model/src/change-type';
import type { IssueId } from '@atlassian/jira-software-board-common';
import type { Issue, IssueParentId } from '../../../../model/issue/issue-types';
import { type IssueUpdateOrigin, OTHER } from '../../../../model/issue/issue-update-origin';
import flagMessages from '../../../../view/flags/messages';
import { errorFlagMeta, type FlagMeta } from '../../meta/flag-meta';

export const ISSUE_UPDATE_REQUEST = 'state.actions.issue.update.ISSUE_UPDATE_REQUEST' as const;

export type IssueUpdateRequestAction = {
	type: typeof ISSUE_UPDATE_REQUEST;
	payload: {
		issueIds: IssueId[];
		origin: IssueUpdateOrigin;
		transactionId: string;
	};
};

export const issueUpdateRequestWithTransactionId = (
	issueIds: IssueId[],
	origin: IssueUpdateOrigin,
	transactionId: string,
): IssueUpdateRequestAction => ({
	type: ISSUE_UPDATE_REQUEST,
	payload: { issueIds, origin, transactionId },
});

export const issueUpdateRequest = (
	issueIds: IssueId[],
	origin: IssueUpdateOrigin = OTHER,
): IssueUpdateRequestAction => issueUpdateRequestWithTransactionId(issueIds, origin, uuid());

export const ISSUE_UPDATE_SUCCESS = 'state.actions.issue.update.ISSUE_UPDATE_SUCCESS' as const;

export type IssueUpdateSuccessAction = {
	type: typeof ISSUE_UPDATE_SUCCESS;
	payload: {
		issue: Issue;
		origin: IssueUpdateOrigin;
	};
};

export const issueUpdateSuccess = (
	issue: Issue,
	origin: IssueUpdateOrigin,
): IssueUpdateSuccessAction => ({
	type: ISSUE_UPDATE_SUCCESS,
	payload: { issue, origin },
});

export const ISSUE_UPDATE_FAILURE = 'state.actions.issue.update.ISSUE_UPDATE_FAILURE' as const;

export type IssueUpdateFailureAction = {
	type: typeof ISSUE_UPDATE_FAILURE;
	payload: {
		errorString: string;
	};
};

export const issueUpdateFailure = (errorString: string): IssueUpdateFailureAction => ({
	type: ISSUE_UPDATE_FAILURE,
	payload: { errorString },
});

export const ISSUE_BENTO_UPDATE = 'state.actions.issue.update.ISSUE_BENTO_UPDATE' as const;

export type IssueBentoUpdateAction = {
	type: typeof ISSUE_BENTO_UPDATE;
	payload: ChangeEvent;
	meta: {
		transactionId: string;
	};
};

export const issueBentoUpdate = (
	payload: ChangeEvent,
	transactionId: string = uuid(),
): IssueBentoUpdateAction => ({
	type: ISSUE_BENTO_UPDATE,
	payload,
	meta: {
		transactionId,
	},
});

export const SET_ISSUE_PARENT = 'state.actions.issue.update.SET_ISSUE_PARENT' as const;

export type SetIssueParentAction = {
	type: typeof SET_ISSUE_PARENT;
	payload: {
		issueId: IssueId;
		issueParentId: IssueParentId | null;
	};
};

export const setIssueParent = (
	issueId: IssueId,
	issueParentId: IssueParentId | null,
): SetIssueParentAction => ({
	type: SET_ISSUE_PARENT,
	payload: {
		issueId,
		issueParentId,
	},
});

export const ISSUE_INCREMENT_PLANNING_UPDATE_SUCCESS =
	'state.actions.issue.update.ISSUE_INCREMENT_PLANNING_UPDATE_SUCCESS' as const;

export type IssueIncrementPlanningUpdateSuccessAction = {
	type: typeof ISSUE_INCREMENT_PLANNING_UPDATE_SUCCESS;
};

export const issueIncrementPlanningUpdateSuccess =
	(): IssueIncrementPlanningUpdateSuccessAction => ({
		type: ISSUE_INCREMENT_PLANNING_UPDATE_SUCCESS,
	});

export const ISSUE_INCREMENT_PLANNING_UPDATE_FAILURE =
	'state.actions.issue.update.ISSUE_INCREMENT_PLANNING_UPDATE_FAILURE' as const;

export type IssueIncrementPlanningUpdateFailedAction = {
	type: typeof ISSUE_INCREMENT_PLANNING_UPDATE_FAILURE;
	meta: FlagMeta;
};

export const issueIncrementPlanningUpdateFailed = (): IssueIncrementPlanningUpdateFailedAction => ({
	type: ISSUE_INCREMENT_PLANNING_UPDATE_FAILURE,
	meta: errorFlagMeta({
		titleMessage: flagMessages.genericTitle,
		descriptionMessage: flagMessages.genericDescription,
	}),
});

export const ISSUE_INCREMENT_PLANNING_UPDATE =
	'state.actions.issue.update.ISSUE_INCREMENT_PLANNING_UPDATE' as const;

export type IncrementPlanningUpdatePayload = {
	isEstimateField?: boolean;
	fieldId: string;
	fieldValue: string | number | null;
	issueId: IssueId;
};

export type IssueIncrementPlanningUpdateAction = {
	type: typeof ISSUE_INCREMENT_PLANNING_UPDATE;
	payload: IncrementPlanningUpdatePayload;
};

export const issueIncrementPlanningUpdate = (
	payload: IncrementPlanningUpdatePayload,
): IssueIncrementPlanningUpdateAction => ({
	type: ISSUE_INCREMENT_PLANNING_UPDATE,
	payload,
});

export type Action =
	| IssueUpdateRequestAction
	| IssueUpdateSuccessAction
	| IssueUpdateFailureAction
	| IssueBentoUpdateAction
	| SetIssueParentAction
	| IssueIncrementPlanningUpdateAction
	| IssueIncrementPlanningUpdateFailedAction
	| IssueIncrementPlanningUpdateSuccessAction;
