/**
 * @generated SignedSource<<7a0d3fbb3610dfdcd8128a1b88d6f6a8>>
 * @relayHash 9e0292a0e9e1a7cfc043ea04242ec447
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d7442a20376f6ce597664338dc598c7ade7ff8e2f238b04acb696e15c5022319

import type { ConcreteRequest, Query } from 'relay-runtime';
export type issueCreatedFlagGetTrialLinkQuery$variables = {
  cloudId: string;
  offeringKey?: string | null | undefined;
  productKey: string;
};
export type issueCreatedFlagGetTrialLinkQuery$data = {
  readonly tenantContexts: ReadonlyArray<{
    readonly entitlementInfo: {
      readonly entitlement: {
        readonly experienceCapabilities: {
          readonly changeOffering: {
            readonly experienceUrl: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
};
export type issueCreatedFlagGetTrialLinkQuery = {
  response: issueCreatedFlagGetTrialLinkQuery$data;
  variables: issueCreatedFlagGetTrialLinkQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offeringKey"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productKey"
},
v3 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "cloudIds.0",
        "variableName": "cloudId"
      }
    ],
    "kind": "ListValue",
    "name": "cloudIds"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "hamsProductKey",
    "variableName": "productKey"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "offeringKey",
    "variableName": "offeringKey"
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "experienceUrl"
},
v7 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "issueCreatedFlagGetTrialLinkQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v4/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "experienceCapabilities",
                    "plural": false,
                    "selections": [
                      {
                        "args": (v5/*: any*/),
                        "kind": "LinkedField",
                        "name": "changeOffering",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "issueCreatedFlagGetTrialLinkQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v4/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "experienceCapabilities",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "args": (v5/*: any*/),
                        "kind": "LinkedField",
                        "name": "changeOffering",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v6/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "d7442a20376f6ce597664338dc598c7ade7ff8e2f238b04acb696e15c5022319",
    "metadata": {},
    "name": "issueCreatedFlagGetTrialLinkQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "4d50fdd81aa1005eb031986467ce9f75";

export default node;
