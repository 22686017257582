import { useMemo } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { FlagAction } from '@atlassian/jira-flags';
import { useHelpPanelLink } from '@atlassian/jira-help-panel-button';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

const CMP_ISSUE_LIMIT_SUPPORT_ARTICLE_URL =
	'https://support.atlassian.com/jira-software-cloud/docs/issue-limits-in-company-managed-projects/';
const CMP_ISSUE_LIMIT_IPH_ARTICLE_ID = '1KMwTxMBNiDPhaHMDlfT4u';

export function editFilterCtaAction(flagSource: string): FlagAction {
	return {
		content: messages.editFilterCta,

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		href: `${window.location.pathname}?config=filter`,
		onClick: (_, analyticsEvent) => {
			if (analyticsEvent) {
				fireUIAnalytics(analyticsEvent, 'editBoardFilter', { flagSource });
			}
		},
	};
}

export function cmpIssueLimitSupportArticleCtaAction(flagSource: string): FlagAction {
	return {
		content: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.viewMoreInfoCtaIssueTermRefresh
			: messages.viewMoreInfoCta,
		href: CMP_ISSUE_LIMIT_SUPPORT_ARTICLE_URL,
		target: '_blank',
		onClick: (_, analyticsEvent) => {
			if (analyticsEvent) {
				fireUIAnalytics(analyticsEvent, 'cmpIssueLimitSupportArticle', {
					flagSource,
				});
			}
		},
	};
}

export function cmpIssueLimitIPHArticleCtaAction(
	flagSource: string,
	setIPHArticleId?: (articleId: string) => void,
): FlagAction {
	return {
		content: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.viewMoreInfoCtaIssueTermRefresh
			: messages.viewMoreInfoCta,
		onClick: (_, analyticsEvent) => {
			setIPHArticleId?.(CMP_ISSUE_LIMIT_IPH_ARTICLE_ID);

			if (analyticsEvent) {
				fireUIAnalytics(analyticsEvent, 'cmpIssueLimitIph', {
					flagSource,
				});
			}
		},
	};
}

export const tmpSeeIssuesInSearchCtaAction: (projectKey: string) => FlagAction = (projectKey) => ({
	content: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
		? messages.seeIssuesInSearchCtaIssueTermRefresh
		: messages.seeIssuesInSearchCta,
	href: `/issues/?jql=${encodeURIComponent(`project = ${projectKey} ORDER BY rank ASC`)}`,
});

const selector = functionWithCondition(
	() => expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false),
	() => ({
		content: messages.viewMoreInfoCtaIssueTermRefresh,
		href: 'TBD',
	}),
	() => ({
		content: messages.viewMoreInfoCta,
		href: 'TBD',
	}),
);

export const tmpIssueLimitSupportArticleCtaAction: FlagAction = selector();

interface UseFlagActionArgs {
	isProjectAdmin: boolean;
	isCMP: boolean;
	projectKey: string;
	flagType: 'approaching' | 'reached';
}

export const useFlagActions = ({
	isProjectAdmin,
	isCMP,
	projectKey,
	flagType,
}: UseFlagActionArgs) => {
	const setIPHArticleId = useHelpPanelLink();

	const flagSource =
		flagType === 'approaching' ? 'jswIssueLimitApproachingFlag' : 'jswIssueLimitReachedFlag';

	return useMemo(() => {
		if (isCMP) {
			if (isProjectAdmin) {
				return [
					editFilterCtaAction(flagSource),
					cmpIssueLimitIPHArticleCtaAction(flagSource, setIPHArticleId),
				];
			}

			return [cmpIssueLimitSupportArticleCtaAction(flagSource)];
		}

		return [tmpSeeIssuesInSearchCtaAction(projectKey), tmpIssueLimitSupportArticleCtaAction];
	}, [isCMP, projectKey, isProjectAdmin, flagSource, setIPHArticleId]);
};
