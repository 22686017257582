import { entityArrayToMap } from '../../../../services/software/software-data-transformer';
import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../actions/work';
import type { Action } from '../../../types';
import type { IssueProjectsState } from './types';

export const issueProjectsReducer = (
	state: IssueProjectsState = {},
	action: Action,
): IssueProjectsState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		return entityArrayToMap(action.payload.projects);
	}

	return state;
};
