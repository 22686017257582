/** @jsx jsx */
import React, { type MouseEvent, useState, useEffect, useCallback, memo } from 'react';
import { jsx, css } from '@compiled/react';
import noop from 'lodash/noop';
import uuid from 'uuid';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import {
	ContextualAnalyticsData,
	fireUIAnalytics,
	SCREEN,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useStorageEnforcement } from '@atlassian/jira-software-enforce-storage-limits/src/controllers/storage-enforcement/index.tsx';
import {
	useTriggerEventState,
	useKeepIccOpenActions,
} from '@atlassian/jira-software-onboarding-keep-icc-open/src/controllers';
import { BLUR, ESCAPE, FULL, SIMPLE, VISIBLE } from '../common/constants';
import type { CreatePayload, CreateMetadata, CardType } from '../common/types';
import { IccContext, type IccContextType } from '../services/context/index.tsx';
import { AsyncInlineCardCreateForm } from './form/async';
import Form from './form/main.tsx';
import { FIELD_KEY_STATUS_TRANSITIONS } from './form/status-select';
import Trigger from './trigger/main.tsx';
import type { Props } from './types';

const isEnrolledInSimplifyIccBundle = () => {
	const [expConfigM2] = UNSAFE_noExposureExp('simplify_icc_bundle_m2');
	const isEnrolledM2 = expConfigM2.get('cohort', 'not-enrolled') !== 'not-enrolled';
	return isEnrolledM2;
};

const isInSimplifyIccBundleVariation = () => {
	const [expConfigM2] = UNSAFE_noExposureExp('simplify_icc_bundle_m2');
	const isInVariationM2 = expConfigM2.get('cohort', 'not-enrolled') === 'variation';
	return isInVariationM2;
};

// exact trigger height
const TRIGGER_HEIGHT = 40;

const withMarginTopStyles = css({
	marginTop: `-${TRIGGER_HEIGHT}px`,
});

const withTransparentStyles = css({
	opacity: 0,
});

// exported for test only
export const getInitialCardTypeId = (
	cardTypes: CardType[],
	cardTypeId: number | string | null,
	defaultCardTypeId?: number | string | null,
): number | string | null => {
	const selectedCardType = cardTypes.find(({ id }) => id === cardTypeId);
	const fallbackCardTypeId =
		defaultCardTypeId &&
		cardTypes.some(({ id, isDisabled }) => id === defaultCardTypeId && isDisabled !== true)
			? defaultCardTypeId
			: null;

	if (selectedCardType?.isDisabled === true) {
		return fallbackCardTypeId;
	}

	// To prevent setting invalid value when user switches to another ICC component that have different cardTypes
	return selectedCardType !== undefined ? cardTypeId : fallbackCardTypeId;
};

const InlineCardCreate = (props: Props) => {
	const {
		isFormVisible: isFormVisibleProp,
		isFormVisibleDefault = false,
		isDisabled = false,
		shouldScrollIntoView,
		onChangeFormVisible = noop,
		triggerAppearance,
		onTriggerClick = noop,
		onCancel = noop,
		onCreate = noop,
		onChangeCardType = noop,
		onChangeSummary = noop,
		onChangeStatusTransition = noop,
		onChangeProject = noop,
		cardTypes = [],
		defaultCardTypeId,
		autoFocus,
		extraItems,
		shouldKeepFormOpenOnClickOutside,
		triggerLabel,
		ariaLabelledBy,
		appearance,
		placeholderText,
		numFilters,
		messages,
		saveOnBlur,
		renderAdditionalTypeSelectOptionContent,
		renderAdditionalInlineCreateFooter,
		onBlur = noop,
		TypeSelect,
		StatusSelect,
		statusTransitions,
		projects,
		isModalTrigger,
		shouldShowCreateButton,
		onAutoOpen = noop,
	} = props;

	const [isFormVisible, setIsFormVisible] = useState<boolean>(
		isFormVisibleProp ?? isFormVisibleDefault,
	);
	const [triggerUnmountTimeoutId, setTriggerUnmountTimeoutId] = useState<number | null>(null);
	const previousIsFormVisibleProp = usePreviousWithInitial(isFormVisibleProp);
	const previousIsFormVisible = usePreviousWithInitial(isFormVisible);

	const [shouldFocusTrigger, setShouldFocusTrigger] = useState<boolean>(false);

	const setFocusOnTrigger = useCallback(() => {
		setShouldFocusTrigger(true);
	}, []);

	const { showEnforcementIfStorageIsFull } = useStorageEnforcement();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	// should be clean up with experiment simplify_icc_bundle_m2 or move up to src/packages/software/board/src/view/board-container/board/inline-card-create/visible-icc
	const { hasCreatedIssueICC, triggerReason } = isEnrolledInSimplifyIccBundle()
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useTriggerEventState()
		: { hasCreatedIssueICC: false, triggerReason: undefined };
	const { setHasCreatedIssueICC, setTriggerReason } = isEnrolledInSimplifyIccBundle()
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useKeepIccOpenActions()
		: { setHasCreatedIssueICC: noop, setTriggerReason: noop };

	useEffect(() => {
		if (
			!__SERVER__ &&
			(previousIsFormVisibleProp !== isFormVisibleProp || previousIsFormVisible !== isFormVisible)
		) {
			// invoke callback everytime isFormVisibleProp or internal isFormVisible state changes
			onChangeFormVisible(isFormVisibleProp ?? isFormVisible);
		}
	}, [
		isFormVisible,
		isFormVisibleProp,
		onChangeFormVisible,
		previousIsFormVisible,
		previousIsFormVisibleProp,
	]);

	const onTriggerClickCallback = useCallback(
		(event: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			const sessionId = uuid.v4();
			if (isEnrolledInSimplifyIccBundle()) {
				setHasCreatedIssueICC(false);
			}
			// @ts-expect-error Second argument should be of format "actionSubject action"
			fireUIAnalytics(analyticsEvent, 'triggerClicked', 'triggerButton', {
				formWasOpen: isFormVisible,
				triggerAppearance,
				sessionId,
				...(isEnrolledInSimplifyIccBundle() && {
					isInSimplifyIccBundleVariation: isInSimplifyIccBundleVariation(),
				}),
				isAutoOpen: false,
			});

			if (isFormVisibleProp === undefined) {
				setTriggerUnmountTimeoutId(null);
				setIsFormVisible(true);
			}

			if (fg('jsw_roadmaps_timeline-issue-creation-ally')) {
				setShouldFocusTrigger(false);
			}

			onTriggerClick(sessionId);
		},
		[setHasCreatedIssueICC, isFormVisible, triggerAppearance, isFormVisibleProp, onTriggerClick],
	);

	const onCancelCallback = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'blurred',
				actionSubject: 'inlineCardCreate',
			}),
			{ cancelledBy: ESCAPE },
		);

		if (isFormVisibleProp === undefined) {
			if (triggerUnmountTimeoutId) {
				clearTimeout(triggerUnmountTimeoutId);
				setTriggerUnmountTimeoutId(null);
			}
			setIsFormVisible(false);
		}
		onCancel();
	}, [createAnalyticsEvent, triggerUnmountTimeoutId, isFormVisibleProp, onCancel]);

	const makeOnBlur = useCallback(
		(context: IccContextType) => (summary: string) => {
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'blurred',
					actionSubject: 'inlineCardCreate',
				}),
				{
					cancelledBy: BLUR,
					...(isEnrolledInSimplifyIccBundle() && {
						withContent: !!summary,
						isInSimplifyIccBundleVariation: isInSimplifyIccBundleVariation(),
					}),
				},
			);
			context.onChangeSummary(summary);
			if (isFormVisibleProp === undefined) {
				if (triggerUnmountTimeoutId) {
					clearTimeout(triggerUnmountTimeoutId);
					setTriggerUnmountTimeoutId(null);
				}
				setIsFormVisible(false);
			}
			onBlur();
			if (isEnrolledInSimplifyIccBundle()) {
				setHasCreatedIssueICC(true);
			}
		},
		[
			createAnalyticsEvent,
			isFormVisibleProp,
			onBlur,
			setHasCreatedIssueICC,
			triggerUnmountTimeoutId,
		],
	);

	const makeOnCreate = useCallback(
		(context: IccContextType) => (issues: CreatePayload, metadata: CreateMetadata) => {
			// analytic event name will be changed in the future to match other events
			const analyticsEvent = createAnalyticsEvent({
				action: 'confirmed',
				actionSubject: 'input',
			});
			fireUIAnalytics(analyticsEvent, { createdBy: metadata.createdBy });
			context.onCreate();
			onCreate(issues, metadata, analyticsEvent);
			showEnforcementIfStorageIsFull({ touchpoint: 'inline-card-create' });
			if (isEnrolledInSimplifyIccBundle()) {
				setHasCreatedIssueICC(true);
				setTriggerReason('lastIssueCreated');
			}
		},
		[
			createAnalyticsEvent,
			onCreate,
			showEnforcementIfStorageIsFull,
			setHasCreatedIssueICC,
			setTriggerReason,
		],
	);

	const makeOnCancel = useCallback(
		(context: IccContextType) => {
			if (context && context.onCancel) {
				return () => {
					context.onCancel();
					onCancelCallback();
				};
			}
			return onCancelCallback;
		},
		[onCancelCallback],
	);

	const makeOnChangeCardType = useCallback(
		(context: IccContextType) => {
			if (context && context.onChangeCardType) {
				return (issueTypeId: number | string) => {
					context.onChangeCardType(issueTypeId);
					onChangeCardType(issueTypeId);
				};
			}
			return onChangeCardType;
		},
		[onChangeCardType],
	);

	const makeOnChangeStatusTransition = useCallback(
		(context: IccContextType) => {
			if (context && context.onChangeFieldValue) {
				return (transitionId: number) => {
					context.onChangeFieldValue(FIELD_KEY_STATUS_TRANSITIONS, transitionId);
					onChangeStatusTransition(transitionId);
				};
			}
			return onChangeStatusTransition;
		},
		[onChangeStatusTransition],
	);

	const makeOnChangeSummary = useCallback(
		(context: IccContextType) => {
			if (context && context.onChangeSummary) {
				return (summary: string) => {
					context.onChangeSummary(summary);
					onChangeSummary(summary);
				};
			}
			return onChangeSummary;
		},
		[onChangeSummary],
	);

	const makeOnChangeProject = useCallback(
		(context: IccContextType) => {
			if (context && context.onChangeProject) {
				return (projectId: number) => {
					context.onChangeProject(projectId);
					onChangeProject(projectId);
				};
			}
			return onChangeProject;
		},
		[onChangeProject],
	);

	const isCardTypesEmptyOrAllDisabled = cardTypes.every(({ isDisabled: isCardTypeDisabled }) =>
		Boolean(isCardTypeDisabled),
	);
	const isTriggerDisabled = cardTypes?.length
		? isDisabled || isCardTypesEmptyOrAllDisabled
		: isDisabled;

	const shouldRenderForm = (isFormVisibleProp ?? isFormVisible) && !isTriggerDisabled;

	useEffect(() => {
		/* 	below block is intended for case where icc is automatically opened after icc issue
			created when user opens originally opens icc in-between two existing issues */
		if (isEnrolledInSimplifyIccBundle() && shouldRenderForm === true && hasCreatedIssueICC) {
			// unique sessionId for each icc
			const sessionId = uuid.v4();
			// fires start event and sets sessionId for end event
			onAutoOpen(sessionId);

			const autoFocusAnalyticsEvent = createAnalyticsEvent({
				action: 'focused',
				actionSubject: 'input',
				actionSubjectId: 'inlineCreateCard',
			});
			fireUIAnalytics(autoFocusAnalyticsEvent, {
				triggeredBy: 'autoFocus',
				reason: triggerReason,
				...(isEnrolledInSimplifyIccBundle() && {
					isInSimplifyIccBundleVariation: isInSimplifyIccBundleVariation(),
				}),
			});
			setHasCreatedIssueICC(false);
		}
	}, [
		createAnalyticsEvent,
		hasCreatedIssueICC,
		setHasCreatedIssueICC,
		shouldRenderForm,
		triggerReason,
		isFormVisible,
		triggerAppearance,
		onTriggerClick,
		onAutoOpen,
	]);

	const ICCForm = fg('board_icc_form_async_load') ? AsyncInlineCardCreateForm : Form;

	return (
		<ContextualAnalyticsData
			sourceName="inline-card-create"
			sourceType={SCREEN}
			attributes={{ appearance, numFilters }}
		>
			<Box
				xcss={[containerStyles, triggerUnmountTimeoutId !== null && withTriggerUnmountStyles]}
				testId="platform-inline-card-create.ui.container"
			>
				{shouldRenderForm && (
					<IccContext.Consumer>
						{(value: IccContextType) => (
							<ICCForm
								autoFocus={autoFocus ?? true}
								shouldScrollIntoView={shouldScrollIntoView ?? false}
								onSubmit={makeOnCreate(value)}
								onCancel={makeOnCancel(value)}
								onBlur={makeOnBlur(value)}
								onChangeSummary={makeOnChangeSummary(value)}
								numFilters={numFilters ?? 0}
								messages={messages ?? []}
								cardTypes={cardTypes}
								extraItems={extraItems}
								shouldKeepFormOpenOnClickOutside={shouldKeepFormOpenOnClickOutside}
								selectedCardTypeId={getInitialCardTypeId(
									cardTypes,
									value.initialCardTypeId,
									defaultCardTypeId,
								)}
								selectedFieldValues={value.initialFieldValues}
								appearance={appearance ?? 'full'}
								placeholderText={placeholderText ?? ''}
								saveOnBlur={saveOnBlur ?? false}
								onChangeCardType={makeOnChangeCardType(value)}
								onChangeStatusTransition={makeOnChangeStatusTransition(value)}
								onChangeProject={makeOnChangeProject(value)}
								initialSummary={value.initialSummary}
								renderAdditionalTypeSelectOptionContent={renderAdditionalTypeSelectOptionContent}
								renderAdditionalInlineCreateFooter={renderAdditionalInlineCreateFooter}
								TypeSelect={TypeSelect}
								StatusSelect={StatusSelect}
								statusTransitions={statusTransitions}
								selectedProjectId={value.initialProjectId}
								projects={projects}
								shouldShowCreateButton={shouldShowCreateButton}
								{...(fg('jsw_roadmaps_timeline-issue-creation-ally') ? { setFocusOnTrigger } : {})}
							/>
						)}
					</IccContext.Consumer>
				)}
				<div
					css={[
						triggerUnmountTimeoutId !== null && withMarginTopStyles,
						!(!shouldRenderForm && triggerUnmountTimeoutId === null) && withTransparentStyles,
					]}
				>
					{(!shouldRenderForm || triggerUnmountTimeoutId !== null) && (
						<Trigger
							isModalTrigger={isModalTrigger}
							isSticky={appearance === SIMPLE}
							triggerAppearance={triggerAppearance}
							label={triggerLabel}
							ariaLabelledBy={ariaLabelledBy}
							onClick={onTriggerClickCallback}
							isDisabled={isTriggerDisabled}
							{...(fg('jsw_roadmaps_timeline-issue-creation-ally') ? { shouldFocusTrigger } : {})}
						/>
					)}
				</div>
			</Box>
		</ContextualAnalyticsData>
	);
};

InlineCardCreate.defaultProps = {
	isFormVisibleDefault: false,
	isDisabled: false,
	autoFocus: true,
	saveOnBlur: false,
	appearance: FULL,
	triggerAppearance: VISIBLE,
	triggerLabel: '',
	placeholderText: '',
	numFilters: 0,
	messages: [],
	cardTypes: [],
	extraItems: undefined,
	onCreate: noop,
	onCancel: noop,
	onBlur: noop,
	onTriggerClick: noop,
	onChangeSummary: noop,
	onChangeCardType: noop,
	onChangeStatusTransition: noop,
	onChangeFormVisible: noop,
	onChangeProject: noop,
	onAutoOpen: noop,
	renderAdditionalInlineCreateFooter: undefined,
	shouldShowCreateButton: false,
};

export default memo<Props>(InlineCardCreate);

const containerStyles = xcss({
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	width: '100%',
});

const withTriggerUnmountStyles = xcss({
	minHeight: `${TRIGGER_HEIGHT}px`,
});
