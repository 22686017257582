import React, { useCallback, useEffect } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { ACTION_SUBJECT_ID as WORK_SUGGESTION_TOGGLE_ACTION_SUBJECT_ID } from '@atlassian/jira-insights-next-best-task/src/services/use-next-best-task-user-preference/constants';
import { useNextBestTaskUserPreference } from '@atlassian/jira-insights-next-best-task/src/services/use-next-best-task-user-preference/index.tsx';
import type { SwimlaneId } from '@atlassian/jira-platform-board-kit/src/common/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { nextBestTaskFeatureResource } from '@atlassian/jira-router-resources-next-best-task/src/services/index.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common';
import { View } from '@atlassian/jira-software-view-settings/src/common/types/constant.tsx';
import {
	useShowUnscheduledColumn,
	useShowOfftrackDependencyLines,
	useIssueIdsToShowDependencies,
} from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import ViewSettingsPopup from '@atlassian/jira-software-view-settings/src/ui/index.tsx';
import {
	ViewSettingsButton,
	ViewSettingsSyncFromLocalStorageWrapper,
} from '@atlassian/jira-software-view-settings/src/ui/view-settings-button/index.tsx';
import {
	ViewSettingsPanelOnboarding,
	ViewSettingsPanelButtonSpotlight,
} from '@atlassian/jira-software-view-settings/src/ui/view-settings-panel-onboarding/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import { isViewSettingAsPanelExpEnabledWithNoExposure } from '../../../../feature-flags';
import { useBoardActionCreator, useBoardSelector } from '../../../../state';
import { fetchCardCovers } from '../../../../state/actions/card-covers';
import { fetchIssueLinksStats } from '../../../../state/actions/issue-links-stats';
import { getIssueLinksPermissions } from '../../../../state/selectors/issue-links-permissions/issue-links-permissions-selectors';
import { boardOrderedIssueIdsSelector } from '../../../../state/selectors/issue/board-issue-selectors';
import { getFirstSelectableIssueAscending } from '../../../../state/selectors/issue/issue-navigation-selectors';
import {
	getCardExtraFields,
	getIsDaysInColumnEnabled,
	getIsDueDateEnabled,
	getIsIssueLabelsEnabled,
	projectTypeSelector,
	getIsEstimateEnabled,
	getIsPriorityEnabled,
	getCanEditBoard,
	getCanInviteOthersToProject,
	getIsIncrementPlanningBoard,
	getIssues,
} from '../../../../state/selectors/software/software-selectors';
import { getCustomFilters } from '../../../../state/selectors/work/work-selectors';
import {
	useIsCMPBoard,
	useIsIncrementPlanningBoard,
	useIsJSWBoard,
} from '../../../../state/state-hooks/capabilities';

export type DispatchProps = {
	onSwimlaneExpandAll: () => void;
	onSwimlaneCollapseAll: (swimlaneIds: SwimlaneId[], isIPBoard: boolean) => void;
	onSetShowUnscheduledColumn: (isVisible: boolean) => void;
	onSetShowOfftrackDependencyLines: (isVisible: boolean) => void;
	onSetIssueIdsToShowDependencies: (issueIds: IssueId[]) => void;
	onTogglePanel: () => void;
};

export type StateProps = {
	rapidViewId: number;
	swimlaneIds: SwimlaneId[];
	hasSwimlanes: boolean;
	isClassic: boolean;
	epicCustomName?: string;
	isViewSettingPanelOpen?: boolean;
};

export type OwnProps = {
	showCardDetails: boolean;
};

export type ViewSettingsProps = StateProps & DispatchProps & OwnProps;

export const useIsJSMBoard = () => useBoardSelector(projectTypeSelector) === SERVICE_DESK_PROJECT;

export const ViewSettings = ({
	rapidViewId,
	isClassic,
	hasSwimlanes,
	showCardDetails,
	epicCustomName,
	swimlaneIds,
	isViewSettingPanelOpen,
	onSwimlaneExpandAll,
	onSwimlaneCollapseAll,
	onSetShowUnscheduledColumn,
	onSetShowOfftrackDependencyLines,
	onSetIssueIdsToShowDependencies,
	onTogglePanel,
}: ViewSettingsProps) => {
	const isIPBoard = useBoardSelector(getIsIncrementPlanningBoard);
	const handleSwimlaneCollapseAll = useCallback(() => {
		onSwimlaneCollapseAll(swimlaneIds, isIPBoard);
	}, [onSwimlaneCollapseAll, swimlaneIds, isIPBoard]);

	const isCompanyManaged = useIsCMPBoard();
	const isQuickFilterAvailable = useBoardSelector(getCustomFilters).length > 0;
	const showFilterBarToggle =
		isCompanyManaged && (!isViewSettingAsPanelExpEnabledWithNoExposure() || isQuickFilterAvailable);
	const isIncrementPlanningBoard = useIsIncrementPlanningBoard();
	const isJSMBoard = useIsJSMBoard();
	const isJSWBoard = useIsJSWBoard();

	const showUnscheduledColumn = useShowUnscheduledColumn();
	const showOfftrackDependencyLines = useShowOfftrackDependencyLines();
	const issueIdsToShowDependencies = useIssueIdsToShowDependencies();
	const isDaysInColumnEnabled = useBoardSelector(getIsDaysInColumnEnabled);
	const isDueDateEnabled = useBoardSelector(getIsDueDateEnabled);
	const isLabelsEnabled = useBoardSelector(getIsIssueLabelsEnabled);
	const cardExtraFields = useBoardSelector(getCardExtraFields);
	const isEstimateEnabled = useBoardSelector(getIsEstimateEnabled);
	const isPriorityEnabled = useBoardSelector(getIsPriorityEnabled);
	const canEdit = useBoardSelector(getCanEditBoard);
	const canInvite = useBoardSelector(getCanInviteOthersToProject);
	const { canUserLinkIssue, issueLinkTypes } = useBoardSelector(getIssueLinksPermissions);
	const firstSelectableIssueId = useBoardSelector(getFirstSelectableIssueAscending);
	const issues = useBoardSelector(getIssues);

	const isIssueLinkingEnabledOnInstance = canUserLinkIssue && issueLinkTypes?.length > 0;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const boardIssueIds = useBoardSelector(boardOrderedIssueIdsSelector);
	const onLinkedIssuesStatsToggledOn = useBoardActionCreator(() =>
		fetchIssueLinksStats(boardIssueIds, { analyticsEvent: createAnalyticsEvent({}) }),
	);
	const onShowCardCoversToggledOn = useBoardActionCreator(() =>
		fetchCardCovers(boardIssueIds, { analyticsEvent: createAnalyticsEvent({}) }),
	);

	const [, { doFetch: updateWorkSuggestionsEnabled }] = useNextBestTaskUserPreference();

	const {
		data: isWorkSuggestionsEnabledData,
		update: updateWorkSuggestionsResource,
		loading: loadingWorkSuggestionsEnabled,
	} = useResource(nextBestTaskFeatureResource);
	const onToggleWorkSuggestions = (analyticsEvent: UIAnalyticsEvent) => {
		const newOptInValue = !isWorkSuggestionsEnabledData?.hasUserOptedIn;
		updateWorkSuggestionsResource(() => ({
			hasUserOptedIn: newOptInValue,
		}));
		updateWorkSuggestionsEnabled({ hasUserOptedIn: newOptInValue });
		fireUIAnalytics(analyticsEvent, WORK_SUGGESTION_TOGGLE_ACTION_SUBJECT_ID, {
			hasUserOptedIn: newOptInValue,
		});
	};

	useEffect(() => {
		// Setting the unscheduled column visibility state in IP board based on the value from view settings package
		if (isIncrementPlanningBoard) {
			onSetShowUnscheduledColumn(showUnscheduledColumn);
			onSetShowOfftrackDependencyLines(showOfftrackDependencyLines);
			onSetIssueIdsToShowDependencies(issueIdsToShowDependencies);
		}
	}, [
		isIncrementPlanningBoard,
		showUnscheduledColumn,
		showOfftrackDependencyLines,
		issueIdsToShowDependencies,
		onSetShowUnscheduledColumn,
		onSetShowOfftrackDependencyLines,
		onSetIssueIdsToShowDependencies,
	]);

	if (isIncrementPlanningBoard) {
		// Increment planning settings only have unscheduled column toggle and swimlane expand/collapse
		return (
			<ViewSettingsPopup
				boardId={rapidViewId}
				isClassic={isClassic}
				view={View.INCREMENT_PLANNING_BOARD}
				isSwimlanesEnabled={hasSwimlanes}
				onSwimlaneExpandAll={onSwimlaneExpandAll}
				onSwimlaneCollapseAll={handleSwimlaneCollapseAll}
				dependencyIssueOptions={issues}
			/>
		);
	}

	if (isViewSettingAsPanelExpEnabledWithNoExposure() && isJSMBoard) {
		return (
			<ViewSettingsPopup
				boardId={rapidViewId}
				isClassic={isClassic}
				view={View.JSM_BOARD}
				epicCustomName={epicCustomName}
				showCardDetails={showCardDetails}
				isSwimlanesEnabled={hasSwimlanes}
				onSwimlaneExpandAll={onSwimlaneExpandAll}
				onSwimlaneCollapseAll={handleSwimlaneCollapseAll}
				showFilterBarToggle={showFilterBarToggle}
			/>
		);
	}

	const renderAdditionalFieldSpotlight = () => (
		<ViewSettingsSyncFromLocalStorageWrapper
			boardId={rapidViewId}
			view={View.BOARD}
			isDaysInColumnEnabled={isDaysInColumnEnabled}
			cardExtraFields={cardExtraFields}
			isDueDateEnabled={isDueDateEnabled}
			isLabelsEnabled={isLabelsEnabled}
			isEstimateEnabled={isEstimateEnabled}
			isPriorityEnabled={isPriorityEnabled}
		>
			<ViewSettingsButton isSelected={isViewSettingPanelOpen || false} onClick={onTogglePanel} />
		</ViewSettingsSyncFromLocalStorageWrapper>
	);

	if (isViewSettingAsPanelExpEnabledWithNoExposure()) {
		return (
			<>
				<ViewSettingsPanelOnboarding
					isViewSettingPanelOpen={isViewSettingPanelOpen}
					onTogglePanel={onTogglePanel}
				/>
				<ViewSettingsPanelButtonSpotlight>
					{renderAdditionalFieldSpotlight()}
				</ViewSettingsPanelButtonSpotlight>
			</>
		);
	}

	return (
		<ViewSettingsPopup
			boardId={rapidViewId}
			isClassic={isClassic}
			view={View.BOARD}
			epicCustomName={epicCustomName}
			showCardDetails={showCardDetails}
			isSwimlanesEnabled={hasSwimlanes}
			onSwimlaneExpandAll={onSwimlaneExpandAll}
			onSwimlaneCollapseAll={handleSwimlaneCollapseAll}
			showFilterBarToggle={showFilterBarToggle}
			isWorkSuggestionsEnabled={!!isWorkSuggestionsEnabledData?.hasUserOptedIn}
			showWorkSuggestions={
				isWorkSuggestionsEnabledData != null && !loadingWorkSuggestionsEnabled && isJSWBoard
			}
			onToggleWorkSuggestions={onToggleWorkSuggestions}
			showLinkedIssueStatsToggle={
				isIssueLinkingEnabledOnInstance && Boolean(firstSelectableIssueId)
			}
			onLinkedIssuesStatsToggledOn={onLinkedIssuesStatsToggledOn}
			isAddPeopleButtonEnabled={
				canEdit ||
				(canInvite && expValEquals('open_invite_for_open_tmp_projects', 'cohort', 'experiment'))
			}
			onShowCardCoversToggledOn={onShowCardCoversToggledOn}
		/>
	);
};
