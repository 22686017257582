import type {
	ActionPromise,
	BulkEditPayload,
} from '../../../../model/issue/issue-increment-planning-types';

export const ISSUE_BULK_UPDATE_REQUEST =
	'state.actions.issue.bulk-update.ISSUE_BULK_UPDATE_REQUEST' as const;

export type IssueBulkUpdateRequestAction = {
	type: typeof ISSUE_BULK_UPDATE_REQUEST;
	payload: BulkEditPayload;
	promise?: ActionPromise;
};

export const issueBulkUpdateRequest = (payload: BulkEditPayload): IssueBulkUpdateRequestAction => ({
	type: ISSUE_BULK_UPDATE_REQUEST,
	payload,
});

export const ISSUE_BULK_UPDATE_SUCCESS =
	'state.actions.issue.bulk-update.ISSUE_BULK_UPDATE_SUCCESS' as const;

export type IssueBulkUpdateSuccessAction = {
	type: typeof ISSUE_BULK_UPDATE_SUCCESS;
};

export const issueBulkUpdateSuccess = (): IssueBulkUpdateSuccessAction => ({
	type: ISSUE_BULK_UPDATE_SUCCESS,
});

export const ISSUE_BULK_UPDATE_FAILED =
	'state.actions.issue.bulk-update.ISSUE_BULK_UPDATE_FAILED' as const;

export type IssueBulkUpdateFailedAction = {
	type: typeof ISSUE_BULK_UPDATE_FAILED;
};

export const issueBulkUpdateFailed = (): IssueBulkUpdateFailedAction => ({
	type: ISSUE_BULK_UPDATE_FAILED,
});

export type Action =
	| IssueBulkUpdateRequestAction
	| IssueBulkUpdateSuccessAction
	| IssueBulkUpdateFailedAction;
