import { useEffect, useRef } from 'react';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import type {
	UseHandleOnIssueLinksStatsCloseOptions,
	UseHandleOnIssueLinksStatsCloseOptionsResult,
} from './types';

export const useHandleOnIssueLinksStatsClose = ({
	isIssueLinkStatsOpen,
	isLinkIssueModalOpen,
}: UseHandleOnIssueLinksStatsCloseOptions): UseHandleOnIssueLinksStatsCloseOptionsResult => {
	const openTriggerRef = useRef<HTMLElement | null>(null);

	const previousIsIssueLinkStatsOpen = usePreviousWithInitial(isIssueLinkStatsOpen);
	const previousisLinkIssueModalOpen = usePreviousWithInitial(isLinkIssueModalOpen);

	const isIssueLinkStatsClosed =
		isIssueLinkStatsOpen === false &&
		previousIsIssueLinkStatsOpen === true &&
		// adding this condition here to prevent focus action when opening link issue modal from stats popup
		isLinkIssueModalOpen === false;

	const isIssueLinkModalClosed =
		isLinkIssueModalOpen === false && previousisLinkIssueModalOpen === true;

	const updateRef = (element: HTMLElement | null) => {
		openTriggerRef.current = element;
	};

	useEffect(() => {
		if (isIssueLinkStatsClosed || isIssueLinkModalClosed) {
			openTriggerRef.current?.focus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isIssueLinkStatsOpen, isLinkIssueModalOpen]);

	return updateRef;
};
