import React, { useState, type ComponentType } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { keyframes } from 'styled-components';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { type CustomTriggerProps, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIsDismissed } from '@atlassian/jira-onboarding-quickstart-core/src/services';
import {
	DROPDOWN,
	FireUiAnalytics,
	FireScreenAnalytics,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import { ConditionalNudgeWrapper } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/conditional-nudge-wrapper/index.tsx';
import { BoardInProgressColumnNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/kanban-onboarding-tour/board-in-progress-column/async.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import ColumnClearAllMenuItem from './item-clear-all';
import ColumnDeleteMenuItem from './item-delete';
import ColumnLimitsMenuItem from './item-limits';
import messages from './messages';

export type Props = {
	isDefaultOpen: boolean;
	isDisabled: boolean;
	columnId: number;
	externalValues: {
		columnIndex: number;
	};
	title: string;
	onVisibilityChange: (visible: boolean) => void;
	onOpenChange: (attrs: { isOpen: boolean }) => void;
	intl: IntlShape;
};

export const ColumnMenu = (props: Props) => {
	const {
		columnId,
		externalValues,
		title,
		onOpenChange,
		onVisibilityChange,
		intl: { formatMessage },
		isDisabled = false,
		isDefaultOpen = false,
	} = props;
	const [isOpen, setIsOpen] = useState(isDefaultOpen);
	const [isOnboardingDismissed] = useIsDismissed();

	const handleOpenChange = (attrs: { isOpen: boolean }) => {
		setIsOpen(attrs.isOpen);
		onOpenChange(attrs);
	};

	const handleClick = () => {
		setIsOpen(false);
		onOpenChange({ isOpen: false });
	};

	const toggleMenu = (open: boolean) => () => {
		if (onVisibilityChange) {
			onVisibilityChange(open);
			setIsOpen(open);
		}
	};

	const renderTrigger = ({ triggerRef, ...triggerProps }: CustomTriggerProps) =>
		isVisualRefreshEnabled() ? (
			<IconButton
				{...triggerProps}
				ref={triggerRef}
				appearance="subtle"
				icon={ShowMoreHorizontalIcon}
				label={formatMessage(messages.dropdownMenuAriaLabel, {
					heading: title,
				})}
			/>
		) : (
			<Button
				{...triggerProps}
				appearance="subtle"
				iconBefore={
					fg('enghealth-12479-jsw-board-visual-refresh') ? (
						<ShowMoreHorizontalIcon
							label="More actions"
							LEGACY_fallbackIcon={MoreIcon}
							color={token('color.icon')}
						/>
					) : (
						<MoreIcon label="More actions" />
					)
				}
				aria-label={formatMessage(messages.dropdownMenuAriaLabelOld, {
					heading: title,
				})}
				ref={triggerRef}
			/>
		);

	return (
		<MeatballMenuButtonBackground>
			<ContextualAnalyticsData
				sourceName="columnSettings"
				sourceType={DROPDOWN}
				attributes={{ columnId, columnIndex: externalValues.columnIndex }}
			>
				<ConditionalNudgeWrapper
					Wrapper={BoardInProgressColumnNudgeAsync}
					conditionsToApplyWrapper={
						(fg('board_in_progress_nudge_conditional_load') ? !isOnboardingDismissed : true) &&
						externalValues.columnIndex === 2
					}
					onShow={toggleMenu(true)}
					onHide={toggleMenu(false)}
				>
					<DropdownMenu
						isOpen={isOpen}
						onOpenChange={handleOpenChange}
						trigger={renderTrigger}
						placement="bottom-end"
					>
						<DropdownItemGroup>
							<ColumnLimitsMenuItem
								columnId={columnId}
								isDisabled={isDisabled}
								onClick={handleClick}
							/>
							<ColumnClearAllMenuItem
								columnId={columnId}
								isDisabled={isDisabled}
								onClick={handleClick}
							/>
							<ColumnDeleteMenuItem
								columnId={columnId}
								isDisabled={isDisabled}
								onClick={handleClick}
							/>
						</DropdownItemGroup>
						<FireUiAnalytics action="toggled" actionSubject="dropdownMenu" />
						<FireScreenAnalytics />
					</DropdownMenu>
				</ConditionalNudgeWrapper>
			</ContextualAnalyticsData>
		</MeatballMenuButtonBackground>
	);
};

export const hideableColumnMenu =
	(Menu: ComponentType<Props>): ComponentType<Props> =>
	(props: Props) =>
		props.isDisabled ? null : <Menu {...props} />;

export const HideableMenu = hideableColumnMenu(ColumnMenu);

export default HideableMenu;

const MeatballBackgroundAnimation = keyframes({
	'0%': {
		opacity: 0,
	},
	'100%': {
		opacity: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MeatballMenuButtonBackground = styled.div({
	animation: `${MeatballBackgroundAnimation} 0.2s ease forwards`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('color.background.neutral', colors.N50),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
});
