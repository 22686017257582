import { useCallback } from 'react';
import type ApolloClient from 'apollo-client';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { SwagError } from '@atlassian/jira-software-swag/src/common/utils/error/index.tsx';
import { isExpectedGraphQLError } from '../../common/utils';
import type {
	AssignIssue,
	AssignIssueVariables as AssignToMeMutationArgs,
} from '../../services/assign-to-me-mutation/__generated_apollo__/AssignIssue.ts';
import { MUTATION, useAssignToMeMutation } from '../../services/assign-to-me-mutation/main';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onMutationError = (error: any) => {
	if (isExpectedGraphQLError(error)) {
		return;
	}

	fireErrorAnalytics({
		error,
		meta: {
			id: 'assignToMeMutationFailed',
			packageName: 'jiraSoftwareKeyboardShortcuts',
		},
	});
};

export const assignToMe = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	swagClient: ApolloClient<any>,
	variables: AssignToMeMutationArgs,
): Promise<AssignIssue> =>
	swagClient
		.mutate<AssignIssue>({
			mutation: MUTATION,
			variables,
		})
		.then((result) => {
			if ((result.errors && result.errors.length) || !result.data) {
				throw new SwagError({
					// @ts-expect-error - TS2322 - Type 'readonly GraphQLError[] | undefined' is not assignable to type 'GraphqlError[] | undefined'.
					graphQLErrors: result.errors,
					errorMessage: 'Assign to me failed',
				});
			}

			return result.data;
		})
		.catch((err) => {
			onMutationError(err);
			throw err;
		});

export const useAssignToMe = () => {
	const [assignToMeMutation] = useAssignToMeMutation();

	return useCallback(
		({ issueId, accountId }: AssignToMeMutationArgs) => {
			const assignMutationPromise = assignToMeMutation({
				variables: { issueId, accountId },
			});

			assignMutationPromise.catch(onMutationError);

			return assignMutationPromise;
		},
		[assignToMeMutation],
	);
};
