import React, { Component } from 'react';
import OpTrigger from '@atlassian/jira-common-util-op-triggers/src/on-mount-and-update';
import Context from '../context';

type Props = {
	name: string;
};

// eslint-disable-next-line jira/react/no-class-components
export default class SetCustomMark extends Component<Props> {
	render() {
		const { name } = this.props;

		return (
			<Context.Consumer>
				{/* @ts-expect-error - TS2339 - Property 'pageId' does not exist on type 'String'. | TS2339 - Property 'onCustomMark' does not exist on type 'String'. */}
				{({ pageId, onCustomMark }) => <OpTrigger op={onCustomMark} args={[name, pageId]} />}
			</Context.Consumer>
		);
	}
}
