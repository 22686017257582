import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { setReturnToProjectUrl } from '@atlassian/jira-navigation-apps-sidebar-project-utils';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useRouterActions } from '@atlassian/react-resource-router';
import messages from './messages';

export type OwnProps = {};

export type StateProps = {
	url: string;
};

export type Props = OwnProps & StateProps;

export const ManageCustomFiltersItem = ({ url }: Props) => {
	const { formatMessage } = useIntl();
	const { push } = useRouterActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onClick = useCallback(() => {
		setReturnToProjectUrl();
		fireUIAnalytics(
			createAnalyticsEvent({ actionSubject: 'dropdownItem', action: 'clicked' }),
			'clickBoardManageCustomFilterItem',
		);
		push(url);
	}, [push, url, createAnalyticsEvent]);

	return <DropdownItem onClick={onClick}>{formatMessage(messages.displayText)}</DropdownItem>;
};

export default ManageCustomFiltersItem;
