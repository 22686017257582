import React, { useCallback } from 'react';
import { getLongTasksMetrics } from '@atlassian/jira-common-long-task-metrics';
import { boardScrollReporter } from '@atlassian/jira-common-long-task-metrics/src/reporters/software-board-scroll';
import BoardScrollNotifier from '@atlassian/jira-platform-board-kit/src/ui/board-scroll-notifier/index.tsx';
import { connect } from '@atlassian/jira-react-redux';
import { sessionIdSelector } from '../../../state/selectors/software/software-selectors';
import {
	columnsWithResolutionSelector,
	platformSwimlanesSelector,
} from '../../../state/selectors/work/work-selectors';
import type { State } from '../../../state/types';
import type { Props } from './types';

export const ScrollObserver = ({ columns, swimlanes, boardSessionId }: Props) => {
	const onStartScrollingObserver = useCallback(() => {
		getLongTasksMetrics('scroll').start('board', undefined, {
			swimlaneCount: swimlanes.length,
			columnCount: columns.length,
			boardSessionId,
		});
	}, [swimlanes, columns, boardSessionId]);

	const onStopScrollingObserver = useCallback(() => {
		getLongTasksMetrics('scroll').stop(boardScrollReporter);
	}, []);

	return (
		<BoardScrollNotifier
			onScrollStart={onStartScrollingObserver}
			onScrollStop={onStopScrollingObserver}
		/>
	);
};

const mapStateToProps = (state: State): Props => ({
	columns: columnsWithResolutionSelector(state),
	swimlanes: platformSwimlanesSelector(state),
	boardSessionId: sessionIdSelector(state),
});

const ScrollObserverConnected = connect(mapStateToProps, {})(ScrollObserver);

export default ScrollObserverConnected;
