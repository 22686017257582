export const FULL = 'full' as const;
export const COMPACT = 'compact' as const;
export const SIMPLE = 'simple' as const;
export const TABLE_ROW = 'table_row' as const;

export const HIDDEN = 'hidden' as const;
export const VISIBLE = 'visible' as const;

export const BLUR = 'blur' as const;
export const ENTER = 'enter' as const;
export const ESCAPE = 'escape' as const;
export const CREATE_BUTTON = 'create_button' as const;
export const MULTI_DIALOG_SINGLE_CREATE_BUTTON = 'multi_dialog_single_create_button' as const;
export const MULTI_DIALOG_MULTI_CREATE_BUTTON = 'multi_dialog_multi_create_button' as const;

export const MAX_SUMMARY_LENGTH = 255 as const;

export const TOOLTIP_DELAY = 500 as const;
export const ADD_ISSUE_TYPE_OPTION = 'add-issue-type-option';
