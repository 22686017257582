/** @jsx jsx */
import React, {
	type RefObject,
	type ReactNode,
	Component,
	type ReactElement,
	type KeyboardEvent,
	type MouseEvent,
	type SyntheticEvent,
} from 'react';
import { styled, css, jsx } from '@compiled/react';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import keycode from 'keycode';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Avatar from '@atlaskit/avatar';
import FlagFilledIcon from '@atlaskit/icon/core/flag-filled';
import ChevronDownIconOld from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIconOld from '@atlaskit/icon/glyph/chevron-right';
import FlagFilledIconOld from '@atlaskit/icon/glyph/flag-filled';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import Lozenge from '@atlaskit/lozenge';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
// eslint-disable-next-line import/order
import { UNDEFINED } from '@atlassian/jira-common-constants/src/status-categories';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	type Color,
	type ColorSchema,
	PURPLE,
} from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { transformColorToTheme } from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import { CardKey } from '@atlassian/jira-platform-card/src/common/ui/key/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { Link as LinkComponent } from '@atlassian/react-resource-router';
import {
	layout,
	zIndex,
	swimlaneHeaderHeight,
	unscheduledColumnHeaderHeight,
} from '../../common/constants/styles';
import type { IssueStatus } from '../../common/types';
import { getLozengeAppearanceByStatus } from '../../common/utils/lozenge-appearance';
import CardEpic from './epic';
import messages from './messages';
import { TruncatedTooltip } from './truncated-tooltip';
import type { IssueParent, Assignee, SwimlaneModeId } from './types';

type Props = {
	isSwimlaneHeaderClickable: boolean;
	issueKey: string | null;
	issueStatus: IssueStatus | null;
	issueParent: IssueParent | null;
	name: string;
	spotlightTargetId: string;
	showCardAmount?: boolean;
	cardAmount: number;
	icon: ReactElement | null;
	children: ReactElement | ReactElement[];
	collapsed: boolean;
	assignee: Assignee | null;
	customSwimlaneContent: ReactNode;
	customRightContent: ReactNode;
	summaryTooltip: string | null;
	// TODO Update class to functional component and use the analytics hooks
	onToggle: (analyticsEvent: UIAnalyticsEvent) => void;
	onClick: (analyticsEvent: UIAnalyticsEvent) => void;
	intl: IntlShape;
	isSubtask: boolean;
	link?: string;
	projectKey: string | null | undefined;
	isFlagged: boolean;
	isDone: boolean;
	wrapperStyle?: React.CSSProperties;
	innerRef?: RefObject<HTMLDivElement>;
	lozenge?: ReactElement | null;
	customLeftContent?: ReactElement | ReactNode | null;
	hasShadow?: boolean;
	swimlaneMode?: SwimlaneModeId | null;
	isUnscheduledWorkColumnPanel: boolean;
};

const AVATAR_BORDER_COLOR = 'transparent';

const LOZENGE_THEMES = transformColorToTheme(
	(colorSchema: ColorSchema) => colorSchema.textBackground,
);

const getLozengeThemeByColor = (color: Color) =>
	// This uses schema from src/packages/issue/epic-color/src/common/constants.js
	LOZENGE_THEMES[color] || LOZENGE_THEMES[PURPLE];

// eslint-disable-next-line jira/react/no-class-components
class Swimlane extends Component<Props> {
	static defaultProps = {
		issueKey: null,
		issueParent: null,
		issueStatus: null,
		isSwimlaneHeaderClickable: false,
		collapsed: false,
		spotlightTargetId: '',
		assignee: null,
		icon: null,
		customSwimlaneContent: null,
		customRightContent: null,
		summaryTooltip: null,
		onToggle: noop,
		onClick: noop,
		isSubtask: false,
		link: undefined,
		projectKey: undefined,
		isFlagged: false,
		isDone: false,
		wrapperStyles: {},
		lozenge: null,
		showCardAmount: true,
		hasShadow: true,
		isUnscheduledWorkColumnPanel: false,
	};

	onKeyDown = (event: KeyboardEvent<HTMLDivElement>, analyticsEvent: UIAnalyticsEvent) => {
		if (event.keyCode === keycode('enter') || event.keyCode === keycode('space')) {
			event.preventDefault();
			this.props.onToggle(analyticsEvent);
		}
	};

	onSwimlaneHeaderKeyDown = (
		event: KeyboardEvent<HTMLDivElement>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		if (event.keyCode === keycode('enter') || event.keyCode === keycode('space')) {
			event.preventDefault();
			this.onLinkClick(event, analyticsEvent);
		}
	};

	onLinkClick = (
		e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLDivElement>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		e.stopPropagation();
		this.props.onClick(analyticsEvent);
	};

	getSwimlaneContent(): ReactElement {
		const {
			intl: { formatMessage },
			showCardAmount,
			cardAmount,
			icon,
			name,
			collapsed,
			isSwimlaneHeaderClickable,
			issueKey,
			issueParent,
			issueStatus,
			assignee,
			customSwimlaneContent,
			summaryTooltip,
			isSubtask,
			link,
			projectKey,
			isFlagged,
			isDone,
			lozenge,
			swimlaneMode,
			customLeftContent,
		} = this.props;

		if (!isNil(customSwimlaneContent)) {
			return <>{customSwimlaneContent}</>;
		}

		const cardsAmountLabel = showCardAmount
			? formatMessage(isSubtask ? messages.subtaskCount : messages.issueCount, {
					count: cardAmount || 0,
				})
			: null;

		const summary = projectKey ? `${name} (${projectKey})` : name;

		const headerContent = (
			<Flex gap="space.100" alignItems="center" xcss={headerContentStyles}>
				{(icon || (issueParent && swimlaneMode)) && (
					<Flex alignItems="center" gap="space.050">
						{icon}
						{issueParent && swimlaneMode === 'SWIMLANE_BY_PARENT_ISSUE' && (
							<Tooltip content={issueParent.summary}>
								<ColorBox color={getLozengeThemeByColor(issueParent.color)} />
							</Tooltip>
						)}
					</Flex>
				)}
				{issueKey && <CardKey text={issueKey} stopEventPropagation={false} isDone={isDone} />}
				{summaryTooltip ? (
					<Tooltip content={summaryTooltip} data-testid="platform-board-kit.ui.swimlane.tooltip">
						<SummarySection data-testid="platform-board-kit.ui.swimlane.summary-section">
							{summary}
						</SummarySection>
					</Tooltip>
				) : (
					<TruncatedTooltip content={summary}>
						{({ ref }) => (
							<SummarySection
								ref={ref}
								data-testid="platform-board-kit.ui.swimlane.summary-section"
							>
								{summary}
							</SummarySection>
						)}
					</TruncatedTooltip>
				)}
				{lozenge && <LozengeWrapper>{lozenge}</LozengeWrapper>}
				{customLeftContent}
				{showCardAmount && <SwimlaneIssuesAmount>{`(${cardsAmountLabel})`}</SwimlaneIssuesAmount>}
				{swimlaneMode !== 'SWIMLANE_BY_PARENT_ISSUE' && issueParent && (
					<CardEpic color={issueParent.color}>{issueParent.summary}</CardEpic>
				)}
				{issueStatus && (issueStatus.name || '').toString().length > 0 && (
					<StatusSection>
						<ScreenReaderText>{formatMessage(messages.statusLozenge)}</ScreenReaderText>
						<Tooltip content={issueStatus.name}>
							<Lozenge
								appearance={getLozengeAppearanceByStatus(
									issueStatus && issueStatus.category ? issueStatus.category : UNDEFINED,
								)}
								testId="platform-board-kit.ui.swimlane.lozenge"
							>
								{issueStatus.name}
							</Lozenge>
						</Tooltip>
					</StatusSection>
				)}
				{isFlagged &&
					(fg('tnk-1614-visual-refresh-boardkit-icons') ? (
						<FlagFilledIcon
							label={formatMessage(messages.flagged)}
							color={token('color.icon.danger', colors.R300)}
							LEGACY_fallbackIcon={FlagFilledIconOld}
							LEGACY_primaryColor={token('color.icon.danger', colors.R300)}
							LEGACY_size="small"
						/>
					) : (
						<FlagFilledIconOld
							label={formatMessage(messages.flagged)}
							primaryColor={token('color.icon.danger', colors.R300)}
							size="small"
						/>
					))}
				{assignee && (
					<AvatarWrapper>
						<Tooltip content={assignee.name}>
							<Avatar
								src={assignee.avatarUrl}
								name={assignee.name}
								size="small"
								borderColor={AVATAR_BORDER_COLOR}
							/>
						</Tooltip>
					</AvatarWrapper>
				)}
			</Flex>
		);

		const renderHeader = () => {
			if (isSwimlaneHeaderClickable) {
				if (issueKey) {
					return (
						<LinkButtonWithAnalytics
							data-testid="platform-board-kit.ui.swimlane.link-button"
							onClick={this.onLinkClick}
							// @ts-expect-error - Type '(event: KeyboardEvent<HTMLDivElement>, analyticsEvent: UIAnalyticsEvent) => void' is not assignable to type 'KeyboardEventHandler<HTMLButtonElement>'.
							onKeyDown={this.onSwimlaneHeaderKeyDown}
						>
							{headerContent}
						</LinkButtonWithAnalytics>
					);
				}

				if (link) {
					return (
						<LinkWithAnalytics
							href={link}
							// @ts-expect-error - Type '(e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLDivElement>, analyticsEvent: UIAnalyticsEvent) => void' is not assignable to type '((e: MouseEvent<Element, MouseEvent> | KeyboardEvent<Element>, args_1: UIAnalyticsEvent) => any) | ((e: MouseEvent<...> | KeyboardEvent<...>, args_1: UIAnalyticsEvent) => any) | undefined'.
							onClick={this.onLinkClick}
							// @ts-expect-error - Type '(event: KeyboardEvent<HTMLDivElement>, analyticsEvent: UIAnalyticsEvent) => void' is not assignable to type 'KeyboardEventHandler<HTMLAnchorElement>'.
							onKeyDown={this.onSwimlaneHeaderKeyDown}
						>
							{headerContent}
						</LinkWithAnalytics>
					);
				}
			}

			return headerContent;
		};

		const renderChevronIcon = () => {
			if (collapsed) {
				return fg('tnk-1614-visual-refresh-boardkit-icons') ? (
					<Box xcss={isVisualRefreshEnabled() && iconSpacing}>
						<ChevronRightIcon
							LEGACY_fallbackIcon={ChevronRightIconOld}
							LEGACY_primaryColor={token('color.icon', '#44546F')}
							color={token('color.icon')}
							label=""
						/>
					</Box>
				) : (
					<ChevronRightIconOld primaryColor={token('color.icon', '#44546F')} label="" />
				);
			}

			return fg('tnk-1614-visual-refresh-boardkit-icons') ? (
				<Box xcss={isVisualRefreshEnabled() && iconSpacing}>
					<ChevronDownIcon
						LEGACY_fallbackIcon={ChevronDownIconOld}
						LEGACY_primaryColor={token('color.icon', '#44546F')}
						color={token('color.icon')}
						label=""
					/>
				</Box>
			) : (
				<ChevronDownIconOld primaryColor={token('color.icon', '#44546F')} label="" />
			);
		};

		return (
			<SwimlaneContent
				data-testid="platform-board-kit.ui.swimlane.swimlane-content"
				role="button"
				// @ts-expect-error Type '(event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void' is not assignable to type 'MouseEventHandler<HTMLDivElement>'.
				onClick={this.toggleColumns}
				tabIndex={0}
				// @ts-expect-error Type '(event: KeyboardEvent<HTMLDivElement>, analyticsEvent: UIAnalyticsEvent) => void' is not assignable to type 'KeyboardEventHandler<HTMLDivElement>'.
				onKeyDown={this.onKeyDown}
				aria-expanded={!collapsed}
			>
				{renderChevronIcon()}

				{renderHeader()}
			</SwimlaneContent>
		);
	}

	toggleColumns = (event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
		this.props.onToggle(analyticsEvent);
	};

	// Extracted for feature flagging purposes
	// Return all extracted JSX when cleaning up com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1 to render function
	renderSwimlaneInnerContent = () => {
		const { spotlightTargetId, customRightContent } = this.props;
		return (
			<>
				<SpotlightTarget name={spotlightTargetId}>{this.getSwimlaneContent()}</SpotlightTarget>
				<EngagementSpotlight engagementId={spotlightTargetId} />
				{Boolean(customRightContent) && (
					<SwimlaneRightContentContainer>{customRightContent}</SwimlaneRightContentContainer>
				)}
			</>
		);
	};

	renderSwimlaneInner = () => {
		if (ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1')) {
			const { isUnscheduledWorkColumnPanel } = this.props;
			return (
				<Box
					xcss={[
						SwimlaneInnerFullWidthStyles,
						isUnscheduledWorkColumnPanel && SwimlaneInnerSingleColumnSizedStyles,
					]}
				>
					{this.renderSwimlaneInnerContent()}
				</Box>
			);
		}
		return <SwimlaneInner>{this.renderSwimlaneInnerContent()}</SwimlaneInner>;
	};

	renderSwimlaneBar = () => {
		if (!ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1')) {
			return <SwimlaneBar>{this.renderSwimlaneInner()}</SwimlaneBar>;
		}
		const { isUnscheduledWorkColumnPanel } = this.props;
		return (
			<div
				css={[
					swimlaneBarStyles,
					isUnscheduledWorkColumnPanel && swimlaneBarUnscheduledColumnStyles,
				]}
			>
				{this.renderSwimlaneInner()}
			</div>
		);
	};

	render() {
		const { children, collapsed, wrapperStyle, innerRef, hasShadow } = this.props;

		return (
			<div
				data-testid="platform-board-kit.ui.swimlane.swimlane-wrapper"
				// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={wrapperStyle}
				ref={innerRef}
			>
				{ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1') && hasShadow && (
					<>
						<SwimlaneShadow />
						<SwimlaneShadowMask />
					</>
				)}
				{!ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1') && (
					<>
						<SwimlaneShadow />
						<SwimlaneShadowMask />
					</>
				)}
				{this.renderSwimlaneBar()}
				{!collapsed && (
					<Flex testId="platform-board-kit.ui.swimlane.swimlane-columns">{children}</Flex>
				)}
			</div>
		);
	}
}

export default injectIntl(Swimlane);

const swimlaneShadowSize = 2;
const headerHeight = swimlaneHeaderHeight;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlaneContent = styled.div({
	display: 'inline-flex',
	flex: 1,
	minWidth: 0,
	alignItems: 'center',
	marginLeft: token('space.025', '2px'),
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlaneInner = styled.div({
	display: 'flex',
	gap: token('space.100', '0.5rem'),
	alignItems: 'center',
	flex: 1,
	// So things dont overlap on smaller screens
	minWidth: '700px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `min(100%, calc(var(--board-scroll-element-width, 2000) * 1px - ${layout.boardBorderWidth}px))`,
});

const SwimlaneInnerSingleColumnSizedStyles = xcss({
	minWidth: '100%',
	maxWidth: '100%',
});

const SwimlaneInnerFullWidthStyles = xcss({
	display: 'flex',
	gap: 'space.100',
	alignItems: 'center',
	flex: 1,
	// So things dont overlap on smaller screens
	minWidth: '700px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `min(100%, calc(var(--board-scroll-element-width, 2000) * 1px - ${layout.boardBorderWidth}px))`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlaneShadow = styled.div({
	display: 'block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('color.blanket', colors.N80),
	opacity: 0.2,
	height: `${swimlaneShadowSize}px`,
	position: 'sticky',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `${headerHeight * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.stickySwimlaneBar,
	pointerEvents: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	margin: `0 ${layout.columnMargin}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlaneShadowMask = styled.div({
	display: 'block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('elevation.surface', colors.N0),
	height: `${swimlaneShadowSize}px`,
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `${headerHeight}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.stickySwimlaneBar,
	pointerEvents: 'none',
	margin: `${token('space.negative.025', '-2px')} 0 0 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarWrapper = styled.div({
	height: '28px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlaneIssuesAmount = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.UNSAFE_small', fontFallback.body.UNSAFE_small),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N300),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlaneBar = styled.div({
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('elevation.surface', colors.N0),
	borderRadius: '3px',
	'&:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', colors.B200)}`,
		outline: 'none',
	},

	position: 'sticky',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.stickySwimlaneBar,
	paddingTop: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${headerHeight - swimlaneShadowSize}px`,
	marginTop: token('space.negative.025', '-2px'),
	marginBottom: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `${headerHeight}px`,
});

const swimlaneBarStyles = css({
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('elevation.surface', colors.N0),
	borderRadius: '3px',
	'&:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', colors.B200)}`,
		outline: 'none',
	},

	position: 'sticky',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.stickySwimlaneBar,
	paddingTop: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${headerHeight - swimlaneShadowSize}px`,
	marginTop: token('space.negative.025', '-2px'),
	marginBottom: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `${headerHeight}px`,
});

const swimlaneBarUnscheduledColumnStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `${unscheduledColumnHeaderHeight}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkButton = styled.button({
	background: 'none',
	border: 'none',
	padding: `0 ${token('space.100', '8px')} 0 0`,
	font: 'inherit',
	cursor: 'pointer',
	minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Link = styled(LinkComponent)({
	background: 'none',
	border: 'none',
	padding: `0 ${token('space.100', '8px')} 0 0`,
	font: 'inherit',
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&, &:hover': {
		textDecoration: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummarySection = styled.div({
	flex: 1,
	minWidth: 0,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StatusSection = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		lineHeight: `${gridSize * 2}px`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		display: 'flex',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlaneRightContentContainer = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	padding: `0 ${token('space.100', '8px')} 0 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapper = styled.span({
	marginLeft: token('space.100', '8px'),
	display: 'inline-block',
});

const LinkButtonWithAnalytics = ComponentWithAnalytics('swimlaneHeader', {
	onClick: 'clicked',
})(LinkButton);

const LinkWithAnalytics = ComponentWithAnalytics('swimlaneHeader', {
	onClick: 'clicked',
})(Link);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColorBox = styled.div<{ color: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) => props.color,
	width: '1.25rem',
	height: '1.25rem',
	borderRadius: token('border.radius.050', '4px'),
});

const headerContentStyles = xcss({ minWidth: '0' });

const iconSpacing = xcss({ margin: 'space.050' });
