import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';
import {
	WORK_DATA_SET,
	type WorkDataSetAction,
	showMissingIssueTypeMessage,
} from '../../state/actions/work';
import { getIsCMPBoard } from '../../state/selectors/software/software-selectors';
import type { ActionsObservable, MiddlewareAPI } from '../../state/types';

const showMissingIssueTypeMessageEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(WORK_DATA_SET)
		.filter(
			({ payload }: WorkDataSetAction) =>
				getIsCMPBoard(store.getState()) && !!payload?.missingIssueType,
		)
		.switchMap(({ payload }: WorkDataSetAction) =>
			Observable.of(showMissingIssueTypeMessage(`${payload.missingIssueType}`)),
		);

export default showMissingIssueTypeMessageEpic;
