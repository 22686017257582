import type { IssueId } from '@atlassian/jira-software-board-common';
import type { FireAnalyticsFn } from '@atlassian/jira-software-keyboard-shortcuts/src/utils/analytics/index.tsx';
import type { OptimisticId } from '../../../../model/software/software-types';
import type { FlagMeta } from '../../meta/flag-meta';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiWithFlagMeta,
	type OptimisticUiMeta,
} from '../../meta/optimistic-ui-meta';

export const ASSIGN_TO_ME_REQUEST =
	'state.actions.issue.assign-to-me.ASSIGN_TO_ME_REQUEST' as const;

export type AssignToMeRequestAction = {
	type: typeof ASSIGN_TO_ME_REQUEST;
	payload: {
		issueId: IssueId;
		assigneeAccountId: string | null;
		concurrentId?: string;
		fireShortcutAnalyticsFn?: FireAnalyticsFn;
	};
	meta: OptimisticUiMeta;
};

export const assignToMeRequest = ({
	issueId,
	assigneeAccountId,
	concurrentId,
	fireShortcutAnalyticsFn,
}: {
	issueId: IssueId;
	assigneeAccountId: string | null;
	concurrentId?: string;
	fireShortcutAnalyticsFn?: FireAnalyticsFn;
}): AssignToMeRequestAction => ({
	type: ASSIGN_TO_ME_REQUEST,
	payload: {
		issueId,
		assigneeAccountId,
		concurrentId,
		fireShortcutAnalyticsFn,
	},
	meta: beginOptimisticUiMeta(),
});

export const ASSIGN_TO_ME_REQUEST_THROTTLED =
	'state.actions.issue.assign-to-me.ASSIGN_TO_ME_REQUEST_THROTTLED' as const;

export type AssignToMeRequestThrottledAction = {
	type: typeof ASSIGN_TO_ME_REQUEST_THROTTLED;
	payload: {
		issueId: IssueId;
		assigneeAccountId: string | null;
		fireShortcutAnalyticsFn: FireAnalyticsFn;
	};
};

export const assignToMeRequestThrottled = (
	issueId: IssueId,
	assigneeAccountId: string | null,
	fireShortcutAnalyticsFn: FireAnalyticsFn,
): AssignToMeRequestThrottledAction => ({
	type: ASSIGN_TO_ME_REQUEST_THROTTLED,
	payload: {
		issueId,
		assigneeAccountId,
		fireShortcutAnalyticsFn,
	},
});

export const ASSIGN_TO_ME_SUCCESS =
	'state.actions.issue.assign-to-me.ASSIGN_TO_ME_SUCCESS' as const;

export type AssignToMeSucessAction = {
	type: typeof ASSIGN_TO_ME_SUCCESS;
	meta: OptimisticUiMeta;
};

export const assignToMeSucess = (optimisticId: OptimisticId): AssignToMeSucessAction => ({
	type: ASSIGN_TO_ME_SUCCESS,
	meta: commitOptimisticUiMeta(optimisticId),
});

export const ASSIGN_TO_ME_FAILURE =
	'state.actions.issue.assign-to-me.ASSIGN_TO_ME_FAILURE' as const;

export type AssignToMeFailureAction = {
	type: typeof ASSIGN_TO_ME_FAILURE;
	meta: OptimisticUiMeta & FlagMeta;
};

export const assignToMeFailure = (optimisticId: OptimisticId): AssignToMeFailureAction => ({
	type: ASSIGN_TO_ME_FAILURE,
	meta: revertOptimisticUiWithFlagMeta(optimisticId),
});

export type Action =
	| AssignToMeRequestAction
	| AssignToMeRequestThrottledAction
	| AssignToMeSucessAction
	| AssignToMeFailureAction;
