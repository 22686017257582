import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../actions/work';
import type { Action } from '../../../types';
import type { IssueLinkTypesState } from './types';

export const IssueLinkTypesReducer = (
	state: IssueLinkTypesState = [],
	action: Action,
): IssueLinkTypesState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		return action.payload.issueLinkTypes || [];
	}

	return state;
};
