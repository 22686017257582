import React, { useState, useRef, type ReactNode, useContext, useEffect } from 'react';
import { useFormState } from '@atlaskit/form';
import { Box, Text, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { DEFAULT_ITERATIONS_COUNT, DEFAULT_ITERATION_LENGTH } from '../../../common/constants';
import Ipcontext from '../../../services/context';
import { useFetchIssueCount } from '../../../services/fetch-issue-count';
import messages from './messages';

const IssueCountText = ({ children }: { children: ReactNode }) => (
	<Box xcss={issueCountStyles}>
		<Text as="p">{children}</Text>
	</Box>
);

const shouldRefetchIssueCount = (
	startDate: string | undefined,
	issueCountVisible: boolean,
	filterFieldId: string | undefined,
	filterFieldValue: string | undefined,
	lastStartDate: React.MutableRefObject<string | undefined>,
	lastFilterFieldValue: React.MutableRefObject<string | undefined>,
) => {
	// startDate is always required
	if (!startDate) {
		return false;
	}

	const filterIsValid = !filterFieldId || (filterFieldId && filterFieldValue);
	const countEmptyOrValuesChanged =
		!issueCountVisible ||
		lastStartDate.current !== startDate ||
		(!!filterFieldId && !!filterFieldValue && lastFilterFieldValue.current !== filterFieldValue);

	if (
		// first off run. checking filterFieldId and filterFieldValue as otherwise this will be true in case where the user selects those two and then clears the filterFieldValue - we don't want to call the endpoint with empty filterFieldValue
		!fg('fix_ip_checking_issue_count_without_filter_value') &&
		((!issueCountVisible && !filterFieldId && !filterFieldValue) ||
			lastStartDate.current !== startDate ||
			(filterFieldId && filterFieldValue && lastFilterFieldValue.current !== filterFieldValue))
	) {
		return true;
	}

	if (
		// Fetch field values if count is empty (initial state) or values is changed, and the filter field and values are valid

		countEmptyOrValuesChanged &&
		filterIsValid &&
		fg('fix_ip_checking_issue_count_without_filter_value')
	) {
		return true;
	}
	if (fg('fix_ip_checking_issue_count_without_filter_value')) {
		return false;
	}
};

const IssueCount = ({ initialStartDate }: { initialStartDate: string }) => {
	const { formatMessage } = useIntl();
	const { planId, scenarioId } = useContext(Ipcontext);
	const formState = useFormState();

	const [issueCountVisible, setIssueCountVisible] = useState(false);

	const startDate = formState?.values?.start;
	const filterFieldId = formState?.values?.filterFieldId?.value;
	const filterFieldValue = formState?.values?.filterFieldValue?.value;

	const lastStartDate = useRef<string | undefined>(initialStartDate);
	const lastFilterField = useRef<string | undefined>(undefined);
	const lastFilterFieldValue = useRef<string | undefined>(undefined);

	const {
		issueCount,
		fetchIssueCount,
		isLoading: isLoadingIssueCount,
	} = useFetchIssueCount({
		planId,
		scenarioId,
		// iterationsCount and iterationLength are not used by the backend when counting issues, hence only default value is needed
		iterationsCount: DEFAULT_ITERATIONS_COUNT,
		iterationLength: DEFAULT_ITERATION_LENGTH,
		start: new Date(startDate).getTime(),
		filterFieldId,
		filterFieldValue,
	});

	useEffect(() => {
		if (
			shouldRefetchIssueCount(
				startDate,
				issueCountVisible,
				filterFieldId,
				filterFieldValue,
				lastStartDate,
				lastFilterFieldValue,
			)
		) {
			fetchIssueCount();
			setIssueCountVisible(true);

			if (lastStartDate.current !== startDate) {
				lastStartDate.current = startDate;
			}
			if (lastFilterField.current !== filterFieldId) {
				lastFilterField.current = filterFieldId;
			}
			if (lastFilterFieldValue.current !== filterFieldValue) {
				lastFilterFieldValue.current = filterFieldValue;
			}
		}
	}, [
		fetchIssueCount,
		filterFieldId,
		lastFilterField,
		filterFieldValue,
		lastFilterFieldValue,
		issueCountVisible,
		lastStartDate,
		startDate,
	]);

	// hide issue count if any values were cleared, otherwise the count is not accurate
	useEffect(() => {
		if (
			!startDate ||
			// check if it was fetched and then cleared for the below two fields
			(!filterFieldId && lastFilterField.current) ||
			(!filterFieldValue && lastFilterFieldValue.current)
		) {
			setIssueCountVisible(false);
		}
	}, [startDate, filterFieldId, lastFilterField, filterFieldValue, lastFilterFieldValue]);

	return (
		<IssueCountText>
			{formatMessage(messages.issueCount)}{' '}
			{isLoadingIssueCount ? (
				<Spinner size="small" />
			) : (
				<Text as="strong">{issueCountVisible ? issueCount : '-'}</Text>
			)}
		</IssueCountText>
	);
};

export default IssueCount;

const issueCountStyles = xcss({
	marginRight: 'auto',
});
