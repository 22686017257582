import React, { useCallback, useMemo, memo } from 'react';
import noop from 'lodash/noop';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';
import {
	type IssueStatusCategoryId,
	toIssueStatusCategoryId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { StatusCategory, CheckboxSelectEntry } from '../../../common/types';
import ListFilter from '../../../common/ui/list';
import messages from './messages';

type Props = {
	statusCategories: StatusCategory[];
	selectedStatusCategories?: IssueStatusCategoryId[];
	onChange: (statusCategories: IssueStatusCategoryId[], attributes?: AnalyticsAttributes) => void;
	isNested?: boolean;
};

const StatusCategoryFilter = ({
	statusCategories = [],
	selectedStatusCategories = [],
	onChange = noop,
	isNested = false,
}: Props) => {
	const { formatMessage } = useIntl();
	const values = useMemo(
		(): CheckboxSelectEntry[] =>
			statusCategories.map(({ id, name }) => ({
				label: name,
				value: id,
			})),
		[statusCategories],
	);

	const onChangeValue = useCallback(
		(entries: CheckboxSelectEntry[]) => {
			const analyticAttributes = { isNested };
			onChange(
				entries.map(({ value }) => toIssueStatusCategoryId(value)),
				analyticAttributes,
			);
		},
		[onChange, isNested],
	);

	// early exit if we have only one option
	if (values.length <= 1) {
		return null;
	}

	return (
		<ListFilter
			label={formatMessage(messages.label)}
			values={values}
			selectedValues={selectedStatusCategories.map(String)}
			onChange={onChangeValue}
			isNested={isNested}
		/>
	);
};

const StatusCategoryFilterComponent = memo<Props>(StatusCategoryFilter);

StatusCategoryFilterComponent.displayName = 'StatusCategoryFilter';
// @ts-expect-error - TS2339 - Property 'whyDidYouRender' does not exist on type 'NamedExoticComponent<Props>'.
StatusCategoryFilterComponent.whyDidYouRender = true;

export default StatusCategoryFilterComponent;
