import React from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

export const CardSpinner = () => {
	const { formatMessage } = useIntl();
	const message = formatMessage(messages.loading);

	return (
		<Container>
			<Tooltip content={message}>
				<Spinner label={message} size="small" />
			</Tooltip>
		</Container>
	);
};

export default CardSpinner;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	lineHeight: 1,
});
