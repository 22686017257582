import { combineReducers } from 'redux';
import type { Action } from '../../../types';
import { boardColumnsReducer } from './columns/reducer';
import { boardConfigReducer } from './config/reducer';
import { boardFavouriteReducer } from './favourite/reducer';
import { hasFilteredIssuesReducer } from './has-filtered-issues/reducer';
import { isConfigLoadedReducer } from './is-config-loaded/reducer';
import { isCurrentUserLoadedReducer } from './is-current-user-loaded/reducer';
import { boardIssueChildrenReducer } from './issue-children/reducer';
import { boardIssueParentReducer } from './issue-parents/reducer';
import { boardIssuesReducer } from './issues/reducer';
import { permissionsReducer } from './permissions/reducer';
import { selectedSprintsReducer } from './selected-sprint/reducer';
import type { BoardState } from './types';

export default combineReducers<BoardState, Action>({
	columns: boardColumnsReducer,
	config: boardConfigReducer,
	hasFilteredIssues: hasFilteredIssuesReducer,
	issues: boardIssuesReducer,
	selectedSprints: selectedSprintsReducer,
	isConfigLoaded: isConfigLoadedReducer,
	isCurrentUserLoaded: isCurrentUserLoadedReducer,
	permissions: permissionsReducer,
	issueChildren: boardIssueChildrenReducer,
	issueParentOrder: boardIssueParentReducer,
	boardFavourite: boardFavouriteReducer,
});
