import { useEffect } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import debounce from 'lodash/debounce';
import { useScrollState } from '../use-scroll-state';
import type { RowDescriptor } from '../use-virtual';
import { RemeasureObserver } from './utils';

type UseRemeasureOnResizeParams = { rows: { forceRemeasure: RowDescriptor['forceRemeasure'] }[] };

const observerCount = new Map<HTMLElement, number>();
export const remeasureOperations: Set<() => void> = new Set();
export const flushRemeasure = debounce(() => {
	unstable_batchedUpdates(() => {
		remeasureOperations.forEach((remeasure) => remeasure());
	});
}, 200);
const resizeObserver = new RemeasureObserver(flushRemeasure);

export function useRemeasureOnResize({ rows }: UseRemeasureOnResizeParams) {
	const { scrollElement } = useScrollState();

	useEffect(() => {
		if (!scrollElement) {
			return undefined;
		}

		const operations = rows.map((row) => () => row.forceRemeasure());
		operations.forEach((operation) => {
			remeasureOperations.add(operation);
		});

		resizeObserver.observe(scrollElement);
		observerCount.set(scrollElement, (observerCount.get(scrollElement) ?? 0) + 1);

		return () => {
			operations.forEach((operation) => {
				remeasureOperations.delete(operation);
			});

			observerCount.set(scrollElement, (observerCount.get(scrollElement) ?? 0) - 1);
			const refCount = observerCount.get(scrollElement) ?? 0;
			if (refCount <= 0) {
				resizeObserver.unobserve(scrollElement);
				observerCount.delete(scrollElement);
			}
		};
	}, [rows, scrollElement]);
}
