import React, { Component, createRef, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import Popup from '@atlaskit/popup';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { gridSize, borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import {
	FULL,
	SIMPLE,
	BLUR,
	ENTER,
	COMPACT,
	TABLE_ROW,
	CREATE_BUTTON,
	MULTI_DIALOG_SINGLE_CREATE_BUTTON,
	MULTI_DIALOG_MULTI_CREATE_BUTTON,
} from '../../common/constants';
import {
	type CreatedBy,
	type CardAppearance,
	type ValidationError,
	type CreateMetadata,
	emptyValidationError,
	type Message as MessageType,
	type CardType,
} from '../../common/types';
import { CreateButton } from './create-button';
import InlineMessages from './inline-messages/main.tsx';
import messages from './inline-messages/messages';
import MultiCreateDialog from './multi-create-dialog/main.tsx';
import DefaultProjectSelect from './project-select';
import type { SelectRef as ProjectSelectRef } from './project-select/types.tsx';
import DefaultStatusSelect, { FIELD_KEY_STATUS_TRANSITIONS } from './status-select';
import type { SelectRef as StatusSelectRef } from './status-select/types.tsx';
import Summary, {
	splitSummary,
	validateSummary,
	hasValidationError,
	stripConsecutiveWhitespacesAndTrim,
} from './summary/main.tsx';
import DefaultTypeSelect, { type SelectRef as TypeSelectRef } from './type-select';
import type { Props } from './types';

export const getSelectedCardTypeId = (
	initialSelectedCardTypeId: number | string | null,
	cardTypes: CardType[],
) => {
	if (initialSelectedCardTypeId) {
		return initialSelectedCardTypeId;
	}
	const filteredCardTypes = cardTypes.filter(({ isDisabled }) => !isDisabled);
	return filteredCardTypes.length > 0 ? filteredCardTypes[0].id : null;
};

export const isClickOutsideElement = (target: EventTarget | null, ref: HTMLDivElement | null) =>
	target instanceof Node && ref ? !ref.contains(target) : true;

export const isClickOutsideSelect = (
	target: EventTarget | null,
	selectRef: TypeSelectRef | StatusSelectRef | ProjectSelectRef | null,
) =>
	target instanceof Node && selectRef?.node
		? !selectRef.node.menuRef?.contains(target) && !selectRef.node.targetRef?.contains(target)
		: true;

type State = {
	validationError: ValidationError;
	selectedCardTypeId: number | string | null;
	selectedTransitionId: number | null;
	selectedProjectId: number | null | undefined;
	// can remove sanitisedSummaries when cleaning up remove_unused_multi_create__f39014bf
	sanitisedSummaries: string[];
	sanitisedSummary: string;
};

// eslint-disable-next-line jira/react/no-class-components
class Form extends Component<Props, State> {
	static defaultProps = {
		autoFocus: true,
		saveOnBlur: false,
		numFilters: 0,
		messages: [],
		cardTypes: [],
		TypeSelect: DefaultTypeSelect,
		extraItems: undefined,
		shouldKeepFormOpenOnClickOutside: undefined,
		selectedCardTypeId: null,
		selectedFieldVaues: new Map(),
		appearance: FULL,
		placeholderText: '',
		initialSummary: '',
		onSubmit: noop,
		onCancel: noop,
		onBlur: noop,
		onChangeSummary: noop,
		onChangeCardType: noop,
		onChangeStatusTransition: noop,
		onChangeProject: noop,
		setFocusOnTrigger: noop,
		renderAdditionalInlineCreateFooter: undefined,
		shouldShowCreateButton: false,
	};

	state: State = {
		validationError: validateSummary(this.props.initialSummary, this.props.intl),
		selectedCardTypeId: getSelectedCardTypeId(this.props.selectedCardTypeId, this.props.cardTypes),
		selectedTransitionId:
			Number(this.props.selectedFieldValues?.get(FIELD_KEY_STATUS_TRANSITIONS)) || null,
		selectedProjectId: this.props.selectedProjectId,
		sanitisedSummaries: splitSummary(this.props.initialSummary),
		sanitisedSummary: stripConsecutiveWhitespacesAndTrim(this.props.initialSummary),
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static getDerivedStateFromProps(props: any, state: any) {
		if (ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1')) {
			// Checks if the selectedCardTypeId still exists in the cardTypes array and refresh the value if it isn't
			// This is used in Program board where the cardTypes are updated when the project selector value changes
			const selectedCardType = props.cardTypes.find(
				({ id, isDisabled }: CardType) => id === state.selectedCardTypeId && !isDisabled,
			);
			if (!selectedCardType) {
				return {
					...state,
					selectedCardTypeId: getSelectedCardTypeId(props.selectedCardTypeId, props.cardTypes),
				};
			}
		}
		return null;
	}

	componentDidMount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('mouseup', this.onMouseUp);
	}

	componentWillUnmount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('mouseup', this.onMouseUp);
		this.debouncedOnChangeSummary.flush();
	}

	onMouseUp = ({ target }: MouseEvent) => {
		if (this.props.shouldKeepFormOpenOnClickOutside) {
			return;
		}

		if (
			isClickOutsideElement(target, this.form.current) &&
			isClickOutsideElement(target, this.inlineMessagePopupRef.current) &&
			// Can remove multiCreatePopupRef when cleaning up remove_unused_multi_create__f39014bf
			isClickOutsideElement(target, this.multiCreatePopupRef.current) &&
			isClickOutsideSelect(target, this.typeSelect.current) &&
			isClickOutsideSelect(target, this.statusSelect.current) &&
			isClickOutsideSelect(target, this.projectSelect.current)
		) {
			this.onBlur();
		}
	};

	onSubmit =
		({ isConcat = false, createdBy = ENTER }: { isConcat?: boolean; createdBy?: CreatedBy } = {}) =>
		() => {
			const {
				sanitisedSummaries,
				sanitisedSummary,
				selectedCardTypeId,
				selectedTransitionId,
				selectedProjectId,
			} = this.state;

			if (selectedCardTypeId === null) return;
			this.debouncedOnChangeSummary.flush();
			this.submitCounter += 1;
			const metadata: CreateMetadata = {
				createdBy,
			};
			let summaryData;
			if (fg('remove_unused_multi_create__f39014bf')) {
				// summaryData is a string
				summaryData = sanitisedSummary;
				if (summaryData.length > 0) {
					const fields = new Map();
					fields.set(FIELD_KEY_STATUS_TRANSITIONS, selectedTransitionId);
					const summaryDataTransformed = {
						summary: summaryData,
						cardTypeId: selectedCardTypeId,
						fields,
						projectId: selectedProjectId === null ? undefined : selectedProjectId,
					};
					this.props.onSubmit([summaryDataTransformed], metadata);
				} else {
					this.props.onBlur('');
				}

				this.setState({
					validationError: emptyValidationError,
					sanitisedSummaries: [],
					sanitisedSummary: '',
				});
			} else {
				// summaryData is a string[]
				if (isConcat) {
					summaryData = [sanitisedSummaries.join(' ')];
				} else {
					summaryData = sanitisedSummaries;
				}

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const summaryDataTransformed = summaryData.reduce<Array<any>>((accumulator, summary) => {
					if (summary.trim().length > 0) {
						const fields = new Map();
						fields.set(FIELD_KEY_STATUS_TRANSITIONS, selectedTransitionId);
						accumulator.push({
							summary,
							cardTypeId: selectedCardTypeId,
							fields,
							projectId: selectedProjectId,
						});
					}
					return accumulator;
				}, []);

				if (summaryDataTransformed.length > 0) {
					this.props.onSubmit(summaryDataTransformed, metadata);
				} else {
					this.props.onBlur('');
				}

				this.setState({
					validationError: emptyValidationError,
					sanitisedSummaries: [],
					sanitisedSummary: '',
				});
			}
		};

	onCancel = () => {
		this.debouncedOnChangeSummary.flush();
		this.props.onCancel();
		this.setState({
			validationError: emptyValidationError,
			sanitisedSummaries: [],
			sanitisedSummary: '',
		});
	};

	onSelectCardType = (cardTypeId: number | string) => {
		this.props.onChangeCardType(cardTypeId);
		this.setState({
			selectedCardTypeId: cardTypeId,
		});
	};

	onSelectProject = (projectId: number) => {
		this.props.onChangeProject(projectId);
		this.setState({
			selectedProjectId: projectId,
		});
	};

	onSelectTransition = (transitionId: number) => {
		this.props.onChangeStatusTransition(transitionId);
		this.setState({
			selectedTransitionId: transitionId,
		});
	};

	onChangeSummary = ({
		summary,
		validationError,
	}: {
		summary: string;
		validationError: ValidationError;
	}) => {
		this.setState((prevState: State) => ({
			sanitisedSummaries: [summary.replace('\n', ' ')],
			sanitisedSummary: summary.replace('\n', ' '),
			validationError: { ...prevState.validationError, ...validationError },
		}));
		this.debouncedOnChangeSummary(summary);
	};

	onBlur = () => {
		if (this.props.saveOnBlur) {
			this.onSubmit({ isConcat: true, createdBy: BLUR })();
		} else if (fg('remove_unused_multi_create__f39014bf')) {
			this.props.onBlur(this.state.sanitisedSummary);
		} else {
			this.props.onBlur(this.state.sanitisedSummaries.join('\n'));
		}
	};

	submitCounter = 0;

	initialSummary: string = this.props.initialSummary;

	form = createRef<HTMLDivElement>();

	typeSelect = createRef<TypeSelectRef>();

	statusSelect = createRef<StatusSelectRef>();

	projectSelect = createRef<ProjectSelectRef>();

	inlineMessagePopupRef = createRef<HTMLDivElement>();

	multiCreatePopupRef = createRef<HTMLDivElement>();

	debouncedOnChangeSummary = debounce((summary: string) => {
		this.props.onChangeSummary(summary);
	}, 250);

	getSelectedIssueType = (): CardType | undefined => {
		const { selectedCardTypeId } = this.state;
		const { cardTypes } = this.props;

		return cardTypes.find((type) => type.id === selectedCardTypeId);
	};

	renderTypeDropdown = () => {
		const { cardTypes, extraItems, TypeSelect = DefaultTypeSelect } = this.props;

		const { selectedCardTypeId } = this.state;

		if (selectedCardTypeId === null) return null;

		return (
			<TypeSelect
				ref={this.typeSelect}
				cardTypes={cardTypes}
				selectedCardTypeId={selectedCardTypeId}
				onSelectCardType={this.onSelectCardType}
				renderAdditionalOptionContent={this.props.renderAdditionalTypeSelectOptionContent}
				shouldRenderToParent
				extraItems={extraItems}
			/>
		);
	};

	renderProjectDropdown = () => {
		if (!ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1')) {
			return null;
		}
		const { ProjectSelect = DefaultProjectSelect, projects } = this.props;
		const { selectedProjectId } = this.state;
		if ((projects?.length || 0) > 1) {
			return (
				<ProjectSelect
					ref={this.projectSelect}
					projects={projects || []}
					selectedProjectId={selectedProjectId}
					onSelectProject={this.onSelectProject}
				/>
			);
		}
		return null;
	};

	renderStatusDropdown = () => {
		const { StatusSelect = DefaultStatusSelect, statusTransitions } = this.props;
		const { selectedTransitionId } = this.state;

		if (!statusTransitions?.length) {
			return null;
		}

		return (
			<StatusSelect
				ref={this.statusSelect}
				statusTransitions={statusTransitions}
				selectedTransitionId={selectedTransitionId || statusTransitions[0].transitionId}
				onSelectTransition={this.onSelectTransition}
			/>
		);
	};

	renderSummary = () => (
		<SummaryContainer appearance={this.props.appearance}>
			<Summary
				initialSummary={this.initialSummary}
				appearance={this.props.appearance}
				submitCounter={this.submitCounter}
				placeholderText={this.props.placeholderText}
				autoFocus={this.props.autoFocus}
				shouldScrollIntoView={this.props.shouldScrollIntoView}
				onChangeSummary={this.onChangeSummary}
				onSubmit={this.onSubmit()}
				onCancel={this.onCancel}
				{...(fg('jsw_roadmaps_timeline-issue-creation-ally')
					? {
							selectedIssueType: this.getSelectedIssueType()?.name,
							setFocusOnTrigger: this.props.setFocusOnTrigger,
						}
					: {})}
			/>
		</SummaryContainer>
	);

	buildWarnings = (): MessageType[] => {
		const {
			intl: { formatMessage },
		} = this.props;
		const { sanitisedSummaries, sanitisedSummary, validationError } = this.state;

		if (!fg('remove_unused_multi_create__f39014bf')) {
			if (sanitisedSummaries.length === 1) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const validationErrors: string[] = Object.values(validationError).reduce<Array<any>>(
					(accumulator, errorMessage) => {
						if (errorMessage) {
							accumulator.push(String(errorMessage));
						}
						return accumulator;
					},
					[],
				);

				return validationErrors.map((error: string) => ({
					title: formatMessage(messages.validationErrorTitle, {
						validationErrorsNo: validationErrors.length,
					}),
					description: error,
				}));
			}
			return [];
		}
		if (sanitisedSummary.length > 0) {
			const validationErrors: string[] = Object.values(validationError).reduce<string[]>(
				(accumulator, errorMessage) => {
					if (errorMessage) {
						accumulator.push(String(errorMessage));
					}
					return accumulator;
				},
				[],
			);

			return validationErrors.map((error: string) => ({
				title: formatMessage(messages.validationErrorTitle, {
					validationErrorsNo: validationErrors.length,
				}),
				description: error,
			}));
		}
		return [];
	};

	renderInlineMessageInfo = () => {
		const {
			numFilters,
			messages: inlineMessages,
			intl: { formatMessage },
		} = this.props;

		const infos: MessageType[] = inlineMessages.length > 0 ? [...inlineMessages] : [];
		const warnings: MessageType[] = this.buildWarnings();

		if (numFilters) {
			infos.push({
				title: formatMessage(messages.appliedFiltersTitleNew),
				description: formatMessage(messages.appliedFiltersDescription),
			});
		}

		return (
			<InlineMessages infos={infos} warnings={warnings} innerRef={this.inlineMessagePopupRef} />
		);
	};

	renderCreateButton = () => {
		if (fg('remove_unused_multi_create__f39014bf')) {
			const isEmpty = this.state.sanitisedSummary.length === 0;

			return (
				this.props.shouldShowCreateButton && (
					<CreateButton
						onClick={this.onSubmit({
							isConcat: false,
							createdBy: CREATE_BUTTON,
						})}
						isEmpty={isEmpty}
						hasValidationError={hasValidationError(this.state.validationError)}
					/>
				)
			);
		}
		const shouldShowCreateButton =
			this.props.shouldShowCreateButton && this.state.sanitisedSummaries.length <= 1;

		const isEmpty = this.state.sanitisedSummaries[0]
			? this.state.sanitisedSummaries[0].length === 0
			: true;

		return (
			shouldShowCreateButton && (
				<CreateButton
					onClick={this.onSubmit({
						isConcat: false,
						createdBy: CREATE_BUTTON,
					})}
					isEmpty={isEmpty}
					hasValidationError={hasValidationError(this.state.validationError)}
				/>
			)
		);
	};

	renderFullViewContent = (renderAdditionalInlineCreateFooter: ReactNode | undefined) => (
		<FullContent isInvalid={hasValidationError(this.state.validationError)}>
			<Body appearance={this.props.appearance}>{this.renderSummary()}</Body>
			<FullFooter>
				<FooterSection>
					{this.renderTypeDropdown()}
					{this.renderProjectDropdown()}
					{this.renderStatusDropdown()}
				</FooterSection>
				<FooterSection>
					{this.renderInlineMessageInfo()} {this.renderCreateButton()}
				</FooterSection>
			</FullFooter>
			{renderAdditionalInlineCreateFooter}
		</FullContent>
	);

	renderCompactViewContent = () => {
		const { appearance } = this.props;
		return (
			<CompactContent
				isInvalid={hasValidationError(this.state.validationError)}
				appearance={appearance}
			>
				{appearance !== SIMPLE && (
					<Header>
						{this.renderTypeDropdown()}
						{this.renderProjectDropdown()}
					</Header>
				)}
				<Body appearance={appearance}>{this.renderSummary()}</Body>
				{appearance !== SIMPLE && (
					<CompactFooter>
						<FooterSection>{this.renderInlineMessageInfo()}</FooterSection>
					</CompactFooter>
				)}
			</CompactContent>
		);
	};

	render() {
		const { appearance, cardTypes, renderAdditionalInlineCreateFooter } = this.props;
		const { sanitisedSummaries, validationError } = this.state;
		const { selectedCardTypeId } = this.state;
		// There will only be one summary after cleaning up remove_unused_multi_create__f39014bf
		const summariesNo = sanitisedSummaries.length;
		const isSummaryMultiline = summariesNo > 1;
		const hasRequiredFields = cardTypes.find(
			({ id }) => id === selectedCardTypeId,
		)?.hasRequiredFields;

		return (
			<Container
				ref={this.form}
				appearance={appearance}
				data-testid="platform-inline-card-create.ui.form.container"
			>
				{fg('remove_unused_multi_create__f39014bf') ? (
					<StyledForm appearance={appearance}>
						{appearance === FULL
							? this.renderFullViewContent(renderAdditionalInlineCreateFooter)
							: this.renderCompactViewContent()}
					</StyledForm>
				) : (
					<Popup
						isOpen={isSummaryMultiline}
						placement="bottom-start"
						content={() => (
							<MultiCreateDialog
								innerRef={this.multiCreatePopupRef}
								summariesNo={summariesNo}
								validationError={validationError}
								onSubmitSingle={this.onSubmit({
									isConcat: true,
									createdBy: MULTI_DIALOG_SINGLE_CREATE_BUTTON,
								})}
								onSubmitMultiple={this.onSubmit({
									createdBy: MULTI_DIALOG_MULTI_CREATE_BUTTON,
								})}
								hasRequiredFields={hasRequiredFields}
								onClose={this.onBlur}
							/>
						)}
						trigger={(triggerProps) => (
							<StyledForm appearance={appearance} {...triggerProps}>
								{appearance === FULL
									? this.renderFullViewContent(renderAdditionalInlineCreateFooter)
									: this.renderCompactViewContent()}
							</StyledForm>
						)}
					/>
				)}
			</Container>
		);
	}
}

// AK icon is not vertically aligned AK-5620 so we have to hack the button
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterSection = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& button': {
		display: 'flex',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-child': {
		flexShrink: 0,
	},
	minWidth: 0,
});

export { Form };

export default injectIntl(Form);

/* Card layout */

/**
 * z-index needs to be removed for board because we want the textarea to be
 * beneath the swimlane and and sticky header while allowing the trigger button to stay
 * on top of them. Change it with caution.
 * @atlassian/jira-board-v2/src/app/global-style
 */
type CardAppearanceProps = { appearance: CardAppearance };
type CompactContentProps = { isInvalid?: boolean; appearance?: CardAppearance };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<CardAppearanceProps>(
	{
		position: 'relative',
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'column',
		backgroundColor: 'transparent',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	(props: CardAppearanceProps) => props.appearance !== FULL && 'z-index: 3',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	(props: CardAppearanceProps) => props.appearance === FULL && `margin: ${gridSize / 4}px 0`,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CompactContent = styled.div<CompactContentProps>(
	{
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: `${token('space.050', '4px')} ${token('space.100', '8px')}`,
		outline: 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		boxShadow: `inset 0 0 0 ${gridSize / 4}px ${(props: CompactContentProps) =>
			props.isInvalid
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.border.danger', colors.R400)
				: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.border.focused', colors.B200)}`,
		boxSizing: 'border-box',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	(props) => props.appearance === COMPACT && `padding-left: ${gridSize * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	(props) => props.appearance === COMPACT && `padding-left: ${gridSize * 1.5}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	(props) => props.appearance === TABLE_ROW && `padding-left: ${gridSize * 1.5}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	(props) => props.appearance !== COMPACT && `border-radius: ${borderRadius}px`,
	{
		width: '100%',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	(props) => (props.appearance === TABLE_ROW ? 'height: 100%;' : `height: ${gridSize * 5}px`),
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('elevation.surface', colors.N0),
		transition: 'box-shadow 200ms ease-in-out',
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FullContent = styled(CompactContent)({
	flexDirection: 'column',
	alignItems: 'start',
	height: 'auto',
	padding: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Header = styled.div({
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Body = styled.div<CardAppearanceProps>({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexGrow: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	width: (props) => (props.appearance === FULL ? '100%' : 'auto'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Footer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FullFooter = styled(Footer)({
	width: '100%',
	justifyContent: 'space-between',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3.5}px`,
	lineHeight: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CompactFooter = styled(Footer)({
	justifyContent: 'flex-end',
	marginLeft: token('space.100', '8px'),
});

/* Form */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledForm = styled.form<CardAppearanceProps>(
	{
		display: 'flex',
		alignItems: 'center',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	(props: CardAppearanceProps) => props.appearance === TABLE_ROW && `height: ${gridSize * 4}px`,
);

/* Text area */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummaryContainer = styled.div<CardAppearanceProps>(
	{
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'start',
		alignItems: 'center',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	(props) => props.appearance === FULL && `margin-bottom: ${gridSize}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		(props.appearance === COMPACT || props.appearance === TABLE_ROW) &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`margin-left: ${gridSize}px`,
);
