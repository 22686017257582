import {
	CARD_AUTO_SCROLL,
	CARD_AUTO_SCROLL_UNSCHEDULED_COLUMN_ICC,
} from '../../../../actions/card/card-auto-scroll';
import type { Action } from '../../../../types';
import type { AutoScrollState } from './types';

const initialAutoScrollState: AutoScrollState = {
	issueId: null,
	unscheduledColumnSwimlaneId: null,
	isImmediate: false,
};
const cardAutoScrollReducer = (state = initialAutoScrollState, action: Action): AutoScrollState => {
	if (action.type === CARD_AUTO_SCROLL) {
		return { ...state, issueId: action.payload.issueId };
	}

	if (action.type === CARD_AUTO_SCROLL_UNSCHEDULED_COLUMN_ICC) {
		return {
			...state,
			unscheduledColumnSwimlaneId: action.payload.swimlaneId,
			isImmediate: action.payload.isImmediate ?? false,
		};
	}

	return state;
};

export default cardAutoScrollReducer;
