import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tooltip: {
		id: 'software.main.header.sprint-controls.trigger-button.tooltip',
		defaultMessage: 'Sprint details',
		description:
			'tooltip shown on the sprint controls button. The button is just an icon, so the tooltip clarifies that the button will show sprint details.',
	},
});
