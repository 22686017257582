import React, { useCallback, useMemo, memo } from 'react';
import noop from 'lodash/noop';
import { useIntl } from '@atlassian/jira-intl';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';
import { type IssueStatusId, toIssueStatusId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Status, CheckboxSelectEntry } from '../../../common/types';
import ListFilter from '../../../common/ui/list';
import messages from './messages';

type Props = {
	statuses: Status[];
	selectedStatuses?: IssueStatusId[];
	onChange: (statuses: IssueStatusId[], attributes?: AnalyticsAttributes) => void;
	isNested?: boolean;
};

const StatusFilter = ({
	statuses = [],
	selectedStatuses = [],
	onChange = noop,
	isNested = false,
}: Props) => {
	const { formatMessage } = useIntl();
	const values = useMemo(
		(): CheckboxSelectEntry[] =>
			statuses.map(({ id, name }) => ({
				label: name,
				value: id,
			})),
		[statuses],
	);

	const onChangeValue = useCallback(
		(entries: CheckboxSelectEntry[]) => {
			const analyticAttributes = { isNested };
			onChange(
				entries.map(({ value }) => toIssueStatusId(value)),
				analyticAttributes,
			);
		},
		[onChange, isNested],
	);

	// early exit if we have only one option
	if (values.length <= 1) {
		return null;
	}

	return (
		<ListFilter
			label={formatMessage(messages.label)}
			values={values}
			selectedValues={selectedStatuses.map(String)}
			onChange={onChangeValue}
			isNested={isNested}
		/>
	);
};

const StatusFilterComponent = memo<Props>(StatusFilter);

StatusFilterComponent.displayName = 'StatusFilter';
// @ts-expect-error - TS2339 - Property 'whyDidYouRender' does not exist on type 'NamedExoticComponent<Props>'.
StatusFilterComponent.whyDidYouRender = true;

export default StatusFilterComponent;
