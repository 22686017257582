import React, { useCallback, useMemo } from 'react';
import { sub, startOfDay, formatISO } from 'date-fns';
import Button from '@atlaskit/button';
import { JiraSoftwareBoardAri } from '@atlassian/ari/jira-software/board';
import { JiraProjectAri } from '@atlassian/ari/jira/project';
import { useShowCreateRetroOrCrossSellModal } from '@atlassian/jira-confluence-sprint-retros/src/controllers/show-create-retro-or-cross-sell-modal/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
// eslint-disable-next-line jira/restricted/@atlassian/jira-platform-ari
import { createAri } from '@atlassian/jira-platform-ari';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { completeSprintModalEntryPoint } from '@atlassian/jira-software-complete-sprint-modal-relay/entrypoint';
import { ModalTriggerButton } from '@atlassian/jira-software-modal-dialog/src/ui/modal-trigger-button/index.tsx';
import { BOARD } from '@atlassian/jira-software-resource-invalidator/src/common/types.tsx';
import { triggerResourceCacheInvalidation } from '@atlassian/jira-software-resource-invalidator/src/controllers/resources-cache-invalidation-subject/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { Capability } from '../../../../common/capability';
import { completeSprintModalLoad } from '../../../../common/graphql/metrics';
import { useBacklogUrl } from '../../../../common/hooks/use-backlog-url';
import { DisabledButtonHelper } from '../../../../common/sprint-actions/disabled-button-helper';
import { useBoardSelector } from '../../../../state';
import {
	getIsCMPBoard,
	rapidViewIdSelector,
} from '../../../../state/selectors/software/software-selectors';
import {
	activeSprintsSelector,
	getSelectedSprintsKeys,
} from '../../../../state/selectors/sprint/sprint-selectors';
import { workIssuesSelector } from '../../../../state/selectors/work/work-selectors';
import { useCapability } from '../../../../state/state-hooks/capabilities';
import messages from './messages';

const A11Y_HELPER_TEXT_ID = 'software-board.header.complete-sprint-button.a11y-helper-text';

type Props = {
	isDisabled?: boolean;
};

const CompleteSprintButton = ({ isDisabled }: Props) => {
	const activeSprints = useBoardSelector(activeSprintsSelector);
	// FIXME next dev actively working in this area
	// not urgent bc (null <= 0) => true so no crash
	// @ts-expect-error TS2531: Object is possibly 'null'.
	const appearance = activeSprints && activeSprints[0]?.daysRemaining <= 0 ? 'primary' : 'default';
	const boardId = useBoardSelector(rapidViewIdSelector);
	const selectedSprintIds = useBoardSelector(getSelectedSprintsKeys);
	const sourceIssueNo = useBoardSelector(workIssuesSelector).length;
	const isCMPBoard = useBoardSelector(getIsCMPBoard);
	const isTabNavigation = useCapability(Capability.IS_TAB_NAVIGATION);

	const cloudId = useCloudId();
	const { data: project, loading } = useProjectContext();
	const jiraProjectAri = project
		? JiraProjectAri.create({
				siteId: cloudId,
				projectId: String(project.projectId),
			}).toString()
		: '';

	const { formatMessage } = useIntl();
	const { push } = useRouterActions();

	const boardIdAri = JiraSoftwareBoardAri.create({
		siteId: cloudId,
		boardId: String(boardId),
	}).toString();

	const showRetrosModal = useShowCreateRetroOrCrossSellModal();

	const backlogUrl = useBacklogUrl(['sprintCompleted']) || '';

	const onCompleted = useCallback(() => {
		push(backlogUrl);

		// Set the flag to load the retros modal when we get to the backlog if the checkbox was ticked
		showRetrosModal();

		// Since the sprint modals don't go through redux we need to fire their invalidations manually
		triggerResourceCacheInvalidation(BOARD, 'COMPLETE_SPRINT_MODAL_COMPLETE');
	}, [backlogUrl, push, showRetrosModal]);

	const entryPointParams = useMemo(
		() => ({
			jiraProjectAri,
			// Date of 12 weeks ago as we want to fetch deployments date updated at most 12 weeks ago for deployments report nudge on sprint completion
			updatedFromDeploymentsDate: formatISO(startOfDay(sub(Date.now(), { weeks: 12 }))),
			boardId: boardIdAri,
			activeSprintIds:
				activeSprints?.map((sprint) =>
					createAri({
						cloudId,
						resourceId: String(sprint.id),
						resourceOwner: 'jira-software',
						resourceType: 'sprint',
					}),
				) ?? [],
			isCompanyManaged: isCMPBoard,
		}),
		[activeSprints, boardIdAri, cloudId, isCMPBoard, jiraProjectAri],
	);

	const { entryPointActions, entryPointReferenceSubject, stopMetric } = useEntryPoint(
		completeSprintModalEntryPoint,
		entryPointParams,
		completeSprintModalLoad,
	);

	const triggerRef = useEntryPointButtonTrigger(entryPointActions);

	let sprintId = selectedSprintIds?.[0];

	if (!sprintId && activeSprints) {
		sprintId = activeSprints?.[0]?.id.toString();
	}

	const props = useMemo(
		() => ({
			analyticsSource: 'simple-software-board',
			boardId: boardIdAri,
			onCompleted,
			sourceIssueNo,
			sprintId,
			selectedSprintIds,
			onReady: stopMetric,
			isCompanyManaged: isCMPBoard,
		}),
		[boardIdAri, onCompleted, sourceIssueNo, sprintId, selectedSprintIds, stopMetric, isCMPBoard],
	);
	return (
		<>
			{isTabNavigation ? (
				<>
					{fg('jsw-jet-yamato-fe-permissions-seq-sprint-actions') ? (
						<DisabledButtonHelper
							isDisabled={isDisabled}
							describedById={A11Y_HELPER_TEXT_ID}
							helpText={formatMessage(messages.completeSprintButtonDisabledInfoTextNonFinal)}
						>
							<ModalTriggerButton
								testId="software-board.header.sprint-goal-panel.complete-sprint-button.complete-sprint-button"
								appearance={appearance}
								isDisabled={isDisabled}
								aria-describedby={A11Y_HELPER_TEXT_ID}
								ref={loading ? undefined : triggerRef}
							>
								{formatMessage(messages.completeSprintButton)}
							</ModalTriggerButton>
						</DisabledButtonHelper>
					) : (
						<ModalTriggerButton
							testId="software-board.header.sprint-goal-panel.complete-sprint-button.complete-sprint-button"
							appearance={appearance}
							isDisabled={isDisabled}
							ref={loading ? undefined : triggerRef}
						>
							{formatMessage(messages.completeSprintButton)}
						</ModalTriggerButton>
					)}
				</>
			) : (
				<>
					{fg('jsw-jet-yamato-fe-permissions-seq-sprint-actions') ? (
						<DisabledButtonHelper
							isDisabled={isDisabled}
							describedById={A11Y_HELPER_TEXT_ID}
							helpText={formatMessage(messages.completeSprintButtonDisabledInfoTextNonFinal)}
						>
							<Button
								testId="software-board.header.sprint-goal-panel.complete-sprint-button.complete-sprint-button"
								appearance={appearance}
								isDisabled={isDisabled}
								aria-describedby={A11Y_HELPER_TEXT_ID}
								ref={loading ? undefined : triggerRef}
							>
								{formatMessage(messages.completeSprintButton)}
							</Button>
						</DisabledButtonHelper>
					) : (
						<Button
							testId="software-board.header.sprint-goal-panel.complete-sprint-button.complete-sprint-button"
							appearance={appearance}
							isDisabled={isDisabled}
							ref={loading ? undefined : triggerRef}
						>
							{formatMessage(messages.completeSprintButton)}
						</Button>
					)}
				</>
			)}

			<ModalEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="complete-sprint-modal"
				teamName="a4t-pandora"
				packageName="jiraSoftwareCompleteSprintModalRelay"
				entryPointActions={entryPointActions}
				runtimeProps={props}
			/>
		</>
	);
};

export default CompleteSprintButton;
