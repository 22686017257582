import React from 'react';
import { usePreloadedQuery, graphql, type PreloadedQuery } from 'react-relay';
import type { OperationType } from 'relay-runtime';
import type { viewBoardCustomFiltersQuery } from '@atlassian/jira-relay/src/__generated__/viewBoardCustomFiltersQuery.graphql';
import CustomFilterRelay from '@atlassian/jira-software-filters/src/ui/custom-filter/index.tsx';
import type { CustomFilterProps as CustomFilterRelayProps } from '@atlassian/jira-software-filters/src/ui/stateless/types';

type BoardCustomFilterRelayProps = Flow.Diff<
	CustomFilterRelayProps,
	{
		customFiltersQueryRef?: PreloadedQuery<OperationType>;
	}
> & {
	customFiltersQueryRef: PreloadedQuery<viewBoardCustomFiltersQuery>;
};

export const BoardCustomFilterRelay = ({
	customFiltersQueryRef,
	...props
}: BoardCustomFilterRelayProps) => {
	const customFiltersFragmentRef = usePreloadedQuery(
		graphql`
			query viewBoardCustomFiltersQuery($boardId: ID!) @preloadable {
				# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
				...customFilter_softwareFilters_CustomFilterRelay @arguments(boardId: $boardId)
			}
		`,
		customFiltersQueryRef,
	);

	return <CustomFilterRelay customFiltersFragmentRef={customFiltersFragmentRef} {...props} />;
};
