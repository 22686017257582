import { createFilter } from '@atlaskit/select';
import {
	FILTER_MENU_MIN_WIDTH,
	FILTER_MENU_MAX_WIDTH,
	FILTER_SEARCH_THRESHOLD,
	FILTER_MENU_MIN_WIDTH_ON_NESTED,
} from './constants';
import type { ListFilterProps, GroupOption, Option } from './types';

const isValueGroupOption = (value: GroupOption | Option): value is GroupOption =>
	'options' in value;

const containsOnlyOption = (values: (GroupOption | Option)[]): values is Option[] =>
	values.every((value) => !isValueGroupOption(value));

/* For the options to have a title, they must be part of a group. If future consumers
 * want to have arbitrary groups, this component will require broader API changes.
 *
 * (!) NOTE: Options must maintain reference equality when the selected options change, otherwise
 * react-select exhibits unexpected focus behaviour. Be careful with any changes to memoization here.
 */
export const getOptionsFromProps = ({
	values,
	menuGroupTitle = undefined,
}: {
	values: ListFilterProps['values'];
	menuGroupTitle?: ListFilterProps['menuGroupTitle'];
}) => {
	// menuGroupTitle should be deprecated
	if (menuGroupTitle && containsOnlyOption(values))
		return [{ label: menuGroupTitle, options: values }];

	return values;
};

/* We only receive the selected ids,
 * so we need to transform them into options entities.
 */
export const getSelectedOptionsFromProps = ({
	values,
	selectedValueIds,
}: {
	values: ListFilterProps['values'];
	selectedValueIds: ListFilterProps['selectedValues'];
}) => {
	const selectedIdLookup = selectedValueIds.reduce((acc: Set<string>, cur: string) => {
		acc.add(cur);
		return acc;
	}, new Set());

	const valuesToLook = values.flatMap((value) =>
		isValueGroupOption(value) ? value.options : [value],
	);

	const selectedOptions = valuesToLook.filter(({ value }) => selectedIdLookup.has(value));

	return { selectedIdLookup, selectedOptions };
};

/* Filters can used with async or sync searching, and we need different behaviours for each.
 * The main limitation is that search only displays when the number of options exceeds the provided threshold.
 */
export const getSearchableProps = ({
	optionsCount,
	isAsyncSearch,
	defaultSearchThreshold = FILTER_SEARCH_THRESHOLD,
	isNested,
}: {
	optionsCount: number;
	isAsyncSearch: boolean;
	defaultSearchThreshold?: number;
	isNested?: boolean;
}) => {
	// Search input should always be visible for async selects
	const searchThreshold = isAsyncSearch ? -1 : defaultSearchThreshold;
	const isSearchable = optionsCount > searchThreshold;
	// Due to renderToParent the grid size will change so, incresing the min width for low resolutions
	const filterMenuMinWidth = isNested ? FILTER_MENU_MIN_WIDTH_ON_NESTED : FILTER_MENU_MIN_WIDTH;
	const maxMenuWidth = FILTER_MENU_MAX_WIDTH;
	// Set the min and max width to be the same so updated search results don't cause the menu to resize
	const minMenuWidth = isSearchable ? maxMenuWidth : filterMenuMinWidth;

	// Don't filter anything on the frontend for async selects
	const filter = createFilter({ stringify: (option) => option.label });
	const filterOption = isAsyncSearch ? undefined : filter;

	return { minMenuWidth, maxMenuWidth, searchThreshold, filterOption };
};
