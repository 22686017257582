import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	completeSprintButton: {
		id: 'software.main.header.sprint-goal-panel.complete-sprint-button.complete-sprint-button',
		defaultMessage: 'Complete sprint',
		description:
			'Label for the button that ends or closes off the sprint. Shown in the board header, the button is grey most of the time, but turns blue on the last day of the sprint.',
	},
	completeSprintButtonDisabledInfoTextNonFinal: {
		id: 'software.main.header.complete-sprint-button.complete-sprint-button-disabled-info-text-non-final',
		defaultMessage: 'Request permissions from board admin',
		description:
			'Text to display when the complete sprint button is disabled due to lack of permissions',
	},
});
