import flow from 'lodash/flow';
import memoize from 'lodash/memoize';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux';
import { CAN_EDIT_BOARD } from '../../../../../../model/permission/permission-types';
import { getPermissionsSelector } from '../../../../../../state/selectors/board/board-permissions-selectors';
import { getColumnIndex } from '../../../../../../state/selectors/column/column-selectors';
import { getDoneIssuesInColumn } from '../../../../../../state/selectors/issue/issue-selectors';
import { getIsCMPBoard } from '../../../../../../state/selectors/software/software-selectors';
import { isSprintsEnabled } from '../../../../../../state/selectors/sprint/sprint-selectors';
import {
	isAnyColumnUpdating,
	isInlineColumnEditEnabled,
} from '../../../../../../state/selectors/work/work-selectors';
import type { State } from '../../../../../../state/types';
import Menu from './view';

const getExternalValues = memoize((columnIndex) => ({
	columnIndex: columnIndex + 1,
}));

type OwnProps = {
	columnId: number;
	intl: IntlShape;
};

type StateProps = {
	isDisabled: boolean;
	externalId: string;
	externalValues: unknown;
};

export const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const columnIndex = getColumnIndex(state, ownProps.columnId);

	const hasDoneIssues = getDoneIssuesInColumn(state)(ownProps.columnId).length > 0;
	const isClearDoneIssueVisible =
		!isSprintsEnabled(state) && hasDoneIssues && !getIsCMPBoard(state);
	const anyColumnUpdating = isAnyColumnUpdating(state);

	const isVisible =
		getPermissionsSelector(state)[CAN_EDIT_BOARD] &&
		(isInlineColumnEditEnabled(state) || isClearDoneIssueVisible) &&
		!anyColumnUpdating;

	return {
		externalId: 'platform.board.column.meatball',
		externalValues: getExternalValues(columnIndex),
		isDisabled: !isVisible,
	};
};

const connector = connect(mapStateToProps, {});

const ConnectedMenu = flow(
	AnalyticsEventToProps('dropdownMenuItem', {
		onOpenChange: 'opened',
	}),
	connector,
)(Menu);

const MenuWithIntl = injectIntl(ConnectedMenu);

export default MenuWithIntl;
