import {
	BACKLOG,
	BOARD,
	UNKNOWN_LOCATION,
	CLASSIC_BACKLOG_ROUTE_NAME,
	CLASSIC_BOARD_ROUTE_NAME,
	NEXT_GEN_BACKLOG_ROUTE_NAME,
	NEXT_GEN_BOARD_ROUTE_NAME,
} from './constants';
import type { LOCATION } from './types';

const locations = {
	[NEXT_GEN_BACKLOG_ROUTE_NAME]: BACKLOG,
	[CLASSIC_BACKLOG_ROUTE_NAME]: BACKLOG,
	[NEXT_GEN_BOARD_ROUTE_NAME]: BOARD,
	[CLASSIC_BOARD_ROUTE_NAME]: BOARD,
} as const;

export const getBoardOrBacklogFromLocation = (userLocation: string): LOCATION =>
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly "software:software-backlog": "backlog"; readonly "classic-software:rapidboard-backlog": "backlog"; readonly "software:software-boards": "board"; readonly "classic-software:rapidboard-board": "board"; }'.
	locations[userLocation] || UNKNOWN_LOCATION;
