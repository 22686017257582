import React from 'react';
import { InsightsStoreContainer } from '../controllers/sweet-state';
import type { State } from '../controllers/sweet-state/types';
import { useInsightsContainer } from '../controllers/use-insights-container';
import type { ExtendedActions } from '../controllers/use-insights-container/types';
import type { InsightsContainerProps } from './types';

const buildScopeName = (scope: string, packageName: string): string => `${scope}-${packageName}`;

type PropsWithChildrenFunction<TState extends State> = {
	children: (state: TState, actions: ExtendedActions<TState>) => JSX.Element;
};

const ChildrenWrapperWithHook = <TState extends State>({
	children,
}: PropsWithChildrenFunction<TState>) => {
	const [state, actions] = useInsightsContainer<TState>();
	return children(state, actions);
};

export const InsightsContainer = <TState extends State>({
	scope,
	packageName,
	children,
}: InsightsContainerProps<TState>) => {
	const insightsStoreContainerScope = buildScopeName(scope, packageName);

	if (typeof children === 'function') {
		return (
			<InsightsStoreContainer scope={insightsStoreContainerScope}>
				<ChildrenWrapperWithHook<TState>>{children}</ChildrenWrapperWithHook>
			</InsightsStoreContainer>
		);
	}

	return (
		<InsightsStoreContainer scope={insightsStoreContainerScope}>{children}</InsightsStoreContainer>
	);
};
