import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError, { type ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { AccessLevel } from '@atlassian/jira-shared-types/src/project.tsx';
import type { ProjectAccessLevelResponse } from './types';

export const url = (baseUrl: string, projectId: number) =>
	`${baseUrl}/rest/internal/simplified/1.0/accesslevel/project/${projectId}`;

const transform = (data: ProjectAccessLevelResponse): AccessLevel => data.value;

export const getProjectAccessLevel = (
	baseUrl: string,
	projectId: number,
): Promise<AccessLevel | null> => {
	const request = () => performGetRequest(url(baseUrl, projectId));
	return request()
		.then((data: ProjectAccessLevelResponse) => transform(data))
		.catch((error: FetchError | ValidationError) => {
			const attributes =
				error instanceof FetchError
					? {
							statusCode: error.statusCode,
							traceId: error.traceId,
						}
					: { errorMessage: error.message };
			fireErrorAnalytics({
				meta: {
					id: 'fetchProjectAccessLevel',
					packageName: 'jiraFilters',
					teamName: 'a4t-tanuki',
				},
				error,
				attributes,
				sendToPrivacyUnsafeSplunk: true,
			});
			return Promise.resolve(null);
		});
};
