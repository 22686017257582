import React, { useCallback } from 'react';
import Button from '@atlaskit/button/new';
import { useIntl } from '@atlassian/jira-intl';
import { useIsFullscreen } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/fullscreen/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { fireUIAnalytics, type AnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { View } from '@atlassian/jira-software-view-settings/src/common/types/constant.tsx';
import { useViewSettingsActions } from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import type { StandupActions } from '../../../common/types';
import messages from './messages';

type EndStandupButtonProps = StandupActions & { boardId: number };

export const EndStandupButton = ({ setIsActiveStandup, boardId }: EndStandupButtonProps) => {
	const { formatMessage } = useIntl();
	const { setIsFullscreen: setIsFullscreenNav3 } = useViewSettingsActions();
	const [, { setIsFullscreen }] = useIsFullscreen();

	const exitFullscreen = useCallback(() => {
		getWillShowNav4()
			? setIsFullscreen(false)
			: setIsFullscreenNav3({ boardId, isFullscreen: false, view: View.BOARD });
	}, [boardId, setIsFullscreen, setIsFullscreenNav3]);

	const handleExitStandup = (analyticsEvent: AnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'exitStandup');
		exitFullscreen();
		setIsActiveStandup(false);
	};

	return (
		<Button onClick={(__, analyticsEvent) => handleExitStandup(analyticsEvent)}>
			{formatMessage(messages.endStandupButton)}
		</Button>
	);
};
