import type { Action } from '../../../actions';
import { WORK_DATA_SET } from '../../../actions/work';
import type { CustomFiltersState } from './types';

const initialState: CustomFiltersState = { filters: [] };

/**
 * Redux reducer for CMP custom filters only, these are read from the main data
 * request.
 */
export function customFiltersReducer(
	state: CustomFiltersState = initialState,
	action: Action,
): CustomFiltersState {
	if (action.type === WORK_DATA_SET && action.payload.customFilters) {
		return { filters: action.payload.customFilters };
	}

	return state;
}
