import {
	HIDE_UPDATE_ISSUE_PARENT_MODAL,
	SHOW_UPDATE_ISSUE_PARENT_MODAL,
} from '../../../actions/issue/update-issue-parent-modal';
import type { Action } from '../../../types';
import type { UpdateIssueParentModalState } from './types';

export const updateIssueParentModalReducer = (
	state: UpdateIssueParentModalState = { isOpen: false },
	action: Action,
): UpdateIssueParentModalState => {
	if (action.type === SHOW_UPDATE_ISSUE_PARENT_MODAL) {
		const { issueParentId, transitions } = action.payload;
		return { isOpen: true, issueParentId, transitions };
	}

	if (action.type === HIDE_UPDATE_ISSUE_PARENT_MODAL) {
		return { isOpen: false };
	}

	return state;
};
