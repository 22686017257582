import { DRAG_END } from '../../../actions/board/drag';
import {
	CARD_GET_ISSUE_TRANSITIONS,
	CARD_GET_ISSUE_TRANSITIONS_FAILURE,
	CARD_SET_ISSUE_TRANSITIONS,
} from '../../../actions/card';
import type { Action } from '../../../types';
import type { IssueTransitionsState } from './types';

const emptyState: IssueTransitionsState = {};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: IssueTransitionsState = emptyState,
	action: Action,
): IssueTransitionsState => {
	switch (action.type) {
		case CARD_GET_ISSUE_TRANSITIONS: {
			return {
				[String(action.payload.issueId)]: {
					status: 'loading',
				},
			};
		}

		case CARD_SET_ISSUE_TRANSITIONS:
			return {
				[String(action.payload.issueId)]: {
					status: 'success',
					data: action.payload.columnTransitions,
				},
			};

		case CARD_GET_ISSUE_TRANSITIONS_FAILURE:
			return {
				[String(action.payload.issueId)]: {
					status: 'failure',
				},
			};

		case DRAG_END:
			return emptyState;

		default:
			return state;
	}
};
