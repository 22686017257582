import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	issueCount: {
		id: 'platform-board-kit.swimlane.issue-count',

		defaultMessage: `{count, plural,
            =0 {no issues}
            one {# issue}
            other {# issues}
        }`,

		description: '',
	},
	subtaskCount: {
		id: 'platform-board-kit.swimlane.subtask-count',

		defaultMessage: `{count, plural,
            =0 {no subtasks}
            one {# subtask}
            other {# subtasks}
        }`,

		description: '',
	},
	statusLozenge: {
		id: 'platform-board-kit.swimlane.status-lozenge',
		defaultMessage: 'status',
		description: 'Status',
	},
	flagged: {
		id: 'platform-board-kit.swimlane.flagged',
		defaultMessage: 'Flagged',
		description: 'Flagged',
	},
});

export default messages;
