import type { IssueId } from '@atlassian/jira-software-board-common';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { HEADER_BULK_INDICATOR_MENU_ID } from '../../constants';
import { createSeparator } from '../separator';

export type Props = { selectedCards: IssueId[] };

export const useHeader = ({ selectedCards }: Props): MenuNode[] => {
	const separator = createSeparator(HEADER_BULK_INDICATOR_MENU_ID);

	return selectedCards?.length > 1
		? [
				{
					id: HEADER_BULK_INDICATOR_MENU_ID,
					type: MenuNodeType.BulkIndicator,
					selectedCount: selectedCards.length,
				},
				separator,
			]
		: [];
};
