import { createSelector } from 'reselect';
import { SWIMLANE_BY_SUBTASK } from '../../../model/swimlane/swimlane-modes';
import { getIsCMPBoard, getUi } from '../software/software-selectors';
import { getSwimlaneMode } from '../swimlane/swimlane-mode-selectors';
import { getFirstColumnIssueIds } from './backlog-issue-move-selectors';
import { isBacklogEnabled } from './board-selectors';

export const isOpenSelector = createSelector([getUi], (ui) =>
	Boolean(ui.backlogEnableDialog?.isOpen),
);

export const isDialogVisibleSelector = createSelector(
	[isOpenSelector, isBacklogEnabled, getFirstColumnIssueIds, getSwimlaneMode, getIsCMPBoard],
	(isOpen, backlogEnabled, firstColumnIssueIds, swimlaneMode, isCMPBoard) => {
		if (isCMPBoard) {
			return false;
		}
		if (swimlaneMode === SWIMLANE_BY_SUBTASK.id) {
			return false;
		}
		const firstColumnMoreThen19Issues = firstColumnIssueIds.length > 19;
		return isOpen && !backlogEnabled && firstColumnMoreThen19Issues;
	},
);
