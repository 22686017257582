import { useEffect } from 'react';
import {
	type MigrationStatus,
	Status,
} from '@atlassian/jira-project-create-importer-common/src/common/types/index.tsx';
import { useExperienceTracker } from '@atlassian/jira-project-create-importer-common/src/services/use-experience-tracker/index.tsx';
import type { MigrationId } from '@atlassian/jira-project-create-importer-common/src/services/use-migration-id-manager/types';
import { usePollMigrationStatus } from '@atlassian/jira-project-create-importer-common/src/services/use-poll-migration-status/index.tsx';
import { APP_IDENTIFIER, BASE_POLLING_INTERVAL_MS } from '../../constants';
import type { EXPERIENCES } from '../../constants/metrics';

export const useTrackMigrationStatus = (
	experience: EXPERIENCES,
	migrationId: MigrationId,
	onSuccess?: (status: MigrationStatus) => void,
	onFail?: (status: MigrationStatus) => void,
) => {
	const { migrationStatus } = usePollMigrationStatus(migrationId, BASE_POLLING_INTERVAL_MS);
	const [onExperienceStart, onExperienceSuccess, onExperienceFail] =
		useExperienceTracker(experience);

	useEffect(() => {
		onExperienceStart();
	}, [onExperienceStart]);

	useEffect(() => {
		if (migrationStatus.overallStatus === Status.SUCCESS) {
			onExperienceSuccess();
			onSuccess && onSuccess(migrationStatus);
			return;
		}

		if (migrationStatus.overallStatus === Status.FAILED) {
			onExperienceFail(
				APP_IDENTIFIER,
				{
					name: 'Import Failed',
					message: 'Issue Import Failed',
				},
				{ migrationId },
			);
			onFail && onFail(migrationStatus);
		}
	}, [migrationId, migrationStatus, onExperienceFail, onExperienceSuccess, onFail, onSuccess]);

	return { migrationStatus };
};
