import { INSIGHTS_PACKAGE } from './package';

/**
 * Analytics and metrics keys
 *
 * Use these to keep a consistent namespace for analytics and
 * metrics within the insights app.
 */
// This is only so it is still backwards compatible. We will remove this in a separate PR.
export const JIRA_INSIGHTS = INSIGHTS_PACKAGE;
export const INSIGHTS_BEACON = `${JIRA_INSIGHTS}.beacon`;
export const INSIGHTS_PANEL = `${JIRA_INSIGHTS}.panel`;
export const INSIGHTS_PANEL_HELP = `${INSIGHTS_PANEL}.help`;
export const INSIGHTS_LOCATION = `${JIRA_INSIGHTS}.location`;
/**
 * Analytics attributes
 *
 * Use these to keep consistent attributes for analytics we fire.
 */
export const EXPERIENCE_TIMEOUT_MILLIS = 60000;
/**
 * Analytics actions
 *
 * To be used as an action string composed of `actionSubject` and `action`,
 * separated by a space. The analytics library expects this format.
 *
 * e.g. `jira.insights.panel.button clicked`
 * e.g. `jira.insights.beacon.sprint-commitment dataFetch`
 */

// Data fetch
export const DATA_FETCH_ACTION = 'dataFetch';

export const CLIENT_ERROR = 'clientError';

// Render action
export const RENDER_ACTION = 'render';

/**
 * Analytics attributes
 *
 * To be used as the last argument when firing analytics events.
 * Use one of the following depending on which type of analytic
 * event is being emitted. When adding a new type, it must be
 * inexact (...) to match types with the analytics library.
 *
 * e.g. `type NewAttributeType = { a: string, b: number, ... }`
 */

// Data fetch
export type DataFetchAttributes = {
	successful: boolean;
	statusCodeGroup?: string;
};
export const dataFetchSuccess: DataFetchAttributes = { successful: true };
export const dataFetchFail: DataFetchAttributes = { successful: false };

export const INSIGHTS_PANEL_EXPERIENCE = 'panel';
export const ERROR_BOUNDARY_HIT = 'error-boundary-hit';

export const boardType = {
	SCRUM: 'scrum',
	KANBAN: 'kanban',
} as const;

export const BOARD_KANBAN_PANEL_EXPERIENCE =
	`${JIRA_INSIGHTS}.board.${boardType.KANBAN}-panel` as const;

export const INSIGHTS_BUTTON = 'insights-show-insights-button.ui.insights-button';
export const INSIGHTS_BUTTON_LG = 'insights-show-insights-button.ui.insights-button-large';
export const INSIGHTS_BUTTON_SM = 'insights-show-insights-button.ui.insights-button-small';
export const INSIGHTS_BUTTON_RESPONSIVE =
	'insights-show-insights-button.ui.insights-button-responsive';
