import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../actions/work';
import type { Action } from '../../../types';
import type { IncrementConfigState } from './types';

export const incrementConfigReducer = (
	state: IncrementConfigState = null,
	action: Action,
): IncrementConfigState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		return action.payload.incrementConfig || null;
	}

	return state;
};
