export const LINKED_ISSUE_CARD_CONTENT_TESTID =
	'@atlassian/jira-issue-links-details.ui.linked-issue-card-content.linked-issue-card-content';
export const EXCEED_MAX_SIZE_LINK_TESTID =
	'@atlassian/jira-issue-links-details.ui.linked-issue-card-group.exceed-max-size-button';
export const RIGHT_FOOTER_BUTTON_TESTID =
	'@atlassian/jira-issue-links-details.ui.linked-issue-card-group.right-footer-button';
export const LEFT_FOOTER_BUTTON_TESTID =
	'@atlassian/jira-issue-links-details.ui.linked-issue-card-group.left-footer-button';
export const UNLINK_ISSUE_BUTTON_TESTID =
	'@atlassian/jira-issue-links-details.ui.linked-issue-card-content.unlink-button';

export const ISSUE_LINK_TESTID =
	'@atlassian/jira-issue-links-details.ui.linked-issue-card-content.issue-link';

export const LOADING_SPINNER_TESTID =
	'@atlassian/jira-issue-links-details.ui.linked-issue-card-group.spinner';

export const ISSUE_DUE_DATE_LOZENGE_TESTID =
	'@atlassian/jira-issue-links-details.ui.linked-issue-card-content.issue-due-date-lozenge';

export const ISSUE_STATUS_LOZENGE_TESTID =
	'@atlassian/jira-issue-links-details.ui.linked-issue-card-content.issue-status-lozenge';
