import React, { useCallback } from 'react';
import type { PreloadedQuery } from 'react-relay';
import type { OperationType } from 'relay-runtime';
import CustomFilter, {
	type CustomFilterProps,
} from '@atlassian/jira-filters/src/ui/filters/custom/index.tsx';
import type { viewBoardCustomFiltersQuery } from '@atlassian/jira-relay/src/__generated__/viewBoardCustomFiltersQuery.graphql';
import type {
	CustomFilterProps as CustomFilterRelayProps,
	APIProps as Props,
} from '@atlassian/jira-software-filters/src/ui/stateless/types';
import { View } from '@atlassian/jira-software-view-settings/src/common/types/constant.tsx';
import { ViewSetting } from '@atlassian/jira-software-view-settings/src/common/types/settings.tsx';
import {
	useShowQuickFilters,
	useViewSettingsActions,
} from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import { useBoardSelector } from '../../../../../state';
import { rapidViewIdSelector } from '../../../../../state/selectors/software/software-selectors';
import { getCustomFilters } from '../../../../../state/selectors/work/work-selectors';
import { useIsCMPBoard } from '../../../../../state/state-hooks/capabilities';
import { BoardCustomFilterRelay } from './view';

export type FilterBarWrapperProps = Props & {
	customFiltersQueryRef: PreloadedQuery<viewBoardCustomFiltersQuery> | null | undefined;
};

type BoardCustomFilterReduxProps = {
	onChange?: CustomFilterProps['onChange'];
	selectedFilters?: CustomFilterProps['selectedFilters'];
	showLink?: CustomFilterProps['showLink'];
	manageUrl?: CustomFilterProps['manageUrl'];
	isNested?: CustomFilterProps['isNested'];
	isCompanyManaged?: CustomFilterProps['isCompanyManaged'];
};

const BoardCustomFilterRedux = (props: BoardCustomFilterReduxProps) => {
	const boardId = useBoardSelector(rapidViewIdSelector);
	const filters = useBoardSelector(getCustomFilters);
	const isFilterBarVisible = useShowQuickFilters();
	const { toggleViewSetting } = useViewSettingsActions();

	const onToggleIsFilterBarVisible = useCallback(() => {
		if (boardId) {
			toggleViewSetting({
				boardId: Number(boardId),
				view: View.BOARD,
				setting: ViewSetting.QUICK_FILTERS,
			});
		}
	}, [boardId, toggleViewSetting]);

	return (
		<CustomFilter
			{...props}
			filters={filters}
			isPanelToggleChecked={isFilterBarVisible}
			onTogglePanel={onToggleIsFilterBarVisible}
			showPanelToggle={props.isCompanyManaged}
		/>
	);
};

type BoardCustomFilterProps = Flow.Diff<
	CustomFilterRelayProps,
	{
		customFiltersQueryRef?: PreloadedQuery<OperationType>;
	}
> & {
	customFiltersQueryRef: PreloadedQuery<viewBoardCustomFiltersQuery>;
};

export const BoardCustomFilter = (props: BoardCustomFilterProps) => {
	const isCMPBoard = useIsCMPBoard();

	if (isCMPBoard) {
		return <BoardCustomFilterRedux {...props} isCompanyManaged />;
	}

	return <BoardCustomFilterRelay {...props} />;
};
