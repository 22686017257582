import type { IssueId } from '@atlassian/jira-software-board-common';
import type { Issue } from '../../../model/issue/issue-types';

export const SET_ISSUE_CHILDREN = 'state.actions.issue-children.SET_ISSUE_CHILDREN' as const;

export type SetIssueChildrenAction = {
	type: typeof SET_ISSUE_CHILDREN;
	payload: Issue[];
};

export const setIssueChildren = (issueChildren: Issue[]): SetIssueChildrenAction => ({
	type: SET_ISSUE_CHILDREN,
	payload: issueChildren,
});

export const SET_ISSUE_CHILDREN_RANK =
	'state.actions.issue-children.SET_ISSUE_CHILDREN_RANK' as const;

type SetIssueChildrenRankPayload = {
	issueIds: number[];
	rankId: number;
	isRankAfter: boolean;
};

export type SetIssueChildrenRankAction = {
	type: typeof SET_ISSUE_CHILDREN_RANK;
	payload: SetIssueChildrenRankPayload;
};

export const setIssueChildrenRank = ({
	issueIds,
	rankId,
	isRankAfter,
}: SetIssueChildrenRankPayload): SetIssueChildrenRankAction => ({
	type: SET_ISSUE_CHILDREN_RANK,
	payload: {
		issueIds,
		rankId,
		isRankAfter,
	},
});

export const ISSUE_CHILDREN_IN_FINAL_COLUMN_REQUEST =
	'state.actions.issue-children.ISSUE_CHILDREN_IN_FINAL_COLUMN_REQUEST' as const;

type IssueChildrenInFinalColumnRequestPayload = {
	parentId: IssueId;
};

export type IssueChildrenInFinalColumnRequestAction = {
	type: typeof ISSUE_CHILDREN_IN_FINAL_COLUMN_REQUEST;
	payload: IssueChildrenInFinalColumnRequestPayload;
};

export const issueChildrenInFinalColumnRequest = (
	parentId: IssueId,
): IssueChildrenInFinalColumnRequestAction => ({
	type: ISSUE_CHILDREN_IN_FINAL_COLUMN_REQUEST,
	payload: { parentId },
});

export const CLEAR_ISSUE_CHILDREN_MENTIONS_OPTIMISTIC =
	'state.actions.issue.mentions.CLEAR_ISSUE_CHILDREN_MENTIONS_OPTIMISTIC' as const;

export type ClearIssueChildrenMentionsOptimisticAction = {
	type: typeof CLEAR_ISSUE_CHILDREN_MENTIONS_OPTIMISTIC;
	payload: {
		issueId: IssueId;
	};
};

export const clearIssueChildrenMentionsOptimistic = (
	issueId: IssueId,
): ClearIssueChildrenMentionsOptimisticAction => ({
	type: CLEAR_ISSUE_CHILDREN_MENTIONS_OPTIMISTIC,
	payload: {
		issueId,
	},
});

export type Action =
	| SetIssueChildrenAction
	| SetIssueChildrenRankAction
	| IssueChildrenInFinalColumnRequestAction
	| ClearIssueChildrenMentionsOptimisticAction;
