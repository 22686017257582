import { useMutation, type MutationTuple } from '@apollo/react-hooks';
import gqlTagSwag from 'graphql-tag';
import type { Variables, Response } from './types';

export const MUTATION = gqlTagSwag`
    mutation SoftwareBoardClearCards($boardId: ID!, $cardIds: [ID!]!) {
        clearCards(input: { boardId: $boardId, cardIds: $cardIds }) {
            clearedCardIds
        }
    }
`;

export const useClearCardsMutation = (): MutationTuple<Response, Variables> =>
	useMutation<Response, Variables>(MUTATION);
