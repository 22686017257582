import { connect } from '@atlassian/jira-react-redux';
import { openIssueModal, closeIssueModal } from '../../state/actions/issue/modal';
import SyncWithRouting from './view';

const mapDispatchToProps = {
	onOpenIssueModal: openIssueModal,
	onCloseIssueModal: closeIssueModal,
} as const;

export default connect(null, mapDispatchToProps)(SyncWithRouting);
