import React, { useMemo, useState, useLayoutEffect, useEffect } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { ff } from '@atlassian/jira-feature-flagging-using-meta';
import { INSIGHTS_PACKAGE } from '@atlassian/jira-insights-common-constants/src/common/constants/package';
import { getBoardOrBacklogFromLocation } from '@atlassian/jira-insights-common-constants/src/services/location';
import { getUserLocation } from '@atlassian/jira-platform-router-utils/src/common/utils/index.tsx';
import { TIMEOUT_IN_MS, experiences } from '../common/constants';
import { ExperienceError } from '../common/utils';
import { trackBrowserMetricsForJira } from '../common/utils/metrics';
import {
	InsightsExperienceTrackerStoreContainer,
	useInsightsExperienceTracker,
} from '../controllers/sweet-state';
import { useMetricsMemo } from '../controllers/use-metrics-memo';
import type { InsightsExperienceTrackerProps } from './types';
import { useUfoErrorLogger } from './utils';

export const InsightsExperienceTracker = ({
	ufoExperience,
	experienceId,
	experienceIdsToCollect,
	timeoutInMs = TIMEOUT_IN_MS,
	attributes,
	children,
	packageName,
}: InsightsExperienceTrackerProps) => {
	const userLocation = getUserLocation();
	const eventFrom = getBoardOrBacklogFromLocation(userLocation);

	const ufoErrorLogger = useUfoErrorLogger({ eventFrom, ufoExperience });

	const insightsPackage =
		ff('jsm-board-insights-issues-for-attention_erwbg') && packageName
			? packageName
			: INSIGHTS_PACKAGE;

	const insightsExperience = `${insightsPackage}.${eventFrom}.${experienceId}`;

	const metricsTracker = useMetricsMemo(() => {
		const browserMetricsKey = `${insightsExperience}.${experiences.RENDER}`;
		const metricsAttributes = {
			...attributes,
			userLocation,
		};

		const service = trackBrowserMetricsForJira({
			browserMetricsKey,
			attributes: metricsAttributes,
		});

		return {
			start: () => {
				service.start();
				ufoExperience?.start().catch(ufoErrorLogger);
			},
			end: () => {
				service.end();
				ufoExperience?.success().catch(ufoErrorLogger);
			},
			cancel: (error?: Error) => {
				// send message to splunk here
				if (error) {
					log.safeErrorWithoutCustomerData(
						browserMetricsKey,
						'Insights experience failed',
						new ExperienceError(error, metricsAttributes),
					);
				}

				service.cancel();
				ufoExperience?.failure().catch(ufoErrorLogger);
			},
		};
	}, [insightsExperience, userLocation, attributes, ufoExperience]);

	return (
		<InsightsExperienceTrackerStoreContainer
			scope={insightsExperience}
			experienceIdsToCollect={experienceIdsToCollect}
			timeoutInMs={timeoutInMs}
			metricsTracker={metricsTracker}
			ufoExperience={ufoExperience}
		>
			{children}
		</InsightsExperienceTrackerStoreContainer>
	);
};

export const useInsightsExperience = () => {
	const [, actions] = useInsightsExperienceTracker();
	const [isExperienceOwner, setExperienceOwner] = useState(false);

	// we only need to expose start, fail and success
	const returnActions = useMemo(
		() => ({
			start: () => {
				setExperienceOwner(true);
				actions.start();
			},
			fail: actions.fail,
			success: actions.success,
		}),
		[actions, setExperienceOwner],
	);

	useEffect(
		() => () => {
			if (isExperienceOwner === true) {
				actions.reset();
			}
		},
		[isExperienceOwner, actions],
	);

	return returnActions;
};

// This will allow us to track down the whole experience for now. Once we need to start tracking down each experience we will have to
// create a new component called `StartInsightsExperienceId` that will be in charge of starting the experience by ID (for only 1 of the experiences). When we
// fire the `start` action we will also start all the individual experiences and when we `succeed/fail` we will also succeed the individual
// experience (and the whole experience when all the related experiences succeeded/fail, this is what we currently do). For doing this we will have
// to allow the metricsTracker to be passed down an ID like: `metricTracker.start(experienceId)`, this will allow us to only succeed/fail one experience Id.
export const StartInsightsExperience = () => {
	const { start } = useInsightsExperience();

	useLayoutEffect(() => {
		start();
	}, [start]);

	return null;
};
