import React, { useMemo, useState, useEffect } from 'react';
import { styled } from '@compiled/react';
import { fg } from '@atlassian/jira-feature-gating';
import { columnHeaderHeight } from '@atlassian/jira-platform-board-kit/src/common/constants/styles/index.tsx';
import { SwimlaneColumnHeaderContainer } from '@atlassian/jira-platform-board-kit/src/ui/swimlane-column-header/index.tsx';
import SwimlaneWrapper from '@atlassian/jira-platform-board-kit/src/ui/swimlane/wrapper/index.tsx';
import { useDependencyLinks } from '@atlassian/jira-portfolio-3-plan-increment-common/src/hooks/use-dependency-links';
import { DependencyLines } from '@atlassian/jira-portfolio-3-plan-increment-common/src/ui/dependency-lines';
import {
	type MeasurementCacheEntry,
	useVirtual,
} from '@atlassian/jira-software-fast-virtual/src/services/use-virtual/index.tsx';
import { Capability } from '../../../../common/capability';
import { useBoardSelector } from '../../../../state';
import {
	columnsWithResolutionSelector,
	platformSwimlaneIdsSelector,
	swimlaneHeightsSelector,
	getIssuesEntriesWithIssueLinksInBoard,
} from '../../../../state/selectors/work/work-selectors';
import {
	useCapability,
	useIsIncrementPlanningBoard,
} from '../../../../state/state-hooks/capabilities';
import ColumnHeader from '../column/header';
import ColumnMenu from '../column/header/menu';
import SwimlaneColumnHeader from '../swimlane-header';
import VirtualizedSwimlane from '../virtualized-swimlane';
import { useAutoScrollSwimlane } from './use-auto-scroll-swimlane';

const SwimlanesContainer = () => {
	const columns = useBoardSelector(columnsWithResolutionSelector);
	const swimlaneIds = useBoardSelector(platformSwimlaneIdsSelector);
	const isIpBoard = useIsIncrementPlanningBoard();
	const issuesWithLinksById = useBoardSelector(getIssuesEntriesWithIssueLinksInBoard);
	const [menusShown, setMenusShowns] = useState<Record<number, boolean>>({});
	const isFlexible = useCapability(Capability.FLEXIBLE_BOARD_COLUMNS);
	const setMenuVisibility = (columnId: number) => (visible: boolean) => {
		setMenusShowns({ [columnId]: visible });
	};

	const swimlaneHeights = useBoardSelector(swimlaneHeightsSelector);
	const cacheOptions = useMemo(
		() => ({
			cache: new Map<string, MeasurementCacheEntry>(),
			getCacheKey: (index: number) => swimlaneIds[index],
		}),
		[swimlaneIds],
	);

	const { rows, totalSize, scrollTo, rowStates } = useVirtual({
		rowCount: swimlaneIds.length,
		getDefaultRowSize: (index) => swimlaneHeights[swimlaneIds[index]],
		offsetTop: columnHeaderHeight,
		cacheOptions,
	});

	const [_, { updateSwimlanePositions }] = useDependencyLinks();

	useEffect(() => {
		if (isIpBoard && rowStates && fg('dependency_visualisation_program_board_fe_and_be')) {
			updateSwimlanePositions(rowStates, rows, swimlaneIds);
		}
	}, [isIpBoard, updateSwimlanePositions, rows, rowStates, swimlaneIds]);

	useAutoScrollSwimlane(swimlaneIds, scrollTo);

	const renderSwimlanes = () => (
		<>
			{rows.map(({ index, top, forceRemeasure, measure }) => (
				<VirtualizedSwimlane
					key={swimlaneIds[index]}
					id={swimlaneIds[index]}
					index={index}
					top={top}
					measure={measure}
					forceRemeasure={forceRemeasure}
					isLastSwimlane={index === swimlaneIds.length - 1}
					isUnscheduledWorkColumnPanel={false}
				/>
			))}
		</>
	);

	return (
		<SwimlanesContainerWrapper isFlexible={isFlexible}>
			<SwimlaneColumnHeaderContainer flexibleColumns={isFlexible}>
				{columns.map(({ id }, columnIndex) => (
					<SwimlaneColumnHeader
						id={id}
						key={id}
						header={
							<ColumnHeader
								id={id}
								isMenuShown={menusShown[id] || false}
								menu={<ColumnMenu columnId={id} onVisibilityChange={setMenuVisibility(id)} />}
							/>
						}
						draggableIndex={columnIndex}
					/>
				))}
			</SwimlaneColumnHeaderContainer>
			<SwimlaneWrapper
				flexibleColumns={isFlexible}
				minHeight={totalSize + columnHeaderHeight}
				isVirtualised
			>
				{renderSwimlanes()}
			</SwimlaneWrapper>
			{isIpBoard && fg('dependency_visualisation_program_board_fe_and_be') && (
				<DependencyLines issuesWithLinksById={issuesWithLinksById} />
			)}
		</SwimlanesContainerWrapper>
	);
};

export default SwimlanesContainer;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlanesContainerWrapper = styled.div<{ isFlexible?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ isFlexible }) => (isFlexible ? '100%' : 'initial'),
});
