import { useEffect } from 'react';
import { setCachedFilters } from '@atlassian/jira-filters-storage';
import { useBoardSelector } from '../../state';
import { rapidViewIdSelector } from '../../state/selectors/software/software-selectors';
import { useIsCMPBoard } from '../../state/state-hooks/capabilities';

export const FiltersStorage = () => {
	const isCMPBoard = useIsCMPBoard();
	const boardId = useBoardSelector(rapidViewIdSelector);
	const filters = useBoardSelector((state) => state.ui.workFilters?.values);

	useEffect(() => {
		if (isCMPBoard) {
			setCachedFilters('board', boardId, filters);
		}
	}, [boardId, filters, isCMPBoard]);

	return null;
};
