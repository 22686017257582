import memoizeOne from 'memoize-one';
import type { Size } from '@atlaskit/icon/types';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { branchDetailsEntryPoint } from '@atlassian/jira-development-board-branch-details-popup/entrypoint';
import { commitDetailsEntryPoint } from '@atlassian/jira-development-board-commit-details-popup/entrypoint';
import {
	ButtonStatus,
	DevInfoTypes,
	type ButtonStatusType,
	type DevInfoType,
} from '@atlassian/jira-development-board-common/src/types.tsx';
import { deploymentDetailsEntryPoint } from '@atlassian/jira-development-board-deployment-details-popup/entrypoint';
import { designDetailsEntryPoint } from '@atlassian/jira-development-board-design-details-popup/entrypoint';
import { pullRequestDetailsEntryPoint } from '@atlassian/jira-development-board-pr-details-popup/entrypoint';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';
import {
	branchDetailsPopupLoad,
	commitDetailsPopupLoad,
	deploymentDetailsPopupLoad,
	designDetailsPopupLoad,
	prDetailsPopupLoad,
} from './utils/performance-analytics';

type IconColor = Record<DevInfoType, Record<ButtonStatusType, string>>;

export const HOVER_TIMEOUT_MS = 300;

export const DevInfoIconPropsMapping = memoizeOne((formatMessage: FormatMessage) => ({
	[DevInfoTypes.BRANCH]: {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		size: 'small' as Size,
		label: formatMessage(messages.branchIconLabel),
	},
	[DevInfoTypes.COMMIT]: {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		size: 'small' as Size,
		label: formatMessage(messages.commitIconLabel),
	},
	[DevInfoTypes.PULLREQUEST]: {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		size: 'small' as Size,
		label: formatMessage(messages.openPullRequestIconLabel),
	},
	[DevInfoTypes.DECLINED]: {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		size: 'small' as Size,
		label: formatMessage(messages.declinedPullRequestIconLabel),
	},
	[DevInfoTypes.MERGED]: {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		size: 'small' as Size,
		label: formatMessage(messages.mergedPullRequestIconLabel),
	},
	[DevInfoTypes.DEPLOYMENT]: {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		size: 'small' as Size,
		label: formatMessage(messages.deploymentIconLabel),
	},
	[DevInfoTypes.DESIGN]: {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		size: 'small' as Size,
		label: formatMessage(messages.designIconLabel),
	},
}));

export const DevInfoButtonLabelMapping = memoizeOne((formatMessage: FormatMessage) => ({
	[DevInfoTypes.BRANCH]: formatMessage(messages.branchIconButtonLabel),
	[DevInfoTypes.COMMIT]: formatMessage(messages.commitIconButtonLabel),
	[DevInfoTypes.PULLREQUEST]: formatMessage(messages.pullRequestIconButtonLabel),
	[DevInfoTypes.MERGED]: formatMessage(messages.pullRequestIconButtonLabel),
	[DevInfoTypes.DECLINED]: formatMessage(messages.pullRequestIconButtonLabel),
	[DevInfoTypes.DEPLOYMENT]: formatMessage(messages.deploymentIconButtonLabel),
	[DevInfoTypes.DESIGN]: formatMessage(messages.designIconButtonLabel),
}));

export const iconColor: IconColor = {
	[DevInfoTypes.BRANCH]: {
		[ButtonStatus.ACTIVE]: token('color.icon.inverse', colors.N0),
		[ButtonStatus.HOVER]: token('color.icon.subtle', colors.N500),
		[ButtonStatus.DEFAULT]: token('color.icon.subtle', colors.N100),
	},
	[DevInfoTypes.COMMIT]: {
		[ButtonStatus.ACTIVE]: token('color.icon.inverse', colors.N0),
		[ButtonStatus.HOVER]: token('color.icon.subtle', colors.N500),
		[ButtonStatus.DEFAULT]: token('color.icon.subtle', colors.N100),
	},
	[DevInfoTypes.PULLREQUEST]: {
		[ButtonStatus.ACTIVE]: token('color.icon.inverse', colors.N0),
		[ButtonStatus.HOVER]: token('color.icon.subtle', colors.N500),
		[ButtonStatus.DEFAULT]: token('color.icon.subtle', colors.N100),
	},
	[DevInfoTypes.MERGED]: {
		[ButtonStatus.ACTIVE]: token('color.icon.success', colors.G200),
		[ButtonStatus.HOVER]: token('color.icon.success', colors.G300),
		[ButtonStatus.DEFAULT]: token('color.icon.success', colors.G300),
	},
	[DevInfoTypes.DECLINED]: {
		[ButtonStatus.ACTIVE]: token('color.icon.danger', colors.R100),
		[ButtonStatus.HOVER]: token('color.icon.danger', colors.R300),
		[ButtonStatus.DEFAULT]: token('color.icon.danger', colors.R300),
	},
	[DevInfoTypes.DEPLOYMENT]: {
		[ButtonStatus.ACTIVE]: token('color.icon.inverse', colors.N0),
		[ButtonStatus.HOVER]: token('color.icon.subtle', colors.N500),
		[ButtonStatus.DEFAULT]: token('color.icon.subtle', colors.N100),
	},
	[DevInfoTypes.DESIGN]: {
		[ButtonStatus.ACTIVE]: token('color.icon.inverse', colors.N0),
		[ButtonStatus.HOVER]: token('color.icon.subtle', colors.N500),
		[ButtonStatus.DEFAULT]: token('color.icon.subtle', colors.N100),
	},
};

export const experienceDetails = {
	[DevInfoTypes.BRANCH]: {
		id: 'branchDetailsPopup',
		name: 'jiraSoftwareBoardBranchDetailsPopup',
	},
	[DevInfoTypes.COMMIT]: {
		id: 'commitDetailsPopup',
		name: 'jiraSoftwareBoardCommitDetailsPopup',
	},
	[DevInfoTypes.DEPLOYMENT]: {
		id: 'deploymentDetailsPopup',
		name: 'jiraSoftwareBoardDeploymentDetailsPopup',
	},
	[DevInfoTypes.PULLREQUEST]: { id: 'prDetailsPopup', name: 'jiraSoftwareBoardPrDetailsPopup' },
	[DevInfoTypes.DESIGN]: {
		id: 'designDetailsPopup',
		name: 'jiraSoftwareBoardDesignDetailsPopup',
	},
};

export const metrics = {
	[DevInfoTypes.BRANCH]: branchDetailsPopupLoad,
	[DevInfoTypes.COMMIT]: commitDetailsPopupLoad,
	[DevInfoTypes.DEPLOYMENT]: deploymentDetailsPopupLoad,
	[DevInfoTypes.PULLREQUEST]: prDetailsPopupLoad,
	[DevInfoTypes.DESIGN]: designDetailsPopupLoad,
};

export const packageDetails = {
	[DevInfoTypes.BRANCH]: {
		id: 'renderBranchDetailsPopup',
		packageName: 'jiraDevelopmentBoardBranchDetailsPopup',
		teamName: 'fusion-isotopes',
	},
	[DevInfoTypes.COMMIT]: {
		id: 'renderCommitDetailsPopup',
		packageName: 'jiraDevelopmentBoardCommitDetailsPopup',
		teamName: 'fusion-isotopes',
	},
	[DevInfoTypes.DEPLOYMENT]: {
		id: 'renderDeploymentDetailsPopup',
		packageName: 'jiraDevelopmentBoardDeploymentDetailsPopup',
		teamName: 'fusion-solaris',
	},
	[DevInfoTypes.PULLREQUEST]: {
		id: 'renderPRDetailsPopup',
		packageName: 'jiraDevelopmentBoardPrDetailsPopup',
		teamName: 'fusion-isotopes',
	},
	[DevInfoTypes.DESIGN]: {
		id: 'renderDesignDetailsPopup',
		packageName: 'jiraDevelopmentBoardDesignDetailsPopup',
		teamName: 'helios-headcoach',
	},
};

export const popupEntryPoints = {
	[DevInfoTypes.BRANCH]: branchDetailsEntryPoint,
	[DevInfoTypes.COMMIT]: commitDetailsEntryPoint,
	[DevInfoTypes.DEPLOYMENT]: deploymentDetailsEntryPoint,
	[DevInfoTypes.PULLREQUEST]: pullRequestDetailsEntryPoint,
	[DevInfoTypes.DESIGN]: designDetailsEntryPoint,
};

export const DeploymentState = Object.freeze({
	SUCCESSFUL: 'SUCCESSFUL',
	FAILED: 'FAILED',
});

export const DeploymentEnvironment = Object.freeze({
	PRODUCTION: 'PRODUCTION',
	STAGING: 'STAGING',
	TESTING: 'TESTING',
});
