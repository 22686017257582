import type { AnyAction, Dispatch, Store } from 'redux';
import noop from 'lodash/noop';
import unique from 'lodash/uniq';
// DO NOT CONSUME DIRECTLY AS IT'S UNSAFE
import queryString from 'query-string';
import isShallowEqual from '@atlassian/jira-common-util-is-shallow-equal';

// This would handle numbers but we'll force consumers to provide strings only
export type StringifiableValue = null | undefined | string[] | string | number | boolean | number[];

export interface StringifiableQuery {
	[key: string]: StringifiableValue;
}

function stringifySafe(queryLike: StringifiableQuery): string {
	return queryString.stringify(queryLike);
}

export interface ParsedQuery {
	[key: string]: null | string[] | string;
}

function parseSafe(search: string): ParsedQuery {
	return queryString.parse(search);
}

export interface HistoryLike {
	replace: (path: string) => void;
}

export interface UrlTransform {
	stringify: (value: StringifiableValue) => StringifiableValue;
	parse: (value: StringifiableValue) => StringifiableValue;
}

export type UrlBinding<State, Action extends AnyAction> = {
	urlKey: string;
	transform: UrlTransform;
	getValueForUrl: (state: State) => StringifiableValue;
	setValueFromUrl: (dispatch: Dispatch<Action>, value: StringifiableValue) => void;
	getDefaultValue?: () => StringifiableValue;
};

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const getQueryString = (): ParsedQuery => parseSafe(window.location.search);

function getNonManagedParams<T>(
	currentParams: { [k: string]: T },
	managedKeys: string[],
): { [k: string]: T } {
	const previous: { [k: string]: T } = {};
	Object.keys(currentParams).forEach((key) => {
		if (managedKeys.includes(key)) {
			return;
		}

		previous[key] = currentParams[key];
	});
	return previous;
}

function setQueryString(params: StringifiableQuery, managedKeys: string[], history: HistoryLike) {
	const current = getQueryString();

	const mergedParams = {
		...(managedKeys.length ? getNonManagedParams(current, managedKeys) : {}),
		...params,
	};

	// do not bother updating the query string if you do not need to
	if (isShallowEqual(current, mergedParams)) {
		return;
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const currentUrlPath = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

	const qs = Object.keys(mergedParams).length ? `?${stringifySafe(mergedParams)}` : '';
	if (history) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		history.replace(window.location.pathname + qs);
	} else {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.history.replaceState({}, '', currentUrlPath + qs);
	}
}

function setStateFromUrl<State, Action extends AnyAction>(
	dispatch: Dispatch<Action>,
	urlBindings: UrlBinding<State, Action>[],
) {
	const qs = getQueryString();
	urlBindings.forEach((binding) => {
		let value: StringifiableValue = qs[binding.urlKey];

		if (typeof value === 'undefined') {
			const { getDefaultValue } = binding;
			value = typeof getDefaultValue === 'function' ? getDefaultValue() : undefined;
		}

		if (typeof value === 'undefined') {
			return;
		}

		binding.setValueFromUrl(dispatch, binding.transform.parse(value));
	});
}

function setUrlFromState<State, Action extends AnyAction>(
	state: State,
	urlBindings: UrlBinding<State, Action>[],
	keepNonManagedParams: boolean,
	history: HistoryLike,
) {
	const search: StringifiableQuery = {};
	urlBindings.forEach((binding) => {
		const value = binding.getValueForUrl(state);

		if (typeof value === 'undefined') {
			return;
		}

		search[binding.urlKey] = binding.transform.stringify(value);
	});

	const managedKeys = keepNonManagedParams ? urlBindings.map(({ urlKey }) => urlKey) : [];

	setQueryString(search, managedKeys, history);
}

// on first load -> get things from the url and put it into state
// every update after -> put state into the url
export default function bindUrlToState<State, Action extends AnyAction>(
	store: Store<State, Action>,
	urlBindings: UrlBinding<State, Action>[] = [],
	keepNonManagedParams = false,
	history: HistoryLike,
) {
	if (!urlBindings.length) {
		return noop;
	}

	const urlKeys = urlBindings.map((binding) => binding.urlKey);
	if (unique(urlKeys).length !== urlKeys.length) {
		// eslint-disable-next-line no-console
		console.warn('clash found in url binding keys', urlKeys);
		return noop;
	}

	setStateFromUrl<State, Action>(store.dispatch, urlBindings);

	return store.subscribe(() => {
		const state = store.getState();
		setUrlFromState(state, urlBindings, keepNonManagedParams, history);
	});
}
