import React, { useEffect, useRef, useCallback, type FocusEvent, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import isNil from 'lodash/isNil';
import { Box } from '@atlaskit/primitives';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { HIDDEN } from '@atlassian/jira-platform-inline-card-create/src/common/constants.tsx';
import { useIccContext } from '@atlassian/jira-platform-inline-card-create/src/services/context/index.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common';
import type { CardId } from '../../../../../model/card/card-types';
import type { ColumnId } from '../../../../../model/column/column-types';
import type { SwimlaneId } from '../../../../../model/swimlane/swimlane-types';
import { useBoardSelector, useBoardStore, useBoardDispatch } from '../../../../../state';
import { setActiveIssueWithIcc } from '../../../../../state/actions/card';
import { getCardEditAssignee } from '../../../../../state/selectors/card/card-selectors';
import {
	isActiveCardSelector,
	shouldRenderIcc,
} from '../../../../../state/selectors/work/work-selectors';
import {
	useIsCMPBoard,
	useIsIncrementPlanningBoard,
} from '../../../../../state/state-hooks/capabilities';
import InlineCardCreate from '../../inline-card-create';
import { ICCPlaceholder } from '../../inline-card-create/styled';
import { getActiveElement, useIccRef, isIccIntersectingWithHeader } from './util';

export type Props = {
	isColumnIccEnabled: boolean;
	isLastSwimlane: boolean;
	canShowIccNudge: boolean;
	id: IssueId;
	cardIndex: number;
	columnId: ColumnId;
	swimlaneId: SwimlaneId | null | undefined;
	forceIccRender: boolean;
	onIssueChange: () => void;
	children: ReactNode;
	groupParentId?: CardId;
	headerRef: React.RefObject<HTMLDivElement>;
};

const renderRichCardDelay = 200;
export const CardWithIcc = (props: Props) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const iccRef = useIccRef();
	const isIPBoard = useIsIncrementPlanningBoard();
	const { initialProjectId: projectId } = useIccContext();
	const pendingHoverAction = useRef<ReturnType<typeof setTimeout> | null>(null);
	const hasRenderedIcc: {
		current: boolean;
	} = useRef(false);

	const {
		isColumnIccEnabled,
		id,
		cardIndex,
		columnId,
		swimlaneId,
		isLastSwimlane,
		children = null,
		onIssueChange,
		canShowIccNudge,
		forceIccRender,
		groupParentId,
		headerRef,
	} = props;
	const isCMPBoard = useIsCMPBoard();
	const boardStore = useBoardStore();
	const dispatch = useBoardDispatch();
	const isActualIcc = useBoardSelector((state) => shouldRenderIcc(state, { issueId: id }));
	const isAssigneeEditing = Boolean(useBoardSelector((state) => getCardEditAssignee(state)(id)));

	useEffect(
		() => () => {
			// Clean up timer
			if (pendingHoverAction.current != null) {
				clearTimeout(pendingHoverAction.current);
			}
		},
		[],
	);

	const onMouseOver = useCallback(() => {
		// Early return if card is rich already
		if (isActiveCardSelector(boardStore.getState(), { issueId: id })) {
			return;
		}
		// Delay setting the richActiveCard to avoid unneccessary renders when scrolling or moving the mouse around
		pendingHoverAction.current = setTimeout(() => {
			dispatch(setActiveIssueWithIcc(id));
		}, renderRichCardDelay);
	}, [boardStore, id, dispatch]);

	const onFocus = useCallback(() => {
		dispatch(setActiveIssueWithIcc(id));
	}, [dispatch, id]);

	const onMouseLeave = useCallback(() => {
		const containerElement = containerRef.current;
		if (!containerElement) {
			return;
		}
		// Remove pending setActiveIssueWithIcc if it hasn't already fired
		if (pendingHoverAction.current) {
			clearTimeout(pendingHoverAction.current);
			pendingHoverAction.current = null;
		}

		const activeElement = getActiveElement();
		// Ignore event if focused on a child
		if (activeElement instanceof Node && containerElement.contains(activeElement)) {
			return;
		}

		dispatch(setActiveIssueWithIcc(null));
	}, [containerRef, dispatch]);

	const onBlur = useCallback(
		(e: FocusEvent<HTMLElement>) => {
			const { relatedTarget } = e;
			if (
				containerRef.current &&
				relatedTarget instanceof Node &&
				!containerRef.current.contains(relatedTarget)
			) {
				dispatch(setActiveIssueWithIcc(null));
			}
		},
		[containerRef, dispatch],
	);

	if (isActualIcc && !hasRenderedIcc.current) {
		hasRenderedIcc.current = true;
	}

	// @ts-expect-error - Operator '>' cannot be applied to types 'IssueId' and 'number'.
	// for the IPBoard, can create issue above the scenario issue card which id is a string,
	// in the JSW board, the card which just created may have not been commit to BE and the id is negative
	// in this case, cannot create new issue above these just created issues
	const canUseIccOld = () => (isIPBoard ? !isNil(id) : id > 0);
	const canUseIccNew = () => (isIPBoard ? !isNil(id) : typeof id === 'number' && id > 0);
	const canUseIcc = fg('do_not_show_icc_in_ip_board_when_header_overlaps')
		? canUseIccNew()
		: canUseIccOld();

	const iccIntersectingWithHeader = fg('do_not_show_icc_in_ip_board_when_header_overlaps')
		? isIPBoard && isIccIntersectingWithHeader(headerRef, iccRef, cardIndex === 0)
		: false;

	const shouldRenderIccAbove =
		canUseIcc &&
		!iccIntersectingWithHeader &&
		(cardIndex === 0 || forceIccRender || isActualIcc || hasRenderedIcc.current);

	const icc =
		isColumnIccEnabled && shouldRenderIccAbove ? (
			<InlineCardCreate
				canShowIccNudge={canShowIccNudge}
				columnId={columnId}
				swimlaneId={swimlaneId}
				cardIndex={cardIndex}
				triggerAppearance={HIDDEN}
				// TODO-BOARD-REFACTOR-TYPES
				// @ts-expect-error id is string but insertBefore is wanting a number
				insertBefore={id}
				isLastSwimlane={isLastSwimlane}
				onChangeFormVisible={onIssueChange}
				// TODO-BOARD-REFACTOR-TYPES
				// @ts-expect-error groupParentId is string but inline-card-create is wanting a number
				groupParentId={groupParentId}
				// TODO-BOARD-REFACTOR-TYPES
				// @ts-expect-error projectID expects number but it is number | null
				projectId={projectId}
			/>
		) : (
			<ICCPlaceholder />
		);

	return (
		<CardWithICC
			raiseCardForPopup={isAssigneeEditing}
			data-testid="software-board.board-container.board.card-container.card-with-icc"
			onFocus={onFocus}
			onBlur={onBlur}
			ref={containerRef}
			{...(!isCMPBoard
				? { onMouseOver, onMouseLeave }
				: { onMouseEnter: onMouseOver, onMouseLeave })}
		>
			{isIPBoard && fg('do_not_show_icc_in_ip_board_when_header_overlaps') ? (
				<Box ref={iccRef}>{icc}</Box>
			) : (
				icc
			)}
			{children}
		</CardWithICC>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardWithICC = styled.div<{ raiseCardForPopup: boolean }>({
	display: 'block',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	zIndex: (props) => (props.raiseCardForPopup ? layers.card : 'auto'),
});
