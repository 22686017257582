import type {
	IssueCreatedPayload,
	CREATE_FORM_FIELD_VALUE_CHANGE_PAYLOAD,
	ProjectFieldValueChangePayload,
	IssueTypeFieldValueChangePayload,
	CREATE_FORM_FIELD_BLUR_PAYLOAD,
	UseTriggerIssueCreateModal,
	StatusFieldChangePayload,
	ResetFormFieldsForCreateAnotherPayload,
	UseFormContextStateChange,
	CreateFormFieldConfigUpdatePayload,
	ModalClosedPayload,
	ClassificationLevelFieldChangePayload,
} from './types';

export const GIC_LIFECYCLE_EVENTS = {
	MODAL_OPENED: 'MODAL_OPENED',
	MODAL_CLOSED: 'MODAL_CLOSED',
	MODAL_KEEP_EDITING_EXISTING_ISSUE: 'MODAL_KEEP_EDITING',
	MODAL_DISCARD_EXISTING_IN_PROGRESS_ISSUE: 'MODAL_DISCARD',
	MODAL_DISCARD_SUGGESTED_ISSUE: 'MODAL_DISCARD_SUGGESTED_ISSUE',
	MODAL_DISCARD_SUGGESTED_ISSUE_CHANGES: 'MODAL_DISCARD_SUGGESTED_ISSUE_CHANGES',
	MODAL_DISCARD_ALL_SUGGESTED_ISSUES: 'MODAL_DISCARD_ALL_SUGGESTED_ISSUES',
	CREATE_FORM_FIELD_CONFIG_UPDATE: 'CREATE_FORM_FIELD_CONFIG_UPDATE',
	CREATE_FORM_FIELD_VALUE_CHANGE: 'CREATE_FORM_FIELD_VALUE_CHANGE',
	ISSUE_CREATE_SUCCESS: 'ISSUE_CREATE_SUCCESS',
	PROJECT_FIELD_VALUE_CHANGE: 'PROJECT_FIELD_VALUE_CHANGE',
	ISSUE_TYPE_FIELD_VALUE_CHANGE: 'ISSUE_TYPE_FIELD_VALUE_CHANGE',
	RESET_FORM_FIELDS_FOR_CREATE_ANOTHER: 'RESET_FORM_FIELDS_FOR_CREATE_ANOTHER',
	USE_TRIGGER_ISSUE_CREATE_MODAL_STATE_CHANGE: 'USE_TRIGGER_ISSUE_CREATE_MODAL_STATE_CHANGE',
	STATUS_FIELD_STATE_CHANGE: 'STATUS_FIELD_STATE_CHANGE',
	CREATE_FORM_FIELD_BLUR: 'CREATE_FORM_FIELD_BLUR',
	CLASSIFICATION_LEVEL_FIELD_CHANGE: 'CLASSIFICATION_LEVEL_FIELD_CHANGE',
	FORM_CONTEXT_STATE_CHANGE: 'FORM_CONTEXT_STATE_CHANGE',
} as const;

export type GIC_LIFECYCLE_EVENTS = (typeof GIC_LIFECYCLE_EVENTS)[keyof typeof GIC_LIFECYCLE_EVENTS];

export type GIC_LIFECYCLE_EVENTS_PAYLOADS = {
	[GIC_LIFECYCLE_EVENTS.MODAL_OPENED]: {
		inProgressIssueID: string;
	};
	[GIC_LIFECYCLE_EVENTS.MODAL_CLOSED]: ModalClosedPayload;
	[GIC_LIFECYCLE_EVENTS.MODAL_KEEP_EDITING_EXISTING_ISSUE]: {};
	[GIC_LIFECYCLE_EVENTS.MODAL_DISCARD_EXISTING_IN_PROGRESS_ISSUE]: {};
	[GIC_LIFECYCLE_EVENTS.MODAL_DISCARD_SUGGESTED_ISSUE]: {};
	[GIC_LIFECYCLE_EVENTS.MODAL_DISCARD_SUGGESTED_ISSUE_CHANGES]: {};
	[GIC_LIFECYCLE_EVENTS.MODAL_DISCARD_ALL_SUGGESTED_ISSUES]: {};
	[GIC_LIFECYCLE_EVENTS.CREATE_FORM_FIELD_CONFIG_UPDATE]: CreateFormFieldConfigUpdatePayload;
	[GIC_LIFECYCLE_EVENTS.CREATE_FORM_FIELD_VALUE_CHANGE]: CREATE_FORM_FIELD_VALUE_CHANGE_PAYLOAD;
	[GIC_LIFECYCLE_EVENTS.CREATE_FORM_FIELD_BLUR]: CREATE_FORM_FIELD_BLUR_PAYLOAD;
	[GIC_LIFECYCLE_EVENTS.ISSUE_CREATE_SUCCESS]: IssueCreatedPayload;
	[GIC_LIFECYCLE_EVENTS.PROJECT_FIELD_VALUE_CHANGE]: ProjectFieldValueChangePayload;
	[GIC_LIFECYCLE_EVENTS.ISSUE_TYPE_FIELD_VALUE_CHANGE]: IssueTypeFieldValueChangePayload;
	[GIC_LIFECYCLE_EVENTS.RESET_FORM_FIELDS_FOR_CREATE_ANOTHER]: ResetFormFieldsForCreateAnotherPayload;
	[GIC_LIFECYCLE_EVENTS.USE_TRIGGER_ISSUE_CREATE_MODAL_STATE_CHANGE]: UseTriggerIssueCreateModal;
	[GIC_LIFECYCLE_EVENTS.STATUS_FIELD_STATE_CHANGE]: StatusFieldChangePayload;
	[GIC_LIFECYCLE_EVENTS.FORM_CONTEXT_STATE_CHANGE]: UseFormContextStateChange;
	[GIC_LIFECYCLE_EVENTS.CLASSIFICATION_LEVEL_FIELD_CHANGE]: ClassificationLevelFieldChangePayload;
};
