import { useEffect } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { INSIGHTS_PACKAGE } from '@atlassian/jira-insights-common-constants/src/common/constants/package';
import { permissionStatus } from '@atlassian/jira-insights-common-constants/src/common/constants/permissions';
import { getBoardOrBacklogFromLocation } from '@atlassian/jira-insights-common-constants/src/services/location/index.tsx';
import { useFetch } from '@atlassian/jira-insights-fetch/src/controllers/use-fetch/main.tsx';
import { fetchInsightPermission } from '../../services/insight-permission';
import { useLocalStorage } from '../../services/use-local-storage/main.tsx';
import { INSIGHT_PERMISSION_ACTION_SUBJECT_ID } from './constants';
import type { CheckClassicViewAggregatedDataPermissionProp } from './types';

export const CheckClassicViewAggregatedDataPermission = ({
	boardId,
	userLocation,
	onPermissionLoaded,
	onInsightsEnabledWithoutPermission,
}: CheckClassicViewAggregatedDataPermissionProp) => {
	const eventFrom = getBoardOrBacklogFromLocation(userLocation);
	const actionSubject = `${INSIGHTS_PACKAGE}.${eventFrom}`;

	const [{ data: permissionResult, loading, fetched, aborted, hasError }] = useFetch(
		{
			onFetch: (options) => fetchInsightPermission({ boardId }, options),
			browserMetricsKey: `${actionSubject}.${INSIGHT_PERMISSION_ACTION_SUBJECT_ID}`,
			analyticsTracking: {
				actionSubject: `${actionSubject}.${INSIGHT_PERMISSION_ACTION_SUBJECT_ID}`,
				packageName: INSIGHTS_PACKAGE,
			},
			loggingKey: INSIGHT_PERMISSION_ACTION_SUBJECT_ID,
		},
		[],
	);

	const [_, setShouldUseJQLData] = useLocalStorage<boolean>(
		'jira-insights-should-use-jql',
		'shouldUseJQLQuery',
		{ defaultValue: false },
	);

	useEffect(() => {
		if (loading) {
			onPermissionLoaded(permissionStatus.LOADING);
		}

		if (fetched && !hasError && !aborted) {
			if (permissionResult) {
				onPermissionLoaded(permissionStatus.ALLOWED);
				const shouldPermittedUsersUseJQLData = fg('insights-use_jql_query_for_enabled_users');

				setShouldUseJQLData(shouldPermittedUsersUseJQLData);
			} else {
				// target experiment only towards not-permitted users
				const shouldEnableAccess = expVal('jira_insights_open-insights', 'isEnabled', false);

				if (shouldEnableAccess) {
					onPermissionLoaded(permissionStatus.ALLOWED);
					onInsightsEnabledWithoutPermission?.();
					setShouldUseJQLData(true);
				} else {
					onPermissionLoaded(permissionStatus.DISALLOWED);
				}
			}
		}

		if (hasError || aborted) {
			onPermissionLoaded(permissionStatus.DISALLOWED);
		}
	}, [
		fetched,
		loading,
		aborted,
		permissionResult,
		hasError,
		onPermissionLoaded,
		setShouldUseJQLData,
		onInsightsEnabledWithoutPermission,
	]);

	return null;
};

export const CheckSimplifiedDataPermission = () => {
	const [_, setShouldUseJQLData] = useLocalStorage<boolean>(
		'jira-insights-should-use-jql',
		'shouldUseJQLQuery',
		{ defaultValue: false },
	);

	const shouldPermittedUsersUseJQLData = fg('insights-use_jql_query_for_enabled_users');

	useEffect(() => {
		setShouldUseJQLData(shouldPermittedUsersUseJQLData);
	}, [setShouldUseJQLData, shouldPermittedUsersUseJQLData]);

	return null;
};
