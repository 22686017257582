import React, {
	type ComponentType,
	Component,
	cloneElement,
	type ReactNode,
	type ReactElement,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
} from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { columnHeaderHeight, layout } from '../../../constants/styles';
import { ColumnTheme, type ColumnId } from '../../../types';
import { columnThemes } from '../../column';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnHeaderContainerControl = styled.div<{
	isDraggable: boolean;
	isMenuShown?: boolean;
	canShowMenu?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>((props) => ({
	userSelect: 'none',
	width: '100%',
	maxWidth: '100%',
	boxSizing: 'border-box',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	height: `${columnHeaderHeight}px`,
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backgroundColor: props.theme.background,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderTopLeftRadius: `${layout.cardListRadius}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderTopRightRadius: `${layout.cardListRadius}px`,
	transition: 'background-color 0.2s ease',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	cursor: props.isDraggable ? 'grab' : 'default',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& .agility-column-menu-meatball': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		position: props.isMenuShown ? 'initial' : 'absolute',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		visibility: props.isMenuShown ? 'initial' : 'hidden',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		opacity: props.isMenuShown ? '1' : '0',
		transition: 'opacity 0s linear 0.3s',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > div': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			backgroundColor: props.theme.background,
		},
	},

	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& .agility-column-menu-meatball': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			position: props.canShowMenu ? 'initial' : 'absolute',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			visibility: props.canShowMenu ? 'initial' : 'hidden',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			opacity: props.canShowMenu ? '1' : '0',
		},
	},
}));

type ColumnHeaderContainerExperimentProps = {
	isDraggable: boolean;
	appearance: ColumnTheme;
	isMenuShown?: boolean;
	canShowMenu?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnHeaderContainerExperiment = styled2.div<ColumnHeaderContainerExperimentProps>({
	userSelect: 'none',
	width: '100%',
	maxWidth: '100%',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	height: `${columnHeaderHeight}px`,
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: ({ appearance }) => columnThemes[appearance].background,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderTopLeftRadius: `${layout.cardListRadius}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderTopRightRadius: `${layout.cardListRadius}px`,
	transition: 'background-color 0.2s ease',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isDraggable }: ColumnHeaderContainerExperimentProps) =>
		isDraggable ? 'grab' : 'default',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& .agility-column-menu-meatball': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		position: (props: ColumnHeaderContainerExperimentProps) =>
			props.isMenuShown ? 'initial' : 'absolute',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		visibility: (props: ColumnHeaderContainerExperimentProps) =>
			props.isMenuShown ? 'initial' : 'hidden',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		opacity: (props: ColumnHeaderContainerExperimentProps) => (props.isMenuShown ? '1' : '0'),
		transition: 'opacity 0s linear 0.3s',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > div': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			backgroundColor: ({ appearance }) => columnThemes[appearance].background,
		},
	},

	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& .agility-column-menu-meatball': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			position: (props: ColumnHeaderContainerExperimentProps) =>
				props.canShowMenu ? 'initial' : 'absolute',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			visibility: (props: ColumnHeaderContainerExperimentProps) =>
				props.canShowMenu ? 'initial' : 'hidden',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			opacity: (props: ColumnHeaderContainerExperimentProps) => (props.canShowMenu ? '1' : '0'),
		},
	},
});

const ColumnHeaderContainer = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	ColumnHeaderContainerExperiment,
	ColumnHeaderContainerControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnHeaderActions = styled2.div({
	display: 'flex',
	position: 'absolute',
	right: token('space.100', '8px'),
	top: token('space.300', '24px'),
	transform: 'translateY(-50%)',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerContainerControl = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	paddingRight: `${layout.gutter}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerContainerExperiment = styled2.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	paddingRight: `${layout.gutter}px`,
});

const SpinnerContainer = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	SpinnerContainerExperiment,
	SpinnerContainerControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled2.div({
	display: 'flex',
	flexFlow: 'column nowrap',
	justifyContent: 'center',
	alignItems: 'stretch',
	flex: '1 1 auto',
	alignSelf: 'auto',
	maxWidth: '100%',
	'&:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', colors.B200)}`,
		outline: 'none',
	},

	padding: `${token('space.200', '16px')} 0`,
	/* ie11 below */
	width: '100%',

	height: `calc(100% - ${token('space.400', '32px')})`,
});

type State = {
	isMenuOpen: boolean;
};

type Props = {
	isEditingTitle: boolean;
	isDragging: boolean;
	isDraggable: boolean;
	isSpinnerVisible: boolean;
	isMenuEnabled: boolean;
	isMenuShown?: boolean | null;
	id: ColumnId;
	title: ReactNode;
	titleHeadingText?: string;
	appearance?: ColumnTheme;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	menu?: ReactElement<ComponentProps<ComponentType<any>>> | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	OverlayContent?: ComponentType<any> | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClick?: () => any;
};

// eslint-disable-next-line jira/react/no-class-components
export default class ColumnHeader extends Component<Props, State> {
	static defaultProps = {
		isDragging: false,
		isDraggable: false,
		isSpinnerVisible: false,
		isMenuEnabled: true,
		isEditingTitle: false,
	};

	state: State = {
		isMenuOpen: false,
	};

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (
			nextProps.isDragging !== this.props.isDragging &&
			nextProps.isDragging &&
			this.state.isMenuOpen
		) {
			this.setState({
				isMenuOpen: false,
			});
		}
	}

	timeoutId: NodeJS.Timeout | null = null;

	onMenuOpenChange = (attrs: { isOpen: boolean }) => {
		this.setState({
			isMenuOpen: attrs.isOpen,
		});
	};

	hasTemporaryId = () => Number(this.props.id) <= 0;

	canShowMenu = () =>
		this.props.isMenuEnabled &&
		!this.hasTemporaryId() &&
		!this.props.isEditingTitle &&
		!this.props.isDragging;

	renderMenu = () => {
		const { titleHeadingText } = this.props;
		if (this.props.menu) {
			return (
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className="agility-column-menu-meatball"
					data-test-id="platform-board-kit.common.ui.column-header.header.meatball"
					data-testid="platform-board-kit.common.ui.column-header.header.meatball"
				>
					{cloneElement(this.props.menu, {
						title: titleHeadingText,
						onOpenChange: this.onMenuOpenChange,
					})}
				</div>
			);
		}

		return null;
	};

	onFocus = () => {
		this.timeoutId && clearTimeout(this.timeoutId);
		if (!this.state.isMenuOpen && this.canShowMenu()) {
			this.setState({
				isMenuOpen: true,
			});
		}
	};

	onBlur = () => {
		this.timeoutId = setTimeout(() => {
			if (this.state.isMenuOpen) {
				this.setState({
					isMenuOpen: false,
				});
			}
		}, 0);
	};

	render() {
		const { title, isSpinnerVisible, isDraggable, onClick, OverlayContent, appearance } =
			this.props;

		return (
			<ColumnHeaderContainer
				onClick={onClick}
				canShowMenu={this.canShowMenu()}
				isMenuShown={
					this.props.isMenuShown != null ? this.props.isMenuShown : this.state.isMenuOpen
				}
				isDraggable={isDraggable}
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				appearance={appearance ?? ColumnTheme.Default}
				data-testid="platform-board-kit.common.ui.column-header.header.column-header-container"
			>
				{OverlayContent && <OverlayContent />}
				<TitleWrapper>{title}</TitleWrapper>
				<ColumnHeaderActions
					// Prevent menu click to bubble up and fire header analytic event.
					onClick={(event: React.MouseEvent) => event.stopPropagation()}
				>
					{this.renderMenu()}
					{isSpinnerVisible && (
						<SpinnerContainer>
							<Spinner size="small" />
						</SpinnerContainer>
					)}
				</ColumnHeaderActions>
			</ColumnHeaderContainer>
		);
	}
}
