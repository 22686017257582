import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import Sla from '../../sla';
import { CustomFieldTooltip } from './custom-field-tooltip';
import { AsyncHtmlCardCustomFieldContent } from './html-card-custom-field-content';
import { TextCardCustomFieldContent } from './text-card-custom-field-content';
import {
	CUSTOM_FIELD_FIX_VERSIONS,
	TextFixVersionsCustomFieldContent,
} from './text-fix-versions-custom-field-content';
import {
	TextLabelCustomFieldContent,
	CUSTOM_FIELD_LABELS,
} from './text-label-custom-field-content';
import { TextSmartLinkCustomFieldContent } from './text-smart-link-custom-field-content';
import type { CustomField, TextCustomField } from './types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { CustomField, TextCustomField };

type CardCustomFieldProps = {
	field: CustomField;
	isCMPBoard?: boolean | null;
	fixVersions?: number[];
};

export const CardCustomField = ({ field, isCMPBoard, fixVersions }: CardCustomFieldProps) => {
	const content = (() => {
		if (field.renderer === 'number') {
			return (
				<TextCardCustomFieldContent
					field={{ text: field.value != null ? String(field.value) : undefined }}
				/>
			);
		}

		if (field.renderer === 'sla') {
			return field.value !== undefined ? (
				<Sla
					{...field.value}
					data-testid="platform-card.common.ui.custom-fields.card-custom-field.sla"
				/>
			) : null;
		}

		// TMP renders labels outside of custom fields
		if (isCMPBoard && field.renderer === 'text' && field.id === CUSTOM_FIELD_LABELS) {
			return <TextLabelCustomFieldContent field={field} />;
		}

		if (field.renderer === 'text' && field.id === CUSTOM_FIELD_FIX_VERSIONS) {
			return <TextFixVersionsCustomFieldContent field={field} fixVersions={fixVersions} />;
		}

		if (field.renderer === 'text' || field.renderer === 'status' || field.renderer === 'epiclink') {
			return <TextCardCustomFieldContent field={field} />;
		}

		if (
			field.renderer === 'url' &&
			expValEquals('jsw_perf_format-links-in-custom-fields', 'board-and-backlog', true)
		) {
			return <TextSmartLinkCustomFieldContent field={field} />;
		}

		if (
			field.renderer === 'html' ||
			field.renderer === 'date' ||
			field.renderer === 'duration' ||
			field.renderer === 'url'
		) {
			return <AsyncHtmlCardCustomFieldContent field={field} />;
		}

		// This is a type assertion that will make TypeScript make sure all
		// `renderers` are checked above and that it's impossible to render
		// null for known types.
		const _unreachable: never = field;
		return null;
	})();

	if (content == null) {
		return null;
	}

	const isSmartLink =
		expValEquals('jsw_perf_format-links-in-custom-fields', 'board-and-backlog', true) &&
		field.renderer === 'url';

	// Hiding tooltip for html fields that already have tooltips
	const hideTooltip = field.label === 'Development' || isSmartLink;

	if (hideTooltip) {
		return content;
	}

	if (field.renderer === 'text' && field.id === CUSTOM_FIELD_FIX_VERSIONS) {
		return (
			<Tooltip content={field.text} position="bottom-start">
				{content}
			</Tooltip>
		);
	}

	// all type of custom fields have renderer of html
	// except the label custom field which has renderer of text
	return field.renderer === 'html' || field.renderer === 'text' ? (
		<CustomFieldTooltip field={field}>{content}</CustomFieldTooltip>
	) : (
		<Tooltip content={field.label}>{content}</Tooltip>
	);
};
