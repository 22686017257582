import AutomationMenu, { BoardMenu } from '@atlassian/jira-automation-menu/src/ui/main.tsx';
import BreadcrumbRenderer from '@atlassian/jira-breadcrumbs/src/breadcrumb-renderer/index.tsx';
import { connect } from '@atlassian/jira-react-redux';
import { Capability } from '../../common/capability';
import { isNewAutomationMenuEnabled } from '../../feature-flags';
import { CAN_EDIT_BOARD, CAN_MANAGE_AUTOMATION } from '../../model/permission/permission-types';
import { getPermissionsSelector } from '../../state/selectors/board/board-permissions-selectors';
import { getIsHeaderCompactMode } from '../../state/selectors/board/board-selectors';
import { getCapability } from '../../state/selectors/capability/capability-selectors';
import {
	selectedIssueKeysSelector,
	selectedIssueIdsSelector,
} from '../../state/selectors/card/card-selectors';
import {
	contextPathSelector,
	getLocale,
	getIsCMPBoard,
	getIsIncrementPlanningBoard,
	getBoardName,
	getBoardType,
	projectIdSelector,
	projectKeySelector,
	projectNameSelector,
	rapidViewIdSelector,
	getBoardTypeForAnalytics,
	getIsJSWBoard,
} from '../../state/selectors/software/software-selectors';
import {
	remainingDaysSelector,
	activeSprintGoalSelector,
	hasActiveSprintsSelector,
	isSprintsEnabled,
	hasManageSprintPermission,
} from '../../state/selectors/sprint/sprint-selectors';
import { isReleasesFeatureEnabled } from '../../state/selectors/work/work-selectors';
import type { State } from '../../state/types';
import CompleteSprintButton from './complete-sprint-button';
import ControlsBar from './controls-bar';
import BoardOperations from './extension';
import FavoriteButton from './favourite-button';
import Menu from './menu';
import ReleaseVersion from './release-version';
import SprintRemainingDays from './sprint-remaining-days';
import Title from './title';
import { getIsCompleteSprintButtonVisible } from './utils';
import Header, { type StateProps } from './view';

export const mapStateToProps = (state: State): StateProps => {
	const isTabNavigation = getCapability(state)(Capability.IS_TAB_NAVIGATION);

	const isCompleteSprintButtonVisible = getIsCompleteSprintButtonVisible(state, isTabNavigation);

	// We should remove Sprint Remaining Days entirely when removing Capability.IS_TAB_NAVIGATION
	const isSprintRemainingDaysVisible =
		!isTabNavigation && !!remainingDaysSelector(state).remainingDays;
	const isCMPBoard = getIsCMPBoard(state);
	const isIncrementPlanningBoard = getIsIncrementPlanningBoard(state);
	const boardType = getBoardType(state);
	const isJSWBoard = getIsJSWBoard(state);

	return {
		baseUrl: contextPathSelector(state),
		locale: getLocale(state),
		sprintGoal: activeSprintGoalSelector(state),
		isMenuVisible: !!getPermissionsSelector(state)[CAN_EDIT_BOARD] || isCMPBoard,
		isHeaderCompactMode: getIsHeaderCompactMode(state),
		isCMPBoard,
		isJSWBoard,
		isIncrementPlanningBoard,
		rapidViewId: rapidViewIdSelector(state),
		Menu,
		ReleaseVersion,
		isReleaseVersionVisible:
			isCMPBoard && boardType === 'KANBAN' && isReleasesFeatureEnabled(state),
		ControlsBar,
		Title,
		// New AutomationMenu is NOT just for CMP now. We should use it for TMP as well
		AutomationMenu: isNewAutomationMenuEnabled() ? BoardMenu : AutomationMenu,
		isCompleteSprintButtonVisible,
		hasManageSprintPermission: hasManageSprintPermission(state),
		CompleteSprintButton,
		FavoriteButton,
		isSprintRemainingDaysVisible,
		SprintRemainingDays,
		BoardOperations,
		BreadcrumbRenderer,
		// For UIF this will always be true but the AutomationMenu is permission aware
		canManageAutomations: !!getPermissionsSelector(state)[CAN_MANAGE_AUTOMATION],
		projectKey: projectKeySelector(state),
		projectId: projectIdSelector(state),
		projectName: projectNameSelector(state),
		projectType: 'software',
		isSprintsEnabled: isSprintsEnabled(state),
		hasActiveSprint: hasActiveSprintsSelector(state),
		selectedIssueKeys: selectedIssueKeysSelector(state),
		selectedIssueIds: selectedIssueIdsSelector(state),
		boardName: getBoardName(state),
		shouldRenderFavouriteButton: getCapability(state)(
			Capability.RENDER_BOARD_FAVOURITE_HEADER_BUTTON,
		),
		shouldRenderAutomationButton: getCapability(state)(
			Capability.RENDER_BOARD_AUTOMATION_HEADER_BUTTON,
		),
		shouldRenderReleaseButton: getCapability(state)(Capability.RENDER_BOARD_RELEASE_MENU_BUTON),
		shouldRenderFeedbackCollectorButton: getCapability(state)(
			Capability.RENDER_BOARD_FEEDBACK_COLLECTOR_BUTTON,
		),
		shouldRenderFullscreenButton: getCapability(state)(Capability.RENDER_BOARD_FULLSCREEN_BUTTON),
		shouldRenderViewSettingsCardDetailsOption: getCapability(state)(
			Capability.RENDER_VIEW_SETTINGS_CARD_DETAILS_OPTION,
		),
		boardType: getBoardTypeForAnalytics(state),
		isTabNavigation,
	};
};

export default connect(mapStateToProps, {})(Header);
