import isNil from 'lodash/isNil';
import { SPRINT_STATES } from '@atlassian/jira-shared-types/src/rest/jira/sprint.tsx';
import type { MenuNode } from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import type { Column } from '../../../../../../../../model/column/column-types';
import { getSwimlaneId } from '../../../../../../../../services/issue/issue-data-transformer';
import { useBoardSelector, useBoardActionCreator } from '../../../../../../../../state';
import { issueRankTeamDateRequest } from '../../../../../../../../state/actions/issue/rank-team-date';
import { orderedColumnsSelector } from '../../../../../../../../state/selectors/column/column-selectors';
import { boardIssuesSelector } from '../../../../../../../../state/selectors/issue/board-issue-selectors';
import { getSwimlaneMode } from '../../../../../../../../state/selectors/swimlane/swimlane-mode-selectors';
import { getSwimlanes } from '../../../../../../../../state/selectors/swimlane/swimlane-selectors';
import { getTeamSprintsForIterations } from '../../../../../../../../state/selectors/team/team-selectors';
import type { State } from '../../../../../../../../state/types';
import type { Props } from './types';
import { useChangeDateRangeContent } from './view';

export const useChangeDateRange = ({
	issueId,
	selectedCardIds,
	isMultiSelectMode,
}: Props): MenuNode[] => {
	const allIssues = useBoardSelector(boardIssuesSelector);
	const triggerIssue = allIssues[String(issueId)];
	const columns = useBoardSelector(orderedColumnsSelector);

	const swimlaneMode = useBoardSelector(getSwimlaneMode);
	const swimlaneId = useBoardSelector((state: State) =>
		!isNil(triggerIssue) ? getSwimlaneId(swimlaneMode, getSwimlanes(state), triggerIssue) : null,
	);

	const teamSprintsForIterations = useBoardSelector((state) =>
		getTeamSprintsForIterations(state, swimlaneId),
	);

	let filteredColumns: Column[] = isMultiSelectMode
		? columns
		: columns.filter((column: Column) => column.id !== triggerIssue.columnId);

	if (teamSprintsForIterations !== null) {
		/* if there are sprints associated with columns, we need an extra layer of filtering
            to remove closed sprints from the available options */
		filteredColumns = filteredColumns.filter(
			(column: Column) =>
				teamSprintsForIterations[Number(column.id)]?.state !== SPRINT_STATES.CLOSED,
		);
		/* TODO JPO-26040: Also need to filter the 'unscheduled' column from the available options
            in the case where an issue has only completed sprints assigned.
            (We don't yet have this data available on the issue) */
	}

	const onDateRangeChange = useBoardActionCreator((destinationColumn: Column) =>
		issueRankTeamDateRequest({
			issueIds: selectedCardIds,
			sourceColumnId: triggerIssue.columnId,
			destinationColumnId: destinationColumn.id,
			rankBeforeIssueId: null,
			rankAfterIssueId: null,
			sourceSwimlaneId: swimlaneId,
			destinationSwimlaneId: swimlaneId,
		}),
	);

	return useChangeDateRangeContent({
		onDateRangeChange,
		columns: filteredColumns,
		teamSprintsForIterations,
	});
};
