import { gqlTagSwag } from '@atlassian/jira-software-swag';

export const ColumnStatusFragment = gqlTagSwag`
    fragment ColumnStatusFragment on ColumnStatus {
        status {
            id
            name
            category
        }
        transitions {
            id
            name
            status { 
                id
            }
            originStatus { id }
            cardType { id }
            isGlobal
            isInitial
            hasConditions
        }
    }
`;
