import React from 'react';
import { FeatureGateChecker } from './feature-gate-checker';
import { IssueCreatedFlag } from './issue-created-flag';

export const RecommendTrialToFreeFlag = ({
	flagId,
	onDismiss,
	issueKeys,
}: {
	flagId: string;
	onDismiss?: () => void;
	issueKeys: string[];
}) => (
	<FeatureGateChecker
		experiment={<IssueCreatedFlag flagId={flagId} onDismiss={onDismiss} issueKeys={issueKeys} />}
	/>
);
