import React, { memo } from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { DEFAULT_MENU_WIDTH } from '../../common/constants';
import { type MenuNode, MenuNodeType } from '../../common/types';
import { MenuContainer } from '../../common/ui';
import * as constants from './constants';

type Props = {
	menuItems: MenuNode[];
};

export const getPlaceholderHeight = (menuItems: MenuNode[]): number =>
	menuItems.reduce((acc: number, menuItem: MenuNode) => {
		if (menuItem.type === MenuNodeType.Separator) {
			return acc + constants.SEPARATOR_PLACEHOLDER_HEIGHT;
		}
		if (menuItem.type === MenuNodeType.Section) {
			return (
				acc +
				constants.SECTION_HEADER_PLACEHOLDER_HEIGHT +
				getPlaceholderHeight(menuItem.children || [])
			);
		}
		return acc + constants.MENU_ITEM_PLACEHOLDER_HEIGHT;
	}, 0);

export const MenuRendererPlaceholder = memo(({ menuItems }: Props) => {
	const height = getPlaceholderHeight(menuItems);
	return (
		<Container data-testid={constants.CONTEXT_MENU_PLACEHOLDER_TESTID}>
			<InnerItems height={height}>
				<Spinner size="medium" />
			</InnerItems>
		</Container>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled(MenuContainer)({
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: DEFAULT_MENU_WIDTH,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InnerItems = styled.div<{ height: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});
