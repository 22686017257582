import React, { Component } from 'react';
import Lozenge from '@atlaskit/lozenge';
import {
	type StatusCategory,
	UNDEFINED,
	TODO,
	IN_PROGRESS,
	DONE,
} from '@atlassian/jira-common-constants/src/status-categories';

const statusCategoryToAppearenceMap = {
	[UNDEFINED]: 'default',
	[TODO]: 'default',
	[DONE]: 'success',
	[IN_PROGRESS]: 'inprogress',
} as const;

type Props = {
	isActive: boolean;
	category: StatusCategory;
	name: string;
	isFullWidth?: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export default class StatusLozenge extends Component<Props> {
	static defaultProps = {
		isActive: false,
	};

	render() {
		return (
			<Lozenge
				testId="common-components-status-lozenge.status-lozenge"
				isBold={this.props.isActive}
				appearance={statusCategoryToAppearenceMap[this.props.category]}
				maxWidth={this.props.isFullWidth ? '100%' : undefined}
			>
				{this.props.name}
			</Lozenge>
		);
	}
}
