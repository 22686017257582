import type { JiraPlanDataRestResponse } from '@atlassian/jira-software-backlog-uif-types';
import type { MobileRestApiResponse } from '@atlassian/jira-software-board-uif-types';
import { fetchUIFBacklogData as fetchUIFBacklogDataInternal } from './services/fetch-uif-backlog-data';
import type { FetchUIFBoardDataParams } from './services/fetch-uif-board-data';
import { setBacklogCachedEntry } from './services/uif-backlog-cache';
import { setBoardCachedEntry } from './services/uif-board-cache';
import { fetchUIFBoardDataStream } from './services/uif-board-cache-service';
import type { ParsedQueryParameters } from './services/utils/types';

/**
 * This type holds both a cached response (or final result) and a refresh.
 *
 * If the load comes from cache, the result will be filled with cached results
 * and the refresh promise will be a secondary change that may be pushed into
 * the app.
 *
 * Alternatively, if the load misses the cache, only the result will be filled
 * and the refresh promise will be `null`.
 *
 * The client is expected to make use of the result data and, if the refresh
 * promise is present, to update the UI with the refresh contents. This allows
 * us to instantly load from cache but update the UI once the data refresh
 * comes in.
 */
export interface UIFBoardCachedDataResult {
	result: MobileRestApiResponse;
	refresh: null | Promise<MobileRestApiResponse>;
	isCacheHit: boolean;
}

export interface UIFBacklogCachedDataResult {
	result: JiraPlanDataRestResponse;
	refresh: null | Promise<JiraPlanDataRestResponse>;
	isCacheHit: boolean;
}

export async function fetchUIFBoardData(
	params: FetchUIFBoardDataParams,
): Promise<UIFBoardCachedDataResult> {
	const { cachedPromise, refreshPromise } = fetchUIFBoardDataStream(params);

	const refreshResult: Promise<{ value: MobileRestApiResponse; type: string }> =
		refreshPromise.then((value) => ({ value, type: 'refresh' }));

	const { value: result, type } = await Promise.race([
		cachedPromise.then((value) => (value != null ? { value, type: 'cache' } : refreshResult)),
		refreshResult,
	]);

	const isCacheHit = type === 'cache';

	return {
		result,
		refresh: isCacheHit ? refreshPromise : null,
		isCacheHit,
	};
}

export async function refreshUIFBoardData(boardId: string, response: MobileRestApiResponse) {
	setBoardCachedEntry(
		{ boardId },
		{
			timestamp: Date.now(),
			etag: response.etag,
			data: response,
		},
	);
}

export async function fetchUIFBacklogData(
	boardId: number,
	queryParameters: ParsedQueryParameters,
): Promise<UIFBacklogCachedDataResult> {
	const { cachedPromise, requestPromise } = fetchUIFBacklogDataInternal(boardId, queryParameters);

	const refreshResult: Promise<{ result: JiraPlanDataRestResponse; type: string }> =
		requestPromise.then((response) => {
			refreshUIFBacklogData(boardId, response);
			return { result: response, type: 'refresh' };
		});

	const { result, type } = await Promise.race([
		cachedPromise.then((cachedResult) =>
			cachedResult != null ? { result: cachedResult.data, type: 'cache' } : refreshResult,
		),
		refreshResult,
	]);

	const isCacheHit = type === 'cache';

	return {
		result,
		refresh: type === 'cache' ? requestPromise : null,
		isCacheHit,
	};
}

export function refreshUIFBacklogData(boardId: number, response: JiraPlanDataRestResponse) {
	return setBacklogCachedEntry(boardId, {
		timestamp: Date.now(),
		data: response,
	});
}
