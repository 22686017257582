import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { setLocalStorage } from '../../../common/utils/local-storage';
import { LAST_AUTO_OPEN_TIMESTAMP_KEY } from './constants';
import type { JptAutoOpenTimestampState } from './types';
import { getHasBeenFourteenDays } from './utils';

const updateAutoOpenTimestamp =
	() =>
	async ({ setState }: StoreActionApi<JptAutoOpenTimestampState>) => {
		const timestamp = Date.now();
		setState({
			hasBeenFourteenDays: getHasBeenFourteenDays(timestamp),
		});
		setLocalStorage(LAST_AUTO_OPEN_TIMESTAMP_KEY, timestamp);
	};

export const actions = { updateAutoOpenTimestamp };
