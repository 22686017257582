import { BOARD } from '@atlassian/jira-software-resource-invalidator/src/common/types.tsx';
import createResourcesCacheMiddleware from '@atlassian/jira-software-resource-invalidator/src/controllers/create-resources-cache-middleware/index.tsx';
import { DRAG_END, DRAG_START } from '../../actions/board/drag';
import {
	CARD_ADD_SHOW_RIPPLE,
	CARD_BLUR,
	CARD_CLICK,
	CARD_DRAG_OVER_TRANSITION,
	CARD_DRAG_OVER_TRANSITION_REQUEST,
	CARD_DRAG_SELECTED_TRANSITION,
	SET_INLINE_EDITING,
	CARD_FOCUS,
	CARD_GET_ISSUE_TRANSITIONS,
	CARD_MEDIA_DATA_SET,
	CARD_MULTI_SELECT,
	CARD_NEXT,
	CARD_NEXT_COLUMN,
	CARD_PREV,
	CARD_PREV_COLUMN,
	CARD_REFRESH_REQUEST,
	CARD_REMOVE_SHOW_RIPPLE,
	CARD_SET_ISSUE_TRANSITIONS,
	CARD_SINGLE_SELECT,
	CARD_TOGGLE_SELECTION,
	SET_ACTIVE_ISSUE_WITH_ICC,
	SET_ISSUE_ID_WITH_ABOVE_ICC_OPEN,
} from '../../actions/card';
import { COLUMN_DELETE_MODAL_CLOSE } from '../../actions/column/delete';
import { UPDATE_DETAIL_VIEW } from '../../actions/detail-view';
import {
	CLEAR_ALL_FILTERS_CLICKED,
	FILTER_PANEL_MOUNTED,
	FILTERS_SET,
	SEARCH_FOCUS,
	SET_ALL_FILTERS,
} from '../../actions/filter';
import { FLAG_DISMISSED } from '../../actions/flags';
import {
	HIDE_ASSIGN_ISSUE_PARENT_MODAL,
	SHOW_ASSIGN_ISSUE_PARENT_MODAL,
} from '../../actions/issue/assign-issue-parent-modal';
import { DEV_STATUS_LOAD } from '../../actions/issue/dev-status';
import { ISSUE_MEDIA_VISIBILITY_CHANGE_REQUEST } from '../../actions/issue/media';
import { CLOSE_ISSUE_MODAL, OPEN_ISSUE_MODAL } from '../../actions/issue/modal';
import { TOGGLE_PANEL } from '../../actions/panels';
import { PERSON_SET } from '../../actions/people';
import { REALTIME_DISPATCH_EVENT } from '../../actions/realtime';
import { SOFTWARE_APP_LOADED, SOFTWARE_DISPLAY_WARNING } from '../../actions/software';
import {
	SWIMLANES_TOGGLE,
	SWIMLANE_TOGGLE,
	SWIMLANE_MODE_CHANGE_REQUEST,
} from '../../actions/swimlane';
import {
	BOARD_NON_CRITICAL_VIEW,
	BOARD_VIEW,
	WORK_DATA_CRITICAL_SET,
	WORK_DATA_SET,
	WORK_REFRESH_DATA,
} from '../../actions/work';

// We ignore the success and failure mutations, we just invalidate them as soon as the request
// is fired. That way if someone navigates away before the SUCCESS/FAILURE mutation returns they
// won't get an incorrect cached version
const BOARD_IGNORE_REGEX = /^(state\.actions\.).+(_SUCCESS|_FAILURE)$/;

// This one helps us ignore junk like COMMON/REDUX_DISPOSABLE/DISPOSE_ACTION
const ELIGIBLE_FOR_INVALIDATION = /^(state\.actions\.).+$/;

export const shouldTriggerCacheInvalidation = ({ type }: { type: string }) => {
	// There are a lot of mutations that just get fired on startup which will immediately
	// invalidate the cache. Just ignore those
	switch (type) {
		case WORK_REFRESH_DATA: // We just update our cache in this case
		case PERSON_SET: // Runs on every load
		case SOFTWARE_APP_LOADED: // Runs on every load
		case DEV_STATUS_LOAD: // Runs on every load
		case BOARD_VIEW: // Runs on every load
		case BOARD_NON_CRITICAL_VIEW: // Runs on every load
		case WORK_DATA_SET: // Ignore when we set the data
		case WORK_DATA_CRITICAL_SET: // Ignore when we set the data
		case REALTIME_DISPATCH_EVENT: // We don't care if we fire the event
		case FILTER_PANEL_MOUNTED: // Don't care if you mount the panel
		case CLOSE_ISSUE_MODAL: // Don't care about the modals
		case OPEN_ISSUE_MODAL: // Don't care about the modals
		case ISSUE_MEDIA_VISIBILITY_CHANGE_REQUEST: // Don't care about viewing images
		case CARD_MEDIA_DATA_SET: // Don't care about media
		case CARD_REFRESH_REQUEST: // Don't care about requests
		case SET_ACTIVE_ISSUE_WITH_ICC: // UI
		case CARD_CLICK: // UI
		case CARD_TOGGLE_SELECTION: // UI
		case CARD_MULTI_SELECT: // UI
		case CARD_SINGLE_SELECT: // UI
		case CARD_DRAG_SELECTED_TRANSITION: // UI
		case CARD_DRAG_OVER_TRANSITION: // UI
		case CARD_DRAG_OVER_TRANSITION_REQUEST: // UI
		case SEARCH_FOCUS: // UI
		case SET_ISSUE_ID_WITH_ABOVE_ICC_OPEN: // UI
		case CLEAR_ALL_FILTERS_CLICKED: // UI
		case CARD_FOCUS: // UI
		case SHOW_ASSIGN_ISSUE_PARENT_MODAL: // UI
		case HIDE_ASSIGN_ISSUE_PARENT_MODAL: // UI
		case COLUMN_DELETE_MODAL_CLOSE: // UI
		case FILTERS_SET: // UI
		case SET_ALL_FILTERS: // UI
		case CARD_REMOVE_SHOW_RIPPLE: // UI
		case CARD_ADD_SHOW_RIPPLE: // UI
		case CARD_GET_ISSUE_TRANSITIONS: // UI
		case CARD_SET_ISSUE_TRANSITIONS: // UI
		case UPDATE_DETAIL_VIEW: // UI
		case TOGGLE_PANEL: // UI
		case FLAG_DISMISSED: // UI
		case SWIMLANES_TOGGLE: // UI
		case SWIMLANE_TOGGLE: // UI
		case SWIMLANE_MODE_CHANGE_REQUEST: // UI
		case DRAG_START: // UI
		case DRAG_END: // UI
		case CARD_BLUR: // UI
		case CARD_PREV: // UI
		case CARD_NEXT: // UI
		case CARD_PREV_COLUMN: // UI
		case CARD_NEXT_COLUMN: // UI
		case SOFTWARE_DISPLAY_WARNING: // UI
		case SET_INLINE_EDITING:
			return false;
		default:
			break;
	}

	// Ignore stuff that isn't applicable to the board (e.g. COMMON/REDUX_DISPOSABLE/DISPOSE_ACTION)
	// Or If it is one that we ignore (SUCCESS/FAILURE)
	if (!ELIGIBLE_FOR_INVALIDATION.exec(type) || BOARD_IGNORE_REGEX.exec(type)) {
		return false;
	}

	// DEFAULT TO TRUE. It's better to forcibly refresh the cache if someone forgets to add
	// their mutation here
	return true;
};

export default createResourcesCacheMiddleware(BOARD, shouldTriggerCacheInvalidation);
