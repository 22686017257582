import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software.main.board-container.board.column.no-active-sprint-dialog.title',
		defaultMessage: 'Get started in the backlog',
		description: '',
	},
	description: {
		id: 'software.main.board-container.board.column.no-active-sprint-dialog.description',
		defaultMessage: 'Plan and start a sprint to see issues here.',
		description: '',
	},
	button: {
		id: 'software.main.board-container.board.column.no-active-sprint-dialog.button',
		defaultMessage: 'Go to Backlog',
		description: '',
	},
});
