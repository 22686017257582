import isNil from 'lodash/isNil';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { IssueId } from '@atlassian/jira-software-board-common';
import type { MenuNode } from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { Capability } from '../../../../../../../../common/capability';
import {
	NO_SWIMLANE,
	SWIMLANE_BY_SUBTASK,
} from '../../../../../../../../model/swimlane/swimlane-modes';
import { getSwimlaneId } from '../../../../../../../../services/issue/issue-data-transformer';
import { useBoardSelector, useBoardActionCreator } from '../../../../../../../../state';
import { issueMoveToBacklogRequest } from '../../../../../../../../state/actions/issue/move-to-backlog';
import {
	bulkIssueRankTransitionRequest,
	issueRankTransitionRequest,
} from '../../../../../../../../state/actions/issue/rank-transition';
import { isBacklogEnabled as isBacklogEnabledSelector } from '../../../../../../../../state/selectors/board/board-selectors';
import {
	areAnySelectedCardsIssueChildren,
	isCardSelected,
} from '../../../../../../../../state/selectors/card/card-selectors';
import { getIssueChildren } from '../../../../../../../../state/selectors/issue-children';
import {
	boardIssuesSelector,
	isBoardIssueAChild,
} from '../../../../../../../../state/selectors/issue/board-issue-selectors';
import {
	getFirstIssueIdInSwimlane,
	getLastIssueIdInSwimlane,
} from '../../../../../../../../state/selectors/issue/issue-in-swimlane-selectors';
import {
	getFirstIssueInColumn,
	getLastIssueInColumn,
	getFirstIssueChildInColumnByParent,
	getLastIssueChildInColumnByParent,
} from '../../../../../../../../state/selectors/issue/issue-selectors';
import { isSprintsEnabled as isSprintsEnabledSelector } from '../../../../../../../../state/selectors/sprint/sprint-selectors';
import { getSwimlaneMode } from '../../../../../../../../state/selectors/swimlane/swimlane-mode-selectors';
import { getSwimlanes } from '../../../../../../../../state/selectors/swimlane/swimlane-selectors';
import { useCapability } from '../../../../../../../../state/state-hooks/capabilities';
import type { Props } from './types';
import { useMoveToContent } from './view';

export const useMoveTo = ({ issueId, selectedCardIds, isMultiSelectMode }: Props): MenuNode[] => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({});

	const allIssues = useBoardSelector(boardIssuesSelector);
	const selectedIssue = allIssues[String(issueId)];
	const selectedCardsContainSubtasks = useBoardSelector((state) =>
		isMultiSelectMode
			? areAnySelectedCardsIssueChildren(state)
			: isBoardIssueAChild(state)(issueId),
	);
	const isSelected = useBoardSelector(isCardSelected)(issueId);

	const swimlaneMode = useBoardSelector(getSwimlaneMode);
	const hasSwimlanes = swimlaneMode !== NO_SWIMLANE.id;
	const swimlaneId = useBoardSelector((state) =>
		hasSwimlanes && !isNil(selectedIssue)
			? getSwimlaneId(swimlaneMode, getSwimlanes(state), selectedIssue)
			: null,
	);
	const issueChildren = useBoardSelector(getIssueChildren);
	const isMoveGroupCard = swimlaneMode !== SWIMLANE_BY_SUBTASK.id && issueId in issueChildren;
	const tryGetRankableIssueId = (id: IssueId | null | undefined) => {
		if (swimlaneMode !== SWIMLANE_BY_SUBTASK.id && id && id in issueChildren) {
			return issueChildren[id].parentId;
		}

		return id;
	};

	const firstIssueChildInColumnByParent = useBoardSelector((state) =>
		getFirstIssueChildInColumnByParent(state, selectedIssue?.columnId, selectedIssue?.parentId),
	);
	const lastIssueChildInColumnByParent = useBoardSelector((state) =>
		getLastIssueChildInColumnByParent(state, selectedIssue?.columnId, selectedIssue?.parentId),
	);

	const firstIssueIdInColumn = useBoardSelector((state) =>
		!hasSwimlanes || !swimlaneId
			? getFirstIssueInColumn(state, selectedIssue?.columnId)
			: getFirstIssueIdInSwimlane(state, selectedIssue?.columnId, swimlaneId),
	);
	const lastIssueIdInColumn = useBoardSelector((state) =>
		!hasSwimlanes || !swimlaneId
			? getLastIssueInColumn(state, selectedIssue?.columnId)
			: getLastIssueIdInSwimlane(state, selectedIssue?.columnId, swimlaneId),
	);

	const firstIssueId = isMoveGroupCard
		? firstIssueChildInColumnByParent
		: tryGetRankableIssueId(firstIssueIdInColumn);

	const lastIssueId = isMoveGroupCard
		? lastIssueChildInColumnByParent
		: tryGetRankableIssueId(lastIssueIdInColumn);

	const isFirst = issueId === firstIssueId;
	const isLast = issueId === lastIssueId;

	const canMoveToBacklog = useCapability(Capability.RENDER_MOVE_TO_BACKLOG_CONTEXT_MENU_ITEM);

	const isMoveToBacklogEnabled = useBoardSelector(
		(state) =>
			canMoveToBacklog &&
			isBacklogEnabledSelector(state) &&
			!isSprintsEnabledSelector(state) &&
			!selectedCardsContainSubtasks,
	);

	const onMoveToBacklog = useBoardActionCreator(() => {
		const issueIdsToMove = selectedCardIds;
		const issueKeysToMove = selectedCardIds.map((cardId) => allIssues[String(cardId)].key);
		const firstColumnId = allIssues[String(selectedCardIds[0])].columnId;

		return issueMoveToBacklogRequest(issueIdsToMove, issueKeysToMove, firstColumnId);
	});

	const onMoveToTop = useBoardActionCreator(() => {
		if (isSelected && selectedCardIds.length > 1) {
			return bulkIssueRankTransitionRequest({
				issueIds: selectedCardIds,
				sourceColumnId: selectedIssue.columnId,
				destinationColumnId: selectedIssue.columnId,
				rankBeforeIssueId: firstIssueId,
				rankAfterIssueId: null,
				sourceSwimlaneId: null,
				destinationSwimlaneId: null,
				isSourceDone: false,
				isDestinationDone: false,
				analyticsEvent,
			});
		}
		return issueRankTransitionRequest({
			issueIds: [issueId],
			sourceColumnId: selectedIssue.columnId,
			destinationColumnId: selectedIssue.columnId,
			rankBeforeIssueId: firstIssueId,
			rankAfterIssueId: null,
			sourceSwimlaneId: null,
			destinationSwimlaneId: null,
			isSourceDone: false,
			isDestinationDone: false,
			analyticsEvent,
		});
	});

	const onMoveToBottom = useBoardActionCreator(() => {
		if (isSelected && selectedCardIds.length > 1) {
			return bulkIssueRankTransitionRequest({
				issueIds: selectedCardIds,
				sourceColumnId: selectedIssue.columnId,
				destinationColumnId: selectedIssue.columnId,
				rankBeforeIssueId: null,
				rankAfterIssueId: lastIssueId,
				sourceSwimlaneId: null,
				destinationSwimlaneId: null,
				isSourceDone: false,
				isDestinationDone: false,
				analyticsEvent,
			});
		}
		return issueRankTransitionRequest({
			issueIds: [issueId],
			sourceColumnId: selectedIssue.columnId,
			destinationColumnId: selectedIssue.columnId,
			rankBeforeIssueId: null,
			rankAfterIssueId: lastIssueId,
			sourceSwimlaneId: null,
			destinationSwimlaneId: null,
			isSourceDone: false,
			isDestinationDone: false,
			analyticsEvent,
		});
	});

	return useMoveToContent({
		isMoveToBacklogEnabled,
		isMoveToTopActionVisible: !isFirst,
		isMoveToBottomActionVisible: !isLast,
		onMoveToBacklog,
		onMoveToTop,
		onMoveToBottom,
	});
};
