import { connect } from '@atlassian/jira-react-redux';
import { getIssueTypesUrl } from '../../common/urls';
import { CAN_EDIT_BOARD } from '../../model/permission/permission-types';
import { getPermissionsSelector } from '../../state/selectors/board/board-permissions-selectors';
import {
	contextPathSelector,
	getIsCMPBoard,
	projectKeySelector,
} from '../../state/selectors/software/software-selectors';
import type { State } from '../../state/types';
import type { StateProps } from './types';
import MissingIssueTypeFlag from './view';

const mapStateToProps = (state: State): StateProps => {
	const permissions = getPermissionsSelector(state);
	return {
		contextPath: contextPathSelector(state),
		canEditBoard: permissions[CAN_EDIT_BOARD] === true && !getIsCMPBoard(state),
		wantedUrl: getIssueTypesUrl(projectKeySelector(state)),
	};
};

export default connect(mapStateToProps, {})(MissingIssueTypeFlag);
