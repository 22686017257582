import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	storyPoints: {
		id: 'insights-common.common.story-points',
		defaultMessage: '{value, plural, one {point} other {points}}',
		description: 'Story point estimation count for Jira issues.',
	},
	issueCount: {
		id: 'insights-common.common.issue-count',
		defaultMessage: '{value, plural, one {issue} other {issues}}',
		description: 'Issue count of Jira issues.',
	},
	sprints: {
		id: 'insights-common.common.sprints',
		defaultMessage: '{sprintCount, plural, one {sprint} other {sprints}}',
		description: 'Shows Jira sprint count',
	},
	timeTrackingLabel: {
		id: 'insights-common.common.time-tracking-label',
		defaultMessage: 'time estimation',
		description: 'Text used for time estimate estimation unit.',
	},
	storyPointsLabel: {
		id: 'insights-common.common.story-points-label',
		defaultMessage: 'story points',
		description: 'Text used for story point estimation unit.',
	},
	issueCountLabel: {
		id: 'insights-common.common.issue-count-label',
		defaultMessage: 'issue count',
		description: 'Text used for issue count estimation unit.',
	},
	issueCountIssueTermRefresh: {
		id: 'insights-common.common.issue-count-issue-term-refresh',
		defaultMessage: '{value, plural, one {issue} other {issues}}',
		description: 'Issue count of Jira issues.',
	},
	issueCountLabelIssueTermRefresh: {
		id: 'insights-common.common.issue-count-label-issue-term-refresh',
		defaultMessage: 'issue count',
		description: 'Text used for issue count estimation unit.',
	},
});
