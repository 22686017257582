import type { Observable as ObservableType } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { CardRefreshBoardScopeResponse } from '../../model/card/card-types';
import { getJSWGraphQlEndpointUrl } from '../software/software-api-urls';

const softwareBoardCardOperationName = 'SoftwareBoardCard';

export const getBoardScopeCardQuery = (
	boardId: string,
	cardIds: string[],
	shouldLoadChildren: boolean, // TODO: KODAMA-15 should be removed when card create mutation is introduced
) => {
	const issueChildren = shouldLoadChildren
		? `board {
            issueChildren {
                id
                flagged
                done
                parentId
                estimate {
                    storyPoints
                    originalEstimate {
                        value
                        valueAsText
                    }
                }
                issue {
                    id
                    key
                    summary
                    labels
                    assignee {
                        accountId
                        displayName
                        avatarUrl
                    }
                    type { id, name, iconUrl }
                    status { id }
                    activeSprint { id }
                }
                coverMedia {
                    attachmentId
                    endpointUrl
                    clientId
                    token
                    attachmentMediaApiId
                    hiddenByUser
                }
                priority {
                    name
                    iconUrl
                }
                dueDate
            }
        }`
		: '';

	return `query ${softwareBoardCardOperationName} {
        boardScope(boardId:${boardId}){
            projectLocation {
                id
            }
            cards(cardIds: [${String(cardIds)}]){
                id
                flagged
                done
                parentId
                estimate {
                    storyPoints
                    originalEstimate {
                        value
                        valueAsText
                    }
                }
                coverMedia{
                    attachmentId
                    endpointUrl
                    clientId
                    token
                    attachmentMediaApiId
                    hiddenByUser
                }
                issue{
                    id
                    key
                    summary
                    labels
                    status{
                        id
                        name
                    }
                    assignee{
                        accountId
                        displayName
                        avatarUrl
                    }
                    type{
                        id
                        name
                        iconUrl
                    }
                    activeSprint { id }
                }
                childIssuesMetadata { complete, total }
                priority {
                    name
                    iconUrl
                }
                dueDate
            }
            ${issueChildren}
        }
    }`;
};

const fetchBoardScopeCardData = (
	boardId: string,
	cardIds: string[],
	shouldLoadChildren: boolean,
) => ({
	method: 'POST',
	body: JSON.stringify({
		operationName: softwareBoardCardOperationName,
		query: getBoardScopeCardQuery(boardId, cardIds, shouldLoadChildren),
	}),
});

export const fetchCardRefreshBoardScopeData = (
	contextPath: string,
	boardId: string,
	cardIds: string[],
	shouldLoadChildren = true,
): ObservableType<CardRefreshBoardScopeResponse> =>
	fetchJson$(
		getJSWGraphQlEndpointUrl(contextPath),
		fetchBoardScopeCardData(boardId, cardIds, shouldLoadChildren),
	);
