import type { Column, ColumnId } from '../../types';

const INITIAL_COLUMN_ID = 0;
let lastColumnId = INITIAL_COLUMN_ID;

const generateId = (): ColumnId => {
	lastColumnId -= 1;
	return lastColumnId;
};

export const newColumn = (): Column => ({
	id: generateId(),
	name: '',
	isDone: false,
	clearedOutCardsUrl: null,
	totalCardsCount: 0,
	isUpdating: true,
	isInitial: false,
});
