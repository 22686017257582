import {
	ROUTE_NAMES_RAPIDBOARD_BOARD,
	ROUTE_NAMES_RAPIDBOARD_BOARD_EMBED,
	ROUTE_NAMES_RAPIDBOARD_USER_BOARD,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';

/**
 * Use the router to determine if we are on a CMP board.
 */
export const useIsCMPBoardRoute = (): boolean => {
	const { name: routeName } = useCurrentRoute();

	return [
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		ROUTE_NAMES_RAPIDBOARD_BOARD as string,
		ROUTE_NAMES_RAPIDBOARD_BOARD_EMBED,
		ROUTE_NAMES_RAPIDBOARD_USER_BOARD,
	].includes(routeName);
};
