import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	visibleIssueCount: {
		id: 'software.main.board-container.board.column.program-swimlane-column-header.visible-issue-count',
		defaultMessage: '{count} / {total} {total, plural, one {issue} other {issues}}',
		description: 'Number of visible issues in swimlane column',
	},
	totalIssueCount: {
		id: 'software.main.board-container.board.column.program-swimlane-column-header.total-issue-count',
		defaultMessage: '{total} {total, plural, one {issue} other {issues}}',
		description: 'Number of total issues in swimlane column',
	},
	completedSprint: {
		id: 'software.main.board-container.board.column.program-swimlane-column-header.completed-sprint',
		defaultMessage: 'Completed sprint',
		description: 'Label for past sprints in the program board swimlane column',
	},
	activeSprint: {
		id: 'software.main.board-container.board.column.program-swimlane-column-header.active-sprint',
		defaultMessage: 'Active sprint',
		description: 'Label for current sprints in the program board swimlane column',
	},
	futureSprint: {
		id: 'software.main.board-container.board.column.program-swimlane-column-header.future-sprint',
		defaultMessage: 'Future sprint',
		description: 'Label for future sprints in the program board swimlane column',
	},
});
