import { createSessionExpirableStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-legacy
import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';

/**
 * Return an ExpirableStorage to be used with the "have i seen it" service.
 *
 * If the user is anonymous, return null.
 */

const createStorage = () => {
	let storage = null;
	try {
		let context;
		try {
			context = getTenantContext_DEPRECATED_DO_NOT_USE();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			return storage;
		}
		const { atlassianAccountId } = context;
		if (atlassianAccountId) {
			storage = createSessionExpirableStorageProvider(
				`have-i-seen-it-${atlassianAccountId.replace(/:/g, '_')}`,
			);
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		log.safeErrorWithoutCustomerData('have-i-seen-it.storage', 'Failed to initialize storage.');
	}
	return storage;
};
export default createStorage();
