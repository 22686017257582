import {
	fireTrackAnalytics,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { getStatusColumn } from '../../../../common/utils/column';
import type { ColumnId } from '../../../../model/column/column-types';
import type {
	ColumnCreateRequestAction,
	ColumnCreateSuccessAction,
	ColumnCreateFailureAction,
} from '../../../actions/column/create';
import type {
	ColumnDeleteAction,
	ColumnDeleteRequestAction,
	ColumnDeleteSuccessAction,
	ColumnDeleteFailureAction,
} from '../../../actions/column/delete';
import {
	type ColumnSetLimitsRequestAction,
	type ColumnSetLimitsSuccessAction,
	type ColumnSetLimitsFailureAction,
	type ColumnCloseLimitsModalAction,
	type ColumnOpenLimitsModalAction,
	COLUMN_SET_LIMITS_REQUEST,
} from '../../../actions/column/limits';
import type { ColumnRankRequestAction } from '../../../actions/column/rank';
import type {
	ColumnRenameRequestAction,
	ColumnRenameSuccessAction,
	ColumnRenameFailureAction,
} from '../../../actions/column/rename';
import {
	type IssueRankTransitionRequestAction,
	ISSUE_RANK_TRANSITION_REQUEST,
} from '../../../actions/issue/rank-transition';
import type { State } from '../../../reducers/types';
import {
	getColumnIndex,
	columnStatusMappingSelector,
	getColumnById,
} from '../../../selectors/column/column-selectors';
import { getColumnTotalIssuesCount, getColumnTheme } from '../../../selectors/work/work-selectors';
import { toOneBasedIndex } from '../analytics-selector';

export const columnRankRequest =
	(eventPrefix: string) => (preState: State, state: State, action: ColumnRankRequestAction) => {
		const { columnId } = action.payload;
		const { isInitial, isDone } = columnStatusMappingSelector(state)[columnId];

		return {
			name: `${eventPrefix}.column.rank`,
			data: {
				startIndex: toOneBasedIndex(getColumnIndex(preState, columnId)),
				endIndex: toOneBasedIndex(getColumnIndex(state, columnId)),
				hasInitialStatus: isInitial,
				isResolutionDone: isDone,
			},
		};
	};

export const columnRankSuccess = (eventPrefix: string) => () => ({
	name: `${eventPrefix}.column.rank.success`,
	data: {},
});

export const columnRankFailure = (eventPrefix: string) => () => ({
	name: `${eventPrefix}.column.rank.failure`,
	data: {},
});

export const columnRenameRequest =
	(eventPrefix: string) => (preState: State, state: State, action: ColumnRenameRequestAction) => ({
		name: `${eventPrefix}.column-header.edit.request`,
		data: {
			columnIndex: toOneBasedIndex(getColumnIndex(state, action.payload.columnId)),
			transactionId: action.meta.optimistic.id,
		},
	});

export const columnRenameSuccess =
	(eventPrefix: string) => (preState: State, state: State, action: ColumnRenameSuccessAction) => ({
		name: `${eventPrefix}.column-header.edit.success`,
		data: {
			columnIndex: toOneBasedIndex(getColumnIndex(state, action.payload.columnId)),
			transactionId: action.meta.optimistic.id,
		},
	});

export const columnRenameFailure =
	(eventPrefix: string) => (preState: State, state: State, action: ColumnRenameFailureAction) => ({
		name: `${eventPrefix}.column-header.edit.failure`,
		data: {
			columnIndex: toOneBasedIndex(getColumnIndex(state, action.payload.columnId)),
			transactionId: action.meta.optimistic.id,
		},
	});

export const columnCreateRequest =
	(eventPrefix: string) => (preState: State, state: State, action: ColumnCreateRequestAction) => ({
		name: `${eventPrefix}.column.add`,
		data: {
			columnIndex: toOneBasedIndex(getColumnIndex(state, action.payload.temporaryColumnId)),
		},
	});

export const columnCreateSuccess =
	(eventPrefix: string) => (preState: State, state: State, action: ColumnCreateSuccessAction) => {
		const {
			payload: {
				column: { id },
			},
			meta: { analyticsEvent },
		} = action;
		fireTrackAnalytics(analyticsEvent, 'column created', {
			columnId: id,
		});

		return {
			name: `${eventPrefix}.column.add.success`,
			data: {
				columnIndex: toOneBasedIndex(getColumnIndex(state, action.payload.temporaryColumnId)),
			},
		};
	};

export const columnCreateFailure =
	(eventPrefix: string) => (preState: State, state: State, action: ColumnCreateFailureAction) => {
		const {
			error,
			meta: { analyticsEvent },
		} = action;
		fireOperationalAnalytics(analyticsEvent, 'column createFailed', {
			errorMessage: error,
		});

		return {
			name: `${eventPrefix}.column.add.failure`,
			data: {
				columnIndex: toOneBasedIndex(getColumnIndex(state, action.payload.temporaryColumnId)),
			},
		};
	};

export const columnDelete =
	(eventPrefix: string) => (preState: State, state: State, action: ColumnDeleteAction) => ({
		name: `${eventPrefix}.column.delete`,
		data: {
			columnIndex: toOneBasedIndex(getColumnIndex(state, action.payload.columnId)),
			hasIssues: action.payload.hasIssues,
			hasIssuesNotOnBoard: action.payload.hasIssuesNotOnBoard,
		},
	});

export const columnDeleteRequest =
	(eventPrefix: string) => (preState: State, state: State, action: ColumnDeleteRequestAction) => ({
		name: `${eventPrefix}.column.delete.request`,
		data: {
			columnIndex: toOneBasedIndex(getColumnIndex(state, action.payload.columnId)),
		},
	});

export const columnDeleteSuccess =
	(eventPrefix: string) => (preState: State, state: State, action: ColumnDeleteSuccessAction) => ({
		name: `${eventPrefix}.column.delete.success`,
		data: {
			columnIndexes: action.payload.columns.map((column) =>
				toOneBasedIndex(getColumnIndex(state, column.id)),
			),
		},
	});

export const columnDeleteFailure =
	(eventPrefix: string) => (preState: State, state: State, action: ColumnDeleteFailureAction) => ({
		name: `${eventPrefix}.column.delete.failure`,
		data: {
			columnIndex: toOneBasedIndex(getColumnIndex(state, action.payload.columnId)),
		},
	});

export const columnOpenLimitsModal =
	(eventPrefix: string) =>
	(preState: State, state: State, action: ColumnOpenLimitsModalAction) => ({
		name: `${eventPrefix}.column.limits.dialog.open`,
		data: {
			columnIndex: toOneBasedIndex(getColumnIndex(state, action.payload.columnId)),
		},
	});

export const columnCloseLimitsModal =
	(eventPrefix: string) =>
	(preState: State, state: State, action: ColumnCloseLimitsModalAction) => ({
		name: `${eventPrefix}.column.limits.dialog.close`,
		data: {
			columnIndex: toOneBasedIndex(getColumnIndex(state, action.payload.columnId)),
		},
	});

export const columnSetLimitsRequest =
	(eventPrefix: string) =>
	(preState: State, state: State, action: ColumnSetLimitsRequestAction) => ({
		name: `${eventPrefix}.column.limits.set.request`,
		data: {
			columnIndex: toOneBasedIndex(getColumnIndex(state, action.payload.columnId)),
			maxIssueCount: action.payload.maxIssueCount,
			minIssueCount: action.payload.minIssueCount,
			transactionId: action.meta.optimistic.id,
		},
	});

export const columnSetLimitsSuccess =
	(eventPrefix: string) =>
	(preState: State, state: State, action: ColumnSetLimitsSuccessAction) => ({
		name: `${eventPrefix}.column.limits.set.success`,
		data: {
			columnIndex: toOneBasedIndex(getColumnIndex(state, action.payload.columnId)),
			transactionId: action.meta.optimistic.id,
		},
	});

export const columnSetLimitsFailure =
	(eventPrefix: string) =>
	(preState: State, state: State, action: ColumnSetLimitsFailureAction) => ({
		name: `${eventPrefix}.column.limits.set.failure`,
		data: {
			columnIndex: toOneBasedIndex(getColumnIndex(state, action.payload.columnId)),
			transactionId: action.meta.optimistic.id,
		},
	});

const getColumnBustedEvent = (
	eventName: string,
	checkState: State,
	payloadState: State,
	columnId: ColumnId,
) =>
	getColumnTheme(checkState, columnId) !== 'default'
		? {
				name: eventName,
				data: {
					columnIndex: toOneBasedIndex(getColumnIndex(payloadState, columnId)),
					maxIssueCount: getStatusColumn(getColumnById(payloadState, columnId))?.maxIssueCount,
					issueCount: getColumnTotalIssuesCount(payloadState, columnId),
				},
			}
		: null;

export const columnBustStatus =
	(eventPrefix: string) =>
	(
		preState: State,
		state: State,
		action: IssueRankTransitionRequestAction | ColumnSetLimitsRequestAction,
	) => {
		const bustedEventName = `${eventPrefix}.column.limits.busted`;
		const unbustedEventName = `${eventPrefix}.column.limits.unbusted`;

		if (action.type === ISSUE_RANK_TRANSITION_REQUEST) {
			const {
				payload: { destinationColumnId, sourceColumnId },
			} = action;
			return (
				getColumnBustedEvent(bustedEventName, state, state, destinationColumnId) ||
				getColumnBustedEvent(unbustedEventName, preState, state, sourceColumnId) ||
				null
			);
		}

		if (action.type === COLUMN_SET_LIMITS_REQUEST) {
			const {
				payload: { columnId },
			} = action;
			return (
				getColumnBustedEvent(bustedEventName, state, state, columnId) ||
				getColumnBustedEvent(unbustedEventName, preState, state, columnId) ||
				null
			);
		}

		return null;
	};
