import type { IconProps } from '@atlaskit/icon';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import UploadIcon from '@atlaskit/icon/glyph/upload';
import type { IconSrc } from '../../../../common/types';
import JiraIcon from './jira-icon';
import LaunchDarklyIcon from './launch-darkly-icon';
import SecurityIcon from './security-icon';
import SlackIcon from './slack-icon';
import TransitionIcon from './transition-icon';

export const templateIcons: { [src in IconSrc]: React.ComponentType<IconProps> } = {
	LaunchDarklyIcon,
	SecurityIcon,
	SlackIcon,
	SoftwareIcon: JiraIcon,
	TransitionIcon,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	UploadIcon,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	EditorAddIcon,
};
