import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props as FormProps } from './types';

const AsyncInlineCardCreateFormComp = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-inline-card-create-form" */ './main'),
);

export const AsyncInlineCardCreateForm = (props: Omit<FormProps, 'intl'>) => (
	<ErrorBoundary id="async-inline-card-create-form" packageName="JiraPlatformBoardKit">
		<Placeholder name="async-inline-card-create-form" fallback={null}>
			<AsyncInlineCardCreateFormComp {...props} />
		</Placeholder>
	</ErrorBoundary>
);
