import { useEffect } from 'react';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { getProjectAccessLevel } from '../../../../services/fetch-project-access-level';
import { getUserProjectRoles } from '../../../../services/fetch-user-project-roles';
import { reduceCustomRoles, fireAddPeopleRenderedEvent } from './utils';

export type Props = {
	projectId: number;
	baseUrl: string;
	showAddPeopleButton: boolean;
	screenName: string;
};

export const Analytics = (props: Props) => {
	const { projectId, baseUrl, showAddPeopleButton, screenName } = props;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (projectId >= 0 && showAddPeopleButton) {
			Promise.all([
				getUserProjectRoles(baseUrl, projectId),
				getProjectAccessLevel(baseUrl, projectId),
			]) // eslint-disable-next-line @typescript-eslint/no-explicit-any
				.then(([roleData, projectAccessLevel]: [any, any]) => {
					const projectRoleNames =
						roleData === null || roleData.projectRoleNames === null
							? null
							: reduceCustomRoles(roleData.projectRoleNames);

					fireAddPeopleRenderedEvent(
						createAnalyticsEvent,
						screenName,
						projectId,
						projectRoleNames,
						projectAccessLevel,
					);
				});
		}
	}, [baseUrl, createAnalyticsEvent, projectId, screenName, showAddPeopleButton]);

	return null;
};
