import type { FetchState, FetchData, Action } from '../types';
import { initialState } from './state';

const resetInitialState = () => initialState;

const setFetchStart = (state: FetchState<FetchData>) => ({
	...state,
	initialized: true,
	loading: true,
	fetched: false,
	hasError: false,
	aborted: false,
});

const setFetchEnd = (_: FetchState<FetchData>, action: Action) => ({
	initialized: true,
	loading: false,
	fetched: true,
	hasError: false,
	aborted: false,
	data: action.payload,
});

const setError = (_: FetchState<FetchData>, action: Action) => ({
	error: action.payload,
	initialized: true,
	loading: false,
	fetched: false,
	hasError: true,
	aborted: false,
});

const setAborted = () => ({
	initialized: true,
	loading: false,
	fetched: false,
	hasError: false,
	aborted: true,
});

const setDefault = (state: FetchState<FetchData>) => state;

export const strategies = {
	resetInitialState,
	setFetchStart,
	setFetchEnd,
	setError,
	setAborted,
	default: setDefault,
} as const;
