import { connect } from '@atlassian/jira-react-redux';
import type { Sprint } from '../../../model/sprint/sprint-types';
import {
	getIsCMPBoard,
	rapidViewIdSelector,
} from '../../../state/selectors/software/software-selectors';
import {
	activeSprintsSelector,
	getSelectedSprintsKeys,
} from '../../../state/selectors/sprint/sprint-selectors';
import { workIssuesSelector } from '../../../state/selectors/work/work-selectors';
import type { State } from '../../../state/types';
import { type StateProps, type OwnProps, CompleteSprintButton } from './view';

export const getAppearanceState = (sprints: Sprint[] | null) => {
	// FIXME next dev actively working in this area
	// not urgent bc (null <= 0) => true so no crash
	// @ts-expect-error TS2531: Object is possibly 'null'.
	if (sprints && sprints[0]?.daysRemaining <= 0) {
		return 'primary';
	}

	return 'default';
};

const mapStateToProps = (state: State): StateProps => {
	const activeSprints = activeSprintsSelector(state);

	return {
		activeSprints,
		appearance: getAppearanceState(activeSprints),
		boardId: rapidViewIdSelector(state),
		selectedSprintIds: getSelectedSprintsKeys(state),
		sourceIssueNo: workIssuesSelector(state).length,
		isCMPBoard: getIsCMPBoard(state),
	};
};

export default connect<StateProps, {}, OwnProps, State>(mapStateToProps, {})(CompleteSprintButton);
