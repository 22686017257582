// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';

const LazyResourcesCacheWrapper = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-jira-software-resource-invalidator-cache-wrapper" */ './index'
		),
);

export const AsyncResourcesCacheWrapper = (
	props: ComponentProps<typeof LazyResourcesCacheWrapper>,
) => (
	<Placeholder name="jira-software-resource-invalidator-cache-wrapper" fallback={null}>
		<LazyResourcesCacheWrapper {...props} />
	</Placeholder>
);
