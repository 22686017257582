import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import { Colors } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { PARENT_HIERARCHY_TYPE } from '@atlassian/jira-issue-type-hierarchies';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/mergeMap';
import { REFRESH_SOURCE_INLINE_CREATE } from '../../model/constants';
import type { Issue } from '../../model/issue/issue-types';
import { issueCreateFilteredV2, cardCreateFilteredOutInIPBoard } from '../../state/actions/flags';
import {
	type IssueCreateSuccessAction,
	ISSUE_CREATE_SUCCESS,
} from '../../state/actions/issue/create';
import { addIssueParent } from '../../state/actions/issue/parent';
import { workRefreshData } from '../../state/actions/work';
import { isCustomFiltersActive } from '../../state/selectors/filter/custom-filter-selectors';
import {
	getIsCMPBoard,
	getIsIncrementPlanningBoard,
	getIssueTypeInIPboard,
} from '../../state/selectors/software/software-selectors';
import { activeSprintsSelector } from '../../state/selectors/sprint/sprint-selectors';
import { workFilteredIssuesSelector } from '../../state/selectors/work/work-selectors';
import type { MiddlewareAPI, ActionsObservable, Action } from '../../state/types';

const transformNewIssues = (issues: Issue[]) =>
	issues.map((issue) => ({
		issueId: String(issue.id),
		issueKey: String(issue.key),
		issueTypeId: String(issue.typeId),
		createdIssueDetails: {
			fields: {
				status: {
					id: String(issue.statusId),
				},
				project: {
					id: String(issue.projectId),
				},
				issuetype: {
					// We do not have access to hierarchy level of the new issues,
					// but it is not used when creating via ICC, so we are hardcoding to 0
					hierarchyLevel: 0,
				},
			},
		},
	}));

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(ISSUE_CREATE_SUCCESS).mergeMap((action: IssueCreateSuccessAction) => {
		const currentIssues = action.payload.issues;
		const state = store.getState();
		const actions: Action[] = [];
		const isIPBoard = getIsIncrementPlanningBoard(state);
		const isCMPBoard = getIsCMPBoard(state);
		const filteredIssues = workFilteredIssuesSelector(state);
		const activeSprints = activeSprintsSelector(state);

		const createdAndFilteredIssues = currentIssues.filter(
			({ id }) => !filteredIssues.some((issue) => issue.id === id),
		);

		// don't show this flag here if custom filters are active,
		// we'll show it after the refiltering succeeds
		const shouldShowFlag = createdAndFilteredIssues.length && !isCustomFiltersActive(state);
		let hasShownFlag = false;
		if (shouldShowFlag) {
			if (isIPBoard) {
				actions.push(cardCreateFilteredOutInIPBoard());
			} else {
				actions.push(issueCreateFilteredV2(createdAndFilteredIssues.map(({ key }) => key)));
			}
			hasShownFlag = true;
		} else if (
			(activeSprints?.length ?? 0) > 1 &&
			!currentIssues.every((issue) => issue.sprintId)
		) {
			// show a flag to pick sprint for issues created if there are multiple active sprints and no sprint filter applied as its value will be preset otherwise
			actions.push(
				issueCreateFilteredV2(
					currentIssues.map(({ key }) => key),
					activeSprints,
				),
			);
			hasShownFlag = true;
		}

		if (isCMPBoard) {
			actions.push(
				workRefreshData(
					REFRESH_SOURCE_INLINE_CREATE,
					transformNewIssues(action.payload.issues),
					undefined,
					hasShownFlag,
				),
			);
		}

		// in the IP board, adding the newly created epic level issue to the issueParents
		if (isIPBoard) {
			const issue = currentIssues[0];
			const { typeId, projectId } = issue;
			const issueType = getIssueTypeInIPboard(state)(projectId, `${typeId}`);
			const color = issue.color ?? Colors.PURPLE;
			if (!isNil(issueType) && issueType?.hierarchyLevelType === PARENT_HIERARCHY_TYPE) {
				actions.push(
					addIssueParent({
						issue: {
							id: issue.id,
							key: issue.key,
							summary: issue.summary,
							issueType: {
								iconUrl: issueType.iconUrl,
								id: issueType.id,
								name: issueType.name,
							},
							color,
							projectId,
							flagged: issue?.isFlagged ?? false,
						},
					}),
				);
			}
		}

		return Observable.from(actions);
	});
