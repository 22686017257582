import type { ThemeAppearance } from '@atlaskit/lozenge';
import { statusCategories } from '@atlassian/jira-common-constants';
import { LOZENGE_APPEARANCE } from '../../constants';

/**
 * Appearance list - https://atlaskit.atlassian.com/packages/core/lozenge
 */
export const getLozengeAppearanceByStatus = (
	statusCategory?: statusCategories.StatusCategory,
): ThemeAppearance => {
	switch (statusCategories.categoryIdForStatusCategory(statusCategory)) {
		case 3:
			return LOZENGE_APPEARANCE.success;
		case 4:
			return LOZENGE_APPEARANCE.inprogress;
		default:
			return LOZENGE_APPEARANCE.default;
	}
};
