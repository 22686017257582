import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import get from 'lodash/get';
import { type Observable as ObservableType, Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log';
import type { CardTransitionEntities } from '../../../model/card-transition/card-transition-types';
import type { ColumnCreateGQLData, Column } from '../../../model/column/column-types';
import columnCreate from '../../../rest/column-create-graphql';
import { getCardTransitions, transformColumn } from '../../board-scope-graphql/transformer';

export type ColumnCreateData = {
	column: Column;
	columns: Column[];
	cardTransitions: CardTransitionEntities | null;
};

export const transform = (projectKey: string, data: ColumnCreateGQLData): ColumnCreateData => ({
	column: transformColumn(data.newColumn),
	columns: data.columns.map(transformColumn),
	cardTransitions: getCardTransitions(projectKey, data.columns.concat(data.newColumn)),
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	contextPath: string,
	boardId: string,
	columnName: string,
	projectKey: string,
): ObservableType<ColumnCreateData> =>
	columnCreate(contextPath, boardId, columnName)
		.flatMap((columnCreateData: ColumnCreateGQLData) =>
			Observable.of(transform(projectKey, columnCreateData)),
		)
		.catch((error) => {
			log.safeErrorWithoutCustomerData(
				'board.column.create.graphql.failure',
				'Failed to create column via GraphQl',
				{ message: get(error, ['message'], '') || get(error, [0, 'message'], '') },
			);
			return Observable.throw(error);
		});
