import { BEGIN, COMMIT, REVERT } from 'redux-optimistic-ui';
import uuid from 'uuid';
import type { OptimisticId } from '../../../model/software/software-types';
import flagMessages from '../../../view/flags/messages';
import { type FlagMeta, warningFlagMeta } from './flag-meta';

export type OptimisticUiMeta = {
	optimistic: {
		type: typeof BEGIN | typeof COMMIT | typeof REVERT;
		id: OptimisticId;
	};
};

export const optimisticUiRevertFlagId = 'OPTIMISTIC_REVERT_FLAG';

export const beginOptimisticUiMeta = (): OptimisticUiMeta => ({
	optimistic: {
		type: BEGIN,
		id: uuid.v4(),
	},
});

export const commitOptimisticUiMeta = (optimisticId: OptimisticId): OptimisticUiMeta => ({
	optimistic: {
		type: COMMIT,
		id: optimisticId,
	},
});

export const revertOptimisticUiMeta = (optimisticId: OptimisticId): OptimisticUiMeta => ({
	optimistic: {
		type: REVERT,
		id: optimisticId,
	},
});

export const revertOptimisticUiWithFlagMeta = (
	optimisticId: OptimisticId,
): OptimisticUiMeta & FlagMeta => ({
	optimistic: {
		type: REVERT,
		id: optimisticId,
	},
	...warningFlagMeta({
		id: optimisticUiRevertFlagId,
		titleMessage: flagMessages.genericTitle,
		descriptionMessage: flagMessages.genericDescription,
	}),
});
