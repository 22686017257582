export const EXPERIMENT_NAME = 'jsw_presentation_toolkit_experiment';
// 1000 milliseconds per second, 60 seconds per minute
export const ONE_MINUTE_IN_MS = 60 * 1000;

// 60 minutes per hour, 24 hours per day
export const ONE_DAY_IN_MS = ONE_MINUTE_IN_MS * 60 * 24;

export const STANDARD_EDITION_STAGING = 'ef35c2d0-782b-4630-892a-dc34b54f1a3e';
export const STANDARD_EDITION_PROD = '7b634a4b-3d3d-41e1-bdd1-a705c97bdbdb';
export const PREMIUM_EDITION_STAGING = '34e8a66a-709f-41c6-9ca7-39b79432a7f6';
export const PREMIUM_EDITION_PROD = 'cbf0a165-b123-498b-8ae0-ea287f543baf';
