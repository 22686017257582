import { useEffect } from 'react';
import { useFetch } from '@atlassian/jira-insights-fetch/src/controllers/use-fetch/main.tsx';
import {
	insightsSettingsLoaderAnalytics,
	insightsSettingsDefaults,
	GET_SETTINGS_ENDPOINT_TIMEOUT_WHEN_INSIGHTS_LOADING,
} from '../../common/constants';
import { withDefaults } from '../../common/utils/with-default';
import { fetchInsightsSettings } from '../../services/insights-settings';
import type { InsightsSettingsLoaderProps } from './types';

export const InsightsSettingsLoader = ({
	boardId,
	onSettingsLoaded,
}: InsightsSettingsLoaderProps) => {
	const [{ data: settingsResult, loading, fetched, aborted, hasError }, { doAbort }] = useFetch(
		{
			onFetch: (options) => fetchInsightsSettings({ boardId }, options),
			isAbortable: true,
			analyticsTracking: {
				actionSubject: `${insightsSettingsLoaderAnalytics.ACTION_SUBJECT}.${insightsSettingsLoaderAnalytics.ACTION_SUBJECT_ID}`,
				sampleRate: 100,
			},
			browserMetricsKey: `${insightsSettingsLoaderAnalytics.ACTION_SUBJECT}.${insightsSettingsLoaderAnalytics.ACTION_SUBJECT_ID}`,
			loggingKey: `${insightsSettingsLoaderAnalytics.ACTION_SUBJECT}.${insightsSettingsLoaderAnalytics.ACTION_SUBJECT_ID}`,
		},
		[boardId],
	);

	useEffect(() => {
		let timeout: NodeJS.Timeout;
		if (loading || !settingsResult) {
			onSettingsLoaded({ status: 'loading' });
			timeout = setTimeout(doAbort, GET_SETTINGS_ENDPOINT_TIMEOUT_WHEN_INSIGHTS_LOADING);
		}

		if (fetched && settingsResult && !hasError && !aborted) {
			onSettingsLoaded({
				status: 'success',
				data: withDefaults(settingsResult.context.board.settings),
			});
		}

		if (hasError || aborted) {
			onSettingsLoaded({ status: 'error', data: insightsSettingsDefaults });
		}
		return () => clearTimeout(timeout);
	}, [fetched, loading, aborted, settingsResult, hasError, onSettingsLoaded, doAbort]);

	return null;
};
