import { Subject } from 'rxjs/Subject';
import type { CacheInvalidationType } from '../../common/types';

// Standard observer pattern, when the board/backlog/roadmap is mounted and loaded cache,
// but cache is stale and is updated by live data, this will be triggered

const ResourcesCacheRefreshAnalytics = new Subject<CacheInvalidationType>();

export default ResourcesCacheRefreshAnalytics;

export const triggerCacheRefreshAnalytics = (origin: CacheInvalidationType) => {
	ResourcesCacheRefreshAnalytics.next(origin);
};
