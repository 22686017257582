import { splice } from 'icepick';
import type { Action } from '../../../../actions';
import { CARD_DELETE, type CardDeleteAction } from '../../../../actions/card';
import {
	COLUMN_DELETE_REQUEST,
	type ColumnDeleteRequestAction,
} from '../../../../actions/column/delete';
import { ADD_ISSUE_PARENT } from '../../../../actions/issue/parent';
import {
	WORK_DATA_SET,
	type WorkDataCriticalSetAction,
	type WorkDataSetAction,
	WORK_DATA_CRITICAL_SET,
} from '../../../../actions/work';
import type { IssueParentState } from './types';

export const boardIssueParentReducer = (
	state: IssueParentState = [],
	action: Action,
): IssueParentState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		const {
			payload: { issueParents },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as WorkDataSetAction | WorkDataCriticalSetAction;
		return issueParents.map((issue) => issue.id);
	}

	if (action.type === CARD_DELETE) {
		const {
			payload: { issueId },
		}: CardDeleteAction = action;

		const index = state.indexOf(issueId);
		return index < 0 ? state : splice(state, index, 1);
	}

	if (action.type === ADD_ISSUE_PARENT) {
		return [...state, action.payload?.issue.id];
	}

	if (action.type === COLUMN_DELETE_REQUEST) {
		const {
			payload: { closestColumn, issuesInColumn },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as ColumnDeleteRequestAction;
		if (!closestColumn) {
			const issueIdsInColumn = new Set(issuesInColumn.map((issue) => issue.id));
			return state.filter((id) => !issueIdsInColumn.has(id));
		}
	}

	return state;
};
