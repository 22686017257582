import { connect } from '@atlassian/jira-react-redux';
import { getColumnStatusMappingUrl } from '../../../../common/urls';
import {
	projectKeySelector,
	rapidViewIdSelector,
} from '../../../../state/selectors/software/software-selectors';
import type { State } from '../../../../state/types';
import ConfigureBoardItem from './view';

type StateProps = {
	url: string;
};

const mapStateToProps = (state: State): StateProps => ({
	url: getColumnStatusMappingUrl(projectKeySelector(state), rapidViewIdSelector(state)),
});

export default connect(mapStateToProps, {})(ConfigureBoardItem);
