import {
	SHOW_ISSUE_FLAG_WITH_COMMENT_MODAL,
	HIDE_ISSUE_FLAG_WITH_COMMENT_MODAL,
} from '../../../actions/issue/flag-with-comment-modal';
import type { Action } from '../../../types';
import type { FlagWithCommentModalState } from './types';

const defaultState = { isOpen: false } as const;

export const issueFlagWithCommentModalReducer = (
	state: FlagWithCommentModalState = defaultState,
	action: Action,
): FlagWithCommentModalState => {
	if (action.type === SHOW_ISSUE_FLAG_WITH_COMMENT_MODAL) {
		return {
			isOpen: true,
			issueId: action.payload.issueId,
		};
	}
	if (action.type === HIDE_ISSUE_FLAG_WITH_COMMENT_MODAL) {
		return { isOpen: false };
	}

	return state;
};
