import type { Dispatch } from 'redux';
import { ff } from '@atlassian/jira-feature-flagging';
import type { SwimlaneId } from '@atlassian/jira-platform-board-kit/src/common/types.tsx';
import { connect } from '@atlassian/jira-react-redux';
import type { IssueId } from '@atlassian/jira-software-board-common';
import { appendUnscheduledWorkSwimlaneIdSuffix } from '../../../../common/utils/increment-planning';
import { VIEW_SETTINGS_PANEL } from '../../../../model/panels/constants';
import { NO_SWIMLANE } from '../../../../model/swimlane/swimlane-modes';
import {
	setShowUnscheduledColumn,
	setShowOfftrackDependencyLines,
	setIssueIdsToShowDependencies,
} from '../../../../state/actions/board/menu';
import { closeInsightsPanel, togglePanel } from '../../../../state/actions/panels';
import { swimlanesCollapse, swimlanesExpand } from '../../../../state/actions/swimlane';
import { getViewSettingsPanel } from '../../../../state/selectors/panels';
import {
	getHierarchyNomenclature,
	getIsCMPBoard,
	rapidViewIdSelector,
} from '../../../../state/selectors/software/software-selectors';
import { getSwimlaneMode } from '../../../../state/selectors/swimlane/swimlane-mode-selectors';
import { platformSwimlaneIdsSelector } from '../../../../state/selectors/work/work-selectors';
import type { State } from '../../../../state/types';
import { type DispatchProps, type StateProps, ViewSettings } from './view';

export const mapStateToProps = (state: State): StateProps => {
	const hasSwimlanes = getSwimlaneMode(state) !== NO_SWIMLANE.id;
	const isCMPBoard = getIsCMPBoard(state);
	const isViewSettingPanelOpen = getViewSettingsPanel(state);

	return {
		rapidViewId: Number(rapidViewIdSelector(state)),
		swimlaneIds: platformSwimlaneIdsSelector(state),
		isClassic: isCMPBoard,
		hasSwimlanes,
		epicCustomName: getHierarchyNomenclature(state)?.firstLevelName,
		isViewSettingPanelOpen,
	};
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSwimlaneExpandAll: () => {
		dispatch(swimlanesExpand());
	},
	onSwimlaneCollapseAll: (swimlaneIds: SwimlaneId[], isIPBoard: boolean) => {
		// For the program board, we want to collapse all the swimlanes in the unplanned work column as well
		const swimlanesToCollapseIds: SwimlaneId[] =
			ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1') && isIPBoard
				? swimlaneIds.reduce<SwimlaneId[]>((acc, id) => {
						acc.push(id, appendUnscheduledWorkSwimlaneIdSuffix(id));
						return acc;
					}, [])
				: swimlaneIds;
		dispatch(swimlanesCollapse(swimlanesToCollapseIds));
	},
	onSetShowUnscheduledColumn: (isVisible: boolean) => {
		dispatch(setShowUnscheduledColumn(isVisible));
	},
	onSetShowOfftrackDependencyLines: (isVisible: boolean) => {
		dispatch(setShowOfftrackDependencyLines(isVisible));
	},
	onSetIssueIdsToShowDependencies: (issueIds: IssueId[]) => {
		dispatch(setIssueIdsToShowDependencies(issueIds));
	},
	onTogglePanel: () => {
		dispatch(togglePanel(VIEW_SETTINGS_PANEL));
		dispatch(closeInsightsPanel());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewSettings);
