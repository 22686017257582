import React, { useCallback, useMemo, memo } from 'react';
import noop from 'lodash/noop';
import { useIntl } from '@atlassian/jira-intl';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';
import type { RequestTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { RequestType, CheckboxSelectEntry } from '../../../common/types';
import ListFilter from '../../../common/ui/list';
import messages from './messages';

export type Props = {
	requestTypes: RequestType[];
	selectedRequestTypes?: RequestTypeId[];
	onChange: (ids: RequestTypeId[], attributes?: AnalyticsAttributes) => void;
	isNested?: boolean;
};

const RequestTypeFilter = ({
	requestTypes = [],
	selectedRequestTypes = [],
	onChange = noop,
	isNested = false,
}: Props) => {
	const { formatMessage } = useIntl();

	const values = useMemo(
		(): CheckboxSelectEntry[] =>
			requestTypes.map((requestType) => ({
				label: requestType.name,
				value: requestType.id,
				iconUrl: requestType.iconUrl,
			})),
		[requestTypes],
	);

	const onChangeCallback = useCallback(
		(entries: CheckboxSelectEntry[]) => {
			const analyticAttributes = { isNested };
			onChange(
				entries.map((entry) => entry.value),
				analyticAttributes,
			);
		},
		[onChange, isNested],
	);

	// early exit if we have only one option
	if (requestTypes.length <= 1) {
		return null;
	}

	return (
		<ListFilter
			label={formatMessage(messages.label)}
			values={values}
			selectedValues={selectedRequestTypes.map(String)}
			onChange={onChangeCallback}
			isNested={isNested}
		/>
	);
};

const RequestTypeFilterComponent = memo<Props>(RequestTypeFilter);

RequestTypeFilterComponent.displayName = 'RequestTypeFilter';
// @ts-expect-error - TS2339 - Property 'whyDidYouRender' does not exist on type 'NamedExoticComponent<Props>'.
RequestTypeFilterComponent.whyDidYouRender = true;

export default RequestTypeFilterComponent;
