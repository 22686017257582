import get from 'lodash/get';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { TEXT, ASSIGNEE, LABEL } from '../../../../model/filter/filter-types';
import {
	type DismissFlagAction,
	type ShowCreatedIssueIsFilteredFlagAction,
	type IssueCreateFilteredV2Action,
	FLAG_CREATED_ISSUE_IS_FILTERED,
} from '../../../actions/flags';
import type { State } from '../../../reducers/types';
import { isCustomFiltersActive } from '../../../selectors/filter/custom-filter-selectors';
import { getIsCustomBoardWithIcc } from '../../../selectors/inline-create/inline-create-selectors';
import { getSwimlaneMode } from '../../../selectors/swimlane/swimlane-mode-selectors';
import { getWorkFilters } from '../../../selectors/work/work-selectors';

// deprecated - will be replaced with ISSUE_CREATE_FILTERED_V2
export const flagDismissed =
	(eventPrefix: string) => (preState: State, state: State, action: DismissFlagAction) => {
		const flag = action.payload;

		if (flag.id === FLAG_CREATED_ISSUE_IS_FILTERED) {
			const issueId = get(flag, ['context', 'issueId']);

			return {
				name: `${eventPrefix}.filter.flag.new-issue-filtered.dismissed`,
				data: {
					issueId,
				},
			};
		}

		// Don't fire analytic events when any other kind of flag is dismissed
		return null;
	};

// deprecated - will be replaced with ISSUE_CREATE_FILTERED_V2
export const flagCreatedIssueIsFiltered =
	(eventPrefix: string) =>
	(preState: State, state: State, action: ShowCreatedIssueIsFilteredFlagAction) => {
		const issue = action.payload;
		const filterValues = getWorkFilters(state).values;

		// There is only ever at most 1 text filter
		const textFilter = get(filterValues, [TEXT, 'length'], 0) > 0;
		const numAssigneeFilters = get(filterValues, [ASSIGNEE, 'length'], 0);
		const numLabelFilters = get(filterValues, [LABEL, 'length'], 0);

		return {
			name: `${eventPrefix}.filter.flag.new-issue-filtered.shown`,
			data: {
				issueId: issue.id,
				textFilter,
				numAssigneeFilters,
				numLabelFilters,
			},
		};
	};

export const flagIssueCreateFilteredV2 =
	(eventPrefix: string) => (preState: State, state: State, action: IssueCreateFilteredV2Action) => {
		const issueKeys = action.payload.cardKeys;
		const filterValues = getWorkFilters(state).values;

		if (action.payload.filteredOnServer) {
			const isCustomBoardWithIcc = getIsCustomBoardWithIcc(state);
			const hasCustomFilters = isCustomFiltersActive(state);
			const swimlaneMode = getSwimlaneMode(state);

			getAnalyticsWebClientPromise().then((client) => {
				const payload = {
					action: 'filteredOnServer',
					actionSubject: 'newIssues',
					source: 'boardScreen',
					attributes: {
						isCustomBoardWithIcc,
						hasCustomFilters,
						swimlaneMode,
					},
				};
				client.getInstance().sendTrackEvent(payload);
			});

			return null;
		}

		// There is only ever at most 1 text filter
		const textFilter = get(filterValues, [TEXT, 'length'], 0) > 0;
		const numAssigneeFilters = get(filterValues, [ASSIGNEE, 'length'], 0);
		const numLabelFilters = get(filterValues, [LABEL, 'length'], 0);

		return {
			name: `${eventPrefix}.filter.flag.new-issue-filtered.shown`,
			data: {
				issueKeys,
				textFilter,
				numAssigneeFilters,
				numLabelFilters,
			},
		};
	};

export const flagIssueCreateMovedBetweenJqlSwimlanes = () => (preState: State, state: State) => {
	const isCustomBoardWithIcc = getIsCustomBoardWithIcc(state);
	const hasCustomFilters = isCustomFiltersActive(state);
	const swimlaneMode = getSwimlaneMode(state);

	getAnalyticsWebClientPromise().then((client) => {
		const payload = {
			action: 'movedBetweenSwimlanes',
			actionSubject: 'newIssues',
			source: 'boardScreen',
			attributes: {
				isCustomBoardWithIcc,
				hasCustomFilters,
				swimlaneMode,
			},
		};
		client.getInstance().sendTrackEvent(payload);
	});

	return null;
};
