import uuid from 'uuid';
import type { Action, BoundActions } from '@atlassian/react-sweet-state';
import type { StoreApi, State } from '../types';
import { initialState } from './initial-state';

const updateContainer =
	<TState extends State>(data: TState['data']): Action<State> =>
	({ setState, getState }: StoreApi<State>) => {
		const state = getState();

		const nextState = {
			info: state.info,
			data: {
				...state.data,
				...data,
			},
		};

		setState(nextState);
	};

const toggleProp =
	<TState extends State>(propsName: keyof TState['data']): Action<State> =>
	({ setState, getState }: StoreApi<State>) => {
		const state = getState();

		const nextState = {
			info: state.info,
			data: {
				...state.data,
				[propsName]: !state.data[propsName],
			},
		};

		setState(nextState);
	};

const setInfo =
	<TState extends State>(data: Partial<TState['info']>): Action<State> =>
	({ setState, getState }: StoreApi<State>) => {
		const state = getState();

		const nextState = {
			data: state.data,
			info: {
				...state.info,
				...data,
			},
		};

		setState(nextState);
	};

const refreshUniqueId =
	(): Action<State> =>
	({ setState, getState }: StoreApi<State>) => {
		const state = getState();

		const nextState = {
			data: state.data,
			info: {
				...state.info,
				uniqueId: uuid.v4(),
			},
		};

		setState(nextState);
	};

const cleanState =
	(): Action<State> =>
	({ setState }: StoreApi<State>) => {
		setState(initialState);
	};

const updateIsDeepLinking =
	(isDeepLinking: boolean): Action<State> =>
	({ setState, getState }: StoreApi<State>) => {
		const state = getState();

		const nextState = {
			data: state.data,
			info: {
				...state.info,
				isDeepLinking,
			},
		};

		setState(nextState);
	};

export const actions = {
	setInfo,
	cleanState,
	toggleProp,
	updateContainer,
	refreshUniqueId,
	updateIsDeepLinking,
} as const;

export type Actions = typeof actions;

export type BoundedActions = BoundActions<State, Actions>;
