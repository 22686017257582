import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
// eslint-disable-next-line jira/import-whitelist
import {
	prefetchApiReadyMark,
	prefetchApiStartMark,
} from '@atlassian/jira-providers-spa-apdex-analytics/src/marks';
import { createResourceError } from '@atlassian/jira-router-resources-utils/src/utils/create-resource-error/index.tsx';
import type { BoardScopeGQL } from '@atlassian/jira-software-board-fetch-scope-critical/src/types';
// eslint-disable-next-line jira/import-whitelist
import { SwagError } from '@atlassian/jira-software-swag/src/common/utils/error/index.tsx';

import { checkAuthentication } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication';

import { checkLicense } from '@atlassian/jira-spa-apps-common/src/interceptors/license';
import type { RouterDataContext, ResourceStoreContext } from '@atlassian/react-resource-router';
import { fetchBoardData } from '../../data/index-cdf';

export const getEarlyPreload = (): Promise<BoardScopeGQL> | null => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (window.__JSW_BOARD_DATA_PROMISE__) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const earlyPreloadPromise = window.__JSW_BOARD_DATA_PROMISE__;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.__JSW_BOARD_DATA_PROMISE__ = null;
		return earlyPreloadPromise.then((preload) => {
			if ((preload.errors && preload.errors.length) || !preload.data?.boardScope) {
				throw new SwagError({
					graphQLErrors: preload.errors,
					errorMessage: 'Board critical early fetch failed',
				});
			}

			return preload.data.boardScope;
		});
	}

	return null;
};

export const fetchData = (baseUrl: string, boardId?: string | null): Promise<BoardScopeGQL> => {
	const earlyPreload = getEarlyPreload();
	if (earlyPreload != null) {
		return earlyPreload;
	}

	return fetchBoardData(baseUrl, boardId || '');
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (
	{ match }: RouterDataContext,
	{ tenantContext }: ResourceStoreContext,
): Promise<BoardScopeGQL> => {
	if (!checkAuthentication(tenantContext) || !match.params.boardId) {
		throw createResourceError({
			component: 'spa.view.routes.next-gen.software-boards-data.getData',
			message: 'Current user is not authenticated',
			name: 'AuthenticationError',
			skipSentry: true,
		});
	}

	if (!checkLicense(tenantContext, 'software')) {
		throw createResourceError({
			component: 'spa.view.routes.next-gen.software-boards-data.getData',
			message: 'License check failed',
			name: 'AuthenticationError',
			skipSentry: true,
		});
	}

	const { baseUrl } = tenantContext;
	try {
		setMark(prefetchApiStartMark);
		const data = await fetchData(baseUrl, match.params.boardId);
		setMark(prefetchApiReadyMark);
		return data;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		throw createResourceError(
			{
				component: 'spa.view.routes.next-gen.software-boards.getData',
				message: 'An error was thrown while prefetching board data',
				name: 'ResourceError',
			},
			error,
		);
	}
};
