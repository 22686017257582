import { createStore, createHook } from '@atlassian/react-sweet-state';
import actions, { type Actions } from './actions';
import { initialState } from './config';
import type { State } from './types';

export const Store = createStore<State, Actions>({
	initialState,
	actions,
});

export const useCardMediaActions = createHook(Store, {
	selector: null,
});
