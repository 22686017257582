import React from 'react';
import WarningIconNew from '@atlaskit/icon/core/warning';
import WarningIconOld from '@atlaskit/icon/glyph/warning';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import AutoDismissFlag from '@atlassian/jira-flags/src/common/ui/components/ak-auto-dismiss-flag';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueKey } from '../../../model/issue/issue-types';
import flagMessages from '../messages';

type Params = {
	id: string;
	issueKey: IssueKey;
} & Intl & {
		onPrimaryActionClick: (flagId: string) => void;
	};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ id, issueKey, intl, onPrimaryActionClick }: Params) => {
	const { formatMessage } = intl;

	return (
		<AutoDismissFlag
			id={id}
			key={id}
			title={formatMessage(flagMessages.createdIssueIsFilteredTitle, { issueKey })}
			description={formatMessage(flagMessages.createdIssueIsFilteredDescription)}
			icon={
				fg('tnk-1613-jira-visual-refresh-jsw-board-icons-2') ? (
					<WarningIconNew
						color={token('color.icon.warning', colors.Y300)}
						spacing="spacious"
						LEGACY_fallbackIcon={WarningIconOld}
						LEGACY_primaryColor={token('color.icon.warning', colors.Y300)}
						label={formatMessage(flagMessages.warningLabel)}
					/>
				) : (
					<WarningIconOld
						primaryColor={token('color.icon.warning', colors.Y300)}
						label={formatMessage(flagMessages.warningLabel)}
					/>
				)
			}
			actions={[
				{
					content: formatMessage(flagMessages.createdIssueIsFilteredLink),
					onClick: () => {
						onPrimaryActionClick(id);
					},
				},
			]}
		/>
	);
};
