import React from 'react';
import kebabCase from 'lodash/kebabCase';
import { ErrorMessage } from '@atlaskit/form';
import { Box, xcss, Inline, Text } from '@atlaskit/primitives';
import Select, { type ReactSelectProps } from '@atlaskit/select';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { ViewAs } from '../../types/constant.tsx';
import type { IssueId } from '../../types/state.tsx';
import { isViewSettingsPanelAndTailoredViewExperimentEnabled } from '../../utils';

export type IssueOption = {
	value: IssueId;
	label: string;
	iconUrl?: string;
};

export type Props = {
	title: string;
	titleIcon?: React.ReactNode;
	testId?: string;
	viewAs?: (typeof ViewAs)[keyof typeof ViewAs];
	options: IssueOption[];
} & ReactSelectProps<IssueOption>;

export const ViewSettingsSelect = ({
	title,
	testId,
	viewAs = ViewAs.POPUP,
	validationErrorMessage,
	titleIcon,
	...restProps
}: Props) => {
	const id = kebabCase(`jira-software-view-settings-${title}`);
	const errorId = kebabCase(`jira-software-view-settings-${title}-error`);

	return (
		<Box
			xcss={
				viewAs === ViewAs.PANEL && isViewSettingsPanelAndTailoredViewExperimentEnabled()
					? panelContainerStyles
					: popupContainerStyles
			}
			testId={testId}
		>
			<Box xcss={labelWrapperStyles}>
				<Inline alignBlock="center">
					<label htmlFor={id}>{title}</label>
					{titleIcon}
				</Inline>
			</Box>
			<Select
				inputId={id}
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				menuPortalTarget={document.body}
				styles={{
					menuPortal: (base) => ({ ...base, zIndex: layers.layer }),
				}}
				aria-invalid={!!validationErrorMessage}
				aria-errormessage={errorId}
				{...restProps}
			/>
			{validationErrorMessage && (
				<Text as="span" id={errorId}>
					<ErrorMessage>{validationErrorMessage}</ErrorMessage>
				</Text>
			)}
		</Box>
	);
};

const labelWrapperStyles = xcss({
	marginBottom: 'space.050',
});

const popupContainerStyles = xcss({
	marginBlock: 'space.200',
	marginInline: 'space.200',
	minWidth: '200px',
});

const panelContainerStyles = xcss({
	marginBlock: 'space.200',
	marginInline: 'auto',
	minWidth: '200px',
});
