import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { OnboardingNudgeErrorBoundary } from '@atlassian/jira-onboarding-quickstart-core/src/common/ui/onboarding-nudge-error-boundary';
import Placeholder from '@atlassian/jira-placeholder';
import {
	BOARD_IN_PROGRESS_COLUMN_NUDGE_ID,
	ERROR_BOUNDARY_ID_KANBAN_TOUR_NUDGE,
} from '../../../common/constants';
import type { WrapperProps } from '../../../common/types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const BoardInProgressColumnNudgeLazy = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-software-kanban-onboarding-board-in-progress-column-nudge" */ './index'
		).then((exportedModule) => exportedModule.BoardInProgressNudge),
	{
		ssr: false,
	},
);

export const BoardInProgressColumnNudgeAsync = ({ children, ...restProps }: WrapperProps) => (
	<OnboardingNudgeErrorBoundary
		id={ERROR_BOUNDARY_ID_KANBAN_TOUR_NUDGE}
		fallback={children}
		attributes={{
			nudgeId: BOARD_IN_PROGRESS_COLUMN_NUDGE_ID,
		}}
	>
		<Placeholder
			name="software-kanban-onboarding-board-in-progress-column-nudge"
			fallback={children}
		>
			<BoardInProgressColumnNudgeLazy {...restProps}>{children}</BoardInProgressColumnNudgeLazy>
		</Placeholder>
	</OnboardingNudgeErrorBoundary>
);

BoardInProgressColumnNudgeAsync.displayName = 'BoardInProgressColumnNudgeAsync';
