import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import { fg } from '@atlassian/jira-feature-gating';
import { addToSprint } from '../../../rest/sprint/add-to-sprint';
import { removeFromSprintService } from '../../../services/remove-from-sprint';
import { makeServiceContext } from '../../../services/service-context';
import {
	showUndoFlag,
	UNDO_FLAG_PERFORM_COMMIT,
	UNDO_FLAG_PERFORM_UNDO,
	SHOW_UNDO_FLAG,
} from '../../../state/actions/flags';
import {
	addToSprintSuccess,
	addToSprintFailure,
} from '../../../state/actions/sprints/add-to-sprint';
import {
	type RemoveFromSprintRequestAction,
	removeFromSprintSuccess,
	removeFromSprintFailure,
	REMOVE_FROM_SPRINT_REQUEST,
} from '../../../state/actions/sprints/remove-from-sprint';
import { BEFORE_DISPOSE } from '../../../state/actions/work';
import { getIssueById } from '../../../state/selectors/issue/issue-selectors';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../../state/types';
import { messages } from './messages';

function onBeforeunload(e: BeforeUnloadEvent) {
	e.preventDefault(); // Firefox
	// The message returned in `beforeunload` handler doesn't have any effect in modern browsers
	const message = '';
	e.returnValue = message; // Chrome
	return message; // IE
}

export function removeFromSprintEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> {
	return action$
		.ofType(REMOVE_FROM_SPRINT_REQUEST)
		.flatMap((action: RemoveFromSprintRequestAction) => {
			const state = store.getState();
			const context = makeServiceContext(state);

			const issue = getIssueById(state, action.payload.issueId);

			// Ignore if issue missing, could be some sort of race condition from real-time updates
			if (!issue) {
				return Observable.empty<Action>();
			}

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('beforeunload', onBeforeunload);

			const undoFlag = showUndoFlag({
				id: action.meta.optimistic.id,
				titleMessage: messages.undoMessageTitle,
				descriptionMessage: messages.undoMessageDescription,
				context: {
					issueKey: issue.key,
				},
				links: [
					{
						content: messages.undoMessageViewIssue,
						href: `/browse/${issue.key}`,
					},
				],
			});

			const optimisticId = action.meta.optimistic.id;
			const undoFlagId = undoFlag.meta.flag.id;
			const issueId = issue.id;
			const issueKey = issue.key;
			const sprintId = issue.sprintId;

			const remove$ = action$
				.ofType(SHOW_UNDO_FLAG)
				.take(1)
				.flatMap(() => {
					if (fg('jira-removefromsprint-nodelay')) {
						return removeFromSprintService(context, { issueKey })
							.map(() => removeFromSprintSuccess(issueId, issueKey, optimisticId))
							.catch((_err) => Observable.of(removeFromSprintFailure(issueKey, optimisticId)));
					}
					return Observable.empty<Action>();
				});

			const finish$ = action$
				.ofType(UNDO_FLAG_PERFORM_COMMIT, UNDO_FLAG_PERFORM_UNDO, BEFORE_DISPOSE)
				.filter(
					(commitAction) =>
						commitAction.type === BEFORE_DISPOSE || commitAction.payload.flagId === undoFlagId,
				)
				.take(1)
				.flatMap((undoAction) => {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					window.removeEventListener('beforeunload', onBeforeunload);

					if (undoAction.type === UNDO_FLAG_PERFORM_UNDO) {
						if (fg('jira-removefromsprint-nodelay')) {
							const sprintIdNumber = Number.parseInt(sprintId, 10);
							const boardId = Number.parseInt(context.boardId, 10);
							return addToSprint(issueId.toString(), sprintIdNumber, boardId)
								.map(() => addToSprintSuccess(issueId.toString(), sprintIdNumber, optimisticId))
								.catch((_err) =>
									Observable.of(
										addToSprintFailure(issueId.toString(), sprintIdNumber, optimisticId),
									),
								);
						}
						return Observable.of(removeFromSprintFailure(issueKey, optimisticId));
					}

					if (!fg('jira-removefromsprint-nodelay')) {
						return removeFromSprintService(context, { issueKey })
							.map(() => removeFromSprintSuccess(issueId, issueKey, optimisticId))
							.catch((_err) => Observable.of(removeFromSprintFailure(issueKey, optimisticId)));
					}
					return Observable.empty<Action>();
				});

			return Observable.merge(remove$, Observable.of(undoFlag), finish$);
		});
}
