import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import type { BaseFlagProps } from './common/ui/types';
import { IssueLimitFlags } from './ui';

type Props = BaseFlagProps;

const App = (props: Props) => (
	<JSErrorBoundary
		id="app"
		packageName="jiraSoftwareIssueLimitFlags"
		teamName="a4t-tanuki"
		fallback="flag"
	>
		<IssueLimitFlags {...props} />
	</JSErrorBoundary>
);

export default App;
