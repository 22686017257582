import type { StoreApi, QueryGithubOnboardingRef } from './types';

export const actions = {
	setIsAutoOpened:
		(isAutoOpened: boolean) =>
		({ setState }: StoreApi) => {
			setState({ isAutoOpened });
		},
	setIsNbtToggleButtonVisible:
		(isNbtToggleButtonVisible: boolean) =>
		({ setState }: StoreApi) => {
			setState({ isNbtToggleButtonVisible });
		},
	setIsOnlyCurrentUserSelected:
		(isOnlyCurrentUserSelected: boolean) =>
		({ setState }: StoreApi) => {
			if (!isOnlyCurrentUserSelected) {
				setState({ isOnlyCurrentUserSelected, isAutoOpened: false, isHiddenByUser: false });
			} else {
				setState({ isOnlyCurrentUserSelected });
			}
		},
	setQueryGithubOnboardingRef:
		(queryGithubOnboardingRef: QueryGithubOnboardingRef) =>
		({ getState, setState }: StoreApi) => {
			if (getState().queryGithubOnboardingRef == null) {
				setState({ queryGithubOnboardingRef });
			}
		},

	onViewSettingsSpotlightInit:
		() =>
		({ setState }: StoreApi) => {
			setState({ hasViewedViewSettingsSpotlight: false });
		},

	onViewSettingsSpotlightStart:
		() =>
		({ setState }: StoreApi) => {
			setState({ activeViewSettingsSpotlight: 0 });
		},

	onViewSettingsSpotlightNext:
		() =>
		({ setState }: StoreApi) => {
			setState({
				activeViewSettingsSpotlight: 1,
			});
		},

	onViewSettingsSpotlightEnd:
		() =>
		({ setState }: StoreApi) => {
			setState({
				hasViewedViewSettingsSpotlight: true,
				activeViewSettingsSpotlight: null,
			});
		},

	setOpenViewSettings:
		(openViewSettingsCallback: () => void) =>
		({ getState, setState }: StoreApi) => {
			if (!getState().openViewSettings) {
				setState({
					openViewSettings: openViewSettingsCallback,
				});
			}
		},

	setViewSettingsTogglePulses:
		(viewSettingsTogglePulses: boolean) =>
		({ setState }: StoreApi) => {
			setState({ viewSettingsTogglePulses });
		},

	setIsHiddenByUser:
		(isHiddenByUser: boolean) =>
		({ setState }: StoreApi) => {
			setState({
				isHiddenByUser,
			});
		},
} as const;

export type Actions = typeof actions;
