import React, { Component } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { miniMapMapHeight as mapHeight } from '../../../../common/constants/styles';

type Props = {
	innerOffset: number;
	innerWidth: number;
	onScroll: (newOffset: number) => void;
	onScrollAttempted: (scrollChanged: boolean) => void;
};

type State = {
	isDragging: boolean;
	mouseStart: number | null;
	initialOffset: number;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InnerViewStyleControl = styled.div<{ innerWidth: string | number; isDragging: boolean }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => ({
		top: token('space.negative.025', '-2px'),
		left: token('space.negative.025', '-2px'),
		position: 'absolute',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		border: `2px solid ${token('color.border.brand', colors.B100)}`,
		borderRadius: '3px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `${props.innerWidth}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		height: `${mapHeight}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderColor: props.isDragging
			? // This looks different in dark mode but the colors used match between none/light/dark
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.border.focused', colors.B200)
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.border.brand', colors.B100),
		cursor: 'move',
	}),
);

type InnerViewStyleProps = {
	innerWidth: string | number;
	isDragging: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InnerViewStyleExperiment = styled2.div<InnerViewStyleProps>({
	top: `${token('space.negative.025', '-2px')}`,
	left: `${token('space.negative.025', '-2px')}`,
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	border: `2px solid ${token('color.border.brand', colors.B100)}`,
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: `${({ innerWidth }: InnerViewStyleProps) => innerWidth}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${mapHeight}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderColor: ({ isDragging }: InnerViewStyleProps) =>
		isDragging
			? // This looks different in dark mode but the colors used match between none/light/dark
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.border.focused', colors.B200)
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.border.brand', colors.B100),
	cursor: 'move',
});

const InnerViewStyle = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	InnerViewStyleExperiment,
	InnerViewStyleControl,
);

// eslint-disable-next-line jira/react/no-class-components
export default class MinimapInnerView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			isDragging: false,
			mouseStart: null,
			initialOffset: 0,
		};
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (this.state.isDragging && !prevState.isDragging) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.addEventListener('mousemove', this.onMouseMove);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.addEventListener('mouseup', this.onMouseUp);
		} else if (!this.state.isDragging && !prevState.isDragging) {
			this.cleanupListeners();
		}
		this.updateInnerViewPosition();
	}

	componentWillUnmount() {
		this.cleanupListeners();
	}

	onMouseDown = (e: MouseEvent | React.MouseEvent) => {
		if (e.button === 0) {
			this.setState({
				isDragging: true,
				mouseStart: e.clientX,
				initialOffset: this.props.innerOffset,
			});
		}
	};

	onMouseUp = () => {
		// Because we have two mouseup event handlers, this can be fired twice
		// if the mouseup is over the minimap square.
		if (this.state.isDragging) {
			const scrollChanged = this.props.innerOffset !== this.state.initialOffset;
			this.props.onScrollAttempted(scrollChanged);

			this.setState({ isDragging: false });
		}
	};

	onMouseMove = (e: MouseEvent | React.MouseEvent) => {
		if (this.state.isDragging && this.state.mouseStart !== null) {
			const mouseCurrent = e.clientX;
			this.props.onScroll(this.state.initialOffset + mouseCurrent - this.state.mouseStart);
		}
	};

	setElement = (element: HTMLElement) => {
		this.element = element;
		this.updateInnerViewPosition();
	};

	updateInnerViewPosition = () => {
		if (this.element) {
			this.element.style.marginLeft = `${this.props.innerOffset}px`;
		}
	};

	cleanupListeners() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.removeEventListener('mousemove', this.onMouseMove);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.removeEventListener('mouseup', this.onMouseUp);
	}

	element: HTMLElement | null = null;

	render() {
		return (
			<InnerViewStyle
				data-testid="platform-board-kit.ui.minimap.inner-view"
				innerRef={this.setElement}
				onMouseDown={this.onMouseDown}
				onMouseUp={this.onMouseUp}
				onMouseMove={this.onMouseMove}
				isDragging={this.state.isDragging}
				innerWidth={this.props.innerWidth}
			/>
		);
	}
}
