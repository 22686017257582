import React, { useContext, createContext } from 'react';
import type { Action, AnyAction, Dispatch, Store } from 'redux';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/shim/with-selector';
import { useEvent } from '@atlassian/jira-software-react-use-event';

export interface ProviderProps<State, Actions extends Action = AnyAction> {
	/**
	 * The single Redux store in your application.
	 */
	store: Store<State, Actions>;
	children: React.ReactNode;
}

export type EqualityFn<T> = (a: T, b: T) => boolean;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const refEquality: EqualityFn<any> = (a, b) => a === b;

interface JiraSoftwareStoreResult<State, Actions extends Action = AnyAction> {
	/**
	 * A provider to wrap your app to gain access to the hooks
	 *
	 * @returns {JSX.Element} the Context Provider
	 *
	 * @example
	 *
	 * import React from 'react'
	 * import { createStore } from 'redux'
	 * import { createJiraSoftwareStoreContext } from '@atlassian/jira-software-redux-hooks'
	 *
	 * const { Provider } = createJiraSoftwareStoreContext()
	 *
	 * export const ExampleComponent = () => {
	 *   const store = createStore()
	 *   return (
	 *      <Provider store={store}>
	 *          <App />
	 *      </Provider>
	 *   )
	 * }
	 */
	Provider: (props: ProviderProps<State, Actions>) => JSX.Element;
	/**
	 * A hook to access the redux store.
	 *
	 * @returns {any} the redux store
	 *
	 * @example
	 *
	 * import React from 'react'
	 * import { createJiraSoftwareStoreContext } from '@atlassian/jira-software-redux-hooks'
	 *
	 * const { useStore } = createJiraSoftwareStoreContext()
	 *
	 * export const ExampleComponent = () => {
	 *   const store = useStore()
	 *   return <div>{store.getState()}</div>
	 * }
	 */
	useStore: () => Store<State, Actions>;
	/**
	 * A hook to access the redux store's state. This hook takes a selector function
	 * as an argument. The selector is called with the store state.
	 *
	 * This hook takes an optional equality comparison function as the second parameter
	 * that allows you to customize the way the selected state is compared to determine
	 * whether the component needs to be re-rendered.
	 *
	 * @param {Function} selector the selector function
	 * @param {Function=} equalityFn the function that will be used to determine equality
	 *
	 * @returns {any} the selected state
	 *
	 * @example
	 *
	 * import React from 'react'
	 * import { createJiraSoftwareStoreContext } from '@atlassian/jira-software-redux-hooks'
	 *
	 * const { useSelector } = createJiraSoftwareStoreContext()
	 *
	 * export const CounterComponent = () => {
	 *   const counter = useSelector(state => state.counter)
	 *   return <div>{counter}</div>
	 * }
	 */
	useSelector: <Selected>(
		selector: (state: State) => Selected,
		equalityFn?: EqualityFn<Selected>,
	) => Selected;
	/**
	 * A hook to access the redux `dispatch` function.
	 *
	 * @returns {any|function} redux store's `dispatch` function
	 *
	 * @example
	 *
	 * import React, { useCallback } from 'react'
	 * import { createJiraSoftwareStoreContext } from '@atlassian/jira-software-redux-hooks'
	 *
	 * const { useDispatch } = createJiraSoftwareStoreContext()
	 *
	 * export const CounterComponent = ({ value }) => {
	 *   const dispatch = useDispatch()
	 *   const increaseCounter = useCallback(() => dispatch({ type: 'increase-counter' }), [])
	 *   return (
	 *     <div>
	 *       <span>{value}</span>
	 *       <button onClick={increaseCounter}>Increase counter</button>
	 *     </div>
	 *   )
	 * }
	 */
	useDispatch: () => Dispatch<Actions>;
	/**
	 * A hook to create a dispatcher with a stable reference. This uses
	 * useEvent internally. It's unsafe to call the function returned during
	 * render, but the `actionCreator` callback may capture context from the
	 * component without worrying about the result changing its reference.
	 *
	 * The returned callback will always have the same referential identity,
	 * while always referring to the latest arguments. This can improve
	 * performance of redux applications by preventing re-renders.
	 *
	 * @example
	 *
	 * import React from 'react'
	 *
	 * const { useActionCreator } = createJiraSoftwareStoreContext()
	 *
	 * export const CounterComponent = ({ value }) => {
	 *   const increaseCounter = useActionCreator(() => ({
	 *     type: 'INCREASE',
	 *     payload: value
	 *     // `value` IS CAPTURED, BUT `increaseCounter` NEVER CHANGES
	 *   }))
	 *   return (
	 *     <div>
	 *       <span>{value}</span>
	 *       <button onClick={increaseCounter}>Increase counter</button>
	 *     </div>
	 *   )
	 * }
	 */
	useActionCreator: <Args extends Array<unknown>>(
		actionCreator: (...args: Args) => Actions,
	) => (...args: Args) => void;
}

export function createJiraSoftwareStoreContext<
	State,
	Actions extends Action = AnyAction,
>(): JiraSoftwareStoreResult<State, Actions> {
	const ctx = createContext<Store<State, Actions> | null>(null);

	const useStore = (): Store<State, Actions> => {
		const store = useContext(ctx);
		if (!store) {
			throw new Error('useStore called outside of redux JiraSoftwareStoreContext tree');
		}
		return store;
	};

	function useSelector<Selected>(
		selector: (state: State) => Selected,
		equalityFn: EqualityFn<Selected> = refEquality,
	): Selected {
		const store = useStore();

		const result = useSyncExternalStoreWithSelector(
			store.subscribe,
			store.getState,
			// Server state probably wont exist but we can use store.getState in case it does work.
			store.getState,
			selector,
			equalityFn,
		);

		return result;
	}

	function useDispatch() {
		const store = useStore();
		return store.dispatch;
	}

	function useActionCreator<Args extends Array<unknown>>(
		actionCreator: (...args: Args) => Actions,
	): (...args: Args) => void {
		const dispatch = useDispatch();

		return useEvent((...args) => {
			const action = actionCreator(...args);
			dispatch(action);
		});
	}

	function ReduxProvider(props: ProviderProps<State, Actions>) {
		return <ctx.Provider value={props.store}>{props.children}</ctx.Provider>;
	}

	return { Provider: ReduxProvider, useSelector, useStore, useDispatch, useActionCreator };
}
