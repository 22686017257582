import { useState, useCallback } from 'react';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';

const usePopupControllerV1 = (defaultState = false) => {
	const [isOpen, setIsOpen] = useState(defaultState);

	const togglePopup = () => {
		setIsOpen((prev) => !prev);
	};

	const closePopup = useCallback(() => setIsOpen(false), []);

	return [isOpen, togglePopup, closePopup] as const;
};

/** convenience boolean hook for controlling @atlaskit/popup
 * https://atlassian.design/components/popup/
 */
const usePopupControllerV2 = (defaultState = false) => {
	const [isOpen, setIsOpen] = useState(defaultState);

	const togglePopup = useCallback(() => {
		setIsOpen((pendingIsOpen) => !pendingIsOpen);
	}, [setIsOpen]);

	const closePopup = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	return [isOpen, togglePopup, closePopup] as const;
};

// fixing this component while implementing a feature
export const usePopupController = functionWithCondition(
	() => fg('jsw_nav4_new_sprint_controls_in_boards_blu-3256'),
	usePopupControllerV2,
	usePopupControllerV1,
);
