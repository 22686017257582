import { COLUMN_DELETE, COLUMN_DELETE_MODAL_CLOSE } from '../../../../actions/column/delete';
import type { Action } from '../../../../types';
import type { DeleteModalState } from './types';

export const columnDeleteModalReducer = (
	state: DeleteModalState = { isOpen: false },
	action: Action,
): DeleteModalState => {
	if (action.type === COLUMN_DELETE) {
		const { columnId } = action.payload;
		return { isOpen: true, columnId };
	}

	if (action.type === COLUMN_DELETE_MODAL_CLOSE) {
		return { isOpen: false };
	}

	return state;
};
