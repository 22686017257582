import React, { Component, type ReactNode } from 'react';
import memoizeOne from 'memoize-one';
import { useBoardScroll } from '../../controllers/board-scroll';
import type { OnHorizontalScroll } from '../../controllers/board-scroll/types';
import { minScalingFactor, showMapRatio, maxMapWidth } from './constants';
import MinimapDumb from './view';

type Props = {
	children: ReactNode;
	scrollWidth: number;
	scrollLeft: number;
	clientWidth: number;
	setHorizontalScroll: OnHorizontalScroll;
	onScrollAttempted: (scrollChanged: boolean) => void;
	onShow: () => void;
};

type State = {
	isShowing: boolean;
};

const shouldShowMap = ({ scrollWidth, clientWidth }: Partial<Props>): boolean =>
	// @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
	scrollWidth / clientWidth > showMapRatio;

// eslint-disable-next-line jira/react/no-class-components
export class Minimap extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		const isShowing = shouldShowMap(props);
		if (isShowing) {
			this.props.onShow();
		}
		this.state = {
			isShowing,
		};
	}

	UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
		const { isShowing } = this.state;
		const shouldShow = shouldShowMap(nextProps);
		if (shouldShow !== isShowing) {
			if (shouldShow) {
				this.props.onShow();
			}
			this.setState({ isShowing: shouldShow });
		}
	};

	onScroll = (newOffset: number) => {
		const scalingFactor = this.getScalingFactor(this.props.scrollWidth);
		this.props.setHorizontalScroll(newOffset * scalingFactor);
	};

	getScalingFactor = memoizeOne((scrollWidth: number) => {
		const maxSizeScalingFactor = scrollWidth / maxMapWidth;
		return Math.max(minScalingFactor, maxSizeScalingFactor);
	});

	getDimensions() {
		const scalingFactor = this.getScalingFactor(this.props.scrollWidth);
		return {
			outerWidth: this.props.scrollWidth / scalingFactor,
			innerWidth: this.props.clientWidth / scalingFactor,
			innerOffset: this.props.scrollLeft / scalingFactor,
		};
	}

	render() {
		if (!this.state.isShowing) {
			return null;
		}

		const { outerWidth, innerWidth, innerOffset } = this.getDimensions();

		return (
			<MinimapDumb
				onScrollAttempted={this.props.onScrollAttempted}
				onScroll={this.onScroll}
				outerWidth={outerWidth}
				innerWidth={innerWidth}
				innerOffset={innerOffset}
			>
				{this.props.children}
			</MinimapDumb>
		);
	}
}

type OwnProps = {
	children: ReactNode;
	onScrollAttempted: (scrollChanged: boolean) => void;
	onShow: () => void;
};

const MinimapMain = (props: OwnProps) => {
	const [{ clientWidth, scrollLeft, scrollWidth }, { onHorizontalScroll }] = useBoardScroll();

	return (
		<Minimap
			clientWidth={clientWidth}
			scrollLeft={scrollLeft}
			scrollWidth={scrollWidth}
			setHorizontalScroll={onHorizontalScroll}
			{...props}
		/>
	);
};

export default MinimapMain;
