import React, {
	useCallback,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
	type ReactElement,
} from 'react';
import debounce from 'lodash/debounce';
import { useUID } from 'react-uid';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { IconButton } from '@atlaskit/button/new';
import CloseIcon from '@atlaskit/icon/core/migration/close--editor-close';
import { ExitingPersistence, FadeIn } from '@atlaskit/motion';
import { Box, Stack, Text, xcss } from '@atlaskit/primitives';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { FormattedMessage } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { ASSIGNEE } from '@atlassian/jira-software-filters/src/common/constants';
import { PACKAGE_NAME } from '../../../../../model/constants';
import { useBoardDispatch, useBoardSelector } from '../../../../../state';
import { setAllFilters } from '../../../../../state/actions/filter';
import { unassignedWorkFilterSelector } from '../../../../../state/selectors/unassigned-filter-cta/unassigned-filter-cta-selector';
import { useShouldShowUnassignedFilterCTA } from '../../../../../state/state-hooks/unassigned-filter-cta';
import messages from '../messages';
import { useDismissal } from '../use-dismissal';
import { useResizeObserver } from '../use-resize-observer';

const CTA_DEFAULT_HEIGHT = 126;

type UnassignedFilterCTAProps = {
	children?: (ctaHeight: number) => ReactElement;
};

export const UnassignedFilterCTA = ({ children }: UnassignedFilterCTAProps) => {
	const [ctaHeight, setCtaHeight] = useState<number>(0);
	return (
		<>
			<JSErrorBoundary
				id="unassigned-filter-cta"
				packageName={PACKAGE_NAME}
				withExperienceTracker
				fallback="unmount"
				teamName="jira-warepil"
			>
				<ContextualAnalyticsData sourceName="viewUnassignedFilterCTA" sourceType={SCREEN}>
					<InnerUnassignedFilterCTA setCtaHeight={setCtaHeight} />
				</ContextualAnalyticsData>
			</JSErrorBoundary>
			{children?.(ctaHeight)}
		</>
	);
};

type InnerUnassignedFilterCTAProps = {
	setCtaHeight: (ctaHeight: number) => void;
};

const InnerUnassignedFilterCTA = ({ setCtaHeight }: InnerUnassignedFilterCTAProps) => {
	const { dismiss, isDismissed, dismissCount } = useDismissal();
	const isBoardInTargetState = useShouldShowUnassignedFilterCTA();
	const newFilters = useBoardSelector(unassignedWorkFilterSelector);
	const dispatch = useBoardDispatch();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const id = useUID();
	const ctaRef = useRef<HTMLDivElement>(null);

	const isVisible =
		!isDismissed &&
		isBoardInTargetState &&
		expValEquals('jira-collab-unassigned-filter-cta', 'isEnabled', true);

	const updateCtaHeight = useCallback(() => {
		const ctaHeightUpdated = ctaRef.current?.clientHeight ?? CTA_DEFAULT_HEIGHT;
		setCtaHeight(isVisible ? ctaHeightUpdated : 0);
	}, [isVisible, setCtaHeight]);

	const updateCtaHeightDebounced = useMemo(
		() => debounce(updateCtaHeight, 100, { leading: false, trailing: true }),
		[updateCtaHeight],
	);

	// Update height on CTA size change (e.g. window resize)
	useResizeObserver(ctaRef.current, updateCtaHeightDebounced);

	// Update height on render display logic change (e.g. dismiss or filter change)
	// note: updateCtaHeight is recreated as isDismissed & shouldShow change
	useLayoutEffect(() => {
		updateCtaHeight();
	}, [updateCtaHeight]);

	const handleFilterClick = () => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'changed',
			actionSubject: 'filterBar',
		});
		fireUIAnalytics(analyticsEvent, 'changeFilterBar', {
			isUnassignedSelected: true,
			isOnlyCurrentUserSelected: false,
			isUnassignedFilterCta: true,
			unassignedFilterDismissCount: dismissCount,
		});
		return dispatch(setAllFilters(newFilters, ASSIGNEE, analyticsEvent));
	};

	const dismissWithAnalytics = useCallback(
		(e: React.MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'unassignedCTADismissed');
			dismiss();
		},
		[dismiss],
	);

	return (
		<ContextualAnalyticsData attributes={{ dismissCount }}>
			<ExitingPersistence appear exitThenEnter>
				{!isVisible ? null : (
					<FadeIn entranceDirection="top" exitDirection="top">
						{(fadeInProps) => (
							<div {...fadeInProps} role="group" aria-labelledby={id} ref={ctaRef}>
								<FireScreenAnalytics />
								<Stack xcss={containerStyle} space="space.050">
									<Box xcss={titleStyle}>
										<Text weight="semibold" id={id}>
											<FormattedMessage {...messages.unassignedFilterCtaTitle} />
										</Text>
									</Box>
									<Text>
										<FormattedMessage {...messages.unassignedFilterCtaDescription} />
									</Text>
									<Button onClick={handleFilterClick}>
										<FormattedMessage {...messages.unassignedFilterCtaButtonText} />
									</Button>
									<Box xcss={closeButtonStyle}>
										<IconButton
											icon={CloseIcon}
											label={<FormattedMessage {...messages.unassignedFilterCtaCloseButtonLabel} />}
											appearance="subtle"
											spacing="compact"
											onClick={dismissWithAnalytics}
										/>
									</Box>
								</Stack>
							</div>
						)}
					</FadeIn>
				)}
			</ExitingPersistence>
		</ContextualAnalyticsData>
	);
};

const containerStyle = xcss({
	position: 'relative',
	padding: 'space.200',
	margin: 'space.050',
	marginBottom: '0',
	backgroundColor: 'color.background.information',
	borderRadius: 'border.radius',
	boxShadow: 'elevation.shadow.raised',
});

const titleStyle = xcss({
	paddingRight: 'space.300',
});

const closeButtonStyle = xcss({
	position: 'absolute',
	top: 'space.150',
	right: 'space.150',
});
