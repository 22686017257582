import { createSelector } from 'reselect';
import { UNASSIGNED_USER_ID } from '@atlassian/jira-software-filters/src/common/constants';
import type { Person } from '../../../model/people/people-types';
import type { PeopleState } from '../../reducers/entities/people/types';
import type { State } from '../../reducers/types';
import { getEntities } from '../software/software-selectors';

export const getPeople = (state: State): PeopleState => getEntities(state).people;

export const getPerson = (state: State, id: string): Person => getPeople(state)[id];

export const getCurrentUserAccountId = (state: State): string => state.configuration.userAccountId;

/**
 * get the account Ids of all assignees
 * Add an extra id of "unassigned" is appended in the result.
 */
export const getAssigneeAccountIds = createSelector([getPeople], (assignees) => {
	const accountIds = Object.keys(assignees);

	return [...accountIds, UNASSIGNED_USER_ID];
});
