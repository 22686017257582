import React, { useState, useRef, type ReactNode } from 'react';
import Tooltip from '@atlaskit/tooltip';
import type { CustomField } from '../types';

type CustomFieldTooltipProps = {
	field: CustomField;
	children: ReactNode;
};

export const CustomFieldTooltip = ({ field, children }: CustomFieldTooltipProps) => {
	const { label } = field;

	const [tooltipContent, setTooltipContent] = useState(label);
	const ref = useRef<HTMLDivElement>(null);

	const onShow = () => {
		if (ref.current && ref.current.innerText) {
			const toolTipString = `${label}: ${ref.current.innerText}`;
			if (toolTipString !== tooltipContent) {
				setTooltipContent(toolTipString);
			}
		}
	};

	return (
		<Tooltip onShow={onShow} content={tooltipContent}>
			<div ref={ref}>{children}</div>
		</Tooltip>
	);
};
