import React, { useCallback, useEffect, useMemo, useState } from 'react';
import values from 'lodash/values';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import { UNSCHEDULED_COLUMN_ID } from '@atlassian/jira-common-constants/src/column-types';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging-using-meta';
import { fg } from '@atlassian/jira-feature-gating';
import { AsyncInlineCardCreate as BoardKitAsyncInlineCardCreate } from '@atlassian/jira-platform-board-kit/src/ui/inline-card-create/async.tsx';
import BoardKitInlineCardCreate from '@atlassian/jira-platform-board-kit/src/ui/inline-card-create/index.tsx';
import type { StatusTransition } from '@atlassian/jira-platform-inline-card-create';
import { useIccContext } from '@atlassian/jira-platform-inline-card-create/src/services/context/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	useKeepIccOpenActions,
	useKeepIccOpenState,
	useTriggerEventState,
} from '@atlassian/jira-software-onboarding-keep-icc-open/src/controllers/index.tsx';
import { CAN_EDIT_BOARD } from '../../../../../model/permission/permission-types';
import type { IssueProject } from '../../../../../model/software/software-types';
import { useBoardDispatch, useBoardSelector } from '../../../../../state';
import {
	setIssueIdWithAboveICCOpen,
	closeIccInUnscheduledColumn,
	openIccInUnscheduledColumn,
} from '../../../../../state/actions/card';
import {
	clearInlineCreateSessionId,
	setInlineCreateSessionId,
} from '../../../../../state/actions/inline-create';
import { getPermissionsSelector } from '../../../../../state/selectors/board/board-permissions-selectors';
import {
	isBoardEmptySelector,
	isEligibleForSimplifyIccBundleExperiment,
} from '../../../../../state/selectors/board/board-selectors';
import {
	getInitialTransitionsForColumn,
	makeGetStatusTransitionsForType,
	manageIssueTypesLinkSelector,
	getIssueProjectsForIssueCreation,
} from '../../../../../state/selectors/card-create/card-create-selectors';
import {
	hasICCOpen,
	keepICCOpenInColumn,
	getColumnIdToKeepICCOpen,
	getLastOpenedColumnId,
	isUnscheduledColumnICCOpen,
} from '../../../../../state/selectors/card/card-selectors';
import { getColumnIndex } from '../../../../../state/selectors/column/column-selectors';
import { defaultIssueTypeIdSelector } from '../../../../../state/selectors/issue-type/issue-type-selectors';
import { getIsIncrementPlanningBoard } from '../../../../../state/selectors/software/software-selectors';
import {
	filtersForInlineCardCreateCountSelector,
	hasCustomFiltersSelector,
} from '../../../../../state/selectors/work/work-selectors';
import type { VisibleIccProps } from './types';
import { useOnCreate } from './utils';

const EMPTY_ARRAY: StatusTransition[] = [];

const isInSimplifyIccBundleVariation = (fireExposureEvent = false) => {
	const [expConfigM2, fireExposureM2] = UNSAFE_noExposureExp('simplify_icc_bundle_m2');

	if (fireExposureEvent) {
		fireExposureM2();
	}

	const isInVariationM2 = expConfigM2.get('cohort', 'not-enrolled') === 'variation';
	return isInVariationM2;
};

const isEnrolledInSimplifyIccBundle = (fireExposureEvent = false) => {
	const [expConfigM2, fireExposureM2] = UNSAFE_noExposureExp('simplify_icc_bundle_m2');

	if (fireExposureEvent) {
		fireExposureM2();
	}

	const isInVariationM2 = expConfigM2.get('cohort', 'not-enrolled') !== 'not-enrolled';
	return isInVariationM2;
};

const EMPTY_PROJECTS_ARRAY: IssueProject[] = [];

const VisibleIccComponent = ({
	columnId,
	cardTypes,
	cardIndex,
	insertBefore,
	isLastSwimlane,
	projectId,
	swimlaneId,
	groupParentId,
	mappedCardTypes,
	onChangeFormVisible,
	showOnHover,
	triggerAppearance,
	renderAdditionalTypeSelectOptionContent,
	isFooter,
}: VisibleIccProps) => {
	const [isFormVisible, setIsFormVisible] = useState(false);

	const { initialCardTypeId } = useIccContext();

	const keepIccOpen = useKeepIccOpenState();

	const { triggerReason } = useTriggerEventState();

	const { setTriggerReason } = useKeepIccOpenActions();

	const dispatch = useBoardDispatch();

	const hasCustomFilters = useBoardSelector(hasCustomFiltersSelector);

	const appliedFilterCount = useBoardSelector((state) =>
		hasCustomFilters ? 0 : filtersForInlineCardCreateCountSelector(state),
	);
	const isIPBoard = useBoardSelector(getIsIncrementPlanningBoard);

	const canEditBoard = useBoardSelector((state) => getPermissionsSelector(state)[CAN_EDIT_BOARD]);

	const manageIssueTypesLink = useBoardSelector((state) =>
		canEditBoard ? manageIssueTypesLinkSelector(state) : null,
	);
	const getStatusTransitions = useBoardSelector(makeGetStatusTransitionsForType);

	const defaultIssueTypeId = useBoardSelector(defaultIssueTypeIdSelector);

	const isUnscheduledColumn =
		ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1') &&
		isIPBoard &&
		columnId === UNSCHEDULED_COLUMN_ID;

	const shouldStayOpen = useBoardSelector((state) => {
		if (isUnscheduledColumn) {
			return !!isFooter && isUnscheduledColumnICCOpen(state, swimlaneId, columnId);
		}

		if (isEligibleForSimplifyIccBundleExperiment(state) && isEnrolledInSimplifyIccBundle(true)) {
			if (
				isBoardEmptySelector(state) &&
				getColumnIndex(state, columnId) === 0 &&
				getColumnIdToKeepICCOpen(state) === null &&
				getLastOpenedColumnId(state) === null
			) {
				if (triggerReason !== 'emptyBoard') {
					setTriggerReason('emptyBoard');
				}

				if (isInSimplifyIccBundleVariation(true)) {
					dispatch(setIssueIdWithAboveICCOpen(null, columnId));
					return true;
				}
			}

			// This is an existing code path and is not part of the
			// changes in the variation cohort, so no checks needed
			if (insertBefore) {
				return hasICCOpen(state, insertBefore);
			}

			if (isInSimplifyIccBundleVariation(true)) {
				return keepICCOpenInColumn(state, columnId);
			}
		}

		return hasICCOpen(state, insertBefore);
	});

	const projects = useBoardSelector((state) =>
		isIPBoard ? values(getIssueProjectsForIssueCreation(state)) : EMPTY_PROJECTS_ARRAY,
	);

	const issueTypeTransitions = useBoardSelector((state) =>
		!isIPBoard ? getInitialTransitionsForColumn(state)(columnId) : undefined,
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onAutoOpen = useCallback(
		(sessionId: string) => {
			// start event for auto open icc
			const startAutoOpenICCAnalyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			});

			// firing start event for icc that is auto open - same as onTriggerClickCallback
			// @ts-expect-error Second argument should be of format "actionSubject action"
			fireUIAnalytics(startAutoOpenICCAnalyticsEvent, 'triggerClicked', 'triggerButton', {
				formWasOpen: isFormVisible,
				triggerAppearance,
				sessionId,
				isInSimplifyIccBundleVariation: isInSimplifyIccBundleVariation(),
				isAutoOpen: true,
			});
			dispatch(setInlineCreateSessionId(sessionId));
		},
		[createAnalyticsEvent, dispatch, isFormVisible, triggerAppearance],
	);

	const onTriggerClick = useCallback(
		(sessionId?: string) => {
			if (isEnrolledInSimplifyIccBundle()) {
				setIsFormVisible(true);
			}

			if (ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1') && isIPBoard) {
				// When other create button is clicked, close ICC in unscheduled column
				dispatch(closeIccInUnscheduledColumn());

				if (isUnscheduledColumn && isFooter && swimlaneId) {
					// If the button clicked in unscheduled column and footer, sync the swimlane ID to the state
					// This is so the create button in the header is disabled
					dispatch(openIccInUnscheduledColumn(swimlaneId));
				}
			}
			dispatch(setInlineCreateSessionId(sessionId));
			dispatch(setIssueIdWithAboveICCOpen(insertBefore, columnId));
		},

		[dispatch, insertBefore, columnId, swimlaneId, isIPBoard, isUnscheduledColumn, isFooter],
	);

	const onCancelOrBlur = useCallback(() => {
		if (isEnrolledInSimplifyIccBundle() || isUnscheduledColumn) {
			setIsFormVisible(false);
		}

		if (isUnscheduledColumn) {
			dispatch(closeIccInUnscheduledColumn());
		}
		dispatch(clearInlineCreateSessionId());
		dispatch(setIssueIdWithAboveICCOpen(null, null));
	}, [dispatch, isUnscheduledColumn]);

	const onFormVisibleChange = useCallback(
		(formVisible: boolean) => {
			setIsFormVisible(formVisible);
			onChangeFormVisible(formVisible);
		},
		[onChangeFormVisible],
	);

	const onCreate = useOnCreate({
		columnId,
		cardTypes,
		cardIndex,
		insertBefore,
		isLastSwimlane,
		projectId,
		swimlaneId,
		groupParentId,
		isUnscheduledColumn,
	});

	const statusTransitions = useMemo(() => {
		if ((shouldStayOpen || isFormVisible) && !isIPBoard) {
			return getStatusTransitions(columnId, issueTypeTransitions, initialCardTypeId);
		}

		return EMPTY_ARRAY;
	}, [
		columnId,
		getStatusTransitions,
		initialCardTypeId,
		isFormVisible,
		isIPBoard,
		issueTypeTransitions,
		shouldStayOpen,
	]);

	const shouldShowCreateButton = useBoardSelector((state) => {
		if (isEligibleForSimplifyIccBundleExperiment(state) && isEnrolledInSimplifyIccBundle(true)) {
			// Validation/warning message and Multicreate dialog can only be checked later (depends on user input)
			return (
				projects.length === 0 && // this should only happen for IPBoards, including check to be safe
				isInSimplifyIccBundleVariation(true)
			);
		}
		return false;
	});

	useEffect(() => {
		if (isUnscheduledColumn) {
			setIsFormVisible(shouldStayOpen);
		} else if (isEnrolledInSimplifyIccBundle()) {
			// Checking if it's undefined here because we should still be setting it to
			// false if keepIccOpen is false
			if (isInSimplifyIccBundleVariation() && shouldStayOpen) {
				setIsFormVisible(keepIccOpen);
			} else {
				setIsFormVisible(shouldStayOpen);
			}
		}
	}, [keepIccOpen, shouldStayOpen, isUnscheduledColumn]);

	const InlineCardCreate = fg('board_icc_form_async_load')
		? BoardKitInlineCardCreate
		: BoardKitAsyncInlineCardCreate;

	return (
		<InlineCardCreate
			defaultCardTypeId={defaultIssueTypeId}
			cardTypes={mappedCardTypes}
			// appliedFilterCount is used to show the info dialog with the X filters applied message
			// hide it if we have custom filters active as it'll be misleading
			appliedFilterCount={appliedFilterCount}
			manageIssueTypesLink={manageIssueTypesLink}
			shouldStayOpen={shouldStayOpen}
			isIPBoard={isIPBoard}
			statusTransitions={statusTransitions}
			isFormVisible={
				isEnrolledInSimplifyIccBundle() || isUnscheduledColumn ? isFormVisible : undefined
			}
			// TODO-BOARD-REFACTOR-TYPES
			// @ts-expect-error inconsistent types between IssueProject and Project
			projects={projects}
			onCreate={onCreate}
			onTriggerClick={onTriggerClick}
			onCancel={onCancelOrBlur}
			onBlur={onCancelOrBlur}
			onChangeFormVisible={
				isEnrolledInSimplifyIccBundle() ? onChangeFormVisible : onFormVisibleChange
			}
			renderAdditionalTypeSelectOptionContent={renderAdditionalTypeSelectOptionContent}
			showOnHover={showOnHover}
			triggerAppearance={triggerAppearance}
			shouldShowCreateButton={shouldShowCreateButton}
			{...(isUnscheduledColumn && { shouldScrollIntoView: true })}
			onAutoOpen={onAutoOpen}
		/>
	);
};

export const VisibleIcc = AnalyticsSubject('issue')(VisibleIccComponent);
