import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import {
	CARD_DRAG_OVER_TRANSITION_REQUEST,
	type CardDragOverTransitionRequestAction,
	cardDragOverTransition,
} from '../../state/actions/card';
import { getIsCMPBoard } from '../../state/selectors/software/software-selectors';
import type { ActionsObservable, MiddlewareAPI } from '../../state/types';

export default function cardTransitionEpic(action$: ActionsObservable, store: MiddlewareAPI) {
	return action$
		.ofType(CARD_DRAG_OVER_TRANSITION_REQUEST)
		.mergeMap((action: CardDragOverTransitionRequestAction) => {
			const state = store.getState();
			const isCMPBoard = getIsCMPBoard(state);
			const { transitionId, toColumnId, hoverIntent } = action.payload;

			if (isCMPBoard) {
				// We always avoid passing the hover intent in a CMP board, so the ranking mode is disabled when hovering on the transition zone
				return Observable.of(cardDragOverTransition(transitionId, toColumnId, false));
			}

			return Observable.of(cardDragOverTransition(transitionId, toColumnId, hoverIntent));
		});
}
