import { graphql, commitMutation } from 'react-relay';
import { Observable as ObservableType } from 'rxjs/Observable';
import { createAri } from '@atlassian/jira-platform-ari';
import getRelayEnvironment from '@atlassian/jira-relay-environment';
import type {
	deleteCardMutation,
	deleteCardMutation$data,
	DeleteCardInput,
} from '@atlassian/jira-relay/src/__generated__/deleteCardMutation.graphql';
import type { IssueId } from '@atlassian/jira-software-board-common';
import { getStatusCodeFromGraphQLErrors } from '@atlassian/jira-software-sla-tracker/src/services/status-code/index.tsx';
import RelayDataID from '@atlassian/relay-data-id';

/**
 * This DeleteCardExtraInput type was created to get the data required
 * to manually update the Relay store after a card is deleted. There are two
 * reasons it is required now.
 * 1. The deleteCard mutation accepts a jira-software card ARI whereas
 * the boardScope critical load still receives cards with a jira issue ARI.
 * Once they are in sync, we do not need to use a custom 'updater'
 * function and can instead use a @deleteRecord directive.
 * https://jdog.jira-dev.com/browse/TOTEM-3221
 * 2. We cannot easily parse the ARI in the DeleteCardInput cardId field due
 * to the @atlassian/ari library not yet approved. Once approved, we would
 * have been able to use it to pull out these values from the ARI directly
 * https://jdog.jira-dev.com/browse/TOTEM-3113
 */
export type DeleteCardExtraInput = {
	cloudId: string;
	issueId: IssueId;
};

export const deleteCard = (
	deleteCardInput: DeleteCardInput,
	extraInput: DeleteCardExtraInput,
): ObservableType<deleteCardMutation$data> => {
	const variables = { input: deleteCardInput };

	return ObservableType.fromPromise(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		new Promise((resolve, reject: (error?: any) => void) => {
			commitMutation<deleteCardMutation>(getRelayEnvironment(), {
				mutation: graphql`
					mutation deleteCardMutation($input: DeleteCardInput) {
						jsw {
							deleteCard(input: $input) {
								clientMutationId
								statusCode
								success
								message
							}
						}
					}
				`,
				variables,
				updater: (store) => {
					const { cloudId, issueId } = extraInput;
					const issueAri = createAri({
						resourceOwner: 'jira',
						cloudId,
						resourceType: 'issue',
						resourceId: issueId.toString(),
					});
					const issueDataId = RelayDataID({ id: issueAri }, 'SoftwareCard');
					if (issueDataId !== undefined) {
						store.delete(issueDataId);
					}
				},
				onCompleted: (response, errors) => {
					if (errors && errors.length) {
						const statusCode = getStatusCodeFromGraphQLErrors(errors);
						if (statusCode == null) {
							reject(errors);
							return;
						}
						errors.map((error) => reject(error));

						return;
					}
					resolve(response);
				},
				onError: (error: Error) => {
					reject(error);
				},
			});
		}),
	);
};
