import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import isNumber from 'lodash/isNumber';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	convertCapacity,
	convertCapacityFromPlanningUnitToSeconds,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/utils';
import {
	type UpdatePlannedCapacityRequestAction,
	type PlannedCapacityCompletePayload,
	UPDATE_PLANNED_CAPACITY_REQUEST,
	updatePlannedCapacitySuccess,
	updatePlannedCapacityFailed,
} from '../../../state/actions/sprints/update-planned-capacity';
import { getTeamById } from '../../../state/selectors/team/team-selectors';
import type { ActionsObservable, MiddlewareAPI, BoardDependencies } from '../../../state/types';

export function updatePlannedCapacityEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
	{ customRequestHandlers }: BoardDependencies,
) {
	return action$
		.ofType(UPDATE_PLANNED_CAPACITY_REQUEST)
		.mergeMap((action: UpdatePlannedCapacityRequestAction) => {
			const {
				payload: {
					capacity,
					planningUnit,
					workingHoursPerDay,
					iterationId,
					teamId,
					totalCapacity,
					defaultCapacity,
				},
			} = action;
			const { planningStyle } = getTeamById(store.getState())(teamId);

			const currentCapacity = totalCapacity === null ? defaultCapacity : totalCapacity;

			if (capacity !== '') {
				const newCapacity = parseFloat(capacity);
				const convertedCapacity =
					(isNumber(currentCapacity) &&
						convertCapacity(currentCapacity, planningUnit, workingHoursPerDay)) ||
					0;

				if (Number.isNaN(newCapacity) || convertedCapacity === newCapacity) {
					return Observable.empty<never>();
				}
			}

			const requestPayload = {
				iterationId: `${iterationId}`,
				planningUnit,
				teamId,
				schedulingMode: planningStyle,
			};

			const success = (payload: PlannedCapacityCompletePayload) =>
				Observable.of(updatePlannedCapacitySuccess(payload));

			const error = (err?: Error) => {
				log.safeErrorWithoutCustomerData(
					'increment.planning.planned.capacity.update',
					'Failed to update IP board planned capacity',
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					err as Error,
				);
				return Observable.of(updatePlannedCapacityFailed());
			};

			if (!customRequestHandlers) {
				return error(new Error('no request handler found for planned capacity update'));
			}

			if (capacity === '') {
				if (!customRequestHandlers?.removePlannedCapacity) {
					return error(new Error('no request handler found for planned capacity update'));
				}
				return Observable.from(
					customRequestHandlers.removePlannedCapacity({
						...requestPayload,
						capacity: null,
					}),
				)
					.flatMap(() => success({ teamId, iterationId: `${iterationId}`, capacity: null }))
					.catch((err) => error(err));
			}

			const parsed = parseFloat(capacity);
			const convertedCapacity = convertCapacityFromPlanningUnitToSeconds({
				capacity: parsed || 0,
				planningUnit,
				workingHours: workingHoursPerDay,
			});
			if (!customRequestHandlers?.updatePlannedCapacity) {
				return error(new Error('no request handler found for planned capacity update'));
			}
			return Observable.from(
				customRequestHandlers.updatePlannedCapacity({
					...requestPayload,
					capacity: convertedCapacity,
				}),
			)
				.flatMap(() =>
					success({
						teamId,
						iterationId: `${iterationId}`,
						capacity: convertedCapacity,
					}),
				)
				.catch((err) => error(err));
		});
}
