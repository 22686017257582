import type { Action } from '../../../actions';
import { type SetVersionDataAction, SET_VERSION_DATA } from '../../../actions/version';
import type { VersionDataState } from './types';

const initVersionDataState: VersionDataState = {
	versionsPerProject: {},
	canCreateVersion: false,
};

export const versionDataReducer = (
	state: VersionDataState = initVersionDataState,
	action: Action,
): VersionDataState => {
	if (action.type === SET_VERSION_DATA) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { payload } = action as SetVersionDataAction;
		return { ...payload };
	}

	return state;
};
