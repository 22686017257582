import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	findDoneClearedCardsLink: {
		id: 'platform-board-kit.card.done-cards-button.find-done-cleared-cards-link',
		defaultMessage: 'See all Done issues',
		description:
			'Shown at the bottom of the last column on a board if it contains issues that are Done and have been cleared',
	},
	doneClearedCardsTooltip: {
		id: 'platform-board-kit.card.done-cards-button.done-cleared-cards-tooltip',
		defaultMessage: 'Done issues cleared from the board can be viewed here.',
		description:
			'Shown when the "find done cleared cards" link is hovered over. The "find done cleared cards" link is shown at the bottom of the last column on a board if it contains issues that are Done and have been cleared',
	},
	oldIssuesSearchLink: {
		id: 'platform-board-kit.card.done-cards-button.old-issues-search-link',
		defaultMessage: 'See older issues',
		description:
			'Shown as a link button in footer of Done Column for CMP Kanban when there are more issues to shown via JQL search',
	},
	oldIssuesSearchMessage: {
		id: 'platform-board-kit.card.done-cards-button.old-issues-search-message',
		defaultMessage: 'View older issues that have been cleared from the board',
		description: 'Shown when there are old issues to be shown in CMP Kanban done column',
	},
});
