import React, { useCallback } from 'react';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { type SwimlaneId, STORY } from '../../../../model/swimlane/swimlane-types';
import { useBoardDispatch, useBoardSelector } from '../../../../state';
import { issueChildrenInFinalColumnRequest } from '../../../../state/actions/issue-children';
import {
	areSwimlaneBySubtaskParentChildrenDoneSelector,
	isSwimlaneBySubtaskParentDoneSelector,
} from '../../../../state/selectors/swimlane/swimlane-selectors';
import { platformSwimlaneSelector } from '../../../../state/selectors/work/work-selectors';
import messages from './messages';

type Props = {
	id: SwimlaneId;
};

export const SwimlaneMoveToDoneButton = ({ id }: Props) => {
	const { formatMessage } = useIntl();
	const dispatch = useBoardDispatch();
	const { values: swimlaneValues } = useBoardSelector(platformSwimlaneSelector)(id) || {};

	const openMoveToDoneModal = useCallback(() => {
		if (swimlaneValues?.type === STORY && swimlaneValues?.parentId) {
			dispatch(issueChildrenInFinalColumnRequest(swimlaneValues.parentId));
		}
	}, [dispatch, swimlaneValues]);

	const isParentDone = useBoardSelector(isSwimlaneBySubtaskParentDoneSelector)(id);
	const areAllParentChildrenDone = useBoardSelector(areSwimlaneBySubtaskParentChildrenDoneSelector)(
		swimlaneValues,
	);

	return !isParentDone && areAllParentChildrenDone ? (
		<Button onClick={openMoveToDoneModal}>{formatMessage(messages.moveToDoneLabel)}</Button>
	) : null;
};
