import {
	INSIGHTS_BACKLOG_PACKAGE,
	INSIGHTS_BACKLOG_SIMPLIFIED_PACKAGE,
	INSIGHTS_BOARD_PACKAGE,
	INSIGHTS_BOARD_SIMPLIFIED_PACKAGE,
} from '@atlassian/jira-insights-common-constants/src/common/constants/package';

type ParametersType = {
	isSimplifiedProject: boolean;
	boardId: number;
};

export const buildBoardScopeName = ({ isSimplifiedProject, boardId }: ParametersType): string =>
	`${
		isSimplifiedProject ? INSIGHTS_BOARD_SIMPLIFIED_PACKAGE : INSIGHTS_BOARD_PACKAGE
	}-boardId:${boardId}`;

export const buildBacklogScopeName = ({ isSimplifiedProject, boardId }: ParametersType): string =>
	`${
		isSimplifiedProject ? INSIGHTS_BACKLOG_SIMPLIFIED_PACKAGE : INSIGHTS_BACKLOG_PACKAGE
	}-boardId:${boardId}`;
