import { useEffect } from 'react';
import { createHook, createStore } from '@atlassian/react-sweet-state';
import type { BoardToolSection, BoardToolWebItem, SprintActionsType } from '../common/types';
import type { FetchCMPSprintActionsParams } from '../services/load-extension-data';
import * as actions from './actions';
import type { State } from './types';

export type Actions = typeof actions;

export const Store = createStore<State, Actions>({
	name: '@atlassian/jira-software-connect-toolbar',
	initialState: { toolSections: null, key: null, isLoading: false, sprintActions: null },
	actions,
});

export const useToolSections = createHook(Store, {
	selector: (state): (BoardToolSection | BoardToolWebItem)[] | null => state.toolSections,
});

export function useFetchedToolSections({
	boardId,
	projectKey,
	mode = 'work',
}: {
	boardId: string;
	projectKey: string;
	mode?: 'work' | 'plan';
}) {
	const [toolSections, { fetchExtensionData }] = useToolSections();
	useEffect(() => {
		fetchExtensionData({ boardId, projectKey, mode });
	}, [boardId, fetchExtensionData, mode, projectKey]);
	return toolSections;
}

export const useSprintActions = createHook(Store, {
	selector: (state): SprintActionsType => state.sprintActions,
});

export const useFetchedSprintActions = ({ sprintId }: FetchCMPSprintActionsParams) => {
	const [sprintActions, { fetchSprintActionsData }] = useSprintActions();
	useEffect(() => {
		fetchSprintActionsData({ sprintId });
	}, [fetchSprintActionsData, sprintId]);
	return sprintActions;
};
