import {
	BACKLOG_ISSUE_MOVE_CANCEL,
	BACKLOG_ISSUE_MOVE_EXECUTE,
	BACKLOG_ISSUE_MOVE_EXECUTE_FAILURE,
	BACKLOG_ISSUE_MOVE_EXECUTE_SUCCESS,
	BACKLOG_ISSUE_MOVE_SHOW,
} from '../../actions/board/backlog-issue-move';
import { CARD_MEDIA_TOGGLE_REQUEST } from '../../actions/board/menu';
import {
	RENAME_BOARD_FAILURE,
	RENAME_BOARD_REQUEST,
	RENAME_BOARD_SUCCESS,
} from '../../actions/board/rename';
import { CARD_CLICK } from '../../actions/card';
import {
	FILTERED_CARD_SUCCESS,
	FILTERED_CARD_FAILURE,
	REFILTER_SUCCESS,
	REFILTER_FAILURE,
} from '../../actions/card/filtered-cards';
import {
	COLUMN_CREATE_REQUEST,
	COLUMN_CREATE_SUCCESS,
	COLUMN_CREATE_FAILURE,
} from '../../actions/column/create';
import {
	COLUMN_DELETE,
	COLUMN_DELETE_REQUEST,
	COLUMN_DELETE_SUCCESS,
	COLUMN_DELETE_FAILURE,
} from '../../actions/column/delete';
import {
	COLUMN_SET_LIMITS_REQUEST,
	COLUMN_SET_LIMITS_SUCCESS,
	COLUMN_SET_LIMITS_FAILURE,
	COLUMN_CLOSE_LIMITS_MODAL,
	COLUMN_OPEN_LIMITS_MODAL,
} from '../../actions/column/limits';
import {
	COLUMN_RANK_REQUEST,
	COLUMN_RANK_SUCCESS,
	COLUMN_RANK_FAILURE,
} from '../../actions/column/rank';
import {
	COLUMN_RENAME_REQUEST,
	COLUMN_RENAME_SUCCESS,
	COLUMN_RENAME_FAILURE,
} from '../../actions/column/rename';
import {
	FILTER_PANEL_MOUNTED,
	SEARCH_FOCUS,
	SET_ALL_FILTERS,
	CLEAR_ALL_FILTERS_CLICKED,
} from '../../actions/filter';
import {
	FLAG_DISMISSED,
	FLAG_CREATED_ISSUE_IS_FILTERED,
	ISSUE_CREATE_FILTERED_V2,
	ISSUE_CREATE_MOVED_BETWEEN_SWIMLANES,
} from '../../actions/flags';
import { DONE_ISSUES_BUTTON_CLICK, ISSUE_CHANGE_SWIMLANE } from '../../actions/issue';
import {
	ISSUE_CREATE_REQUEST,
	ISSUE_CREATE_SUCCESS,
	ISSUE_CREATE_FAILURE,
} from '../../actions/issue/create';
import {
	ISSUE_DELETE_SUCCESS,
	ISSUE_DELETE_FAILURE,
	ISSUE_DELETE_REQUEST,
} from '../../actions/issue/delete';
import {
	ISSUE_DELETE_MODAL_OPEN,
	ISSUE_DELETE_MODAL_CLOSE,
} from '../../actions/issue/delete-modal';
import { DEV_STATUS_LOAD_SUCCESS } from '../../actions/issue/dev-status';
import { ISSUE_FLAG_WITH_COMMENT_REQUEST } from '../../actions/issue/flag-with-comment';
import { ISSUE_MEDIA_VISIBILITY_CHANGE_REQUEST } from '../../actions/issue/media';
import { OPEN_ISSUE_MODAL } from '../../actions/issue/modal';
import {
	ISSUE_MOVE_TO_BACKLOG_REQUEST,
	ISSUE_MOVE_TO_BACKLOG_SUCCESS,
	ISSUE_MOVE_TO_BACKLOG_FAILURE,
} from '../../actions/issue/move-to-backlog';
import {
	ISSUE_RANK_TRANSITION_SUCCESS,
	ISSUE_RANK_TRANSITION_FAILURE,
	ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC,
} from '../../actions/issue/rank-transition';
import { ISSUE_UPDATE_SUCCESS } from '../../actions/issue/update';
import { SWIMLANE_MODE_CHANGE_REQUEST } from '../../actions/swimlane';
import {
	BOARD_LOAD_FAILURE,
	BOARD_LOAD_FAILURE_NO_COLUMN,
	BOARD_VIEW,
	BOARD_NON_CRITICAL_VIEW,
	WORK_DATA_CRITICAL_SET,
	WORK_DATA_SET,
	WORK_REFRESH_DATA,
} from '../../actions/work';
import type { AnalyticsActionsMap } from './analytics-types';
import {
	backlogIssueMoveCancel,
	backlogIssueMoveExecute,
	backlogIssueMoveExecuteSuccess,
	backlogIssueMoveExecuteFailure,
	backlogIssueMoveShow,
} from './board/backlog-issue-move-analytics';
import { cardMediaToggled } from './board/board-menu-analytics';
import {
	renameBoardFailure,
	renameBoardRequest,
	renameBoardSuccess,
} from './board/board-title-analytics';
import {
	columnRankRequest,
	columnRankSuccess,
	columnRankFailure,
	columnRenameRequest,
	columnRenameSuccess,
	columnRenameFailure,
	columnCreateRequest,
	columnCreateSuccess,
	columnCreateFailure,
	columnDelete,
	columnDeleteRequest,
	columnDeleteSuccess,
	columnDeleteFailure,
	columnSetLimitsRequest,
	columnSetLimitsSuccess,
	columnSetLimitsFailure,
	columnBustStatus,
	columnOpenLimitsModal,
	columnCloseLimitsModal,
} from './column/column-analytics';
import {
	columnRankRequestPerformance,
	columnRankSuccessPerformance,
	columnRenameRequestPerformance,
	columnRenameSuccessPerformance,
	columnCreateRequestPerformance,
	columnCreateSuccessPerformance,
	columnDeleteRequestPerformance,
	columnDeleteSuccessPerformance,
	columnLimitRequestPerformance,
	columnLimitSuccessPerformance,
} from './column/column-analytics-performance';
import {
	setCustomFiltersPerformance,
	setCustomFiltersSuccess,
	setCustomFiltersFailure,
	setCustomFiltersRefilterSuccess,
	setCustomFiltersRefilterFailure,
} from './custom-filters';
import {
	filterPanelMounted,
	searchFocus,
	setAllFilters,
	clearAllFiltersClicked,
	setAllFiltersPerformance,
} from './filter/filter-analytics';
import {
	flagDismissed,
	flagCreatedIssueIsFiltered,
	flagIssueCreateFilteredV2,
	flagIssueCreateMovedBetweenJqlSwimlanes,
} from './flags/flag-analytics';
import { issueViewOpenPerformance } from './issue-view';
import {
	doneIssuesButtonClick,
	issueCreateRequest,
	issueCreateSuccess,
	issueCreateFailure,
	issueDeleteSuccess,
	issueDeleteFailure,
	issueRankTransitionRequest,
	issueRankTransitionSuccess,
	issueRankTransitionFailure,
	issueLabelsAmount,
	devStatusLoadSuccess,
	openIssueCreate,
	issueChangeSwimlane,
	issueMoveToBacklogRequest,
	issueMoveToBacklogSuccess,
	issueMoveToBacklogFailure,
} from './issue/issue-analytics';
import {
	issueCreateRequestPerformance,
	issueCreateSuccessPerformance,
	issueDeleteRequestPerformance,
	issueFlagToggleRequestPerformance,
	issueRankTransitionRequestPerformance,
	issueRankTransitionSuccessPerformance,
} from './issue/issue-analytics-performance';
import { issueDeleteModalOpen, issueDeleteModalClose } from './issue/issue-delete-modal-analytics';
import { issueMediaVisibilityChange } from './issue/issue-media-analytics';
import { swimlaneModeChanged } from './swimlane/swimlane-analytics';
import {
	boardView,
	boardLoadFailure,
	workRefreshData,
	boardLoadNoColumnFailure,
	boardNonCriticalView,
} from './work/work-analytics';

export const ANALYTIC_PREFIX = 'board';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	({
		[BACKLOG_ISSUE_MOVE_SHOW]: [backlogIssueMoveShow],
		[BACKLOG_ISSUE_MOVE_CANCEL]: [backlogIssueMoveCancel],
		[BACKLOG_ISSUE_MOVE_EXECUTE]: [backlogIssueMoveExecute],
		[BACKLOG_ISSUE_MOVE_EXECUTE_FAILURE]: [backlogIssueMoveExecuteFailure],
		[BACKLOG_ISSUE_MOVE_EXECUTE_SUCCESS]: [backlogIssueMoveExecuteSuccess],

		[BOARD_LOAD_FAILURE]: [boardLoadFailure],
		[BOARD_LOAD_FAILURE_NO_COLUMN]: [boardLoadNoColumnFailure],
		[BOARD_VIEW]: [boardView],
		[BOARD_NON_CRITICAL_VIEW]: [boardNonCriticalView],
		[WORK_DATA_SET]: [issueLabelsAmount],
		[WORK_DATA_CRITICAL_SET]: [issueLabelsAmount],
		[WORK_REFRESH_DATA]: [workRefreshData],

		[RENAME_BOARD_FAILURE]: [renameBoardFailure],
		[RENAME_BOARD_REQUEST]: [renameBoardRequest],
		[RENAME_BOARD_SUCCESS]: [renameBoardSuccess],

		[CARD_MEDIA_TOGGLE_REQUEST]: [cardMediaToggled],

		[COLUMN_RANK_REQUEST]: [columnRankRequest, columnRankRequestPerformance],
		[COLUMN_RANK_SUCCESS]: [columnRankSuccess, columnRankSuccessPerformance],
		[COLUMN_RANK_FAILURE]: [columnRankFailure],

		[COLUMN_RENAME_REQUEST]: [columnRenameRequest, columnRenameRequestPerformance],
		[COLUMN_RENAME_SUCCESS]: [columnRenameSuccess, columnRenameSuccessPerformance],
		[COLUMN_RENAME_FAILURE]: [columnRenameFailure],

		[COLUMN_CREATE_REQUEST]: [columnCreateRequest, columnCreateRequestPerformance],
		[COLUMN_CREATE_SUCCESS]: [columnCreateSuccess, columnCreateSuccessPerformance],
		[COLUMN_CREATE_FAILURE]: [columnCreateFailure],

		[COLUMN_DELETE]: [columnDelete],
		[COLUMN_DELETE_REQUEST]: [columnDeleteRequest, columnDeleteRequestPerformance],
		[COLUMN_DELETE_SUCCESS]: [columnDeleteSuccess, columnDeleteSuccessPerformance],
		[COLUMN_DELETE_FAILURE]: [columnDeleteFailure],

		[COLUMN_OPEN_LIMITS_MODAL]: [columnOpenLimitsModal],
		[COLUMN_CLOSE_LIMITS_MODAL]: [columnCloseLimitsModal],
		[COLUMN_SET_LIMITS_REQUEST]: [
			columnSetLimitsRequest,
			columnBustStatus,
			columnLimitRequestPerformance,
		],
		[COLUMN_SET_LIMITS_SUCCESS]: [columnSetLimitsSuccess, columnLimitSuccessPerformance],
		[COLUMN_SET_LIMITS_FAILURE]: [columnSetLimitsFailure],

		[DONE_ISSUES_BUTTON_CLICK]: [doneIssuesButtonClick],

		[ISSUE_CREATE_REQUEST]: [issueCreateRequest, issueCreateRequestPerformance],
		[ISSUE_CREATE_SUCCESS]: [issueCreateSuccess, issueCreateSuccessPerformance],
		[ISSUE_CREATE_FAILURE]: [issueCreateFailure],

		[ISSUE_DELETE_REQUEST]: [issueDeleteRequestPerformance],
		[ISSUE_DELETE_SUCCESS]: [issueDeleteSuccess],
		[ISSUE_DELETE_FAILURE]: [issueDeleteFailure],

		[ISSUE_DELETE_MODAL_OPEN]: [issueDeleteModalOpen],
		[ISSUE_DELETE_MODAL_CLOSE]: [issueDeleteModalClose],

		[ISSUE_FLAG_WITH_COMMENT_REQUEST]: [issueFlagToggleRequestPerformance],

		[ISSUE_MOVE_TO_BACKLOG_REQUEST]: [issueMoveToBacklogRequest],
		[ISSUE_MOVE_TO_BACKLOG_SUCCESS]: [issueMoveToBacklogSuccess],
		[ISSUE_MOVE_TO_BACKLOG_FAILURE]: [issueMoveToBacklogFailure],

		[ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC]: [
			issueRankTransitionRequest,
			columnBustStatus,
			issueRankTransitionRequestPerformance,
		],
		[ISSUE_RANK_TRANSITION_SUCCESS]: [
			issueRankTransitionSuccess,
			issueRankTransitionSuccessPerformance,
		],
		[ISSUE_RANK_TRANSITION_FAILURE]: [issueRankTransitionFailure],
		[ISSUE_UPDATE_SUCCESS]: [issueLabelsAmount],
		[ISSUE_CHANGE_SWIMLANE]: [issueChangeSwimlane],

		[DEV_STATUS_LOAD_SUCCESS]: [devStatusLoadSuccess],

		[FILTER_PANEL_MOUNTED]: [filterPanelMounted],

		[SEARCH_FOCUS]: [searchFocus],

		[SET_ALL_FILTERS]: [setAllFilters, setAllFiltersPerformance],
		[CLEAR_ALL_FILTERS_CLICKED]: [clearAllFiltersClicked],

		[FLAG_DISMISSED]: [flagDismissed],
		[FLAG_CREATED_ISSUE_IS_FILTERED]: [flagCreatedIssueIsFiltered], // deprecated - will be replaced with ISSUE_CREATE_FILTERED_V2
		[ISSUE_CREATE_FILTERED_V2]: [flagIssueCreateFilteredV2],
		[ISSUE_CREATE_MOVED_BETWEEN_SWIMLANES]: [flagIssueCreateMovedBetweenJqlSwimlanes],

		[OPEN_ISSUE_MODAL]: [openIssueCreate],
		[SWIMLANE_MODE_CHANGE_REQUEST]: [swimlaneModeChanged],

		[ISSUE_MEDIA_VISIBILITY_CHANGE_REQUEST]: [issueMediaVisibilityChange],

		[CARD_CLICK]: [issueViewOpenPerformance],

		[FILTERED_CARD_SUCCESS]: [setCustomFiltersPerformance, setCustomFiltersSuccess],
		[FILTERED_CARD_FAILURE]: [setCustomFiltersFailure],
		[REFILTER_SUCCESS]: [setCustomFiltersRefilterSuccess],
		[REFILTER_FAILURE]: [setCustomFiltersRefilterFailure],
	}) as AnalyticsActionsMap;
