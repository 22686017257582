import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	backlogLimitReachedFlagTitle: {
		id: 'software-issue-limit-flags.limit-reached-flag.backlog-limit-reached-flag-title',
		defaultMessage: 'Your backlog is full',
		description: 'Title for flag when issue count has reached the limit on backlog',
	},
	backlogLimitReachedAdminFlagBody: {
		id: 'software-issue-limit-flags.limit-reached-flag.backlog-limit-reached-admin-flag-body',
		defaultMessage:
			"The backlog can only display 5000 issues, and your current filter contains {issueCount}. Edit your filter to refine what's included and excluded.",
		description:
			'Description for flag when issue count has reached the limit on backlog for board admins',
	},
	backlogLimitReachedEndUserFlagBody: {
		id: 'software-issue-limit-flags.limit-reached-flag.backlog-limit-reached-end-user-flag-body',
		defaultMessage:
			"The backlog can only display 5000 issues, and the current filter contains {issueCount}. An admin will need to edit the board filter to refine what's included and excluded.",
		description:
			'Description for flag when issue count is approaching limit on backlog for end users',
	},
	boardLimitReachedFlagTitle: {
		id: 'software-issue-limit-flags.limit-reached-flag.board-limit-reached-flag-title',
		defaultMessage: 'Your board is full',
		description: 'Title for flag when issue count has reached the limit on backlog',
	},
	boardLimitReachedAdminFlagBody: {
		id: 'software-issue-limit-flags.limit-reached-flag.board-limit-reached-admin-flag-body',
		defaultMessage:
			"The board can only display 5000 issues, and your current filter contains {issueCount}. Edit your filter to refine what's included and excluded.",
		description:
			'Description for flag when issue count has reached the limit on backlog for board admins',
	},
	boardLimitReachedEndUserFlagBody: {
		id: 'software-issue-limit-flags.limit-reached-flag.board-limit-reached-end-user-flag-body',
		defaultMessage:
			"The board can only display 5000 issues, and the current filter contains {issueCount}. An admin will need to edit the board filter to refine what's included and excluded.",
		description:
			'Description for flag when issue count has reached the limit on backlog for end users',
	},
	tmpBacklogSprintsLimitReachedFlagTitle: {
		id: 'software-issue-limit-flags.limit-reached-flag.tmp-backlog-sprints-limit-reached-flag-title',
		defaultMessage: 'Your backlog and sprint sections are full',
		description:
			'Title for flag when issue count in both the backlog and sprint sections have reached the limit on a TMP backlog',
	},
	tmpSprintsLimitReachedFlagTitle: {
		id: 'software-issue-limit-flags.limit-reached-flag.tmp-sprints-limit-reached-flag-title',
		defaultMessage: 'Your sprint section is full',
		description:
			'Title for flag when issue count in the sprint section has reached the limit on a TMP backlog',
	},
	tmpBacklogLimitReachedFlagTitle: {
		id: 'software-issue-limit-flags.limit-reached-flag.tmp-backlog-limit-reached-flag-title',
		defaultMessage: 'Your backlog is full',
		description:
			'Title for flag when issue count in the backlog section has reached the limit on a TMP backlog',
	},
	tmpBacklogBoardLimitReachedFlagTitle: {
		id: 'software-issue-limit-flags.limit-reached-flag.tmp-backlog-board-limit-reached-flag-title',
		defaultMessage: 'Your backlog and board sections are full',
		description:
			'Title for flag when issue count in the backlog and board section has reached the limit on a TMP backlog',
	},
	tmpBoardLimitReachedFlagBody: {
		id: 'software-issue-limit-flags.limit-reached-flag.tmp-board-limit-reached-flag-body',
		defaultMessage:
			"The board can only display 5000 issues, and your current board contains {issueCount}. It's time to move some issues.",
		description: 'Description for flag when issue count has reached the limit on a TMP board',
	},
	tmpBacklogSprintsLimitReachedFlagBody: {
		id: 'software-issue-limit-flags.limit-reached-flag.tmp-backlog-sprints-limit-reached-flag-body',
		defaultMessage:
			"Your backlog and sprint sections can each only display 5000 issues. It's time to move some issues.",
		description:
			'Description for flag when issue count in both the backlog and sprint sections have reached the limit on a TMP backlog',
	},
	tmpSprintsLimitReachedFlagBody: {
		id: 'software-issue-limit-flags.limit-reached-flag.tmp-sprints-limit-reached-flag-body',
		defaultMessage:
			"We can only display 5000 issues across all your sprints. It's time to move some issues.",
		description:
			'Description for flag when issue count in the sprint section has reached the limit on a TMP backlog',
	},
	tmpBacklogLimitReachedFlagBody: {
		id: 'software-issue-limit-flags.limit-reached-flag.tmp-backlog-limit-reached-flag-body',
		defaultMessage:
			"The backlog can only display 5000 issues, and your current backlog contains {issueCount}. It's time to move some issues.",
		description:
			'Description for flag when issue count in the backlog section has reached the limit on a TMP backlog',
	},
	tmpBacklogBoardLimitReachedFlagBody: {
		id: 'software-issue-limit-flags.limit-reached-flag.tmp-backlog-board-limit-reached-flag-body',
		defaultMessage:
			"Your backlog and board sections can each only display 5000 issues. It's time to move some issues.",
		description:
			'Description for flag when issue count in both the backlog and board sections have reached the limit on a TMP backlog',
	},
	backlogLimitReachedAdminFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-reached-flag.backlog-limit-reached-admin-flag-body-issue-term-refresh',
		defaultMessage:
			"The backlog can only display 5000 issues, and your current filter contains {issueCount}. Edit your filter to refine what's included and excluded.",
		description:
			'Description for flag when issue count has reached the limit on backlog for board admins',
	},
	backlogLimitReachedEndUserFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-reached-flag.backlog-limit-reached-end-user-flag-body-issue-term-refresh',
		defaultMessage:
			"The backlog can only display 5000 issues, and the current filter contains {issueCount}. An admin will need to edit the board filter to refine what's included and excluded.",
		description:
			'Description for flag when issue count is approaching limit on backlog for end users',
	},
	boardLimitReachedAdminFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-reached-flag.board-limit-reached-admin-flag-body-issue-term-refresh',
		defaultMessage:
			"The board can only display 5000 issues, and your current filter contains {issueCount}. Edit your filter to refine what's included and excluded.",
		description:
			'Description for flag when issue count has reached the limit on backlog for board admins',
	},
	boardLimitReachedEndUserFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-reached-flag.board-limit-reached-end-user-flag-body-issue-term-refresh',
		defaultMessage:
			"The board can only display 5000 issues, and the current filter contains {issueCount}. An admin will need to edit the board filter to refine what's included and excluded.",
		description:
			'Description for flag when issue count has reached the limit on backlog for end users',
	},
	tmpBoardLimitReachedFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-reached-flag.tmp-board-limit-reached-flag-body-issue-term-refresh',
		defaultMessage:
			"The board can only display 5000 issues, and your current board contains {issueCount}. It's time to move some issues.",
		description: 'Description for flag when issue count has reached the limit on a TMP board',
	},
	tmpBacklogSprintsLimitReachedFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-reached-flag.tmp-backlog-sprints-limit-reached-flag-body-issue-term-refresh',
		defaultMessage:
			"Your backlog and sprint sections can each only display 5000 issues. It's time to move some issues.",
		description:
			'Description for flag when issue count in both the backlog and sprint sections have reached the limit on a TMP backlog',
	},
	tmpSprintsLimitReachedFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-reached-flag.tmp-sprints-limit-reached-flag-body-issue-term-refresh',
		defaultMessage:
			"We can only display 5000 issues across all your sprints. It's time to move some issues.",
		description:
			'Description for flag when issue count in the sprint section has reached the limit on a TMP backlog',
	},
	tmpBacklogLimitReachedFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-reached-flag.tmp-backlog-limit-reached-flag-body-issue-term-refresh',
		defaultMessage:
			"The backlog can only display 5000 issues, and your current backlog contains {issueCount}. It's time to move some issues.",
		description:
			'Description for flag when issue count in the backlog section has reached the limit on a TMP backlog',
	},
	tmpBacklogBoardLimitReachedFlagBodyIssueTermRefresh: {
		id: 'software-issue-limit-flags.limit-reached-flag.tmp-backlog-board-limit-reached-flag-body-issue-term-refresh',
		defaultMessage:
			"Your backlog and board sections can each only display 5000 issues. It's time to move some issues.",
		description:
			'Description for flag when issue count in both the backlog and board sections have reached the limit on a TMP backlog',
	},
});
