import { useDevOpsDetailsDialogLoaderActions } from '@atlassian/jira-development-details-loader/src/controllers/main.tsx';
import {
	DEPLOYMENT_PANEL_DATA_TYPE,
	COMMIT_PANEL_DATA_TYPE,
} from '@atlassian/jira-development-details/src/common/model/constants';
import { parseAri, type Ari } from '@atlassian/jira-platform-ari';
import { DevInfoTypes } from '../../../types';

export const parseIssueId = (issueAri: Ari) => parseAri(issueAri)?.resourceId;

const devInfoTypeToPanelDataType = (devInfoType: string) => {
	if (devInfoType === DevInfoTypes.COMMIT) {
		return COMMIT_PANEL_DATA_TYPE;
	}

	if (devInfoType === DevInfoTypes.DEPLOYMENT) {
		return DEPLOYMENT_PANEL_DATA_TYPE;
	}

	return devInfoType;
};

export function useShowDevDetailDialogForPopup(scopeId?: string) {
	const { showDevDetailDialog } = useDevOpsDetailsDialogLoaderActions(
		scopeId || 'software-board.board',
	);
	const showDevDetailDialogForPopup = (devInfoType: string, issueAri: Ari) => {
		const type = devInfoTypeToPanelDataType(devInfoType);
		const issueId = parseIssueId(issueAri);
		// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'PanelDataTypes'.
		showDevDetailDialog(type, undefined, '', issueId);
	};
	return {
		showDevDetailDialogForPopup,
	};
}
