import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	customFieldSettingFieldValuePlaceholder: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.board-scope-filter.custom-field-setting-field-value-placeholder',
		defaultMessage: 'Select value',
		description: 'Placeholder for the custom field value',
	},
	customFieldSettingFieldValueLabel: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.board-scope-filter.custom-field-setting-field-value-label',
		defaultMessage: 'Value',
		description: 'Label for the custom field value',
	},
	scopeFilterFieldValueLabel: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.board-scope-filter.scope-filter-field-value-label',
		defaultMessage: 'Value',
		description: 'Label for the program board scope filter value',
	},
	scopeFilterFieldValuePlaceholder: {
		id: 'portfolio-3-plan-increment-common.ui.increment-creation.board-scope-filter.scope-filter-field-value-placeholder',
		defaultMessage: 'Select value',
		description: 'Placeholder for the program board scope filter value',
	},
});

export default messages;
