import { combineReducers } from 'redux';
import type { Action } from '../../../types';
import { boardAddNewIssueToSprintReducer } from './add-new-issue-to-sprint/reducer';
import { displayOptionsReducer } from './display-options/reducer';
import { isDraggingReducer } from './drag/reducer';
import { isIpBoardFailedReducer } from './ip-board-failed/reducer';
import { isPollingEnabledReducer } from './is-polling-enabled/reducer';
import type { BoardState } from './types';

export default combineReducers<BoardState, Action>({
	isPollingEnabled: isPollingEnabledReducer,
	isDragging: isDraggingReducer,
	displayOptions: displayOptionsReducer,
	boardAddNewIssueToSprint: boardAddNewIssueToSprintReducer,
	isIpBoardFailed: isIpBoardFailedReducer,
});
