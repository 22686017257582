import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { EligibilityChecker } from './eligibility-checker';

export const FeatureGateChecker = ({ experiment }: { experiment: JSX.Element }): JSX.Element => {
	const isInGate = !!fg('jira_recommended_trial_to_free_ab_gate');

	if (isInGate) {
		return <EligibilityChecker>{experiment}</EligibilityChecker>;
	}
	return <></>;
};
