import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	buttonLabel: {
		id: 'software.main.header.menu.button-label',
		defaultMessage: 'More',
		description: 'Label for the overflow menu button',
	},
	dropdownAriaLabel: {
		id: 'software.main.header.menu.dropdown-aria-label',
		defaultMessage: 'Board',
		description: 'Message for screen readers',
	},
	iconButtonLabel: {
		id: 'software.main.header.menu.icon-button-label',
		defaultMessage: 'Board menu',
		description: 'Label for the overflow menu button and message for screen readers',
	},
});
