import flow from 'lodash/flow';
import memoizeOne from 'memoize-one';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux';
import { Capability } from '../../common/capability';
import { CAN_CREATE_COLUMNS } from '../../model/permission/permission-types';
import { NO_SWIMLANE } from '../../model/swimlane/swimlane-modes';
import { preventInlineEditingAction } from '../../state/actions/board/prevent-inline-editing';
import { boardNonCriticalView } from '../../state/actions/work';
import { getPermissionsSelector } from '../../state/selectors/board/board-permissions-selectors';
import {
	getPreventInlineEditing,
	shouldRenderSwimlaneSelector,
} from '../../state/selectors/board/board-selectors';
import { getCapability } from '../../state/selectors/capability/capability-selectors';
import {
	getIsCMPBoard,
	getLocale,
	sessionIdSelector,
	getIsIncrementPlanningBoard,
} from '../../state/selectors/software/software-selectors';
import { isSprintsEnabled } from '../../state/selectors/sprint/sprint-selectors';
import { getSwimlaneMode } from '../../state/selectors/swimlane/swimlane-mode-selectors';
import {
	getIsUnscheduledColumnVisible,
	isBoardConfigLoaded,
	isInlineColumnEditEnabled,
} from '../../state/selectors/work/work-selectors';
import type { Dispatch, State } from '../../state/types';
import Board from './board';
import BacklogIssueMoveDialog from './board/backlog-issue-move-dialog';
import ColumnConfigurationButton from './board/column-configuration';
import ColumnCreate from './board/column-create';
import KeyboardShortcuts from './board/keyboard-shortcuts';
import BoardMinimap from './board/minimap';
import ScrollObserver from './scroll-observer';
import SubmitApdex from './submit-apdex';
import BoardContainer from './view';

type OwnProps = {
	isCacheHit: boolean;
	onDragEnd?: () => void;
};

export const mapStateToProps = (state: State) => {
	const permissions = getPermissionsSelector(state);
	const hasSwimlanes = getSwimlaneMode(state) !== NO_SWIMLANE.id;
	const boardSessionId = sessionIdSelector(state);
	const isNonCriticalWorksetLoaded = isBoardConfigLoaded(state);
	const locale = getLocale(state);
	const preventInlineEditing = getPreventInlineEditing(state);
	const isIncrementPlanningBoard = getIsIncrementPlanningBoard(state);
	const isUnscheduledColumnVisible = isIncrementPlanningBoard
		? getIsUnscheduledColumnVisible(state)
		: undefined;
	const isCMPBoard = getIsCMPBoard(state);
	// This hides the ColumnConfiguration button for CMP
	const isColumnConfigurationButtonEnabled = !isCMPBoard;

	return {
		isColumnCreateAllowed: permissions[CAN_CREATE_COLUMNS] || false,
		isInlineColumnEditEnabled: isInlineColumnEditEnabled(state),
		isFlexibleColumns: getCapability(state)(Capability.FLEXIBLE_BOARD_COLUMNS),
		hasSwimlanes,
		isNonCriticalWorksetLoaded,
		boardSessionId,
		locale,
		preventInlineEditing,
		shouldRenderSwimlane: shouldRenderSwimlaneSelector(state),
		isSprintsEnabled: isSprintsEnabled(state),
		isIncrementPlanningBoard,
		isColumnConfigurationButtonEnabled,
		isUnscheduledColumnVisible,
	};
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
	dispatch,
});

const onBoardNonCriticalViewedFactory = memoizeOne(
	(dispatch: Dispatch) => (analyticsEvent: UIAnalyticsEvent) =>
		// @ts-expect-error - Argument of type 'BoardNonCriticalViewAction' is not assignable to parameter of type 'Action'.
		dispatch(boardNonCriticalView(analyticsEvent)),
);
const onPreventInlineEditingFactory = memoizeOne(
	(dispatch: Dispatch) => (shouldPreventInlineEditing: boolean) =>
		dispatch(preventInlineEditingAction(shouldPreventInlineEditing)),
);

const mergeProps = (
	stateProps: ReturnType<typeof mapStateToProps>,
	dispatchProps: ReturnType<typeof mapDispatchToProps>,
	ownProps: OwnProps,
) => {
	const { isCacheHit } = ownProps;

	return {
		...ownProps,
		...stateProps,
		isCacheHit,
		Board,
		BacklogIssueMoveDialog,
		KeyboardShortcuts,
		ColumnCreate,
		ColumnConfigurationButton,
		BoardMinimap,
		SubmitApdex,
		ScrollObserver,
		onBoardNonCriticalViewed: onBoardNonCriticalViewedFactory(dispatchProps.dispatch),
		onPreventInlineEditing: onPreventInlineEditingFactory(dispatchProps.dispatch),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export default flow(
	AnalyticsEventToProps('board', {
		onBoardNonCriticalViewed: 'viewed',
	}),
	connector,
	AnalyticsSubject('boardNonCritical'),
)(BoardContainer);
