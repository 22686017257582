import { styled as styled2 } from '@compiled/react';
import { colors } from '@atlaskit/theme';
// eslint-disable-next-line import/order
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FilterBarOld = styled2.div({
	display: 'flex',
	position: 'relative',
	alignItems: 'center',
	minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FilterBarNew = styled2.div({
	display: 'flex',
	position: 'relative',
	alignItems: 'center',
	minWidth: 0,
	gap: token('space.100'),
});

export const FilterBar = componentWithCondition(isVisualRefreshEnabled, FilterBarNew, FilterBarOld);

// TODO: https://product-fabric.atlassian.net/browse/DSP-8582
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ListFilterContainer = styled2.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not(:empty)': {
		display: 'flex',
		marginLeft: token('space.150', '12px'),
		paddingLeft: token('space.050', '4px'),
		position: 'relative',
		'&::before': {
			content: "''",
			position: 'absolute',

			top: '50%',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			height: `${gridSize * 2}px`,
			transform: 'translateY(-50%)',
			width: '1px',
			left: 0,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			background: token('color.background.accent.purple.subtle', colors.N40),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ListFilterContainerNextGenOld = styled2.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not(:empty)': {
		display: 'flex',
		marginLeft: token('space.025', '2px'),
		paddingLeft: token('space.050', '4px'),
		position: 'relative',
		minWidth: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ListFilterContainerNextGenNew = styled2.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not(:empty)': {
		display: 'flex',
		position: 'relative',
		minWidth: 0,
	},
});

export const ListFilterContainerNextGen = componentWithCondition(
	isVisualRefreshEnabled,
	ListFilterContainerNextGenNew,
	ListFilterContainerNextGenOld,
);
