import React from 'react';

import { di } from 'react-magnetic-di';

import Icon from '@atlaskit/icon';
import CreateRuleIconLegacy from '@atlaskit/icon/glyph/add';
import AuditLogIconLegacy from '@atlaskit/icon/glyph/check-circle-outline';
import CreateRuleIcon from '@atlaskit/icon/utility/add';
import AuditLogIcon from '@atlaskit/icon/utility/check-circle';
import { LinkItem, Section } from '@atlaskit/menu';

import { ManageRulesGlyph, MenuIcon } from '../../common/ui/icon';

import messages from './messages';
/**
 * baseAutomationUrl - the host url you'd like the create/manage automations links to route to
 * createAutomationComponentFilter - optional category string to apply as a filter when routing
 * the create automation button
 * onCreateAutomationFooterLinkClicked - is called when create automation is clicked
 * onManageAutomationsFooterLinkClicked - is called when manage automations is clicked
 * onAuditLogFooterLinkClicked - is called when audit log is clicked
 * */
interface Props {
	baseAutomationUrl: string;
	createAutomationComponentFilter?: string;
	onCreateAutomationFooterLinkClicked?: () => void;
	onManageAutomationsFooterLinkClicked?: () => void;
	onAuditLogFooterLinkClicked?: () => void;
}

const handleOptionalCallback = (optionalEvent?: () => void) => {
	if (optionalEvent) {
		optionalEvent();
	}
};

export default ({
	baseAutomationUrl,
	createAutomationComponentFilter,
	onCreateAutomationFooterLinkClicked,
	onManageAutomationsFooterLinkClicked,
	onAuditLogFooterLinkClicked,
}: Props) => {
	di(AuditLogIcon, CreateRuleIcon, Icon, MenuIcon, Section);
	const componentFilter = createAutomationComponentFilter
		? `?componentFilterCriteria=${createAutomationComponentFilter}`
		: '';
	const createNewRuleUrl = `${baseAutomationUrl}#/rule/new/__NEW__TRIGGER${componentFilter}`;

	return (
		<Section hasSeparator isList>
			<LinkItem
				onClick={() => handleOptionalCallback(onCreateAutomationFooterLinkClicked)}
				href={createNewRuleUrl}
				testId="create-automation-link"
				iconBefore={
					<MenuIcon>
						<CreateRuleIcon
							LEGACY_size="small"
							label=""
							LEGACY_fallbackIcon={CreateRuleIconLegacy}
							color="currentColor"
						/>
					</MenuIcon>
				}
			>
				{messages.createAutomationItem.defaultMessage}
			</LinkItem>
			<LinkItem
				onClick={() => handleOptionalCallback(onManageAutomationsFooterLinkClicked)}
				href={baseAutomationUrl}
				testId="manage-automations-link"
				iconBefore={
					<MenuIcon>
						<Icon size="small" glyph={ManageRulesGlyph} label="" />
					</MenuIcon>
				}
			>
				{messages.manageAutomationsItem.defaultMessage}
			</LinkItem>
			<LinkItem
				onClick={() => {
					handleOptionalCallback(onAuditLogFooterLinkClicked);
				}}
				href={`${baseAutomationUrl}#tab/audit-log`}
				testId="audit-log-link"
				iconBefore={
					<MenuIcon>
						<AuditLogIcon
							LEGACY_size="small"
							label=""
							LEGACY_fallbackIcon={AuditLogIconLegacy}
							color="currentColor"
						/>
					</MenuIcon>
				}
			>
				{messages.auditLogItem.defaultMessage}
			</LinkItem>
		</Section>
	);
};
