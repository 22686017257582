import type { Dispatch, Middleware } from 'redux';
import log from '@atlassian/jira-common-util-logging/src/log';
import { captureException } from './sentry';

type ActionConstraint = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	type: any;
};

/**
 * Catches errors within redux reducers and makes them available to monitoring.
 *
 * Takes an optional error action creator as a parameter to be reduced when an error is encountered.
 * This could be used to show an error flag, for example.
 *
 * Should be passed as part of the middleware array when creating the store.
 * E.g. [middlewareOne, errorHandling('your-app-name')]
 */
const errorHandling =
	<S, Action extends ActionConstraint>(
		appId: string,
		errorActionCreator?: (() => Action) | null,
		/**
		 * Callback for errors
		 */
		onError?: ((error: Error) => void) | null,
		// @ts-expect-error - TS2315 - Type 'Middleware' is not generic.
	): Middleware<S, Action> =>
	() =>
	(next: Dispatch<Action>) =>
	(action: Action) => {
		try {
			next(action);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			const fullLocation = `common.redux-error.${appId}`;

			log.safeErrorWithoutCustomerData(
				fullLocation,
				`Unhandled error for Redux action: '${action?.type}'`,
				error,
			);
			captureException(fullLocation, error);

			errorActionCreator && next(errorActionCreator());
			onError && onError(error);
		}
	};
export default errorHandling;
