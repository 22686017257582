import React from 'react';
import isNil from 'lodash/isNil';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux';
import type { IssueId } from '@atlassian/jira-software-board-common';
import { useShortcutAnalytics } from '@atlassian/jira-software-keyboard-shortcuts/src/utils/analytics/index.tsx';
import type { IssueKey } from '../../../../model/issue/issue-types';
import {
	ASSIGNEE_SHORTCUT_KEY,
	LABELS_SHORTCUT_KEY,
	COMMENT_SHORTCUT_KEY,
} from '../../../../model/keyboard-shortcuts/constants';
import { NO_SWIMLANE } from '../../../../model/swimlane/swimlane-modes';
import { toggleBoardMenus } from '../../../../state/actions/board/menu';
import { cardNext, cardPrev, cardNextColumn, cardPrevColumn } from '../../../../state/actions/card';
import { assignToMeRequestThrottled } from '../../../../state/actions/issue/assign-to-me';
import { openIssueModal, closeIssueModal } from '../../../../state/actions/issue/modal';
import { issueMoveToTopOrBottomOfContainer } from '../../../../state/actions/issue/rank-transition';
import { swimlanesToggle } from '../../../../state/actions/swimlane';
import type { State } from '../../../../state/reducers/types';
import {
	getCardActive,
	getCardActiveKey,
	getCardActiveAssignee,
} from '../../../../state/selectors/card/card-selectors';
import { getIssueKeyForModal } from '../../../../state/selectors/issue/issue-modal-selectors';
import { getCurrentUserAccountId } from '../../../../state/selectors/people/people-selectors';
import { getIsCMPBoard } from '../../../../state/selectors/software/software-selectors';
import { getSwimlaneMode } from '../../../../state/selectors/swimlane/swimlane-mode-selectors';
import { platformSwimlaneIdsSelector } from '../../../../state/selectors/work/work-selectors';
import type { Dispatch } from '../../../../state/types';
import type { StateProps, OwnProps, DispatchProps } from './types';
import { getExternalActionForKey } from './utils';
import { KeyboardShortcuts } from './view';

export const mapStateToProps = (state: State): StateProps => {
	const activeIssueKey = getCardActiveKey(state);
	const activeIssueId = getCardActive(state);
	const activeIssueAssignee = getCardActiveAssignee(state);
	const currentUserAccountId = getCurrentUserAccountId(state);
	const issueKeyForModal = getIssueKeyForModal(state);
	const swimlaneIds = platformSwimlaneIdsSelector(state);
	const hasSwimlanes = getSwimlaneMode(state) !== NO_SWIMLANE.id;
	const isCMPBoard = getIsCMPBoard(state);

	return {
		activeIssueKey,
		activeIssueId,
		activeIssueAssignee,
		currentUserAccountId,
		issueKeyForModal,
		swimlaneIds,
		hasSwimlanes,
		isCMPBoard,
	};
};

const bentoShortcutsAnalyticsMap = {
	[ASSIGNEE_SHORTCUT_KEY]: 'assignIssueKeyboardShortcut',
	[LABELS_SHORTCUT_KEY]: 'editIssueLabelsKeyboardShortcut',
	[COMMENT_SHORTCUT_KEY]: 'commentIssueKeyboardShortcut',
} as const;

export const mapDispatchToProps = (
	dispatch: Dispatch,
	{ fireShortcutAnalytics, analyticsEvent }: OwnProps,
): DispatchProps => {
	const onNextIssue = () => {
		// @ts-expect-error - Argument of type '{ type: string; payload: { fireShortcutAnalyticsFn: FireShortcutAnalyticsFn; }; }' is not assignable to parameter of type 'Action'.
		dispatch(cardNext(fireShortcutAnalytics));
	};
	const onPrevIssue = () => {
		// @ts-expect-error - Argument of type '{ type: string; payload: { fireShortcutAnalyticsFn: FireShortcutAnalyticsFn; }; }' is not assignable to parameter of type 'Action'.
		dispatch(cardPrev(fireShortcutAnalytics));
	};
	const onNextColumn = () => {
		// @ts-expect-error - Argument of type '{ type: string; payload: { fireShortcutAnalyticsFn: FireShortcutAnalyticsFn; }; }' is not assignable to parameter of type 'Action'.
		dispatch(cardNextColumn(fireShortcutAnalytics));
	};
	const onPrevColumn = () => {
		// @ts-expect-error - Argument of type '{ type: string; payload: { fireShortcutAnalyticsFn: FireShortcutAnalyticsFn; }; }' is not assignable to parameter of type 'Action'.
		dispatch(cardPrevColumn(fireShortcutAnalytics));
	};

	// @ts-expect-error - TS7006 - Parameter 'placement' implicitly has an 'any' type.
	const onMoveToTopOrBottomOfContainer = (placement) => {
		dispatch(issueMoveToTopOrBottomOfContainer(placement, fireShortcutAnalytics, analyticsEvent));
	};

	const onAssignToMe = (
		activeIssueId: IssueId | null | undefined,
		activeIssueAssignee: string | null | undefined,
		currentUserAccountId: null | string,
	) => {
		if (isNil(activeIssueId)) return;

		const assigneeAccountId =
			currentUserAccountId === activeIssueAssignee ? null : currentUserAccountId;

		dispatch(assignToMeRequestThrottled(activeIssueId, assigneeAccountId, fireShortcutAnalytics));
	};

	const onToggleBentoViewForFocusedCard = (
		activeIssueKey: IssueKey | null | undefined,
		issueKeyForModal: IssueKey | null | undefined,
		isDetailViewInSidebar: boolean,
	) => {
		if (isNil(activeIssueKey)) return;

		let isOpeningDetailsView;
		if (issueKeyForModal === activeIssueKey) {
			// If modal is currently open, this shortcut does not close the modal,
			// (since Bento would be in control). This however will close an open sidebar.
			dispatch(closeIssueModal(activeIssueKey));
			isOpeningDetailsView = false;
		} else {
			dispatch(openIssueModal(activeIssueKey));
			isOpeningDetailsView = true;
		}

		fireShortcutAnalytics({
			key: 't',
			id: 'toggleDetailsViewKeyboardShortcut',
			attributes: {
				toggled: isOpeningDetailsView ? 'open' : 'closed',
				issueView: isDetailViewInSidebar ? 'sideView' : 'modalView',
			},
		});
	};

	const onOpenAndFocusBentoField = (
		shortcutKey: string,
		activeIssueKey: IssueKey | null | undefined,
	) => {
		if (isNil(activeIssueKey)) {
			return;
		}

		const externalAction = getExternalActionForKey(shortcutKey);
		if (!externalAction) {
			return;
		}

		dispatch(openIssueModal(activeIssueKey, externalAction));

		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly a: "assignIssueKeyboardShortcut"; readonly l: "editIssueLabelsKeyboardShortcut"; readonly m: "commentIssueKeyboardShortcut"; }'.
		if (bentoShortcutsAnalyticsMap[shortcutKey]) {
			fireShortcutAnalytics({
				key: shortcutKey,
				// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly a: "assignIssueKeyboardShortcut"; readonly l: "editIssueLabelsKeyboardShortcut"; readonly m: "commentIssueKeyboardShortcut"; }'.
				id: bentoShortcutsAnalyticsMap[shortcutKey],
			});
		}
	};

	// remove onShowHideMenu when removing Capability.IS_TAB_NAVIGATION
	const onShowHideMenu = () => {
		dispatch(toggleBoardMenus());
		fireShortcutAnalytics({
			key: 'z',
			id: 'toggleMenuKeyboardShortcut',
		});
	};

	return {
		onNextIssue,
		onPrevIssue,
		onNextColumn,
		onPrevColumn,
		onAssignToMe,
		onToggleBentoViewForFocusedCard,
		onOpenAndFocusBentoField,
		onMoveToTopOrBottomOfContainer,
		onSwimlanesToggle: (swimlaneIds) => {
			dispatch(swimlanesToggle(swimlaneIds));
			fireShortcutAnalytics({ id: 'toggleSwimlanesKeyboardShortcut', key: '-' });
		},
		onOpenFocusedCard: (activeIssueKey) => {
			if (isNil(activeIssueKey)) return;
			dispatch(openIssueModal(activeIssueKey));
			fireShortcutAnalytics({ id: 'viewIssueKeyboardShortcut', key: 'o' });
		},
		onShowHideMenu,
	};
};

const ConnectedKeyboardShortcuts = connect(mapStateToProps, mapDispatchToProps)(KeyboardShortcuts);

const KeyboardShortcutsWithAnalytics = (props: OwnProps) => {
	const fireShortcutAnalytics = useShortcutAnalytics('board');

	/*
        new analyticsEvent required to use existing card reranking actions
    */
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		actionSubject: 'keyboardShortcut',
		action: 'pressed',
	});
	return (
		<ConnectedKeyboardShortcuts
			{...props}
			fireShortcutAnalytics={fireShortcutAnalytics}
			analyticsEvent={analyticsEvent}
		/>
	);
};

export default KeyboardShortcutsWithAnalytics;
