import type { OptimisticId } from '../../../../model/software/software-types';
import type { FlagMeta } from '../../meta/flag-meta';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiWithFlagMeta,
	type OptimisticUiMeta,
} from '../../meta/optimistic-ui-meta';

export const RENAME_BOARD_REQUEST = 'state.actions.board.rename.RENAME_BOARD_REQUEST' as const;

export type RenameBoardRequestAction = {
	type: typeof RENAME_BOARD_REQUEST;
	payload: string;
	meta: OptimisticUiMeta;
};

export const renameBoardRequest = (title: string): RenameBoardRequestAction => ({
	type: RENAME_BOARD_REQUEST,
	payload: title,
	meta: beginOptimisticUiMeta(),
});

export const RENAME_BOARD_SUCCESS = 'state.actions.board.rename.RENAME_BOARD_SUCCESS' as const;

export type RenameBoardSuccessAction = {
	type: typeof RENAME_BOARD_SUCCESS;
	meta: OptimisticUiMeta;
};

export const renameBoardSuccess = (optimisticId: OptimisticId): RenameBoardSuccessAction => ({
	type: RENAME_BOARD_SUCCESS,
	meta: commitOptimisticUiMeta(optimisticId),
});

export const RENAME_BOARD_FAILURE = 'state.actions.board.rename.RENAME_BOARD_FAILURE' as const;

export type RenameBoardFailureAction = {
	type: typeof RENAME_BOARD_FAILURE;
	meta: OptimisticUiMeta & FlagMeta;
};

export const renameBoardFailure = (optimisticId: OptimisticId): RenameBoardFailureAction => ({
	type: RENAME_BOARD_FAILURE,
	meta: revertOptimisticUiWithFlagMeta(optimisticId),
});

export type Action = RenameBoardRequestAction | RenameBoardSuccessAction | RenameBoardFailureAction;
