import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import startOfWeek from 'date-fns/startOfWeek';

import { type Variant } from '../types';
import { type DayTimePickerValue } from '../ui/day-time-picker/types';
import { type DayTimeRangePickerValue } from '../ui/day-time-range-picker/types';

import { type DateFNSWeekDay, type WeekDay } from './types';

const dayLabelFormat = {
	default: 'EEEE',
	compact: 'EEE',
};

export const getDayOptions = (
	params = { weekStartDay: 1 as WeekDay },
	variant: Variant = 'default',
) => {
	if (params.weekStartDay > 8) {
		params.weekStartDay = params.weekStartDay % 8;
	}

	const firstDOW = startOfWeek(new Date(), {
		weekStartsOn: (params.weekStartDay - 1) as DateFNSWeekDay,
	});

	const weekDays = Array.from(Array(7)).map((e, i) => ({
		label: format(addDays(firstDOW, i), dayLabelFormat[variant]),
		value: ((i + params.weekStartDay - 1) % 7) + 1,
	}));

	return weekDays as {
		label: string;
		value: WeekDay;
	}[];
};

export const getDefaultTimes = () => {
	return [...Array(24).keys()]
		.map((time) => {
			let newTime = time.toString();
			if (newTime.length === 1) {
				newTime = `0${time}`;
			}
			return [`${newTime}:00`, `${newTime}:30`];
		})
		.flatMap((time) => time);
};

export const createEmptyIntervalValue: () => DayTimeRangePickerValue = () => {
	return {
		startDay: null,
		startHour: null,
		startMinute: null,
		endDay: null,
		endHour: null,
		endMinute: null,
	};
};

export const getDayValue = (
	value: DayTimePickerValue | null | undefined,
	variant: Variant = 'default',
) => {
	if (value) {
		if (value.day) {
			const dayOptions = getDayOptions(undefined, variant);
			const dayValue = dayOptions.find((day) => day.value === value.day);

			return dayValue || undefined;
		}
	}

	return undefined;
};

export const getTimeValue = (value: DayTimePickerValue | null | undefined) => {
	if (value) {
		const isTimeSet = (value.hour || value.hour === 0) && (value.minute || value.minute === 0);

		if (isTimeSet) {
			if (value.hour! > 24 || value.minute! > 60) {
				return undefined;
			}

			const formattedTimeValue = `${value.hour || '00'}:${value.minute || '00'}`;

			return formattedTimeValue || undefined;
		}
	}

	return undefined;
};
