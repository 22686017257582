import {
	createStore,
	createContainer,
	createHook,
	createActionsHook,
	defaultRegistry,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import { type Actions, actions } from './actions';
import type { State, ContainerProps } from './types';

export const EMPTY_SPRINTS = [];

// This is the value of the store on initialisation
const initialState: State = {
	sprints: EMPTY_SPRINTS,
	isAutoOpened: false,
	isNbtToggleButtonVisible: false,
	isOnlyCurrentUserSelected: false,
	isHiddenByUser: false,
	queryGithubOnboardingRef: null,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onIssueOpen: () => {},
	activeViewSettingsSpotlight: null,
	hasViewedViewSettingsSpotlight: true,
	openViewSettings: null,
	viewSettingsTogglePulses: false,
} as const;

const Store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'jira.insights.next-best-task-store',
});

export const resetInsightsNBTStore = () => defaultRegistry.deleteStore(Store);

const handlePropsChange =
	() =>
	(
		{ setState }: StoreActionApi<State>,
		{ accountId, boardId, isCMPBoard, sprints, onIssueOpen }: ContainerProps,
	) => {
		setState({
			accountId,
			boardId,
			isCMPBoard,
			sprints,
			onIssueOpen,
		});
	};

export const useSprints = createHook(Store, {
	selector: (state) => state.sprints || EMPTY_SPRINTS,
});

export const NextBestTaskContainer = createContainer<State, Actions, ContainerProps>(Store, {
	onInit: handlePropsChange,
	onUpdate: handlePropsChange,
});

export const useNextBestTaskContainer = createHook(Store);

export const useNextBestTaskActions = createActionsHook(Store);
