import { ff } from '@atlassian/jira-feature-flagging';
import type { MobileRestApiResponse } from '@atlassian/jira-software-board-uif-types';
import { type FetchUIFBoardDataParams, fetchUIFBoardData } from '../fetch-uif-board-data';
import { setBoardCachedEntry } from '../uif-board-cache';

export interface BoardDataStreamResult {
	cachedPromise: Promise<MobileRestApiResponse | null>;
	refreshPromise: Promise<MobileRestApiResponse>;
}

export function fetchUIFBoardDataStream({
	boardId,
	queryParameters,
	fields,
}: FetchUIFBoardDataParams): BoardDataStreamResult {
	const { cachedPromise: cachedBoardData, promise } = fetchUIFBoardData({
		boardId,
		queryParameters,
		fields,
	});

	const cachedPromise = cachedBoardData.then((cache) => cache?.data ?? null);

	const refreshPromise = (async () => {
		const response = await promise;

		if (ff('jsw.cmp.response-null-abort') && !response) {
			const error = new Error('CMP fetchUIFBoardData returned null');
			error.name = 'ResponseNullError';
			throw error;
		}

		setBoardCachedEntry(
			{ boardId },
			{
				timestamp: Date.now(),
				etag: response.etag,
				data: response,
			},
		);

		return response;
	})();

	return { cachedPromise, refreshPromise };
}
