import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { useIsIssueLinkDetailsPopupOpen } from '../../controllers/main';
import { IssueLinksDetailsPopupEntryPointContainer } from './issue-links-details-popup-entrypoint-container';
import { messages } from './messages';
import type { IssueLinksStatsButtonProps } from './types';

// Replace with lodash/noop
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export const IssueLinksStatsButton = ({
	issueLinkCount,
	issueKey,
	issueId,
	issueSummary,
	issueType,
	isFocusOnTrigger,
	feedbackCollectorPrefix,
	onIssueUnlink,
	onFocusToTrigger,
	canUserLinkIssue,
	withDueDate = false,
	maxIssueLinksStatsCount,
	sourceProductType,
	onSuccessfulIssueLink = noop,
	isLinkIssuePopupEnabled = false,
}: IssueLinksStatsButtonProps) => {
	const { formatMessage } = useIntl();
	const isOpen = useIsIssueLinkDetailsPopupOpen({
		openedIssueKey: issueKey,
	});

	const issueLinkCountDisplay =
		issueLinkCount > maxIssueLinksStatsCount ? `${maxIssueLinksStatsCount}+` : issueLinkCount;

	const tooltipContent =
		issueLinkCount > maxIssueLinksStatsCount
			? formatMessage(messages.genericMaxIssueLinksStatsTooltipLabel, {
					maxIssueLinksStatsCount,
				})
			: formatMessage(messages.issueLinksTooltipLabel, {
					issueLinkCount,
				});

	return (
		<Tooltip
			content={isOpen ? '' : tooltipContent}
			testId="issue-links-stats.ui.issue-links-stats-button.linked-issue-stats-tooltip"
			delay={0}
		>
			<IssueLinksDetailsPopupEntryPointContainer
				isOpen={isOpen}
				isFocusOnTrigger={isFocusOnTrigger}
				issueId={issueId}
				issueLinkCount={issueLinkCountDisplay}
				issueSummary={issueSummary}
				issueType={issueType}
				issueKey={issueKey}
				feedbackCollectorPrefix={feedbackCollectorPrefix}
				onIssueUnlink={onIssueUnlink}
				onFocusToTrigger={onFocusToTrigger}
				canUserLinkIssue={canUserLinkIssue}
				iconLabel={tooltipContent}
				withDueDate={withDueDate}
				sourceProductType={sourceProductType}
				onSuccessfulIssueLink={onSuccessfulIssueLink}
				isLinkIssuePopupEnabled={isLinkIssuePopupEnabled}
			/>
		</Tooltip>
	);
};
