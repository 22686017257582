import React, { useCallback, useRef } from 'react';
import { useFlagsService } from '@atlassian/jira-flags';
import { getErrorFlag as getFlagFromError } from '@atlassian/jira-software-swag/src/services/error-to-flag/index.tsx';
import UndoFlag from '../../common/ui/undo-flag';
import { useClearCardsMutation } from '../../services/clear-cards-mutation/main.tsx';
import type { MutationResult as ClearCardsMutationResult } from '../../services/clear-cards-mutation/types.tsx';
import type { MutationResult as UnclearCardsMutationResult } from '../../services/unclear-cards-mutation/types.tsx';
import { useUnclearCards } from '../unclear';

type MutationArgs = {
	cardIds: (number | string)[];
	boardId: string;
	resolvedIssuesUrl: string;
	onUndo?: (promise: UnclearCardsMutationResult, retry: boolean) => void;
	/* When the undo flag is closed without the cards being restored */
	onUndoFlagDismissed?: () => void;
};
export type ClearCardsFunction = (arg1: MutationArgs) => ClearCardsMutationResult;

export const useClearCards = () => {
	const [clearMutation] = useClearCardsMutation();
	const [unclearCards] = useUnclearCards();
	const { showFlag, dismissFlag } = useFlagsService();
	const flagId = useRef<unknown>();

	const onDismissFlag = useCallback(() => {
		if (flagId.current) {
			// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'string'.
			dismissFlag(flagId.current);
		}
	}, [dismissFlag]);

	const callback = useCallback(
		({ cardIds, boardId, onUndo, onUndoFlagDismissed, resolvedIssuesUrl }: MutationArgs) => {
			const clearMutationPromise = clearMutation({ variables: { cardIds, boardId } });

			const onUndoCallback = () => {
				unclearCards({ cardIds, boardId, onUndo });
			};

			clearMutationPromise
				.then(() => {
					flagId.current = showFlag({
						render: (props) => (
							<UndoFlag
								resolvedIssuesUrl={resolvedIssuesUrl}
								numDoneCards={cardIds.length}
								onUndo={() => {
									onUndoCallback();
									onDismissFlag();
								}}
								onDismiss={() => {
									onUndoFlagDismissed && onUndoFlagDismissed();
									onDismissFlag();
								}}
								{...props}
							/>
						),
					});
				})
				.catch((error) => {
					showFlag(getFlagFromError(error));
				});

			return clearMutationPromise;
		},
		[clearMutation, onDismissFlag, showFlag, unclearCards],
	);

	return [callback] as const;
};
