import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	spotlightHeadingNonFinal: {
		id: 'software-onboarding-nudges--next.sample-project-data.spotlight-heading-non-final',
		defaultMessage: 'Let’s start with your tasks',
		description:
			'Spotlight heading that will show up to users enrolled in sample data crawl phase experiment. This Nudge guides the user to checkout out the pre-populated Jira tasks.',
	},
	spotlightContentNonFinal: {
		id: 'software-onboarding-nudges--next.sample-project-data.spotlight-content-non-final',
		defaultMessage:
			'We’ve created some starter tasks for you. Make them your own, or create a blank task to start tracking your work in Jira.',
		description:
			'Spotlight content that will show up to users enrolled in sample data crawl phase experiment. This Nudge guides the user to checkout out the pre-populated Jira tasks.',
	},
	spotlightDismissActionNonFinal: {
		id: 'software-onboarding-nudges--next.sample-project-data.spotlight-dismiss-action-non-final',
		defaultMessage: 'Dismiss',
		description: 'Spotlight content for action that will dismiss the nudge',
	},
	spotlightCtaActionNonFinal: {
		id: 'software-onboarding-nudges--next.sample-project-data.spotlight-cta-action-non-final',
		defaultMessage: 'View task',
		description: 'Spotlight content for action that will open up the pre-populated task',
	},
});
