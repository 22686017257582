import { setIn } from 'icepick';
import { FILTERS_SET, SET_ALL_FILTERS } from '../../../actions/filter';
import type { Action } from '../../../types';
import type { FilterState } from './types';

const initialState = {
	values: {},
} as const;

export const workFiltersReducer = (
	state: FilterState = initialState,
	action: Action,
): FilterState => {
	if (action.type === FILTERS_SET) {
		return setIn(state, ['values', action.payload.filterType], action.payload.values);
	}
	if (action.type === SET_ALL_FILTERS) {
		return setIn(state, ['values'], action.payload.filters);
	}

	return state;
};
