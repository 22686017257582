import { connect } from '@atlassian/jira-react-redux';
import { setAllFilters } from '../../state/actions/filter';
import type { Dispatch } from '../../state/types';
import type { DispatchProps } from './types';
import CardCreateUnmappedFlagInIpBoard from './view';

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onClearAllButtonClicked: () => {
		dispatch(setAllFilters({}));
	},
});

export default connect(null, mapDispatchToProps)(CardCreateUnmappedFlagInIpBoard);
