import React, { useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { columnHeaderHeight } from '../../../../common/constants/styles';

export function LoadingContainer() {
	const [showSpinner, setShowSpinner] = useState(false);

	useEffect(() => {
		const timeoutId = setTimeout(() => setShowSpinner(true), 1000);

		return () => clearTimeout(timeoutId);
	}, []);

	return <Wrapper>{showSpinner ? <Spinner size="small" /> : null}</Wrapper>;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	position: 'sticky',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${columnHeaderHeight * 2}px`,
	alignItems: 'center',
	justifyContent: 'center',
});
