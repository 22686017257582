import React, { useCallback, type ComponentPropsWithoutRef } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { isValid } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import Heading from '@atlaskit/heading';
import { Box, xcss } from '@atlaskit/primitives';
import { colors, typography } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import {
	formatWithLocale,
	setNumberOffsetToISODate,
} from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import { useUserSubscriber } from '@atlassian/jira-user-services/src/main.tsx';
import { DEFAULT_DATE_DISPLAY_FORMAT_SHORT } from '../../../../model/constants';
import type { Sprint } from '../../../../model/sprint/sprint-types';
import messages from '../messages';

const MS_IN_HOURS = 3600000;

export const useFormatDate = () => {
	const [{ data: loggedInUser }] = useUserSubscriber();
	const { formatMessage } = useIntl();

	return useCallback(
		(locale: string, date: string) => {
			const utcOffset = Math.floor(
				getTimezoneOffset(loggedInUser?.timeZone ?? 'utc') / MS_IN_HOURS,
			);

			const format = DEFAULT_DATE_DISPLAY_FORMAT_SHORT;

			const parsedDate: Date = setNumberOffsetToISODate(date, utcOffset);
			return isValid(parsedDate)
				? formatWithLocale(parsedDate, format, locale)
				: formatMessage(messages.invalidDate);
		},
		[formatMessage, loggedInUser?.timeZone],
	);
};

type Props = {
	contentHeader?: string;
	startDate: NonNullable<Sprint['startDate']>;
	endDate: NonNullable<Sprint['endDate']>;
	locale: string;
};

const TooltipContent = ({ contentHeader, startDate, endDate, locale }: Props) => {
	const { formatMessage } = useIntl();
	const formatDate = useFormatDate();

	return (
		<ContentWrapper>
			{contentHeader && <dt>{contentHeader}</dt>}
			<DateLabelStyled>{formatMessage(messages.startDate)}</DateLabelStyled>
			<DateValueStyled>{formatDate(locale, startDate)}</DateValueStyled>
			<DateLabelStyled>{formatMessage(messages.endDate)}</DateLabelStyled>
			<DateValueStyled>{formatDate(locale, endDate)}</DateValueStyled>
		</ContentWrapper>
	);
};

export default TooltipContent;

const DateLabelStyledWrapper = (
	props: ComponentPropsWithoutRef<typeof DateLabelStyledExperiment>,
) => (
	<Box as="dt" xcss={dateLabelStyledStyles}>
		<Heading level="h400">
			<DateLabelStyledExperiment {...props} />
		</Heading>
	</Box>
);

const dateLabelStyledStyles = xcss({
	marginLeft: 'space.0',
	marginTop: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const ContentWrapper = styled2.dl(() => ({
	padding: token('space.050', '4px'),
	marginTop: 0,
}));

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DateLabelStyledControl = styled.dt`
	${typography.h400()};
	color: ${token('color.text.inverse', colors.N0)};
	margin-left: 0;
	margin-top: ${token('space.050', '4px')};
	&:first-of-type {
		margin-top: 0;
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DateLabelStyledExperiment = styled2.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.inverse', colors.N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-of-type': {
		marginTop: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DateLabelStyled = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	DateLabelStyledWrapper,
	DateLabelStyledControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DateValueStyled = styled2.dd({
	marginLeft: 0,
});
