import React from 'react';
import type { ActiveKeyMap } from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-registry';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { useViewMode } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { getWillShowNav3 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav3.tsx';
import { TOP_OF_CONTAINER, BOTTOM_OF_CONTAINER } from '../../../../model/constants';
import {
	ASSIGNEE_SHORTCUT_KEY,
	LABELS_SHORTCUT_KEY,
	COMMENT_SHORTCUT_KEY,
} from '../../../../model/keyboard-shortcuts/constants';
import type { Props } from './types';

const bentoShortcutKeys = [ASSIGNEE_SHORTCUT_KEY, LABELS_SHORTCUT_KEY, COMMENT_SHORTCUT_KEY];

export const KeyboardShortcuts = ({
	activeIssueId,
	activeIssueKey,
	activeIssueAssignee,
	currentUserAccountId,
	issueKeyForModal,
	hasSwimlanes,
	swimlaneIds,
	isCMPBoard,
	onOpenFocusedCard,
	onToggleBentoViewForFocusedCard,
	onSwimlanesToggle,
	onNextIssue,
	onPrevIssue,
	onNextColumn,
	onPrevColumn,
	onOpenAndFocusBentoField,
	onAssignToMe,
	onMoveToTopOrBottomOfContainer,
	onShowHideMenu,
}: Props) => {
	const viewMode = useViewMode();
	const isDetailViewInSidebar = viewMode === 'SIDEBAR';

	const keyMap: ActiveKeyMap = {
		...(hasSwimlanes && {
			'-': {
				callback: () => onSwimlanesToggle(swimlaneIds),
			},
		}),
		o: {
			callback: () => onOpenFocusedCard(activeIssueKey),
		},
		t: {
			callback: () =>
				onToggleBentoViewForFocusedCard(activeIssueKey, issueKeyForModal, isDetailViewInSidebar),
		},
		j: {
			callback: onNextIssue,
		},
		k: {
			callback: onPrevIssue,
		},
		n: {
			callback: onNextColumn,
		},
		p: {
			callback: onPrevColumn,
		},
		i: {
			callback: () => onAssignToMe(activeIssueId, activeIssueAssignee, currentUserAccountId),
		},
		// Show/Hide menu is also called Compact mode, this whole section should be removed when cleaning up getWillShowNav3
		// as the z key is already used for the FullscreenButton (see @atlassian/jira-fullscreen-button) and
		// compact mode does not exist in nav4
		...(getWillShowNav3() && isCMPBoard
			? {
					z: {
						callback: onShowHideMenu,
					},
				}
			: {}),
		's t': {
			callback: () => onMoveToTopOrBottomOfContainer(TOP_OF_CONTAINER),
		},
		's b': {
			callback: () => onMoveToTopOrBottomOfContainer(BOTTOM_OF_CONTAINER),
		},
	};

	bentoShortcutKeys.forEach((shortcutKey) => {
		keyMap[shortcutKey] = {
			callback: () => {
				onOpenAndFocusBentoField(shortcutKey, activeIssueKey);
			},
		};
	});

	return <Shortcuts keyMap={keyMap} location="software.board.keyboard-shortcuts" />;
};
