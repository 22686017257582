import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { BOARD_CREATE_COLUMN_BUTTON_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants';
import { OnboardingNudgeErrorBoundary } from '@atlassian/jira-onboarding-quickstart-core/src/common/ui/onboarding-nudge-error-boundary';
import type { QuickstartNudgeWrapperBaseProps } from '@atlassian/jira-onboarding-quickstart-core/src/common/ui/quickstart-nudge/types';
import Placeholder from '@atlassian/jira-placeholder';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const BoardCreateColumnButtonLazy = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-software-onboarding-board-create-column-nudge" */ './index'
		).then((exportedModule) => exportedModule.BoardCreateColumnButtonWrapperNudge),
	{
		ssr: false,
	},
);
export const BoardCreateColumnButtonWrapperNudgeAsync = ({
	children,
}: QuickstartNudgeWrapperBaseProps) => (
	<OnboardingNudgeErrorBoundary
		id={BOARD_CREATE_COLUMN_BUTTON_NUDGE_ID}
		fallback={children}
		attributes={{
			nudgeId: BOARD_CREATE_COLUMN_BUTTON_NUDGE_ID,
		}}
	>
		<Placeholder name="software-onboarding-board-create-column-nudge" fallback={<>{children}</>}>
			<BoardCreateColumnButtonLazy nudgeId={BOARD_CREATE_COLUMN_BUTTON_NUDGE_ID}>
				{children}
			</BoardCreateColumnButtonLazy>
		</Placeholder>
	</OnboardingNudgeErrorBoundary>
);
