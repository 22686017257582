/** @jsx jsx */
import React, { useRef, useState, useCallback, useMemo, Fragment } from 'react';
import { css, styled as styled2, jsx } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import Badge from '@atlaskit/badge';
import Button from '@atlaskit/button';
import ChevronDown from '@atlaskit/icon/glyph/chevron-down';
import { Popup, type TriggerProps } from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useResizeObserver as useResizeObserverPlatform } from '@atlassian/jira-react-use-resize-observer';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import messages from './messages';
import type { NonEmptyArray, SelectedItems, OverflowState, OverflowConfigItem } from './types';
import { useResizeObserver as useResizeObserverLocal } from './use-resize-observer';
import { calcOverflow, getShouldRecomputeOverflow, getSelectedItemsCount } from './utils';

interface Props {
	/** Items that will be rendered before the "more" dropdown when visible */
	itemsBefore: OverflowConfigItem[];
	/** Items that will be rendered after the "more" dropdown when visible */
	itemsAfter: OverflowConfigItem[];
	/** A collection of item selection status (useful for rendering a count badge on the "more" dropdown) */
	selectedItems?: SelectedItems;
}

const OverflowHandler = ({ itemsBefore, itemsAfter, selectedItems = {} }: Props) => {
	const { formatMessage } = useIntl();
	const overflowContainerRef = useRef<HTMLDivElement>(null);
	const moreFilterRef = useRef<HTMLElement>(null);
	const elementsRef = useRef<{ [id: string]: HTMLElement }>({});
	const [isOpen, setIsOpen] = useState<boolean>(false);

	// There is a flickering bug switching to/from the "more" button https://jplat.atlassian.net/browse/BLU-3488
	// Additional information can also be found in https://jbusiness.atlassian.net/browse/JVRQ-426
	// But can't reproduce in Storybook + lot of pending changes, so won't fix for now.
	// I suspect this is related to this out-of-rerender memorization of data.
	// Next person actively working on this file is welcome to check. See also investigation notes in the ticket above.
	const prevOverflowContainerWidthRef = useRef<number>(0);
	const elementsWidthRef = useRef<{ [id: string]: number }>({});
	const visibleElementsRef = useRef<{ [id: string]: { width: number; height: number } }>({});
	const prevElementsWidthRef = useRef<{ [id: string]: number }>({});

	const [{ overflowableHideState, isMoreDropdownHidden }, setOverflowState] =
		useState<OverflowState>({
			overflowableHideState: {},
			isMoreDropdownHidden: true,
		});

	const { renderedOverflowableItems, staticItems, renderedItemsBefore, renderedItemsAfter } =
		useMemo(() => {
			const theOverflowable: OverflowConfigItem[] = [];
			const theStatic: OverflowConfigItem[] = [];
			const theRenderedBefore: OverflowConfigItem[] = [];
			const theRenderedAfter: OverflowConfigItem[] = [];

			const categoriseItems = ({ isRenderedBefore }: { isRenderedBefore: boolean }) => {
				(isRenderedBefore ? itemsBefore : itemsAfter).forEach((item: OverflowConfigItem) => {
					const { isStatic, isHidden = false } = item;

					// Exclude hidden overflowable items from rendering
					if (!isStatic && isHidden) {
						return;
					}

					(isStatic ? theStatic : theOverflowable).push(item);
					(isRenderedBefore ? theRenderedBefore : theRenderedAfter).push(item);
				});
			};

			categoriseItems({ isRenderedBefore: true });
			categoriseItems({ isRenderedBefore: false });

			return {
				renderedOverflowableItems: theOverflowable,
				staticItems: theStatic,
				renderedItemsBefore: theRenderedBefore,
				renderedItemsAfter: theRenderedAfter,
			};
		}, [itemsBefore, itemsAfter]);

	/**
	 * Calculate the count for "More" dropdown.
	 * It excludes the selected items of the visible items.
	 */
	const selectedOverflowItemsCount = useMemo(
		() => getSelectedItemsCount(overflowableHideState, selectedItems),
		[overflowableHideState, selectedItems],
	);

	const onResize = useCallback(() => {
		const { current: overflowContainer } = overflowContainerRef;
		const { current: moreFilter } = moreFilterRef;

		const shouldRecomputeOverflow = getShouldRecomputeOverflow(
			prevOverflowContainerWidthRef.current,
			overflowContainer?.offsetWidth ?? 0,
			prevElementsWidthRef.current,
			elementsWidthRef.current,
		);

		if (
			!overflowContainer ||
			!moreFilter ||
			!renderedOverflowableItems.length ||
			!shouldRecomputeOverflow
		) {
			return;
		}

		// Store the widths for comparison
		prevOverflowContainerWidthRef.current = overflowContainer.offsetWidth;
		prevElementsWidthRef.current = { ...elementsWidthRef.current };

		setOverflowState(
			calcOverflow(
				overflowContainer,
				moreFilter,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				renderedOverflowableItems as NonEmptyArray<OverflowConfigItem>,
				staticItems,
				elementsRef.current,
			),
		);
	}, [renderedOverflowableItems, staticItems]);

	if (expVal('blu-3488_fix_jsw_filters_flickering', 'isEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useResizeObserverPlatform({
			ref: overflowContainerRef,
			onResize,
		});
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useResizeObserverLocal(overflowContainerRef, onResize);
	}

	const onClick = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen, setIsOpen]);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const triggerLabel = formatMessage(messages.more);
	const triggerTooltipText = formatMessage(messages.moreFiltersTooltip);
	const buttonToolTip = useMemo(
		() => (
			<Tooltip content={triggerTooltipText} hideTooltipOnClick tag="span">
				<TriggerContainer data-testid="filters.common.ui.overflow-handler.trigger-container">
					<TriggerLabel>{triggerLabel}</TriggerLabel>
					{selectedOverflowItemsCount > 0 && (
						<BadgeContainer>
							<Badge appearance="primary">{selectedOverflowItemsCount}</Badge>
						</BadgeContainer>
					)}
				</TriggerContainer>
			</Tooltip>
		),
		[selectedOverflowItemsCount, triggerTooltipText, triggerLabel],
	);

	const content = useCallback(
		() => (
			<PopupContainer>
				{renderedOverflowableItems.map(
					({ id, renderer }: OverflowConfigItem) =>
						Boolean(overflowableHideState[id]) && <Fragment key={id}>{renderer(true)}</Fragment>,
				)}
			</PopupContainer>
		),
		[renderedOverflowableItems, overflowableHideState],
	);

	const renderItems = (items: OverflowConfigItem[]) =>
		items.map(({ id, isStatic, isHidden = false, renderer }: OverflowConfigItem) => {
			const isItemHidden = isStatic ? isHidden : Boolean(overflowableHideState[id]);

			return (
				<ItemWrapper
					key={id}
					innerRef={(element: HTMLElement) => {
						elementsRef.current[id] = element;
						if (element) {
							elementsWidthRef.current[id] = element.offsetWidth;
							if (!isHidden) {
								visibleElementsRef.current[id] = {
									width: element.offsetWidth,
									height: element.offsetHeight,
								};
							}
						}
					}}
					aria-hidden={isItemHidden}
					isHidden={isItemHidden}
				>
					{renderer(false)}
				</ItemWrapper>
			);
		});

	return (
		<div
			css={[isVisualRefreshEnabled() ? overflowContainerStyles : overflowContainerStylesOld]}
			ref={overflowContainerRef}
		>
			{renderItems(renderedItemsBefore)}
			<ItemWrapper
				innerRef={moreFilterRef}
				aria-hidden={isMoreDropdownHidden}
				isHidden={isMoreDropdownHidden}
			>
				<Popup
					trigger={(triggerProps: TriggerProps) => (
						<Button
							{...triggerProps}
							appearance={isVisualRefreshEnabled() ? 'default' : 'subtle'}
							isSelected={isOpen}
							iconAfter={
								<ChevronDown primaryColor={token('color.icon', '#44546F')} label={triggerLabel} />
							}
							onClick={onClick}
						>
							{buttonToolTip}
						</Button>
					)}
					shouldRenderToParent
					shouldFlip
					isOpen={isOpen}
					placement="bottom-start"
					content={content}
					onClose={onClose}
				/>
			</ItemWrapper>
			{renderItems(renderedItemsAfter)}
		</div>
	);
};

export default OverflowHandler;

const overflowContainerStylesOld = css({
	display: 'flex',
	minWidth: 0,
});

const overflowContainerStyles = css({
	display: 'flex',
	minWidth: 0,
	margin: `0 ${token('space.negative.050')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupContainer = styled2.div({
	padding: `${token('space.050', '4px')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TriggerContainer = styled2.span({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TriggerLabel = styled2.span({
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BadgeContainer = styled2.span({
	display: 'inline-block',
	paddingLeft: token('space.050', '4px'),
});

interface ItemWrapperProps {
	isHidden: boolean;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const ItemWrapperControl = styled.div<ItemWrapperProps>((props) => ({
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	order: props.isHidden ? 2 : 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	visibility: props.isHidden ? 'hidden' : 'visible',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	pointerEvents: props.isHidden ? 'none' : 'auto',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemWrapperExperiment = styled2.div<ItemWrapperProps>({
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	order: ({ isHidden }: ItemWrapperProps) => (isHidden ? 2 : 1),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: ({ isHidden }: ItemWrapperProps) => (isHidden ? 'hidden' : 'visible'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	pointerEvents: ({ isHidden }: ItemWrapperProps) => (isHidden ? 'none' : 'auto'),
});

const ItemWrapper = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	ItemWrapperExperiment,
	ItemWrapperControl,
);
