import React from 'react';
import { ButtonItem, MenuGroup as PopupGroup, Section as PopupSection } from '@atlaskit/menu';
import { Box, xcss } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { FireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { backlogViewTestIds } from '../../../../common/constants';
import messages from '../../../../common/messages';
import { ActionSubject, ToggleKey } from '../../../../common/types/analytics.tsx';
import {
	ViewAs,
	ExpandableState,
	KeyboardShortcut,
	View,
} from '../../../../common/types/constant.tsx';
import type { BacklogViewProps } from '../../../../common/types/menu.tsx';
import { ViewSetting } from '../../../../common/types/settings.tsx';
import { PanelGroup } from '../../../../common/ui/group/index.tsx';
import { PanelSection } from '../../../../common/ui/section/index.tsx';
import { ViewSettingsRadioGroup } from '../../../../common/ui/view-settings-radio-group/index.tsx';
import { ViewSettingsToggle } from '../../../../common/ui/view-settings-toggle/index.tsx';
import { isViewSettingsPanelAndTailoredViewExperimentEnabled } from '../../../../common/utils';
import { useViewSettings } from '../../../../controllers';
import { CardLayoutCompactIcon } from './assets/card-layout-compact';
import { CardLayoutDefaultIcon } from './assets/card-layout-default';

export const BacklogMenu = ({
	viewAs,
	boardId,
	isClassic,
	isVersionsEnabled,
	showEpicAsPanel,
	showFilterBarToggle = false,
	epicCustomName = 'Epic',
	areSubtasksEnabled = false,
	areAllSubtasksExpanded = false,
	areAllSubtasksCollapsed = false,
	showCardDetails = true,
	isEstimateEnabled = false,
	onHandleChange,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSubtasksExpandAll = () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSubtasksCollapseAll = () => {},
	cardExtraFields = [],
	isDueDateEnabled = false,
	isPriorityEnabled = false,
}: BacklogViewProps) => {
	const { formatMessage } = useIntl();
	const [state, actions] = useViewSettings();

	const Section = viewAs === ViewAs.PANEL ? PanelSection : PopupSection;
	const Group = viewAs === ViewAs.PANEL ? PanelGroup : PopupGroup;
	const {
		toggleViewSetting,
		toggleShowEpics,
		toggleShowStatuses,
		toggleShowVersions,
		setViewSettingsCompactMode,
		toggleExtraField,
		toggleShowDueDate,
	} = actions;

	const hasCardExtraFields = cardExtraFields.length > 0;

	return (
		<>
			<Group>
				<Section>
					{isVersionsEnabled && (
						<ViewSettingsToggle
							testId={backlogViewTestIds.showVersionPanelToggle}
							title={formatMessage(messages.versionPanel)}
							onChange={() => {
								toggleViewSetting({
									boardId,
									view: View.BACKLOG,
									setting: ViewSetting.VERSION_PANEL,
								});
								onHandleChange(ActionSubject.TOGGLE, {
									key: ToggleKey.VERSION_PANEL_TOGGLE,
									state: !state.showVersionPanel,
								});
							}}
							keyboardShortcut={KeyboardShortcut.VERSION_PANEL_SHORTCUT}
							isChecked={state.showVersionPanel}
							viewAs={viewAs}
						/>
					)}
					{showEpicAsPanel && (
						<ViewSettingsToggle
							testId={backlogViewTestIds.showEpicPanelToggle}
							title={formatMessage(messages.epicPanel, {
								epicName: epicCustomName,
							})}
							onChange={() => {
								toggleViewSetting({
									boardId,
									view: View.BACKLOG,
									setting: ViewSetting.EPIC_PANEL,
								});
								onHandleChange(ActionSubject.TOGGLE, {
									key: ToggleKey.EPIC_PANEL_TOGGLE,
									state: !state.showEpicPanel,
								});
							}}
							keyboardShortcut={KeyboardShortcut.ISSUE_PARENT_PANEL_SHORTCUT}
							isChecked={state.showEpicPanel}
							viewAs={viewAs}
						/>
					)}
					{showFilterBarToggle && (
						<ViewSettingsToggle
							testId={backlogViewTestIds.showQuickFiltersToggle}
							title={formatMessage(messages.quickFilters)}
							onChange={() => {
								toggleViewSetting({
									boardId,
									view: View.BACKLOG,
									setting: ViewSetting.QUICK_FILTERS,
								});
								onHandleChange(ActionSubject.TOGGLE, {
									key: ToggleKey.QUICK_FILTERS_TOGGLE,
									state: !state.showQuickFilters,
								});
							}}
							isChecked={state.showQuickFilters}
							viewAs={viewAs}
						/>
					)}
					{viewAs === ViewAs.PANEL ? (
						<Section
							title={formatMessage(messages.cardDensity)}
							hasSeparator
							data-testId={backlogViewTestIds.cardLayoutSection}
						>
							<ViewSettingsRadioGroup
								testId={backlogViewTestIds.cardLayoutRadioGroup}
								onSelect={(value) => {
									setViewSettingsCompactMode({
										boardId,
										view: View.BACKLOG,
										showCompactMode: value === 'compact',
									});
									onHandleChange(ActionSubject.BUTTON, {
										key: ToggleKey.CARD_DENSITY,
										state: value,
									});
								}}
								value={state.showCompactMode ? 'compact' : 'default'}
								options={[
									{
										name: 'card-layout',
										value: 'default',
										label: 'Default',
										icon: CardLayoutDefaultIcon,
									},
									{
										name: 'card-layout',
										value: 'compact',
										label: 'Compact',
										icon: CardLayoutCompactIcon,
									},
								]}
								viewAs={viewAs}
								ariaLabel={formatMessage(messages.cardDensity)}
							/>
						</Section>
					) : (
						<ViewSettingsToggle
							testId={backlogViewTestIds.showCompactModeToggle}
							title={formatMessage(messages.compactMode)}
							onChange={() => {
								toggleViewSetting({
									boardId,
									view: View.BACKLOG,
									setting: ViewSetting.COMPACT_MODE,
								});
								onHandleChange(ActionSubject.TOGGLE, {
									key: ToggleKey.COMPACT_MODE_TOGGLE,
									state: !state.showCompactMode,
								});
							}}
							isChecked={state.showCompactMode}
							viewAs={viewAs}
						/>
					)}
				</Section>
				{areSubtasksEnabled && viewAs !== ViewAs.PANEL && (
					<Section
						title={formatMessage(messages.subtasks)}
						hasSeparator
						data-testId={backlogViewTestIds.subtaskSection}
					>
						<ButtonItem
							testId={backlogViewTestIds.subtaskExpandAll}
							isDisabled={areAllSubtasksExpanded}
							onClick={() => {
								onSubtasksExpandAll();
								onHandleChange(ActionSubject.BUTTON, {
									key: ToggleKey.SUBTASKS_EXPAND_ALL,
									state: ExpandableState.EXPANDED,
								});
							}}
						>
							{formatMessage(messages.expandAllSubtasks)}
						</ButtonItem>
						<ButtonItem
							testId={backlogViewTestIds.subtaskCollapseAll}
							isDisabled={areAllSubtasksCollapsed}
							onClick={() => {
								onSubtasksCollapseAll();
								onHandleChange(ActionSubject.BUTTON, {
									key: ToggleKey.SUBTASKS_COLLAPSE_ALL,
									state: ExpandableState.COLLAPSED,
								});
							}}
						>
							{formatMessage(messages.collapseAllSubtasks)}
						</ButtonItem>
					</Section>
				)}
				{showCardDetails && (
					<Section
						testId={backlogViewTestIds.cardDetailsSection}
						title={
							viewAs === ViewAs.PANEL
								? formatMessage(messages.cardFields)
								: formatMessage(messages.cardDetails)
						}
						hasSeparator
					>
						{isViewSettingsPanelAndTailoredViewExperimentEnabled() && (
							<>
								<ViewSettingsToggle
									testId={backlogViewTestIds.showIssueTypeToggle}
									title={formatMessage(
										expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
											? messages.cardDetailIssueTypeIssueTermRefresh
											: messages.cardDetailIssueType,
									)}
									onChange={() => {
										toggleViewSetting({
											boardId,
											view: View.BACKLOG,
											setting: ViewSetting.SHOW_ISSUE_TYPE,
										});
										onHandleChange(ActionSubject.TOGGLE, {
											key: ToggleKey.SHOW_ISSUE_TYPE,
											state: !state.showIssueType,
										});
									}}
									isChecked={state.showIssueType}
									viewAs={viewAs}
								/>
								<ViewSettingsToggle
									testId={backlogViewTestIds.showIssueKeyToggle}
									title={formatMessage(
										expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
											? messages.cardDetailIssueKeyIssueTermRefresh
											: messages.cardDetailIssueKey,
									)}
									onChange={() => {
										toggleViewSetting({
											boardId,
											view: View.BACKLOG,
											setting: ViewSetting.SHOW_ISSUE_KEY,
										});
										onHandleChange(ActionSubject.TOGGLE, {
											key: ToggleKey.SHOW_ISSUE_KEY,
											state: !state.showIssueKey,
										});
									}}
									isChecked={state.showIssueKey}
									viewAs={viewAs}
								/>
							</>
						)}

						{isVersionsEnabled && isClassic && (
							<ViewSettingsToggle
								testId={backlogViewTestIds.showVersionsToggle}
								title={formatMessage(messages.cardDetailVersion)}
								onChange={() => {
									toggleShowVersions({ boardId, view: View.BACKLOG });
									onHandleChange(ActionSubject.TOGGLE, {
										key: ToggleKey.SHOW_VERSIONS_TOGGLE,
										state: !state.showVersions,
									});
								}}
								isChecked={state.showVersions}
								viewAs={viewAs}
							/>
						)}
						<ViewSettingsToggle
							testId={backlogViewTestIds.showEpicsToggle}
							title={epicCustomName}
							onChange={() => {
								toggleShowEpics({ boardId, view: View.BACKLOG });
								onHandleChange(ActionSubject.TOGGLE, {
									key: ToggleKey.SHOW_EPICS_TOGGLE,
									state: !state.showEpics,
								});
							}}
							isChecked={state.showEpics}
							viewAs={viewAs}
						/>
						{/* Due date available only for tmp projects */}
						{isViewSettingsPanelAndTailoredViewExperimentEnabled() && isDueDateEnabled && (
							<ViewSettingsToggle
								testId={backlogViewTestIds.showDueDateToggle}
								title={formatMessage(messages.dueDate)}
								onChange={() => {
									toggleShowDueDate({ boardId, view: View.BACKLOG });
									onHandleChange(ActionSubject.TOGGLE, {
										key: ToggleKey.SHOW_DUE_DATE,
										state: !state.showDueDate,
									});
								}}
								isChecked={state.showDueDate}
								viewAs={viewAs}
							/>
						)}
						<ViewSettingsToggle
							testId={backlogViewTestIds.showStatusToggle}
							title={formatMessage(messages.cardDetailStatus)}
							onChange={() => {
								toggleShowStatuses({ boardId, view: View.BACKLOG });
								onHandleChange(ActionSubject.TOGGLE, {
									key: ToggleKey.SHOW_STATUSES_TOGGLE,
									state: !state.showStatuses,
								});
							}}
							isChecked={state.showStatuses}
							viewAs={viewAs}
						/>
						{isViewSettingsPanelAndTailoredViewExperimentEnabled() && (
							<>
								{isEstimateEnabled && (
									<ViewSettingsToggle
										testId={backlogViewTestIds.showEstimateToggle}
										title={formatMessage(messages.cardDetailEstimate)}
										onChange={() => {
											toggleViewSetting({
												boardId,
												view: View.BACKLOG,
												setting: ViewSetting.SHOW_ESTIMATE,
											});
											onHandleChange(ActionSubject.TOGGLE, {
												key: ToggleKey.SHOW_ESTIMATE,
												state: !state.showEstimate,
											});
										}}
										isChecked={state.showEstimate}
										viewAs={viewAs}
									/>
								)}
								{isPriorityEnabled && (
									<ViewSettingsToggle
										testId={backlogViewTestIds.showPriorityToggle}
										title={formatMessage(messages.cardDetailPriority)}
										onChange={() => {
											toggleViewSetting({
												boardId,
												view: View.BACKLOG,
												setting: ViewSetting.SHOW_PRIORITY,
											});
											onHandleChange(ActionSubject.TOGGLE, {
												key: ToggleKey.SHOW_PRIORITY,
												state: !state.showPriority,
											});
										}}
										isChecked={state.showPriority}
										viewAs={viewAs}
									/>
								)}
								<ViewSettingsToggle
									testId={backlogViewTestIds.showAssigneeToggle}
									title={formatMessage(messages.cardDetailAssignee)}
									onChange={() => {
										toggleViewSetting({
											boardId,
											view: View.BACKLOG,
											setting: ViewSetting.SHOW_ASSIGNEE,
										});
										onHandleChange(ActionSubject.TOGGLE, {
											key: ToggleKey.SHOW_ASSIGNEE,
											state: !state.showAssignee,
										});
									}}
									isChecked={state.showAssignee}
									viewAs={viewAs}
								/>
							</>
						)}
					</Section>
				)}
				{hasCardExtraFields && isViewSettingsPanelAndTailoredViewExperimentEnabled() && (
					<Section testId={backlogViewTestIds.customFieldsSection} hasSeparator>
						{cardExtraFields.map((field) => (
							<ViewSettingsToggle
								key={field.id}
								testId={`software-view-settings.ui.view-settings-menu.views.backlog.show-${field.id}-toggle-backlog`}
								title={field.label}
								onChange={() => {
									toggleExtraField({
										boardId,
										view: View.BACKLOG,
										fieldId: field.id,
									});
									onHandleChange(ActionSubject.TOGGLE, {
										key: `${ToggleKey.SHOW_EXTRA_FIELD}_${field.id}`,
										state: !state.cardExtraFields[field.id],
									});
								}}
								isChecked={state.cardExtraFields[field.id]}
								viewAs={viewAs}
							/>
						))}
					</Section>
				)}

				{areSubtasksEnabled && viewAs === ViewAs.PANEL && (
					<Section
						title={formatMessage(messages.subtasks)}
						hasSeparator
						data-testId={backlogViewTestIds.subtaskSection}
					>
						<Box xcss={buttonItemContainerPanelModeStyles}>
							<ButtonItem
								testId={backlogViewTestIds.subtaskExpandAll}
								isDisabled={areAllSubtasksExpanded}
								onClick={() => {
									onSubtasksExpandAll();
									onHandleChange(ActionSubject.BUTTON, {
										key: ToggleKey.SUBTASKS_EXPAND_ALL,
										state: ExpandableState.EXPANDED,
									});
								}}
							>
								<Box paddingInline="space.100">{formatMessage(messages.expandAllSubtasks)}</Box>
							</ButtonItem>
							<ButtonItem
								testId={backlogViewTestIds.subtaskCollapseAll}
								isDisabled={areAllSubtasksCollapsed}
								onClick={() => {
									onSubtasksCollapseAll();
									onHandleChange(ActionSubject.BUTTON, {
										key: ToggleKey.SUBTASKS_COLLAPSE_ALL,
										state: ExpandableState.COLLAPSED,
									});
								}}
							>
								<Box paddingInline="space.100">{formatMessage(messages.collapseAllSubtasks)}</Box>
							</ButtonItem>
						</Box>
					</Section>
				)}
			</Group>
			<FireScreenAnalytics
				attributes={{
					viewSettingsState: state,
				}}
			/>
		</>
	);
};

const buttonItemContainerPanelModeStyles = xcss({
	position: 'relative',
	left: '-24px',
	width: 'calc(100% + 48px)',
});
