import { useCallback } from 'react';
import { isValid } from 'date-fns';
import { type IntlShape, useIntl } from '@atlassian/jira-intl';
import { longDateFormat } from './constants';

// as a posible  solution for internationalization days, months and "am" / "pm" might be date API methods like toLocaleDateString()
// or Intl.DateTimeFormat API

export const getFullDayLabel = (day: number) => {
	switch (day) {
		case 0:
			return 'Sunday';
		case 1:
			return 'Monday';
		case 2:
			return 'Tuesday';
		case 3:
			return 'Wednesday';
		case 4:
			return 'Thursday';
		case 5:
			return 'Friday';
		default:
			return 'Saturday';
	}
};

export const getFullMonthLabel = (month: number): string => {
	switch (month) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};

export const getDayLabel = (day: number): string => getFullDayLabel(day).substr(0, 3);

export const getMonthLabel = (month: number): string => getFullMonthLabel(month).substr(0, 3);

export const getFormatParts = (format: string): string[] => {
	let stringFormat: string | null | undefined = format;
	let match;
	let parts: Array<string> = [];
	const DATE_FORMATS_SPLIT =
		/((?:[^YMDHhmsaAZzE']+)|(?:'(?:[^']|'')*')|(?:E+|Y+|M+|D+|H+|h+|m+|s+|a|A|Z|z))(.*)/;
	const slice = [].slice;
	const concatMethod = (array1: string[], array2: string[], index: number): string[] =>
		array1.concat(slice.call(array2, index));

	while (stringFormat) {
		match = DATE_FORMATS_SPLIT.exec(stringFormat);

		if (match) {
			parts = concatMethod(parts, match, 1);
			stringFormat = parts.pop();
		} else {
			parts.push(format);
			stringFormat = null;
		}
	}

	return parts;
};

export const formatDateUTC = (time: number | string, format: string): string => {
	const date: Date = new Date(time);

	if (!isValid(date)) {
		throw new Error('Date passed to formatDateUTC function is not valid');
	}

	// get individual values
	const dayOfWeekIndex = date.getUTCDay();
	const month = date.getUTCMonth();

	const fullYear = date.getUTCFullYear().toString();
	const fullMonth = getFullMonthLabel(month);
	const shortMonth = getMonthLabel(month);
	const digitMonth = month + 1;
	const hour = date.getUTCHours();
	const minutes = date.getUTCMinutes();
	const seconds = date.getUTCSeconds();

	const formatArray = getFormatParts(format);

	// TODO: getFormatVal is hardcoded to date-fns v.1.x.x format should be reimplemented by owners team
	// pick individual parts of the date format
	const getFormatVal = (part: string): string => {
		let val;

		switch (part) {
			case 'YYYY': // 4 digit representation of year (e.g. AD 1 => 0001, AD 2010 => 2010)
				val = fullYear.padStart(4, '0');
				break;
			case 'YY': // 2 digit representation of year, padded (00-99). (e.g. AD 2001 => 01)
				val = fullYear.padStart(4, '0').substr(-2);
				break;
			case 'MMMM': // Month in year (January-December)
				val = fullMonth;
				break;
			case 'MMM': // Month in year (Jan-Dec)
				val = shortMonth;
				break;
			case 'MM': // Month in year (01-12)
				val = digitMonth.toString().padStart(2, '0');
				break;
			case 'M': // Month in year (1-12)
				val = digitMonth;
				break;
			case 'EEEE': // Day in Week,(Sunday-Saturday)
				val = getFullDayLabel(dayOfWeekIndex);
				break;
			case 'EEE': // Day in Week, (Sun-Sat)
				val = getDayLabel(dayOfWeekIndex);
				break;
			case 'DD': // Day in month (01-31)
				val = date.getUTCDate().toString().padStart(2, '0');
				break;
			case 'D': // Day in month (1-31)
				val = date.getUTCDate();
				break;
			case 'hh': // Hour in AM/PM (01-12)
				val = 12;
				if (hour !== 0) {
					const diff = hour - 12;
					val = diff > 0 ? diff.toString().padStart(2, '0') : hour.toString().padStart(2, '0');
				}
				break;
			case 'h': // Hour in AM/PM, (1-12)
				val = 12;
				if (hour !== 0) {
					val = hour > 12 ? hour - 12 : hour;
				}
				break;
			case 'HH': // Hour in day (00-23)
				val = hour.toString().padStart(2, '0');
				break;
			case 'H': // Hour in day (0-23)
				val = hour;
				break;
			case 'mm': // Minute in hour (00-59)
				val = minutes.toString().padStart(2, '0');
				break;
			case 'm': // Minute in hour (0-59)
				val = minutes;
				break;
			case 'ss': // Seconds in minute (00-59)
				val = seconds.toString().padStart(2, '0');
				break;
			case 's': // Seconds in minute (0-59)
				val = seconds;
				break;
			case 'a':
				val = hour >= 12 ? 'pm' : 'am';
				break;
			case 'A':
				val = hour >= 12 ? 'PM' : 'AM';
				break;
			default:
				val = part;
		}
		val = typeof val !== 'undefined' ? val.toString() : part;
		return val;
	};
	let dateVal = '';

	formatArray.forEach((part) => {
		dateVal += getFormatVal(part);
	});

	return dateVal;
};

// Internationalisation functions

export const formatTimestampWithIntl = (
	intl: IntlShape,
	timestamp: number,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	formatOptions: any = longDateFormat,
) => intl.formatDate(timestamp, formatOptions);

export const formatDateWithIntl = (
	intl: IntlShape,
	iso: string | number,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	formatOptions: any = longDateFormat,
) => {
	const date = new Date(iso);
	return intl.formatDate(date.getTime(), formatOptions);
};

export const useDateFormatter = () => {
	const intl = useIntl();

	const formatTimestamp = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(timestamp: number, formatOptions?: any) =>
			formatTimestampWithIntl(intl, timestamp, formatOptions),
		[intl],
	);

	const formatDate = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(iso: string, formatOptions?: any) => {
			const date = new Date(iso);
			return formatTimestampWithIntl(intl, date.getTime(), formatOptions);
		},
		[intl],
	);

	return { formatTimestamp, formatDate };
};
