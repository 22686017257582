import React, { useEffect, type ComponentType, type PropsWithChildren } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { boardType } from '@atlassian/jira-insights-common-constants/src/common/constants/analytics.tsx';
import { isValidBoardId } from '@atlassian/jira-insights-common/src/common/utils/utils.tsx';
import { useIsCurrentBrowserSupported } from '@atlassian/jira-insights-common/src/controllers/use-is-current-browser-supported/index.tsx';
import { useValueChangeEffect } from '@atlassian/jira-insights-common/src/controllers/use-value-change-effect/index.tsx';
import { WithInsightsErrorBoundary } from '@atlassian/jira-insights-common/src/ui/insights-error-boundary/index.tsx';
import { withComponentSafety } from '@atlassian/jira-insights-common/src/ui/with-component-safety/index.tsx';
import { useInsightsContainer } from '@atlassian/jira-insights-container/src/controllers/use-insights-container/index.tsx';
import { InsightsContainer } from '@atlassian/jira-insights-container/src/ui/index.tsx';
import { InsightsButton } from '@atlassian/jira-insights-show-insights-button/src/ui/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { getUserLocation } from '@atlassian/jira-platform-router-utils/src/common/utils/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { buildBoardScopeName } from '../common';
import { INSIGHTS_PACKAGE, BOARD_BUTTON_ID, INSIGHTS_PANEL_BOUNDARY_ID } from '../common/constants';
import messages from './messages';
import type { BoardButtonContainerState, BoardButtonProps } from './types';

const ButtonTooltip = ({ children }: PropsWithChildren) => {
	const { formatMessage } = useIntl();

	return <Tooltip content={formatMessage(messages.tooltipNonFinal)}>{children}</Tooltip>;
};

export const ServicedeskBoardInsightsButtonComponent = ({
	isCompact = false,
	isSimplifiedProject,
	isOpen,
	boardId,
	onToggle,
	edition,
	projectId,
}: BoardButtonProps) => {
	const userLocation = getUserLocation();
	const isSupported = useIsCurrentBrowserSupported();

	const [
		{
			info: { isProjectType, uniqueId },
		},
		{ refreshUniqueId, updateIsDeepLinking },
	] = useInsightsContainer<BoardButtonContainerState>();

	useEffect(() => {
		if (!isOpen) {
			updateIsDeepLinking(false);
		}
	}, [updateIsDeepLinking, isOpen, isSimplifiedProject]);

	// refresh experience when insights (panel) is closed
	useValueChangeEffect(
		{
			listener: isOpen,
			callback: (current, previous) => {
				if (current === false && previous === true) refreshUniqueId();
			},
		},
		[refreshUniqueId],
	);

	return isSupported ? (
		<ContextualAnalyticsData
			attributes={{
				edition,
				boardId,
				userLocation,
				projectId,
				frameworkType: boardType.KANBAN,
				experienceId: uniqueId,
			}}
		>
			<>
				<InsightsButton
					isInsightsPanelOpen={isOpen}
					isCompact={isCompact}
					boardId={boardId}
					onToggle={onToggle}
					isHidden={!isProjectType}
					renderTooltip={ButtonTooltip}
				/>
			</>
		</ContextualAnalyticsData>
	) : null;
};

const ServicedeskBoardInsightsButtonWithContainer = (props: BoardButtonProps) => {
	const scope = buildBoardScopeName({
		isSimplifiedProject: props.isSimplifiedProject,
		boardId: props.boardId,
	});

	return (
		<Box xcss={serviceDeskInsightStyle}>
			<InsightsContainer scope={scope} packageName={INSIGHTS_PACKAGE}>
				<ServicedeskBoardInsightsButtonComponent {...props} />
			</InsightsContainer>
		</Box>
	);
};

const SafeServicedeskBoardInsightsButton = withComponentSafety(
	ServicedeskBoardInsightsButtonWithContainer,
	{
		condition: ({ boardId }) => isValidBoardId({ boardId }),
		location: BOARD_BUTTON_ID,
		message: ({ boardId }) => `Servicedesk Board button received invalid boardId: ${boardId}`,
	},
);

export const ServicedeskBoardInsightsButton: ComponentType<BoardButtonProps> =
	WithInsightsErrorBoundary(
		SafeServicedeskBoardInsightsButton,
		INSIGHTS_PANEL_BOUNDARY_ID,
		INSIGHTS_PACKAGE,
	);

const serviceDeskInsightStyle = xcss({
	marginRight: 'space.100',
});
