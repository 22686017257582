import { createSelector } from 'reselect';
import findLast from 'lodash/findLast';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { alphabeticalSort } from '@atlassian/jira-common-util-software-filters-sort';
import {
	CHILD_HIERARCHY_TYPE,
	PARENT_HIERARCHY_TYPE,
} from '@atlassian/jira-issue-type-hierarchies';
import type { AncestorIssue } from '@atlassian/jira-plan-issue-modal/src/types';
import { SPRINT_STATES } from '@atlassian/jira-shared-types/src/rest/jira/sprint.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common';
import type { ColumnId } from '../../../model/column/column-types';
import type { IssueType } from '../../../model/filter/filter-types';
import type {
	DevStatusActivity,
	Issue,
	IssueArray,
	IssueEntities,
	IssueKey,
	IssueTypeId,
	PartialIssue,
	IssueParent,
} from '../../../model/issue/issue-types';
import type { Status } from '../../../model/software/software-types';
import type { RankConfigData } from '../../../model/work/config-types';
import type { IssueProjectsState } from '../../reducers/entities/issue-projects/types';
import type { IssuesState } from '../../reducers/entities/issues/types';
import type { RequestTypesState } from '../../reducers/entities/request-types/types';
import type { StatusesState } from '../../reducers/entities/statuses/types';
import type { State } from '../../reducers/types';
import { getColumnIndex, getStatusMapSelector } from '../column/column-selectors';
import { getIssueChildren } from '../issue-children';
import { getIssueParents } from '../issue-parent';
import { getIssueTypesGroupedByNameLevel } from '../issue-type/issue-type-selectors';
import {
	getBoardEntity,
	getIssues,
	getIssueTypes,
	getProjectIssueTypes,
	getIssueProjects,
	getRequestTypes,
	getStatuses,
} from '../software/software-selectors';
import { getTeamSprintsForIterations } from '../team/team-selectors';
import {
	boardIssuesAndChildrenSelector,
	boardIssuesSelector,
	boardOrderedIssueIdsSelector,
} from './board-issue-selectors';

export const isAssignedToIssueParent = createSelector(boardIssuesSelector, (issues) => {
	const isAssignedToIssueParentCheck = (issueIds: IssueId[]) => {
		const stringIds = issueIds.map((id) => String(id));
		return (
			Object.keys(issues).filter((id) => stringIds.includes(id) && issues[id].parentId).length > 0
		);
	};

	return isAssignedToIssueParentCheck;
});

export const getIssueById = (state: State, issueId: IssueId): Issue =>
	boardIssuesSelector(state)[String(issueId)];

export const hasIssue = (state: State, issueId: IssueId): boolean => !!getIssueById(state, issueId);

export const getIssueIndex = (state: State, issueId: IssueId): number =>
	boardOrderedIssueIdsSelector(state).indexOf(issueId);

export const isDoneIssueSelector = createSelector(
	[boardIssuesSelector, boardIssuesAndChildrenSelector],
	(issuesWithoutSubtasks, allIssues) => {
		// using the boardIssuesAndChildrenSelector here allows this selector to be used in any situation.
		// boardIssuesSelector filters out issues with subtasks when grouping by subtasks/stories and this selector couldn't access those issues.
		const isDoneIssueSelectorCheck = (issueId: IssueId) =>
			get(allIssues[String(issueId)], ['isDone'], false);
		return isDoneIssueSelectorCheck;
	},
);

const getIssuesByKeys = createSelector([boardIssuesSelector], (issues) => groupBy(issues, 'key'));
const getIssuesAndIssueChildrenByKeys = createSelector([boardIssuesAndChildrenSelector], (issues) =>
	groupBy(issues, 'key'),
);

export const getIssueOrIssueChildrenByKey = (
	state: State,
	issueKey: IssueKey,
): Issue | undefined =>
	getIssuesAndIssueChildrenByKeys(state)[issueKey]
		? getIssuesAndIssueChildrenByKeys(state)[issueKey][0]
		: undefined;

export const getIssueByKey = (state: State, issueKey: IssueKey): Issue | undefined =>
	getIssuesByKeys(state)[issueKey] ? getIssuesByKeys(state)[issueKey][0] : undefined;

export const getIssueOrIssueChildById = createSelector(
	[getIssues, getIssueChildren],
	(issues, issueChildren) => (issueId: IssueId | null | undefined) =>
		issues[String(issueId)] || issueChildren[String(issueId)],
);

export const hasIssueOrIssueChild = (state: State, issueId: IssueId) =>
	!!getIssueOrIssueChildById(state)(issueId);

export const getRankConfig = (state: State): RankConfigData =>
	getBoardEntity(state).config.rankConfig;

const getOrderedIssues = createSelector(
	[boardOrderedIssueIdsSelector, boardIssuesSelector],
	(orderedIds, issues) => orderedIds.map((issueId) => issues[String(issueId)]),
);

export const getOrderedIssuesByColumn = createSelector(getOrderedIssues, (issues) =>
	groupBy(issues, (issue) => issue.columnId),
);

export const getDoneIssues = createSelector(getIssues, (issues) =>
	Object.keys(issues)
		.map((issueId) => issues[issueId])
		.filter((issue) => issue.isDone),
);

export const getDoneIssuesInColumn = createSelector(getDoneIssues, (doneIssues) => {
	const doneIssuesInColumn = (columnId: number) =>
		doneIssues.filter((issue) => issue.columnId === columnId);
	return memoize(doneIssuesInColumn);
});

export const getIssueTypeLevelForBoardIssue = createSelector(
	[boardIssuesSelector, getProjectIssueTypes],
	(issues, projectIssueTypes) => {
		const issueTypeLevel = (issueId: IssueId) => {
			const issue = issues[String(issueId)];
			// If issue is not on the board then it's most likely an issue parent
			if (!issue) return 1;
			const projectIssueType = projectIssueTypes[String(issue.typeId)];
			if (projectIssueType.hierarchyLevelType === CHILD_HIERARCHY_TYPE) return -1;
			if (projectIssueType.hierarchyLevelType === PARENT_HIERARCHY_TYPE) return 1;
			return 0;
		};
		return issueTypeLevel;
	},
);

export const getFirstIssueInColumn = (
	state: State,
	columnId: ColumnId,
): IssueId | null | undefined => get(getOrderedIssuesByColumn(state), [columnId, 0, 'id'], null);

export const getFirstIssueChildInColumnByParent = (
	state: State,
	columnId: ColumnId,
	parentId: IssueId | null,
): IssueId | null | undefined =>
	getOrderedIssuesByColumn(state)[columnId]?.find((issue) => issue.parentId === parentId)?.id;

export const getLastIssueInColumn = (
	state: State,
	columnId: ColumnId,
): IssueId | null | undefined => get(last(getOrderedIssuesByColumn(state)[columnId]), ['id'], null);

export const getLastIssueChildInColumnByParent = (
	state: State,
	columnId: ColumnId,
	parentId: IssueId | null,
): IssueId | null | undefined =>
	findLast(getOrderedIssuesByColumn(state)[columnId], (issue) => issue.parentId === parentId)?.id;

export const getDistanceBetweenIssuesInColumn = createSelector(
	[getOrderedIssuesByColumn],
	(orderedIssuesByColumn) =>
		(columnId: ColumnId, issue1: IssueId, issue2: IssueId): number => {
			const issueIds = orderedIssuesByColumn[columnId].map((issue) => issue.id);
			if (!issueIds.includes(issue1) || !issueIds.includes(issue2)) {
				return 0;
			}

			return issueIds.indexOf(issue1) - issueIds.indexOf(issue2);
		},
);

export const isFirstIssueInColumn = (state: State, issueId: IssueId): boolean => {
	const issue = boardIssuesSelector(state)[String(issueId)];
	return issue !== undefined && getFirstIssueInColumn(state, issue.columnId) === issueId;
};

export const isLastIssueInColumn = (state: State, issueId: IssueId): boolean => {
	const issue = boardIssuesSelector(state)[String(issueId)];
	return issue !== undefined && getLastIssueInColumn(state, issue.columnId) === issueId;
};

export const isIssueFlagged = (state: State, issueId: IssueId): boolean => {
	const issue = boardIssuesSelector(state)[String(issueId)];
	return !isNil(issue) && Boolean(issue.isFlagged);
};

const getColumnIdForIssue = (state: State, issueId: IssueId): number =>
	boardIssuesSelector(state)[String(issueId)].columnId;

export const makeGetColumnIdForIssue = createSelector(boardIssuesSelector, (issues) =>
	memoize((issueId) => issues[String(issueId)].columnId),
);

export const getColumnIndexForIssue = (state: State, issueId: IssueId): number => {
	const columnId = getColumnIdForIssue(state, issueId);
	return getColumnIndex(state, columnId);
};

export const issueLabelsSelector = createSelector([boardIssuesSelector], (issues: IssuesState) =>
	Array.from(
		uniq(
			Object.keys(issues)
				.map((key) => issues[key])
				.reduce(
					(labels: string[], issue: Issue) =>
						issue.labels
							? issue.labels.reduce((acc: string[], label: string) => {
									acc.push(label);
									return acc;
								}, labels)
							: labels,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					[] as string[],
				),
		).sort((a, b) => alphabeticalSort(a, b)),
	),
);

export const makeIssueDevStatusSelector = createSelector(
	boardIssuesSelector,
	(previousIssues: IssueEntities) =>
		memoize((issues: IssueArray) =>
			issues.map((issue) => {
				const idString = String(issue.id);
				if (previousIssues[idString] && previousIssues[idString].devStatus) {
					const { devStatus } = previousIssues[idString];
					return { ...issue, devStatus };
				}
				return issue;
			}),
		),
);

export const issueIdsWithDevStatusSelector = createSelector(
	boardIssuesSelector,
	(issues: IssueEntities) =>
		memoize((expectedDevStatuses: DevStatusActivity[]) =>
			Object.keys(issues).filter((issueId) => {
				const { devStatus } = issues[issueId];
				return devStatus && expectedDevStatuses.includes(devStatus.activity);
			}),
		),
);

export const issueProjectIdsSelector = createSelector(
	[getIssueProjects],
	(issueProjects: IssueProjectsState) =>
		uniq(Object.keys(issueProjects).map((key: string) => String(key))),
);

export const issueTypeFilterSelector = createSelector(
	[boardIssuesSelector],
	(issues: IssuesState) =>
		Array.from(uniq(Object.keys(issues).map((key: string) => String(issues[key].typeId)))),
);

export const issueTypeSelector = createSelector([boardIssuesSelector], (issues: IssuesState) =>
	uniqBy(
		Object.keys(issues).map((key: string) => {
			const issue: Issue = issues[key];
			return {
				id: String(issue.typeId),
				name: issue.typeName || '',
				iconUrl: issue.typeUrl || '',
			};
		}),
		'id',
	),
);

export const sortedIssueTypeSelector = createSelector([issueTypeSelector], (issues: IssueType[]) =>
	issues.sort((a, b) => alphabeticalSort(a.name, b.name)),
);

export const issueTypeGroupSelector = createSelector(
	[issueTypeFilterSelector, getIssueTypesGroupedByNameLevel],
	(issueTypes, issueTypeGroups) =>
		issueTypeGroups
			.map((group) => ({
				...group,
				issueTypes: group.issueTypes.filter((issueType) => issueTypes.includes(issueType)),
			}))
			.filter((group) => group.issueTypes.length > 0),
);

export const requestTypesSelector = createSelector(
	[boardIssuesSelector, getRequestTypes],
	(issues: IssuesState, requestTypes: RequestTypesState) => {
		if (isEmpty(requestTypes)) {
			return [];
		}

		return uniqBy(
			Object.keys(issues)
				.filter((key: string) => {
					const issue: Issue = issues[key];
					return !!(issue.requestTypeId && requestTypes[issue.requestTypeId]);
				})
				.map((key: string) => {
					const issue: Issue = issues[key];
					// requestTypeId will always have a value, because we are filtering out issues without request types
					const { id, name, iconUrl } = requestTypes[issue.requestTypeId ?? ''];
					return {
						id,
						name,
						iconUrl,
					};
				}),
			'id',
		).sort((a, b) => alphabeticalSort(a.name, b.name));
	},
);

export const statusesSelector = createSelector(
	[boardIssuesSelector, getStatuses],
	(issues: IssuesState, statuses: StatusesState) => {
		if (isEmpty(statuses)) {
			return [];
		}

		return uniqBy(
			Object.keys(issues)
				.filter((key: string) => {
					const issue: Issue = issues[key];
					return !!statuses[issue.statusId];
				})
				.map((key: string) => {
					const issue: Issue = issues[key];
					const { id, name } = statuses[issue.statusId];
					return {
						id: String(id),
						name,
					};
				}),
			'id',
		).sort((a, b) => alphabeticalSort(a.name, b.name));
	},
);

export const makeDoesColumnContainDoneCardsSelector = createSelector(
	[getDoneIssues],
	(doneCards) => {
		const doesColumnContainDoneCardsCheck = (columnId: ColumnId) =>
			doneCards.filter((card) => columnId === card.columnId).length > 0;
		return doesColumnContainDoneCardsCheck;
	},
);

export const getIssueTypeById = (state: State, typeId: IssueTypeId) =>
	getIssueTypes(state)[typeId.toString()] || getProjectIssueTypes(state)[typeId.toString()];

export const partialIssuesFromIssueIdsSelector = createSelector(getIssues, (issues) => {
	const getPartialIssuesFromIssueIds = (issueIds: IssueId[]): PartialIssue[] =>
		issueIds
			.filter((issueId) => issues[String(issueId)])
			.map((issueId) => {
				const { id, key, parentId } = issues[String(issueId)];
				return { id, key, parentId };
			});

	return memoize(getPartialIssuesFromIssueIds);
});

export const getIssueKeysFromIssueIds = createSelector(getIssues, (issues) =>
	memoize((issueIds: IssueId[]): IssueKey[] =>
		issueIds
			.map((issueId) => issues[String(issueId)]?.key)
			.filter((issueKey): issueKey is IssueKey => !!issueKey),
	),
);

export const getIssueStatusSelector = createSelector(
	[boardIssuesSelector, getStatusMapSelector],
	(issues, statusMap) =>
		(issueId: IssueId): Status | undefined => {
			const issue = issues[String(issueId)];
			return statusMap[issue?.statusId];
		},
);

// This selector is used only by the Incrememt Planning Board
export const getIsIssueDoneInClosedSprint = (state: State, issueId: IssueId): boolean => {
	const issue = getIssues(state)[issueId];
	if (!issue) return false;

	const isDone = issue.isDone;
	const swimlane = issue.teamId;

	// teamless swimlane
	if (!swimlane) return false;

	const teamSprintsForIterations = getTeamSprintsForIterations(state, swimlane);

	// no sprints associated to team
	if (teamSprintsForIterations === null) return false;

	const sprintAssociatedToSwimlaneColumn = teamSprintsForIterations[Number(issue.columnId)];

	// no sprint associated to column
	if (!sprintAssociatedToSwimlaneColumn) return false;

	return isDone && sprintAssociatedToSwimlaneColumn.state === SPRINT_STATES.CLOSED;
};

const isIssueParent = (issue: Issue | IssueParent): issue is IssueParent => 'issueType' in issue;

// used for issue breadcrumbs in the program board. Getting a parent of an issue from board data, and then checking if it has parents (above Epic) in issueParents
export const makeGetAncestorsOfIssueForProgramBoard = createSelector(
	[boardIssuesSelector, getIssueParents],
	(issuesById, issueParents) =>
		(issue: Issue, projectKey: string): Array<AncestorIssue> => {
			const ancestors: Array<Issue | IssueParent> = [];
			const ancestorsIds: Array<IssueId> = [];

			let parent: Issue | IssueParent = issuesById[issue?.parentId || ''];

			while (parent) {
				if (ancestorsIds.includes(parent.id)) {
					// prevent infinite loop
					break;
				}
				ancestorsIds.push(parent.id);
				ancestors.push(parent);

				const parentId: IssueId | null = isIssueParent(parent) ? parent.id : parent.parentId;
				parent = issuesById[parentId || ''];

				if (!parent) {
					parent = issueParents[parentId || ''];
				}
			}

			const normalisedAncestors: AncestorIssue[] = ancestors.map((ancestor) => ({
				id: ancestor.id.toString(),
				key: ancestor.key,
				projectKey,
				summary: ancestor.summary,
				typeUrl: isIssueParent(ancestor) ? ancestor.issueType.iconUrl : ancestor.typeUrl,
				typeName: isIssueParent(ancestor) ? ancestor.issueType.name : ancestor.typeName,
			}));

			return normalisedAncestors;
		},
);
