import type { State } from '../../state/reducers/types';
import { boardOrderedIssueIdsSelector } from '../../state/selectors/issue/board-issue-selectors';
import { workFilteredIssuesSelector } from '../../state/selectors/work/work-selectors';

export type BoardCardCount = {
	cardsCount: number;
	visibleCardsCount: number;
};

export const getBoardCardCount = (state: State): BoardCardCount => ({
	cardsCount: boardOrderedIssueIdsSelector(state).length,
	visibleCardsCount: workFilteredIssuesSelector(state).length,
});
