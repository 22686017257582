import { SPRINT } from '@atlassian/jira-software-filters/src/common/constants';
import { FILTERED_CARD_CLEAR } from '../../../actions/card/filtered-cards';
import { FILTERS_SET, SET_ALL_FILTERS } from '../../../actions/filter';
import { SELECT_SPRINTS, CLEAR_SELECTED_SPRINTS } from '../../../actions/sprints/select';
import type { Action } from '../../../types';
import { type SprintsState, initialSprintsState } from './types';

export const sprintsReducer = (
	state: SprintsState = initialSprintsState,
	action: Action,
): SprintsState => {
	if (action.type === SELECT_SPRINTS) {
		const { sprintFilterIds } = action.payload;
		return { selected: sprintFilterIds };
	}

	if (action.type === CLEAR_SELECTED_SPRINTS || action.type === FILTERED_CARD_CLEAR) {
		return { selected: [] };
	}

	if (action.type === FILTERS_SET && action.payload.filterType === 'SPRINT') {
		const { values } = action.payload;
		return { selected: Array.isArray(values) ? values : [values] };
	}

	if (action.type === SET_ALL_FILTERS) {
		return {
			selected: action.payload.filters[SPRINT] || [],
		};
	}

	return state;
};
