import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import { fg } from '@atlassian/jira-feature-gating';
import { Capability } from '../../common/capability';
import {
	type CardClickAction,
	cardToggleSelection,
	cardSingleSelect,
	CARD_CLICK,
	cardMultiSelect,
} from '../../state/actions/card';
import { openIssueModal, openPlanIssueModal } from '../../state/actions/issue/modal';
import { getCapability } from '../../state/selectors/capability/capability-selectors';
import { getCardShiftSelectionIds } from '../../state/selectors/card/card-multi-selection';
import { getCardSelection } from '../../state/selectors/card/card-selectors';
import { isBoardIssueAChild } from '../../state/selectors/issue/board-issue-selectors';
import { getIssueById } from '../../state/selectors/issue/issue-selectors';
import {
	getIsCMPBoard,
	getIsIncrementPlanningBoard,
} from '../../state/selectors/software/software-selectors';
import {
	issueColumnIdSelector,
	issueSwimlaneIdSelector,
} from '../../state/selectors/work/work-selectors';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types';

export default function cardClickEpic(action$: ActionsObservable, store: MiddlewareAPI) {
	return action$.ofType(CARD_CLICK).mergeMap((action: CardClickAction): Observable<Action> => {
		const state = store.getState();
		const { issueId, withCmd, withShift } = action.payload;

		if (withCmd) {
			const cardSelection = getCardSelection(state);
			const isCMPBoard = getIsCMPBoard(state);
			const isMultiSelectAcrossSwimlanesEnabled = getCapability(state)(
				Capability.MULTISELECT_CARD_ACROSS_SWIMLANES,
			);
			const isAdding = cardSelection.indexOf(action.payload.issueId) === -1;

			// We only allow issues with the same column, swimlane and card type to be selected together in a CMP board
			if (isCMPBoard && cardSelection.length !== 0 && isAdding) {
				const getColumnIdByIssue = issueColumnIdSelector(state);
				const getSwimlaneIdByIssue = issueSwimlaneIdSelector(state);
				const isIssueAChild = isBoardIssueAChild(state);
				const existingSelectedId = cardSelection[0];

				const sameColumnId = getColumnIdByIssue(existingSelectedId) === getColumnIdByIssue(issueId);
				const sameSwimlaneId =
					getSwimlaneIdByIssue(existingSelectedId) === getSwimlaneIdByIssue(issueId);
				const sameCardType = isIssueAChild(existingSelectedId) === isIssueAChild(issueId);
				const sameCardGroup =
					getIssueById(state, existingSelectedId).parentId ===
					getIssueById(state, issueId).parentId;

				if (
					!sameColumnId ||
					!sameSwimlaneId ||
					!sameCardType ||
					(isIssueAChild(issueId) && !sameCardGroup)
				) {
					return Observable.empty<never>();
				}
			}

			// Don't allow issues in the same swimlane to be selected together when
			// MULTISELECT_CARD_ACROSS_SWIMLANES capability is false
			if (!isMultiSelectAcrossSwimlanesEnabled && cardSelection.length !== 0 && isAdding) {
				const getSwimlaneIdByIssue = issueSwimlaneIdSelector(state);
				const existingSelectedId = cardSelection[0];

				const sameSwimlaneId =
					getSwimlaneIdByIssue(existingSelectedId) === getSwimlaneIdByIssue(issueId);

				if (!sameSwimlaneId) {
					return Observable.empty<never>();
				}
			}

			return Observable.of(cardToggleSelection(issueId));
		}

		if (withShift) {
			const rangeSelection = getCardShiftSelectionIds(state)(issueId);
			if (rangeSelection) {
				return Observable.of(cardMultiSelect(rangeSelection));
			}
		}

		// open bento + select card
		const issue = getIssueById(state, issueId);

		const isIncrementPlanningBoard = getIsIncrementPlanningBoard(state);

		const isIssueViewInProgramBoardOn = fg('issue_view_in_program_board');

		if (isIncrementPlanningBoard) {
			if (isIssueViewInProgramBoardOn) {
				return Observable.from([cardSingleSelect(issueId), openPlanIssueModal(issueId.toString())]);
			}
			return Observable.empty();
		}

		return Observable.from([
			cardSingleSelect(issueId),
			openIssueModal(issue.key, action.payload.externalAction),
		]);
	});
}
