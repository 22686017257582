import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	nameIsTakenTitle: {
		id: 'software.main.state.selectors.column.name-is-taken-title',
		defaultMessage: 'The name ‘{name}’ is already being used on this board',
		description: '',
	},
	nameIsTakenDescription: {
		id: 'software.main.state.selectors.column.name-is-taken-description',
		defaultMessage:
			'You’ll need to give your column a unique name. Trust us - it’ll be less confusing this way',
		description: '',
	},
	minIssueCount: {
		id: 'software.main.state.selectors.column.min-issue-count',
		defaultMessage: 'MIN: {count}',
		description:
			'This is a lozenge that is displayed when a minimum issue count for the column has been specified.',
	},
	minIssueCountTooltip: {
		id: 'software.main.state.selectors.column.min-issue-count-tooltip',
		defaultMessage: 'This column will be highlighted when the number of issues is under this limit',
		description: 'This is a tooltip that explains what the minimum issue count lozenge means.',
	},
	maxIssueCount: {
		id: 'software.main.state.selectors.column.max-issue-count',
		defaultMessage: 'MAX: {count}',
		description:
			'This is a lozenge that is displayed when a max issue count for the column has been specified.',
	},
	maxIssueCountTooltip: {
		id: 'software.main.state.selectors.column.max-issue-count-tooltip',
		defaultMessage: 'This column will be highlighted when the number of issues exceeds this limit',
		description: 'This is a tooltip that explains what the max issue count lozenge means.',
	},
});
