import { useEffect, useMemo } from 'react';
import type { Person } from '@atlassian/jira-filters/src/common/types';
import { getUserShape } from '@atlassian/jira-issue-field-assignee/src/common/utils.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { ASSIGNEE_TYPE } from '@atlassian/jira-platform-field-config';
import { useDiffCheck } from '../../../../common/hooks/use-diff-check/index.tsx';
import { SYNC } from '../../../../model/issue/issue-update-origin';
// import type { Person } from '../../../../model/people/people-types';
import { useBoardActionCreator, useBoardSelector } from '../../../../state';
import { cardDataSet } from '../../../../state/actions/card';
import { getPerson } from '../../../../state/selectors/people/people-selectors';
import type { FieldSyncAssigneeProps } from './types';

export const FieldSyncAssignee = ({ issue }: FieldSyncAssigneeProps) => {
	const { key, assigneeAccountId } = issue;

	const assigneeIdFromBoard = useMemo(
		() => (assigneeAccountId ? String(assigneeAccountId) : ''),
		[assigneeAccountId],
	);

	const assigneeFromBoard = useBoardSelector((state) => getPerson(state, assigneeIdFromBoard));
	const setAssigneeInBoard = useBoardActionCreator((assignee: Person | null) =>
		cardDataSet({ ...issue, assigneeAccountId: assignee?.id ?? null }, assignee, SYNC),
	);
	const [assigneeFromIssueField, { setFieldValue: setAssigneeInIssueField }] = useFieldValue({
		issueKey: key,
		fieldKey: ASSIGNEE_TYPE,
	});

	const assigneeIdFromIssueField = useMemo(
		() => assigneeFromIssueField?.accountId || null,
		[assigneeFromIssueField?.accountId],
	);

	const assigneeFromBoardChanged = useDiffCheck([assigneeIdFromBoard]);
	const assigneeFromIssueFieldChanged = useDiffCheck([assigneeIdFromIssueField]);

	const storesNeedToSync = assigneeIdFromBoard !== assigneeIdFromIssueField;

	// Board -> Issue Field Sync
	useEffect(() => {
		if (assigneeFromBoardChanged && storesNeedToSync) {
			setAssigneeInIssueField(
				key,
				ASSIGNEE_TYPE,
				assigneeFromBoard
					? getUserShape(
							assigneeFromBoard.id,
							assigneeFromBoard.displayName,
							assigneeFromBoard.avatarUrl || '',
						)
					: null,
			);
		}
	}, [key, assigneeFromBoardChanged, storesNeedToSync, setAssigneeInIssueField, assigneeFromBoard]);

	// Issue Field -> Board Sync
	useEffect(() => {
		if (!assigneeFromBoardChanged && assigneeFromIssueFieldChanged && storesNeedToSync) {
			const issueFieldPerson: Person = assigneeFromIssueField
				? {
						...assigneeFromIssueField,
						avatarUrl: assigneeFromIssueField?.avatarUrls?.['48x48'],
						id: assigneeFromIssueField.accountId,
					}
				: null;
			setAssigneeInBoard(issueFieldPerson);
		}
	}, [
		assigneeFromBoardChanged,
		assigneeFromIssueField,
		assigneeFromIssueFieldChanged,
		setAssigneeInBoard,
		storesNeedToSync,
	]);

	return null;
};
