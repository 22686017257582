import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	copyIssueLink: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-items.copy-issue-link.copy-issue-link',
		defaultMessage: 'Copy issue link',
		description: 'Label for context menu action to copy the selected issue link',
	},
	copySuccessTitle: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-items.copy-issue-link.copy-success-title',
		defaultMessage: 'Issue link copied',
		description: 'Title for flag shown when issue link is copied to clipboard',
	},
	copySuccessMessage: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-items.copy-issue-link.copy-success-message',
		defaultMessage: 'You’ve copied the issue link to your clipboard.',
		description: 'Message body for flag shown when issue link is copied to clipboard',
	},
	copyFailureTitle: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-items.copy-issue-link.copy-failure-title',
		defaultMessage: 'We couldn’t copy the issue link',
		description: 'Title for flag shown when unable to copy the issue link to clipboard',
	},
	copyFailureMessage: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-items.copy-issue-link.copy-failure-message',
		defaultMessage: 'Refresh the page or check your browser settings, then try again.',
		description: 'Message body for flag shown when unable to copy the issue link to clipboard',
	},
});
