import { useCallback, useState } from 'react';
import {
	createLocalStorageProvider,
	createLocalExpirableStorageProvider,
} from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import type { ObjectItems } from './types';

type Options<T> = {
	defaultValue?: T;
};

type SetStateFunction<T> = (arg1: T) => T;

const isSetStateFunction = <T,>(
	setStateAction: SetStateFunction<T> | T,
): setStateAction is SetStateFunction<T> => typeof setStateAction === 'function';

/*
 * This hook functions like a useState hook but is synchronised with local storage.
 * Like a useState hook, it will trigger a re-render whenever the value is changed.
 *
 * Note: This hook interacts a singular namespace and singular key within that namespace.
 * If you need to interact with multiple keys in a singular namespace this will not work for you.
 * You can however use an object as your value if that meets your needs.
 *
 * For example if you have need to store a value per user you might use...
 * - namespace = jira-insights-onboarding-viewed
 * -       key = <atlassian-account-id>
 * -       options = { defaultValue: <default value>, ttlInMs: date.now() + <how long you want to store it from now> }
 *
 * In local storage the resulting key will be
 * __storejs_<namespace>_<key>
 */
export const useLocalStorage = <T,>(
	namespace: string,
	key: string,
	options?: Options<T>,
): [T, (arg1: T | SetStateFunction<T>, ttlInMs?: number) => void] => {
	const localStorage = createLocalStorageProvider(namespace);

	const initialValue = localStorage.get(key) ?? options?.defaultValue;

	// used to trigger re-render of components
	const [value, setValueInState] = useState<T>(initialValue);

	const setValue = useCallback<(setStateAction: T | SetStateFunction<T>, ttlInMs?: number) => void>(
		(setStateAction) => {
			if (isSetStateFunction(setStateAction)) {
				setValueInState((prevVal: T) => {
					const newVal = setStateAction(prevVal);
					localStorage.set(key, newVal);
					return newVal;
				});
			} else {
				localStorage.set(key, setStateAction);
				setValueInState(setStateAction);
			}
		},
		[key, localStorage],
	);

	return [value, setValue];
};

/*
 * This hook functions like a useState hook but is synchronised with local storage.
 * Like a useState hook, it will trigger a re-render whenever the value is changed.
 *
 * Note: This hook interacts a singular namespace and singular key within that namespace.
 * If you need to interact with multiple keys in a singular namespace this will not work for you.
 * You can however use an object as your value if that meets your needs.
 *
 * For example if you have need to store a value per user you might use...
 * - namespace = jira-insights-onboarding-viewed
 * -       key = <atlassian-account-id>
 * -       ttlInMs = <how long you want to store it from now>
 * -       options = { defaultValue: <default value> }
 *
 * In local storage the resulting key will be
 * __storejs_<namespace>_<key>
 */
export const useLocalStorageWithExpiry = <T,>(
	namespace: string,
	key: string,
	ttlInMs: number,
	options?: Options<T>,
): [T, (arg1: T | ((arg1: T) => T)) => void] => {
	const localStorage = createLocalExpirableStorageProvider(namespace);

	const initialValue = localStorage.get(key) ?? options?.defaultValue;

	// used to trigger re-render of components
	const [value, setValueInState] = useState<T>(initialValue);

	const setValue = useCallback(
		(setStateAction: T | ((arg1: T) => T)) => {
			if (isSetStateFunction(setStateAction)) {
				setValueInState((prevVal: T) => {
					const newVal = setStateAction(prevVal);

					localStorage.set(key, newVal, Date.now() + ttlInMs);

					return newVal;
				});
			} else {
				localStorage.set(key, setStateAction, Date.now() + ttlInMs);
				setValueInState(setStateAction);
			}
		},
		[key, localStorage, ttlInMs],
	);

	return [value, setValue];
};

export const useLocalStorageObject = <T,>(
	namespace: string,
	key: string,
	options?: Options<ObjectItems<T>>,
): [ObjectItems<T>, (objectKey: string, value: T) => void] => {
	const [state, setState] = useLocalStorage<ObjectItems<T>>(namespace, key, options);
	return [
		state,
		// This is for batch update
		(objectKey: string, value: T) =>
			setState((prevState) => ({
				...prevState,
				[objectKey]: value,
			})),
	];
};

export const useLocalStorageObjectWithExpiry = <T,>(
	namespace: string,
	key: string,
	ttlInMs: number,
	options?: Options<ObjectItems<T>>,
): [ObjectItems<T>, (objectKey: string, value: T) => void] => {
	const [state, setState] = useLocalStorageWithExpiry<ObjectItems<T>>(
		namespace,
		key,
		ttlInMs,
		options,
	);
	return [
		state,
		// This is for batch update
		(objectKey: string, value: T) =>
			setState((prevState) => ({
				...prevState,
				[objectKey]: value,
			})),
	];
};
