import React, { memo, useCallback, type RefObject } from 'react';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueLinkedStatsActions } from '@atlassian/jira-issue-links-stats/src/controllers/main';
import {
	AsyncContextMenu,
	AsyncMeatballMenu,
	AsyncMenuRenderer,
} from '@atlassian/jira-software-context-menu/src/async';
import {
	CONTEXT_MENU_APPEARANCE,
	MEATBALL_MENU_APPEARANCE,
} from '@atlassian/jira-software-context-menu/src/common/constants.tsx';
import type { MenuNode } from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { useTraitsActions } from '@atlassian/jira-traits/src/TraitsStore.tsx';
import { Capability } from '../../../../../../common/capability';
import { useBoardSelector } from '../../../../../../state';
import { getVisibleCardSelection } from '../../../../../../state/selectors/card/card-selectors';
import { getIsIssueDoneInClosedSprint } from '../../../../../../state/selectors/issue/issue-selectors';
import {
	getCanRankByIssue,
	projectTypeSelector,
} from '../../../../../../state/selectors/software/software-selectors';
import { useCapability } from '../../../../../../state/state-hooks/capabilities';
import { useShouldShowCardCoverChangeboarding } from '../utils';
import { useArchiveIssue } from './menu-items/archive-issue';
import { useBulkChange } from './menu-items/bulk-change';
import { useChangeDateRange } from './menu-items/change-date-range';
import { useChangeParent } from './menu-items/change-parent';
import { useChangeTeam } from './menu-items/change-team';
import { useCopyIssueKey } from './menu-items/copy-issue-key';
import { useCopyIssueLink } from './menu-items/copy-issue-link';
import { useDeleteIssue } from './menu-items/delete-issue';
import { useEditCover } from './menu-items/edit-cover';
import { useFlag } from './menu-items/flag';
import { useHeader } from './menu-items/header';
import { useAddLabel } from './menu-items/label';
import { useLinkIssue, useLinkIssueIPBoard } from './menu-items/link-issue';
import { useMoveTo } from './menu-items/move-to';
import { useRemoveFromSprint } from './menu-items/remove-from-sprint';
import { createSeparator } from './menu-items/separator';
import { useViewIssueSearch } from './menu-items/view-in-issue-search';
import messages from './messages';
import type { MenuProps, MenuRendererWithMenuItemsProps, ContextMenuItemsProps } from './types';

export const useContextMenuItems = ({
	issueId,
	issueKey,
	selectedCardIds,
}: ContextMenuItemsProps): MenuNode[] => {
	const cardIds = selectedCardIds.includes(issueId) ? selectedCardIds : [issueId];
	const isMultiSelectMode = cardIds.length > 1;

	const projectType = useBoardSelector((state) => projectTypeSelector(state));
	const isSoftwareProject = projectType === SOFTWARE_PROJECT;

	const header = useHeader({ selectedCards: cardIds });

	const shouldRenderMoveTo = useBoardSelector((state) => getCanRankByIssue(state)(issueId));
	const moveTo = useMoveTo({ issueId, selectedCardIds: cardIds, isMultiSelectMode });

	const shouldRenderAddLabelCardMenu = useCapability(Capability.RENDER_ADD_LABEL_CARD_MENU_ITEM);
	const shouldRenderAddFlagCardMenu = useCapability(Capability.RENDER_ADD_FLAG_CARD_MENU_ITEM);
	const shouldRenderEditCoverCardMenu = useCapability(Capability.RENDER_EDIT_COVER);

	const label = useAddLabel({ issueId, selectedCardIds: cardIds });
	const flag = useFlag({ issueId });
	const linkIssue = useLinkIssue({ issueId, issueKey });

	const changeParent = useChangeParent({
		issueId,
		selectedCardIds: cardIds,
		isMultiSelectMode,
	});
	const bulkChange = useBulkChange({ isMultiSelectMode });
	const editCover = useEditCover({ issueId });

	const copyIssueLink = useCopyIssueLink({ issueKey, isMultiSelectMode });
	const copyIssueKey = useCopyIssueKey({ issueKey, isMultiSelectMode });
	const viewInIssueSearch = useViewIssueSearch({ isMultiSelectMode });

	const removeFromSprint = useRemoveFromSprint({ issueId });
	const archiveIssue = useArchiveIssue({ issueKey, issueId, isMultiSelectMode });
	const deleteIssue = useDeleteIssue({ issueId, isMultiSelectMode });

	const cardActions: MenuNode[] = [
		...(shouldRenderAddFlagCardMenu ? flag : []),
		...(shouldRenderAddLabelCardMenu ? label : []),
		...(isSoftwareProject && !isMultiSelectMode ? linkIssue : []),
		...changeParent,
		...bulkChange,
		...(shouldRenderEditCoverCardMenu && !isMultiSelectMode ? editCover : []),
	];
	const otherActions: MenuNode[] = [...copyIssueLink, ...copyIssueKey, ...viewInIssueSearch];
	const deletionActions: MenuNode[] = [
		...removeFromSprint,
		...((ff('issue-unarchival_wdpb9') && archiveIssue) || []),
		...deleteIssue,
	];

	return [
		...header,
		...(shouldRenderMoveTo ? moveTo : []),
		...(shouldRenderMoveTo && moveTo.length > 0 ? [createSeparator('move-actions')] : []),
		...otherActions,
		...(otherActions.length > 0 && cardActions.length > 0
			? [createSeparator('other-actions')]
			: []),
		...cardActions,
		...(deletionActions.length > 0 ? [createSeparator('delete-actions')] : []),
		...deletionActions,
	];
};

export const useIncrementPlanningContextMenuItems = ({
	issueId,
	issueKey,
	selectedCardIds,
	returnFocusRef,
}: ContextMenuItemsProps): MenuNode[] => {
	const cardIds = selectedCardIds.includes(issueId) ? selectedCardIds : [issueId];
	const isMultiSelectMode = cardIds.length > 1;
	const header = useHeader({ selectedCards: cardIds });
	const changeDateRange = useChangeDateRange({
		issueId,
		selectedCardIds: cardIds,
		isMultiSelectMode,
	});

	const isIssueDoneInClosedSprint = useBoardSelector((state) =>
		getIsIssueDoneInClosedSprint(state, issueId),
	);

	const linkIssueOld = useLinkIssueIPBoard({ issueId, issueKey });
	const linkIssueNew = useLinkIssueIPBoard({ issueId, issueKey, returnFocusRef });

	const linkIssue = fg('increment_board_a11y_fix') ? linkIssueNew : linkIssueOld;

	const changeParent = useChangeParent({
		issueId,
		selectedCardIds: cardIds,
		isMultiSelectMode,
	});
	const changeTeam = useChangeTeam({
		issueId,
		selectedCardIds: cardIds,
		isMultiSelectMode,
	});
	return [
		...header,
		...(!isIssueDoneInClosedSprint ? changeDateRange : []),
		...(!isIssueDoneInClosedSprint && changeDateRange.length > 0
			? [createSeparator('move-actions')]
			: []),
		...(!isMultiSelectMode ? linkIssue : []),
		...changeParent,
		...(!isIssueDoneInClosedSprint ? changeTeam : []),
	];
};

const MenuRendererWithMenuItems = memo(
	({ issueId, issueKey, scope, triggerRef }: MenuRendererWithMenuItemsProps) => {
		const selectedCardIdFromState = useBoardSelector(getVisibleCardSelection);
		const shouldUseIncrementPlanningContextMenu = useCapability(
			Capability.RENDER_INCREMENT_PLANNING_CONTEXT_MENU,
		);
		const useContextMenuItemsFn = shouldUseIncrementPlanningContextMenu
			? useIncrementPlanningContextMenuItems
			: useContextMenuItems;
		const menuItems = useContextMenuItemsFn({
			issueId,
			issueKey,
			selectedCardIds: selectedCardIdFromState,
			returnFocusRef: triggerRef,
		});

		return (
			<AsyncMenuRenderer
				scope={scope}
				menuItems={menuItems}
				triggerRef={triggerRef}
				{...(ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1') &&
				shouldUseIncrementPlanningContextMenu
					? { shouldRenderToParent: false }
					: {})}
			/>
		);
	},
);

export const ContextMenu = memo((props: MenuProps) => {
	const { issueId, issueKey, selectedCardIds, appearance } = props;

	const { formatMessage } = useIntl();
	const { addTrait } = useTraitsActions();
	const { handleOnClose: handleOnIssueLinkedStatsClose } = useIssueLinkedStatsActions();

	const shouldShowCardCoverChangeboarding = useShouldShowCardCoverChangeboarding();

	const renderMenu = useCallback(
		(scope: string, menuTriggerRef?: RefObject<HTMLButtonElement>) => {
			handleOnIssueLinkedStatsClose();

			if (shouldShowCardCoverChangeboarding) {
				addTrait('SITE_USER', {
					name: 'jira-software_ui_context-menu_opened_card-actions_board-screen_first_time',
					value: new Date().toISOString(),
				});
			}

			if (appearance === MEATBALL_MENU_APPEARANCE) {
				const { triggerRef } = props;
				if (triggerRef && menuTriggerRef?.current) {
					triggerRef.current = menuTriggerRef.current;
				}
			}

			return (
				<MenuRendererWithMenuItems
					scope={scope}
					issueId={issueId}
					issueKey={issueKey}
					triggerRef={menuTriggerRef}
				/>
			);
		},
		[
			handleOnIssueLinkedStatsClose,
			shouldShowCardCoverChangeboarding,
			appearance,
			issueId,
			issueKey,
			addTrait,
			props,
		],
	);

	if (appearance === CONTEXT_MENU_APPEARANCE) {
		const { children } = props;

		return (
			<AsyncContextMenu
				scope={`${issueId}`}
				renderMenu={renderMenu}
				selectedCardIds={selectedCardIds}
				cardId={issueId}
				jsErrorBoundaryProps={{
					id: 'boardCardContextMenu',
					packageName: 'software-board',
					teamName: 'a4t-tanuki',
				}}
			>
				{children}
			</AsyncContextMenu>
		);
	}

	if (appearance === MEATBALL_MENU_APPEARANCE) {
		const { summary, onOpenChange } = props;
		const ariaLabelMessage = formatMessage(
			selectedCardIds.length > 1 ? messages.menuTriggerMoreCards : messages.menuTriggerOneCard,
			{
				summary,
				issueKey,
			},
		);

		return (
			<AsyncMeatballMenu
				scope={`${issueId}`}
				renderMenu={renderMenu}
				selectedCardIds={selectedCardIds}
				cardId={issueId}
				ariaLabel={ariaLabelMessage}
				onOpenChange={onOpenChange}
				jsErrorBoundaryProps={{
					id: 'boardCardMeatballMenu',
					packageName: 'software-board',
					teamName: 'a4t-tanuki',
				}}
			/>
		);
	}

	return null;
});
