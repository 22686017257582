import type { Observable as ObservableType } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common';

export type Payload = {
	issueIds: IssueId[];
};

const getIssueSetParentUrl = (contextPath: string, projectId: number, parentId: number) =>
	`${contextPath}/rest/internal/simplified/1.0/projects/${projectId}/issues/${parentId}/children`;

// Remove with TOTEM-2726 ff
export const fetchSetIssueParent = (
	contextPath: string,
	projectId: number,
	parentId: number,
	payload: Payload,
): ObservableType<void> =>
	fetchJson$(getIssueSetParentUrl(contextPath, projectId, parentId), {
		method: 'POST',
		body: JSON.stringify(payload),
	});

export const fetchSetCMPIssueParent = (issueKey: string, parentId: number | null) =>
	fetchJson$(`/rest/api/3/issue/${issueKey}`, {
		method: 'PUT',
		body: JSON.stringify({
			fields: {
				parent: {
					id: parentId && String(parentId),
				},
			},
		}),
	});
