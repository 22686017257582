import React, { useEffect } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { ERROR_BOUNDARY_PACKAGE } from '../../common/constants';
import { useNextBestTaskActions } from '../../services/next-best-task-container';

type Props = { isOnlyCurrentUserSelected: boolean };

const NBTDataLoaderForOnlyCurrentUserSelected = ({ isOnlyCurrentUserSelected }: Props) => {
	const { setIsOnlyCurrentUserSelected } = useNextBestTaskActions();

	useEffect(() => {
		if (setIsOnlyCurrentUserSelected != null) {
			setIsOnlyCurrentUserSelected(isOnlyCurrentUserSelected);
		}
	}, [isOnlyCurrentUserSelected, setIsOnlyCurrentUserSelected]);

	return null;
};

export const SafeNBTDataLoaderForOnlyCurrentUserSelected = ({
	isOnlyCurrentUserSelected,
}: Props) => (
	<JSErrorBoundary
		id="filter-data-loader"
		packageName={ERROR_BOUNDARY_PACKAGE}
		fallback="unmount"
		teamName="nova"
	>
		<NBTDataLoaderForOnlyCurrentUserSelected
			isOnlyCurrentUserSelected={isOnlyCurrentUserSelected}
		/>
	</JSErrorBoundary>
);
