import { useIntl } from '@atlassian/jira-intl';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { useBoardSelector } from '../../../../../../../../state';
import { selectedIssueKeysSelector } from '../../../../../../../../state/selectors/card/card-selectors';
import { VIEW_IN_ISSUE_SEARCH_MENU_ID } from '../../constants';
import messages from '../../messages';
import type { Props, ContentProps } from './types';

export const useViewIssueSearchContent = ({
	isViewInIssueSearchVisible,
	onViewIssueSearch,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	if (!isViewInIssueSearchVisible) return [];

	return [
		{
			type: MenuNodeType.Item,
			label: formatMessage(messages.viewInIssueSearch),
			id: VIEW_IN_ISSUE_SEARCH_MENU_ID,
			onClick: onViewIssueSearch,
		},
	];
};

export const useViewIssueSearch = ({ isMultiSelectMode }: Props) => {
	const { push } = useRouterActions();
	const selectedIssueKeys = useBoardSelector(selectedIssueKeysSelector);

	const onViewIssueSearch = () => {
		const url = `/issues/?jql=${encodeURIComponent(`key IN (${selectedIssueKeys.join(',')})`)}`;
		push(url);
	};

	return useViewIssueSearchContent({
		onViewIssueSearch,
		isViewInIssueSearchVisible: isMultiSelectMode,
	});
};
