import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	columnHeader: {
		id: 'software.main.board-container.board.unscheduled-work-swimlanes-container.column-header',
		defaultMessage: 'Drag issues to schedule',
		description:
			'Column header at the top of a column of cards which have not been scheduled in the users program board',
	},
	closeColumnLabel: {
		id: 'software.main.board-container.board.unscheduled-work-swimlanes-container.close-column-label',
		defaultMessage: 'Hide unscheduled work',
		description:
			'Label for a button on the unplanned work column in a program board which hides the column',
	},
});
