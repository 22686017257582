import type { Observable as ObservableType } from 'rxjs/Observable';
import { gqlTagSwag } from '@atlassian/jira-software-swag';
import { mutation$ } from '@atlassian/jira-software-swag/src/services/mutation/index.tsx';
import { dismissMigrationModalAnalyticsOptions } from '../../common/graphql/metrics';
import type { DismissFirstColumnMigrationModalGQLData } from './types';

export const dismissModalOperationName = 'DismissFirstColumnMigrationModal';

export const dismissModalMutation = gqlTagSwag`
    mutation DismissFirstColumnMigrationModal($boardId: ID!) {
        dismissFirstColumnMigrationModal(input: {boardId: $boardId}) {
            boardScope {
                id
            }
        }
    }
`;

export const dismissMigrationModal = (
	contextPath: string,
	boardId: string,
): ObservableType<DismissFirstColumnMigrationModalGQLData> =>
	mutation$(
		contextPath,
		dismissModalOperationName,
		dismissModalMutation,
		{
			boardId,
		},
		dismissMigrationModalAnalyticsOptions,
	);
