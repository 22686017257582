import React from 'react';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { IssueLinksStats as IssueLinksStatsComponent } from '@atlassian/jira-issue-links-stats';
import { useShowIssueLinksStats } from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import { useBoardSelector } from '../../../../../../state';
import { getIssueLinksPermissions } from '../../../../../../state/selectors/issue-links-permissions/issue-links-permissions-selectors';
import {
	getIsLoadingIssueLinksStatsForCard,
	getIssueLinksStatsForCard,
} from '../../../../../../state/selectors/issue-links-stats/issue-links-stats-selectors';
import { getIssueTypeById } from '../../../../../../state/selectors/issue/issue-selectors';
import type { LinkedIssuesStatsProps } from './types';

export const IssueLinksStats = ({ issue, onIssueUnlink }: LinkedIssuesStatsProps) => {
	const shouldShowIssueLinksStats = useShowIssueLinksStats();

	const issueLinksCount = useBoardSelector((state) =>
		getIssueLinksStatsForCard(state)(`${issue.id}`),
	);

	const isLoadingIssueLinkStats = useBoardSelector((state) =>
		getIsLoadingIssueLinksStatsForCard(state)(`${issue.id}`),
	);
	const { canUserLinkIssue } = useBoardSelector((boardState) =>
		getIssueLinksPermissions(boardState),
	);

	const issueType = useBoardSelector((state) => getIssueTypeById(state, Number(issue?.typeId)));

	if (
		!issue.id ||
		!shouldShowIssueLinksStats ||
		(issueLinksCount <= 0 && !isLoadingIssueLinkStats)
	) {
		return <></>;
	}

	return (
		<IssueLinksStatsComponent
			issueLinksCount={issueLinksCount}
			issueKey={issue.key}
			issueId={`${issue.id}`}
			issueSummary={issue.summary}
			issueType={issueType}
			isLoading={isLoadingIssueLinkStats}
			feedbackCollectorPrefix="board"
			onIssueUnlink={onIssueUnlink}
			canUserLinkIssue={canUserLinkIssue}
			withDueDate={false}
			sourceProductType={SOFTWARE_PROJECT}
		/>
	);
};
