import { useCallback } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { getLongTasksMetrics } from '@atlassian/jira-common-long-task-metrics/src';
import { dndReporter } from '@atlassian/jira-common-long-task-metrics/src/reporters/software-dnd';
import clearMarksWithPrefix from '@atlassian/jira-common-performance/src/clear-marks-with-prefix.tsx';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import { CARD_DND_TYPE } from '@atlassian/jira-platform-board-kit/src/common/constants/drag-drop/index.tsx';
import type {
	DraggableData,
	DraggableCardData,
	CardId,
	ColumnId,
	DraggableColumnData,
	SwimlaneId,
} from '@atlassian/jira-platform-board-kit/src/common/types.tsx';
import {
	getColumnDescriptor,
	getCardDescriptor,
} from '@atlassian/jira-platform-board-kit/src/common/utils/descriptor/index.tsx';
import { useBoardDragDropContext as usePlatformBoardDragDropContext } from '@atlassian/jira-platform-board-kit/src/ui/board-drag-drop-context/use-board-drag-drop-context/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { IP_BOARD_TYPE } from '../../../../../common/constants';
import { BOARD_LONG_TASKS_DND_MARK_NAME } from '../../../../../model/constants';
import { useBoardDispatch, useBoardSelector, useBoardStore } from '../../../../../state';
import { dragEnd, dragStart } from '../../../../../state/actions/board/drag';
import {
	cardDragSelectedTransition,
	cardGetIssueTransitions,
} from '../../../../../state/actions/card';
import { cardsMove } from '../../../../../state/actions/card/card-move';
import { columnRankRequest } from '../../../../../state/actions/column/rank';
import {
	getCardDragState,
	getSelectedTransition,
	hasNonGlobalTransitions,
	isHoverIntended,
	makeGetHasConditions,
} from '../../../../../state/selectors/card/card-selectors';
import { hasMultipleStatusesPerColumn } from '../../../../../state/selectors/column/column-selectors';
import {
	getOrderedColumnIds,
	sessionIdSelector,
} from '../../../../../state/selectors/software/software-selectors';
import { getIssuesIdsForColumn } from '../../../../../state/selectors/work/work-selectors';
import { useIsIncrementPlanningBoard } from '../../../../../state/state-hooks/capabilities';

type BoardDragDropContextProps = {
	onDragEnd?: () => void;
};

export const useBoardDragDropContext = ({
	onDragEnd: onDragEndProp,
}: BoardDragDropContextProps) => {
	const dispatch = useBoardDispatch();
	const orderedColumnIds = useBoardSelector(getOrderedColumnIds);
	const getSomeTransitionsHaveConditions = useBoardSelector(makeGetHasConditions);
	const boardSessionId = useBoardSelector(sessionIdSelector);
	const boardStore = useBoardStore();
	const isIPBoard = useIsIncrementPlanningBoard();
	const overTransitionId = useBoardSelector(
		(state) => getCardDragState(state).overTransition?.transitionId,
	);
	const selectedTransitionId = useBoardSelector(
		(state) => getSelectedTransition(state)?.transitionId,
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const hasCustomTransitions = useBoardSelector(hasNonGlobalTransitions);
	const hasMultipleStatuses = useBoardSelector(hasMultipleStatusesPerColumn);
	const isHoverIntendedDuringDrag = useBoardSelector((state) =>
		hasCustomTransitions || hasMultipleStatuses ? isHoverIntended(state) : false,
	);
	const isTransitionSelected = useBoardSelector((state) => {
		const transitionId = getSelectedTransition(state)?.transitionId;
		return hasCustomTransitions || hasMultipleStatuses ? !!transitionId : false;
	});

	const onDragStart = useCallback(
		(draggableData: DraggableCardData | DraggableColumnData) => {
			clearMarksWithPrefix(BOARD_LONG_TASKS_DND_MARK_NAME);
			setMark(BOARD_LONG_TASKS_DND_MARK_NAME);
			const draggableId =
				draggableData.type === CARD_DND_TYPE
					? getCardDescriptor(draggableData.cardId)
					: getColumnDescriptor(draggableData.columnId, draggableData.swimlaneId ?? undefined);

			dispatch(dragStart(draggableId, 'FLUID'));
			const analyticsEvent = createAnalyticsEvent({
				action: 'dragStarted',
				actionSubject: draggableData.type === CARD_DND_TYPE ? 'card' : 'column',
			});

			if (!isIPBoard && draggableData.type === CARD_DND_TYPE) {
				const { cardId } = draggableData;
				const hasConditions = getSomeTransitionsHaveConditions(cardId);
				if (hasConditions) {
					dispatch(cardGetIssueTransitions(cardId, analyticsEvent));
				}
			}
			fireUIAnalytics(analyticsEvent, isIPBoard ? { source: IP_BOARD_TYPE } : {});
		},
		[createAnalyticsEvent, dispatch, getSomeTransitionsHaveConditions, isIPBoard],
	);

	const onDragEnd = useCallback(
		(draggableData: DraggableData, targetData: DraggableData | undefined) => {
			onDragEndProp?.();
			getLongTasksMetrics('dnd').stop(dndReporter, {
				type: draggableData.type,
				boardSessionId,
				isPragmaticBoardEnabled: true,
			});
			dispatch(dragEnd());

			const analyticsEvent = createAnalyticsEvent({
				action: 'dragEnded',
				actionSubject: draggableData.type === CARD_DND_TYPE ? 'card' : 'column',
			});

			let analyticAttributes;

			if (!isIPBoard) {
				analyticAttributes =
					draggableData.type === 'CARD'
						? {
								dndType: draggableData.type,
								hasNonGlobalTransitions: hasCustomTransitions,
								isTransitionSelected,
								hoverIntent: isHoverIntendedDuringDrag,
								sameColumn: draggableData.columnId === targetData?.columnId,
							}
						: {
								dndType: draggableData.type,
							};
			} else {
				analyticAttributes = {
					source: IP_BOARD_TYPE,
				};
			}

			fireUIAnalytics(analyticsEvent, analyticAttributes);
		},
		[
			boardSessionId,
			dispatch,
			onDragEndProp,
			createAnalyticsEvent,
			isHoverIntendedDuringDrag,
			isTransitionSelected,
			hasCustomTransitions,
			isIPBoard,
		],
	);

	const onColumnMove = useCallback(
		(columnId: ColumnId, sourceColumnIndex: number, destinationColumnIndex: number) => {
			if (!isIPBoard) {
				const analyticsEvent = createAnalyticsEvent({
					action: 'moved',
					actionSubject: 'column',
				});
				dispatch(
					columnRankRequest(
						Number(columnId),
						orderedColumnIds[destinationColumnIndex],
						sourceColumnIndex,
						analyticsEvent,
					),
				);
			}
		},
		[createAnalyticsEvent, dispatch, orderedColumnIds, isIPBoard],
	);

	const onCardsMove = useCallback(
		({
			cardIds,
			sourceColumnId,
			destinationColumnId,
			sourceCardIndex,
			destinationCardIndex,
			sourceSwimlaneId,
			destinationSwimlaneId,
		}: {
			cardIds: CardId[];
			sourceColumnId: ColumnId;
			destinationColumnId: ColumnId;
			sourceCardIndex: number;
			destinationCardIndex?: number;
			sourceSwimlaneId?: SwimlaneId | null;
			destinationSwimlaneId?: SwimlaneId | null;
		}) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'updated',
				actionSubject: 'issue',
			});

			if (overTransitionId && !selectedTransitionId) {
				dispatch(
					cardDragSelectedTransition(
						overTransitionId,
						Number(destinationColumnId),
						destinationSwimlaneId ?? undefined,
					),
				);
			}

			const finalDestinationCardIndex = Number.isInteger(destinationCardIndex)
				? destinationCardIndex
				: getIssuesIdsForColumn(boardStore.getState(), {
						swimlaneId: destinationSwimlaneId,
						columnId: Number(destinationColumnId),
					})?.length;

			dispatch(
				cardsMove({
					cardIds,
					sourceColumnId: Number(sourceColumnId),
					destinationColumnId: Number(destinationColumnId),
					sourceCardIndex,
					destinationCardIndex: finalDestinationCardIndex ?? 0,
					sourceSwimlaneId,
					destinationSwimlaneId,
					analyticsEvent,
				}),
			);
		},
		[createAnalyticsEvent, dispatch, overTransitionId, selectedTransitionId, boardStore],
	);

	usePlatformBoardDragDropContext({ onDragStart, onDragEnd, onColumnMove, onCardsMove });
};
