import type { BoardDeferredData } from '../../../../services/board/board-deferred-data';
import flagMessages from '../../../../view/flags/messages';
import { warningFlagMeta, type FlagMeta } from '../../meta/flag-meta';

export const BOARD_DEFERRED_DATA_LOAD =
	'state.actions.board.deferred-data.BOARD_DEFERRED_DATA_LOAD' as const;

export type BoardDeferredDataLoadAction = {
	type: typeof BOARD_DEFERRED_DATA_LOAD;
};

export const boardDeferredDataLoad = (): BoardDeferredDataLoadAction => ({
	type: BOARD_DEFERRED_DATA_LOAD,
});

export const BOARD_DEFERRED_DATA_LOAD_SUCCESS =
	'state.actions.board.deferred-data.BOARD_DEFERRED_DATA_LOAD_SUCCESS' as const;

export type BoardDeferredDataLoadSuccessAction = {
	type: typeof BOARD_DEFERRED_DATA_LOAD_SUCCESS;
	payload: BoardDeferredData;
};

export const boardDeferredDataLoadSuccess = (
	data: BoardDeferredData,
): BoardDeferredDataLoadSuccessAction => ({
	type: BOARD_DEFERRED_DATA_LOAD_SUCCESS,
	payload: data,
});

export const BOARD_DEFERRED_DATA_LOAD_FAILURE =
	'state.actions.board.deferred-data.BOARD_DEFERRED_DATA_LOAD_FAILURE' as const;

export type BoardDeferredDataLoadFailureAction = {
	type: typeof BOARD_DEFERRED_DATA_LOAD_FAILURE;
	meta: FlagMeta;
};

export const boardDeferredDataLoadFailure = (): BoardDeferredDataLoadFailureAction => ({
	type: BOARD_DEFERRED_DATA_LOAD_FAILURE,
	meta: warningFlagMeta({
		id: BOARD_DEFERRED_DATA_LOAD_FAILURE,
		titleMessage: flagMessages.boardDeferredDataFetchFailedTitle,
		descriptionMessage: flagMessages.boardDeferredDataFetchFailedDescription,
	}),
});

export type Action =
	| BoardDeferredDataLoadAction
	| BoardDeferredDataLoadSuccessAction
	| BoardDeferredDataLoadFailureAction;
