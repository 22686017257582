import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { styled } from '@compiled/react';
import isNil from 'lodash/isNil';
import {
	type Edge,
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
	dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { ff } from '@atlassian/jira-feature-flagging';
import { useAutobatch } from '@atlassian/jira-software-react-scheduler/src/ui/autobatch/index.tsx';
import { useEvent } from '@atlassian/jira-software-react-use-event';
import { CARD_DND_TYPE } from '../../../common/constants/drag-drop';
import type { DraggableCardData } from '../../../common/types';
import type { CardDragState, UseDraggableCardParams } from './types';

const MAX_CARD_WIDTH = 270;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardDragPreviewContainer = styled.div<{ width: number }>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => `${props.width}px`,
});

const defaultIsDroppable = () => true;

export const useDraggableCard = ({
	isDraggable,
	elementRef,
	cardId,
	cardIndex,
	columnId,
	swimlaneId,
	CardPreview,
	shouldShowRipple,
	isDroppable = defaultIsDroppable,
}: UseDraggableCardParams) => {
	const autobatch = useAutobatch();
	const [dragState, setDragState] = useState<CardDragState>({ type: 'idle' });
	const batchSetDragState = useCallback(
		(state: CardDragState) => autobatch(() => setDragState(state)),
		[autobatch],
	);
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
	const batchSetClosestEdge = useCallback(
		(edge: Edge | null) => autobatch(() => setClosestEdge(edge)),
		[autobatch],
	);
	const [cardWidth, setCardWidth] = useState<number>(MAX_CARD_WIDTH);
	const batchSetCardWidth = useCallback(
		(width: number) => autobatch(() => setCardWidth(width)),
		[autobatch],
	);
	const isDroppableStable = useEvent(isDroppable);

	useEffect(() => {
		if (!ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1')) {
			if (!isDraggable || isNil(cardIndex) || !columnId || !elementRef.current) return undefined;
		} else if (!isDraggable || isNil(cardIndex) || isNil(columnId) || !elementRef.current)
			return undefined;
		return combine(
			draggable({
				element: elementRef.current,
				getInitialData: (): DraggableCardData => ({
					type: CARD_DND_TYPE,
					cardId,
					cardIndex,
					columnId,
					swimlaneId,
				}),
				onDrop: () => batchSetDragState({ type: 'idle' }),
				onGenerateDragPreview: ({ nativeSetDragImage }) => {
					const previewWidth = elementRef.current?.offsetWidth;
					if (previewWidth && previewWidth < MAX_CARD_WIDTH) batchSetCardWidth(previewWidth);

					setCustomNativeDragPreview({
						getOffset: pointerOutsideOfPreview({
							x: '8px',
							y: '8px',
						}),
						render({ container }) {
							batchSetDragState({ type: 'dragging', container });
							return () => batchSetDragState({ type: 'idle' });
						},
						nativeSetDragImage,
					});
				},
				canDrag: () => {
					if (typeof cardId === 'number') {
						return cardId > 0;
					}
					return !!cardId;
				},
			}),
			dropTargetForElements({
				element: elementRef.current,
				getIsSticky: () => true,
				getData: ({ input, element }) =>
					attachClosestEdge(
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{
							type: CARD_DND_TYPE,
							cardId,
							cardIndex,
							columnId,
							swimlaneId,
						} as DraggableCardData,
						{
							input,
							element,
							allowedEdges: ['top', 'bottom'],
						},
					),
				canDrop: (args) => {
					// If card is in optimistic state
					if (typeof cardId === 'number' && cardId < 0) {
						return false;
					}

					if (args.source.data.type === CARD_DND_TYPE) {
						return (
							isDroppableStable(
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								args.source.data as DraggableCardData,
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								{
									type: CARD_DND_TYPE,
									cardId,
									cardIndex,
									columnId,
									swimlaneId,
								} as DraggableCardData,
							) ?? true
						);
					}

					return false;
				},
				onDrag: ({ source: { data }, self }) => {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					if ((data as DraggableCardData).cardId !== cardId) {
						batchSetClosestEdge(extractClosestEdge(self.data));
					}
				},
				onDragEnter: ({ source: { data }, self }) => {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					if ((data as DraggableCardData).cardId !== cardId) {
						batchSetClosestEdge(extractClosestEdge(self.data));
					}
				},
				onDragLeave: () => batchSetClosestEdge(null),
				onDrop: () => batchSetClosestEdge(null),
			}),
		);
	}, [
		cardId,
		cardIndex,
		closestEdge,
		columnId,
		elementRef,
		isDraggable,
		swimlaneId,
		shouldShowRipple,
		isDroppableStable,
		batchSetDragState,
		batchSetClosestEdge,
		batchSetCardWidth,
	]);

	return {
		dragPreview:
			dragState.type === 'dragging'
				? ReactDOM.createPortal(
						<CardDragPreviewContainer width={cardWidth}>
							{CardPreview ? <CardPreview cardId={cardId} /> : null}
						</CardDragPreviewContainer>,
						dragState.container,
					)
				: null,
		closestEdge,
	};
};
