import { getIn, setIn } from 'icepick';
import { createSelector } from 'reselect';
import isNil from 'lodash/isNil';
import memoize from 'lodash/memoize';
import { PARENT_HIERARCHY_TYPE } from '@atlassian/jira-issue-type-hierarchies';
import { SWIMLANE_TEAMLESS } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import type {
	SprintByIterationId,
	OverlappedSprint,
	Sprint,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/types';
import { SPRINT_STATES } from '@atlassian/jira-shared-types/src/rest/jira/sprint.tsx';
import type { TeamState } from '../../reducers/entities/teams/types';
import type { State } from '../../reducers/types';
import { getColumns } from '../column/column-selectors';
import { getEntities, getIssues, getProjectIssueTypes } from '../software/software-selectors';

export const getTeams = (state: State): TeamState => getEntities(state).teams;

export const getTeamById = createSelector(getTeams, (teams) => {
	const getTeamByIdInner = (teamId: string) => teams[teamId];
	return memoize(getTeamByIdInner);
});

export const getTeamSprintsForIterations = (
	state: State,
	teamId: string | null | undefined,
): SprintByIterationId => {
	const teams = getTeams(state);
	if (!teamId || !teams[teamId]) {
		return null;
	}
	return teams[teamId].sprintByIterationId || null;
};

type SprintCapacityInfoPerSprint = {
	[teamId: string]: {
		[sprintId: string]: {
			defaultCapacity: number;
			usedCapacity: number;
			doneCapacity: number;
			totalCapacity: number | null;
			itemKey: string | undefined;
		};
	};
};

type UsedCapacityPerSprint = {
	[teamId: string]: {
		[sprintId: string]: {
			total: number;
			done: number;
		};
	};
};

const getUsedCapacityPerSprint = createSelector(
	getIssues,
	getProjectIssueTypes,
	(issues, projectIssueTypes): UsedCapacityPerSprint => {
		let usedCapacityInfoPerSprint: UsedCapacityPerSprint = {};
		Object.values(issues).forEach((issue) => {
			const { teamId, sprintId, typeId, isDone } = issue;
			// if it is epic, skip it
			const isEpicLevel = projectIssueTypes[typeId].hierarchyLevelType === PARENT_HIERARCHY_TYPE;
			if (!isEpicLevel && !isNil(teamId) && !isNil(sprintId) && sprintId !== '') {
				const usedCapacity = getIn(usedCapacityInfoPerSprint, [teamId, sprintId, 'total']) || 0;
				const doneCapacity = getIn(usedCapacityInfoPerSprint, [teamId, sprintId, 'done']) || 0;
				usedCapacityInfoPerSprint = setIn(
					usedCapacityInfoPerSprint,
					[teamId, sprintId, 'total'],
					Number(usedCapacity) + Number(issue.estimate?.value) || 0,
				);
				if (isDone) {
					usedCapacityInfoPerSprint = setIn(
						usedCapacityInfoPerSprint,
						[teamId, sprintId, 'done'],
						Number(doneCapacity) + Number(issue.estimate?.value) || 0,
					);
				}
			}
		});
		return usedCapacityInfoPerSprint;
	},
);

export const getSprintCapacityInfo = createSelector(
	[getTeams, getUsedCapacityPerSprint],
	(teams, usedCapacityInfoPerSprint) => {
		let sprintCapacityInfoPerSprint: SprintCapacityInfoPerSprint = {};
		Object.values(teams).forEach((team) => {
			const {
				id: teamId,
				capacity: defaultCapacity,
				capacityBySprintId,
				sprintByIterationId,
			} = team;
			const sprintIds = sprintByIterationId
				? Object.values(sprintByIterationId).map((sprint) => `${sprint.id}`)
				: [];
			sprintIds.forEach((sprintId: string) => {
				sprintCapacityInfoPerSprint = setIn(sprintCapacityInfoPerSprint, [teamId, sprintId], {
					defaultCapacity,
					usedCapacity: getIn(usedCapacityInfoPerSprint, [teamId, sprintId, 'total']) || 0,
					doneCapacity: getIn(usedCapacityInfoPerSprint, [teamId, sprintId, 'done']) || 0,
					totalCapacity: capacityBySprintId?.[Number(sprintId)]?.capacity ?? null,
					itemKey: capacityBySprintId?.[Number(sprintId)]?.itemKey ?? undefined,
				});
			});
		});
		return sprintCapacityInfoPerSprint;
	},
);

type OverlappedSprintInfoPerTeam = {
	[teamId: string]: {
		[sprintId: string]: OverlappedSprint[];
	};
};

export const getTwoSprintDatesOverlappedInfo = (sprintA: Sprint, sprintB: Sprint) => {
	let startDateA = sprintA.startDate;
	let endDateA = sprintA.state === SPRINT_STATES.CLOSED ? sprintA.completedDate : sprintA.endDate;
	let startDateB = sprintB.startDate;
	let endDateB = sprintB.state === SPRINT_STATES.CLOSED ? sprintB.completedDate : sprintB.endDate;
	// if one of sprint doesn't have start and end date, they are not overlapped.
	if ((isNil(startDateA) && isNil(endDateA)) || (isNil(startDateB) && isNil(endDateB))) {
		return undefined;
	}
	if (isNil(endDateA) && !isNil(endDateB)) {
		endDateA = Number.POSITIVE_INFINITY;
	}
	if (!isNil(endDateA) && isNil(endDateB)) {
		endDateB = Number.POSITIVE_INFINITY;
	}
	if (isNil(startDateA) && !isNil(startDateB)) {
		startDateA = Number.NEGATIVE_INFINITY;
	}
	if (!isNil(startDateA) && isNil(startDateB)) {
		startDateB = Number.NEGATIVE_INFINITY;
	}
	if (!isNil(startDateA) && !isNil(endDateA) && !isNil(startDateB) && !isNil(endDateB)) {
		const baseEndTime = Math.min(endDateB, endDateA);
		const baseStartTime = Math.max(startDateA, startDateB);
		const time = Math.abs(baseEndTime - baseStartTime);
		return {
			// if the time gap is less than 1 day, it is not overlapped
			isOverlapped: !(startDateB > endDateA || endDateB < startDateA) && time > 24 * 60 * 60 * 1000,
			time,
		};
	}
};

export const getOverlappedSprintPerTeam = createSelector(
	getTeams,
	(teams): OverlappedSprintInfoPerTeam => {
		let overlappedSprintInfo: OverlappedSprintInfoPerTeam = {};
		Object.values(teams).forEach((team) => {
			if (!isNil(team.sprintByIterationId)) {
				const sprints = Object.values(team.sprintByIterationId);
				for (let i = 0; i < sprints.length; i++) {
					for (let j = i + 1; j < sprints.length; j++) {
						const isOverlappedResult = getTwoSprintDatesOverlappedInfo(sprints[i], sprints[j]);
						if (!isNil(isOverlappedResult) && isOverlappedResult.isOverlapped) {
							overlappedSprintInfo = setIn(
								overlappedSprintInfo,
								[team.id, sprints[i].id],
								[
									...(getIn(overlappedSprintInfo, [team.id, sprints[i].id]) || []),
									{
										sprintName: sprints[j].name,
										time: isOverlappedResult.time,
									},
								],
							);
							overlappedSprintInfo = setIn(
								overlappedSprintInfo,
								[team.id, sprints[j].id],
								[
									...(getIn(overlappedSprintInfo, [team.id, sprints[j].id]) || []),
									{
										sprintName: sprints[i].name,
										time: isOverlappedResult.time,
									},
								],
							);
						}
					}
				}
			}
		});
		return overlappedSprintInfo;
	},
);

type CapacityInfoPerIteration = {
	[teamId: string]: {
		[iterationId: string]: {
			defaultCapacity: number;
			usedCapacity: number;
			totalCapacity: number | undefined;
		};
	};
};

type UsedCapacityPerIteration = {
	[teamId: string]: {
		[iterationId: string]: number;
	};
};

const getUsedCapacityPerIteration = createSelector(
	[getIssues, getProjectIssueTypes],
	(issues, projectIssueTypes): UsedCapacityPerIteration => {
		let usedCapacityInfoPerIteration: UsedCapacityPerIteration = {};
		Object.values(issues).forEach((issue) => {
			const { teamId, columnId, typeId } = issue;
			const team = teamId ?? SWIMLANE_TEAMLESS;
			// if it is epic, skip it
			const isEpicLevel = projectIssueTypes[typeId].hierarchyLevelType === PARENT_HIERARCHY_TYPE;
			if (!isEpicLevel && !isNil(team) && !isNil(columnId)) {
				const usedCapacity = getIn(usedCapacityInfoPerIteration, [team, columnId]) || 0;
				usedCapacityInfoPerIteration = setIn(
					usedCapacityInfoPerIteration,
					[team, columnId],
					Number(usedCapacity) + Number(issue.estimate?.value) || 0,
				);
			}
		});
		return usedCapacityInfoPerIteration;
	},
);

export const getIterationCapacityInfo = createSelector(
	[getTeams, getUsedCapacityPerIteration, getColumns],
	(teams, usedCapacityInfoPerIteration, columns) => {
		let capacityInfoPerIteration: CapacityInfoPerIteration = {};
		[
			...Object.values(teams),
			{ id: SWIMLANE_TEAMLESS, capacity: null, capacityByIterationId: null },
		].forEach((team) => {
			const { id: teamId, capacity: defaultCapacity, capacityByIterationId } = team;
			Object.keys(columns).forEach((iterationId: string) => {
				capacityInfoPerIteration = setIn(capacityInfoPerIteration, [teamId, iterationId], {
					defaultCapacity,
					usedCapacity: getIn(usedCapacityInfoPerIteration, [teamId, iterationId]) || 0,
					totalCapacity: capacityByIterationId?.[Number(iterationId)]?.capacity ?? undefined,
				});
			});
		});
		return capacityInfoPerIteration;
	},
);
