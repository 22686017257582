import 'rxjs/add/operator/map';
import type { Observable as ObservableType } from 'rxjs/Observable';
import type {
	DeleteCardInput,
	deleteCardMutation$data,
} from '@atlassian/jira-relay/src/__generated__/deleteCardMutation.graphql';
import { deleteCard, type DeleteCardExtraInput } from '../../../rest/card/delete';
import type { ServiceContext } from '../../service-context';

export const deleteCardService = (
	ctx: ServiceContext,
	{
		deleteCardInput,
		extraInput,
	}: { deleteCardInput: DeleteCardInput; extraInput: DeleteCardExtraInput },
): ObservableType<deleteCardMutation$data> => deleteCard(deleteCardInput, extraInput);
