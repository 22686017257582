import { combineReducers } from 'redux';
import type { Action } from '../../../types';
import { columnDeleteModalReducer } from './delete-modal/reducer';
import { issuesNotOnBoardReducer } from './issues-not-on-board/reducer';
import type { ColumnState } from './types';

export default combineReducers<ColumnState, Action>({
	deleteModal: columnDeleteModalReducer,
	issuesNotOnBoard: issuesNotOnBoardReducer,
});
