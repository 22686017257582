import type { Ari } from '@atlassian/jira-platform-ari';
import parameters from '@atlassian/jira-relay/src/__generated__/uiJiraSoftwareEditSprintModalQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const editSprintModalEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-edit-sprint-modal-relay" */ './src/main'),
	),
	getPreloadProps: ({
		boardId,
		sprintId,
		fetchSprints,
	}: {
		boardId: Ari;
		sprintId: Ari;
		fetchSprints: boolean;
	}) => ({
		queries: {
			editSprintModal: {
				options: {
					fetchPolicy: 'network-only' as const,
				},
				parameters,
				variables: {
					boardId,
					sprintId,
					fetchSprints,
				},
			},
		},
	}),
});
