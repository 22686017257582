import { loadProperty } from './load-property';
import {
	saveProperty,
	savePropertyWithoutSendRequest,
	sendUpdatePropertyRequest,
} from './save-property';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	loadProperty,
	saveProperty,
	savePropertyWithoutSendRequest,
	sendUpdatePropertyRequest,
};
