import { entityArrayToMap } from '../../../../services/software/software-data-transformer';
import { WORK_DATA_SET } from '../../../actions/work';
import type { Action } from '../../../types';
import type { StatusesState } from './types';

export const statusesReducer = (state: StatusesState = {}, action: Action): StatusesState => {
	if (action.type === WORK_DATA_SET) {
		const {
			payload: { statuses },
		} = action;
		return entityArrayToMap(statuses);
	}
	return state;
};
