import isNil from 'lodash/isNil';
import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories';
import {
	ChangeEventTypes,
	type ChangeEvent,
} from '@atlassian/jira-issue-view-model/src/change-type';
import { REFRESH_SOURCE_BENTO } from '../../../model/constants';
import type { IssueParent } from '../../../model/issue/issue-types';
import { BENTO } from '../../../model/issue/issue-update-origin';
import type { Status } from '../../../model/software/software-types';
import { setIssueParentSummary, setIssueParentStatus } from '../../../state/actions/issue/parent';
import { setIssuesRank } from '../../../state/actions/issue/rank-transition';
import { issueUpdateRequestWithTransactionId } from '../../../state/actions/issue/update';
import { workRefreshData } from '../../../state/actions/work';
import type { State } from '../../../state/types';

type BentoStatusFieldValue = {
	id: number;
	name: string;
	statusCategory: {
		id: number;
	};
};

export const ignoredFields = ['description', 'assignee', 'status', 'labels', 'reporter'];

export const mapBentoResponseToIssueParent = (
	issueParent: IssueParent,
	state: State,
	payload: ChangeEvent,
	transactionId: string,
) => {
	if (!payload.type || !issueParent) {
		return null;
	}

	const { type } = payload;

	/* uncomment when https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/pull-requests/20736 merged */
	if (
		type === ChangeEventTypes.ISSUE_TYPE_CHANGED /* || type === ChangeEventTypes.ISSUE_COVER_SET */
	) {
		return null;
	}

	if (
		payload.type === ChangeEventTypes.FIELD_CHANGE_REQUESTED &&
		payload.meta.fieldId === 'summary'
	) {
		return setIssueParentSummary(Number(payload.issueId), payload.meta.fieldValue);
	}

	if (payload.type === ChangeEventTypes.FIELD_CHANGE_FAILED && payload.meta.fieldId === 'summary') {
		return issueUpdateRequestWithTransactionId([issueParent.id], BENTO, transactionId);
	}

	if (
		payload.type === ChangeEventTypes.FIELD_CHANGED &&
		payload.meta.fieldId !== 'summary' &&
		!ignoredFields.includes(payload.meta.fieldId)
	) {
		return issueUpdateRequestWithTransactionId([issueParent.id], BENTO, transactionId);
	}

	if (payload.type === ChangeEventTypes.FIELD_CHANGED) {
		if (
			payload.meta.fieldId === 'status' &&
			payload.meta.fieldValue &&
			!isNil(payload.meta.fieldValue.id) &&
			!isNil(payload.meta.fieldValue.name) &&
			!isNil(payload.meta.fieldValue.statusCategory)
		) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const { id, name, statusCategory } = payload.meta.fieldValue as BentoStatusFieldValue;

			const status: Status = {
				id,
				name,
				category: statusCategoryForId(statusCategory.id),
			};

			return setIssueParentStatus(Number(payload.issueId), status);
		}
	}

	if (payload.type === ChangeEventTypes.CHILD_ISSUE_ADDED) {
		return workRefreshData(REFRESH_SOURCE_BENTO);
	}

	if (payload.type === ChangeEventTypes.ISSUE_CHILDREN_ORDER_CHANGED) {
		const meta = {
			issueIds: payload.meta.issueIds.map((id) => Number(id)),
			rankId: payload.meta.rankId,
			isRankAfter: payload.meta.isRankAfter,
		};
		return setIssuesRank(meta);
	}

	return null;
};
