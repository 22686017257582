import { useEffect } from 'react';
import type { Result } from '@atlassian/jira-software-fast-virtual/src/services/use-virtual/index.tsx';
import { useBoardSelector } from '../../../../../state';
import { getCardAutoScroll } from '../../../../../state/selectors/card/card-selectors';
import { platformIssueSelector } from '../../../../../state/selectors/work/work-selectors';

export const useAutoScrollSwimlane = (swimlaneIds: string[], scrollTo: Result['scrollTo']) => {
	const autoScroll = useBoardSelector(getCardAutoScroll);
	const getPlatformIssue = useBoardSelector(platformIssueSelector);
	useEffect(() => {
		const autoScrollIssueId = autoScroll?.issueId;
		const autoScrollSwimlaneId = autoScrollIssueId
			? getPlatformIssue(autoScrollIssueId)?.swimlaneId
			: undefined;

		if (!autoScrollSwimlaneId) return;

		const swimlaneIndex = swimlaneIds.indexOf(autoScrollSwimlaneId);
		if (swimlaneIndex === -1) return;

		return scrollTo(swimlaneIndex, { immediate: true, hasVirtualisedChildren: true });
	}, [autoScroll, getPlatformIssue, scrollTo, swimlaneIds]);
};
