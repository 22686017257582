import React from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { fontFallback } from '@atlaskit/theme/typography';
// eslint-disable-next-line import/order
import { token } from '@atlaskit/tokens';

import { gridSize, borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	type ColorSchema,
	type Color,
	PURPLE,
} from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { transformColorToTheme } from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import type { Props, LozengeStyleProp } from './types';

export const CardLozenge = (props: Props) => {
	const { color, children } = props;

	const epicLozengeStyle = (color && epicLozengeThemes[color]) || undefined;

	/* eslint-disable jira/react/no-style-attribute */
	return (
		<Container>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
			<LozengeContainer style={epicLozengeStyle}>
				<LozengeContent>{children}</LozengeContent>
			</LozengeContainer>
		</Container>
	);
	/* eslint-enable jira/react/no-style-attribute */
};

CardLozenge.defaultProps = {
	// not using tokens - this is a key to get predefined color schema, not actual color string
	color: PURPLE,
};

export default CardLozenge;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerControl = styled.span({
	maxWidth: '100%',
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		lineHeight: `${gridSize * 2}px`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerExperiment = styled2.span({
	maxWidth: '100%',
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		lineHeight: `${gridSize * 2}px`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	ContainerExperiment,
	ContainerControl,
);

const epicLozengeThemes: Record<Color, LozengeStyleProp> = transformColorToTheme(
	(colorSchema: ColorSchema) => ({
		color: colorSchema.text,
		backgroundColor: colorSchema.textBackground,
	}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeContainer = styled2.span({
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.small', fontFallback.body.small),
	fontWeight: token('font.weight.bold', '700'),
	textTransform: 'uppercase',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeContent = styled2.span({
	display: 'inline-block',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	verticalAlign: 'top',
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	maxWidth: '100%',
	padding: `0 ${token('space.050', '4px')}`,
});
