import { useEffect } from 'react';
import { useBoardDispatch, useBoardSelector } from '../../../state';
import { openTeamAssociationModal } from '../../../state/actions/sprints/sprint-association-modal';
import { getColumns } from '../../../state/selectors/column/column-selectors';
import { getTeams } from '../../../state/selectors/team/team-selectors';
import { useIsIncrementPlanningBoard } from '../../../state/state-hooks/capabilities';

export const useOpenTeamAssociationModal = () => {
	const isIncrementPlanningBoard = useIsIncrementPlanningBoard();
	const teams = useBoardSelector(getTeams);
	const numberOfIterations = Object.keys(useBoardSelector(getColumns)).length - 1;

	const dispatch = useBoardDispatch();

	useEffect(() => {
		if (isIncrementPlanningBoard && teams) {
			const teamArray = Object.values(teams);
			if (teamArray.length > 0) {
				for (const team of teamArray) {
					const numberOfTeamIterations =
						team.sprintByIterationId && Object.keys(team.sprintByIterationId).length;
					if (
						numberOfTeamIterations &&
						numberOfTeamIterations > 0 &&
						numberOfTeamIterations < numberOfIterations
					) {
						dispatch(openTeamAssociationModal(team.id));
						return;
					}
				}
			}
		}
	}, [dispatch, isIncrementPlanningBoard, numberOfIterations, teams]);
};
