import React, { type KeyboardEvent, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

type Props = {
	isDisabled: boolean;
} & {
	numDoneCards: number;
	onClick: (event: MouseEvent | KeyboardEvent, analyticsEvent: UIAnalyticsEvent) => void;
};

export const View = ({ onClick, numDoneCards, isDisabled }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOnClick = (event: MouseEvent | KeyboardEvent) => {
		onClick(event, createAnalyticsEvent({ actionSubject: 'dropdownMenuItem', action: 'clicked' }));
	};

	const menuItemClearAll = (
		<DropdownItem
			onClick={handleOnClick}
			isDisabled={isDisabled}
			data-testid="software-board.board-container.board.column.header.menu.item-clear-all"
		>
			{formatMessage(messages.label, { numDoneCards })}
		</DropdownItem>
	);

	if (isDisabled) {
		return <Tooltip content={formatMessage(messages.tooltip)}>{menuItemClearAll}</Tooltip>;
	}

	return menuItemClearAll;
};

export default View;
