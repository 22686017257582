import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';
import type {
	IssueIds,
	IssuesLinksResponse,
} from '../../../model/issue-links-stats/issue-links-stats-types';

export const FETCH_ISSUE_LINKS_STATS =
	'state.actions.issue-links-stats.FETCH_ISSUE_LINKS_STATS' as const;

type Meta = {
	analyticsEvent?: AnalyticsEvent;
};
export type FetchIssueLinksStatsAction = {
	type: typeof FETCH_ISSUE_LINKS_STATS;
	payload?: IssueIds;
	meta?: Meta;
};

export const fetchIssueLinksStats = (
	payload?: IssueIds,
	meta?: Meta,
): FetchIssueLinksStatsAction => ({
	type: FETCH_ISSUE_LINKS_STATS,
	payload,
	meta,
});

export const SET_ISSUE_LINKS_STATS =
	'state.actions.issue-links-stats.SET_ISSUE_LINKS_STATS' as const;

export type SetIssueLinksStatsAction = {
	type: typeof SET_ISSUE_LINKS_STATS;
	payload: IssuesLinksResponse;
};

export const setIssueLinksStats = (payload: IssuesLinksResponse): SetIssueLinksStatsAction => ({
	type: SET_ISSUE_LINKS_STATS,
	payload,
});

export const SET_ISSUE_LINKS_STATS_LOADING =
	'state.actions.issue-links-stats.SET_ISSUE_LINKS_STATS_LOADING' as const;

export type SetIssueLinksStatsLoadingAction = {
	type: typeof SET_ISSUE_LINKS_STATS_LOADING;
	payload: IssueIds;
};

export const setIssueLinksStatsLoading = (payload: IssueIds): SetIssueLinksStatsLoadingAction => ({
	type: SET_ISSUE_LINKS_STATS_LOADING,
	payload,
});

export type Action =
	| FetchIssueLinksStatsAction
	| SetIssueLinksStatsAction
	| SetIssueLinksStatsLoadingAction;
