import type { IssueId } from '@atlassian/jira-software-board-common';
import type { OptimisticId } from '../../../../model/software/software-types';
import type { FlagMeta } from '../../meta/flag-meta';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiWithFlagMeta,
	type OptimisticUiMeta,
} from '../../meta/optimistic-ui-meta';

export const CARD_MEDIA_TOGGLE_REQUEST =
	'state.actions.board.menu.CARD_MEDIA_TOGGLE_REQUEST' as const;

export type CardMediaToggleRequestAction = {
	type: typeof CARD_MEDIA_TOGGLE_REQUEST;
	payload: boolean;
	meta: OptimisticUiMeta;
};

export const cardMediaToggleRequest = (enabled: boolean): CardMediaToggleRequestAction => ({
	type: CARD_MEDIA_TOGGLE_REQUEST,
	payload: enabled,
	meta: beginOptimisticUiMeta(),
});

export const CARD_MEDIA_TOGGLE_SUCCESS =
	'state.actions.board.menu.CARD_MEDIA_TOGGLE_SUCCESS' as const;

export type CardMediaToggleSuccessAction = {
	type: typeof CARD_MEDIA_TOGGLE_SUCCESS;
	meta: OptimisticUiMeta;
};

export const cardMediaToggleSuccess = (
	optimisticId: OptimisticId,
): CardMediaToggleSuccessAction => ({
	type: CARD_MEDIA_TOGGLE_SUCCESS,
	meta: commitOptimisticUiMeta(optimisticId),
});

export const CARD_MEDIA_TOGGLE_FAILURE =
	'state.actions.board.menu.CARD_MEDIA_TOGGLE_FAILURE' as const;

export type CardMediaToggleFailureAction = {
	type: typeof CARD_MEDIA_TOGGLE_FAILURE;
	meta: OptimisticUiMeta & FlagMeta;
};

export const cardMediaToggleFailure = (
	optimisticId: OptimisticId,
): CardMediaToggleFailureAction => ({
	type: CARD_MEDIA_TOGGLE_FAILURE,
	meta: revertOptimisticUiWithFlagMeta(optimisticId),
});

export const TOGGLE_EPIC_LABELS = 'state.actions.board.menu.TOGGLE_EPIC_LABELS';

type ToggleEpicLabelsAction = {
	type: typeof TOGGLE_EPIC_LABELS;
};

export const toggleBoardEpicLabels = (): ToggleEpicLabelsAction => ({
	type: TOGGLE_EPIC_LABELS,
});

// this can be removed when removing Capability.IS_TAB_NAVIGATION
export const TOGGLE_BOARD_MENUS = 'state.actions.board.menu.TOGGLE_BOARD_MENUS';

// this can be removed when removing Capability.IS_TAB_NAVIGATION
type ToggleBoardMenusAction = {
	type: typeof TOGGLE_BOARD_MENUS;
};

// this can be removed when removing Capability.IS_TAB_NAVIGATION
export const toggleBoardMenus = (): ToggleBoardMenusAction => ({
	type: TOGGLE_BOARD_MENUS,
});

export const SET_SHOW_UNSCHEDULED_COLUMN = 'state.actions.board.menu.SET_SHOW_UNSCHEDULED_COLUMN';

type SetShowUnscheduledColumnAction = {
	type: typeof SET_SHOW_UNSCHEDULED_COLUMN;
	payload: boolean;
};

export const setShowUnscheduledColumn = (isVisible: boolean): SetShowUnscheduledColumnAction => ({
	type: SET_SHOW_UNSCHEDULED_COLUMN,
	payload: isVisible,
});

export const SET_SHOW_OFFTRACK_DEPENDENCY_LINES =
	'state.actions.board.menu.SET_SHOW_OFFTRACK_DEPENDENCY_LINES';

type SetShowOfftrackDependencyLinesAction = {
	type: typeof SET_SHOW_OFFTRACK_DEPENDENCY_LINES;
	payload: boolean;
};

export const setShowOfftrackDependencyLines = (
	isVisible: boolean,
): SetShowOfftrackDependencyLinesAction => ({
	type: SET_SHOW_OFFTRACK_DEPENDENCY_LINES,
	payload: isVisible,
});

export const SET_ISSUE_IDS_TO_SHOW_DEPENDENCIES =
	'state.actions.board.menu.SET_ISSUE_IDS_TO_SHOW_DEPENDENCIES';

type SetIssueIdsToShowDependenciesAction = {
	type: typeof SET_ISSUE_IDS_TO_SHOW_DEPENDENCIES;
	payload: (string | number)[];
};

export const setIssueIdsToShowDependencies = (
	issueIds: IssueId[],
): SetIssueIdsToShowDependenciesAction => ({
	type: SET_ISSUE_IDS_TO_SHOW_DEPENDENCIES,
	payload: issueIds,
});

export type Action =
	| CardMediaToggleRequestAction
	| CardMediaToggleSuccessAction
	| CardMediaToggleFailureAction
	| ToggleEpicLabelsAction
	| ToggleBoardMenusAction
	| SetShowUnscheduledColumnAction
	| SetShowOfftrackDependencyLinesAction
	| SetIssueIdsToShowDependenciesAction;
