import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	linkIssueKey: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-items.link-issue.link-issue-key',
		defaultMessage: 'Link issue',
		description: 'Label for context menu action to link an issue',
	},
	addDependencyKey: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-items.link-issue.add-dependency-key',
		defaultMessage: 'Add dependency',
		description: 'Label for context menu action to add a dependency',
	},
	addDependencyCta: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-items.link-issue.add-dependency-cta',
		defaultMessage: 'Add',
		description: 'Label for CTA to add a dependency',
	},
});
