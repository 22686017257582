import { useIntl } from '@atlassian/jira-intl';
import {
	type MenuItemSearch,
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { CHANGE_TEAM_MENU_ID, CHANGE_TEAM_SEARCH_MENU_ID } from '../../constants';
import messages from '../../messages';
import type { ContentProps } from './types';

export const useChangeTeamContent = ({ setQuery, options }: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	const subMenu = [
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		{
			type: MenuNodeType.Search,
			id: CHANGE_TEAM_SEARCH_MENU_ID,
			children: options,
			hasSearch: true,
			isLoading: false,
			onChange: setQuery,
		} as MenuItemSearch,
	];

	return [
		{
			type: MenuNodeType.Item,
			label: formatMessage(messages.changeTeam),
			id: CHANGE_TEAM_MENU_ID,
			submenuWidth: 284,
			children: subMenu,
		},
	];
};
