import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { RETROSPECTIVE_BLUEPRINT_COMPLETE_KEY } from '../../../common/constants';
import { getSprint } from '../../get-sprint';
import type { ConfluencePage } from '../types';

export const createRetro = async (
	cloudId: string,
	spaceKey: string,
	parentPageId: string | null,
	sprintId: string,
	blueprintId?: string | undefined,
): Promise<ConfluencePage> => {
	const [sprint] = await Promise.all([getSprint(sprintId)]);

	const title = `Retrospective: ${sprint.name}`;

	if (!blueprintId) {
		throw new Error(
			'User attempted to create a Confluence page retro, but no retro blueprint was found',
		);
	}

	return fetchJson<ConfluencePage>(
		`/gateway/api/ex/confluence/${cloudId}/wiki/rest/create-dialog/1.0/content/blueprint/${RETROSPECTIVE_BLUEPRINT_COMPLETE_KEY}/instance`,
		{
			method: 'POST',
			body: JSON.stringify({
				content: {
					type: 'page',
					title,
					status: 'draft',
					ancestors: parentPageId ? [{ id: parentPageId }] : undefined,
					space: {
						key: spaceKey,
					},
					// these values are required to create an NCS page
					metadata: {
						properties: {
							editor: {
								value: 'v2',
							},
						},
						frontend: {
							embedded: true,
						},
					},
				},
				contentBlueprintSpec: {
					context: {
						spaceKey,
						parentPageId,
					},
					blueprintId,
				},
			}),
		},
	);
};
