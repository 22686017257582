/*!
 * This module contains services to perform the "issue rank transition"
 * interaction. It is the core interation on the board triggered by DnD.
 */
import type { Observable, Observable as ObservableType } from 'rxjs/Observable';
import type {
	boardCardMoveGraphqlMutation$data,
	BoardCardMoveInput,
} from '@atlassian/jira-relay/src/__generated__/boardCardMoveGraphqlMutation.graphql';
import type {
	BulkIssueRankResponse,
	BulkIssuesRankRequestPayload,
	IssueTransitionAndRankRequestPayload,
	IssueTransitionAndRankResponse,
	IssueTransitionRequestPayload,
} from '../../model/issue/issue-types';
import { fetchBoardCardMoveData } from '../../rest/board-card-move-graphql';
import {
	bulkIssuesRankRest,
	issueRankTransitionRest,
	issueTransitionRest,
} from '../../rest/issue/issue-rank-transition';
import type { ServiceContext } from '../service-context';

/**
 * Perform transition and rank request to Jira
 *
 * @throws {FetchError} Throw fetch error if `response.issuesWereTransitioned` is false.
 */
export const issueRankTransitionService = (
	ctx: ServiceContext,
	params: Omit<IssueTransitionAndRankRequestPayload, 'rapidViewId'>,
): Observable<IssueTransitionAndRankResponse> =>
	issueRankTransitionRest({
		...params,
		rapidViewId: ctx.boardId,
	});

/**
 * Perform transition only request
 *
 * @throws {FetchError} Throw fetch error if `response.issuesWereTransitioned` is false.
 */
export const issueTransitionService = (
	ctx: ServiceContext,
	params: Omit<IssueTransitionRequestPayload, 'rapidViewId'>,
): Observable<IssueTransitionAndRankResponse> =>
	issueTransitionRest({
		...params,
		rapidViewId: ctx.boardId,
	});

/**
 * @deprecated FD-25565 was not rolled-out and this is unused
 */
export const boardCardMoveService = (
	boardCardMoveInput: BoardCardMoveInput,
): ObservableType<boardCardMoveGraphqlMutation$data> => fetchBoardCardMoveData(boardCardMoveInput);

export const bulkIssueRankService = (
	request: BulkIssuesRankRequestPayload,
): Observable<BulkIssueRankResponse> => bulkIssuesRankRest(request);
