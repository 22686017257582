import memoizeOne from 'memoize-one';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux';
import { CAN_EDIT_BOARD } from '../../../../../../../model/permission/permission-types';
import { columnDelete } from '../../../../../../../state/actions/column/delete';
import { getPermissionsSelector } from '../../../../../../../state/selectors/board/board-permissions-selectors';
import {
	doesColumnHaveIssuesSelector,
	hasIssuesNotOnBoard,
	isInlineColumnEditEnabled,
	isLastRemainingColumn,
} from '../../../../../../../state/selectors/work/work-selectors';
import type { State } from '../../../../../../../state/types';
import ItemDelete, { type Props as MenuItemProps } from './view';

type Props = {
	columnId: number;
} & Omit<MenuItemProps, 'isLastRemainingColumn'>;

const mapStateToProps = (state: State, props: Props) => ({
	isDisabled:
		props.isDisabled ||
		!getPermissionsSelector(state)[CAN_EDIT_BOARD] ||
		!isInlineColumnEditEnabled(state),
	isLastRemainingColumn: isLastRemainingColumn(state),
	hasIssues: doesColumnHaveIssuesSelector(state)(props.columnId),
	hasIssuesNotOnBoard: hasIssuesNotOnBoard(state)(props.columnId),
});

const mapDispatchToProps = {
	columnDelete,
} as const;

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const onClickFactory = memoizeOne(
	(
		onClick: Props['onClick'],
		dispatchPropsColumnDelete: DispatchProps['columnDelete'],
		columnId: Props['columnId'],
		hasIssues: StateProps['hasIssues'],
		statePropsHasIssuesNotOnBoard: StateProps['hasIssuesNotOnBoard'],
	) =>
		() => {
			onClick();
			dispatchPropsColumnDelete(columnId, hasIssues, statePropsHasIssuesNotOnBoard);
		},
);

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, props: Props) => ({
	isDisabled: stateProps.isDisabled,
	isLastRemainingColumn: stateProps.isLastRemainingColumn,
	onClick: onClickFactory(
		props.onClick,
		dispatchProps.columnDelete,
		props.columnId,
		stateProps.hasIssues,
		stateProps.hasIssuesNotOnBoard,
	),
});

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

const ColumnDeleteMenuItemWithAnalytics = AnalyticsEventToProps('columnSettingsDeleteMenuItem', {
	onClick: 'clicked',
})(ItemDelete);

export default connector(ColumnDeleteMenuItemWithAnalytics);
