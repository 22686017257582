import { useIntl } from '@atlassian/jira-intl';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { useBoardActionCreator, useBoardSelector } from '../../../../../../../../state';
import { showIssueFlagWithCommentModal } from '../../../../../../../../state/actions/issue/flag-with-comment-modal';
import { isIssueFlagged } from '../../../../../../../../state/selectors/issue/issue-selectors';
import { FLAG_MENU_ID } from '../../constants';
import messages from '../../messages';
import type { Props, ContentProps } from './types';

export const useFlagContent = ({ onToggleFlag, isFlagged }: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	return [
		{
			type: MenuNodeType.Item,
			label: isFlagged ? formatMessage(messages.removeFlag) : formatMessage(messages.addFlag),
			id: FLAG_MENU_ID,
			onClick: onToggleFlag,
		},
	];
};

export const useFlag = ({ issueId }: Props) => {
	const isFlagged = useBoardSelector((state) => isIssueFlagged(state, issueId));

	const onToggleFlag = useBoardActionCreator(() => showIssueFlagWithCommentModal(issueId));

	return useFlagContent({
		onToggleFlag,
		isFlagged,
	});
};
