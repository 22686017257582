import React from 'react';
import { lazy, LazyWait } from 'react-loosely-lazy';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales';
import type AddPeopleModalType from '@atlassian/jira-project-add-people';
import { AddPeopleModalBoundary } from '@atlassian/jira-project-add-people/src/async.tsx';

type Props = {
	isOpen: boolean;
	projectId: number;
	projectKey: string;
	baseUrl: string;
	locale: Locale;
	allowFlags: boolean;
	onClose: () => void;
	onAddSuccess: () => void;
	onError: () => void;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AddPeopleModal = lazy<typeof AddPeopleModalType>(
	() =>
		import(
			/* webpackChunkName: "async-project-add-people-dialog" */ '@atlassian/jira-project-add-people'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => (
	<AddPeopleModalBoundary packageName="software-board">
		<LazyWait until={props.isOpen}>
			{/* @ts-expect-error - TS2741 - Property 'onStopPerfMetric' is missing in type '{ analyticsPrefix: string; isAdmin: true; projectType: "software"; isOpen: boolean; projectId: number; projectKey: string; baseUrl: string; locale: Locale; allowFlags: boolean; onClose: () => void; onAddSuccess: () => void; onError: () => void; }' but required in type 'Pick<Pick<ExternalProps, "baseUrl" | "isAdmin" | "onError" | "isOpen" | "onClose" | "projectType" | "projectKey" | "projectId" | "defaultRoleId" | "adminRoleId" | ... 8 more ... | "partiesInProject">, "baseUrl" | ... 10 more ... | "onAddSuccess">'. */}
			<AddPeopleModal {...props} analyticsPrefix="software.board" isAdmin projectType="software" />
		</LazyWait>
	</AddPeopleModalBoundary>
);
