import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/bufferWhen';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/concat';
import 'rxjs/add/operator/catch';
import isEmpty from 'lodash/isEmpty';
import deepEquals from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	JSW_ISSUE_VIEW_CREATE_ISSUE_IN_EPIC,
	JSW_ISSUE_VIEW_CREATE_SUBTASK,
} from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants';
import { getBoardPrettyUrl } from '@atlassian/jira-software-board-utils';
import { BOARD } from '@atlassian/jira-software-resource-invalidator/src/common/types.tsx';
import { triggerCacheRefreshAnalytics } from '@atlassian/jira-software-resource-invalidator/src/controllers/resources-cache-refresh-analytics-subject/index.tsx';
import { getBoardJirtJitter } from '../../feature-flags';
import { EDIT_BOARD_CONFIG } from '../../model/board/constants';
import { REFRESH_SOURCE_GLOBAL_ISSUE_CREATE } from '../../model/constants';
import { SWIMLANE_BY_JQL } from '../../model/swimlane/swimlane-modes';
import type { WorkData, WorkDataCritical } from '../../model/work/work-types';
import { fetchWorkModeData } from '../../services/board-scope-graphql/non-critical';
import { transformCriticalData } from '../../services/board-scope-graphql/transformer';
import { makeServiceContext } from '../../services/service-context';
import type { Action } from '../../state/actions';
import { checkGlobalIssueCreate } from '../../state/actions/check-global-issue-create';
import {
	SOFTWARE_APP_LOADED,
	SOFTWARE_APP_INITIAL_STATE_LOADED,
} from '../../state/actions/software';
import {
	type WorkDataSetAction,
	WORK_REFRESH_DATA,
	loadBoardFailure,
	showLogBackInMessage,
	showIPBoard404View,
	workDataSet,
	loadBoardFailureNoColumn,
	type WorkRefreshDataAction,
	BOARD_LOAD_FAILURE,
	WORK_DATA_SET,
} from '../../state/actions/work';
import { isDragging } from '../../state/selectors/board/board-selectors';
import { getActiveCustomFilterIds } from '../../state/selectors/filter/custom-filter-selectors';
import {
	getIsIncrementPlanningBoard,
	contextPathSelector,
	rapidViewIdSelector,
	projectKeySelector,
} from '../../state/selectors/software/software-selectors';
import {
	hasAnyActiveOptimistic,
	workDataWithDevStatusSelector,
} from '../../state/selectors/work/work-selectors';
import type { ActionsObservable, MiddlewareAPI } from '../../state/types';
import { handleCMPBoardDataSet } from '../work/handle-work-data-set';

function isOptimisticOrDragging(store: MiddlewareAPI) {
	const state = store.getState();
	return hasAnyActiveOptimistic(state) || isDragging(state);
}

function shouldCloseBuffer(action$: ActionsObservable, store: MiddlewareAPI) {
	return action$.filter(() => !isOptimisticOrDragging(store));
}

function shouldAbort(action$: ActionsObservable, store: MiddlewareAPI) {
	return action$.filter(() => isOptimisticOrDragging(store));
}

function updateIssueDevStatus(store: MiddlewareAPI) {
	return (workData: WorkData): WorkData =>
		workDataWithDevStatusSelector(store.getState())(workData);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function onFailure(error: any, store: MiddlewareAPI): Observable<Action> {
	// when fetching with etag, backend responses 304 meaning that no data change
	// do nothing in thi case
	if (error.statusCode === 304) return Observable.empty<never>();

	if (error.statusCode === 401 || error.statusCode === 403) {
		const boardUrl = getBoardPrettyUrl(
			contextPathSelector(store.getState()),
			projectKeySelector(store.getState()),
			rapidViewIdSelector(store.getState()),
		);
		return Observable.of(showLogBackInMessage(boardUrl));
	}

	if (error.statusCode === 404 && getIsIncrementPlanningBoard(store.getState())) {
		return Observable.of(showIPBoard404View());
	}

	log.safeErrorWithoutCustomerData(
		'board.noncritical.load.failure',
		'Failure on board load',
		error,
	);

	return Observable.of(loadBoardFailure(error, false));
}

/**
 * Remove swimlaneModId from refreshes for CMP boards so user based swimlanes are
 * not overridden on refresh.
 */
function onCMPBoardRefreshResult(
	store: MiddlewareAPI,
	action: WorkDataSetAction,
): Observable<Action> {
	const state = store.getState();
	const swimlaneMode = state.ui.swimlane.mode;

	const customFilterIds = getActiveCustomFilterIds(state);
	const isCustomFiltersOn = customFilterIds.length > 0;

	// For JQL swimlanes in CMP boards, use admin/backend settings as only admins can configure them
	if (swimlaneMode === SWIMLANE_BY_JQL.id) {
		return handleCMPBoardDataSet(action, isCustomFiltersOn);
	}

	return handleCMPBoardDataSet(
		{
			...action,
			payload: {
				...action.payload,
				swimlaneModeId: swimlaneMode ?? action.payload.swimlaneModeId,
			},
		},
		isCustomFiltersOn,
	);
}

function fetchBoardScopeData(store: MiddlewareAPI): Observable<Action> {
	const state = store.getState();
	const ctx = makeServiceContext(state);
	const customFilterIds = getActiveCustomFilterIds(state);

	// We do not pass quick filters and we do not want hidden issues for a refresh.
	return fetchWorkModeData(
		ctx,
		ctx.isCMPBoard
			? {
					activeQuickFilters: customFilterIds,
					includeHidden: customFilterIds.length > 0,
				}
			: undefined,
	)
		.map(updateIssueDevStatus(store))
		.map(workDataSet)
		.flatMap((action) => {
			if (ctx.isCMPBoard) {
				return onCMPBoardRefreshResult(store, action);
			}
			return Observable.of(action);
		})
		.catch((error) => onFailure(error, store));
}

// Look through the given refreshActions to see if one was a global issue create refresh since we
// need to run additional actions from it (e.g. if current default status is unmapped, trigger a flag)
export function checkIssuesIfGlobalIssueCreate(refreshActions: WorkRefreshDataAction[]): Action[] {
	const globalIssueCreateAction = refreshActions.find(
		(action) => action.payload.source === REFRESH_SOURCE_GLOBAL_ISSUE_CREATE,
	);
	// GIC already shows a flag for issue create success so check if the GIC was triggered from Bento to disable the board flag
	const isTriggeredByBento =
		globalIssueCreateAction?.payload?.triggerSource === JSW_ISSUE_VIEW_CREATE_SUBTASK ||
		globalIssueCreateAction?.payload?.triggerSource === JSW_ISSUE_VIEW_CREATE_ISSUE_IN_EPIC;
	const globalIssues = globalIssueCreateAction?.payload?.issues;
	return isNil(globalIssues) || isTriggeredByBento ? [] : [checkGlobalIssueCreate(globalIssues)];
}

function onBoardWithNoColumn(canEditBoard: boolean) {
	return Observable.of(loadBoardFailureNoColumn(false, canEditBoard));
}

export function setupRefreshDelay(action$: ActionsObservable): {
	refreshDelayHandler$: Observable<Action>;
	getDelay: () => number;
} {
	let failures = 0;
	const getDelay = () =>
		Math.min(
			failures > 0 ? 1000 * Math.pow(2, failures) + Math.random() * getBoardJirtJitter() : 0,
			60000,
		);
	const refreshAllowedStream$: Observable<Action> = action$
		.ofType(BOARD_LOAD_FAILURE)
		.flatMap(() => {
			failures += 1;
			return Observable.empty();
		});
	const successStream$: Observable<Action> = action$.ofType(WORK_DATA_SET).flatMap(() => {
		failures = 0;
		return Observable.empty();
	});

	const refreshDelayHandler$ = Observable.merge(refreshAllowedStream$, successStream$);
	return {
		refreshDelayHandler$,
		getDelay,
	};
}

/**
 * Handles data refresh.
 *
 * It buffers incoming actions under the following conditions:
 *
 * - There's an ongoing optimistic transaction;
 * - There's an ongoing drag operation;
 *
 * After the following conditions above are completed the buffered actions are emitted.
 *
 * Actions flushed from the buffer are processed one at the time,
 * and while an action is being processed others will be ignored,
 * therefore guaranteeing that only one request will be handled at the time.
 *
 * While an action is being processed, it may be aborted in case at least one of the
 * following conditions above starts
 */
export default function workLoadDataEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> {
	const { getDelay, refreshDelayHandler$ } = setupRefreshDelay(action$);

	const epicOutput$ = action$
		.ofType(WORK_REFRESH_DATA)
		.bufferWhen(() => shouldCloseBuffer(action$, store))
		.filter((buffer) => !isEmpty(buffer))
		.exhaustMap((refreshActions: WorkRefreshDataAction[]) =>
			Observable.of(refreshActions)
				.delay(getDelay())
				.flatMap(() => fetchBoardScopeData(store))
				.concat(checkIssuesIfGlobalIssueCreate(refreshActions))
				.takeUntil(shouldAbort(action$, store)),
		);

	return Observable.merge(epicOutput$, refreshDelayHandler$);
}

// Note: We can merge this once the feature flag is removed.
// Storybook uses this epic to make its initial API call
export function workLoadOnInitialLoadEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> {
	return action$
		.ofType(SOFTWARE_APP_LOADED, SOFTWARE_APP_INITIAL_STATE_LOADED)
		.filter(() => !hasAnyActiveOptimistic(store.getState()))
		.flatMap((action) => {
			const state = store.getState();
			const boardId = rapidViewIdSelector(state);
			const data =
				action.type === SOFTWARE_APP_LOADED && action.payload.prefetchData != null
					? action.payload.prefetchData
					: null;

			if (action.type === SOFTWARE_APP_LOADED && action.payload.cmpBoardData != null) {
				return Observable.from([]);
			}

			if (data && isEmpty(data.board.columns)) {
				log.safeErrorWithoutCustomerData(
					'board.transform.critical.data',
					'Board does not have any columns',
					{
						message: `Columns does not exist for Board with id :  ${boardId}`,
					},
				);

				// these data wont be in state , need to retrieve from payload.
				const canConfigureBoard = data.currentUser.permissions.includes(EDIT_BOARD_CONFIG);
				return onBoardWithNoColumn(canConfigureBoard);
			}
			const transformedData: Partial<WorkDataCritical> = data ? transformCriticalData(data) : {};

			// Get filters and put them in here
			const customFilterIds = getActiveCustomFilterIds(state);
			const isCustomFiltersOn = customFilterIds.length > 0;

			const ctx = makeServiceContext(state);

			return fetchWorkModeData(ctx, {
				activeQuickFilters: customFilterIds,
				includeHidden: customFilterIds.length > 0,
			})
				.map(updateIssueDevStatus(store))
				.map((nonCriticalData) => {
					// Do a comparison based on what was given by the cache and what we just retrieved
					// Not all keys will match but the ones we loaded from cache are the ones we want to compare
					const isCacheValid =
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						(Object.keys(transformedData) as (keyof WorkDataCritical)[]).every((key) => {
							if (key in nonCriticalData) {
								return deepEquals(transformedData[key], nonCriticalData[key]);
							}
							return true;
						});
					if (!isCacheValid) {
						// Fire analytics;
						triggerCacheRefreshAnalytics(BOARD);
					}
					return nonCriticalData;
				})
				.map(workDataSet)
				.flatMap((response) => {
					if (ctx.isCMPBoard) {
						return handleCMPBoardDataSet(response, isCustomFiltersOn);
					}

					return Observable.of(response);
				})
				.catch((error) => onFailure(error, store));
		});
}
