import React, { useCallback } from 'react';
import { IconButton } from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/core/add';
import { UNSCHEDULED_COLUMN_ID } from '@atlassian/jira-common-constants/src/column-types';
import { useIntl } from '@atlassian/jira-intl';
import { useBoardDispatch, useBoardSelector } from '../../../../../../state';
import { initiateIccInUnscheduledColumn } from '../../../../../../state/actions/card';
import { isUnscheduledColumnICCOpen } from '../../../../../../state/selectors/card/card-selectors';
import { isIccEnabledForColumnOrSwimlane } from '../../../../../../state/selectors/inline-create/inline-create-selectors';
import { isSwimlaneCollapsed } from '../../../../../../state/selectors/swimlane/swimlane-selectors';
import messages from './messages';
import type { Props } from './types';

const CreateUnscheduledIssueButton = ({ teamId }: Props) => {
	const { formatMessage } = useIntl();
	const dispatch = useBoardDispatch();
	const isCollapsed = useBoardSelector((state) => isSwimlaneCollapsed(state, teamId));
	const isOpen = useBoardSelector((state) =>
		isUnscheduledColumnICCOpen(state, teamId, UNSCHEDULED_COLUMN_ID),
	);
	const isIccEnabled = useBoardSelector((state) =>
		isIccEnabledForColumnOrSwimlane(state, teamId, UNSCHEDULED_COLUMN_ID),
	);

	const onClick = useCallback(() => {
		dispatch(initiateIccInUnscheduledColumn(teamId, isCollapsed));
	}, [dispatch, teamId, isCollapsed]);

	if (!isIccEnabled) {
		return null;
	}
	return (
		<IconButton
			spacing="compact"
			icon={AddIcon}
			label={formatMessage(messages.label)}
			isTooltipDisabled={false}
			onClick={onClick}
			isDisabled={!isCollapsed && isOpen}
		/>
	);
};

export default CreateUnscheduledIssueButton;
