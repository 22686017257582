import {
	zIndex,
	columnFixedWidth,
	columnMinWidth,
	columnWithMarginWidth,
	columnHeaderHeight,
	layout,
} from './common/constants/styles';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { layout };
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { BoardProvider } from './ui/board-provider';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as Board } from './ui/board';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as Column } from './ui/column/draggable-column';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as ColumnHeader } from './common/ui/column-header/main';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ColumnMenu } from './common/ui/column-header/main.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { ColumnTransitionZonesContainer } from './ui/column/column-transition-zones-container/main.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as ColumnHeaderTransitionOverlay } from './ui/column/column-header-transition-overlay';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ColumnHeaderTransitionProps } from './ui/column/column-header-transition-overlay';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { CardTransitionZoneInfo } from './ui/column/column-transition-zones-container/transition-zone/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as DoneCardsButton } from './ui/card/done-cards-button';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Props as InlineCardCreateProps } from './ui/inline-card-create';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { InlineCardCreateButton } from './ui/inline-card-create/button';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as Minimap } from './ui/minimap';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as MinimapColumn } from './ui/minimap/column';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as ColumnCreate } from './ui/column/column-create';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Props as ColumnCreateProps } from './ui/column/column-create';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as ColumnCreateButton } from './ui/column/column-create/button';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as ColumnConfigurationButton } from './ui/column/column-configuration';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { Card } from './ui/card/main.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { CardProps } from './ui/card/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as CardCover } from './ui/card/card-cover';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { CardCoverBackgroundImg } from './ui/card/card-cover/background-img/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as CardEpic } from './ui/swimlane/epic';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as CardPriority } from './ui/card/card-field/priority';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as CardDueDate } from './ui/card/due-date';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as Swimlane } from './ui/swimlane';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as SwimlaneWrapper } from './ui/swimlane/wrapper';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	default as SwimlaneColumnHeader,
	SwimlaneColumnHeaderContainer,
} from './ui/swimlane-column-header';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as BoardScrollNotifier } from './ui/board-scroll-notifier';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ValidateColumnCallback } from './common/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ColumnCreateCallback } from './ui/column/column-create/form';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { getDescriptor, getCardDescriptor, getColumnDescriptor } from './common/utils/descriptor';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { getFieldLengthForAnalytics } from './common/utils/field-length-for-analytics';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	LOZENGE_APPEARANCE,
	CARD_CONTAINER_SELECTOR,
	CARD_CONTAINER_SELECTOR_NAME,
	DRAGGABLE_COLUMN_SELECTOR,
	UPDATE_BOARD_SCROLL_POSITION,
} from './common/constants';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	minCardHeight,
	columnHeaderHeight,
	swimlaneHeaderHeight,
	unscheduledColumnWidth,
	scrollbarWidth,
	unscheduledColumnHeaderHeight,
} from './common/constants/styles';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { ColumnWrapper, columnThemes } from './common/ui/column';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	Column as ColumnType,
	ColumnId,
	SwimlaneId,
	CardId,
	Issue,
	IssueParent,
	Person,
	ColumnValidation,
	DraggableData,
	DroppableData,
	DraggableColumnData,
	DraggableCardData,
	BoardType,
} from './common/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { SwimlaneModeId } from './ui/swimlane/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { BoardView } from './common/constants';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	useBoardDragDropContext,
	type BoardDragDropContextProps,
} from './ui/board-drag-drop-context/use-board-drag-drop-context';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	CARD_DND_TYPE,
	COLUMN_DND_TYPE,
	TRANSITION_ZONE_DND_TYPE,
} from './common/constants/drag-drop';

export const sharedStyles = {
	columnWithMarginWidth,
	zIndex,
	columnFixedWidth,
	columnMinWidth,
	columnHeaderHeight,
} as const;

// Exports for @atlassian/jira-platform-board-kit-legacy-dnd - can be removed when all consumers are migrated
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { LeftBorder, RightBorder } from './ui/board/border';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	default as BoardScroll,
	type BoardScrollInfo,
	type BoardScrollInitData,
	type BoardScrollPosition,
} from './ui/board/scroll';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { TransitionZoneIndicatorContainerProvider } from './common/ui/transition-zone-indicator';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { getDraggingCardStatus } from './common/utils/board-drag-drop-context';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as Ripple } from './ui/card/ripple';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as JiraCardContents } from './ui/card/jira-card-contents';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { type OwnProps as DraggableColumnProps } from './ui/column/draggable-column/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DRAGGABLE_COLUMN_SELECTOR_NAME } from './common/constants';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { ColumnTheme, type DndType } from './common/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useBoardScrollApi } from './controllers/board-scroll';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	StyledWrapper as StyledColumnWrapper,
	StyledDraggableColumn,
} from './ui/column/draggable-column/main';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	StyledWrapper as SwimlaneColumnHeaderStyledWrapper,
	StyledDraggable as SwimlaneColumnHeaderStyledDraggable,
} from './ui/swimlane-column-header';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { type SwimlaneColumnHeaderProps } from './ui/swimlane-column-header/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { TransitionZoneIndicatorContainerContext } from './common/ui/transition-zone-indicator';
