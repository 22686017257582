/** @jsx jsx */
import React, {
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	type ReactNode,
	forwardRef,
	memo,
	useCallback,
	useMemo,
} from 'react';
import { css, jsx } from '@compiled/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import ButtonLegacy from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import ChevronDownIconLegacy from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIconLegacy from '@atlaskit/icon/glyph/chevron-up';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/utility/chevron-up';
import ShowMoreIcon from '@atlaskit/icon/utility/show-more-horizontal';
import { Box, xcss, Flex } from '@atlaskit/primitives';
import { PopupSelect, components, type OptionProps } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { TOOLTIP_DELAY } from '../../../common/constants';
import type { CardType, ExtraItem } from '../../../common/types';
import messages from './messages';
import TypeIcon from './type-icon';

const ANALYTICS_SUBJECT = 'iccTypeSelect';

// NOTE: Compiled/css rules needs to be defined at the top of the component (Otherwise Cypress tests failing)
// ----------------------------------------------------------------------------------------------------------

// kept here the negative margins from the original dropdown component but ideally these shouldn't exist
const containerStyles = css({
	marginLeft: token('space.negative.050', '-4px'),
	marginRight: token('space.negative.050', '-4px'),
});

const optionWrapperStyles = css({
	display: 'flex',
	alignItems: 'center',
});

const triggerContentStylesOld = css({
	display: 'flex',
	alignItems: 'center',
	marginLeft: token('space.050', '4px'),
});

const singValueContainerStyles = css({
	display: 'inline-flex',
	alignItems: 'center',
	padding: token('space.050', '4px'),
});

const extraLinksGroupStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `2px solid ${token('color.border', colors.N30)}`,
	marginTop: token('space.050', '4px'),
	paddingTop: token('space.050', '4px'),
});

const optionContentStyles = css({
	flex: 1,
});

const Option = ({ children, ...props }: OptionProps<SelectOption>) => {
	const { formatMessage } = useIntl();
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const { cardType, renderAdditionalContent } = props.data as TypeOption;
	return (
		<components.Option {...props}>
			<div
				css={optionWrapperStyles}
				data-testid="platform-inline-card-create.ui.form.type-select.option"
			>
				{!!cardType && (
					<TypeIcon styles={{ marginRight: token('space.100', '8px') }} cardType={cardType} />
				)}
				{!cardType && isVisualRefreshEnabled() && (
					<Box xcss={manageTypesIconContainer}>
						<ShowMoreIcon label={formatMessage(messages.manageTypes)} color={token('color.icon')} />
					</Box>
				)}
				<span css={optionContentStyles}>{children}</span>
				{!!cardType && !!renderAdditionalContent && renderAdditionalContent(cardType)}
			</div>
		</components.Option>
	);
};

type GroupProps = {
	children?: ReactNode;
};

const Group = ({ children }: GroupProps) => <div css={extraLinksGroupStyles}>{children}</div>;

const COMPONENTS_OLD = { Option, Group } as const;
const COMPONENTS = { Option } as const;

export type TypeOption = {
	label: string;
	value: number | string;
	cardType: CardType;
	optionType: 'card';
	renderAdditionalContent?: (cardType: CardType) => ReactNode;
};

type LinkOption = {
	label: string;
	value: string;
	url: string;
	onItemSelect?: () => void;
	openNewTab?: boolean;
	optionType: 'link';
};

type SelectOption =
	| TypeOption
	| LinkOption
	| {
			options: LinkOption[];
			optionType: 'links';
	  };

const makeOption = (
	cardType: CardType,
	renderAdditionalContent?: (cardType: CardType) => ReactNode,
): TypeOption => ({
	label: cardType.name,
	value: cardType.id,
	cardType,
	optionType: 'card',
	renderAdditionalContent,
});

export type SelectRef = {
	node?: PopupSelect<SelectOption>;
};

export type Props = {
	cardTypes: CardType[];
	selectedCardTypeId: number | string;
	extraItems?: ExtraItem[];
	shouldRenderToParent?: boolean;
	renderAdditionalOptionContent?: (cardType: CardType) => ReactNode;
	onSelectCardType: (selectedCardTypeId: number | string) => void;
};

const TypeSelect = forwardRef<SelectRef, Props>(
	(
		{
			cardTypes,
			selectedCardTypeId,
			extraItems,
			shouldRenderToParent = false,
			onSelectCardType,
			renderAdditionalOptionContent,
		},
		ref,
	) => {
		const { formatMessage } = useIntl();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const isDefaultItem = cardTypes[0]?.id === selectedCardTypeId;

		const value: TypeOption | null = useMemo(() => {
			const selectedType = cardTypes.find((type) => type.id === selectedCardTypeId);

			return selectedType ? makeOption(selectedType, renderAdditionalOptionContent) : null;
		}, [cardTypes, selectedCardTypeId, renderAdditionalOptionContent]);

		const options = useMemo(() => {
			const result: SelectOption[] = cardTypes
				.filter((type) => type.id !== selectedCardTypeId)
				.map((type) => makeOption(type, renderAdditionalOptionContent));

			// add extra links if there are any
			if (!isEmpty(extraItems)) {
				const linkOptions: LinkOption[] = extraItems.map((item) => ({
					label:
						item.name != null
							? item.name
							: formatMessage(
									isVisualRefreshEnabled() ? messages.manageTypes : messages.manageIssueTypes,
								),
					value: item.url,
					url: item.url,
					onItemSelect: item.onItemSelect,
					openNewTab: item.openNewTab ?? false,
					optionType: 'link',
				}));

				// if there are no other options, or if visual-refresh is enabled, add them flat
				if (isVisualRefreshEnabled() || !result.length) {
					result.push(...linkOptions);
				} else {
					// if there are other options already, group the extra links in a new section
					result.push({
						options: linkOptions,
						optionType: 'links',
					});
				}
			}

			return result;
		}, [cardTypes, extraItems, formatMessage, renderAdditionalOptionContent, selectedCardTypeId]);

		const isOptionDisabled = useCallback(
			(option: SelectOption) => option.optionType === 'card' && option.cardType.isDisabled === true,
			[],
		);

		const trigger = useCallback(
			({ isOpen, ...triggerProps }: { isOpen: boolean }) => {
				const triggerContent = (
					<Tooltip content={value?.label ?? null} delay={TOOLTIP_DELAY} hideTooltipOnClick>
						{isVisualRefreshEnabled() ? (
							<Button
								{...triggerProps}
								appearance="subtle"
								isSelected={isOpen}
								aria-label={formatMessage(messages.ariaLabel, {
									value: value?.label ?? formatMessage(messages.invalid),
								})}
								testId="platform-inline-card-create.ui.form.type-select.trigger"
								spacing="compact"
							>
								<Flex
									alignItems="center"
									justifyContent="center"
									gap="space.075"
									xcss={triggerContentStyles}
								>
									<TypeIcon cardType={get(value, 'cardType')} />
									{isOpen ? (
										<ChevronUpIcon label="" color={token('color.icon')} />
									) : (
										<ChevronDownIcon label="" color={token('color.icon')} />
									)}
								</Flex>
							</Button>
						) : (
							<div css={containerStyles}>
								<ButtonLegacy
									{...triggerProps}
									appearance="subtle"
									spacing="none"
									isSelected={isOpen}
									aria-label={formatMessage(messages.ariaLabel, {
										value: value?.label ?? formatMessage(messages.invalid),
									})}
									testId="platform-inline-card-create.ui.form.type-select.trigger"
								>
									<div css={triggerContentStylesOld}>
										<TypeIcon cardType={get(value, 'cardType')} />

										{isOpen ? <ChevronUpIconLegacy label="" /> : <ChevronDownIconLegacy label="" />}
									</div>
								</ButtonLegacy>
							</div>
						)}
					</Tooltip>
				);

				return <Box xcss={typeContainerStyles}>{triggerContent}</Box>;
			},
			[formatMessage, value],
		);

		const onChange = useCallback(
			(option: SelectOption | null) => {
				if (option && 'cardType' in option && option.cardType != null) {
					onSelectCardType(option.cardType.id);

					fireUIAnalytics(
						createAnalyticsEvent({
							action: 'selectedType',
							actionSubject: ANALYTICS_SUBJECT,
						}),
						{
							issueTypesCount: cardTypes.length,
						},
					);
				}
				if (option && 'url' in option && option.url != null) {
					if (option.openNewTab === true) {
						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						window.open(option.url, '_blank');
					} else {
						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						window.location.href = option.url;
					}

					fireUIAnalytics(
						createAnalyticsEvent({
							action: 'selectedExtraItem',
							actionSubject: ANALYTICS_SUBJECT,
						}),
						{
							issueTypesCount: cardTypes.length,
						},
					);
				}
				if (option && 'onItemSelect' in option && option.onItemSelect) {
					option.onItemSelect();
				}
			},
			[cardTypes.length, createAnalyticsEvent, onSelectCardType],
		);

		const onMenuOpen = useCallback(() => {
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'opened', actionSubject: ANALYTICS_SUBJECT }),
				{
					isDefaultItem,
				},
			);
		}, [createAnalyticsEvent, isDefaultItem]);

		const setRefNode = useCallback(
			(popupSelectRef: PopupSelect<SelectOption>) => {
				ref && popupSelectRef && set(ref, 'current.node', popupSelectRef);
			},
			[ref],
		);

		if (cardTypes.length === 1 && isEmpty(extraItems)) {
			return (
				<Tooltip content={value?.label ?? null} delay={TOOLTIP_DELAY}>
					<div css={[containerStyles, singValueContainerStyles]}>
						<TypeIcon cardType={get(value, 'cardType')} />
					</div>
				</Tooltip>
			);
		}

		// to render popup next to the trigger element to achieve DOM order
		const popupStrategy = shouldRenderToParent ? 'fixed' : 'absolute';

		return (
			<PopupSelect
				// @ts-expect-error — Type 'T | null' is not assignable to type 'PopupSelect<SelectOption, false, ModifierList>'
				ref={setRefNode}
				components={isVisualRefreshEnabled() ? COMPONENTS : COMPONENTS_OLD}
				target={trigger}
				options={options}
				isOptionDisabled={isOptionDisabled}
				value={value}
				onChange={onChange}
				onMenuOpen={onMenuOpen}
				isSearchable={false}
				// DSP-2990 - PopupSelect doesn't respect isSearchable, searchThreshold is required
				searchThreshold={Number.MAX_SAFE_INTEGER}
				minMenuWidth="auto"
				classNamePrefix="inline-card-create-type-select"
				testId="platform-inline-card-create.ui.form.type-select.type-select"
				popperProps={{
					strategy: popupStrategy,
				}}
			/>
		);
	},
);
const typeContainerStyles = xcss({
	display: 'flex',
	flexShrink: 0,
});

const triggerContentStyles = xcss({
	marginTop: 'space.050',
});

const manageTypesIconContainer = xcss({
	paddingInline: 'space.025',
	marginRight: 'space.100',
});

export default memo<
	JSX.LibraryManagedAttributes<typeof TypeSelect, ComponentProps<typeof TypeSelect>>
>(TypeSelect);
