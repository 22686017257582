import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';

import { Observable } from 'rxjs/Observable';
import { getDescriptor } from '@atlassian/jira-platform-board-kit/src/common/utils/descriptor/index.tsx';
import { isIncrementPlanningBoardM1Enabled } from '../../feature-flags';
import { type DragStartAction, DRAG_START } from '../../state/actions/board/drag';
import { cardCancelSelection } from '../../state/actions/card';
import { getCardSelection } from '../../state/selectors/card/card-selectors';
import type { Action, ActionsObservable, MiddlewareAPI, State } from '../../state/types';

export const handleCardDragStart = (
	payload: DragStartAction['payload'],
	state: State,
): Observable<Action> => {
	if (!payload) return Observable.empty<never>();

	let cardId;
	let selectedIds = [];

	if (!isIncrementPlanningBoardM1Enabled()) {
		cardId = Number(getDescriptor(payload.draggableId).id);
		selectedIds = getCardSelection(state);
	} else {
		cardId = `${getDescriptor(payload.draggableId).id}`;
		selectedIds = getCardSelection(state).map((id) => `${id}`);
	}

	if (selectedIds.length === 0 || selectedIds.includes(cardId)) {
		return Observable.empty<never>();
	}

	return Observable.of(cardCancelSelection());
};

export function cardDragStartEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> {
	return action$
		.ofType(DRAG_START)
		.mergeMap((action: DragStartAction) => handleCardDragStart(action.payload, store.getState()));
}
