import type { Action } from '../../../actions';
import {
	type SetIssueLinksStatsLoadingAction,
	type SetIssueLinksStatsAction,
	SET_ISSUE_LINKS_STATS,
	SET_ISSUE_LINKS_STATS_LOADING,
} from '../../../actions/issue-links-stats';
import type { IssueLinksStatsState } from './types';

const initialState: IssueLinksStatsState = { statisticItems: {}, loadingStatisticsItems: [] };

export const issueLinksStatsReducer = (
	state: IssueLinksStatsState = initialState,
	action: Action,
): IssueLinksStatsState => {
	if (action.type === SET_ISSUE_LINKS_STATS) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { payload } = action as SetIssueLinksStatsAction;
		return {
			statisticItems: { ...state.statisticItems, ...payload.statisticItems },
			loadingStatisticsItems: [],
		};
	}

	if (action.type === SET_ISSUE_LINKS_STATS_LOADING) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { payload } = action as SetIssueLinksStatsLoadingAction;
		return { ...state, loadingStatisticsItems: payload };
	}
	return state;
};
