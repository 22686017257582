import type { PlanningUnit } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/types';
import flagMessages from '../../../view/flags/messages';
import { errorFlagMeta, type FlagMeta } from '../meta/flag-meta';

type IterationCapacityRequestPayload = {
	capacity: string;
	planningUnit: PlanningUnit;
	workingHoursPerDay: number;
	iterationId: number;
	teamId: string;
	totalCapacity: number | null | undefined;
	defaultCapacity: number;
};

export type IterationCapacityCompletePayload = {
	capacity: number | null;
	iterationId: number;
	teamId: string;
};

export const UPDATE_ITERATION_CAPACITY_REQUEST =
	'state.actions.team.UPDATE_ITERATION_CAPACITY_REQUEST' as const;

export type UpdateIterationCapacityRequestAction = {
	type: typeof UPDATE_ITERATION_CAPACITY_REQUEST;
	payload: IterationCapacityRequestPayload;
};

export const updateIterationCapacityRequest = (
	payload: IterationCapacityRequestPayload,
): UpdateIterationCapacityRequestAction => ({
	type: UPDATE_ITERATION_CAPACITY_REQUEST,
	payload,
});

export const UPDATE_ITERATION_CAPACITY_SUCCESS =
	'state.actions.team.UPDATE_ITERATION_CAPACITY_SUCCESS' as const;

export type UpdateIterationCapacitySuccessAction = {
	type: typeof UPDATE_ITERATION_CAPACITY_SUCCESS;
	payload: IterationCapacityCompletePayload;
};

export const updateIterationCapacitySuccess = (
	payload: IterationCapacityCompletePayload,
): UpdateIterationCapacitySuccessAction => ({
	type: UPDATE_ITERATION_CAPACITY_SUCCESS,
	payload,
});

export const UPDATE_ITERATION_CAPACITY_FAILED =
	'state.actions.team.UPDATE_ITERATION_CAPACITY_FAILED' as const;

export type UpdateIterationCapacityFailedAction = {
	type: typeof UPDATE_ITERATION_CAPACITY_FAILED;
	meta: FlagMeta;
};

export const updateIterationCapacityFailed = (): UpdateIterationCapacityFailedAction => ({
	type: UPDATE_ITERATION_CAPACITY_FAILED,
	meta: errorFlagMeta({
		titleMessage: flagMessages.genericTitle,
		descriptionMessage: flagMessages.genericDescription,
	}),
});

export type Action =
	| UpdateIterationCapacityRequestAction
	| UpdateIterationCapacitySuccessAction
	| UpdateIterationCapacityFailedAction;
