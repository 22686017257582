import React, {
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	type ReactNode,
	type ComponentType,
} from 'react';
import flow from 'lodash/flow';
import memoizeOne from 'memoize-one';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useViewMode } from '@atlassian/jira-issue-context-service/src/main.tsx';
import Board from '@atlassian/jira-platform-board-kit/src/ui/board/index.tsx';
import { AnalyticsEventToProps, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux';
import { Capability } from '../../../common/capability';
import { boardView } from '../../../state/actions/work';
import { getCapability } from '../../../state/selectors/capability/capability-selectors';
import { getIssueKeyForModal } from '../../../state/selectors/issue/issue-modal-selectors';
import { getInsightsPanel } from '../../../state/selectors/panels';
import type { Dispatch, State } from '../../../state/types';

type BoardProps = ComponentProps<typeof Board>;
type BoardViewCallback = BoardProps['onBoardView'];
type ScrollCallback = BoardProps['onScroll'];

// Need to modify Props since withAnalyticsEvents introduces an analyticsEvent argument as the last
// parameter to onBoardView and onCardsMove callbacks
type BoardPropsWithoutOnBoardView = Flow.Diff<
	BoardProps,
	{
		onBoardView: BoardViewCallback;
	}
>;

// Need to modify Props since withAnalyticsEvents introduces an analyticsEvent argument as the last
// parameter to onScroll callbacks
type BoardPropsWithoutOnBoardViewAndOnScroll = Flow.Diff<
	BoardPropsWithoutOnBoardView,
	{
		onScroll: ScrollCallback;
	}
>;
type Props = BoardPropsWithoutOnBoardViewAndOnScroll & {
	onBoardView: (
		boardInfo: {
			hasHorizontalScrollbar?: boolean;
		},
		analyticsEvent: UIAnalyticsEvent, // eslint-disable-next-line @typescript-eslint/no-explicit-any
	) => any;
	onScroll: (
		boardScrollInfo: {
			reachedLeftEdge: boolean;
			reachedRightEdge: boolean;
		},
		analyticsEvent: UIAnalyticsEvent, // eslint-disable-next-line @typescript-eslint/no-explicit-any
	) => any;
	getIssueKeyForModal: string | null | undefined;
	isInsightsPanelOpen: boolean;
	externalId?: string;
};

type OwnProps = {
	children: ReactNode;
};

export const mapStateToProps = (state: State) => ({
	getIssueKeyForModal: getIssueKeyForModal(state),
	isInsightsPanelOpen: getInsightsPanel(state),
	flexibleColumns: getCapability(state)(Capability.FLEXIBLE_BOARD_COLUMNS),
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
	dispatch,
});

const onBoardViewFactory = memoizeOne(
	(dispatch: Dispatch) =>
		(
			boardInfo: {
				hasHorizontalScrollbar?: boolean;
			},
			analyticsEvent: UIAnalyticsEvent,
		) =>
			dispatch(boardView({ ...boardInfo }, analyticsEvent)),
);
const onScrollFactory = memoizeOne(
	() =>
		(
			boardScrollInfo: {
				reachedLeftEdge: boolean;
				reachedRightEdge: boolean;
			},
			analyticsEvent: UIAnalyticsEvent,
		) => {
			const { reachedLeftEdge, reachedRightEdge } = boardScrollInfo;
			if (reachedLeftEdge || reachedRightEdge) {
				const payload = {
					reachedLeftEdge,
					reachedRightEdge,
				};

				fireUIAnalytics(analyticsEvent, payload);
			}
		},
);

export const mergeProps = (
	stateProps: ReturnType<typeof mapStateToProps>,
	dispatchProps: ReturnType<typeof mapDispatchToProps>,
	ownProps: OwnProps,
): Props => ({
	...ownProps,
	getIssueKeyForModal: stateProps.getIssueKeyForModal,
	isInsightsPanelOpen: stateProps.isInsightsPanelOpen,
	flexibleColumns: stateProps.flexibleColumns,
	onBoardView: onBoardViewFactory(dispatchProps.dispatch),
	externalId: 'software.board',
	onScroll: onScrollFactory(),
});

const withOffsetRightPaddingByWidth =
	(WrappedComponent: ComponentType<{ offsetRightPaddingByWidth?: string | boolean }>) =>
	(props: Props) => {
		const viewMode = useViewMode();
		const {
			getIssueKeyForModal: getIssueKeyForModalProp,
			isInsightsPanelOpen: isInsightsPanelOpenProp,
			...rest
		} = props;
		const offsetRightPaddingByWidth =
			(viewMode === 'SIDEBAR' && getIssueKeyForModalProp) || isInsightsPanelOpenProp;

		return <WrappedComponent {...rest} offsetRightPaddingByWidth={offsetRightPaddingByWidth} />;
	};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export default flow(
	AnalyticsEventToProps('board', {
		onBoardView: 'viewed',
		onScroll: 'scrolled',
	}),
	withOffsetRightPaddingByWidth,
	connector,
)(Board);
