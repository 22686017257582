import type {
	BoardToolWebItem,
	SprintAction,
	SprintActionSectionResponse,
	SprintSection,
	SprintActionsItems,
	SprintActionsLocation,
} from '../../types';

// transform SprintAction to BoardToolWebItem
export const transformSprintItem = (sprintAction: SprintAction): BoardToolWebItem => ({
	id: sprintAction.id,
	type: 'webItem',
	label: sprintAction.name,
	url: sprintAction.url,
	cssClass: sprintAction.styleClass,
});

export const isRelevantAction = (sprintAction: SprintAction): boolean => Boolean(sprintAction.url);
export const isRelevantSection = (section: SprintSection): boolean =>
	section.groupId === 'sprint-move-actions' ||
	section.groupId === 'sprint-edit-action' ||
	section.groupId === 'sprint-delete-action';

export const transformSprintActionsResponse = (
	sprintActionsResponse: SprintActionSectionResponse,
): SprintActionsItems => {
	const result: SprintActionsItems = {};

	sprintActionsResponse.sections.filter(isRelevantSection).forEach((section) => {
		const actions = section.actions.filter(isRelevantAction).map(transformSprintItem);
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		result[section.groupId as SprintActionsLocation] = actions;
	});

	return result;
};
