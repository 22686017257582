import { connect } from '@atlassian/jira-react-redux';
import { Capability } from '../../../common/capability';
import { CAN_EDIT_BOARD } from '../../../model/permission/permission-types';
import { getPermissionsSelector } from '../../../state/selectors/board/board-permissions-selectors';
import { getCapability } from '../../../state/selectors/capability/capability-selectors';
import { firstIssueTypeIdSelector } from '../../../state/selectors/issue-type/issue-type-selectors';
import {
	getIsCMPBoard,
	projectKeySelector,
	rapidViewIdSelector,
	getBoardType,
} from '../../../state/selectors/software/software-selectors';
import { activeSprintsSelector } from '../../../state/selectors/sprint/sprint-selectors';
import { isReleasesFeatureEnabled } from '../../../state/selectors/work/work-selectors';
import type { State } from '../../../state/types';
import Menu, { type StateProps } from './view';

export const mapStateToProps = (state: State): StateProps => {
	const permissions = getPermissionsSelector(state);
	const isCMPBoard = getIsCMPBoard(state);
	const boardType = getBoardType(state);

	return {
		isDisabled: !permissions[CAN_EDIT_BOARD] || isCMPBoard,
		projectKey: projectKeySelector(state),
		issueTypeId: firstIssueTypeIdSelector(state) || '',
		isEditSprintItemVisible: activeSprintsSelector(state)?.[0]?.canUpdateSprint ?? false,
		isCardMediaSwitchEnabled: false,
		isConfigureBoardEnabled: permissions[CAN_EDIT_BOARD] === true && !isCMPBoard,
		isManageCustomFiltersEnabled: permissions[CAN_EDIT_BOARD] === true && !isCMPBoard,
		boardId: rapidViewIdSelector(state),
		sprintId: activeSprintsSelector(state)?.[0]?.id,
		isReleaseVersionVisible:
			isCMPBoard &&
			boardType === 'KANBAN' &&
			isReleasesFeatureEnabled(state) &&
			getCapability(state)(Capability.RENDER_BOARD_RELEASE_MENU_BUTON),
	};
};

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
