import { CARD_FOCUS, CARD_BLUR } from '../../../../actions/card';
import type { Action } from '../../../../types';
import type { FocusedState } from './types';

const cardFocusReducer = (state: FocusedState = null, action: Action) => {
	if (action.type === CARD_FOCUS) {
		return action.payload.issueId;
	}

	if (action.type === CARD_BLUR) {
		return null;
	}

	return state;
};

export default cardFocusReducer;
