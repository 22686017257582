import React from 'react';
import { ColumnEdgeContextProvider } from '../column-edge-provider';
import { DraggableColumn } from './main';
import type { OwnProps } from './types';

const DraggableColumnMain = ({ ...props }: OwnProps) => (
	<ColumnEdgeContextProvider>
		<DraggableColumn {...props} />
	</ColumnEdgeContextProvider>
);

export default DraggableColumnMain;
