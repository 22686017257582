import flow from 'lodash/flow';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux';
import { dismissFlag } from '../../state/actions/flags';
import { openIssueModal } from '../../state/actions/issue/modal';
import type { State } from '../../state/types';
import Flags from './view';

export default flow(
	connect(
		(state: State) => ({
			flags: state.ui.flags,
			externalId: 'software.board',
		}),
		{
			dismissFlag,
			openIssueModal,
		},
	),
	AnalyticsEventToProps('Flags', {
		onPrimaryActionClick: 'primaryActionClicked',
	}),
)(Flags);
