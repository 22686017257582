import { type Observable as ObservableType, Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import { gqlTagSwag } from '@atlassian/jira-software-swag';
import { mutation$ } from '@atlassian/jira-software-swag/src/services/mutation/index.tsx';
import { ColumnStatusFragment } from '../../common/graphql/fragments/column-status';
import { columnCreateAnalyticsOptions } from '../../common/graphql/metrics';
import type { ColumnCreateGQLData } from '../../model/column/column-types';

export const softwareColumnCreateOperationName = 'SoftwareColumnCreate';

export const columnCreateMutation = gqlTagSwag`mutation SoftwareColumnCreate($boardId: ID!, $columnName: String!){
        createColumn(input: {boardId: $boardId, columnName: $columnName}) {
            newColumn {
                id
                name
                maxIssueCount
                status {
                    id
                    name
                }
                columnStatus {
                    ...ColumnStatusFragment
                }
                isInitial
                isDone
                transitionId
            }
            columns {
                id
                name
                maxIssueCount
                status {
                    id
                    name
                }
                columnStatus {
                    ...ColumnStatusFragment
                }
                isInitial
                isDone
                transitionId
            }
        }
    }
    
    ${ColumnStatusFragment}
`;

type Response = {
	createColumn: ColumnCreateGQLData;
};

export const parseResponse = (data: Response): ObservableType<ColumnCreateGQLData> =>
	Observable.of(data.createColumn);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	contextPath: string,
	boardId: string,
	columnName: string,
): ObservableType<ColumnCreateGQLData> =>
	mutation$<Response>(
		contextPath,
		softwareColumnCreateOperationName,
		columnCreateMutation,
		{
			boardId,
			columnName,
		},
		columnCreateAnalyticsOptions,
	).flatMap(parseResponse);
