import type { Action } from '../../../actions';
import {
	SET_INLINE_CREATE_SESSION_ID,
	CLEAR_INLINE_CREATE_SESSION_ID,
} from '../../../actions/inline-create';
import type { InlineCreateState } from './types';

const defaultState: InlineCreateState = {
	sessionId: undefined,
};

export const inlineCreateReducer = (
	state: InlineCreateState = defaultState,
	action: Action,
): InlineCreateState => {
	switch (action.type) {
		case SET_INLINE_CREATE_SESSION_ID:
			return { ...state, sessionId: action.payload.sessionId };
		case CLEAR_INLINE_CREATE_SESSION_ID:
			return { ...state, sessionId: undefined };
		default:
			return state;
	}
};
