import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';
import type { IssueId } from '@atlassian/jira-software-board-common';
import type { SwagError } from '@atlassian/jira-software-swag/src/common/utils/error/index.tsx';
import type { CardTransitionInfo } from '../../../../model/card-transition/card-transition-types';
import type { CardDndType } from '../../../../model/card/card-types';
import type { Issue } from '../../../../model/issue/issue-types';
import type { OptimisticId } from '../../../../model/software/software-types';
import type { SwimlaneId } from '../../../../model/swimlane/swimlane-types';
import flagMessages from '../../../../view/flags/messages';
import { errorFlagMeta, type FlagMeta } from '../../meta/flag-meta';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiMeta,
	type OptimisticUiMeta,
} from '../../meta/optimistic-ui-meta';

export const ISSUE_CREATE_REQUEST = 'state.actions.issue.create.ISSUE_CREATE_REQUEST' as const;

type IssueCreateRequestOptions = {
	temporaryIssueIds: IssueId[];
	issues: Issue[];
	insertBefore: IssueId | null | undefined;
	columnIndex: number;
	cardIndex: number;
	swimlaneId: SwimlaneId | null | undefined;
	type: CardDndType;
	analyticsEvent: AnalyticsEvent;
	isSubtaskMissingParent?: boolean; // used for ICC within grouped subtasks that the parents are not shown on the board
	transitionInfo: CardTransitionInfo | null;
	isSwimlaneByJql?: boolean;
};

export type IssueCreateRequestAction = {
	type: typeof ISSUE_CREATE_REQUEST;
	payload: {
		temporaryIssueIds: IssueId[];
		issues: Issue[];
		type: CardDndType;
	};
	meta: {
		insertBefore: IssueId | null | undefined;
		columnIndex: number;
		cardIndex: number;
		swimlaneId: SwimlaneId | null | undefined;
		analyticsEvent: AnalyticsEvent;
		isSubtaskMissingParent?: boolean;
		transitionInfo: CardTransitionInfo | null;
		isSwimlaneByJql?: boolean;
	} & OptimisticUiMeta;
};

export const issueCreateRequest = ({
	temporaryIssueIds,
	issues,
	insertBefore,
	columnIndex,
	cardIndex,
	swimlaneId,
	type,
	analyticsEvent,
	isSubtaskMissingParent,
	transitionInfo,
	isSwimlaneByJql,
}: IssueCreateRequestOptions): IssueCreateRequestAction => ({
	type: ISSUE_CREATE_REQUEST,
	payload: {
		temporaryIssueIds,
		issues,
		type,
	},
	meta: {
		insertBefore,
		columnIndex,
		cardIndex,
		swimlaneId,
		...beginOptimisticUiMeta(),
		analyticsEvent,
		isSubtaskMissingParent,
		transitionInfo,
		isSwimlaneByJql,
	},
});

export const ISSUE_CREATE_SUCCESS = 'state.actions.issue.create.ISSUE_CREATE_SUCCESS' as const;

export type IssueCreateSuccessAction = {
	type: typeof ISSUE_CREATE_SUCCESS;
	payload: {
		temporaryIssueIds: IssueId[];
		issues: Issue[];
	};
	meta: {
		columnIndex: number;
		cardIndex: number;
		analyticsEvent: AnalyticsEvent;
	} & OptimisticUiMeta;
};

export const issueCreateSuccess = (
	optimisticId: OptimisticId,
	temporaryIssueIds: IssueId[],
	issues: Issue[],
	columnIndex: number,
	cardIndex: number,
	analyticsEvent: AnalyticsEvent,
): IssueCreateSuccessAction => ({
	type: ISSUE_CREATE_SUCCESS,
	payload: {
		temporaryIssueIds,
		issues,
	},
	meta: {
		columnIndex,
		cardIndex,
		...commitOptimisticUiMeta(optimisticId),
		analyticsEvent,
	},
});

export const ISSUE_CREATE_FAILURE = 'state.actions.issue.create.ISSUE_CREATE_FAILURE' as const;

export type IssueCreateFailureAction = {
	type: typeof ISSUE_CREATE_FAILURE;
	meta: {
		analyticsEvent: AnalyticsEvent;
		error: SwagError | Error | undefined;
	} & OptimisticUiMeta;
};

export const issueCreateFailure = (
	optimisticId: OptimisticId,
	analyticsEvent: AnalyticsEvent,
	error?: SwagError | Error,
): IssueCreateFailureAction => ({
	type: ISSUE_CREATE_FAILURE,
	// Flag handled by a concurrent action
	meta: {
		...revertOptimisticUiMeta(optimisticId),
		analyticsEvent,
		error,
	},
});

export const IP_ISSUE_CREATE_VALIDATE_FAILURE: 'state.actions.issue.create.IP_ISSUE_CREATE_VALIDATE_FAILURE' =
	'state.actions.issue.create.IP_ISSUE_CREATE_VALIDATE_FAILURE' as const;

export type IPIssueCreateValidateFailureAction = {
	type: typeof IP_ISSUE_CREATE_VALIDATE_FAILURE;
	meta: {
		analyticsEvent: AnalyticsEvent;
		error: Error | undefined;
	};
};

export const issueCreateIPValidateFailure = (
	analyticsEvent: AnalyticsEvent,
	error?: Error,
): IPIssueCreateValidateFailureAction => ({
	type: IP_ISSUE_CREATE_VALIDATE_FAILURE,
	meta: {
		analyticsEvent,
		error,
	},
});

export const CARD_CREATE_UNMAPPED = 'state.actions.issue.create.CARD_CREATE_UNMAPPED' as const;

export type CardCreateUnmappedAction = {
	type: typeof CARD_CREATE_UNMAPPED;
	payload: {
		cardKeys: string[];
	};
};

export const cardCreateUnmapped = (cardKeys: string[]): CardCreateUnmappedAction => ({
	type: CARD_CREATE_UNMAPPED,
	payload: { cardKeys },
});

export const CARD_CREATE_GLOBAL = 'state.actions.issue.create.CARD_CREATE_GLOBAL' as const;

export type CardCreateGlobalAction = {
	type: typeof CARD_CREATE_GLOBAL;
	payload: {
		cardKeys: string[];
	};
};

export const cardCreateGlobal = (cardKeys: string[]): CardCreateGlobalAction => ({
	type: CARD_CREATE_GLOBAL,
	payload: { cardKeys },
});

export const CUSTOM_FIELD_OPTIONS_MISSED_IN_IP_BOARD =
	'state.actions.issue.create.CUSTOM_FIELD_OPTIONS_MISSED_IN_IP_BOARD';

type CustomFieldOptionsMissedInIPBoardActionPayload = {
	fieldValue: string;
	issueTypeName: string;
	projectName: string;
	fieldName: string;
};
export type CustomFieldOptionsMissedInIPBoardAction = {
	type: typeof CUSTOM_FIELD_OPTIONS_MISSED_IN_IP_BOARD;
	meta: FlagMeta;
};

export const customFieldMissedInIPBoard = (
	payload: CustomFieldOptionsMissedInIPBoardActionPayload,
): CustomFieldOptionsMissedInIPBoardAction => ({
	type: CUSTOM_FIELD_OPTIONS_MISSED_IN_IP_BOARD,
	meta: {
		...errorFlagMeta({
			titleMessage: flagMessages.customFieldOptionMissedTitle,
			descriptionMessage: flagMessages.customFieldOptionMissedDescription,
			context: {
				...payload,
			},
		}),
	},
});

export type Action =
	| IssueCreateRequestAction
	| IssueCreateSuccessAction
	| IssueCreateFailureAction
	| CardCreateUnmappedAction
	| CardCreateGlobalAction
	| IPIssueCreateValidateFailureAction
	| CustomFieldOptionsMissedInIPBoardAction;
