import { createSelector } from 'reselect';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';
import { fg } from '@atlassian/jira-feature-gating';
import type { Parent } from '@atlassian/jira-filters/src/common/types.tsx';
import { isEpicHierarchyLevel } from '@atlassian/jira-issue-type-hierarchies';
import { DEFAULT_PARENT_ISSUE_TYPE_NAME } from '../../../model/constants';
import type { IssueParent } from '../../../model/issue/issue-types';
import type { IssueParentsState } from '../../reducers/entities/issue-parents/types';
import type { State } from '../../reducers/types';
import { getIssueChildren } from '../issue-children';
import {
	getIssues,
	getEntities,
	getIssueParentOrderIds,
	getIssueProjects,
	getIsIncrementPlanningBoard,
	getIsCMPBoard,
} from '../software/software-selectors';
import { hasNoActiveSprintStateSelector } from '../sprint/sprint-selectors';

export const getIssueParents = (state: State): IssueParentsState => getEntities(state).issueParents;

export const hasIssueParentsSelector = createSelector(
	getIssueParents,
	(issueParents) => !isEmpty(issueParents),
);

export const issueParentTypeIdsSelector = createSelector(getIssueParents, (issueParents) =>
	uniq(Object.values(issueParents).map((issueParent) => Number(issueParent.issueType.id))),
);

export const issueParentsSelector = createSelector(
	[getIssueParents, getIssueParentOrderIds],
	(issueParents, orderedIssueParentsIds): IssueParent[] =>
		orderedIssueParentsIds.reduce((issueParentIds, issueParentId) => {
			const issueParent = issueParents[String(issueParentId)];
			issueParent && issueParentIds.push(issueParent);
			return issueParentIds;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		}, [] as IssueParent[]),
);

// used by the increment planning board to filter out the issue parents that are not available to the current project
export const availableIssueParentsForIssueProjectSelector = createSelector(
	[issueParentsSelector, getIssueProjects, getIsIncrementPlanningBoard],
	(issueParents, projects, isIncrementPlanningBoard) =>
		memoize((issueProjectId: number | null | undefined): IssueParent[] => {
			if (!isIncrementPlanningBoard) {
				return issueParents;
			}
			if (!issueProjectId) {
				return [];
			}
			const issueProject = projects[issueProjectId];
			if (!issueProject.isCMP) {
				// for TMP issues, filter the issue parents to be in the same project
				return issueParents.filter((parent) => parent.projectId === issueProject.id);
			}
			// for CMP issues, filter issue parents to be in any CMP project
			return issueParents.filter((parent) => {
				const parentProject = parent.projectId ? projects[parent.projectId] : null;
				return parentProject && parentProject.isCMP;
			});
		}),
);

export const issueParentsToListItemSelector = createSelector(issueParentsSelector, (issueParents) =>
	issueParents.map(({ id, summary }: IssueParent) => ({
		id: String(id),
		name: summary,
	})),
);

export const filteredIssueParentsToListItemSelector = createSelector(
	issueParentsSelector,
	getIssues,
	hasNoActiveSprintStateSelector,
	getIsIncrementPlanningBoard,
	getIsCMPBoard,
	(
		issueParents,
		boardIssues,
		hasNoActiveSprint,
		isIncrementPlanningBoard,
		isCMPBoard,
	): Parent[] => {
		if (hasNoActiveSprint) {
			return [];
		}

		const filteredIssueParents = !isCMPBoard
			? issueParents
			: // Filter out non-epics (hierarchy level >1) so they're not available options in the epic filter
				issueParents.filter(({ issueType: { hierarchyLevel } }) =>
					isEpicHierarchyLevel(hierarchyLevel),
				);

		const listItems = filteredIssueParents.map(({ id, summary, key }: IssueParent) => ({
			id: String(id),
			key,
			name: summary,
		}));

		// in the increment planning board, we show epic cards, hence we want to show all epics in the filter
		if (isIncrementPlanningBoard) {
			return listItems;
		}

		// Generate a set of all distinct parentIds for all visible issues on the board.
		const allVisibleParentIds = new Set(
			Object.values(boardIssues).map((issue) => `${issue.parentId}`),
		);

		// remove issueParents that dont have any active issues on the board
		return listItems.filter((issueParent) => allVisibleParentIds.has(issueParent.id));
	},
);

// filteredIssueParentsToListItemSelectorNew will also include issues with hierarchyLevel -1 as part of all visible issues on the board
export const filteredIssueParentsToListItemSelectorNew = createSelector(
	issueParentsSelector,
	getIssues,
	getIssueChildren,
	hasNoActiveSprintStateSelector,
	getIsIncrementPlanningBoard,
	getIsCMPBoard,
	(
		issueParents,
		boardIssues,
		childIssues,
		hasNoActiveSprint,
		isIncrementPlanningBoard,
		isCMPBoard,
	): Parent[] => {
		if (hasNoActiveSprint) {
			return [];
		}

		const filteredIssueParents = !isCMPBoard
			? issueParents
			: // Filter out non-epics (hierarchy level >1) so they're not available options in the epic filter
				issueParents.filter(({ issueType: { hierarchyLevel } }) =>
					isEpicHierarchyLevel(hierarchyLevel),
				);

		const listItems = filteredIssueParents.map(({ id, summary, key }: IssueParent) => ({
			id: String(id),
			key,
			name: summary,
		}));

		// in the increment planning board, we show epic cards, hence we want to show all epics in the filter
		if (isIncrementPlanningBoard) {
			return listItems;
		}

		const visibleParentIds = new Set(
			Object.values(boardIssues).map((issue) =>
				fg('jsw-board-epic-filter-display-card-epics')
					? `${issue.parentId || issue.id}`
					: `${issue.parentId}`,
			),
		);
		const visibleParentIdsFromSubtasks = new Set(
			Object.values(childIssues).map((issue) => `${issue.parentId}`),
		);

		// Generate a set of all distinct parentIds for all visible issues and subtasks on the board.
		const allVisibleParentIds = new Set([...visibleParentIds, ...visibleParentIdsFromSubtasks]);

		// remove issueParents that dont have any active issues on the board
		return listItems.filter((issueParent) => allVisibleParentIds.has(issueParent.id));
	},
);

export const issueParentIdsSelector = createSelector(getIssueParents, (issueParents) =>
	Object.keys(issueParents),
);

export const firstParentIssueTypeNameSelector = createSelector(
	issueParentsSelector,
	(issueParents) =>
		issueParents
			? get(first(issueParents), ['issueType', 'name'], DEFAULT_PARENT_ISSUE_TYPE_NAME)
			: DEFAULT_PARENT_ISSUE_TYPE_NAME,
);

export const makeIssueParentByIdSelector = createSelector(getIssueParents, (issueParents) =>
	memoize((issueParentId) => get(issueParents, String(issueParentId), null)),
);
