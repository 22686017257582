import React from 'react';
import noop from 'lodash/noop';
import { VISIBLE } from '@atlassian/jira-platform-inline-card-create/src/common/constants.tsx';
import { useIccContext } from '@atlassian/jira-platform-inline-card-create/src/services/context/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import UFOCustomData from '@atlassian/react-ufo/custom-data';
import { useBoardSelector } from '../../../../../../state';
import { getIsCMPBoard } from '../../../../../../state/selectors/software/software-selectors';
import DoneCardsButton from '../../../column/done-cards-button';
import InlineCardCreate from '../../../inline-card-create';
import type { Props } from './types';

const Footer = ({
	isLastSwimlane,
	isInitialColumn,
	canShowIccNudge,
	isColumnIccEnabled,
	showDoneCardsButton,
	columnId,
	swimlaneId,
	cardIndex,
	openInitialColumnIcc,
	limitJql,
}: Props) => {
	const { initialProjectId: projectId } = useIccContext();

	const isCompanyManaged = useBoardSelector(getIsCMPBoard);

	if (!isColumnIccEnabled && !showDoneCardsButton) {
		return null;
	}

	const icc = (
		<InlineCardCreate
			showOnHover={!isInitialColumn}
			canShowIccNudge={canShowIccNudge}
			triggerAppearance={VISIBLE}
			columnId={columnId}
			swimlaneId={swimlaneId}
			cardIndex={cardIndex}
			// TODO-BOARD-REFACTOR-TYPES: should stay open is not a property you can pass in. It's calculated internally in the `mergeStateToProps`
			shouldStayOpen={openInitialColumnIcc}
			isLastSwimlane={isLastSwimlane}
			onChangeFormVisible={noop}
			// TODO-BOARD-REFACTOR-TYPES
			// @ts-expect-error projectID expects number but it is number | null
			projectId={projectId}
			isFooter
		/>
	);

	return (
		<>
			{isColumnIccEnabled &&
				(isInitialColumn ? (
					<UFOSegment name="icc-button-in-footer">
						<UFOCustomData data={{ isCompanyManaged }} />
						{icc}
					</UFOSegment>
				) : (
					icc
				))}

			{showDoneCardsButton && <DoneCardsButton limitJql={limitJql} />}
		</>
	);
};

export default Footer;
