import React, { useEffect, useState } from 'react';
import type { BaseFlagProps } from '../common/ui/types';
import { LimitApproachingFlag } from './limit-approaching-flag';
import { LimitReachedFlag } from './limit-reached-flag';

const ISSUE_APPROACHING_LIMIT = 4500;
const ISSUE_REACHED_LIMIT = 5000;

export const IssueLimitFlags = ({ isProjectAdmin, ...flagProps }: BaseFlagProps) => {
	const [limitReachedFlagShown, setLimitReachedFlagShown] = useState(false);

	const limitReachedViews = flagProps.views.filter(
		(view) => view.issueCount >= ISSUE_REACHED_LIMIT,
	);
	const limitApproachingViews = flagProps.views.filter(
		(view) => view.issueCount >= ISSUE_APPROACHING_LIMIT && view.issueCount < ISSUE_REACHED_LIMIT,
	);

	const shouldShowLimitReachedFlag = limitReachedViews.length > 0;
	const shouldShowLimitApproachingFlag = limitApproachingViews.length > 0 && !limitReachedFlagShown;

	useEffect(() => {
		if (shouldShowLimitReachedFlag && !limitReachedFlagShown) {
			setLimitReachedFlagShown(true);
		}
	}, [limitReachedFlagShown, shouldShowLimitReachedFlag]);

	// Check to ensure permission is defined. If value is not boolean, then it likely
	// means it has not yet been loaded
	if (typeof isProjectAdmin !== 'boolean') {
		return null;
	}

	return (
		<>
			{shouldShowLimitApproachingFlag && (
				<LimitApproachingFlag
					{...flagProps}
					isProjectAdmin={isProjectAdmin}
					views={limitApproachingViews}
				/>
			)}
			{shouldShowLimitReachedFlag && (
				<LimitReachedFlag
					{...flagProps}
					isProjectAdmin={isProjectAdmin}
					views={limitReachedViews}
				/>
			)}
		</>
	);
};
