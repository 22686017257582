import React from 'react';
import { styled } from '@compiled/react';
import isNil from 'lodash/isNil';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import errorImageUrl from '@atlassian/jira-common-assets/src/assets/error/something-wrong.svg';
import { ROUTE_NAMES_SERVICEDESK_BOARDS } from '@atlassian/jira-common-constants/src/spa-routes';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';
import { BoardSizeLimitErrorWithNoCTA } from './size-limit-error-no-cta';

export type Props = Intl & {
	projectKey: string;
	baseUrl: string;
	isCMPBoard: boolean;
	savedFilterId?: number;
};

const BoardSizeLimitError = ({
	intl,
	projectKey,
	baseUrl,
	isCMPBoard = false,
	savedFilterId,
}: Props) => {
	const description = isCMPBoard
		? intl.formatMessage(messages.descriptionCmp)
		: intl.formatMessage(messages.description);

	const url =
		isCMPBoard && !isNil(savedFilterId)
			? `${baseUrl}/issues/?filter=${savedFilterId}`
			: `${baseUrl}/issues/?jql=${encodeURIComponent(`project = ${projectKey} ORDER BY rank ASC`)}`;

	const buttonText = isCMPBoard
		? intl.formatMessage(messages.editBoardFilter)
		: intl.formatMessage(messages.goToSearch);

	return (
		<Container>
			<EmptyState
				imageUrl={errorImageUrl}
				header={intl.formatMessage(messages.header)}
				description={description}
				primaryAction={
					<Button href={url} appearance="primary">
						{buttonText}
					</Button>
				}
			/>
		</Container>
	);
};

const BoardSizeLimitErrorEmptyState = (props: Props) => {
	const { name } = useCurrentRoute();

	const showErrorWithNoCTA = name === ROUTE_NAMES_SERVICEDESK_BOARDS;

	return (
		<Container>
			{showErrorWithNoCTA ? (
				<BoardSizeLimitErrorWithNoCTA intl={props.intl} />
			) : (
				<BoardSizeLimitError {...props} />
			)}
		</Container>
	);
};

const BoardSizeLimitErrorEmptyStateWithIntl = injectIntl(BoardSizeLimitErrorEmptyState);
export default BoardSizeLimitErrorEmptyStateWithIntl;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	width: '100%',
});
