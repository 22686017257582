import { freeze } from 'icepick';
import isNil from 'lodash/isNil';
import { getDescriptor } from '@atlassian/jira-platform-board-kit/src/common/utils/descriptor/index.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common';
import { DRAG_START, DRAG_END, type DragMode } from '../../../../actions/board/drag';
import {
	CARD_DRAG_OVER_TRANSITION,
	CARD_ADD_SHOW_RIPPLE,
	CARD_REMOVE_SHOW_RIPPLE,
	CARD_SET_ISSUE_TRANSITIONS,
	CARD_DRAG_SELECTED_TRANSITION,
} from '../../../../actions/card';
import type { Action } from '../../../../types';
import type { CardDragState } from './types';

export const noCardDragState: CardDragState = {
	cardId: null,
	overTransition: undefined,
	snapMode: false,
	rippleIdsToShow: [],
};

const makeCardUiState = (
	draggableId: string,
	mode: DragMode,
	rippleIdsToShow: IssueId[],
): CardDragState => {
	const cardId = getDescriptor(draggableId).id;

	if (Number.isNaN(cardId)) {
		return noCardDragState;
	}
	return {
		cardId: Number(cardId),
		snapMode: mode === 'SNAP',
		hoverIntended: false,
		rippleIdsToShow,
	};
};

export const cardDragReducer = (
	state: CardDragState = noCardDragState,
	action: Action,
): CardDragState => {
	if (action.type === DRAG_START) {
		if (action.payload) {
			return makeCardUiState(
				action.payload.draggableId,
				action.payload.mode,
				state.rippleIdsToShow,
			);
		}
		return {
			...noCardDragState,
			rippleIdsToShow: state.rippleIdsToShow,
		};
	}
	if (action.type === CARD_DRAG_OVER_TRANSITION && state.cardId) {
		const { toColumnId, transitionId, hoverIntent } = action.payload;
		const newTransitionState = transitionId
			? {
					transitionId,
					toColumnId,
					hoverIntent,
				}
			: null;
		const hoverState = {
			...(newTransitionState && {
				// HoverIntended is set to true if hover intent is true once during the drag event later to be used in analytics
				// hoverIntent && transitionId to exclude hover intents to TransitionTo area
				hoverIntended: state.hoverIntended || (hoverIntent && !!transitionId),
			}),
		};
		return freeze({
			...state,
			overTransition: newTransitionState,
			...hoverState,
		});
	}

	if (action.type === CARD_DRAG_SELECTED_TRANSITION) {
		const { transitionId, toColumnId, toSwimlaneId } = action.payload;
		return freeze({
			...state,
			selectedTransition: { transitionId, toColumnId, toSwimlaneId },
		});
	}

	if (action.type === CARD_SET_ISSUE_TRANSITIONS) {
		const { columnTransitions } = action.payload;
		const { overTransition } = state;

		if (
			isNil(overTransition) ||
			isNil(overTransition.transitionId) ||
			isNil(overTransition.toColumnId) ||
			isNil(columnTransitions[overTransition.toColumnId])
		) {
			return state;
		}

		const selectedTransition = columnTransitions[overTransition.toColumnId].find(
			(transition) => transition.id === overTransition.transitionId,
		);

		if (isNil(selectedTransition) || selectedTransition.isAvailable) {
			return state;
		}

		return freeze({
			...state,
			selectedTransition: undefined,
			overTransition: null,
		});
	}

	if (action.type === DRAG_END) {
		return freeze({
			...state,
			...noCardDragState,
		});
	}
	if (action.type === CARD_ADD_SHOW_RIPPLE) {
		const { rippleIdsToShow } = action.payload;

		return freeze({
			...state,
			rippleIdsToShow: [...state.rippleIdsToShow, ...rippleIdsToShow],
		});
	}
	if (action.type === CARD_REMOVE_SHOW_RIPPLE) {
		const { issueId } = action.payload;
		return freeze({
			...state,
			rippleIdsToShow: [...state.rippleIdsToShow.filter((id) => id !== issueId)],
		});
	}

	return state;
};
