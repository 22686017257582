import type { IssueId } from '@atlassian/jira-software-board-common';

export const ISSUE_DELETE_MODAL_OPEN =
	'state.actions.issue.delete-modal.ISSUE_DELETE_MODAL_OPEN' as const;

export type IssueDeleteModalOpenAction = {
	type: typeof ISSUE_DELETE_MODAL_OPEN;
	payload: {
		issueId: IssueId;
		columnIndex: number;
	};
};

// The column index is being used for analytics
export const issueDeleteModalOpen = (
	issueId: IssueId,
	columnIndex: number,
): IssueDeleteModalOpenAction => ({
	type: ISSUE_DELETE_MODAL_OPEN,
	payload: {
		issueId,
		columnIndex,
	},
});

export const ISSUE_DELETE_MODAL_CLOSE =
	'state.actions.issue.delete-modal.ISSUE_DELETE_MODAL_CLOSE' as const;

export type IssueDeleteModalCloseAction = {
	type: typeof ISSUE_DELETE_MODAL_CLOSE;
};

export const issueDeleteModalClose = (): IssueDeleteModalCloseAction => ({
	type: ISSUE_DELETE_MODAL_CLOSE,
});

export type Action = IssueDeleteModalOpenAction | IssueDeleteModalCloseAction;
