import {
	SHOULD_SHOW_KANBAN_ONBOARDING_MODAL,
	SHOULD_SHOW_WELCOME_ONBOARDING_MODAL,
} from '@atlassian/jira-onboarding-core/src/constants';
import { sessionStorage } from '@atlassian/jira-onboarding-core/src/utils/index.tsx';
import { createStore, createStateHook, createActionsHook } from '@atlassian/react-sweet-state';
import { actions } from './actions';
import type { State } from './types';

export type Actions = typeof actions;

const isModalVisible = (key: string) => sessionStorage.get(key) !== undefined;

// If either modal is visible, then don't open the ICC
const setInitialState = () =>
	!(
		isModalVisible(SHOULD_SHOW_KANBAN_ONBOARDING_MODAL) ||
		isModalVisible(SHOULD_SHOW_WELCOME_ONBOARDING_MODAL)
	);

export const initialState: State = {
	keepIccOpen: setInitialState(),
	hasCreatedIssueICC: true,
	triggerReason: 'emptyBoard',
};

const store = createStore<State, Actions>({
	name: 'jsw-onboarding-keep-icc-open',
	initialState,
	actions,
});

export const useKeepIccOpenState = createStateHook(store, {
	selector: (state) => state.keepIccOpen,
});
export const useKeepIccOpenActions = createActionsHook(store);

export const useTriggerEventState = createStateHook(store, {
	selector: (state) => ({
		hasCreatedIssueICC: state.hasCreatedIssueICC,
		triggerReason: state.triggerReason,
	}),
});
