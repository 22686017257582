import {
	type OptimisticUiMeta,
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiMeta,
} from '../../meta/optimistic-ui-meta';

export const SET_FIELD_UPDATE_OPTIMISTIC =
	'state.actions.issue.SET_FIELD_UPDATE_OPTIMISTIC' as const;

export type FieldUpdateOptimisticAction = {
	type: typeof SET_FIELD_UPDATE_OPTIMISTIC;
	meta: OptimisticUiMeta;
};

const fieldUpdateOptimisticMeta = beginOptimisticUiMeta();

export const fieldUpdateOptimistic = (): FieldUpdateOptimisticAction => ({
	type: SET_FIELD_UPDATE_OPTIMISTIC,
	meta: fieldUpdateOptimisticMeta,
});

export const SET_FIELD_UPDATE_SUCCESS = 'state.actions.issue.SET_FIELD_UPDATE_SUCCESS' as const;

export type FieldUpdateSuccessAction = {
	type: typeof SET_FIELD_UPDATE_SUCCESS;
	meta: OptimisticUiMeta;
};

export const fieldUpdateSuccess = (): FieldUpdateSuccessAction => ({
	type: SET_FIELD_UPDATE_SUCCESS,
	meta: commitOptimisticUiMeta(fieldUpdateOptimisticMeta.optimistic.id),
});

export const SET_FIELD_UPDATE_FAILURE = 'state.actions.issue.SET_FIELD_UPDATE_FAILURE' as const;

export type FieldUpdateFailureAction = {
	type: typeof SET_FIELD_UPDATE_FAILURE;
	meta: OptimisticUiMeta;
};

export const fieldUpdateFailure = (): FieldUpdateFailureAction => ({
	type: SET_FIELD_UPDATE_FAILURE,
	meta: revertOptimisticUiMeta(fieldUpdateOptimisticMeta.optimistic.id),
});

export type Action =
	| FieldUpdateOptimisticAction
	| FieldUpdateSuccessAction
	| FieldUpdateFailureAction;
