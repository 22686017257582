import type { Action } from '../../../../actions';
import { SET_IS_FAVOURITE } from '../../../../actions/board/board-favourite';
import type { BoardFavouriteState } from './types';

const initialState: BoardFavouriteState = { isFavourite: undefined };

export const boardFavouriteReducer = (
	state: BoardFavouriteState = initialState,
	action: Action,
): BoardFavouriteState => {
	switch (action.type) {
		case SET_IS_FAVOURITE:
			return { isFavourite: action.payload.isFavourite };
		default:
			return state;
	}
};
