import React from 'react';
import { lazy } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { ScrollInterceptorWrapperProps } from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyScrollInterceptorWrapper = lazy(() =>
	import(/* webpackChunkName: "async-scroll-interceptor" */ './index').then(
		({ ScrollInterceptorWrapper }) => ScrollInterceptorWrapper,
	),
);

export const AsyncLazyScrollInterceptorWrapper = (props: ScrollInterceptorWrapperProps) => (
	<JSErrorBoundary
		id="asyncScrollInterceptor"
		fallback="unmount"
		packageName="jira-scroll-interceptor"
		teamName="jlove-makkuro"
	>
		<Placeholder name="scroll-interceptor" fallback={<></>}>
			<LazyScrollInterceptorWrapper {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
