import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software.main.board-container.board.backlog-issue-move-dialog.title',
		defaultMessage: 'Move your issues?',
		description:
			'Title of the modal shown to users when they turn on backlog on an Agility board offering to move issues in first column from board to backlog modal',
	},
	description: {
		id: 'software.main.board-container.board.backlog-issue-move-dialog.description',
		defaultMessage: "We can move your first column's issues straight into your new backlog.",
		description:
			'Text of the modal shown to users when they turn on backlog on an Agility board offering to move issues from board to backlog modal',
	},
	confirm: {
		id: 'software.main.board-container.board.backlog-issue-move-dialog.confirm',
		defaultMessage: 'Yes, move',
		description:
			'Primary action, button text confirming user wants to move issues in first column of the board to backlog',
	},
	cancel: {
		id: 'software.main.board-container.board.backlog-issue-move-dialog.cancel',
		defaultMessage: 'No thanks',
		description:
			'Secondary action, button text cancelling the move of issues in first column of the board to the backlog',
	},
});
