import { ensureState } from 'redux-optimistic-ui';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import log from '@atlassian/jira-common-util-logging/src/log';
import {
	fireOperationalAnalytics,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { REFRESH_SOURCE_FAILURE, REFRESH_SOURCE_ADD_FLAG } from '../../model/constants';
import type { IssueFlagWithCommentResponse } from '../../rest/card/flag-with-comment/types';
import flagWithCommentService from '../../services/card/flag-with-comment';
import { makeServiceContext } from '../../services/service-context';
import {
	issueFlagWithCommentSuccess,
	issueFlagWithCommentFailure,
	type IssueFlagWithCommentRequestAction,
	ISSUE_FLAG_WITH_COMMENT_REQUEST,
} from '../../state/actions/issue/flag-with-comment';
import { workRefreshData } from '../../state/actions/work';
import { boardIssuesSelector } from '../../state/selectors/issue/board-issue-selectors';
import type { MiddlewareAPI, ActionsObservable } from '../../state/types';

export const issueFlagWithCommentEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(ISSUE_FLAG_WITH_COMMENT_REQUEST)
		.mergeMap((action: IssueFlagWithCommentRequestAction) => {
			const {
				payload: { issueIds, flag, comment },
				meta: {
					optimistic: { id: optimisticId },
					analyticsEvent,
				},
			} = action;

			const state = store.getState();
			const ctx = makeServiceContext(state);

			const { issueProjects } = ensureState(state.entities);
			const issues = boardIssuesSelector(state);

			const issuesToFlag = issueIds.map((id) => issues[id]);
			const internallyCommentingIssueKeys = issuesToFlag
				.filter((issue) => issueProjects[issue.projectId]?.projectTypeKey === SERVICE_DESK_PROJECT)
				.map((issue) => issue.key);
			const publiclyCommentingIssueKeys = issuesToFlag
				.filter((issue) => issueProjects[issue.projectId]?.projectTypeKey !== SERVICE_DESK_PROJECT)
				.map((issue) => issue.key);

			const requests = [];

			if (internallyCommentingIssueKeys.length) {
				requests.push(
					flagWithCommentService(ctx, {
						issueKeys: internallyCommentingIssueKeys,
						flag,
						comment,
						internal: true,
					}),
				);
			}
			if (publiclyCommentingIssueKeys.length) {
				requests.push(
					flagWithCommentService(ctx, {
						issueKeys: publiclyCommentingIssueKeys,
						flag,
						comment,
						internal: false,
					}),
				);
			}

			return forkJoin(requests)
				.flatMap((responses: IssueFlagWithCommentResponse[]) => {
					if (responses.every((r) => r === null)) {
						// if we get here, all of the issues were successfully updated
						fireTrackAnalytics(analyticsEvent, 'cards flagged', {
							numCards: issueIds.length,
							flagAction: flag ? 'added' : 'removed',
						});
						return Observable.from([
							issueFlagWithCommentSuccess(optimisticId, issueIds),
							workRefreshData(REFRESH_SOURCE_ADD_FLAG),
						]);
					}

					// if we get here, at least one of the issues failed to be updated,
					// so we treat it as a failure
					const errorMessages = responses.map((r) => r?.errorMessages || []).flat(1);
					const error = new Error(errorMessages.join(', '));
					log.safeErrorWithoutCustomerData(
						'board.issue-flag-with-comment.partial-failure',
						'Failed to flag and/or comment on some issues.',
						error,
					);
					throw error;
				})
				.catch(() => {
					// if we get here directly from the cardsFlagWithCommentService, none
					// of the issues were successfully updated
					fireOperationalAnalytics(analyticsEvent, 'ui taskFail', {
						task: 'flagCards',
						numCards: issueIds.length,
						flagAction: flag ? 'added' : 'removed',
					});
					return Observable.from([
						issueFlagWithCommentFailure(optimisticId, issueIds),
						workRefreshData(REFRESH_SOURCE_FAILURE),
					]);
				});
		});
