import React, { useCallback, useMemo, memo } from 'react';
import noop from 'lodash/noop';
import { useIntl } from '@atlassian/jira-intl';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';
import { type IssueTypeId, toIssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueType, CheckboxSelectEntry } from '../../../common/types';
import ListFilter from '../../../common/ui/list';
import messages from './messages';

export type Props = {
	issueTypes: IssueType[];
	selectedIssueTypes?: IssueTypeId[];
	onChange: (ids: IssueTypeId[], attributes?: AnalyticsAttributes) => void;
	isNested?: boolean;
};

const IssueTypeFilter = ({
	issueTypes = [],
	selectedIssueTypes = [],
	onChange = noop,
	isNested = false,
}: Props) => {
	const { formatMessage } = useIntl();

	const values = useMemo(
		(): CheckboxSelectEntry[] =>
			issueTypes.map((issueType) => ({
				label: issueType.name,
				value: issueType.id,
				iconUrl: issueType.iconUrl,
			})),
		[issueTypes],
	);

	const onChangeCallback = useCallback(
		(entries: CheckboxSelectEntry[]) => {
			const analyticAttributes = { isNested };
			onChange(
				entries.map((entry) => toIssueTypeId(entry.value)),
				analyticAttributes,
			);
		},
		[onChange, isNested],
	);

	// early exit if we have only one issue type
	if (issueTypes.length <= 1) {
		return null;
	}

	return (
		<ListFilter
			label={formatMessage(messages.label)}
			values={values}
			selectedValues={selectedIssueTypes.map(String)}
			onChange={onChangeCallback}
			isNested={isNested}
		/>
	);
};

const IssueTypeFilterComponent = memo<Props>(IssueTypeFilter);

IssueTypeFilterComponent.displayName = 'IssueTypeFilter';
// @ts-expect-error TS2339: Property 'whyDidYouRender' does not exist on type 'NamedExoticComponent<Props>'.
IssueTypeFilterComponent.whyDidYouRender = true;

export type IssueTypeFilterProps = Props;
export default IssueTypeFilterComponent;
