import {
	ADD_TO_SPRINT_SUCCESS,
	ADD_TO_SPRINT_REQUEST,
	ADD_TO_SPRINT_FAILURE,
} from '../../../../actions/sprints/add-to-sprint';
import type { Action } from '../../../../types';
import type { BoardAddNewIssueToSprintState } from './types';

const initialState = {
	isLoading: false,
	isSuccess: false,
};

export const boardAddNewIssueToSprintReducer = (
	state: BoardAddNewIssueToSprintState | undefined = initialState,
	action: Action,
): BoardAddNewIssueToSprintState => {
	switch (action.type) {
		case ADD_TO_SPRINT_REQUEST:
			return {
				isLoading: true,
				isSuccess: false,
			};

		case ADD_TO_SPRINT_SUCCESS:
			return {
				isLoading: false,
				isSuccess: true,
			};

		case ADD_TO_SPRINT_FAILURE:
			return {
				isLoading: false,
				isSuccess: false,
			};

		default:
			break;
	}

	return state;
};
