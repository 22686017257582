import { setIn, unset, chain } from 'icepick';
import { ff } from '@atlassian/jira-feature-flagging';
import { entityArrayToMap } from '../../../../services/software/software-data-transformer';
import { CARD_DELETE, type CardDeleteAction } from '../../../actions/card';
import {
	SET_ISSUE_PARENT_SUMMARY,
	SET_ISSUE_PARENT_STATUS,
	ADD_ISSUE_PARENT,
} from '../../../actions/issue/parent';
import { SET_SUMMARY } from '../../../actions/issue/summary';
import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../actions/work';
import type { Action } from '../../../types';
import type { IssueParentsState } from './types';

const reducer = (state: IssueParentsState = {}, action: Action): IssueParentsState => {
	switch (action.type) {
		case WORK_DATA_SET:
		case WORK_DATA_CRITICAL_SET:
			return entityArrayToMap(action.payload.issueParents);

		case SET_ISSUE_PARENT_SUMMARY: {
			const { issueId, issueSummary } = action.payload;
			if (state[String(issueId)]) {
				return setIn(state, [String(issueId), 'summary'], issueSummary);
			}
			return state;
		}
		case SET_SUMMARY: {
			const {
				payload: { issueId, summary },
			} = action;
			if (ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1')) {
				if (state[String(issueId)]) {
					return chain(state)
						.setIn([String(issueId), 'summary'], summary)
						.value();
				}
			}

			return state;
		}

		case SET_ISSUE_PARENT_STATUS: {
			const { issueId, status } = action.payload;
			if (state[String(issueId)]) {
				return setIn(state, [String(issueId), 'status'], status);
			}
			return state;
		}

		case CARD_DELETE: {
			const {
				payload: { issueId },
			}: CardDeleteAction = action;

			return state[String(issueId)] ? unset(state, String(issueId)) : state;
		}

		case ADD_ISSUE_PARENT: {
			const { issue } = action.payload;
			return setIn(state, [String(issue.id)], issue);
		}
		default: {
			return state;
		}
	}
};

export default reducer;
