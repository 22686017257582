import { Observable } from 'rxjs/Observable';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { openIssueTransitionModal } from '../../../../state/actions/issue/issue-transition-modal';
import type { Dispatch } from '../../../../state/types';

export const TRANSITION_SCREEN_SUCCESS = 'TRANSITION_SCREEN_SUCCESS';
export const TRANSITION_SCREEN_ERROR = 'TRANSITION_SCREEN_ERROR';
export const TRANSITION_SCREEN_CANCEL = 'TRANSITION_SCREEN_CANCEL';

export const SOFTWARE_BOARD_ISSUE_TRANSITION = 'software-board-issue-transition';

export type TransitionScreenLegacyDialogEvent =
	| { type: typeof TRANSITION_SCREEN_SUCCESS } // eslint-disable-next-line @typescript-eslint/no-explicit-any
	| { type: typeof TRANSITION_SCREEN_ERROR; error: any }
	| { type: typeof TRANSITION_SCREEN_CANCEL };

interface RunTransitionScreenLegacyDialogParams {
	issueId: string;
	issueKey: string;
	transitionId: number;
	dispatch: Dispatch;
	analyticsEvent?: UIAnalyticsEvent;
}

/**
 * Wraps `TransitionScreenLegacyDialog` with an rxjs sequence. Emits
 * `TransitionScreenLegacyDialogEvent` objects for different events.
 */
export function openTransitionScreen({
	issueId,
	issueKey,
	transitionId,
	analyticsEvent,
	dispatch,
}: RunTransitionScreenLegacyDialogParams): Observable<TransitionScreenLegacyDialogEvent> {
	return new Observable((subscriber) => {
		const handleDialogSuccess = () => {
			subscriber.next({ type: TRANSITION_SCREEN_SUCCESS });
			subscriber.complete();
			// @ts-expect-error - Argument of type 'UIAnalyticsEvent | undefined' is not assignable to parameter of type 'UIAnalyticsEvent'.
			fireTrackAnalytics(analyticsEvent, 'issueTransition success', {
				triggerPointKey: SOFTWARE_BOARD_ISSUE_TRANSITION,
				isModalOpen: true,
			});
		};
		const handleDialogCancel = () => {
			subscriber.next({ type: TRANSITION_SCREEN_CANCEL });
			subscriber.complete();
			// @ts-expect-error - Argument of type 'UIAnalyticsEvent | undefined' is not assignable to parameter of type 'UIAnalyticsEvent'.
			fireTrackAnalytics(analyticsEvent, 'issueTransition cancel', {
				triggerPointKey: SOFTWARE_BOARD_ISSUE_TRANSITION,
				isModalOpen: true,
			});
		};
		const handleDialogError = (error?: Error | unknown) => {
			subscriber.next({
				type: TRANSITION_SCREEN_ERROR,
				error: error ?? new Error('Failed to execute transition screen'),
			});
			subscriber.complete();
			// @ts-expect-error - Argument of type 'UIAnalyticsEvent | undefined' is not assignable to parameter of type 'UIAnalyticsEvent'.
			fireTrackAnalytics(analyticsEvent, 'issueTransition failed', {
				triggerPointKey: SOFTWARE_BOARD_ISSUE_TRANSITION,
				isModalOpen: true,
			});
		};

		dispatch(
			openIssueTransitionModal({
				issueId,
				issueKey,
				transitionId: String(transitionId),
				onDialogCancel: handleDialogCancel,
				onDialogError: handleDialogError,
				onDialogSuccess: handleDialogSuccess,
			}),
		);
	});
}
