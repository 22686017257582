import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/concatMap';
import {
	fireTrackAnalytics,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import columnSetLimitService from '../../services/column-graphql/set-limit';
import {
	columnSetLimitsSuccess,
	columnSetLimitsFailure,
	COLUMN_SET_LIMITS_REQUEST,
} from '../../state/actions/column/limits';
import { getColumnIndex } from '../../state/selectors/column/column-selectors';
import {
	contextPathSelector,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors';
import type { ActionsObservable, MiddlewareAPI } from '../../state/types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(COLUMN_SET_LIMITS_REQUEST).concatMap((action) => {
		const state = store.getState();
		const contextPath = contextPathSelector(state);
		const boardId = rapidViewIdSelector(state);

		const {
			payload: { columnId, maxIssueCount, minIssueCount },
			meta: { optimistic, analyticsEvent },
		} = action;
		const position = getColumnIndex(state, columnId);
		return columnSetLimitService(contextPath, boardId, columnId, maxIssueCount, minIssueCount)
			.flatMap(() => {
				if (analyticsEvent) {
					fireTrackAnalytics(analyticsEvent, 'column setLimit', {
						position: position + 1,
						maxIssueCount,
						minIssueCount,
					});
				}
				return Observable.of(columnSetLimitsSuccess(optimistic.id, columnId));
			})
			.catch((error) => {
				if (analyticsEvent) {
					fireOperationalAnalytics(analyticsEvent, 'column setLimitFailed', {
						error,
						position: position + 1,
						maxIssueCount,
					});
				}
				return Observable.of(columnSetLimitsFailure(optimistic.id, columnId));
			});
	});
