import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { memoryBucketHandler, sessionTimeBucketHandler } from '../get-analytics-bucket';
import { getMemoryUsageReport } from '../measure-memory';

const minMs = 60 * 1000; // number of ms in a minute

// Poll memory every 20m
export const POLL_INTERVAL = 20 * minMs;

// Also gather measurements at certain intervals in order to help track memory leaks
export const BUCKET_INTERVALS = [2 * minMs, 10 * minMs];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GetCustomData = () => Record<string, any>;

/**
 * Collect memory metrics and submit then as analytics.
 */
export const measureAndReportMemory = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	getCustomData?: GetCustomData,
) => {
	try {
		const memoryUsageReport = getMemoryUsageReport();
		if (memoryUsageReport == null) {
			return;
		}

		const {
			sessionTime,
			performanceMemoryEntry: { jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize },
		} = memoryUsageReport;

		const customData = getCustomData ? getCustomData() : {};

		fireOperationalAnalytics(createAnalyticsEvent({}), 'memory tracked', {
			bucket: memoryBucketHandler.getBucket(usedJSHeapSize),
			sessionTime,
			sessionTimeBucket: sessionTimeBucketHandler.getBucket(sessionTime),
			jsHeapSizeLimit,
			totalJSHeapSize,
			usedJSHeapSize,
			...customData,
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		log.safeErrorWithoutCustomerData(
			'observability.memory-metrics.measure-and-report-memory',
			'Failed to poll for memory usage',
		);
	}
};

/**
 * Polls for memory metrics and submits them as operational events.
 */
export class PollingMemoryReporter {
	pollInterval: number | null = null;

	bucketTimeouts: ReturnType<typeof setTimeout>[] | null = null;

	createAnalyticsEvent: CreateUIAnalyticsEvent;

	getCustomData: GetCustomData | undefined;

	constructor(createAnalyticsEvent: CreateUIAnalyticsEvent, getCustomData?: GetCustomData) {
		this.createAnalyticsEvent = createAnalyticsEvent;
		this.getCustomData = getCustomData;
	}

	report = () => {
		measureAndReportMemory(this.createAnalyticsEvent, this.getCustomData);
	};

	start() {
		if (this.pollInterval != null) {
			return;
		}

		this.report();

		// @ts-expect-error - TS2322 - Type 'Timer' is not assignable to type 'number'.
		this.pollInterval = setInterval(() => {
			if (this.pollInterval == null) {
				return;
			}
			this.report();
		}, POLL_INTERVAL);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('visibilitychange', this.onVisibilityChange);
	}

	stop() {
		if (this.pollInterval != null) {
			clearInterval(this.pollInterval);
			this.pollInterval = null;
		}

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.removeEventListener('visibilitychange', this.onVisibilityChange);
	}

	onVisibilityChange = () => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const isHidden = window.document?.hidden === true;

		if (isHidden) {
			this.stop();
		} else {
			this.stop();
			this.start();
		}
	};

	setBucketTimeouts() {
		this.bucketTimeouts = BUCKET_INTERVALS.map((ms) =>
			setTimeout(() => {
				this.report();
			}, ms),
		);
	}

	clearBucketTimeouts() {
		this.bucketTimeouts?.forEach((timeout) => {
			clearTimeout(timeout);
		});
	}
}
