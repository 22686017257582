import { useState, useEffect, useMemo } from 'react';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { COHORT, NOT_ENROLLED } from '@atlassian/jira-common-util-get-experiment-group';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { FREE_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { getFlagId, setHasSeenFlag } from '../../common/utils/local-storage';
import { useCheckIfUserCanUpgrade } from '../eligibility';
import type { UseRecommendTrialToFreeResponse, UseRecommendTrialToFreeParameters } from './types';

export const useRecommendTrialToFree = ({
	id,
}: UseRecommendTrialToFreeParameters): UseRecommendTrialToFreeResponse => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { data: projectContext } = useProjectContext();
	const { software: jswEdition } = useAppEditions();
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [expConfig, fireExposure] = UNSAFE_noExposureExp('jira_recommended_trial_to_free_ab');
	const cohort = expConfig.get(COHORT, NOT_ENROLLED);
	const isPassedGate = fg('jira_recommended_trial_to_free_ab_gate');
	const flagId = getFlagId();
	const [shouldShowRecommendTrialFlag, setShouldShowRecommendTrialFlag] = useState(false);
	const isInJiraProject = projectContext?.projectType === SOFTWARE_PROJECT;
	const isTestCohort = cohort === 'test';
	const isEligible = useMemo(
		() =>
			isPassedGate &&
			cohort !== NOT_ENROLLED &&
			jswEdition === FREE_EDITION &&
			isInJiraProject &&
			(!flagId || flagId === `${id}`),
		[isPassedGate, cohort, jswEdition, isInJiraProject, flagId, id],
	);

	const { userCanUpgrade, isLoading } = useCheckIfUserCanUpgrade(!isEligible);

	useEffect(() => {
		if (!isEligible || !userCanUpgrade) {
			return;
		}

		// only fire exposure if the user is eligible
		fireExposure();
		fireOperationalAnalytics(createAnalyticsEvent({}), 'rtfExperiment exposed', {
			experiment: 'jira_recommended_trial_to_free_ab',
			cohort,
		});

		// for the test cohort, we will set the flag as seen once it has rendered
		if (!isTestCohort) {
			setHasSeenFlag(`${id}`);
			return;
		}

		// only set the flag if the user is eligible and in the test cohort
		setShouldShowRecommendTrialFlag(true);
	}, [isEligible, userCanUpgrade, cohort, isTestCohort, id, fireExposure, createAnalyticsEvent]);

	return {
		shouldShowRecommendTrialFlag,
		isLoading,
	};
};
