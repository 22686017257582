import React, { useEffect, useState } from 'react';
import {
	FireScreenAnalytics,
	ContextualAnalyticsData,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import type {
	MigrationId,
	MigrationStatus,
} from '@atlassian/jira-project-create-importer-common/src/common/types/index.tsx';
import { useMigrationIdManager } from '@atlassian/jira-project-create-importer-common/src/services/use-migration-id-manager/index.tsx';
import { EXPERIENCES, X2C_SCREENS } from '../common/constants/metrics';
import { useFlags } from '../common/utils/use-flags';
import { useTrackMigrationStatus } from '../common/utils/use-track-migration-status';

export type Props = {
	projectKey: string;
	projectName: string;
};

type ImportProgressFlagProps = {
	migrationId: MigrationId;
	onSuccess: (migrationStatus: MigrationStatus) => void;
	onFail: (migrationStatus: MigrationStatus) => void;
};

const ImportProgressFlag = ({ migrationId, onSuccess, onFail }: ImportProgressFlagProps) => {
	useTrackMigrationStatus(EXPERIENCES.IMPORT_PART_2, migrationId, onSuccess, onFail);

	return <></>;
};

const ImportProgress = ({ projectKey, projectName }: Props) => {
	const { fetchMigrationId, removeMigrationId } = useMigrationIdManager();
	const [migrationId, setMigrationId] = useState<MigrationId | undefined>(undefined);
	const { onSuccess, onFail } = useFlags({
		projectKey,
		projectName,
		migrationId,
		removeMigrationId,
	});

	useEffect(() => {
		setMigrationId(fetchMigrationId(projectKey));
	}, [fetchMigrationId, projectKey]);

	if (!migrationId) {
		return null;
	}

	return (
		<ContextualAnalyticsData sourceName={X2C_SCREENS.ImportProgressBar} sourceType={SCREEN}>
			<ImportProgressFlag onSuccess={onSuccess} onFail={onFail} migrationId={migrationId} />
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};

export default ImportProgress;
