import React, { useEffect } from 'react';
import { LinkButton } from '@atlaskit/button/new';
import EmptyState from '@atlaskit/empty-state';
import { Flex } from '@atlaskit/primitives';
import { ff } from '@atlassian/jira-feature-flagging';
import MapIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/objects/components/map';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { advancedRoadmapsLastVisitedProgramBoardIdResource } from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import commonMessages from '../../../common/messages';
import { getPlanUrl, getPlanId, urls } from '../../../common/utils';
import { useIncrementsLink } from '../../../hooks/use-increments-link';
import messages from './messages';

const ProgramBoard404Old = () => {
	const { formatMessage } = useIntl();
	const planId = getPlanId();

	const renderPrimaryAction = () => {
		if (planId) {
			const backToPlanUrl = getPlanUrl(planId);
			return (
				<LinkButton href={backToPlanUrl} appearance="primary">
					{formatMessage(commonMessages.backToPlan)}
				</LinkButton>
			);
		}

		return (
			<LinkButton href={urls.managePortfolio} appearance="primary">
				{formatMessage(messages.viewAllPlans)}
			</LinkButton>
		);
	};

	return (
		<Flex alignItems="center" justifyContent="center">
			<EmptyState
				renderImage={getAkEmptyStateRenderImageFn(MapIllustration)}
				header={formatMessage(messages.title404)}
				description={formatMessage(messages.description404)}
				primaryAction={renderPrimaryAction()}
			/>
		</Flex>
	);
};

const ProgramBoard404New = () => {
	const { formatMessage } = useIntl();
	const planId = getPlanId();
	// If the user lands on the 404 page we should invalidate the cache of the last visited
	// program board and refetch a valid one from the server so they are routed to the appropriate
	// program board when next accessing /program route
	const { refresh: refetchLastViewedProgramBoardId } = useResource(
		advancedRoadmapsLastVisitedProgramBoardIdResource,
	);

	const incrementsLink = useIncrementsLink();

	useEffect(() => {
		refetchLastViewedProgramBoardId();
	}, [refetchLastViewedProgramBoardId]);

	const renderPrimaryAction = () => {
		if (planId) {
			return (
				<LinkButton href={incrementsLink} appearance="primary">
					{formatMessage(messages.viewCurrentBoard)}
				</LinkButton>
			);
		}

		return (
			<LinkButton href={urls.managePortfolio} appearance="primary">
				{formatMessage(messages.viewAllPlans)}
			</LinkButton>
		);
	};

	return (
		<Flex alignItems="center" justifyContent="center">
			<EmptyState
				renderImage={getAkEmptyStateRenderImageFn(MapIllustration)}
				header={formatMessage(messages.title404)}
				description={formatMessage(messages.description404)}
				primaryAction={renderPrimaryAction()}
			/>
		</Flex>
	);
};

const ProgramBoard404 = () =>
	ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1') ? (
		<ProgramBoard404New />
	) : (
		<ProgramBoard404Old />
	);

export default ProgramBoard404;
