import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import { getIssueMediaVisibilityChangeUrl } from '../../rest/software/software-api-urls';
import {
	issueMediaVisibilityChangeSuccess,
	issueMediaVisibilityChangeFailure,
	type IssueMediaVisibilityChangeRequestAction,
	ISSUE_MEDIA_VISIBILITY_CHANGE_REQUEST,
} from '../../state/actions/issue/media';
import { contextPathSelector } from '../../state/selectors/software/software-selectors';
import type { MiddlewareAPI, ActionsObservable } from '../../state/types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(ISSUE_MEDIA_VISIBILITY_CHANGE_REQUEST)
		.switchMap((action: IssueMediaVisibilityChangeRequestAction) => {
			const state = store.getState();
			const contextPath = contextPathSelector(state);
			const {
				payload: { issueId, isVisible },
				meta: {
					optimistic: { id: txId },
				},
			} = action;

			const fetchOptions = {
				method: isVisible ? 'PUT' : 'DELETE',
			};

			return fetchJson$(getIssueMediaVisibilityChangeUrl(contextPath, issueId), fetchOptions)
				.flatMap(() => Observable.of(issueMediaVisibilityChangeSuccess(txId, issueId, isVisible)))
				.catch(() => Observable.of(issueMediaVisibilityChangeFailure(txId)));
		});
