import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'software-filters.common.list.placeholder',
		defaultMessage: 'Search filters…',
		description: 'Placeholder text. Appears on the search box of list filter.',
	},
	anounceMessage: {
		id: 'software-filters.common.list.anounce-message',
		defaultMessage:
			'When selecting from the {field} filter, content in the backlog board will automatically update',
		description: 'Anounce message. Gets announced on the form controls fields',
	},
});
