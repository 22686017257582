import React, { type ReactNode } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import CalendarIcon from '@atlaskit/icon/core/calendar';
import { Box } from '@atlaskit/primitives';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import {
	ChildIssuesGlyph,
	FlagGlyph,
	CheckGlyph,
	UploadGlyph,
	BitbucketBranchesGlyph,
	BitbucketCommitsGlyph,
	BitbucketPullrequestsGlyph,
	EmojiFrequentGlyph,
	MoreGlyph,
	PersonGlyph,
	DueDateGlyph,
} from './assets/icons';
import {
	CHILD_ISSUES_ICON,
	FLAG_ICON,
	CHECK_ICON,
	UPLOAD_ICON,
	BITBUCKET_BRANCHES_ICON,
	BITBUCKET_COMMITS_ICON,
	BITBUCKET_PULLREQUESTS_ICON,
	EMOJI_FREQUENT_ICON,
	MORE_ICON,
	PERSON_ICON,
	DUE_DATE_ICON,
	type Props,
	type IconTypeEnum,
	type ContainerProps,
} from './types';

const renderIcon = (type: IconTypeEnum): ReactNode => {
	switch (type) {
		case CHILD_ISSUES_ICON:
			return <ChildIssuesGlyph />;
		case FLAG_ICON:
			return <FlagGlyph />;
		case CHECK_ICON:
			return <CheckGlyph />;
		case UPLOAD_ICON:
			return <UploadGlyph />;
		case BITBUCKET_BRANCHES_ICON:
			return <BitbucketBranchesGlyph />;
		case BITBUCKET_COMMITS_ICON:
			return <BitbucketCommitsGlyph />;
		case BITBUCKET_PULLREQUESTS_ICON:
			return <BitbucketPullrequestsGlyph />;
		case EMOJI_FREQUENT_ICON:
			return <EmojiFrequentGlyph />;
		case MORE_ICON:
			return <MoreGlyph />;
		case PERSON_ICON:
			return <PersonGlyph />;
		case DUE_DATE_ICON:
			return isVisualRefreshEnabled() ? (
				<Box paddingInlineEnd="space.050" paddingBlock="space.025">
					<CalendarIcon label="" color="currentColor" />
				</Box>
			) : (
				<DueDateGlyph />
			);
		default:
			throw Error(`Invalid Icon type - ${type}`);
	}
};

const CardIcon = (props: Props) => {
	const { label, primaryColor, type } = props;

	return (
		<Container role="img" aria-label={label} primaryColor={primaryColor}>
			{renderIcon(type)}
		</Container>
	);
};

export default CardIcon;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const ContainerControl = styled.span((props: ContainerProps) => ({
	display: 'inline-block',
	flexShrink: 0,
	lineHeight: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> svg': {
		verticalAlign: 'bottom',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		color: props.primaryColor || undefined,
	},
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerExperiment = styled2.span({
	display: 'inline-block',
	flexShrink: 0,
	lineHeight: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> svg': {
		verticalAlign: 'bottom',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: (props: ContainerProps) => props.primaryColor || undefined,
	},
});

const Container = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	ContainerExperiment,
	ContainerControl,
);
