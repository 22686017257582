import type { CardId, ColumnId, SwimlaneId } from '../../types';
import { COLUMN, ISSUE } from './types';

interface Descriptor {
	type: string;
	id: number | string;
	swimlaneId: string | undefined;
}

export const getDescriptor = (identifier: string): Descriptor => {
	const [type, id, swimlaneId] = identifier.split('::');

	const maybeNumberId = Number(id);
	return {
		type,
		id: Number.isNaN(maybeNumberId) ? id : maybeNumberId,
		swimlaneId: swimlaneId || undefined,
	};
};

export const getColumnDescriptor = (columnId: ColumnId, swimlaneId?: SwimlaneId): string =>
	swimlaneId ? `${COLUMN}::${columnId}::${swimlaneId}` : `${COLUMN}::${columnId}`;

export const getCardDescriptor = (cardId: CardId): string => `${ISSUE}::${cardId}`;
