import {
	SHOW_ASSIGN_ISSUE_PARENT_MODAL,
	HIDE_ASSIGN_ISSUE_PARENT_MODAL,
} from '../../../actions/issue/assign-issue-parent-modal';
import type { Action } from '../../../types';
import type { AssignIssueParentModalState } from './types';

const defaultState = { isOpen: false } as const;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: AssignIssueParentModalState = defaultState,
	action: Action,
): AssignIssueParentModalState => {
	if (action.type === SHOW_ASSIGN_ISSUE_PARENT_MODAL) {
		return {
			isOpen: true,
			issueIds: action.payload.issueIds,
		};
	}
	if (action.type === HIDE_ASSIGN_ISSUE_PARENT_MODAL) {
		return { isOpen: false };
	}

	return state;
};
