import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import Placeholder from '@atlassian/jira-placeholder';

const LazyNoSoftwareLicense = lazyForPaint(
	() =>
		import(/* webpackChunkName: "async-no-software-license", jiraPriority: 'ondemand' */ './index'),
);

export const AsyncNoSoftwareLicense = () => (
	<Placeholder name="no-software-license" fallback={<Spinner />}>
		<LazyNoSoftwareLicense />
	</Placeholder>
);
