import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import { getCurrentUserDataFromAccountIdUrl } from '../../rest/software/software-api-urls';
import { transformPerson } from '../../services/people/people-data-transformer';
import { setPerson } from '../../state/actions/people';
import {
	SOFTWARE_APP_LOADED,
	SOFTWARE_APP_INITIAL_STATE_LOADED,
} from '../../state/actions/software';
import { WORK_REFRESH_DATA } from '../../state/actions/work';
import { getPeople } from '../../state/selectors/people/people-selectors';
import {
	contextPathSelector,
	currentUserAccountIdSelector,
} from '../../state/selectors/software/software-selectors';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types';

const workGetCurrentUserEpic = (
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> =>
	action$
		.ofType(WORK_REFRESH_DATA, SOFTWARE_APP_LOADED, SOFTWARE_APP_INITIAL_STATE_LOADED)
		.switchMap(() => {
			const state = store.getState();
			const contextPath = contextPathSelector(state);
			const currentUserAccountId = currentUserAccountIdSelector(state);
			const people = getPeople(state);

			if (people[currentUserAccountId]) return Observable.empty<never>();

			return fetchJson$(getCurrentUserDataFromAccountIdUrl(contextPath, currentUserAccountId))
				.flatMap((response) =>
					Observable.of(
						setPerson({
							...transformPerson(response),
						}),
					),
				)
				.catch((error) => {
					fireErrorAnalytics({
						error,
						meta: {
							id: 'board.getCurrentUser.failure',
							packageName: 'jiraSoftwareBoard',
							teamName: 'a4t-tanuki',
						},
					});
					return Observable.empty<never>();
				});
		});

export default workGetCurrentUserEpic;
