import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { ff } from '@atlassian/jira-feature-flagging';
import { SWIMLANE_BY_SUBTASK } from '../../../model/swimlane/swimlane-modes';
import { getOrderedIssuesByColumn } from '../issue/issue-selectors';
import { getUi, getOrderedColumnIds } from '../software/software-selectors';
import { getSwimlaneMode } from '../swimlane/swimlane-mode-selectors';

export const getFirstColumnIssueIds = createSelector(
	[getOrderedColumnIds, getOrderedIssuesByColumn],
	(orderedColumnIds, orderedIssuesByColumn) => {
		if (
			ff('jsw.board.backlog-issue-move-undefined-fix') &&
			(!orderedColumnIds ||
				isEmpty(orderedColumnIds) ||
				!orderedIssuesByColumn ||
				isEmpty(orderedIssuesByColumn))
		) {
			return [];
		}

		const firstColumnId = orderedColumnIds[0];
		const firstColumnIssues = orderedIssuesByColumn[firstColumnId];
		return firstColumnIssues ? firstColumnIssues.map((i) => i.id) : [];
	},
);

export const isOpenSelector = createSelector([getUi], (ui) =>
	Boolean(ui.backlogIssueMoveDialog?.isOpen),
);

export const isDialogVisibleSelector = createSelector(
	[isOpenSelector, getFirstColumnIssueIds, getSwimlaneMode],
	(isOpen, firstColumnIssueIds, swimlaneMode) => {
		if (swimlaneMode === SWIMLANE_BY_SUBTASK.id) return false;

		const firstColumnNotEmpty = firstColumnIssueIds.length > 0;
		return isOpen && firstColumnNotEmpty;
	},
);
