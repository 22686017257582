import { WORK_DATA_SET } from '../../../../actions/work';
import type { Action } from '../../../../types';
import type { IsConfigLoadedState } from './types';

export const isConfigLoadedReducer = (
	state: IsConfigLoadedState = false,
	action: Action,
): IsConfigLoadedState => {
	switch (action.type) {
		case WORK_DATA_SET:
			return true;
		default:
			return state;
	}
};
