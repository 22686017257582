import type { Observable as ObservableType } from 'rxjs/Observable';
import type {
	DeploymentState,
	DeploymentEnvironment,
} from '@atlassian/jira-development-board-dev-info-icon/src/types.tsx';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common';
import { query$ } from '@atlassian/jira-software-swag/src/services/query/index.tsx';
import type { Response } from './types';

export const operationName = 'SoftwareBoardDevStatus';

export const QUERY = `
    query ${operationName} ($boardId: ID!) {
        agile { 
            board(id: $boardId) {
                columns {
                    cards {
                        id
                        devStatus {
                            activity
                            count
                            properties {
                                state
                                environment {
                                    type
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export function fetchDevStatusFromSwag(baseUrl: string, boardId: string): ObservableType<Response> {
	return query$<Response>(baseUrl, operationName, QUERY, { boardId });
}
export interface RestDevStatusResponse {
	devOpsActivityForIssues: {
		[id: number]: {
			count: number;
			devOpsInfoActivityState: string;
			properties?: {
				state?: DeploymentState;
				environment?: {
					type?: DeploymentEnvironment;
				};
			};
		};
	};
}

export interface FetchDevStatusFromRestParams {
	boardId: string;
	issueIds: IssueId[];
}

export function fetchDevStatusFromRest({
	boardId,
	issueIds,
}: FetchDevStatusFromRestParams): ObservableType<RestDevStatusResponse> {
	return fetchJson$('/rest/greenhopper/1.0/xboard/work', {
		method: 'POST',
		body: JSON.stringify({
			rapidViewId: boardId,
			fields: ['issues_dev_info_activity'],
			issueIds,
		}),
	});
}
