import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	notAvailableMessage: {
		id: 'software.main.board-container.board.column.transition-zones-container.not-available-message',
		defaultMessage: 'A workflow rule prevents you from moving the issue this way.',
		description: 'message for when the drop zone is restricted',
	},
	noTransitionMessage: {
		id: 'software.main.board-container.board.column.transition-zones-container.no-transition-message',
		defaultMessage:
			"You can't move this issue to this column due to workflow configurations or permissions.",
		description: 'message for when no drop zone is available',
	},
});
