import {
	type ExternalAction,
	externalEditFieldAction,
	externalAddCommentAction,
} from '@atlassian/jira-issue-view-store/src/actions/external-actions';
import { ASSIGNEE_TYPE, LABELS_TYPE } from '@atlassian/jira-platform-field-config';
import {
	ASSIGNEE_SHORTCUT_KEY,
	LABELS_SHORTCUT_KEY,
	COMMENT_SHORTCUT_KEY,
} from '../../../../model/keyboard-shortcuts/constants';

export const getExternalActionForKey = (key: string): ExternalAction | null => {
	switch (key) {
		case ASSIGNEE_SHORTCUT_KEY:
			return externalEditFieldAction(ASSIGNEE_TYPE);
		case LABELS_SHORTCUT_KEY:
			return externalEditFieldAction(LABELS_TYPE);
		case COMMENT_SHORTCUT_KEY:
			return externalAddCommentAction();
		default:
			return null;
	}
};
