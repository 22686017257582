import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';
import type { CardTransitionEntities } from '../../../../model/card-transition/card-transition-types';
import type { ColumnId } from '../../../../model/column/column-types';
import type { OptimisticId, Status } from '../../../../model/software/software-types';
import type { FlagMeta } from '../../meta/flag-meta';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiWithFlagMeta,
	type OptimisticUiMeta,
} from '../../meta/optimistic-ui-meta';

export const COLUMN_RENAME_REQUEST = 'state.actions.column.rename.COLUMN_RENAME_REQUEST' as const;

export type ColumnRenameRequestAction = {
	type: typeof COLUMN_RENAME_REQUEST;
	payload: {
		columnId: ColumnId;
		name: string;
	};
	meta: {
		analyticsEvent?: AnalyticsEvent;
	} & OptimisticUiMeta;
};

export const columnRenameRequest = (
	columnId: ColumnId,
	name: string,
	analyticsEvent?: AnalyticsEvent,
): ColumnRenameRequestAction => ({
	type: COLUMN_RENAME_REQUEST,
	payload: {
		columnId,
		name,
	},
	meta: {
		...beginOptimisticUiMeta(),
		analyticsEvent,
	},
});

export const COLUMN_RENAME_SUCCESS = 'state.actions.column.rename.COLUMN_RENAME_SUCCESS' as const;

export type ColumnRenameSuccessAction = {
	type: typeof COLUMN_RENAME_SUCCESS;
	payload: {
		columnId: ColumnId;
		statuses: Status[];
		cardTransitions: CardTransitionEntities | null;
	};
	meta: OptimisticUiMeta;
};

export const columnRenameSuccess = (
	optimisticId: OptimisticId,
	columnId: ColumnId,
	statuses: Status[],
	cardTransitions: CardTransitionEntities | null,
): ColumnRenameSuccessAction => ({
	type: COLUMN_RENAME_SUCCESS,
	payload: {
		columnId,
		statuses,
		cardTransitions,
	},
	meta: commitOptimisticUiMeta(optimisticId),
});

export const COLUMN_RENAME_FAILURE = 'state.actions.column.rename.COLUMN_RENAME_FAILURE' as const;

export type ColumnRenameFailureAction = {
	type: typeof COLUMN_RENAME_FAILURE;
	payload: {
		columnId: ColumnId;
	};
	error: string;
	meta: OptimisticUiMeta & FlagMeta;
};

export const columnRenameFailure = (
	optimisticId: OptimisticId,
	columnId: ColumnId,
	error: string,
) => ({
	type: COLUMN_RENAME_FAILURE,
	payload: {
		columnId,
	},
	error,
	meta: revertOptimisticUiWithFlagMeta(optimisticId),
});

export type Action =
	| ColumnRenameRequestAction
	| ColumnRenameSuccessAction
	| ColumnRenameFailureAction;
