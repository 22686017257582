import { useEffect } from 'react';
import {
	type Edge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import {
	CARD_DND_TYPE,
	COLUMN_DND_TYPE,
	TRANSITION_ZONE_DND_TYPE,
} from '../../../common/constants/drag-drop';
import type {
	CardId,
	ColumnId,
	DraggableCardData,
	DraggableColumnData,
	DraggableData,
	DroppableData,
	SwimlaneId,
} from '../../../common/types';
import { getDestinationColumnIndex, getDestinationCardIndex } from '../utils';

export type BoardDragDropContextProps = {
	onDragStart: (draggableData: DraggableData) => void;
	onDragEnd: (draggableData: DraggableData, targetData: DraggableData | undefined) => void;
	onColumnMove: (
		columnId: ColumnId,
		sourceColumnId: number,
		destinationColumnIndex: number,
	) => void;
	onCardsMove: (onCardsMoveParams: {
		cardIds: CardId[];
		sourceColumnId: ColumnId;
		destinationColumnId: ColumnId;
		sourceCardIndex: number;
		destinationCardIndex?: number;
		sourceSwimlaneId?: SwimlaneId | null;
		destinationSwimlaneId?: SwimlaneId | null;
	}) => void;
};

const availableDNDTypes = [CARD_DND_TYPE, COLUMN_DND_TYPE];

export const handleColumnMove = (
	sourceColumnData: DraggableColumnData,
	destinationColumnData: DraggableColumnData | undefined,
	onColumnMove: BoardDragDropContextProps['onColumnMove'],
) => {
	const { columnId, columnIndex: sourceColumnIndex } = sourceColumnData;
	if (
		!destinationColumnData ||
		destinationColumnData.type !== COLUMN_DND_TYPE ||
		columnId === destinationColumnData.columnId
	) {
		return;
	}

	const closestEdgeOfTarget: Edge | null = extractClosestEdge(destinationColumnData);

	if (!closestEdgeOfTarget) return;

	const destinationColumnIndex = getDestinationColumnIndex(
		closestEdgeOfTarget,
		sourceColumnIndex,
		destinationColumnData.columnIndex,
	);

	if (sourceColumnIndex !== destinationColumnIndex) {
		onColumnMove(columnId, sourceColumnIndex, destinationColumnIndex);
	}
};

export const handleCardsMove = (
	cardData: DraggableCardData,
	targetData: DroppableData | undefined,
	onCardsMove: BoardDragDropContextProps['onCardsMove'],
) => {
	const {
		cardId,
		cardIndex: sourceCardIndex,
		columnId: sourceColumnId,
		swimlaneId: sourceSwimlaneId,
	} = cardData;

	if (!targetData) return;

	if (targetData.type === COLUMN_DND_TYPE || targetData.type === TRANSITION_ZONE_DND_TYPE) {
		// Drop on column or transition zone
		onCardsMove({
			cardIds: [cardId],
			sourceColumnId,
			destinationColumnId: targetData.columnId,
			sourceCardIndex,
			sourceSwimlaneId,
			destinationSwimlaneId: targetData.swimlaneId,
		});
		return;
	}

	if (targetData.type === CARD_DND_TYPE) {
		// Drop against a card
		const closestEdge: Edge | null = extractClosestEdge(targetData);
		if (!closestEdge) return;

		const isSameColumnAndSwimlane =
			sourceColumnId === targetData.columnId && sourceSwimlaneId === targetData.swimlaneId;
		const destinationCardIndex = getDestinationCardIndex({
			closestEdge,
			sourceCardIndex,
			destinationCardIndex: targetData.cardIndex,
			isSameColumnAndSwimlane,
		});

		if (
			isSameColumnAndSwimlane &&
			(cardId === targetData.cardId || sourceCardIndex === destinationCardIndex)
		) {
			return;
		}

		onCardsMove({
			cardIds: [cardId],
			sourceColumnId,
			destinationColumnId: targetData.columnId,
			sourceCardIndex,
			destinationCardIndex,
			sourceSwimlaneId,
			destinationSwimlaneId: targetData.swimlaneId,
		});
	}
};

export const useBoardDragDropContext = ({
	onDragStart,
	onDragEnd,
	onColumnMove,
	onCardsMove,
}: BoardDragDropContextProps) => {
	useEffect(
		() =>
			monitorForElements({
				canMonitor: ({ source }) => availableDNDTypes.includes(String(source.data.type)),
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				onDragStart: ({ source }) => onDragStart(source.data as DraggableData),
				onDrop: ({ location, source }) => {
					onDragEnd(
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						source.data as DraggableData,
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						location.current.dropTargets[0]?.data as DraggableData | undefined,
					);
					if (!location.current.dropTargets.length) {
						return;
					}

					if (source.data.type === COLUMN_DND_TYPE) {
						handleColumnMove(
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							source.data as DraggableColumnData,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							location.current.dropTargets[0]?.data as DraggableColumnData | undefined,
							onColumnMove,
						);
					}

					if (source.data.type === CARD_DND_TYPE) {
						handleCardsMove(
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							source.data as DraggableCardData,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							location.current.dropTargets[0]?.data as
								| DraggableColumnData
								| DraggableCardData
								| undefined,
							onCardsMove,
						);
					}
				},
			}),
		[onCardsMove, onColumnMove, onDragEnd, onDragStart],
	);
};
