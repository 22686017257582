import type { ApplicationPropertiesMap } from '../application-properties/types';

type ConfluenceAccess = { hasAccess: boolean };

type SessionStorage = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	get: (key: string) => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	set: (key: string, value?: any) => void;
	remove: (key: string) => void;
	clearAll: () => void;
};

const createTTLTimestamp = (ttl: number) => Date.now() + ttl;

export const getSessionStorageData = (key: string, storage: SessionStorage) => storage.get(key);
export const setSessionStorageData = ({
	key,
	value,
	ttl,
	storage,
}: {
	ttl: number;
	key: string;
	value: ApplicationPropertiesMap | ConfluenceAccess;
	storage: SessionStorage;
}) => {
	const expiresAt = createTTLTimestamp(ttl);
	storage.set(key, { ...value, expiresAt });
};

export const isStorageKeyExpired = (key: string, storage: SessionStorage) => {
	if (!getSessionStorageData(key, storage)) {
		return true;
	}
	const expiresAt = getSessionStorageData(key, storage)?.expiresAt;
	return expiresAt ? new Date().getTime() >= expiresAt : true;
};

export const clearSessionStorageData = (key: string, storage: SessionStorage) =>
	storage.remove(key);
