const storageKey = 'jira_recommended_trial_to_free_ab_flag_seen';

export function getFlagId() {
	if (typeof window === 'undefined') {
		return true;
	}
	return localStorage.getItem(storageKey)?.toString();
}

export function setHasSeenFlag(id: string) {
	if (typeof window === 'undefined') {
		return;
	}
	localStorage.setItem(storageKey, id);
}
