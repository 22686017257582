import {
	DONE,
	IN_PROGRESS,
	TODO,
	UNDEFINED,
	statusCategoryForId,
} from '@atlassian/jira-common-constants/src/status-categories';

const appearanceMap = {
	[UNDEFINED]: 'default',
	[TODO]: 'default',
	[DONE]: 'success',
	[IN_PROGRESS]: 'inprogress',
} as const;

export const toAppearance = (categoryId?: string | number | undefined) =>
	categoryId ? appearanceMap[statusCategoryForId(categoryId)] : appearanceMap[UNDEFINED];
