import { freeze } from 'icepick';
import { createSelector } from 'reselect';
import { ff } from '@atlassian/jira-feature-flagging';
import type { BoardPermissions } from '../../../model/board/board-types';
import {
	CAN_MANAGE_LABS,
	CAN_RANK_COLUMNS,
	CAN_EDIT_BOARD,
	CAN_RENAME_COLUMNS,
	CAN_CREATE_COLUMNS,
	CAN_CREATE_CARDS,
	CAN_DELETE_ISSUE,
	CAN_MANAGE_SPRINT,
	CAN_MANAGE_AUTOMATION,
	CAN_RELEASE,
	CAN_EDIT_ISSUE,
	CAN_ARCHIVE_ISSUE,
} from '../../../model/permission/permission-types';
import type { State } from '../../reducers/types';
import { getBoardEntity, getIsIncrementPlanningBoard } from '../software/software-selectors';

export const boardPermissionsSelector = (state: State): BoardPermissions =>
	getBoardEntity(state).permissions;

export const getPermissionsSelector = createSelector([boardPermissionsSelector], (permissions) =>
	freeze({
		[CAN_EDIT_BOARD]: permissions.editBoardConfig,
		[CAN_RANK_COLUMNS]: permissions.editBoardConfig || false,
		[CAN_RENAME_COLUMNS]: permissions.editBoardConfig || false,
		[CAN_CREATE_COLUMNS]: permissions.editBoardConfig || false,
		[CAN_MANAGE_LABS]: false,
		[CAN_CREATE_CARDS]: permissions.createIssue || false,
		[CAN_EDIT_ISSUE]: permissions.editIssue || false,
		[CAN_DELETE_ISSUE]: permissions.deleteIssue,
		[CAN_MANAGE_SPRINT]: permissions.manageSprint,
		[CAN_MANAGE_AUTOMATION]: permissions.manageAutomation || false,
		[CAN_RELEASE]: permissions.canRelease || false,
		...(ff('issue-unarchival_wdpb9')
			? { [CAN_ARCHIVE_ISSUE]: permissions.archiveIssue || false }
			: {}),
	}),
);

/**
 * Read-only users should not see or be able to
 * - Edit the card (summary, estimates, parent, etc.)
 * - Create issue button in the column
 * - See Add/Change sprint button in the swimlane
 * - See the context menu for the card
 * - Remove or add dependencies
 * - Drag and drop cards
 */
export const isIncrementPlanningReadOnly = createSelector(
	[getPermissionsSelector, getIsIncrementPlanningBoard],
	(permissions, isIncrementPlanningBoard) => {
		if (!isIncrementPlanningBoard) {
			return false;
		}

		// User is read only if they don't have create cards permission
		return !permissions[CAN_CREATE_CARDS];
	},
);
