import { connect } from '@atlassian/jira-react-redux';
import { getManageCustomFiltersUrl } from '../../../../common/urls';
import {
	projectKeySelector,
	rapidViewIdSelector,
} from '../../../../state/selectors/software/software-selectors';
import type { State } from '../../../../state/types';
import ManageCustomFiltersItem, { type StateProps } from './view';

const mapStateToProps = (state: State): StateProps => ({
	url: getManageCustomFiltersUrl(projectKeySelector(state), rapidViewIdSelector(state)),
});

export default connect(mapStateToProps, {})(ManageCustomFiltersItem);
