import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	/* eslint-disable jira/i18n/id-named-by-package */
	title: {
		id: 'servicedesk.queues.common.state.selectors.issues.field-transformers.json-fields.custom-fields.title',
		defaultMessage: 'SLAs',
		description: 'Title of the SLAs',
	},
	past: {
		id: 'servicedesk.queues.common.state.selectors.issues.field-transformers.json-fields.custom-fields.past',
		defaultMessage: 'past',
		description: 'Time past after sla completion',
	},
	left: {
		id: 'servicedesk.queues.common.state.selectors.issues.field-transformers.json-fields.custom-fields.left',
		defaultMessage: 'left',
		description: 'Time left for sla breach',
	},
	met: {
		id: 'servicedesk.queues.common.state.selectors.issues.field-transformers.json-fields.custom-fields.met',
		defaultMessage: 'met',
		description: 'SLA is completed',
	},
	breached: {
		id: 'servicedesk.queues.common.state.selectors.issues.field-transformers.json-fields.custom-fields.breached',
		defaultMessage: 'breached',
		description: 'SLA is breached',
	},
	due: {
		id: 'servicedesk.queues.common.state.selectors.issues.field-transformers.json-fields.custom-fields.due',
		defaultMessage: 'Due',
		description: 'Due time',
	},
	today: {
		id: 'servicedesk.queues.common.state.selectors.issues.field-transformers.json-fields.custom-fields.today',
		defaultMessage: 'Today',
		description: 'Today is breach date',
	},
	tomorrow: {
		id: 'servicedesk.queues.common.state.selectors.issues.field-transformers.json-fields.custom-fields.tomorrow',
		defaultMessage: 'Tomorrow',
		description: 'Tomorrow is breach date',
	},
	yesterday: {
		id: 'servicedesk.queues.common.state.selectors.issues.field-transformers.json-fields.custom-fields.yesterday',
		defaultMessage: 'Yesterday',
		description: 'Yesterday was breach date',
	},
	notApplicable: {
		id: 'servicedesk.queues.common.state.selectors.issues.field-transformers.json-fields.custom-fields.not-applicable',
		defaultMessage: 'Breach time not found',
		description: 'This field is not found',
	},
});
