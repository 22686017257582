import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	completeSprintButton: {
		id: 'software.main.header.complete-sprint-button.complete-sprint-button',
		defaultMessage: 'Complete sprint',
		description: '',
	},
	completeSprintButtonDisabledInfoTextNonFinal: {
		id: 'software.main.header.complete-sprint-button.complete-sprint-button-disabled-info-text-non-final',
		defaultMessage: 'Request permissions from board admin',
		description:
			'Text to display when the complete sprint button is disabled due to lack of permissions',
	},
});
