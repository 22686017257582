import { connect } from '@atlassian/jira-react-redux';
import { operationsSelector } from '../../../../state/selectors/board/board-selectors';
import type { State } from '../../../../state/types';
import ConnectItems from './view';

type OwnProps = {
	hasOperations: boolean;
};

const mapStateToProps = (state: State): OwnProps => ({
	hasOperations: operationsSelector(state).length > 0,
});

export default connect(mapStateToProps)(ConnectItems);
