import type { ReactNode } from 'react';
import type { IssueId, IssueEntry } from '@atlassian/jira-software-board-common';
import type { ColumnId } from '../../../../../model/column/column-types';
import type { SwimlaneId } from '../../../../../model/swimlane/swimlane-types';
import type { RenderCardCallback } from '../types';

export type FastVirtualListProps = {
	name?: string;
	issueEntries: IssueEntry[];
	issuesWithIssueLinksIds: IssueId[];
	renderCard: RenderCardCallback;
	footer: ReactNode | null;
	placeholder: ReactNode | null;
	showFooter: boolean;
	columnId: ColumnId;
	swimlaneId?: SwimlaneId | null;
	offsetTop: number;
	hasCTA?: boolean;
	isUnscheduledWorkColumnPanel?: boolean;
};

export const VIRTUAL_LIST_FOOTER_KEY = 'footer';
export const DEFAULT_CARD_HEIGHT = 84;
export const VIRTUAL_LIST_DND_KEY = 'virtual_list_dnd_key';

/**
 * Static width for cards
 */
export const DEFAULT_CARD_WIDTH = 260;

/**
 * Minimum height set to match ICC height
 */
export const DEFAULT_MIN_HEIGHT = 40;
