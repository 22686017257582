import {
	SET_ACTIVE_ISSUE_WITH_ICC,
	SET_ISSUE_ID_WITH_ABOVE_ICC_OPEN,
	OPEN_ICC_IN_UNSCHEDULED_COLUMN,
	CLOSE_ICC_IN_UNSCHEDULED_COLUMN,
} from '../../../../actions/card';
import { ISSUE_DELETE_SUCCESS } from '../../../../actions/issue/delete';
import type { Action } from '../../../../types';
import type { CardWithIccState } from './types';

export const initialState: CardWithIccState = {
	mainActiveIssue: null,
	cardIdWithAboveIccOpen: null,
	columnIdToKeepIccOpen: null,
	lastColumnIdOpen: null,
	// used in program board unscheduled column
	swimlaneIdToKeepIccOpen: null,
};

export const cardWithIccReducer = (
	state: CardWithIccState | undefined,
	action: Action,
): CardWithIccState => {
	switch (action.type) {
		case SET_ACTIVE_ISSUE_WITH_ICC: {
			return {
				...(state || initialState),
				mainActiveIssue: action.payload.issueId,
			};
		}

		case SET_ISSUE_ID_WITH_ABOVE_ICC_OPEN: {
			return {
				...(state || initialState),
				cardIdWithAboveIccOpen: action.payload.issueId,
				columnIdToKeepIccOpen: action.payload.columnId,
				lastColumnIdOpen: action.payload.columnId || state?.lastColumnIdOpen || null,
			};
		}

		case ISSUE_DELETE_SUCCESS: {
			// Reset the lastColumnIdOpen on deletion so if the board is empty again, the ICC will pop open
			return {
				...(state || initialState),
				lastColumnIdOpen: null,
			};
		}

		case OPEN_ICC_IN_UNSCHEDULED_COLUMN: {
			// Used in program board unscheduled column to open ICC in column footer
			return {
				...(state || initialState),
				swimlaneIdToKeepIccOpen: action.payload.swimlaneId,
			};
		}

		case CLOSE_ICC_IN_UNSCHEDULED_COLUMN: {
			// Close ICC in program board unscheduled column
			return {
				...(state || initialState),
				swimlaneIdToKeepIccOpen: null,
			};
		}

		default: {
			// @ts-expect-error - TS2322: Type 'ShowErrorFlagAction | SetPersonAction | AddToSprintRequestAction | AddToSprintSuccessAction | ... 255 more ... | UnsupportedAction' is not assignable to type 'never'.
			const _exhaustiveCheck: never = action;
			return state || initialState;
		}
	}
};
