import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { makeServiceContext } from '../../services/service-context';
import { fetchVersionDataService } from '../../services/version';
import {
	SOFTWARE_APP_LOADED,
	SOFTWARE_APP_INITIAL_STATE_LOADED,
} from '../../state/actions/software';
import { FETCH_VERSION_DATA, setVersionData } from '../../state/actions/version';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types';

export const versionGetEpic = (
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> =>
	action$
		.ofType(FETCH_VERSION_DATA, SOFTWARE_APP_LOADED, SOFTWARE_APP_INITIAL_STATE_LOADED)
		.switchMap(() => {
			const ctx = makeServiceContext(store.getState());

			if (!ctx.isCMPBoard) return Observable.of();

			return fetchVersionDataService(ctx)
				.mergeMap((versionResponse) => Observable.of(setVersionData(versionResponse)))
				.catch((error) => {
					log.safeErrorWithoutCustomerData(
						'board.version.load.failure',
						'Failure on version data load',
						error,
					);
					return Observable.of();
				});
		});
