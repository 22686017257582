/** @jsx jsx */
import React, { type ReactElement, useState, type RefObject, useEffect } from 'react';
import { styled, css, jsx } from '@compiled/react';
import Button from '@atlaskit/button';
import InformationIcon from '@atlaskit/icon/core/migration/information--info';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import Popup from '@atlaskit/popup';
import { xcss, Stack } from '@atlaskit/primitives';
import Text from '@atlaskit/primitives/text';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { Message as MessageType } from '../../../common/types';

const OPACITY = 0.9;
const iconWrapperStyles = css({
	'&:hover': {
		opacity: OPACITY,
	},
});

const getColor = (type: 'info' | 'warning') => {
	const types = {
		info: token('color.icon.discovery', colors.P300),
		warning: token('color.icon.warning', colors.Y300),
	};

	return types[type];
};

type Props = {
	infos?: MessageType[];
	warnings?: MessageType[];
	innerRef: RefObject<HTMLDivElement>;
	defaultIsOpen?: boolean;
};

const typesMapping = {
	info: {
		icon: InformationIcon,
		defaultLabel: 'info inline message',
	},
	warning: {
		icon: WarningIcon,
		defaultLabel: 'warning inline message',
	},
};

const InlineMessages = ({ infos = [], warnings = [], innerRef, defaultIsOpen = false }: Props) => {
	const [isOpen, setIsOpen] = useState(defaultIsOpen);

	useEffect(() => {
		const onScroll = () => {
			setIsOpen(false);
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('scroll', onScroll, true);
		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('scroll', onScroll);
		};
	}, []);

	const renderMessage = (
		title: string,
		description: string,
		index: number,
		isLast: boolean,
	): ReactElement => {
		return (
			<Stack key={index} space="space.100" xcss={!isLast && separatorStyles}>
				{title && (
					<Text as="p" weight="bold" color="inherit">
						{title}
					</Text>
				)}
				{description && (
					<Text as="p" color="inherit">
						{description}
					</Text>
				)}
			</Stack>
		);
	};

	const renderInlineMessage = (messages: MessageType[], type: 'info' | 'warning') => {
		const {
			[type]: { icon: SelectedIcon, defaultLabel },
		} = typesMapping;

		return (
			<Wrapper data-testid={`platform-inline-card-create.ui.form.inline-messages-${type}`}>
				<Popup
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					placement="bottom-end"
					content={() => (
						<Stack xcss={inlineMessageContentStyles} space="space.200" ref={innerRef}>
							{messages.map(({ title, description }, index) => {
								const isLast = index === messages.length - 1;
								return (title || description) && renderMessage(title, description, index, isLast);
							})}
						</Stack>
					)}
					trigger={(triggerProps) => (
						<Button
							{...triggerProps}
							appearance="subtle-link"
							spacing="none"
							onClick={() => setIsOpen(!isOpen)}
						>
							<span
								css={iconWrapperStyles}
								// eslint-disable-next-line jira/react/no-style-attribute
								style={{ opacity: isOpen ? OPACITY : 1, color: getColor(type) }}
							>
								<SelectedIcon label={defaultLabel} spacing="spacious" color="currentColor" />
							</span>
						</Button>
					)}
				/>
			</Wrapper>
		);
	};

	if (warnings.length > 0) {
		return renderInlineMessage(warnings, 'warning');
	}
	if (infos.length > 0) {
		return renderInlineMessage(infos, 'info');
	}

	return null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	paddingLeft: token('space.100', '8px'),
	marginRight: token('space.negative.025', '-2px'),
});

export default InlineMessages;

const inlineMessageContentStyles = xcss({
	width: '200px',
	boxSizing: 'content-box',
	textAlign: 'left',
	paddingInline: 'space.300',
	paddingBlock: 'space.200',
});

const separatorStyles = xcss({
	paddingBlockEnd: 'space.200',
	borderBottomWidth: 'space.025',
	borderBottomStyle: 'solid',
	borderBottomColor: 'color.border',
});
