import React, { type ComponentType } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props } from './main';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AdminInaccessibleBoard = lazyAfterPaint<ComponentType<Props>>(
	() => import(/* webpackChunkName: "async-admin-inaccessible-board" */ './main'),
	{ ssr: false },
);

export const AsyncAdminInaccessibleBoard: ComponentType<Props> = (props: Props) => {
	const Skeleton = props.skeleton;
	return (
		<JSErrorBoundary
			packageName="jiraAdminInaccessibleBoard"
			id="async-admin-inaccessible-board"
			sendToPrivacyUnsafeSplunk
			fallback="unmount"
		>
			<Placeholder name="admin-inaccessible-board" fallback={Skeleton && <Skeleton />}>
				<AdminInaccessibleBoard {...props} />
			</Placeholder>
		</JSErrorBoundary>
	);
};
