import React, { useMemo, useState } from 'react';
import _every from 'lodash/every';
import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import EditIcon from '@atlaskit/icon/core/edit';
import ChevronDownIconOld from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIconOld from '@atlaskit/icon/glyph/chevron-right';
import EditIconOld from '@atlaskit/icon/glyph/edit';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Box, Stack, Text, Inline, xcss } from '@atlaskit/primitives';
import Pressable from '@atlaskit/primitives/pressable';
import { token } from '@atlaskit/tokens';
import ToolTip from '@atlaskit/tooltip';
import { JiraSoftwareBoardAri } from '@atlassian/ari/jira-software/board';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { createAri } from '@atlassian/jira-platform-ari';
import { editSprintModalEntryPoint } from '@atlassian/jira-software-edit-sprint-modal-relay/entrypoint';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import type { Sprint } from '../../../model/sprint/sprint-types';
import { editSprintModalLoad } from '../../../services/utils/performance-analytics';
import { useBoardSelector } from '../../../state';
import {
	getIsCMPBoard,
	rapidViewIdSelector,
} from '../../../state/selectors/software/software-selectors';
import {
	activeSprintsSelector,
	canCompleteSprintSelector,
	hasManageSprintPermission,
	hasActiveSprintsSelector,
} from '../../../state/selectors/sprint/sprint-selectors';
import EditSprintModalEntryPoint from '../menu/edit-sprint-modal';
import TooltipContent from '../sprint-remaining-days/tooltip-content';
import CompleteSprintButton from './complete-sprint-button';
import messages from './messages';

const toolTipContent = (sprint: Sprint, locale: Locale) => (
	// not urgent, not crashing, this code is about to be retired anyway https://jplat.atlassian.net/browse/BLU-3256
	// @ts-expect-error TS2322: Type 'string | null' is not assignable to type 'string'.
	<TooltipContent startDate={sprint.startDate} endDate={sprint.endDate} locale={locale} />
);

export const SprintGoalPanel = () => {
	const cloudId = useCloudId();
	const locale = useLocale();
	const activeSprints = useBoardSelector(activeSprintsSelector);
	const isCMPBoard = useBoardSelector(getIsCMPBoard);
	const boardId = useBoardSelector(rapidViewIdSelector);

	const canCompleteSprint = useBoardSelector(canCompleteSprintSelector);
	const userHasManageSprintPermission = useBoardSelector(hasManageSprintPermission);
	const isActiveSprint = useBoardSelector(hasActiveSprintsSelector);

	const [isFullDisplay, setIsFullDisplay] = useState(false);
	const onClickPanel = () => {
		setIsFullDisplay(!isFullDisplay);
	};
	const { formatMessage } = useIntl();

	const entryPointParams = useMemo(
		() => ({
			boardId: JiraSoftwareBoardAri.create({
				siteId: cloudId,
				boardId: String(boardId),
			}).toString(),
			sprintId: createAri({
				cloudId,
				resourceType: 'sprint',
				resourceId: String(activeSprints ? activeSprints[0].id : ''),
				resourceOwner: 'jira-software',
			}).toString(),
			fetchSprints: true,
			isCompanyManaged: isCMPBoard,
		}),
		[boardId, cloudId, activeSprints, isCMPBoard],
	);

	const { entryPointActions, entryPointReferenceSubject, stopMetric } = useEntryPoint(
		editSprintModalEntryPoint,
		entryPointParams,
		editSprintModalLoad,
	);
	const editSprintModalTriggerRef = useEntryPointButtonTrigger(entryPointActions);

	if (!activeSprints) {
		return null;
	}

	const numberOfActiveSprints = isCMPBoard ? activeSprints.length : 1;
	const multipleActiveSprints = numberOfActiveSprints > 1;

	let title = activeSprints[0].name;
	let goal = activeSprints[0].goal;
	let canEditSprint = activeSprints[0].canUpdateSprint;

	if (multipleActiveSprints) {
		title = formatMessage(messages.multipleActiveSprintsNonFinal);
		goal = null;
		canEditSprint = _every(activeSprints, (sprint) => sprint.canUpdateSprint);
	}

	const daysRemaining = formatMessage(messages.remainingDaysLabelNonFinal, {
		remainingDays: activeSprints[0].daysRemaining,
	});

	return (
		<>
			<Box xcss={panelStyles}>
				<Stack grow="fill">
					<Inline spread="space-between" alignBlock="center">
						<Inline space="space.025" alignBlock="center">
							{goal ? (
								<>
									<IconButton
										appearance="subtle"
										// eslint-disable-next-line jira/react-no-inline-function-prop
										icon={(iconProps) =>
											isFullDisplay ? (
												<ChevronDownIcon
													{...iconProps}
													color={token('color.text', 'inherit')}
													LEGACY_fallbackIcon={ChevronDownIconOld}
													LEGACY_primaryColor={token('color.text', 'inherit')}
												/>
											) : (
												<ChevronRightIcon
													{...iconProps}
													color={token('color.text', 'inherit')}
													LEGACY_fallbackIcon={ChevronRightIconOld}
													LEGACY_primaryColor={token('color.text', 'inherit')}
												/>
											)
										}
										label={
											isFullDisplay
												? formatMessage(messages.collapseSprintGoalPanelNonFinal)
												: formatMessage(messages.expandSprintGoalPanelNonFinal)
										}
										spacing="compact"
										isTooltipDisabled={false}
										tooltip={{ ...tooltipOptions, position: 'top' }}
										onClick={onClickPanel}
										testId="software-board.header.sprint-goal-panel.icon-button"
									/>
									<ToolTip
										position="top"
										content={
											isFullDisplay
												? formatMessage(messages.collapseSprintGoalPanelNonFinal)
												: formatMessage(messages.expandSprintGoalPanelNonFinal)
										}
									>
										<Pressable onClick={onClickPanel} xcss={pressableStyles}>
											<Text weight="bold">{title}</Text>
										</Pressable>
									</ToolTip>
								</>
							) : (
								<Text weight="bold">{title}</Text>
							)}
							{canEditSprint && (
								<IconButton
									appearance="subtle"
									// eslint-disable-next-line jira/react-no-inline-function-prop
									icon={(iconProps) => (
										<EditIcon
											{...iconProps}
											color={token('color.text', 'inherit')}
											LEGACY_fallbackIcon={EditIconOld}
											LEGACY_size="small"
											LEGACY_primaryColor={token('color.text', 'inherit')}
										/>
									)}
									label={formatMessage(messages.editSprintLabelNonFinal, {
										activeSprints: numberOfActiveSprints,
									})}
									spacing="compact"
									isTooltipDisabled={false}
									tooltip={{ ...tooltipOptions, position: 'right' }}
									ref={editSprintModalTriggerRef}
								/>
							)}
						</Inline>

						<Inline alignBlock="center" space="space.100">
							{!multipleActiveSprints && (
								<ToolTip content={toolTipContent(activeSprints[0], locale)}>
									<Text color="color.text.subtlest">{daysRemaining}</Text>
								</ToolTip>
							)}

							{fg('jsw-jet-yamato-fe-permissions-seq-sprint-actions') ? (
								<>
									{isActiveSprint && (
										<CompleteSprintButton isDisabled={!userHasManageSprintPermission} />
									)}
								</>
							) : (
								<>{canCompleteSprint && <CompleteSprintButton />}</>
							)}
						</Inline>
					</Inline>
					{isFullDisplay && (
						<Box xcss={textStyles}>
							<Text>{goal}</Text>
						</Box>
					)}
				</Stack>
			</Box>
			{canEditSprint && (
				<EditSprintModalEntryPoint
					entryPointReferenceSubject={entryPointReferenceSubject}
					entryPointActions={entryPointActions}
					onReady={stopMetric}
				/>
			)}
		</>
	);
};

const tooltipOptions: IconButtonProps['tooltip'] = {
	hideTooltipOnClick: true,
};

const panelStyles = xcss({
	borderRadius: 'border.radius.200',
	border: `1px solid ${token('color.border')}`,
	padding: 'space.150',
	marginBottom: 'space.200',
});

const textStyles = xcss({
	display: 'flex',
	maxWidth: '750px',
	flexDirection: 'column',
	alignItems: 'start',
	justifyContent: 'center',
	paddingInlineStart: 'space.400',
});

const pressableStyles = xcss({
	':hover': {
		textDecoration: 'underline',
	},
	':active': {
		color: 'color.text',
	},
	backgroundColor: 'color.background.neutral.subtle',
});
