import type { Dispatch } from 'redux';
import { connect } from '@atlassian/jira-react-redux';
import type { RouterActionsType } from '@atlassian/react-resource-router';
import {
	backlogEnableCancel,
	backlogEnableConfirm,
	type Action,
} from '../../../../state/actions/board/backlog-enable';
import { isDialogVisibleSelector } from '../../../../state/selectors/board/backlog-enable-selectors';
import { initialColumnSelector } from '../../../../state/selectors/column/column-selectors';
import type { State } from '../../../../state/types';
import BacklogFeatureDialog from './view';

type DispatchProps = {
	onCancel: () => void;
	onConfirm: (push: RouterActionsType['push'], refresh: () => void) => void;
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
	const onCancel = () => {
		dispatch(backlogEnableCancel());
	};

	const onConfirm = (push: RouterActionsType['push'], refresh: () => void) => {
		dispatch(backlogEnableConfirm(push, refresh));
	};
	return {
		onCancel,
		onConfirm,
	};
};

export default connect(
	(state: State) => ({
		isOpen: isDialogVisibleSelector(state),
		initialColumn: initialColumnSelector(state),
	}),
	mapDispatchToProps,
)(BacklogFeatureDialog);
