import React, { useRef, useState } from 'react';
import { styled } from '@compiled/react';
import { lazy, LazySuspense } from 'react-loosely-lazy';
import Button from '@atlaskit/button';
import DropdownMenu from '@atlaskit/dropdown-menu';
import AppIcon from '@atlaskit/icon/core/app';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { ButtonItem, LinkItem, MenuGroup, Section } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import type { BoardToolWebItem, BoardToolSectionSubItem } from '../../../../types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const AsyncBoardToolWebPanelView = lazy(() =>
	import(/* webpackChunkName: "async-board-tool-web-panel" */ './board-tool-web-panel-view').then(
		({ BoardToolWebPanelView }) => ({ default: BoardToolWebPanelView }),
	),
);

interface Props {
	subItem: BoardToolSectionSubItem;
	onClickWebItem?: () => void;
}

export function BoardToolWebItemView({
	subItem,
	mode = 'dropdown-item',
	onClickWebItem,
}: {
	subItem: BoardToolWebItem;
	mode?: 'button' | 'dropdown-item';
	onClickWebItem?: () => void;
}) {
	const webItemLinkRef = useRef<HTMLAnchorElement>(null);
	const handleOnClickWebItem = (e: React.MouseEvent | React.KeyboardEvent) => {
		e.preventDefault();
		webItemLinkRef.current?.click();
		if (onClickWebItem) onClickWebItem();
	};

	return (
		<>
			{mode === 'dropdown-item' ? (
				<Tooltip
					content={
						getWillShowNav4() && fg('move-connect-addons-board-backlog-nav4')
							? subItem.label
							: undefined
					}
				>
					<LinkItem
						data-testid="software-connect-toolbar.common.ui.tool-sections.board-tool-section-view.board-tool-sub-item.link-item"
						onClick={handleOnClickWebItem}
						iconBefore={
							getWillShowNav4() && fg('move-connect-addons-board-backlog-nav4') ? (
								<AppIcon label={subItem.label} color={token('color.icon')} />
							) : undefined
						}
					>
						{subItem.label}
					</LinkItem>
				</Tooltip>
			) : (
				<Button
					testId="software-connect-toolbar.common.ui.tool-sections.board-tool-section-view.board-tool-sub-item.link-item"
					onClick={handleOnClickWebItem}
				>
					{subItem.label}
				</Button>
			)}

			{/**
			 * Connect add-ons work by a "DOM is source of truth" approach.
			 * This is a very outdated style. Class name on this element
			 * will be used by another script to attach event listeners and
			 * open this plug-in into a modal.
			 */}
			<HiddenLink
				ref={webItemLinkRef}
				href={subItem.url}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={subItem.cssClass ?? ''}
			>
				{subItem.label}
			</HiddenLink>
		</>
	);
}

export function BoardToolSubItem({ subItem, onClickWebItem }: Props) {
	const [isOpen, setIsOpen] = useState(false);
	if (subItem.type === 'webItem') {
		return <BoardToolWebItemView onClickWebItem={onClickWebItem} subItem={subItem} />;
	}
	if (subItem.type === 'section') {
		const content = subItem.subItems?.map((item) => (
			<BoardToolSubItem key={item.id} subItem={item} />
		));

		if (subItem.label === '') {
			return <>{content}</>;
		}

		if (getWillShowNav4() && fg('move-connect-addons-board-backlog-nav4')) {
			return (
				<Popup
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					placement="left-start"
					trigger={(triggerProps) => (
						<Tooltip content={subItem.label}>
							<ButtonItem
								{...triggerProps}
								iconBefore={<AppIcon label={subItem.label} color={token('color.icon')} />}
								iconAfter={<ChevronRightIcon label="" color={token('color.icon')} />}
								onClick={() => setIsOpen(!isOpen)}
								testId="software-connect-toolbar.common.ui.tool-sections.board-tool-section-view.board-tool-sub-item.popup-menu-trigger"
							>
								{subItem.label}
							</ButtonItem>
						</Tooltip>
					)}
					content={() => (
						<MenuGroup maxWidth={240} onClick={(e) => e.stopPropagation()}>
							<Section>{content}</Section>
						</MenuGroup>
					)}
				/>
			);
		}

		return (
			<DropdownMenu
				placement="right-end"
				trigger={subItem.label}
				testId="software-connect-toolbar.common.ui.tool-sections.board-tool-section-view.board-tool-sub-item.dropdown-menu"
			>
				{content}
			</DropdownMenu>
		);
	}
	if (subItem.type === 'webPanel') {
		return (
			<JSErrorBoundary
				id="board-tool-web-panel-view"
				packageName="@atlassian/jira-software-connect-toolbar"
				teamName="a4t-tanuki"
			>
				<LazySuspense fallback="Loading...">
					<AsyncBoardToolWebPanelView webPanel={subItem} />
				</LazySuspense>
			</JSErrorBoundary>
		);
	}

	return null;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HiddenLink = styled.a({
	display: 'none',
});
