import {
	OPEN_GLOBAL_ISSUE_CREATE,
	CLOSE_GLOBAL_ISSUE_CREATE,
} from '../../../actions/global-issue-create';
import type { Action } from '../../../types';
import type { GlobalIssueCreateState } from './types';

const defaultState = { isOpen: false } as const;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: GlobalIssueCreateState = defaultState,
	action: Action,
): GlobalIssueCreateState => {
	if (action.type === OPEN_GLOBAL_ISSUE_CREATE) {
		return {
			isOpen: true,
			inputs: action.payload,
		};
	}
	if (action.type === CLOSE_GLOBAL_ISSUE_CREATE) {
		return { isOpen: false };
	}

	return state;
};
