import {
	CLOSE_TEAM_SPRINT_ASSOCIATION_MODEL,
	OPEN_TEAM_SPRINT_ASSOCIATION_MODEL,
} from '../../../actions/sprints/sprint-association-modal';
import type { Action } from '../../../types';
import type { SprintAssociationModalState } from './types';

const defaultState = { isOpen: false } as const;

export const sprintAssociationModalReducer = (
	state: SprintAssociationModalState = defaultState,
	action: Action,
): SprintAssociationModalState => {
	if (action.type === OPEN_TEAM_SPRINT_ASSOCIATION_MODEL) {
		return {
			isOpen: true,
			...action.payload,
		};
	}
	if (action.type === CLOSE_TEAM_SPRINT_ASSOCIATION_MODEL) {
		return { isOpen: false };
	}

	return state;
};
