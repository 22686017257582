import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

export const FILTER_MENU_MIN_WIDTH = gridSize * 21;
export const FILTER_MENU_MIN_WIDTH_ON_NESTED = gridSize * 25;
export const FILTER_MENU_MAX_WIDTH = gridSize * 48;

export const FILTER_SEARCH_THRESHOLD = 9;

export const OPEN_CHANGE_PAYLOAD = { action: 'clicked', actionSubject: 'dropdownMenu' };
export const OPEN_CHANGE_SUBJECT_ID = 'filterList';
