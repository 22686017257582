import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type InlineCardCreate from './index';
import type { Props } from './index';

const AsyncInlineCardCreateComp = lazyAfterPaint<typeof InlineCardCreate>(
	() => import(/* webpackChunkName: "async-inline-card-create" */ './index'),
);

export const AsyncInlineCardCreate = (props: Props) => (
	<ErrorBoundary id="async-inline-card-create" packageName="JiraPlatformBoardKit">
		<Placeholder name="async-inline-card-create" fallback={null}>
			<AsyncInlineCardCreateComp {...props} />
		</Placeholder>
	</ErrorBoundary>
);
