import React from 'react';
import { ErrorFlag, type FlagComponentProps } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

export const CardCoverToggleFailedFlag = ({ id, onDismissed }: FlagComponentProps) => {
	const { formatMessage } = useIntl();

	return (
		<ErrorFlag
			title={formatMessage(messages.title)}
			description={formatMessage(messages.description)}
			id={id}
			onDismissed={onDismissed}
		/>
	);
};
