import React, { useCallback, type SyntheticEvent, type ComponentPropsWithoutRef } from 'react';
import { styled, css } from '@compiled/react';
import isNil from 'lodash/isNil';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useFieldConfigWithoutRefetch } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { READ_VIEW_CONTAINER_SELECTOR } from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { AsyncLazyNumberFieldInlineEdit } from '@atlassian/jira-issue-field-number-inline-edit/src/ui/async.tsx';
import { NumberInlineEditErrorBoundary } from '@atlassian/jira-issue-field-number-inline-edit/src/ui/error-boundary/index.tsx';
import { READ_VIEW_CONTAINER_SELECTOR as originalReadViewContainer } from '@atlassian/jira-issue-field-original-estimate/src/common/constants.tsx';
import {
	IP_BOARD_HOURS_PLANNING_UNIT,
	IP_BOARD_DAYS_PLANNING_UNIT,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import EpicStoryPoint from '@atlassian/jira-portfolio-3-plan-increment-common/src/ui/epic-story-point';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	defaultTimeTrackingOptions,
	SECONDS_PER_HOUR,
} from '@atlassian/jira-time-tracking-formatter/src/constants.tsx';
import type { TimeTrackingOptions } from '@atlassian/jira-time-tracking-formatter/src/types.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { EstimateFieldStatic } from '../../../../../../common/fields/estimate-field/static/index.tsx';
import { EstimateWrapper } from '../../../../../../common/fields/estimate-field/wrapper/index.tsx';
import { useEditableField } from '../../../../../../common/fields/use-editable-field';
import { useFireInvalidFieldConfigError } from '../../../../../../common/fields/use-fire-invalid-field-config-error';
import { timeTrackingConfigTransformer } from '../../../../../../common/utils/time-tracking';
import { PACKAGE_NAME } from '../../../../../../model/constants';
import { useBoardDispatch, useBoardSelector } from '../../../../../../state';
import { issueIncrementPlanningUpdate } from '../../../../../../state/actions/issue/update';
import { getPreventInlineEditing } from '../../../../../../state/selectors/board/board-selectors';
import { issueParentIdsSelector } from '../../../../../../state/selectors/issue-parent';
import { getPlanningUnit } from '../../../../../../state/selectors/software/software-selectors';
import { getTimeTrackingOptions } from '../../../../../../state/selectors/work/work-selectors';
import { useIsIncrementPlanningBoard } from '../../../../../../state/state-hooks/capabilities';
import { INLINE_EDITING_FIELD_ZINDEX } from '../constants';
import { STORY_POINT_WRAPPER_TEST_ID } from './constants';
import messages from './messages';
import type {
	StoryPointFieldProps,
	StoryPointFieldInnerProps,
	StorypointsEstimateWrapperProps,
} from './types';

const stopPropagation = (e: SyntheticEvent<HTMLElement>) => e.stopPropagation();

const fieldKey = 'storyPoints';

const StoryPointFieldInner = ({
	issueId,
	issueKey,
	storyPointFieldId,
	estimate,
	onFailure,
	dialogPlacement,
	...props
}: StoryPointFieldInnerProps) => {
	const isIncrementPlanningBoard = useIsIncrementPlanningBoard();

	const { formatMessage } = useIntl();
	const dispatch = useBoardDispatch();

	const timeTrackingOptions: TimeTrackingOptions = useBoardSelector((state) =>
		getTimeTrackingOptions(state),
	);

	const planningUnit = useBoardSelector((state) => getPlanningUnit(state));

	const preventInlineEditing = useBoardSelector((state) => getPreventInlineEditing(state));

	const storyPointKey = storyPointFieldId || '';

	const { fireInvalidFieldConfigError } = useFireInvalidFieldConfigError();
	const onFailureFireError = useCallback(
		(error: Error) => {
			onFailure?.();
			fireInvalidFieldConfigError(error);
		},
		[fireInvalidFieldConfigError, onFailure],
	);

	return (
		<ContextualAnalyticsData
			attributes={{
				isInlineEditing: true,
				fieldKey,
				fieldType: 'number',
			}}
		>
			<StorypointsEstimateWrapper
				data-testid={STORY_POINT_WRAPPER_TEST_ID}
				onClick={stopPropagation}
				onKeyDown={stopPropagation} // Prevent Enter from opening issue when cross or tick is focused
				hasValue={!isNil(estimate)}
				disableClick={preventInlineEditing}
				isIncrementPlanningBoard={isIncrementPlanningBoard}
			>
				<AsyncLazyNumberFieldInlineEdit
					editButtonLabel={
						fg('sea-3101-board-card-sp-estimate-button-voice')
							? formatMessage(messages.editButtonLabel, {
									storyPoints: estimate,
								})
							: undefined
					}
					{...props}
					actionSubject="inlineEdit"
					issueKey={toIssueKey(issueKey)}
					fieldKey={storyPointKey}
					analyticsFieldKeyAlias="storyPoints"
					onFailure={onFailureFireError}
					dialogPlacement={dialogPlacement}
					{...(isIncrementPlanningBoard && {
						min: 0,
						saveField: async (_: string, fieldId: string, fieldValue: number | string | null) => {
							const getFieldValue = () => {
								if (isNil(fieldValue) || fieldValue === '') {
									return fieldValue;
								}
								if (planningUnit === IP_BOARD_HOURS_PLANNING_UNIT) {
									return Number(fieldValue) * SECONDS_PER_HOUR;
								}
								if (planningUnit === IP_BOARD_DAYS_PLANNING_UNIT) {
									const workingHoursPerDay =
										timeTrackingConfigTransformer(timeTrackingOptions).hoursPerDay ||
										defaultTimeTrackingOptions.workingHoursPerDay;
									return Number(fieldValue) * workingHoursPerDay * SECONDS_PER_HOUR;
								}
								return fieldValue;
							};
							dispatch(
								issueIncrementPlanningUpdate({
									issueId,
									fieldId,
									fieldValue: getFieldValue(),
								}),
							);
							// to make ts happy
							return undefined;
						},
					})}
				/>
			</StorypointsEstimateWrapper>
		</ContextualAnalyticsData>
	);
};

export const StoryPointField = ({ shouldRenderRichField, ...props }: StoryPointFieldProps) => {
	const storyPointKey = props.storyPointFieldId || '';
	const [{ value: storyPointFieldConfig }] = useFieldConfigWithoutRefetch(
		props.issueKey,
		storyPointKey,
	);
	const shouldRenderRich = Boolean(shouldRenderRichField && storyPointFieldConfig);
	const editableField = useEditableField({
		isExperienceAvailable: shouldRenderRich,
	});

	const isIncrementPlanningBoard = useIsIncrementPlanningBoard();
	const issueParents = useBoardSelector((state) => issueParentIdsSelector(state));
	const isIssueParent = !!issueParents && issueParents.includes(`${props.issueId}`);

	if (isIncrementPlanningBoard && isIssueParent) {
		return <EpicStoryPoint value={props.estimate} />;
	}

	const fallback = props.estimate ? <EstimateFieldStatic value={props.estimate} /> : null;

	if (shouldRenderRich) {
		return (
			<NumberInlineEditErrorBoundary
				packageName={PACKAGE_NAME}
				fallback={fallback}
				onError={editableField.onError}
			>
				<UFOSegment name="ng-board.inline-edit.story-point-field">
					<StoryPointFieldInner {...props} {...editableField} />
				</UFOSegment>
			</NumberInlineEditErrorBoundary>
		);
	}

	return fallback;
};

// prevents warning for usage of style composition - can be removed when https://product-fabric.atlassian.net/browse/APP-728 is done.

const CompiledPropsForwarder = ({
	disableClick,
	isIncrementPlanningBoard,
	...rest
}: StorypointsEstimateWrapperProps & ComponentPropsWithoutRef<typeof EstimateWrapper>) => (
	<EstimateWrapper {...rest} />
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StorypointsEstimateWrapper = styled(CompiledPropsForwarder)<StorypointsEstimateWrapperProps>(
	{
		/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		[READ_VIEW_CONTAINER_SELECTOR]: {
			whiteSpace: 'nowrap',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			marginLeft: `-${gridSize * 0.25}px`,
			marginBottom: 0,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			marginTop: `-${gridSize * 0.125}px`, // reverse the margin-top added from BacklogWrapper
			marginRight: 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		height: gridSize * 3.5,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ disableClick }) =>
		disableClick &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			pointerEvents: 'none',
		}),
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		[`&:not(:has(${originalReadViewContainer}, ${READ_VIEW_CONTAINER_SELECTOR}))`]: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			zIndex: INLINE_EDITING_FIELD_ZINDEX,
		},
	},
	/**
	 * In Increment planning boards, the estimate field is displayed closer to the right edge of a card
	 * than in other boards, therefore when editing the estimate, the text field is inelegantly rendered
	 * overflowing the right edge of the card:
	 *               |
	 *           ____|___
	 *           |  13 ⇳|  <--
	 *           ￣￣|￣￣
	 * ______________|
	 *
	 * Therefore we increase the right margin to visually render the text field within the card edges.
	 */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isIncrementPlanningBoard }) =>
		isIncrementPlanningBoard &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			[`&:not(:has(${originalReadViewContainer}, ${READ_VIEW_CONTAINER_SELECTOR}))`]: {
				// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
				marginRight: '56px',
			},
		}),
);
