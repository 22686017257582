import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software.main.header.menu.release-version.title',
		defaultMessage: 'Release',
		description: 'The title text of release dropdown menu',
	},
	version: {
		id: 'software.main.header.menu.release-version.version',
		defaultMessage: 'Version',
		description: 'a software version',
	},
	tooltipRelease: {
		id: 'software.main.header.menu.release-version.tooltip-release',
		defaultMessage: 'Release a version with issues in the {columnName} column',
		description: 'The tooltip title text when the release dropdown menu enabled',
	},
	tooltipDisabled: {
		id: 'software.main.header.menu.release-version.tooltip-disabled',
		defaultMessage: 'There are no issues to be released',
		description: 'The tooltip title text when the release dropdown is disabled',
	},
	nothingToRelease: {
		id: 'software.main.header.menu.release-version.nothing-to-release',
		defaultMessage: 'To release a version, move issues to the {columnName} column',
		description: 'Text of the nothing to release menu item',
	},
	menuItemCreateVersion: {
		id: 'software.main.header.menu.release-version.menu-item-create-version',
		defaultMessage: 'Create version',
		description: 'To release issue in a new created version',
	},
	releaseSuccessFlagTitle: {
		id: 'software.main.header.menu.release-version.release-success-flag-title',
		defaultMessage: '{versionName} released',
		description: 'The title of the error flag when relase fails',
	},
	releaseFailedFlagTitle: {
		id: 'software.main.header.menu.release-version.release-failed-flag-title',
		defaultMessage: 'Something went wrong',
		description: 'The title of the error flag when relase fails',
	},
	releaseFailedFlagDescription: {
		id: 'software.main.header.menu.release-version.release-failed-flag-description',
		defaultMessage:
			'We weren’t able to complete this release. Please refresh the page and try again.',
		description: 'The description of the error flag when relase fails',
	},
});
