import type { CardId, ColumnId, SwimlaneId } from '../../types';

export type DescriptorType = 'ISSUE' | 'COLUMN';

export type Descriptor =
	| {
			type: 'ISSUE';
			id: CardId;
	  }
	| {
			type: 'COLUMN';
			id: ColumnId;
			swimlaneId: SwimlaneId;
	  };

export type Identifier = string;

export const ISSUE: DescriptorType = 'ISSUE';
export const COLUMN: DescriptorType = 'COLUMN';
