import { type MutableRefObject, useEffect, useMemo } from 'react';
import { REFRESH_SOURCE_BOARD_API } from '../../model/constants';
import { useBoardDispatch } from '../../state';
import { workRefreshData } from '../../state/actions/work';
import type { BoardRefAPI } from './types';

const BoardAPI = ({ boardApiRef }: { boardApiRef: MutableRefObject<BoardRefAPI | null> }) => {
	const dispatch = useBoardDispatch();
	const boardApi = useMemo(
		() => ({
			refresh: () => dispatch(workRefreshData(REFRESH_SOURCE_BOARD_API)),
		}),
		[dispatch],
	);
	useEffect(() => {
		// eslint-disable-next-line no-param-reassign
		boardApiRef.current = boardApi;
	}, [boardApiRef, boardApi]);

	return null;
};

export default BoardAPI;
