import React, { useMemo, useCallback, forwardRef } from 'react';
import { getIn } from 'icepick';
import isNil from 'lodash/isNil';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	swimlaneHeaderHeight,
	columnHeaderHeight,
	unscheduledColumnHeaderHeight,
} from '@atlassian/jira-platform-board-kit/src/common/constants/styles/index.tsx';
import {
	IP_BOARD_STORY_POINTS_PLANNING_UNIT,
	SWIMLANE_TEAMLESS,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import IterationColumnHeaderInner from '@atlassian/jira-portfolio-3-plan-increment-common/src/ui/iteration-column-header/index.tsx';
import SprintColumnHeaderInner from '@atlassian/jira-portfolio-3-plan-increment-common/src/ui/sprint-column-header/index.tsx';
import { getDateRangeColumn } from '../../../../../common/utils/column';
import { useBoardSelector, useBoardDispatch } from '../../../../../state';
import { updatePlannedCapacityRequest } from '../../../../../state/actions/sprints/update-planned-capacity';
import { updateIterationCapacityRequest } from '../../../../../state/actions/team';
import { isIncrementPlanningReadOnly } from '../../../../../state/selectors/board/board-permissions-selectors';
import { getColumnById } from '../../../../../state/selectors/column/column-selectors';
import {
	getIncrementConfig,
	getBoardConfig,
} from '../../../../../state/selectors/software/software-selectors';
import {
	getIterationCapacityInfo,
	getOverlappedSprintPerTeam,
	getSprintCapacityInfo,
	getTeamById,
} from '../../../../../state/selectors/team/team-selectors';
import {
	getTeamSwimlaneColumnTotalIssuesCount,
	getTeamSwimlaneColumnSprint,
} from '../../../../../state/selectors/work/work-selectors';
import { HeaderMeasurement } from './header-measurement';
import messages from './messages';
import type { IterationColumnHeaderProps, SprintColumnHeaderProps, Props } from './types';

const IterationColumnHeader = ({
	swimlaneId,
	columnId,
	planningUnit,
	workingHoursPerDay,
	capacityInfo,
	hideTotalCapacity,
	isReadOnly,
	totalIssues,
	visibleIssues,
}: IterationColumnHeaderProps) => {
	const dispatch = useBoardDispatch();

	const { totalCapacity, usedCapacity, defaultCapacity } = capacityInfo;

	const onChangeCapacity = useCallback(
		(capacity: string) => {
			dispatch(
				updateIterationCapacityRequest({
					capacity,
					planningUnit,
					workingHoursPerDay,
					iterationId: columnId,
					teamId: swimlaneId,
					totalCapacity,
					defaultCapacity,
				}),
			);
		},
		[
			dispatch,
			planningUnit,
			workingHoursPerDay,
			columnId,
			swimlaneId,
			totalCapacity,
			defaultCapacity,
		],
	);

	return (
		<IterationColumnHeaderInner
			columnId={columnId}
			totalCapacity={totalCapacity}
			usedCapacity={usedCapacity}
			defaultCapacity={defaultCapacity}
			planningConfig={{
				planningUnit,
				workingHoursPerDay,
			}}
			onChangeCapacity={onChangeCapacity}
			hideTotalCapacity={hideTotalCapacity}
			isReadOnly={isReadOnly}
			visibleIssues={visibleIssues}
			totalIssues={totalIssues}
		/>
	);
};

const SprintColumnHeader = ({
	sprint,
	swimlaneId,
	columnId,
	planningUnit,
	workingHoursPerDay,
	sprintCapacity,
	isReadOnly,
}: SprintColumnHeaderProps) => {
	const dispatch = useBoardDispatch();
	const overlappedSprintPerTeam = useBoardSelector(getOverlappedSprintPerTeam);
	const overlappedSprints = getIn(overlappedSprintPerTeam, [swimlaneId, sprint.id]);
	const column = useBoardSelector((state) => getDateRangeColumn(getColumnById(state, columnId)));

	const onChangeCapacity = useCallback(
		(capacity: string) => {
			dispatch(
				updatePlannedCapacityRequest({
					capacity,
					planningUnit,
					workingHoursPerDay,
					iterationId: sprint.id,
					teamId: swimlaneId,
					totalCapacity: sprintCapacity.totalCapacity,
					defaultCapacity: sprintCapacity.defaultCapacity,
				}),
			);
		},
		[
			dispatch,
			planningUnit,
			workingHoursPerDay,
			sprint,
			swimlaneId,
			sprintCapacity.totalCapacity,
			sprintCapacity.defaultCapacity,
		],
	);
	return (
		<SprintColumnHeaderInner
			sprint={sprint}
			totalCapacity={sprintCapacity.totalCapacity}
			usedCapacity={sprintCapacity.usedCapacity}
			doneCapacity={sprintCapacity.doneCapacity}
			defaultCapacity={sprintCapacity.defaultCapacity}
			planningConfig={{
				planningUnit,
				workingHoursPerDay,
			}}
			overlappedSprints={overlappedSprints}
			columnDateRange={column?.dateRange}
			onChangeCapacity={onChangeCapacity}
			isReadOnly={isReadOnly}
		/>
	);
};

const ProgramSwimlaneColumnHeaderComponent = ({
	count,
	columnId,
	swimlaneId,
	isUnscheduledWorkColumnPanel,
	headerRef,
}: Props) => {
	const { formatMessage } = useIntl();
	const isReadOnly = useBoardSelector(isIncrementPlanningReadOnly);
	const isExternalTeam =
		useBoardSelector((state) => getTeamById(state)(swimlaneId)?.isPlanTeam) === false;
	const isScenarioTeam =
		useBoardSelector((state) => getTeamById(state)(swimlaneId)?.isOnlyInScenario) === true;
	const sprint = useBoardSelector((state) =>
		getTeamSwimlaneColumnSprint(state, columnId, swimlaneId),
	);
	const sprintCapacityPerTeam = useBoardSelector(getSprintCapacityInfo);
	const sprintCapacity = sprint && getIn(sprintCapacityPerTeam, [swimlaneId, sprint.id]);

	const iterationCapacityPerTeam = useBoardSelector(getIterationCapacityInfo);
	const capacity = getIn(iterationCapacityPerTeam, [swimlaneId, columnId]);

	const {
		timeTrackingOptions: { workingHoursPerDay },
	} = useBoardSelector(getBoardConfig);
	const incrementConfig = useBoardSelector(getIncrementConfig);
	const total: number = useBoardSelector((state) =>
		getTeamSwimlaneColumnTotalIssuesCount(state, columnId, swimlaneId),
	);
	const visibleIssues = useMemo(
		() =>
			count !== total
				? formatMessage(messages.visibleIssueCount, { count, total })
				: formatMessage(messages.totalIssueCount, { total }),
		[count, total, formatMessage],
	);
	const planningUnit = isNil(incrementConfig?.planningUnit)
		? IP_BOARD_STORY_POINTS_PLANNING_UNIT
		: incrementConfig.planningUnit;

	if (swimlaneId === SWIMLANE_TEAMLESS || (isNil(sprint) && capacity)) {
		if (fg('do_not_show_icc_in_ip_board_when_header_overlaps')) {
			return (
				<Box
					ref={headerRef}
					xcss={
						isUnscheduledWorkColumnPanel
							? [programSwimlaneColumnHeaderOuterStyles, unscheduledColumnHeaderOuterStyles]
							: programSwimlaneColumnHeaderOuterStyles
					}
				>
					{headerRef &&
						typeof headerRef !== 'function' &&
						headerRef.current &&
						fg('dependency_visualisation_program_board_fe_and_be') && (
							<HeaderMeasurement
								columnId={columnId}
								isUnscheduledWorkColumnPanel={isUnscheduledWorkColumnPanel}
								headerRef={headerRef}
							/>
						)}
					<IterationColumnHeader
						visibleIssues={count}
						totalIssues={total}
						swimlaneId={swimlaneId}
						columnId={columnId}
						planningUnit={planningUnit}
						workingHoursPerDay={workingHoursPerDay}
						capacityInfo={capacity}
						hideTotalCapacity={isExternalTeam || swimlaneId === SWIMLANE_TEAMLESS || isScenarioTeam}
						isReadOnly={isReadOnly}
					/>
				</Box>
			);
		}
		return (
			<IterationColumnHeader
				visibleIssues={count}
				totalIssues={total}
				swimlaneId={swimlaneId}
				columnId={columnId}
				planningUnit={planningUnit}
				workingHoursPerDay={workingHoursPerDay}
				capacityInfo={capacity}
				hideTotalCapacity={isExternalTeam || swimlaneId === SWIMLANE_TEAMLESS || isScenarioTeam}
				isReadOnly={isReadOnly}
			/>
		);
	}

	if (sprint && sprintCapacity) {
		return (
			<Box
				xcss={
					isUnscheduledWorkColumnPanel
						? [programSwimlaneColumnHeaderOuterStyles, unscheduledColumnHeaderOuterStyles]
						: programSwimlaneColumnHeaderOuterStyles
				}
				ref={headerRef}
			>
				{headerRef &&
					typeof headerRef !== 'function' &&
					headerRef.current &&
					fg('dependency_visualisation_program_board_fe_and_be') && (
						<HeaderMeasurement
							columnId={columnId}
							isUnscheduledWorkColumnPanel={isUnscheduledWorkColumnPanel}
							headerRef={headerRef}
						/>
					)}
				<SprintColumnHeader
					sprint={sprint}
					swimlaneId={swimlaneId}
					columnId={columnId}
					planningUnit={planningUnit}
					workingHoursPerDay={workingHoursPerDay}
					sprintCapacity={sprintCapacity}
					isReadOnly={isReadOnly}
				/>
			</Box>
		);
	}

	return (
		<Box
			xcss={
				isUnscheduledWorkColumnPanel
					? [programSwimlaneColumnHeaderOuterStyles, unscheduledColumnHeaderOuterStyles]
					: programSwimlaneColumnHeaderOuterStyles
			}
			ref={headerRef}
		>
			{headerRef &&
				typeof headerRef !== 'function' &&
				headerRef.current &&
				fg('dependency_visualisation_program_board_fe_and_be') && (
					<HeaderMeasurement
						columnId={columnId}
						isUnscheduledWorkColumnPanel={isUnscheduledWorkColumnPanel}
						headerRef={headerRef}
					/>
				)}
			<Box xcss={[columnHeaderWrapper, storyPointCapacityAndIssueCountStyles]}>
				<Box xcss={issueCountStyles}>{visibleIssues}</Box>
			</Box>
		</Box>
	);
};

export const ProgramSwimlaneColumnHeader = componentWithFG(
	'do_not_show_icc_in_ip_board_when_header_overlaps',
	forwardRef<HTMLDivElement, Omit<Props, 'headerRef'>>((props, ref) => (
		<ProgramSwimlaneColumnHeaderComponent {...props} headerRef={ref} />
	)),
	ProgramSwimlaneColumnHeaderComponent,
);

// main board columns header height, plus swimlane header height,
// minus the 8px gutter which gets removed from the top of the swimlane header on scroll
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const stickyOffset = `calc(${swimlaneHeaderHeight + columnHeaderHeight}px - ${token(
	'space.100',
	'8px',
)})`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const unscheduledColumnStickyOffset = `calc(${unscheduledColumnHeaderHeight + columnHeaderHeight}px - ${token(
	'space.100',
	'8px',
)})`;

const programSwimlaneColumnHeaderOuterStyles = xcss({
	position: 'sticky',
	backgroundColor: 'elevation.surface',
	paddingTop: 'space.075',
	// zIndex of 1 is required to ensure the column header is above the column and below the swimlane header
	// @ts-expect-error ts(2322) Type '"1"' is not assignable to type '"card" | "navigation" | "dialog" | "layer" | "blanket" | "modal" | "flag" | "spotlight" | "tooltip" | undefined'
	zIndex: '1',
	top: `${stickyOffset}`,
});

const unscheduledColumnHeaderOuterStyles = xcss({
	top: `${unscheduledColumnStickyOffset}`,
});

const columnHeaderWrapper = xcss({
	backgroundColor: 'elevation.surface.sunken',
	borderTopLeftRadius: 'border.radius.100',
	borderTopRightRadius: 'border.radius.100',
	paddingBlock: 'space.050',
});

const storyPointCapacityAndIssueCountStyles = xcss({
	paddingInline: 'space.200',
});

const issueCountStyles = xcss({
	color: 'color.text.subtlest',
	paddingTop: 'space.075',
	paddingBottom: 'space.075',
	height: `${token('space.400', '32px')}`,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

export default ProgramSwimlaneColumnHeader;
