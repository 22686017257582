import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';

export const COMPLETE_SPRINT_CROSS_FLOW_EXPERIMENT_KEY = 'jsw_sprint_completion_retro_cross_flow';
export const COMPLETE_SPRINT_CROSS_JOIN_EXPERIMENT_KEY =
	'conf_jira_sprint_completion_cross_join_exp';
export const COHORT = 'cohort';
export const NOT_ENROLLED_COHORT = 'not-enrolled';
export const EXPERIMENT_COHORT = 'experiment';
export const CONTROL_COHORT = 'control';

export const isCompleteSprintCrossFlowEnabled = () => {
	const [completeSprintCrossFlowConfig] = UNSAFE_noExposureExp(
		'jsw_sprint_completion_retro_cross_flow',
	);

	return (
		ff('sprint-completion-cross-flow') &&
		completeSprintCrossFlowConfig.get(COHORT, NOT_ENROLLED_COHORT) === EXPERIMENT_COHORT
	);
};

export const fireCompleteSprintCrossFlowExposureEventWhenEnrolled = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
) => {
	const [completeSprintCrossFlowConfig, completeSprintCrossFlowFireExposure] = UNSAFE_noExposureExp(
		'jsw_sprint_completion_retro_cross_flow',
	);
	const completeSprintCrossFlowExperimentValue = completeSprintCrossFlowConfig.get(
		COHORT,
		NOT_ENROLLED_COHORT,
	);

	if (
		ff('sprint-completion-cross-flow') &&
		completeSprintCrossFlowExperimentValue !== NOT_ENROLLED_COHORT
	) {
		completeSprintCrossFlowFireExposure();

		// manually fire for Socrates
		fireTrackAnalytics(createAnalyticsEvent({}), 'feature exposed', {
			flagKey: COMPLETE_SPRINT_CROSS_FLOW_EXPERIMENT_KEY,
			value: completeSprintCrossFlowExperimentValue,
			growthInitiative: 'cross-flow',
		});
	}
};

export const isCompleteSprintCrossJoinEnabled = () => {
	const [completeSprintCrossJoinConfig] = UNSAFE_noExposureExp(
		'conf_jira_sprint_completion_cross_join_exp',
	);

	return completeSprintCrossJoinConfig.get(COHORT, NOT_ENROLLED_COHORT) === EXPERIMENT_COHORT;
};

export const fireCompleteSprintCrossJoinExposureEventWhenEnrolled = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
) => {
	const [completeSprintCrossJoinConfig, completeSprintCrossJoinFireExposure] = UNSAFE_noExposureExp(
		'conf_jira_sprint_completion_cross_join_exp',
	);
	const completeSprintCrossJoinExperimentValue = completeSprintCrossJoinConfig.get(
		COHORT,
		NOT_ENROLLED_COHORT,
	);

	if (completeSprintCrossJoinExperimentValue !== NOT_ENROLLED_COHORT) {
		completeSprintCrossJoinFireExposure();

		// manually fire for Socrates
		fireTrackAnalytics(createAnalyticsEvent({}), 'feature exposed', {
			flagKey: COMPLETE_SPRINT_CROSS_JOIN_EXPERIMENT_KEY,
			value: completeSprintCrossJoinExperimentValue,
			growthInitiative: 'cross-join',
		});
	}
};
