import log from '@atlassian/jira-common-util-logging/src/log';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

export const addBoardToHistory = (baseUrl: string, boardId: string) =>
	performPostRequest(`${baseUrl}/rest/greenhopper/1.0/rapidview/addtohistory`, {
		body: JSON.stringify({ rapidViewId: boardId }),
	}).catch((e) => {
		log.safeErrorWithoutCustomerData(
			'software.view-history.src.services',
			'Cannot add board to recent history',
			e,
		);
	});
