import React, { type ChangeEvent, type Ref } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import LinkItem from '../link-item';
import PanelToggle from '../panel-toggle';

type Props = {
	isPanelToggleChecked: boolean;
	showLink: boolean;
	showPanelToggle: boolean;
	useHrefForUrl: boolean;
	url: string | undefined;
	label: string;
	toggleLabel?: string;
	linkTitle: string | undefined;
	onTogglePanel: () => void;
	onLinkItemClick: () => void;
	toggleRef: Ref<HTMLInputElement>;
};

const FilterListFooter = ({
	isPanelToggleChecked,
	showLink,
	showPanelToggle,
	useHrefForUrl,
	url,
	label,
	toggleLabel,
	linkTitle,
	toggleRef,
	onTogglePanel,
	onLinkItemClick,
}: Props) => {
	const onToggleHandler = (_: ChangeEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent);
		onTogglePanel();
	};
	return (
		<>
			{(showPanelToggle || (showLink && url && linkTitle)) && <HorizontalSeparator />}
			{showPanelToggle && (
				<PanelToggleContainer data-testid="filters.common.ui.list.footer.panel-toggle">
					<PanelToggle
						label={toggleLabel || label}
						panelType={label}
						isChecked={isPanelToggleChecked}
						toggleRef={toggleRef}
						onToggle={onToggleHandler}
					/>
				</PanelToggleContainer>
			)}

			{showLink && url && linkTitle && (
				<LinkItem
					url={url}
					linkTitle={linkTitle}
					onClick={onLinkItemClick}
					useHref={useHrefForUrl}
				/>
			)}
		</>
	);
};

FilterListFooter.defaultProps = {
	url: undefined,
	linkTitle: undefined,
	showPanel: false,
	showLink: false,
	toggleRef: noop,
};

export default FilterListFooter;
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export { LinkItem, PanelToggle };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HorizontalSeparator = styled.hr({
	border: 'none',
	height: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral.hovered', colors.N30),
	margin: '0px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelToggleContainer = styled.div({
	padding: `6px ${token('space.200', '16px')}`,
	display: 'flex',
	flexDirection: 'column',
});
