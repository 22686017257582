import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import {
	FETCH_ISSUE_LINKS_STATS,
	setIssueLinksStatsLoading,
} from '../../state/actions/issue-links-stats';
import type { Action, ActionsObservable } from '../../state/types';

export const getIsIssueLinksStatsLoadingEpic = (action$: ActionsObservable): Observable<Action> =>
	action$.ofType(FETCH_ISSUE_LINKS_STATS).switchMap((action) => {
		const payloadIssueIds = action.type === FETCH_ISSUE_LINKS_STATS && action.payload;

		if (payloadIssueIds) {
			return Observable.of(setIssueLinksStatsLoading(payloadIssueIds));
		}

		return Observable.of();
	});
