import { freeze, map, merge, set, unset, setIn } from 'icepick';
import clone from 'lodash/clone';
import isNil from 'lodash/isNil';
import { ColumnType } from '@atlassian/jira-common-constants/src/column-types';
import { getStatusColumn } from '../../../../common/utils/column';
import type { StatusColumn } from '../../../../model/column/column-types';
import { entityArrayToMap } from '../../../../services/software/software-data-transformer';
import type { Action } from '../../../actions';
import { COLUMN_CREATE_REQUEST, COLUMN_CREATE_SUCCESS } from '../../../actions/column/create';
import { COLUMN_DELETE_REQUEST, COLUMN_DELETE_SUCCESS } from '../../../actions/column/delete';
import { COLUMN_SET_LIMITS_REQUEST } from '../../../actions/column/limits';
import { COLUMN_RENAME_REQUEST, COLUMN_RENAME_SUCCESS } from '../../../actions/column/rename';
import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../actions/work';
import type { ColumnsState } from './types';

export const columnsReducer = (state: ColumnsState = freeze({}), action: Action): ColumnsState => {
	if (
		(action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) &&
		action.payload.columns
	) {
		return freeze(entityArrayToMap(action.payload.columns));
	}

	if (action.type === COLUMN_RENAME_REQUEST) {
		const { columnId, name } = action.payload;
		return setIn(state, [columnId, 'name'], name);
	}

	if (action.type === COLUMN_DELETE_REQUEST) {
		const { columnId } = action.payload;
		return unset(state, columnId);
	}

	if (action.type === COLUMN_RENAME_SUCCESS) {
		const { columnId, statuses } = action.payload;
		const newStatuses = [...statuses];
		return setIn(state, [columnId, 'statuses'], newStatuses);
	}

	if (action.type === COLUMN_DELETE_SUCCESS || action.type === COLUMN_CREATE_SUCCESS) {
		let newState = clone(state);

		if (action.type === COLUMN_CREATE_SUCCESS) {
			const { temporaryColumnId, column } = action.payload;
			newState = unset(newState, temporaryColumnId);
			newState = set(newState, column.id, column);
		}

		action.payload.columns
			.filter(
				(column): column is StatusColumn =>
					!isNil(newState[column.id]) && column.type === ColumnType.STATUS,
			)
			.forEach((column) => {
				newState = setIn(
					newState,
					[column.id, 'statuses'],
					map(
						(status) => ({
							...status,
							category: column.statuses[0].category,
							isInitial: column.statuses[0].isInitial,
							isDone: column.statuses[0].isDone,
						}),
						getStatusColumn(newState[column.id])?.statuses ?? [],
					),
				);
			});

		return newState;
	}

	if (action.type === COLUMN_CREATE_REQUEST) {
		const { temporaryColumnId: id, name } = action.payload;
		const newState = merge(state, {
			[id]: {
				id,
				name,
				statuses: [
					{
						id,
						name,
						isInitial: false,
						isDone: false,
					},
				],
				maxIssueCount: null,
			},
		});
		return newState;
	}

	if (action.type === COLUMN_SET_LIMITS_REQUEST) {
		const { columnId, maxIssueCount, minIssueCount } = action.payload;
		const newState = merge(state, {
			[columnId]: {
				maxIssueCount,
				minIssueCount,
			},
		});
		return newState;
	}

	return state;
};
