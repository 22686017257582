/*!
 * `ServiceContext` should be the first parameter of all service functions.
 *
 * Its purpouse is to have a pattern for switching between CMP/TMP
 * implementations of service calls.
 */
import type { ProjectType } from '@atlassian/jira-common-constants';
import type { State } from '../state/reducers/types';
import { rapidViewIdSelector } from '../state/selectors/software/software-selectors';

/**
 * This is a shared context that should be fed into all services as the first
 * parameter
 *
 * @example
 *
 *     const ctx = makeServiceContext(state);
 *     const observable = runMyService(ctx);
 *
 */
export interface ServiceContext {
	boardId: string;
	projectType: ProjectType;
	contextPath: string;
	isCMPBoard: boolean;
	/**
	 * ARJ Increment Planning Board
	 */
	isIncrementPlanningBoard: boolean;
	planId?: string;
	scenarioId?: string;
}

/**
 * Build a ServiceContext from the redux state.
 */
export function makeServiceContext(state: State): ServiceContext {
	return {
		boardId: rapidViewIdSelector(state),
		contextPath: state.configuration.contextPath,
		isCMPBoard: state.configuration.isCMPBoard,
		projectType: state.configuration.projectType,
		/**
		 * ARJ Increment Planning Board
		 */
		isIncrementPlanningBoard: state.configuration.isIncrementPlanningBoard,
		planId: state.configuration.planId,
		scenarioId: state.configuration.scenarioId,
	};
}
