import React, { useCallback, useContext } from 'react';
import Button from '@atlaskit/button/new';
import Tooltip from '@atlaskit/tooltip';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger';
import { useIntl } from '@atlassian/jira-intl';
import { useIsFullscreen } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/fullscreen/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	fireUIAnalytics,
	FireScreenAnalytics,
	SCREEN,
	type AnalyticsEvent,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import { View } from '@atlassian/jira-software-view-settings/src/common/types/constant.tsx';
import { useViewSettingsActions } from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import type { StandupActions } from '../../../common/types';
import { StandupEntryPointContext } from '../../../controllers/context-provider';
import messages from './messages';

type StartStandupButtonProps = StandupActions & { isStandupDisabled: boolean; boardId: number };

export const StartStandupButton = ({
	setIsActiveStandup,
	isStandupDisabled,
	boardId,
}: StartStandupButtonProps) => {
	const { formatMessage } = useIntl();
	const { entryPointActions } = useContext(StandupEntryPointContext);
	const triggerRef = useEntryPointButtonTrigger(entryPointActions);
	const { setIsFullscreen: setIsFullscreenNav3 } = useViewSettingsActions();
	const [, { setIsFullscreen }] = useIsFullscreen();

	const enterFullscreen = useCallback(() => {
		getWillShowNav4()
			? setIsFullscreen(true)
			: setIsFullscreenNav3({ boardId, isFullscreen: true, view: View.BOARD });
	}, [boardId, setIsFullscreen, setIsFullscreenNav3]);

	const handleStartStandup = (analyticsEvent: AnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'startStandup');
		enterFullscreen();
		setIsActiveStandup(true);
	};

	return (
		<ContextualAnalyticsData sourceName="jiraStandupsStartStandupButton" sourceType={SCREEN}>
			<Tooltip content={isStandupDisabled ? formatMessage(messages.disabledButtonTooltip) : null}>
				<Button
					isDisabled={isStandupDisabled}
					ref={triggerRef}
					onClick={(__, analyticsEvent: AnalyticsEvent) => handleStartStandup(analyticsEvent)}
				>
					{formatMessage(messages.startStandupButton)}
				</Button>
			</Tooltip>
			<FireScreenAnalytics attributes={{ isStandupDisabled }} />
		</ContextualAnalyticsData>
	);
};
