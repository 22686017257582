import { NO_SWIMLANE } from '../../../../model/swimlane/swimlane-modes';
import type { State } from '../../../reducers/types';
import { getSwimlaneMode } from '../../../selectors/swimlane/swimlane-mode-selectors';
import { getSwimlanes } from '../../../selectors/swimlane/swimlane-selectors';

export const swimlaneModeChanged = (eventPrefix: string) => (preState: State, state: State) => {
	const hasSwimlanes = getSwimlaneMode(state) !== NO_SWIMLANE.id;
	return {
		name: `${eventPrefix}.swimlane.mode.changed`,
		data: {
			hasSwimlanes,
			numSwimlanes: hasSwimlanes ? getSwimlanes(state).length : 0,
		},
	};
};
