import React, {
	type ComponentType,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	// eslint-disable-next-line jira/restricted/react
	PureComponent,
	type ReactElement,
} from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '@atlassian/jira-software-board-css-module/src/column-header.module.css';
import { useBoardHasScrollTop } from '../../../controllers/board-scroll';
import { zIndex } from '../../constants/styles';
import {
	type ValidateColumnCallback,
	type ColumnId,
	type ColumnLozengeValues,
	ColumnTheme,
} from '../../types';
import { isStickySupported } from '../../utils/is-sticky-supported';
import { Overlay } from '../overlay';
import ColumnTitleLozenges from './editable-title/column-title/lozanges/index.tsx';
import ColumnEditableTitle from './editable-title/main.tsx';
import ColumnHeaderV2 from './header';

export type Lozenges = (string | ColumnLozengeValues)[];

type ColumnHeaderV2Props = ComponentProps<typeof ColumnHeaderV2>;
export type ColumnMenu = ColumnHeaderV2Props['menu'];

export type Props = {
	isShadowVisible?: boolean;
	isRenamable?: boolean;
	isDraggable: boolean;
	isMenuShown?: boolean | null;
	// injected by column
	isUpdating?: boolean;
	isDisabled?: boolean;
	isDragging?: boolean;
	isDone: boolean;
	isCMPBoard: boolean;
	shouldRemoveSpaceForMenu?: boolean;
	isFlexibleColumns?: boolean | null;
	id: ColumnId;
	title: string;
	lozenges?: Lozenges | null | undefined;
	cardsCount?: number;
	totalCardsCount?: number;
	showTotalCardsCount?: boolean;
	validateRename?: ValidateColumnCallback;
	appearance?: ColumnTheme;
	menu?: ColumnMenu;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	OverlayContent?: ComponentType<any> | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onRename: (id: ColumnId, name: string) => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onEditStart?: () => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onEditCancel?: (columnId: ColumnId, type: string) => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onHeaderClick?: () => any;
};

type State = {
	isEditingTitle: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export class ColumnHeader extends PureComponent<Props, State> {
	static defaultProps = {
		isRenamable: true,
		isShadowVisible: false,
		isDragging: false,
		isUpdating: false,
		isDisabled: false,
		isDone: false,
		cardsCount: 0,
		totalCardsCount: 0,
		showTotalCardsCount: false,
		lozenges: null,
		validateRename: () => ({
			isValid: true,
			message: null,
		}),
		appearance: 'default',
		isCMPboard: false,
		isFlexibleColumns: false,
		shouldRemoveSpaceForMenu: false,
	};

	state = {
		isEditingTitle: false,
	};

	onColumnTitleEditChange = (isEditingTitle: boolean) => {
		this.setState({
			isEditingTitle,
		});

		if (isEditingTitle && this.props.onEditStart) {
			this.props.onEditStart();
		}
	};

	onColumnTitleEditCancel = (columnId: ColumnId, type: string) => {
		this.props.onEditCancel && this.props.onEditCancel(columnId, type);
	};

	onHeaderClick = () => {
		this.props.onHeaderClick && this.props.onHeaderClick();
	};

	getColumnLozenges(
		lozenges?: Lozenges | null,
	): ReactElement<ComponentProps<typeof ColumnTitleLozenges>> | null {
		if (!lozenges || lozenges.length === 0) {
			return null;
		}
		return <ColumnTitleLozenges lozenges={lozenges} appearance={this.props.appearance} />;
	}

	render() {
		const {
			id,
			title,
			lozenges,
			isShadowVisible,
			isUpdating,
			isDisabled,
			isDragging,
			isRenamable,
			isDraggable,
			cardsCount,
			totalCardsCount,
			showTotalCardsCount,
			validateRename,
			menu,
			OverlayContent,
			isMenuShown,
			isDone,
			isCMPBoard,
			shouldRemoveSpaceForMenu,
			isFlexibleColumns,
		} = this.props;

		const { isEditingTitle } = this.state;

		const isTitleDisabled = isDisabled || isDragging || !isRenamable;

		return (
			<ColumnHeaderWrapper
				isDragging={isDragging}
				isShadowVisible={isShadowVisible}
				isFlexibleColumns={isFlexibleColumns}
			>
				<ColumnHeaderV2
					id={id}
					title={
						<ColumnEditableTitle
							columnId={id}
							text={title}
							visibleCount={cardsCount || 0}
							totalCount={totalCardsCount}
							showTotalCount={showTotalCardsCount}
							isDisabled={isTitleDisabled}
							onEditChange={this.onColumnTitleEditChange}
							onConfirm={this.props.onRename}
							onCancel={this.onColumnTitleEditCancel}
							lozenges={this.getColumnLozenges(lozenges)}
							// @ts-expect-error - Type 'ValidateColumnCallback | undefined' is not assignable to type '(() => { isValid: boolean; message: null; }) | undefined'.
							validateColumn={validateRename}
							isDone={isDone}
							isCMPBoard={isCMPBoard}
							shouldRemoveSpaceForMenu={shouldRemoveSpaceForMenu}
						/>
					}
					titleHeadingText={title}
					isEditingTitle={isEditingTitle}
					isMenuShown={isMenuShown}
					isSpinnerVisible={isUpdating}
					isDragging={isDragging}
					isDraggable={isDraggable}
					menu={menu}
					onClick={this.onHeaderClick}
					OverlayContent={OverlayContent}
					appearance={this.props.appearance}
				/>
				{(isUpdating || isDisabled) && (
					<Overlay appearance={this.props.appearance ?? ColumnTheme.Default} />
				)}
			</ColumnHeaderWrapper>
		);
	}
}

export type OwnProps = {
	isRenamable?: boolean;
	isDraggable: boolean;
	isMenuShown?: boolean | null;
	// injected by column
	isUpdating?: boolean;
	isDisabled?: boolean;
	isDragging?: boolean;
	isDone?: boolean;
	isCMPBoard: boolean;
	shouldRemoveSpaceForMenu?: boolean;
	isFlexibleColumns?: boolean | null;
	id: ColumnId;
	title: string;
	lozenges?: Lozenges | null | undefined;
	cardsCount?: number;
	totalCardsCount?: number;
	showTotalCardsCount?: boolean;
	validateRename?: ValidateColumnCallback;
	appearance?: ColumnTheme;
	menu?: ColumnMenu;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	OverlayContent?: ComponentType<any> | undefined;
	showShadowOnScroll: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onRename: (id: ColumnId, name: string) => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onEditStart?: () => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onEditCancel?: (columnId: ColumnId, type: string) => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onHeaderClick?: () => any;
};

const ColumnHeaderMain = ({ showShadowOnScroll, ...props }: OwnProps) => {
	const [hasScrollTop] = useBoardHasScrollTop();

	return <ColumnHeader {...props} isShadowVisible={showShadowOnScroll && hasScrollTop} />;
};

export default ColumnHeaderMain;

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const ColumnHeaderContainerBaseControl = styled.div<any>((props) => ({
	height: 'unset',
	display: 'flex',
	flexFlow: 'column nowrap',
	justifyContent: 'flex-start',
	alignItems: 'stretch',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.stickyHeaders,
	container:
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		props.isFlexibleColumns
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`${styles['column-header']} / inline-size`
			: undefined,
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getColumnHeaderContainerBackground = (isDragging: any) => {
	if (isDragging) return 'none';
	return token('color.background.neutral', colors.N0);
};

const ColumnHeaderWrapperControl = isStickySupported
	? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
		styled(ColumnHeaderContainerBaseControl)((props) => ({
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			position: props.isDragging ? 'relative' : 'sticky',
			/*
            When column has scroll top < 0, column content is displayed underneath header,
            and as column content has grey background, rounded corners are gone.
            background-color: white is used to overlay column content => keep header corners rounded.
          */
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			background: getColumnHeaderContainerBackground(props.isDragging),
			top: 0,
			borderRadius: '6px',
			'&::before': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				opacity: props.isShadowVisible ? 0.2 : 0,
				transition: 'opacity 0.2s',
				content: "''",
				position: 'absolute',
				bottom: token('space.negative.025', '-2px'),
				left: 0,
				right: 0,
				height: '2px',
				pointerEvents: 'none',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				backgroundColor: token('color.blanket', colors.N80),
			},
		}))
	: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
		styled(ColumnHeaderContainerBaseControl)({
			position: 'relative',
			borderRadius: '6px',
		});

type ColumnHeaderWrapper = {
	isDragging: boolean;
	isShadowVisible: boolean;
	isFlexibleColumns: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnHeaderWrapperBaseExperiment = styled2.div<ColumnHeaderWrapper>({
	height: 'unset',
	display: 'flex',
	flexFlow: 'column nowrap',
	justifyContent: 'flex-start',
	alignItems: 'stretch',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.stickyHeaders,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	container: (props: ColumnHeaderWrapper) =>
		props.isFlexibleColumns
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`${styles['column-header']} / inline-size`
			: '',
});

const ColumnHeaderWrapperExperiment = isStickySupported
	? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
		styled2(ColumnHeaderWrapperBaseExperiment)({
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			position: (props: ColumnHeaderWrapper) => (props.isDragging ? 'relative' : 'sticky'),
			/*
            When column has scroll top < 0, column content is displayed underneath header,
            and as column content has grey background, rounded corners are gone.
            background-color: white is used to overlay column content => keep header corners rounded.
          */
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			background: (props: ColumnHeaderWrapper) =>
				getColumnHeaderContainerBackground(props.isDragging),
			top: 0,
			borderRadius: '6px',
			'&::before': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
				opacity: (props: ColumnHeaderWrapper) => (props.isShadowVisible ? 0.2 : 0),
				transition: 'opacity 0.2s',
				content: '',
				position: 'absolute',
				bottom: token('space.negative.025', '-2px'),
				left: 0,
				right: 0,
				height: '2px',
				pointerEvents: 'none',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				backgroundColor: token('color.blanket', colors.N80),
			},
		})
	: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
		styled2(ColumnHeaderWrapperBaseExperiment)({
			position: 'relative',
			borderRadius: '6px',
		});

export const ColumnHeaderWrapper = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	ColumnHeaderWrapperExperiment,
	ColumnHeaderWrapperControl,
);
