import type { SwimlaneModeId } from '@atlassian/jira-platform-board-kit/src/ui/swimlane/types.tsx';
import type { OptimisticId } from '../../../model/software/software-types';
import type { SwimlaneId } from '../../../model/swimlane/swimlane-types';
import type { JQLSwimlaneData } from '../../../model/work/work-types';
import flagMessages from '../../../view/flags/messages';
import { type FlagMeta, errorFlagMeta } from '../meta/flag-meta';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiWithFlagMeta,
	type OptimisticUiMeta,
} from '../meta/optimistic-ui-meta';

export const SWIMLANE_JQL_SET_DATA = 'state.actions.swimlane.SWIMLANE_JQL_SET_DATA' as const;

export type SwimlaneJqlSetDataAction = {
	type: typeof SWIMLANE_JQL_SET_DATA;
	payload: JQLSwimlaneData[];
};

export const swimlaneJqlSetDataRequest = (
	swimlanes: JQLSwimlaneData[],
): SwimlaneJqlSetDataAction => ({
	type: SWIMLANE_JQL_SET_DATA,
	payload: swimlanes,
});

export const SWIMLANE_MODE_CHANGE_REQUEST =
	'state.actions.swimlane.SWIMLANE_MODE_CHANGE_REQUEST' as const;

export type SwimlaneModeChangeRequestAction = {
	type: typeof SWIMLANE_MODE_CHANGE_REQUEST;
	payload: SwimlaneModeId;
	meta: OptimisticUiMeta;
};

export const swimlaneModeChangeRequest = (
	mode: SwimlaneModeId,
): SwimlaneModeChangeRequestAction => ({
	type: SWIMLANE_MODE_CHANGE_REQUEST,
	payload: mode,
	meta: beginOptimisticUiMeta(),
});

export const SWIMLANE_MODE_CHANGE_SUCCESS =
	'state.actions.swimlane.SWIMLANE_MODE_CHANGE_SUCCESS' as const;

export type SwimlaneModeChangeSuccessAction = {
	type: typeof SWIMLANE_MODE_CHANGE_SUCCESS;
	meta: OptimisticUiMeta;
};

export const swimlaneModeChangeSuccess = (
	optimisticId: OptimisticId,
): SwimlaneModeChangeSuccessAction => ({
	type: SWIMLANE_MODE_CHANGE_SUCCESS,
	meta: commitOptimisticUiMeta(optimisticId),
});

export const SWIMLANE_MODE_CHANGE_FAILURE =
	'state.actions.swimlane.SWIMLANE_MODE_CHANGE_FAILURE' as const;

export type SwimlaneModeChangeFailureAction = {
	type: typeof SWIMLANE_MODE_CHANGE_FAILURE;
	meta: OptimisticUiMeta & FlagMeta;
};

export const swimlaneModeChangeFailure = (
	optimisticId: OptimisticId,
): SwimlaneModeChangeFailureAction => ({
	type: SWIMLANE_MODE_CHANGE_FAILURE,
	meta: revertOptimisticUiWithFlagMeta(optimisticId),
});

export const SWIMLANE_TOGGLE = 'state.actions.swimlane.SWIMLANE_TOGGLE' as const;

export type SwimlaneToggleAction = {
	type: typeof SWIMLANE_TOGGLE;
	payload: SwimlaneId;
};

export const swimlaneToggle = (id: SwimlaneId): SwimlaneToggleAction => ({
	type: SWIMLANE_TOGGLE,
	payload: id,
});

export const SWIMLANES_TOGGLE = 'state.actions.swimlane.SWIMLANES_TOGGLE' as const;

export type SwimlanesToggleAction = {
	type: typeof SWIMLANES_TOGGLE;
	payload: SwimlaneId[];
};

export const swimlanesToggle = (ids: SwimlaneId[]): SwimlanesToggleAction => ({
	type: SWIMLANES_TOGGLE,
	payload: ids,
});

export const SWIMLANES_COLLAPSE = 'state.actions.swimlane.SWIMLANES_COLLAPSE' as const;

export type SwimlanesCollapseAction = {
	type: typeof SWIMLANES_COLLAPSE;
	payload: SwimlaneId[];
};

export const swimlanesCollapse = (ids: SwimlaneId[]): SwimlanesCollapseAction => ({
	type: SWIMLANES_COLLAPSE,
	payload: ids,
});

export const SWIMLANES_EXPAND = 'state.actions.swimlane.SWIMLANES_EXPAND' as const;

export type SwimlanesExpandAction = {
	type: typeof SWIMLANES_EXPAND;
};

export const swimlanesExpand = (): SwimlanesExpandAction => ({
	type: SWIMLANES_EXPAND,
});

export const TEAM_SWIMLANE_INVALID_MOVE = 'state.actions.swimlane.TEAM_SWIMLANE_INVALID_MOVE';

export type TeamSwimLaneInvalidMoveAction = {
	type: typeof TEAM_SWIMLANE_INVALID_MOVE;
	meta: FlagMeta;
};

export const teamSwimLaneInvalidMove = (teamName: string): TeamSwimLaneInvalidMoveAction => ({
	type: TEAM_SWIMLANE_INVALID_MOVE,
	meta: errorFlagMeta({
		id: TEAM_SWIMLANE_INVALID_MOVE,
		context: {
			teamName,
		},
		titleMessage: flagMessages.teamSwimlaneMoveInvalidTitle,
		descriptionMessage: flagMessages.teamSwimlaneMoveInvalidMessage,
	}),
});

export const SWIMLANE_COLUMN_CLOSED_SPRINT_INVALID_MOVE =
	'state.actions.swimlane.SWIMLANE_COLUMN_CLOSED_SPRINT_INVALID_MOVE';

export type SwimlaneColumnClosedSprintMoveAction = {
	type: typeof SWIMLANE_COLUMN_CLOSED_SPRINT_INVALID_MOVE;
	meta: FlagMeta;
};

export const swimlaneColumnClosedSprintInvalidMove = (
	sprintName: string,
): SwimlaneColumnClosedSprintMoveAction => ({
	type: SWIMLANE_COLUMN_CLOSED_SPRINT_INVALID_MOVE,
	meta: errorFlagMeta({
		id: SWIMLANE_COLUMN_CLOSED_SPRINT_INVALID_MOVE,
		context: {
			sprintName,
		},
		titleMessage: flagMessages.swimlaneColumnClosedSprintInvalidMoveTitle,
		descriptionMessage: flagMessages.swimlaneColumnClosedSprintInvalidMoveMessage,
	}),
});

export type Action =
	| SwimlaneToggleAction
	| SwimlanesToggleAction
	| SwimlanesCollapseAction
	| SwimlanesExpandAction
	| SwimlaneJqlSetDataAction
	| SwimlaneModeChangeRequestAction
	| SwimlaneModeChangeSuccessAction
	| SwimlaneModeChangeFailureAction
	| TeamSwimLaneInvalidMoveAction
	| SwimlaneColumnClosedSprintMoveAction;
