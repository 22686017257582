import type { Extension } from '../../../model/extension/types';

export const EXTENSION_FETCH_SUCCESS = 'state.actions.extension.EXTENSION_FETCH_SUCCESS' as const;

export type ExtensionFetchAction = {
	type: typeof EXTENSION_FETCH_SUCCESS;
	payload: {
		extension: Extension;
	};
};

export const extensionFetchSuccess = (extension: Extension): ExtensionFetchAction => ({
	type: EXTENSION_FETCH_SUCCESS,
	payload: {
		extension,
	},
});

export type ExtensionAction = ExtensionFetchAction;
