import { chain, freeze, setIn } from 'icepick';
import { entityArrayToMap } from '../../../../services/software/software-data-transformer';
import { BOARD_DEFERRED_DATA_LOAD_SUCCESS } from '../../../actions/board/board-deferred-data';
import { EDIT_SPRINT_REQUEST, EDIT_SPRINT_SUCCESS } from '../../../actions/sprints/edit';
import { WORK_DATA_CRITICAL_SET, WORK_DATA_SET } from '../../../actions/work';
import type { Action } from '../../../types';
import type { SprintsState } from './types';

export const sprintsReducer = (state: SprintsState = freeze({}), action: Action): SprintsState => {
	if (
		(action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) &&
		action.payload.sprints
	) {
		return freeze(entityArrayToMap(action.payload.sprints));
	}

	if (action.type === EDIT_SPRINT_REQUEST) {
		const { id, name, goal, startDate, endDate } = action.payload;
		const newState = chain(state)
			.setIn([id, 'name'], name)
			.setIn([id, 'goal'], goal)
			.setIn([id, 'startDate'], startDate)
			.setIn([id, 'endDate'], endDate)
			.value();

		return newState;
	}

	if (action.type === EDIT_SPRINT_SUCCESS) {
		const { id, daysRemaining } = action.payload;
		return setIn(state, [id, 'daysRemaining'], daysRemaining);
	}

	if (action.type === BOARD_DEFERRED_DATA_LOAD_SUCCESS) {
		const { sprints } = action.payload;
		if (sprints) {
			return freeze(entityArrayToMap(sprints));
		}
	}

	return state;
};
