import type { OptimisticState } from 'redux-optimistic-ui';
import countBy from 'lodash/countBy';
import get from 'lodash/get';
import sum from 'lodash/sum';
import truncate from 'lodash/truncate';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { getNormalisedPerformanceFFs } from '@atlassian/jira-common-long-task-metrics/src/common/util/collectors';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { getFieldLengthForAnalytics } from '@atlassian/jira-platform-board-kit/src/common/utils/field-length-for-analytics/index.tsx';
import {
	fireTrackAnalytics,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { values } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common';
import { getBoardDetailViewMode } from '@atlassian/jira-software-board-common/src';
import { isUserNetworkError } from '@atlassian/jira-software-sla-tracker/src/services/response-to-sla/index.tsx';
import { extractStatusCode } from '@atlassian/jira-software-sla-tracker/src/services/status-code/index.tsx';
import { isSyntheticTenant } from '../../../../feature-flags';
import { TEXT, ASSIGNEE, LABEL } from '../../../../model/filter/filter-types';
import { NO_SWIMLANE } from '../../../../model/swimlane/swimlane-modes';
import { getBoardExtraData } from '../../../../services/performance/performance-board-data-reporter';
import { issueDeleteInteraction } from '../../../../services/utils/performance-analytics';
import type {
	IssueCreateRequestAction,
	IssueCreateSuccessAction,
	IssueCreateFailureAction,
} from '../../../actions/issue/create';
import type {
	IssueDeleteSuccessAction,
	IssueDeleteFailureAction,
} from '../../../actions/issue/delete';
import type { DevStatusLoadSuccessAction } from '../../../actions/issue/dev-status';
import type { OpenIssueModalAction } from '../../../actions/issue/modal';
import type {
	IssueMoveToBacklogRequestAction,
	IssueMoveToBacklogSuccessAction,
	IssueMoveToBacklogFailureAction,
} from '../../../actions/issue/move-to-backlog';
import type {
	IssueRankTransitionRequestAction,
	IssueRankTransitionUpdateOptimisticAction,
	IssueRankTransitionSuccessAction,
	IssueRankTransitionFailureAction,
} from '../../../actions/issue/rank-transition';
import type { EntitiesState } from '../../../reducers/entities/types';
import type { State } from '../../../reducers/types';
import {
	getColumnIndex,
	getStatusCategoryByStatusInColumnId,
} from '../../../selectors/column/column-selectors';
import { getIsCustomBoardWithIcc } from '../../../selectors/inline-create/inline-create-selectors';
import {
	getIssueIndex,
	getIssueById,
	getIssueByKey,
	getIssueTypeLevelForBoardIssue,
} from '../../../selectors/issue/issue-selectors';
import {
	getBoardTypeForAnalytics,
	getIsCMPBoard,
} from '../../../selectors/software/software-selectors';
import { getSwimlaneMode } from '../../../selectors/swimlane/swimlane-mode-selectors';
import { getSwimlanes, isInDefaultSwimlane } from '../../../selectors/swimlane/swimlane-selectors';
import {
	getIsAnyStatusInCreateEnabled,
	getWorkFilters,
	workIssuesSelector,
} from '../../../selectors/work/work-selectors';
import { toOneBasedIndex } from '../analytics-selector';

export const getIssueTrackEventPayload = (state: State, issueId: IssueId) => {
	const issue = getIssueById(state, issueId);
	return {
		actionSubjectId: issueId,
		objectType: 'issue',
		objectId: issueId,
		issueTypeId: issue.typeId,
		issueTypeLevel: getIssueTypeLevelForBoardIssue(state)(issueId),
	};
};

const getIssueTrackEventAttributes = (state: State, issueId: IssueId, updatedItems: []) => {
	const issue = getIssueById(state, issueId);
	return {
		updatedItems,
		issueTypeId: issue.typeId,
		issueTypeLevel: getIssueTypeLevelForBoardIssue(state)(issueId),
		devInfoActivityState: issue.devStatus?.activity,
	};
};

export const openIssueCreate =
	(eventPrefix: string) => (preState: State, state: State, action: OpenIssueModalAction) => {
		const issue = getIssueByKey(state, action.payload.issueKey);
		if (issue) {
			return {
				name: `${eventPrefix}.issue.view`,
				data: {
					issueId: issue.id,
					viewMode: getBoardDetailViewMode(),
				},
			};
		}

		return null;
	};

export const doneIssuesButtonClick = (eventPrefix: string) => () => ({
	name: `${eventPrefix}.looking.for.old.issues.to.pin`,
	data: {},
});

export const issueCreateRequest =
	(eventPrefix: string) => (preState: State, state: State, action: IssueCreateRequestAction) => {
		const hasSwimlanes = getSwimlaneMode(preState) !== NO_SWIMLANE.id;
		return {
			name: `${eventPrefix}.issue.create.request`,
			data: {
				columnIndex: toOneBasedIndex(action.meta.columnIndex),
				cardIndex: toOneBasedIndex(action.meta.cardIndex),
				transactionId: action.meta.optimistic.id,
				hasSwimlanes,
				numSwimlanes: hasSwimlanes ? getSwimlanes(preState).length : 0,
				inDefaultSwimlane: isInDefaultSwimlane(preState)(action.meta.swimlaneId),
			},
		};
	};

export const issueCreateSuccess =
	(eventPrefix: string) => (preState: State, state: State, action: IssueCreateSuccessAction) => {
		// GET THE SESSION ID & EVERYTHING ELSE HERE.
		const {
			payload: { issues },
			meta: { columnIndex, cardIndex, optimistic, analyticsEvent },
		} = action;

		/* session id set in useOnCreate to avoid update for autoOpenedICC - if undefined use from state */
		const { sessionId } = analyticsEvent.payload;

		const filterValues = getWorkFilters(state).values;

		const trackEvents: Array<UIAnalyticsEvent> = [];
		issues.forEach((issue) => {
			const clonedEvent = analyticsEvent.clone();
			if (clonedEvent) {
				/** Have to cast the state here as it is an optimistic state at this point in time. */
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const sessionIdFromState = (state.entities as OptimisticState<EntitiesState>).current
					?.inlineCreate?.sessionId;

				let improvedAnalyticsAttributes = {};
				if (sessionId !== undefined || sessionIdFromState !== undefined) {
					// Fields inputted by the user
					const issueCreateFieldsUsed = [
						{
							gqlType: 'JiraSingleLineTextField',
							issueFieldId: 'summary',
							issueFieldType: 'summary',
							isPopulated: true,
						},
					];

					// Fields that can be populated automatically e.g. by filters, swimlanes
					const fieldsPopulated = {
						ASSIGNEE: !!issue.assigneeAccountId,
						PARENT: !!issue.parentId,
						PRIORITY: !!issue.priority,
						SPRINT: !!issue.sprintId,
						FIX_VERSIONS: getFieldLengthForAnalytics(issue.fixVersions?.length),
						LABELS: getFieldLengthForAnalytics(issue.labels?.length),
						STATUS_CATEGORY: getStatusCategoryByStatusInColumnId(state)(
							issue.columnId,
							issue.statusId,
						),
					};

					improvedAnalyticsAttributes = {
						sessionId: sessionId || sessionIdFromState,
						issueCreateFieldsUsed,
						boardType: getBoardTypeForAnalytics(state),
						fieldsPopulated,
						isAnyStatusInCreateEnabled: getIsAnyStatusInCreateEnabled(state) !== false,
					};
				}

				trackEvents.push(
					clonedEvent.update({
						...getIssueTrackEventPayload(state, issue.id),
						appliedFilters: Object.keys(filterValues),
						...improvedAnalyticsAttributes,
						isCustomBoardWithIcc: getIsCustomBoardWithIcc(state),
						swimlaneMode: getSwimlaneMode(state),
					}),
				);
			}
		});

		const swimlanes = getSwimlaneMode(state) || NO_SWIMLANE.id;

		// There is only ever at most 1 text filter
		const textFilter = get(filterValues, [TEXT, 'length'], 0) > 0;
		const numAssigneeFilters = get(filterValues, [ASSIGNEE, 'length'], 0);
		const numLabelFilters = get(filterValues, [LABEL, 'length'], 0);

		fireOperationalAnalytics(analyticsEvent, 'ui taskSuccess', { task: 'createIssue' });

		return {
			name: `${eventPrefix}.issue.create.success`,
			data: {
				issueId: issues[0].id,
				numIssues: issues.length,
				columnIndex: toOneBasedIndex(columnIndex),
				cardIndex: toOneBasedIndex(cardIndex),
				transactionId: optimistic.id,
				swimlanes,
				textFilter,
				numAssigneeFilters,
				numLabelFilters,
			},
			trackEvents,
		};
	};

export const issueCreateFailure =
	(eventPrefix: string) => (preState: State, state: State, action: IssueCreateFailureAction) => {
		const {
			meta: { analyticsEvent, error },
		} = action;

		const statusCode = error ? extractStatusCode(error) : 200;
		if (analyticsEvent) {
			const sloResultType =
				error && (isClientFetchError(error) || isUserNetworkError(error))
					? 'taskAbort'
					: 'taskFail';
			fireOperationalAnalytics(analyticsEvent, `ui ${sloResultType}`, {
				task: 'createIssue',
				statusCode,
				isClassic: getIsCMPBoard(state),
				synthetic: isSyntheticTenant(),
				errorMessage: error?.message
					? truncate(error.message, {
							length: 140,
						})
					: '',
			});
		}
		return {
			name: `${eventPrefix}.issue.create.failure`,
			data: {
				transactionId: action.meta.optimistic.id,
			},
		};
	};

export const issueDeleteSuccess =
	(eventPrefix: string) => (preState: State, state: State, action: IssueDeleteSuccessAction) => {
		const concurrentId = String(action.meta.optimistic.id);
		issueDeleteInteraction(concurrentId).stop({
			customData: { ...getNormalisedPerformanceFFs(), ...getBoardExtraData(state) },
		});
		return {
			name: `${eventPrefix}.issue.delete.success`,
			data: {
				issueId: action.payload.issueId,
			},
		};
	};

export const issueDeleteFailure =
	(eventPrefix: string) => (preState: State, state: State, action: IssueDeleteFailureAction) => {
		const concurrentId = String(action.meta.optimistic.id);
		issueDeleteInteraction(concurrentId).cancel();
		return {
			name: `${eventPrefix}.issue.delete.failure`,
			data: {
				issueId: action.payload.issueId,
			},
		};
	};

export const issueRankTransitionRequest =
	(eventPrefix: string) =>
	(
		preState: State,
		state: State,
		action: IssueRankTransitionRequestAction | IssueRankTransitionUpdateOptimisticAction,
	) => {
		const { issueIds, sourceColumnId, destinationColumnId } = action.payload;
		const beforeRankIssueIndex = getIssueIndex(preState, action.payload.issueIds[0]);
		const afterRankIssueIndex = getIssueIndex(state, action.payload.issueIds[0]);
		const sourceColumnIndex = getColumnIndex(state, sourceColumnId);
		const destinationColumnIndex = getColumnIndex(state, destinationColumnId);

		return {
			name: `${eventPrefix}.issue.move`,
			data: {
				numIssues: issueIds.length,
				oldIssueIndex: toOneBasedIndex(beforeRankIssueIndex),
				newRankIssueIndex: toOneBasedIndex(afterRankIssueIndex),
				oldColumnIndex: toOneBasedIndex(sourceColumnIndex),
				newColumnIndex: toOneBasedIndex(destinationColumnIndex),
				hasTransitioned: sourceColumnId !== destinationColumnId,
				transactionId: action.meta.optimistic?.id,
			},
		};
	};

export const issueRankTransitionSuccess =
	(eventPrefix: string) =>
	(preState: State, state: State, action: IssueRankTransitionSuccessAction) => {
		const {
			payload: { issueIds },
			meta: { analyticsEvent, optimistic },
		} = action;
		const trackEvents: Array<never> = [];
		issueIds.forEach((issueId) =>
			fireTrackAnalytics(
				// @ts-expect-error - Argument of type 'UIAnalyticsEvent | null' is not assignable to parameter of type 'UIAnalyticsEvent'.
				analyticsEvent.clone(),
				'issue updated',
				issueId.toString(),
				getIssueTrackEventAttributes(state, issueId, analyticsEvent.payload.updatedItems),
			),
		);

		return {
			name: `${eventPrefix}.issue.move.success`,
			data: {
				transactionId: optimistic.id,
			},
			trackEvents,
		};
	};

export const issueRankTransitionFailure =
	(eventPrefix: string) =>
	(preState: State, state: State, action: IssueRankTransitionFailureAction) => ({
		name: `${eventPrefix}.issue.move.failure`,
		data: {
			transactionId: action.meta.optimistic.id,
		},
	});

export const issueLabelsAmount = (eventPrefix: string) => (preState: State, state: State) => {
	const issuesByLabel = countBy(workIssuesSelector(state), (issue) =>
		issue.labels.length > 10 ? 10 : issue.labels.length,
	);
	const numIssues = sum(values(issuesByLabel));

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const issueLabelsFlatten: Record<string, any> = {};

	Array(11)
		.fill(0)
		.forEach((value, labelsCount) => {
			const issuesCount = issuesByLabel[String(labelsCount)] || 0;
			const labelCountPropName = labelsCount > 9 ? '10AndMore' : String(labelsCount);
			issueLabelsFlatten[`numIssuesWith${labelCountPropName}Labels`] = issuesCount;
		});
	return {
		name: `${eventPrefix}.issue.labels`,
		data: {
			numIssues,
			...issueLabelsFlatten,
		},
	};
};

export const devStatusLoadSuccess =
	(eventPrefix: string) => (preState: State, state: State, action: DevStatusLoadSuccessAction) => ({
		name: `${eventPrefix}.dev-status.load.success`,
		data: {
			statusCount: Object.keys(action.payload).length,
		},
	});

export const issueChangeSwimlane = (eventPrefix: string) => () => ({
	name: `${eventPrefix}.issue.swimlane-change`,
});

export const issueMoveToBacklogRequest =
	(eventPrefix: string) =>
	(preState: State, state: State, action: IssueMoveToBacklogRequestAction) => {
		const { issueIds, columnId } = action.payload;
		const columnIndex = getColumnIndex(state, columnId);
		return {
			name: `${eventPrefix}.issue.move-to-backlog.request`,
			data: {
				issueIds,
				columnIndex: toOneBasedIndex(columnIndex),
			},
		};
	};

export const issueMoveToBacklogSuccess =
	(eventPrefix: string) =>
	(preState: State, state: State, action: IssueMoveToBacklogSuccessAction) => {
		const { issueIds, columnId } = action.payload;
		const columnIndex = getColumnIndex(state, columnId);
		return {
			name: `${eventPrefix}.issue.move-to-backlog.success`,
			data: {
				issueIds,
				columnIndex: toOneBasedIndex(columnIndex),
			},
		};
	};

export const issueMoveToBacklogFailure =
	(eventPrefix: string) =>
	(preState: State, state: State, action: IssueMoveToBacklogFailureAction) => {
		const { issueIds } = action.payload;
		return {
			name: `${eventPrefix}.issue.move-to-backlog.failure`,
			data: {
				issueIds,
			},
		};
	};
