import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { DONE } from '@atlassian/jira-common-constants/src/status-categories';
import { SWIMLANE_TEAMLESS } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import { SPRINT_STATES } from '@atlassian/jira-shared-types/src/rest/jira/sprint.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common';
import type { CardId } from '../../model/card/card-types';
import type { ColumnId } from '../../model/column/column-types';
import { SWIMLANE_BY_SUBTASK } from '../../model/swimlane/swimlane-modes';
import type { SwimlaneId } from '../../model/swimlane/swimlane-types';
import type { Team } from '../../model/team/team-types';
import { cardAddShowRipple } from '../../state/actions/card';
import { type CardsMoveAction, CARDS_MOVE } from '../../state/actions/card/card-move';
import { issueChangeSwimlane } from '../../state/actions/issue';
import { issueRankTeamDateRequest } from '../../state/actions/issue/rank-team-date';
import { bulkIssueRankTransitionRequest } from '../../state/actions/issue/rank-transition';
import {
	teamSwimLaneInvalidMove,
	swimlaneColumnClosedSprintInvalidMove,
} from '../../state/actions/swimlane';
import {
	cardTransitionDestinationStatusSelector,
	cardTransitionSourceStatusSelector,
} from '../../state/selectors/card-transitions/card-transitions-selectors';
import { getCardSelection, getSelectedTransition } from '../../state/selectors/card/card-selectors';
import { isDoneColumnSelector } from '../../state/selectors/column/column-selectors';
import {
	getChildrenOfParent,
	getIssueChildren,
	getParentIdByIssueChildSelector,
} from '../../state/selectors/issue-children';
import { isDoneIssueSelector } from '../../state/selectors/issue/issue-selectors';
import {
	getIsCMPBoard,
	isBoardRankable,
	getIsIncrementPlanningBoard,
} from '../../state/selectors/software/software-selectors';
import { getSwimlaneMode } from '../../state/selectors/swimlane/swimlane-mode-selectors';
import { makeSwimlaneValuesSelector } from '../../state/selectors/swimlane/swimlane-selectors';
import { getTeamById } from '../../state/selectors/team/team-selectors';
import {
	getFilteredIssueIdsByColumn,
	platformIssueIdsBySwimlaneSelector,
	getTeamSwimlaneColumnSprint,
} from '../../state/selectors/work/work-selectors';
import type { Action, ActionsObservable, MiddlewareAPI, State } from '../../state/types';

export const getRankBeforeAfterCardId = (
	destinationCardIds: CardId[] | undefined,
	sourceColumnId: ColumnId,
	destinationColumnId: ColumnId,
	sourceCardIndex: number,
	destinationCardIndex: number,
	sourceSwimlaneId?: SwimlaneId | null,
	destinationSwimlaneId?: SwimlaneId | null,
): {
	rankBeforeCardId?: IssueId;
	rankAfterCardId?: IssueId;
} => {
	if (!destinationCardIds || destinationCardIds.length === 0) {
		return {};
	}

	// rank. move inside same column
	if (destinationColumnId === sourceColumnId && sourceSwimlaneId === destinationSwimlaneId) {
		// move down list
		if (destinationCardIndex > sourceCardIndex) {
			// rank after last moved item
			return { rankAfterCardId: destinationCardIds[destinationCardIndex] };
		}

		// rank before last moved item
		return { rankBeforeCardId: destinationCardIds[destinationCardIndex] };
	}

	// rank + transition. Move from one column to another + rank inside destination column
	if (destinationCardIndex === 0) {
		// rank before first item
		return { rankBeforeCardId: destinationCardIds[destinationCardIndex] };
	}
	return { rankAfterCardId: destinationCardIds[destinationCardIndex - 1] };
};

type IssueRankTransitionRequestParams = {
	cardIds: IssueId[];
	destinationCardIds: IssueId[];
	sourceColumnId: ColumnId;
	destinationColumnId: ColumnId;
	sourceCardIndex: number;
	destinationCardIndex: number;
	sourceSwimlaneId: SwimlaneId | null | undefined;
	destinationSwimlaneId: SwimlaneId | null | undefined;
	analyticsEvent: UIAnalyticsEvent;
};

export const handleIssueRankTeamDateChange = (
	{
		cardIds,
		destinationCardIds,
		sourceColumnId,
		destinationColumnId,
		sourceCardIndex,
		destinationCardIndex,
		sourceSwimlaneId,
		destinationSwimlaneId,
	}: Omit<IssueRankTransitionRequestParams, 'analyticsEvent'>,
	state: State,
): Observable<Action> => {
	const { rankBeforeCardId, rankAfterCardId } = getRankBeforeAfterCardId(
		destinationCardIds,
		sourceColumnId,
		destinationColumnId,
		sourceCardIndex,
		destinationCardIndex,
		sourceSwimlaneId,
		destinationSwimlaneId,
	);

	if (
		sourceColumnId === destinationColumnId &&
		sourceCardIndex === destinationCardIndex &&
		sourceSwimlaneId === destinationSwimlaneId
	) {
		return Observable.empty<never>();
	}

	const destinationSwimlaneTeam: Team | undefined = !isNil(destinationSwimlaneId)
		? getTeamById(state)(destinationSwimlaneId)
		: undefined;

	if (
		sourceSwimlaneId !== destinationSwimlaneId &&
		!destinationSwimlaneTeam?.isPlanTeam &&
		destinationSwimlaneId !== SWIMLANE_TEAMLESS
	) {
		return Observable.of(teamSwimLaneInvalidMove(destinationSwimlaneTeam?.name || ''));
	}

	const sprintForTeamSwimlaneColumn = !isNil(destinationSwimlaneId)
		? getTeamSwimlaneColumnSprint(state, destinationColumnId, destinationSwimlaneId)
		: undefined;
	const isClosedSprint =
		sprintForTeamSwimlaneColumn && sprintForTeamSwimlaneColumn.state === SPRINT_STATES.CLOSED;
	if (isClosedSprint) {
		return Observable.of(swimlaneColumnClosedSprintInvalidMove(sprintForTeamSwimlaneColumn.name));
	}

	return Observable.of(
		issueRankTeamDateRequest({
			issueIds: cardIds,
			sourceColumnId,
			destinationColumnId,
			rankBeforeIssueId: rankBeforeCardId,
			rankAfterIssueId: rankAfterCardId,
			sourceSwimlaneId,
			destinationSwimlaneId,
		}),
	);
};

export const handleIssueRankTransitionRequest = (
	{
		cardIds,
		destinationCardIds,
		sourceColumnId,
		destinationColumnId,
		sourceCardIndex,
		destinationCardIndex,
		sourceSwimlaneId,
		destinationSwimlaneId,
		analyticsEvent,
	}: IssueRankTransitionRequestParams,
	state: State,
): Observable<Action> => {
	const { rankBeforeCardId, rankAfterCardId } = getRankBeforeAfterCardId(
		destinationCardIds,
		sourceColumnId,
		destinationColumnId,
		sourceCardIndex,
		destinationCardIndex,
		sourceSwimlaneId,
		destinationSwimlaneId,
	);

	const transitionId = getSelectedTransition(state)?.transitionId;

	const destinationStatus = cardTransitionDestinationStatusSelector(state)(
		cardIds[0],
		destinationColumnId,
	);

	// If we drag-and-dropped the issue into exactly the same place, do nothing.
	if (
		sourceColumnId === destinationColumnId &&
		sourceCardIndex === destinationCardIndex &&
		isNil(transitionId) &&
		((isNil(sourceSwimlaneId) && !destinationSwimlaneId) ||
			sourceSwimlaneId === destinationSwimlaneId)
	) {
		return Observable.empty<never>();
	}

	// When there are multiple statuses per column isSourceDone represents the resolution of the issue
	// rather than the column, as an unresolved issue can be in a done column and vise versa.
	const isSourceDone = isDoneIssueSelector(state)(cardIds[0]);

	let isDestinationDone = isDoneColumnSelector(state)(destinationColumnId);
	// When there are multiple statuses per column a user can move an issue to a not done status even
	// though the column is considered "done", therefore we need to check the destinationStatus as it will be used
	// when selecting a status within a column that has multiple statuses
	if (destinationStatus) {
		isDestinationDone = destinationStatus.category === DONE;
	}
	const actions: Action[] = [
		bulkIssueRankTransitionRequest({
			issueIds: cardIds,
			sourceColumnId,
			destinationColumnId,
			rankBeforeIssueId: rankBeforeCardId,
			rankAfterIssueId: rankAfterCardId,
			sourceSwimlaneId,
			destinationSwimlaneId,
			isSourceDone,
			isDestinationDone,
			transitionId,
			sourceStatus: cardTransitionSourceStatusSelector(state)(cardIds[0]),
			destinationStatus,
			isRankable: isBoardRankable(state),
			analyticsEvent,
		}),
	];

	if (isDestinationDone && !isSourceDone) {
		actions.push(cardAddShowRipple(cardIds));
	}

	if (!isNil(destinationSwimlaneId) && sourceSwimlaneId !== destinationSwimlaneId) {
		const values = makeSwimlaneValuesSelector(state)(destinationSwimlaneId);
		if (values) {
			actions.push(issueChangeSwimlane(cardIds, destinationSwimlaneId, values));
		}
	}

	return Observable.from(actions);
};

const tryGetBulkSelectionIds = (state: State, cardId: CardId) => {
	const cardSelection = getCardSelection(state);
	return cardSelection.includes(cardId) ? [...cardSelection] : [cardId];
};

export const handleCardsMove = (
	{
		cardIds,
		sourceColumnId,
		destinationColumnId,
		sourceCardIndex,
		destinationCardIndex,
		sourceSwimlaneId,
		destinationSwimlaneId,
		analyticsEvent,
	}: CardsMoveAction['payload'],
	state: State,
) => {
	const cardsByColumn = !isNil(destinationSwimlaneId)
		? platformIssueIdsBySwimlaneSelector(state)[destinationSwimlaneId]
		: getFilteredIssueIdsByColumn(state);
	let destinationCardIds = cardsByColumn[String(destinationColumnId)] ?? [];
	const issueChildren = getIssueChildren(state);
	const isIncrementPlanningBoard = getIsIncrementPlanningBoard(state);

	if (getIsCMPBoard(state) && getSwimlaneMode(state) !== SWIMLANE_BY_SUBTASK.id) {
		const cardGroupParentIds: CardId[] = [];
		const isIssueChild = cardIds[0] in issueChildren;
		const swimlaneMode = getSwimlaneMode(state);

		if (swimlaneMode !== SWIMLANE_BY_SUBTASK.id && isIssueChild) {
			const issueChild = issueChildren[cardIds[0]];
			const childrenIds = getChildrenOfParent(state)(issueChild.parentId) || [];
			destinationCardIds = destinationCardIds.filter((id) => childrenIds.includes(id));
		} else {
			destinationCardIds = destinationCardIds.reduce<CardId[]>((result, cardId) => {
				const parentId = getParentIdByIssueChildSelector(state)(cardId);
				if (parentId) {
					// Get the parent id for the subtask group, because subtasks always follow their parents when ranking if the board is rankable
					if (!cardGroupParentIds.includes(parentId)) {
						cardGroupParentIds.push(parentId);
						result.push(parentId);
					}
				} else {
					result.push(cardId);
				}
				return result;
			}, []);
		}
	}

	const cardIdsToMove = tryGetBulkSelectionIds(state, cardIds[0]);

	if (isIncrementPlanningBoard) {
		return handleIssueRankTeamDateChange(
			{
				cardIds: cardIdsToMove,
				destinationCardIds,
				sourceColumnId,
				destinationColumnId,
				sourceCardIndex,
				destinationCardIndex,
				sourceSwimlaneId,
				destinationSwimlaneId,
			},
			state,
		);
	}

	return handleIssueRankTransitionRequest(
		{
			cardIds: cardIdsToMove,
			destinationCardIds,
			sourceColumnId,
			destinationColumnId,
			sourceCardIndex,
			destinationCardIndex,
			sourceSwimlaneId,
			destinationSwimlaneId,
			analyticsEvent,
		},
		state,
	);
};

export function cardsMoveEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> {
	return action$
		.ofType(CARDS_MOVE)
		.mergeMap((action: CardsMoveAction) => handleCardsMove(action.payload, store.getState()));
}
