import React, { useState, useCallback, useMemo, useEffect } from 'react';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, {
	type CustomTriggerProps,
	DropdownItemGroup,
	type OnOpenChangeArgs,
} from '@atlaskit/dropdown-menu';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { SpotlightPulse, SpotlightTarget } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { createAri } from '@atlassian/jira-platform-ari';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useSeenStatus } from '@atlassian/jira-servicedesk-itsm-onboarding-common/src/controllers/seen-status/index.tsx';
import { BoardConnectMenuItems } from '@atlassian/jira-software-connect-toolbar/src/ui/board-connect-menu-items/index.tsx';
import { editSprintModalEntryPoint } from '@atlassian/jira-software-edit-sprint-modal-relay/entrypoint';
import ManageWorkflowItem from '@atlassian/jira-software-manage-workflow-menu-item/src/ui/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { Capability } from '../../../common/capability';
import { editSprintModalLoad } from '../../../services/utils/performance-analytics';
import {
	useCapability,
	useIsCMPBoard,
	useIsJSMBoard,
	useCanEditBoard,
} from '../../../state/state-hooks/capabilities';
import CardMediaItem from './card-media-item';
import ConfigureBoardMenuItem from './configure-board';
import ConfigureBoardItem from './configure-board-item';
import BoardConnectItems from './connect-items';
import CreateBoardMenuItem from './create-board';
import { EditSprintMenuItem } from './edit-sprint-item';
import EditSprintModalEntryPoint from './edit-sprint-modal';
import ManageCustomFiltersItem from './manage-custom-filters-item';
import messages from './messages';
import { SPOTLIGHT_TARGET_ID } from './onboarding-spotlight/constants.tsx';
import { OnboardingSpotlight } from './onboarding-spotlight/view.tsx';
import ReleaseVersion from './release-version';

export type StateProps = {
	isCardMediaSwitchEnabled: boolean;
	isConfigureBoardEnabled: boolean;
	isDisabled: boolean;
	isEditSprintItemVisible: boolean;
	isManageCustomFiltersEnabled: boolean;
	issueTypeId: string;
	projectKey: string;
	boardId: string;
	sprintId?: number;
	isReleaseVersionVisible?: boolean;
};

type OwnProps = {};

export type BoardMenuProps = OwnProps & StateProps;

const JSM_BOARD_MEATBALL_MENU_SEEN_STATUS_USER_PROPERTY_KEY = 'jsm-board-meatball-menu-seen-status';

const BoardMenu = ({
	isCardMediaSwitchEnabled,
	isConfigureBoardEnabled,
	isDisabled,
	isEditSprintItemVisible,
	isManageCustomFiltersEnabled,
	issueTypeId,
	projectKey,
	boardId,
	sprintId,
	isReleaseVersionVisible,
}: BoardMenuProps) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const [shouldShowPulse, setShouldShowPulse] = useState(false);
	const [shouldShowSpotlight, setShouldShowSpotlight] = useState(false);
	const [hasSpotlightDisplayed, setHasSpotlightDisplayed] = useState(false);
	const isJsmBoardsPromoGateEnabled = fg('jsm_boards_promo_gate');

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isJSMBoard = useIsJSMBoard();
	const canEditBoard = useCanEditBoard();

	const isTabNavigation = useCapability(Capability.IS_TAB_NAVIGATION);
	const shouldRenderBoardMoreMenuItem = useCapability(Capability.RENDER_BOARD_MORE_MENU_ITEM);
	const shouldRenderBoardSettingsMenuItem = useCapability(
		Capability.RENDER_BOARD_SETTINGS_MENU_ITEM,
	);
	const shouldRenderCreateBoardMenuItem = useCapability(Capability.RENDER_CREATE_BOARD_MENU_ITEM);
	const shouldRenderEditSprintMenuItem = useCapability(Capability.RENDER_EDIT_SPRINT_MENU_ITEM);
	const shouldRenderManageWorkflowMenuItem = useCapability(
		Capability.RENDER_MANAGE_WORKFLOW_MENU_ITEM,
	);

	const isCMPBoard = useIsCMPBoard();
	const cloudId = useCloudId();
	const isAdmin = useIsAdmin();

	const [{ isSeen: hasSeenSpotlight }, { markAsSeen: markSpotlightAsSeen }] = useSeenStatus({
		userPropertyKey: JSM_BOARD_MEATBALL_MENU_SEEN_STATUS_USER_PROPERTY_KEY,
		isSeenValue: true,
		packageName: 'jiraBoard',
		teamName: 'jsd-shield',
	});

	useEffect(() => {
		if (
			isJsmBoardsPromoGateEnabled &&
			isJSMBoard &&
			isAdmin &&
			!hasSeenSpotlight &&
			!hasSpotlightDisplayed
		) {
			setShouldShowPulse(!hasSeenSpotlight);
		}
	}, [hasSeenSpotlight, hasSpotlightDisplayed, isAdmin, isJSMBoard, isJsmBoardsPromoGateEnabled]);

	const onClick = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const onOpenChangeOld = useCallback(
		(attrs: OnOpenChangeArgs) => {
			setIsOpen(attrs.isOpen);
			fireUIAnalytics(
				createAnalyticsEvent({ actionSubject: 'dropdownMenu', action: 'toggled' }),
				'boardHeaderMenu',
			);
		},
		[setIsOpen, createAnalyticsEvent],
	);

	const onOpenChange = useCallback(
		(attrs: OnOpenChangeArgs) => {
			if (isJSMBoard && isAdmin && !hasSeenSpotlight) {
				setShouldShowSpotlight(true);
				setShouldShowPulse(false);
				fireUIAnalytics(
					createAnalyticsEvent({ actionSubject: 'button', action: 'clicked' }),
					'boardAdminOnboardingConfigPulse',
				);
			} else {
				setIsOpen(attrs.isOpen);
				fireUIAnalytics(
					createAnalyticsEvent({ actionSubject: 'dropdownMenu', action: 'toggled' }),
					'boardHeaderMenu',
				);
			}
		},
		[isJSMBoard, isAdmin, hasSeenSpotlight, createAnalyticsEvent],
	);

	const dropdownMenuTrigger = useCallback(
		({ triggerRef, ...triggerProps }: CustomTriggerProps) => {
			if (isVisualRefreshEnabled() || isTabNavigation) {
				return (
					<IconButton
						{...triggerProps}
						icon={ShowMoreHorizontalIcon}
						label={formatMessage(messages.iconButtonLabel)}
						ref={triggerRef}
					/>
				);
			}

			return (
				<Button
					{...triggerProps}
					iconBefore={
						<ShowMoreHorizontalIcon
							label="More actions"
							color={token('color.icon', '#44546F')}
							LEGACY_fallbackIcon={MoreIcon}
							LEGACY_primaryColor={token('color.icon', '#44546F')}
						/>
					}
					aria-label={formatMessage(messages.dropdownAriaLabel)}
					ref={triggerRef}
				/>
			);
		},
		[formatMessage, isTabNavigation],
	);

	const boardAri = createAri({
		resourceOwner: 'jira-software',
		cloudId,
		resourceType: 'board',
		resourceId: String(boardId),
	});

	const entryPointParams = useMemo(
		() => ({
			boardId: boardAri,
			sprintId: createAri({
				resourceOwner: 'jira-software',
				cloudId,
				resourceType: 'sprint',
				resourceId: String(sprintId),
			}),
			fetchSprints: true,
			isCompanyManaged: isCMPBoard,
		}),
		[boardAri, cloudId, sprintId, isCMPBoard],
	);

	const { entryPointActions, entryPointReferenceSubject, stopMetric } = useEntryPoint(
		editSprintModalEntryPoint,
		entryPointParams,
		editSprintModalLoad,
	);

	const editSprintModalTriggerRef = useEntryPointButtonTrigger(entryPointActions);

	const renderEditSprintDropdownItem = useCallback(() => {
		if (!isEditSprintItemVisible) {
			return null;
		}

		return (
			shouldRenderEditSprintMenuItem &&
			!isTabNavigation && <EditSprintMenuItem ref={editSprintModalTriggerRef} onClick={onClick} />
		);
	}, [
		isEditSprintItemVisible,
		shouldRenderEditSprintMenuItem,
		isTabNavigation,
		editSprintModalTriggerRef,
		onClick,
	]);

	const renderReleaseDropdownItem = useCallback(
		() => isReleaseVersionVisible && isTabNavigation && <ReleaseVersion rapidViewId={boardId} />,
		[boardId, isReleaseVersionVisible, isTabNavigation],
	);

	// hide the meatball menu button if user doesn't have edit board permission for JSM project
	if (isJSMBoard && !canEditBoard) {
		return null;
	}

	const dropdownItemGroupContent = (
		<DropdownItemGroup>
			{fg('jsw_nav4_new_sprint_controls_in_boards_blu-3256')
				? isEditSprintItemVisible &&
					shouldRenderEditSprintMenuItem && (
						<EditSprintMenuItem ref={editSprintModalTriggerRef} onClick={onClick} />
					)
				: renderEditSprintDropdownItem()}

			{shouldRenderManageWorkflowMenuItem && (
				<ManageWorkflowItem
					projectKey={projectKey}
					isDisabled={isDisabled}
					issueTypeId={issueTypeId}
				/>
			)}

			{!isCMPBoard && isManageCustomFiltersEnabled ? <ManageCustomFiltersItem /> : null}

			{!isCMPBoard && isCardMediaSwitchEnabled ? (
				<CardMediaItem isDisabled={isDisabled} onClick={onClick} />
			) : null}

			{!isCMPBoard && isConfigureBoardEnabled ? <ConfigureBoardItem /> : null}

			{shouldRenderBoardSettingsMenuItem && <ConfigureBoardMenuItem />}
			{isCMPBoard && !isTabNavigation && !fg('move-connect-addons-board-backlog-nav4') && (
				<BoardConnectMenuItems
					boardId={boardId}
					projectKey={projectKey}
					location="board-tools-1"
					onClickWebItem={onClick}
					mode="work"
				/>
			)}

			{shouldRenderCreateBoardMenuItem && <CreateBoardMenuItem />}

			{isCMPBoard && !isTabNavigation && !fg('move-connect-addons-board-backlog-nav4') && (
				<>
					<BoardConnectMenuItems
						boardId={boardId}
						projectKey={projectKey}
						location="view-actions-work"
						mode="work"
					/>
					<BoardConnectMenuItems
						boardId={boardId}
						projectKey={projectKey}
						location="view-actions-print"
						onClickWebItem={onClick}
						mode="work"
					/>
				</>
			)}
			{renderReleaseDropdownItem()}
			{isTabNavigation && fg('move-connect-addons-board-backlog-nav4') && (
				<BoardConnectItems projectKey={projectKey} boardId={boardId} onClickWebItem={onClick} />
			)}
		</DropdownItemGroup>
	);

	const onAcknowledgeSpotlight = () => {
		markSpotlightAsSeen();
		setHasSpotlightDisplayed(true);
		setShouldShowSpotlight(false);
		setIsOpen(true);
	};

	return (
		<>
			{isEditSprintItemVisible && (
				<EditSprintModalEntryPoint
					entryPointReferenceSubject={entryPointReferenceSubject}
					entryPointActions={entryPointActions}
					onReady={stopMetric}
				/>
			)}

			{shouldRenderBoardMoreMenuItem &&
				(isJsmBoardsPromoGateEnabled && isJSMBoard && isAdmin ? (
					<SpotlightTarget name={SPOTLIGHT_TARGET_ID} key="add-form-button">
						<SpotlightPulse radius={3} pulse={shouldShowPulse}>
							<DropdownMenu
								placement="bottom-end"
								isOpen={isOpen}
								testId="software-board.header.menu.dropdown-menu"
								onOpenChange={onOpenChange}
								trigger={dropdownMenuTrigger}
								shouldRenderToParent={isTabNavigation}
							>
								{isTabNavigation && fg('move-connect-addons-board-backlog-nav4') ? (
									<Box xcss={dropdownMenuGroupStyles}>{dropdownItemGroupContent}</Box>
								) : (
									dropdownItemGroupContent
								)}
							</DropdownMenu>
						</SpotlightPulse>
						{shouldShowSpotlight && <OnboardingSpotlight onAcknowledge={onAcknowledgeSpotlight} />}
						{shouldShowPulse && (
							<ContextualAnalyticsData
								sourceType={SCREEN}
								sourceName="boardAdminOnboardingConfigPulse"
							>
								<FireScreenAnalytics attributes={{ name: 'boardAdminOnboardingConfigPulse' }} />
							</ContextualAnalyticsData>
						)}
					</SpotlightTarget>
				) : (
					<DropdownMenu
						placement="bottom-end"
						isOpen={isOpen}
						testId="software-board.header.menu.dropdown-menu"
						onOpenChange={onOpenChangeOld}
						trigger={dropdownMenuTrigger}
						shouldRenderToParent={isTabNavigation}
					>
						{isTabNavigation && fg('move-connect-addons-board-backlog-nav4') ? (
							<Box xcss={dropdownMenuGroupStyles}>{dropdownItemGroupContent}</Box>
						) : (
							dropdownItemGroupContent
						)}
					</DropdownMenu>
				))}
		</>
	);
};

const dropdownMenuGroupStyles = xcss({
	maxWidth: '240px',
});

export default BoardMenu;
