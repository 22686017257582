import log from '@atlassian/jira-common-util-logging/src/log';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { UseFetchError, LoggerReturn } from '../types';

export const loggingHandler = ({ loggingKey }: { loggingKey?: string }): LoggerReturn => {
	if (loggingKey === null || loggingKey === undefined) {
		return {
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			error: () => {},
		};
	}

	const errorHandler = (thrownError?: UseFetchError) => {
		if (!isClientFetchError(thrownError)) {
			log.safeErrorWithoutCustomerData(loggingKey, 'Error fetching data', thrownError);
		}
	};

	return {
		error: errorHandler,
	};
};
