/**
 * UTC Long format of a date, eg "13 Jul 2023"
 */
export const longDateFormat = {
	day: '2-digit',
	month: 'short',
	year: 'numeric',
	timeZone: 'UTC',
};

/**
 * UTC Day Month format of a date, eg "13 Jul"
 */
export const dateMonthFormat = {
	day: '2-digit',
	month: 'short',
	timeZone: 'UTC',
};

/**
 * UTC Month Year format of a date, eg "Jul 2023"
 */
export const monthYearFormat = {
	month: 'short',
	year: 'numeric',
	timeZone: 'UTC',
};

/**
 * UTC Long date with time, eg "13 Jul 2023 4:59pm"
 */
export const longDateTimeFormat = {
	day: '2-digit',
	month: 'short',
	year: 'numeric',
	hour: 'numeric',
	minute: '2-digit',
	hour12: true,
	timeZone: 'UTC',
};

/**
 * UTC Short date, eg "13/04/2023"
 */
export const shortDateFormat = {
	day: '2-digit',
	month: '2-digit',
	year: 'numeric',
	timeZone: 'UTC',
};

/**
 * UTC Weekday format of a date, eg "Wed 18 Jul 2023"
 */
export const weekdayFormat = {
	weekday: 'short',
	day: '2-digit',
	month: 'short',
	year: 'numeric',
	timeZone: 'UTC',
};

/**
 * UTC Weekday format of a date, eg "Wednesday, 18/07/2023"
 */
export const weekdayFormatLong = {
	weekday: 'long',
	day: '2-digit',
	month: '2-digit',
	year: 'numeric',
	timeZone: 'UTC',
};

export const DateUnits = {
	DAYS: 'days',
	MONTHS: 'months',
	WEEKS: 'weeks',
} as const;

export const CUSTOM_TIME_RANGE_TYPES = {
	FIXED: 'fixed',
	RELATIVE: 'relative',
} as const;

export const DefaultDateFormat = 'DD/MMM/YY';

export const IsoDateFormat = 'YYYY-MM-DD';

export const DEFAULT_WEEK_START_DAY: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 0; // Sunday
