import { createStore, createStateHook, createActionsHook } from '@atlassian/react-sweet-state';
import { getLocalStorage } from '../../../common/utils/local-storage';
import { actions } from './actions';
import { LAST_AUTO_OPEN_TIMESTAMP_KEY } from './constants';
import type { JptAutoOpenTimestampState } from './types';
import { getHasBeenFourteenDays } from './utils';

export type Actions = typeof actions;

export const store = createStore<JptAutoOpenTimestampState, Actions>({
	name: 'jsw-presentation-toolkit-auto-open',
	initialState: {
		hasBeenFourteenDays: getHasBeenFourteenDays(getLocalStorage(LAST_AUTO_OPEN_TIMESTAMP_KEY)),
	},
	actions,
});

const getAutoOpenTimestampStateSelector = (
	state: JptAutoOpenTimestampState,
): JptAutoOpenTimestampState => state;

export const useAutoOpenTimestampState = createStateHook(store, {
	selector: getAutoOpenTimestampStateSelector,
});

export const useAutoOpenTimestampActions = createActionsHook(store);
