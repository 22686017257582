import { useState, useCallback, useEffect } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	APP_IDENTIFIER,
	APP_TEAM_NAME,
	INITIAL_MIGRATION_STATUS,
	X2C_SERVICE_URL_PREFIX,
} from '../../common/constants';
import { EXPERIENCES, pollingInteractionMetric } from '../../common/constants/metrics';
import { type MigrationId, type MigrationStatus, Status } from '../../common/types';
import { isSynthetic } from '../../common/utils';
import { useExperienceTracker } from '../use-experience-tracker';
import type { ContainerTransferStatus, UsePollMigrationStatus } from './types';
import {
	type ShouldKeepPolling,
	calculateMigrationStatus,
	isRetryable,
	withExponentialBackoff,
} from './utils';

export const MAX_POLLING_INTERVAL_MS = 30 * 60 * 1000;
export const EXPONENTIAL_BASE = 1.3;

const getX2CMigrationStatusUrl = (cloudId: string, migrationId: MigrationId) =>
	`${X2C_SERVICE_URL_PREFIX}/${cloudId}/import/${migrationId}/status`;

export const fetchMigrationStatus = (
	cloudId: string,
	migrationId: MigrationId,
): Promise<ContainerTransferStatus> =>
	fetchJson(getX2CMigrationStatusUrl(cloudId, migrationId), {
		method: 'GET',
		headers: {
			'x-issynthetic-traffic': String(isSynthetic()),
		},
	});

export const usePollMigrationStatus = (
	migrationId: MigrationId | null,
	basePollingIntervalMs: number,
): UsePollMigrationStatus => {
	const cloudId = useCloudId();
	const [migrationStatus, setMigrationStatus] = useState<MigrationStatus>(INITIAL_MIGRATION_STATUS);
	const [onExperienceStart, onExperienceSuccess, onExperienceFail] = useExperienceTracker(
		EXPERIENCES.POLLING,
	);

	const getPollingRetryStatus = useCallback(async (): Promise<ShouldKeepPolling> => {
		if (!migrationId) {
			return false;
		}

		try {
			onExperienceStart();
			pollingInteractionMetric.start();
			const containerTransferStatus = await fetchMigrationStatus(cloudId, migrationId);
			const status = calculateMigrationStatus(containerTransferStatus);
			setMigrationStatus(status);

			pollingInteractionMetric.stop();
			onExperienceSuccess();

			return status.overallStatus !== Status.SUCCESS && status.overallStatus !== Status.FAILED;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			pollingInteractionMetric.stop();
			onExperienceFail(APP_IDENTIFIER, error, { migrationId });
			fireErrorAnalytics({
				meta: {
					id: 'fetch-migration-status-error',
					packageName: APP_IDENTIFIER,
					teamName: APP_TEAM_NAME,
				},
				sendToPrivacyUnsafeSplunk: true,
				error,
			});

			if (isRetryable(error)) return true;

			setMigrationStatus({
				...migrationStatus,
				overallStatus: Status.FAILED,
			});

			return false;
		}
	}, [
		cloudId,
		migrationId,
		migrationStatus,
		onExperienceFail,
		onExperienceStart,
		onExperienceSuccess,
	]);

	useEffect(() => {
		if (migrationId) {
			withExponentialBackoff(
				getPollingRetryStatus,
				basePollingIntervalMs,
				MAX_POLLING_INTERVAL_MS,
				EXPONENTIAL_BASE,
			);
		}

		// Only setup new polling if migrationId or interval changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [migrationId, basePollingIntervalMs]);

	return { migrationStatus };
};
