import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const planIssueModal = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "plan-issue-modal" */ './src/ui/index').then(
			(module) => module.AsyncPlanIssueModal,
		),
	),
	getPreloadProps: () => ({}),
});
