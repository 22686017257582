import type { MouseEvent, ReactElement } from 'react';
import type { IconProps } from '@atlaskit/icon';
import type AddItemIcon from '@atlaskit/icon/glyph/add-item';
import type PullRequestIcon from '@atlaskit/icon/glyph/bitbucket/pullrequests';
import type CalendarIcon from '@atlaskit/icon/glyph/calendar';
import type IssueIcon from '@atlaskit/icon/glyph/issue';
import type UploadIcon from '@atlaskit/icon/glyph/upload';
import type PlayIcon from '@atlaskit/icon/glyph/vid-play';
import type { ProjectType } from '@atlassian/jira-common-constants';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { ProjectId, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';

export const filters = {
	CATEGORY_DEVOPS: 'CATEGORY_DEVOPS',
	CATEGORY_ISSUE: 'CATEGORY_ISSUE',
	CATEGORY_INTEGRATION: 'CATEGORY_INTEGRATION',
	CATEGORY_SCHEDULE: 'CATEGORY_SCHEDULE',
	CATEGORY_ALL: 'CATEGORY_ALL',
} as const;

export type FilterKey = keyof typeof filters;

export type ProjectProps = {
	/**
	 * This reflects if a project is CMP or TMP (different URLs and different issue subscription modal)
	 * @deprecated - The ProjectAwareBoardMenu retrieves the project information from a context
	 */
	isSimplified: boolean;
	/**
	 * This will determine if it is a CMP board with the new UI (i.e. we cannot use the legacy issue subscription model)
	 * @deprecated after UIF has cleaned up and the legacy UI is no longer in use (can also remove old subscription model)
	 */
	isUIF: boolean;
	projectType: ProjectType;
	projectId: ProjectId;
	projectKey: ProjectKey;
};

export type IconPropsWithOnClick = IconProps & {
	onClick?: (e: MouseEvent) => void;
};

type WebhookIcon = (arg1: IconPropsWithOnClick) => ReactElement;
type IconType =
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	| typeof CalendarIcon
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	| typeof IssueIcon
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	| typeof UploadIcon
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	| typeof PlayIcon
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	| typeof PullRequestIcon
	| WebhookIcon
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	| typeof AddItemIcon;

export type MenuItem = {
	Icon: IconType;
	// these are INTL shapes which we don't have access too as is
	title: MessageDescriptor;
	description: MessageDescriptor;
	filter: FilterKey;
};

export type TriggerProps = {
	isSelected: boolean;
} & {
	onClick: () => void;
};

export type RuleScope = {
	resources: Ari[];
};

export type ManualRule = {
	id: number;
	name: string;
	executing: boolean;
	ruleScope: RuleScope;
};
