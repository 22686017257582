import { type CapabilityKey, hasCapability } from '../../../common/capability';
import type { State } from '../../reducers/types';
import {
	getEntities,
	getIsIncrementPlanningBoard,
	projectTypeSelector,
} from '../software/software-selectors';

export const getCapability = (state: State) => (key: CapabilityKey) =>
	hasCapability(
		key,
		state.configuration.isCMPBoard,
		getIsIncrementPlanningBoard(state),
		getEntities(state).board.config.allFeatures,
		projectTypeSelector(state),
	);
