import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

export const EXPERIMENT = 'experiment' as const;
export const NOT_ENROLLED = 'not-enrolled' as const;
export const CONTROL = 'control' as const;

export const LEARN_MORE = 'learn-more' as const;
export const READ_ABOUT_CONFIGURING_STATUSES = 'read-about-configuring-statuses' as const;
export const UPGRADE = 'upgrade' as const;
export type UpflowFlow =
	| typeof LEARN_MORE
	| typeof UPGRADE
	| typeof READ_ABOUT_CONFIGURING_STATUSES;

export type ActionIDMapperType = {
	[name: string]: string;
};

export const mapActionIdFromType: ActionIDMapperType = {
	[LEARN_MORE]: 'freeLearnMore',
	[READ_ABOUT_CONFIGURING_STATUSES]: 'readAboutConfiguringStatuses',
	[UPGRADE]: 'freeTryNow',
};

export type MessageMapperType = {
	[name: string]: MessageDescriptor;
};
