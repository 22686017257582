import { chain, freeze, set, setIn, unset } from 'icepick';
import union from 'lodash/union';
import { ColumnType } from '@atlassian/jira-common-constants/src/column-types';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { SWIMLANE_TEAMLESS } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import { values } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common';
import { CARD_DND_TYPE } from '../../../../model/constants';
import { ASSIGNEE, PARENT } from '../../../../model/swimlane/swimlane-types';
import { entityArrayToMap } from '../../../../services/software/software-data-transformer';
import type { Action } from '../../../actions';
import {
	type BacklogIssueMoveExecuteAction,
	BACKLOG_ISSUE_MOVE_EXECUTE,
} from '../../../actions/board/backlog-issue-move';
import {
	CARD_CLEAR,
	CARD_DATA_SET,
	CARD_DELETE,
	type CardDataSetAction,
	type CardDeleteAction,
	type CardClearAction,
} from '../../../actions/card';
import {
	type ColumnDeleteRequestAction,
	COLUMN_DELETE_REQUEST,
} from '../../../actions/column/delete';
import { ISSUE_CHANGE_SWIMLANE, type IssueChangeSwimlaneAction } from '../../../actions/issue';
import {
	ISSUE_ADD_LABELS,
	type IssueAddLabelsAction,
} from '../../../actions/issue/add-label-modal';
import {
	ASSIGN_TO_ME_REQUEST,
	type AssignToMeRequestAction,
} from '../../../actions/issue/assign-to-me';
import {
	ISSUE_BULK_UPDATE_REQUEST,
	type IssueBulkUpdateRequestAction,
} from '../../../actions/issue/bulk-update';
import {
	SET_CHILD_ISSUES_METADATA,
	type SetChildIssuesMetadataAction,
} from '../../../actions/issue/child-issues-metadata';
import {
	ISSUE_CREATE_REQUEST,
	ISSUE_CREATE_SUCCESS,
	type IssueCreateRequestAction,
	type IssueCreateSuccessAction,
} from '../../../actions/issue/create';
import { ISSUE_DELETE_REQUEST, type IssueDeleteRequestAction } from '../../../actions/issue/delete';
import {
	type DevStatusLoadSuccessAction,
	DEV_STATUS_LOAD_SUCCESS,
} from '../../../actions/issue/dev-status';
import { type SetEstimateAction, SET_ESTIMATE } from '../../../actions/issue/estimate';
import {
	ISSUE_FLAG_WITH_COMMENT_REQUEST,
	type IssueFlagWithCommentRequestAction,
} from '../../../actions/issue/flag-with-comment';
import {
	ISSUE_LINKS_CREATE,
	ISSUE_LINKS_REMOVE_SUCCESS,
	type IssueLinkCreateAction,
	ISSUE_LINKS_ADD_UPDATE_SUCCESS,
	type IssueLinkAddAndUpdateSuccessAction,
} from '../../../actions/issue/issue-link';
import {
	ISSUE_MOVE_TO_BACKLOG_REQUEST,
	type IssueMoveToBacklogRequestAction,
} from '../../../actions/issue/move-to-backlog';
import {
	type SetParentOptimisticAction,
	SET_PARENT_OPTIMISTIC,
} from '../../../actions/issue/parent';
import {
	ISSUE_RANK_TEAM_DATE_REQUEST,
	type IssueRankTeamDateRequestAction,
} from '../../../actions/issue/rank-team-date';
import {
	type IssueRankTransitionUpdateOptimisticAction,
	ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC,
} from '../../../actions/issue/rank-transition';
import { type SetSummaryAction, SET_SUMMARY } from '../../../actions/issue/summary';
import {
	ISSUE_UPDATE_SUCCESS,
	type IssueUpdateSuccessAction,
	SET_ISSUE_PARENT,
	type SetIssueParentAction,
} from '../../../actions/issue/update';
import {
	type WorkDataSetAction,
	type WorkDataCriticalSetAction,
	WORK_DATA_SET,
	WORK_DATA_CRITICAL_SET,
} from '../../../actions/work';
import type { IssuesState } from './types';

export const issueEntitiesReducer = (
	state: IssuesState = freeze({}),
	action: Action,
): IssuesState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		const {
			payload: { issues },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as WorkDataSetAction | WorkDataCriticalSetAction;
		return freeze(entityArrayToMap(issues));
	}

	if (action.type === ISSUE_RANK_TEAM_DATE_REQUEST) {
		const {
			payload: { issueIds, destinationColumnId, destinationSwimlaneId },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueRankTeamDateRequestAction;
		const changeChain = chain(state);
		issueIds.forEach((issueId) => {
			if (!state[String(issueId)]) return;
			changeChain.setIn([String(issueId), 'columnId'], destinationColumnId);
			changeChain.setIn(
				[String(issueId), 'teamId'],
				destinationSwimlaneId === SWIMLANE_TEAMLESS ? undefined : destinationSwimlaneId,
			);
			changeChain.setIn([String(issueId), 'hasScenarioChanges'], true);
		});

		return changeChain.value();
	}

	if (action.type === ISSUE_BULK_UPDATE_REQUEST) {
		const {
			payload: { issueIds, sprint },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueBulkUpdateRequestAction;
		const changeChain = chain(state);
		issueIds.forEach((issueId) => {
			if (!state[String(issueId)] || sprint === undefined) return;
			changeChain.setIn([String(issueId), 'sprintId'], sprint);
		});
		return changeChain.value();
	}

	if (action.type === ISSUE_CREATE_REQUEST) {
		const {
			payload: { issues, type },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueCreateRequestAction;
		if (type !== CARD_DND_TYPE) return state;
		const newState = { ...state };
		issues.forEach((issue) => {
			newState[String(issue.id)] = issue;
		});
		return freeze(newState);
	}

	if (action.type === ISSUE_CREATE_SUCCESS) {
		const {
			payload: { temporaryIssueIds, issues },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueCreateSuccessAction;
		const newState = { ...state };

		if (temporaryIssueIds.some((id) => state[String(id)])) {
			temporaryIssueIds.forEach((temporaryId) => {
				delete newState[String(temporaryId)];
			});
			issues.forEach((issue) => {
				newState[String(issue.id)] = issue;
			});
			return freeze(newState);
		}
		return state;
	}

	if (action.type === ISSUE_UPDATE_SUCCESS) {
		const {
			payload: { issue },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueUpdateSuccessAction;
		const issueId = String(issue.id);
		if (state[issueId]) return set(state, issueId, issue);
		return state;
	}

	if (action.type === CARD_DATA_SET) {
		const {
			payload: { issue },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as CardDataSetAction;
		const issueId = String(issue.id);
		const issueInState = state[issueId];
		if (issueInState) {
			return set(state, issueId, {
				...issue,
				devStatus: issue.devStatus || issueInState.devStatus,
			});
		}
		if (state[issueId]) return set(state, issueId, issue);
		return state;
	}

	if (action.type === ISSUE_DELETE_REQUEST) {
		const {
			payload: { issue },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueDeleteRequestAction;
		return unset(state, String(issue.id));
	}

	if (action.type === CARD_DELETE) {
		const {
			payload: { issueId, parentId, isDone },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as CardDeleteAction;

		// if the deleted issue is a base level issue, remove the issue right away
		if (state[String(issueId)]) {
			return unset(state, String(issueId));
		}

		// if the deleted issue is a subtask, update children properties of the issue
		if (state[String(parentId)]) {
			return chain(state)
				.updateIn([String(parentId), 'numCompleteChildren'], (value: number) =>
					isDone ? Math.max(value - 1, 0) : value,
				)
				.updateIn([String(parentId), 'numTotalChildren'], (value: number) => Math.max(value - 1, 0))
				.value();
		}

		// if the deleted issue is an epic, update the issues that are children of the epic
		const newState = chain(state);

		values(state).forEach(({ id, parentId: issueParentId }) => {
			if (issueId && issueId === issueParentId) {
				newState.setIn([String(id), 'parentId'], null);
			}
		});

		return newState.value();
	}

	if (action.type === CARD_CLEAR) {
		const {
			payload: { cardIds },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as CardClearAction;

		const newState = chain(state);
		cardIds.forEach((cardId) => {
			newState.unset(String(cardId));
		});
		return newState.value();
	}

	if (action.type === ISSUE_MOVE_TO_BACKLOG_REQUEST) {
		const {
			payload: { issueIds },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueMoveToBacklogRequestAction;
		const newState = chain(state);
		issueIds.forEach((issueId) => {
			newState.unset(String(issueId));
		});
		return newState.value();
	}

	if (action.type === ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC) {
		const {
			payload: { issueIds, destinationColumnId, isDestinationDone, destinationStatus, isCMPBoard },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueRankTransitionUpdateOptimisticAction;

		const changeChain = chain(state);
		issueIds.forEach((issueId) => {
			if (!state[String(issueId)]) return;
			changeChain.setIn([String(issueId), 'columnId'], destinationColumnId);

			if (destinationStatus) {
				changeChain.setIn([String(issueId), 'statusId'], Number(destinationStatus.id));
			}

			if (!isCMPBoard) {
				// We use `isDone` to show issue key strikethrough as an indicator of issue resolution.
				// Only for TMP, status' category equaling `Done` means the issue is resolved.
				// For CMP, we cannot determine an issue's resolution completely client-side due to the complexities of non-simplified workflows.
				// As we fire a data refresh request on each issue transition for CMP, the correct `isDone` value will be pulled from the server anyway with a slight delay.
				changeChain.setIn([String(issueId), 'isDone'], isDestinationDone);
			}
		});

		return changeChain.value();
	}

	if (action.type === BACKLOG_ISSUE_MOVE_EXECUTE) {
		const {
			payload: { issueIds },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as BacklogIssueMoveExecuteAction;
		const changeChain = chain(state);

		issueIds.forEach((id) => changeChain.unset(String(id)));

		return changeChain.value();
	}

	if (action.type === ISSUE_CHANGE_SWIMLANE) {
		const {
			payload: { issueIds, swimlaneValues },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueChangeSwimlaneAction;
		const changeChain = chain(state);

		issueIds.forEach((issueId) => {
			if (!state[String(issueId)]) return;
			if (swimlaneValues.type === ASSIGNEE) {
				changeChain.setIn([String(issueId), 'assigneeAccountId'], swimlaneValues.assigneeAccountId);
			}
			if (swimlaneValues.type === PARENT) {
				changeChain.setIn([String(issueId), 'parentId'], swimlaneValues.parentId);
			}
		});

		return changeChain.value();
	}

	if (action.type === DEV_STATUS_LOAD_SUCCESS) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { payload } = action as DevStatusLoadSuccessAction;
		const newState = chain(state);

		Object.keys(payload).forEach((issueIdString) => {
			if (!state[String(issueIdString)]) return;
			newState.setIn([issueIdString, 'devStatus'], payload[issueIdString]);
		});

		return newState.value();
	}

	if (action.type === ISSUE_FLAG_WITH_COMMENT_REQUEST) {
		const {
			payload: { issueIds, flag },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueFlagWithCommentRequestAction;
		const newState = chain(state);

		issueIds.forEach((id) => {
			if (state[String(id)]) {
				newState.setIn([String(id), 'isFlagged'], flag);
			}
		});

		return newState.value();
	}

	if (action.type === ASSIGN_TO_ME_REQUEST) {
		const {
			payload: { issueId, assigneeAccountId },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as AssignToMeRequestAction;
		return state[String(issueId)]
			? setIn(state, [String(issueId), 'assigneeAccountId'], assigneeAccountId)
			: state;
	}

	if (action.type === COLUMN_DELETE_REQUEST) {
		const newState = { ...state };
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { payload } = action as ColumnDeleteRequestAction;
		Object.keys(state)
			.filter((issueKey) => state[issueKey].columnId === payload.columnId)
			.forEach((issueKey) => {
				if (!payload.closestColumn) {
					delete newState[issueKey];
				} else if (payload.closestColumn.type === ColumnType.STATUS) {
					newState[issueKey] = {
						...newState[issueKey],
						columnId: payload.closestColumn.id,
						statusId: payload.closestColumn.statuses[0].id,
					};
				}
			});
		return newState;
	}

	if (action.type === ISSUE_ADD_LABELS) {
		const {
			payload: { issueIds, labels },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueAddLabelsAction;
		const newState = chain(state);

		issueIds.forEach((id) => {
			if (!state[String(id)]) return;
			const currentLabels = state[String(id)].labels || [];
			newState.setIn([String(id), 'labels'], union(currentLabels, labels));
		});

		return newState.value();
	}

	if (action.type === SET_PARENT_OPTIMISTIC) {
		const {
			payload: { issues, parentId },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as SetParentOptimisticAction;
		const newState = chain(state);

		if (ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1')) {
			issues.forEach((issue) => {
				if (!state[String(issue.id)]) return;
				newState.setIn([String(issue.id), 'parentId'], parentId);
				newState.setIn([String(issue.id), 'hasScenarioChanges'], true);
			});

			return newState.value();
		}

		issues.forEach((issue) => {
			if (!state[String(issue.id)]) return;
			newState.setIn([String(issue.id), 'parentId'], parentId);
		});
		return newState.value();
	}

	if (action.type === SET_ESTIMATE) {
		const {
			payload: { issueId, estimate },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as SetEstimateAction;

		if (ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1')) {
			if (state[String(issueId)]) {
				return chain(state)
					.setIn([String(issueId), 'estimate'], estimate)
					.setIn([String(issueId), 'hasScenarioChanges'], true)
					.value();
			}

			return state;
		}

		return state[String(issueId)] ? setIn(state, [String(issueId), 'estimate'], estimate) : state;
	}

	if (action.type === SET_ISSUE_PARENT) {
		const {
			payload: { issueId, issueParentId },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as SetIssueParentAction;

		if (ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1')) {
			if (state[String(issueId)]) {
				return chain(state)
					.setIn([String(issueId), 'parentId'], issueParentId)
					.setIn([String(issueId), 'hasScenarioChanges'], true)
					.value();
			}

			return state;
		}

		return state[String(issueId)]
			? setIn(state, [String(issueId), 'parentId'], issueParentId)
			: state;
	}

	if (action.type === SET_CHILD_ISSUES_METADATA) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { payload: issues } = action as SetChildIssuesMetadataAction;
		const newState = chain(state);
		issues.forEach((issue) => {
			if (!state[String(issue.id)]) return;
			newState.setIn([String(issue.id), 'numTotalChildren'], issue.numTotalChildren || 0);
			newState.setIn([String(issue.id), 'numCompleteChildren'], issue.numCompleteChildren || 0);
		});
		return newState.value();
	}

	if (action.type === SET_SUMMARY) {
		const {
			payload: { issueId, summary },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as SetSummaryAction;

		if (ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1')) {
			if (state[String(issueId)]) {
				return chain(state)
					.setIn([String(issueId), 'summary'], summary)
					.setIn([String(issueId), 'hasScenarioChanges'], true)
					.value();
			}

			return state;
		}

		return state[String(issueId)] ? setIn(state, [String(issueId), 'summary'], summary) : state;
	}

	if (
		ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1') &&
		!fg('dependency_visualisation_program_board_fe_and_be') &&
		action.type === ISSUE_LINKS_CREATE
	) {
		const {
			payload: { newIssueLinks },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueLinkCreateAction;

		const newIssueLinksGroups = Object.entries(newIssueLinks);
		const newState = chain(state);
		newIssueLinksGroups.forEach(([issueId, issueLinks]) => {
			newState.setIn(
				[String(issueId), 'issueLinks'],
				[...(state[String(issueId)]?.issueLinks ?? []), ...issueLinks],
			);
			newState.setIn([String(issueId), 'hasScenarioChanges'], true);
		});
		return newState.value();
	}

	if (
		action.type === ISSUE_LINKS_ADD_UPDATE_SUCCESS &&
		fg('dependency_visualisation_program_board_fe_and_be')
	) {
		const {
			payload: { newIssueLinks },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueLinkAddAndUpdateSuccessAction;

		const newIssueLinksGroups = Object.entries(newIssueLinks);
		const newState = chain(state);
		newIssueLinksGroups.forEach(([issueId, issueLinks]) => {
			newState.setIn([String(issueId), 'issueLinks'], issueLinks);
			newState.setIn([String(issueId), 'hasScenarioChanges'], true);
		});
		return newState.value();
	}

	if (
		ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1') &&
		action.type === ISSUE_LINKS_REMOVE_SUCCESS
	) {
		const {
			payload: { issueLinksToRemove },
		} = action;

		const issueLinksGroups = Object.entries(issueLinksToRemove);
		const newState = chain(state);
		const updateState = (issueId: IssueId | undefined, issueLinkIdToRemove: string) => {
			if (issueId) {
				const issueLinks = state[String(issueId)]?.issueLinks ?? [];
				newState.setIn(
					[String(issueId), 'issueLinks'],
					issueLinks.filter((issueLink) => issueLinkIdToRemove !== issueLink.id),
				);
				newState.setIn([String(issueId), 'hasScenarioChanges'], true);
			}
		};

		issueLinksGroups.forEach(([issueId, issueLinkId]) => {
			const issueLinks = state[String(issueId)]?.issueLinks ?? [];
			const issueLinkToRemove = issueLinks.find((issueLink) => issueLinkId === issueLink.id);

			// Update issue links state in the source issue
			updateState(issueLinkToRemove?.sourceId, issueLinkId);

			// Update issue links state in the destination issue
			updateState(issueLinkToRemove?.destinationId, issueLinkId);
		});
		return newState.value();
	}
	return state;
};
