import React, { type ComponentType } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

export type Props = {
	isDisabled: boolean;
	onClick: () => void;
};

export const ColumnLimitsMenuItem = (props: Props) => {
	const { formatMessage } = useIntl();
	return (
		<DropdownItem
			data-testid="software-board.board-container.board.column.header.menu.item-limits"
			isDisabled={props.isDisabled}
			onClick={props.onClick}
		>
			{formatMessage(messages.displayText)}
		</DropdownItem>
	);
};

export const hideableMenuItem =
	(MenuItem: ComponentType<Props>): ComponentType<Props> =>
	(props: Props) =>
		!props.isDisabled ? <MenuItem {...props} /> : null;

export default hideableMenuItem(ColumnLimitsMenuItem);
