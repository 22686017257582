import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';

export const useIsEligibleForOneScreenOnboarding = (isKanban: boolean) => {
	const { data } = useProjectContext();
	const isAdmin = useIsAdmin();
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [expConfig] = UNSAFE_noExposureExp('jira_one_screen_onboarding');
	const isVariation = expConfig.get('isEnabled', false);
	return isKanban && data?.simplified && isAdmin && isVariation;
};
