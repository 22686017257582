import React, { memo, type Ref } from 'react';
import { styled } from '@compiled/react';
import flow from 'lodash/flow';
import noop from 'lodash/noop';
import Lozenge from '@atlaskit/lozenge';
import { colors } from '@atlaskit/theme';
import Toggle from '@atlaskit/toggle';
import { token } from '@atlaskit/tokens';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { injectIntl, FormattedMessage, type IntlShape } from '@atlassian/jira-intl';
import type { PanelType } from '../../../types';
import { getKeyboardShortcutForPanel } from '../../../utils';
import messages from './messages';

export type Props = {
	isChecked: boolean;
	panelType: PanelType;
	ariaLabel: string;
	onToggle: () => void;
	toggleRef?: Ref<HTMLInputElement>;
	label?: string;
};

type PropsWithIntl = Props & {
	intl: IntlShape;
};

const TOGGLE_CHECKBOX_ID = 'filters.common.ui.list.panel-toggle.toggle-checkbox';
const TOGGLE_HINT_ID = 'filters.common.ui.list.panel-toggle.toggle-hint';

const PanelToggle = (props: PropsWithIntl) => {
	const {
		isChecked,
		panelType,
		onToggle,
		ariaLabel,
		intl: { formatMessage },
		toggleRef = null,
		label,
	} = props;
	const panelToggleKeyboardShortcut = getKeyboardShortcutForPanel(panelType);
	const toggleLabel = label || formatMessage(messages.toggleLabel, { panelType });

	return (
		<>
			<PanelToggleContainer>
				<PanelToggleControl>
					<Toggle
						label={ariaLabel}
						id={TOGGLE_CHECKBOX_ID}
						ref={toggleRef}
						onChange={onToggle}
						isChecked={isChecked}
						testId="filters.common.ui.list.panel-toggle.panel-toggle"
						descriptionId={TOGGLE_HINT_ID}
					/>
				</PanelToggleControl>
				<PanelToggleLabel htmlFor={TOGGLE_CHECKBOX_ID}>{toggleLabel}</PanelToggleLabel>
			</PanelToggleContainer>
			{panelToggleKeyboardShortcut && (
				<PanelToggleHint id={TOGGLE_HINT_ID}>
					<FormattedMessage
						{...messages.toggleHint}
						values={{
							kbd: (chunks) => <Lozenge>{chunks}</Lozenge>,
							key: panelToggleKeyboardShortcut,
						}}
					/>
				</PanelToggleHint>
			)}
		</>
	);
};

PanelToggle.defaultProps = {
	isChecked: false,
	panelType: '',
	onToggle: noop,
};

export default flow(
	injectIntl,
	memo,
	ComponentWithAnalytics('filterPanelToggle', {
		onToggle: 'toggled',
	}),
)(PanelToggle);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelToggleContainer = styled.div({
	display: 'inline-flex',
	alignItems: 'center',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelToggleControl = styled.div({
	flex: '0 0 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelToggleLabel = styled.label({
	flex: '1 1 auto',
	marginLeft: token('space.025', '2px'),
	overflowX: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelToggleHint = styled.small({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	whiteSpace: 'nowrap',
	font: token('font.body.UNSAFE_small'),
	marginTop: 0,
	paddingLeft: token('space.075', '6px'),
});
