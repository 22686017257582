import React, { Component, type SyntheticEvent } from 'react';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { CardCoverBackgroundImg } from '@atlassian/jira-platform-board-kit/src/ui/card/card-cover/background-img/index.tsx';
import CardCover from '@atlassian/jira-platform-board-kit/src/ui/card/card-cover/index.tsx';
import { connect } from '@atlassian/jira-react-redux';
import type { IssueId } from '@atlassian/jira-software-board-common';
import { useShowCardCovers } from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import type { IssueMedia } from '../../../../../../model/issue/issue-media-types';
import {
	getIssueMedia,
	isIssueMediaHiddenByUser,
} from '../../../../../../state/selectors/issue/issue-media-selectors';
import { getIsCMPBoard } from '../../../../../../state/selectors/software/software-selectors';
import { isCardMediaEnabled } from '../../../../../../state/selectors/work/work-selectors';
import { useCardMediaActions } from '../../../../../../state/sweet-state/card-media';
import type { State } from '../../../../../../state/types';
import Placeholder from './placeholder';

type OwnProps = {
	// go/jfe-eslint
	id: IssueId;
	isClassic?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setMediaHeight: ((mediaId: string, height: number) => any) | undefined;
	onImageLoad: () => void;
	showCardCovers?: Boolean;
};

type Props = {
	issueMedia: IssueMedia | null | undefined;
} & OwnProps;

// eslint-disable-next-line jira/react/no-class-components
export class CardCoverConnected extends Component<Props> {
	static defaultProps = {
		isClassic: false,
		issueMedia: null,
		setMediaHeight: undefined,
		onImageLoad: noop,
	};

	onImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
		const { onImageLoad, setMediaHeight, issueMedia } = this.props;
		onImageLoad();

		if (
			setMediaHeight &&
			!isNil(issueMedia) &&
			'attachmentMediaApiId' in issueMedia &&
			!isNil(issueMedia.attachmentMediaApiId)
		) {
			setMediaHeight(issueMedia.attachmentMediaApiId, e.currentTarget.height);
		}
	};

	renderPlaceholder() {
		const { issueMedia } = this.props;

		if (
			!isNil(issueMedia) &&
			'attachmentMediaApiId' in issueMedia &&
			!isNil(issueMedia.attachmentMediaApiId)
		) {
			return <Placeholder mediaId={issueMedia.attachmentMediaApiId} />;
		}

		return null;
	}

	render() {
		const { issueMedia, isClassic } = this.props;

		if (isClassic && fg('jsw_cmp_card_cover_images')) {
			if (
				!issueMedia ||
				issueMedia.isHiddenByUser ||
				!expVal('jsw_cmp_card_cover_images_experiment', 'isCardCoverFeatureEnabled', false)
			) {
				return null;
			}

			const media = {
				fileName: issueMedia?.fileName,
				mediaApiFileId: issueMedia?.attachmentMediaApiId,
				mediaClientId: issueMedia?.clientId,
				mediaExternalEndpointUrl: issueMedia?.endpointUrl,
				mediaReadToken: issueMedia?.token,
				width: null,
			};

			return <CardCoverBackgroundImg {...media} />;
		}

		if (issueMedia && !issueMedia.isHiddenByUser) {
			return (
				<CardCover
					mediaId={issueMedia.attachmentMediaApiId}
					clientId={issueMedia.clientId}
					token={issueMedia.token}
					serviceHost={issueMedia.endpointUrl}
					onImageLoad={this.onImageLoad}
					placeholder={this.renderPlaceholder()}
				/>
			);
		}

		return null;
	}
}

export const mapStateToProps = (state: State, ownProps: OwnProps) => {
	const isClassic = getIsCMPBoard(state);

	if (isClassic && fg('jsw_cmp_card_cover_images')) {
		if (!ownProps.showCardCovers) {
			return { issueMedia: null };
		}

		return {
			issueMedia: getIssueMedia(state, ownProps.id),
			isClassic,
		};
	}

	return {
		issueMedia:
			isCardMediaEnabled(state) && !isIssueMediaHiddenByUser(state, ownProps.id)
				? getIssueMedia(state, ownProps.id)
				: null,
	};
};

const ConnectedComponent = connect(mapStateToProps, {})(CardCoverConnected);

export const ConnectedComponentWithPlaceholderActions = (
	props: Omit<OwnProps, 'setMediaHeight' | 'showCardCovers'>,
) => {
	const [, { setMediaHeight }] = useCardMediaActions();
	const showCardCovers = useShowCardCovers();
	return (
		<ConnectedComponent
			{...props}
			setMediaHeight={setMediaHeight}
			showCardCovers={showCardCovers}
		/>
	);
};

export default ConnectedComponentWithPlaceholderActions;
