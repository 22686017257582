import React from 'react';
import noop from 'lodash/noop';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ModalTransition } from '@atlaskit/modal-dialog';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import ReleaseModalAsync from '@atlassian/jira-version-modals/src/release-version-modal/async.tsx';
import { REFRESH_SOURCE_RELEASE_VERSION } from '../../../model/constants';
import { useBoardDispatch } from '../../../state';
import { workRefreshData } from '../../../state/actions/work';
import messages from '../../header/release-version/messages';

type Props = {
	setModalClosed: () => void;
	versionId: string;
	versionName: string;
	projectKey: string;
};

export const ReleaseVersionModal = ({
	setModalClosed,
	versionId,
	versionName,
	projectKey,
}: Props) => {
	const { formatMessage } = useIntl();
	const dispatch = useBoardDispatch();
	const { showFlag } = useFlagsService();

	const handleError = () => {
		showFlag({
			type: 'error',
			title: messages.releaseFailedFlagTitle,
			description: messages.releaseFailedFlagDescription,
			isAutoDismiss: true,
		});
		setModalClosed();
	};

	const handleSuccess = () => {
		showFlag({
			type: 'success',
			title: formatMessage(messages.releaseSuccessFlagTitle, { versionName }),
			isAutoDismiss: true,
		});
		dispatch(workRefreshData(REFRESH_SOURCE_RELEASE_VERSION));
		setModalClosed();
	};

	return (
		<ModalTransition>
			<ReleaseModalAsync
				// eslint-disable-next-line jira/deprecations/no-base-url
				baseUrl=""
				projectKey={projectKey}
				versionId={versionId}
				onOpened={noop}
				onCancel={setModalClosed}
				onSuccess={handleSuccess}
				onError={handleError}
			/>
		</ModalTransition>
	);
};
