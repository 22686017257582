import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { BoardToolSectionsResponse, SprintActionSectionResponse } from '../../common/types';

interface FetchCMPExtensionDataParams {
	boardId: string;
	projectKey: string;
	mode?: 'work' | 'plan';
}

export function fetchCMPExtensionData({
	boardId,
	projectKey,
	mode = 'work',
}: FetchCMPExtensionDataParams): Promise<BoardToolSectionsResponse> {
	return fetchJson<BoardToolSectionsResponse>(
		`/rest/greenhopper/1.0/xboard/toolSections?mode=${mode}&rapidViewId=${boardId}&selectedProjectKey=${projectKey}&_=${Date.now()}`,
	);
}

export type FetchCMPSprintActionsParams = {
	sprintId: string;
};

export function fetchCMPSprintActionsData({
	sprintId,
}: FetchCMPSprintActionsParams): Promise<SprintActionSectionResponse> {
	return fetchJson<SprintActionSectionResponse>(
		`/rest/greenhopper/1.0/xboard/plan/sprints/actions?sprintId=${sprintId}&_=${Date.now()}`,
	);
}
