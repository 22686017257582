import { filter, freeze, push, splice } from 'icepick';
import type { Action } from '../../../../actions';
import { COLUMN_CREATE_REQUEST, COLUMN_CREATE_SUCCESS } from '../../../../actions/column/create';
import { COLUMN_DELETE_REQUEST } from '../../../../actions/column/delete';
import { COLUMN_RANK_REQUEST } from '../../../../actions/column/rank';
import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../../actions/work';
import type { ColumnsState } from './types';

export const boardColumnsReducer = (
	state: ColumnsState = freeze([]),
	action: Action,
): ColumnsState => {
	if (
		(action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) &&
		action.payload.columns
	) {
		return freeze(action.payload.columns.map((column) => column.id));
	}

	if (action.type === COLUMN_DELETE_REQUEST) {
		const { columnId } = action.payload;
		return filter((id) => id !== columnId, state);
	}

	if (action.type === COLUMN_RANK_REQUEST) {
		let columnIds: ColumnsState = state;

		const { columnId, targetColumnId } = action.payload;

		const currentIndex = columnIds.indexOf(columnId);
		const newIndex = columnIds.indexOf(targetColumnId);

		columnIds = splice(columnIds, currentIndex, 1);
		columnIds = splice(columnIds, newIndex, 0, columnId);

		return columnIds;
	}

	if (action.type === COLUMN_CREATE_REQUEST) {
		const { temporaryColumnId } = action.payload;

		// For CMP boards, we put the new column before the final Done column
		if (action.meta.isCMPBoard) {
			return splice(state, state.length - 1, 0, temporaryColumnId);
		}
		return push(state, temporaryColumnId);
	}

	if (action.type === COLUMN_CREATE_SUCCESS) {
		const { temporaryColumnId, column } = action.payload;
		const currentIndex = state.indexOf(temporaryColumnId);
		return splice(state, currentIndex, 1, column.id);
	}

	return state;
};
