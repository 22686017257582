import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage';

const localStorage = createLocalStorageProvider('jira-presentation-toolkit');

export function setLocalStorage(key: string, value: string | number) {
	localStorage.set(key, value);
}

export function getLocalStorage(key: string) {
	return localStorage.get(key);
}
