import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import {
	useBottomRightCornerActions,
	useBottomRightCornerContainer,
} from '../../../controllers/layout-controller/consumers/bottom-right-corner-controller/index.tsx';
import type { Props } from './types';

const OutletWithPortal = ({ orderFromRight, children, id }: Props) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const portalWrapper = useRef(document.createElement('div'));

	const container = useBottomRightCornerContainer();

	if (!container) {
		throw new Error(
			'GlobalBottomRightContainer could not be found. Please ensure that this container was rendered on the page.',
		);
	}

	const { insertBottomRightCornerPortal, removeBottomRightCornerPortal } =
		useBottomRightCornerActions();

	useEffect(() => {
		if (container) {
			const indexToRender = insertBottomRightCornerPortal({ orderFromRight, id });
			// @ts-expect-error - TS2538 - Type 'Promise<void>' cannot be used as an index type. | TS2538 - Type 'void' cannot be used as an index type.
			container.insertBefore(portalWrapper.current, container.children[indexToRender]);
		}

		// required as eslint complains about the ref in cleanup function
		const portalCurrent = portalWrapper.current;
		return () => {
			container?.removeChild(portalCurrent);
			removeBottomRightCornerPortal(id);
		};
	}, [container, id, orderFromRight, insertBottomRightCornerPortal, removeBottomRightCornerPortal]);

	return createPortal(<StyledOutlet>{children}</StyledOutlet>, portalWrapper.current);
};

export const JiraBottomRightCornerOutlet = (props: Props) => (
	<ErrorBoundary
		id="bottom-right-corner-outlet"
		packageName="jira-bottom-right-corner-controller"
		extraEventData={{ portalId: props.id }}
	>
		<OutletWithPortal {...props} />
	</ErrorBoundary>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledOutlet = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *': {
		pointerEvents: 'auto',
		marginLeft: token('space.200', '16px'),
	},
});
