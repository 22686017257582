import { useEffect, useState } from 'react';
import { fetchQuery, graphql, useRelayEnvironment } from 'react-relay';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { eligibilityUserCanUpgradeQuery } from '@atlassian/jira-relay/src/__generated__/eligibilityUserCanUpgradeQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

export const useCheckIfUserCanUpgrade = (skip = false) => {
	const cloudId = useCloudId();
	const [userCanUpgrade, setUserCanUpgrade] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const environment = useRelayEnvironment();

	useEffect(() => {
		if (skip) {
			return;
		}
		fetchQuery<eligibilityUserCanUpgradeQuery>(
			environment,
			graphql`
				query eligibilityUserCanUpgradeQuery(
					$cloudId: ID!
					$productKey: String!
					$offeringKey: ID
				) {
					tenantContexts(cloudIds: [$cloudId]) {
						entitlementInfo(hamsProductKey: $productKey) {
							entitlement {
								experienceCapabilities {
									changeOffering(offeringKey: $offeringKey) {
										isAvailableToUser
									}
								}
							}
						}
					}
				}
			`,
			{
				cloudId,
				productKey: 'jira-software.ondemand',
				offeringKey: 'a70b5cbb-1ae1-4003-8f4a-9001a4a50526',
			},
		).subscribe({
			start: () => {
				setIsLoading(true);
			},
			complete: () => {
				setIsLoading(false);
			},
			next: (data) => {
				const entitlement = data?.tenantContexts?.[0]?.entitlementInfo?.entitlement;
				const canUserUpgrade =
					entitlement?.experienceCapabilities?.changeOffering?.isAvailableToUser;
				setUserCanUpgrade(!!canUserUpgrade);
			},
			error: (error: Error) => {
				fireErrorAnalytics({
					meta: {
						id: 'useCheckIfUserCanUpgrade',
						packageName: 'jiraRecommendTrialToFree',
						teamName: 'luna-growth',
					},
					sendToPrivacyUnsafeSplunk: true,
					error,
				});
				setIsLoading(false);
				setUserCanUpgrade(false);
			},
		});
	}, [cloudId, skip, createAnalyticsEvent, environment]);

	return { userCanUpgrade, isLoading };
};
