import React from 'react';
import Button from '@atlaskit/button/new';
import InfoIcon from '@atlaskit/icon/core/information';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, {
	ModalTransition,
	ModalHeader,
	ModalBody,
	ModalTitle,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import { Text, Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { FormattedMessage } from '@atlassian/jira-intl';

export const ReloadPageModal = ({ closeModal }: { closeModal: () => void }) => (
	<ModalTransition>
		<Modal onClose={closeModal} width="small">
			<ModalHeader>
				<Inline alignBlock="center" space="space.100">
					<InfoIcon label="info" color={token('color.icon.accent.blue')} spacing="none" />
					<ModalTitle>
						<FormattedMessage
							id="jira-recommend-trial-to-free.reload-this-page.title"
							defaultMessage="Reload this page"
							description="Title for a modal that appears when a user may have upgraded and needs to reload the page to see the upgrade take effect"
						/>
					</ModalTitle>
				</Inline>
			</ModalHeader>
			<ModalBody>
				<Text>
					<FormattedMessage
						id="jira-recommend-trial-to-free.reload-this-page.body"
						defaultMessage="If you've upgraded, reload this page to start your trial. Changes may take up to a minute."
						description="Body for a modal that appears when a user may have ugpraded and needs to relaod the page to see the ugprade take effect"
					/>
				</Text>
			</ModalBody>
			<ModalFooter>
				<Button appearance="subtle" onClick={closeModal}>
					Close
				</Button>
				<Button
					appearance="primary"
					onClick={() => {
						if (typeof window !== 'undefined') {
							window.location.reload();
						}
					}}
				>
					Reload
				</Button>
			</ModalFooter>
		</Modal>
	</ModalTransition>
);
