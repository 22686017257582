import React, { useCallback, useMemo, useContext } from 'react';
import keyBy from 'lodash/keyBy';
import noop from 'lodash/noop';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';
import type { Goal } from '@atlassian/jira-shared-types/src/goal.tsx';
import { GoalIcon } from '@atlassian/jira-software-goals/src/ui/goal/icon/index.tsx';
import { GoalTracker } from '@atlassian/jira-software-goals/src/ui/goal/tracker/index.tsx';
import type { CheckboxSelectEntry } from '../../../common/types';
import ListFilter from '../../../common/ui/list';
import type { CustomOptionLabelProps } from '../../../common/ui/list/types.tsx';
import { GoalFilterContext } from './context';
import messages from './messages';

type Action = 'set-value' | 'input-change' | 'input-blur' | 'menu-close';

const EMPTY_ARRAY: string[] = [];

const GoalCustomOptionLabel = ({ label, value }: CustomOptionLabelProps) => {
	const goalContext = useContext(GoalFilterContext);
	const goalState = goalContext.goals[value]?.state;
	return (
		<Inline space="space.050" alignBlock="center" alignInline="start" grow="fill">
			{goalState && <GoalIcon state={goalState.value} />}
			<Box as="span" xcss={goalLabelStyles}>
				{label}
			</Box>
			{goalState && (
				<GoalTracker label={goalState.label} score={goalState.score} value={goalState.value} />
			)}
		</Inline>
	);
};

export type Props = {
	isLoading?: boolean;
	isOpen?: boolean;
	goals: Goal[];
	selectedGoals?: string[];
	showMenuIfValuesAreEmpty?: boolean;
	inputValue?: string;
	onChange: (selectedGoals: string[], attributes?: AnalyticsAttributes) => void;
	onInputChange?: (
		input: string,
		arg2: {
			action: Action;
		},
	) => void;
	onMouseEnterToTriggerButton?: () => void;
	onOpenChange?: (isOpen: boolean) => void;
	isNested?: boolean;
};

const GoalFilter = ({
	goals,
	selectedGoals = EMPTY_ARRAY,
	onChange = noop,
	onInputChange = noop,
	onOpenChange = noop,
	onMouseEnterToTriggerButton = noop,
	showMenuIfValuesAreEmpty = false,
	inputValue,
	isLoading = false,
	isNested = false,
	isOpen = false,
}: Props) => {
	const { formatMessage } = useIntl();

	const values = useMemo(
		(): CheckboxSelectEntry[] =>
			goals.map((goal) => ({
				label: goal.name,
				value: goal.id,
				data: goal.state,
			})),
		[goals],
	);

	const goalsHash = useMemo(() => keyBy(goals, (goal) => goal.id), [goals]);

	const onChangeCallback = useCallback(
		(entries: CheckboxSelectEntry[]) => {
			const analyticAttributes = { isNested };
			onChange(
				entries.map((entry) => entry.value),
				analyticAttributes,
			);
		},
		[onChange, isNested],
	);

	return (
		<GoalFilterContext.Provider value={{ goals: goalsHash }}>
			<ListFilter
				label={formatMessage(messages.label)}
				values={values}
				isOpen={isOpen}
				selectedValues={selectedGoals}
				isPanelToggleChecked={false}
				onChange={onChangeCallback}
				inputValue={inputValue}
				isLoading={isLoading}
				onInputChange={onInputChange}
				onOpenChange={onOpenChange}
				noOptionsMessage={formatMessage(messages.noGoals)}
				showMenuIfValuesAreEmpty={showMenuIfValuesAreEmpty}
				isAsyncSearch={false}
				isNested={isNested}
				onMouseEnterToTriggerButton={onMouseEnterToTriggerButton}
				placeholder={formatMessage(messages.placeholder)}
				CustomOptionLabel={GoalCustomOptionLabel}
				showPanelToggle={false}
				showLink={false}
				useHrefForUrl={false}
				maxSelectionAllowed={Number.MAX_SAFE_INTEGER}
				onTogglePanel={noop}
				onLinkItemClick={noop}
				toggleRef={noop}
			/>
		</GoalFilterContext.Provider>
	);
};

export default GoalFilter;

const goalLabelStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
