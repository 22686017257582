import React from 'react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { Box, Inline, Stack, xcss, media } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { VIEW_SETTINGS_PANEL_CLOSE_BUTTON_TEST_ID } from '../../constants';
import messages from './messages';
import type { PanelProps } from './types';

export const Panel = ({ title = '', onClose = noop, children }: PanelProps) => {
	const { formatMessage } = useIntl();
	return (
		<Stack alignInline="end">
			<Box xcss={panelStyles}>
				<Inline alignBlock="center" grow="fill" spread="space-between" xcss={headerStyles}>
					<Heading size="medium" as="h2">
						{title || formatMessage(messages.defaultTitle)}
					</Heading>
					<Box xcss={ButtonWrapperStyles}>
						<Button
							appearance="subtle"
							spacing="none"
							onClick={onClose}
							testId={VIEW_SETTINGS_PANEL_CLOSE_BUTTON_TEST_ID}
						>
							<Box xcss={IconWrapperStyles}>
								<CrossIcon label={formatMessage(messages.closeButtonLabel)} />
							</Box>
						</Button>
					</Box>
				</Inline>
				<Stack>{children}</Stack>
			</Box>
		</Stack>
	);
};

const headerStyles = xcss({
	marginBottom: 'space.100',
});
const panelStyles = xcss({
	border: `1px solid ${token('color.border', '#091E42')}`,
	borderRadius: 'border.radius.200',
	width: '280px',
	padding: 'space.300',
	[media.above.lg]: {
		width: '300px',
	},
});

const ButtonWrapperStyles = xcss({
	width: token('space.400', '32px'),
	height: token('space.400', '32px'),
});

const IconWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: token('space.400', '32px'),
	height: token('space.400', '32px'),
});
