import { useCallback } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { NOT_ENROLLED } from '@atlassian/jira-common-util-get-experiment-group';
import {
	fireOperationalAnalytics,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { EXPERIMENT_NAME } from '../../common/constants';
import { useExperimentCohort } from '../experiment-cohort';
import type { FireServiceTrackEvent } from './types';

export function useServiceTrackEvent() {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cohort = useExperimentCohort(NOT_ENROLLED);

	const fireServiceTrackEvent = useCallback<FireServiceTrackEvent>(
		({ service }) => {
			fireTrackAnalytics(createAnalyticsEvent({}), 'jptDataFetch succeeded', {
				service,
				experiment: EXPERIMENT_NAME,
				cohort,
			});
		},
		[createAnalyticsEvent, cohort],
	);
	return { fireServiceTrackEvent };
}

export function useOperationalExposureEvent() {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cohort = useExperimentCohort(NOT_ENROLLED);

	const fireOperationalExposureEvent = useCallback(() => {
		fireOperationalAnalytics(createAnalyticsEvent({}), 'jptFeature exposed', {
			experiment: EXPERIMENT_NAME,
			cohort,
		});
	}, [createAnalyticsEvent, cohort]);

	return { fireOperationalExposureEvent };
}
